<template>
  <div>
    <j-card class="text-left mb-4 pa-2 j-elevated-2">
      <v-toolbar flat class="mt-2 float-right">
        <span class="p-text font-weight-bold">{{ eventType }}</span>
      </v-toolbar>
      <v-list class="py-0">
        <v-list-item class="py-0">
          <v-list-item-content class="py-1 my-0">
            <v-list-item-title class="mt-6 grey-navy--text">{{ eventDate }} | {{ eventTime }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <a @click="$router.push('/social/community-fitness/' + meetup.id)" class="text-decoration-none">
          <v-list-item>
            <v-list-item-content class="card-list-title mt-2 grey-navy--text">
              <v-list-item-title class="std-text"
                ><div>
                  {{ meetup.title }}<v-icon class="grey-navy--text mb-1">keyboard_arrow_right</v-icon>
                </div></v-list-item-title
              >
            </v-list-item-content>
          </v-list-item>
        </a>
        <v-list-item v-if="meetup.type === 'PHYSICAL'" class="py-0">
          <v-list-item-content class="py-1 my-0">
            <v-list-item-title class="grey-navy--text"
              ><v-icon class="location-pin-icon">location_on</v-icon>{{ meetup.location }}</v-list-item-title
            >
            <v-list-item-subtitle v-if="meetup.distance" class="grey-navy--text ml-5">{{
              distance
            }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item class="py-0 mt-2">
          <v-list-item-content class="py-1 my-0">
            <v-list-item-subtitle class="grey-navy--text mb-2">
              <v-list-item-subtitle class="font-weight-bold grey-navy--text">
                Host
              </v-list-item-subtitle>
              {{ getHostName(meetup.host) }}
              <span v-if="isHost">(You)</span>
            </v-list-item-subtitle>

            <v-list-item-subtitle class="grey-navy--text mb-2">
              <v-list-item-subtitle class="grey-navy--text" v-if="!isHost"
                ><span class="font-weight-bold">Attendees</span><br />
                ({{ meetup.attendees.length }}/{{ meetup.capacity }}): {{ attendees }}</v-list-item-subtitle
              >
              <v-list-item-subtitle class="grey-navy--text" v-if="isHost"
                ><span class="font-weight-bold">Attendees</span> ({{ meetup.attendees.length }}/{{ meetup.capacity }}):
              </v-list-item-subtitle>
            </v-list-item-subtitle>
            <div v-if="isHost">
              <v-list-item-subtitle
                class="grey-navy--text"
                v-for="({ attendee }, index) in meetup.attendees.slice(0, 3)"
                :key="attendee.id"
              >
                {{ index + 1 }}. {{ attendee.firstName }} {{ attendee.lastName }} - {{ attendee.userProfile.phone }}
              </v-list-item-subtitle>
            </div>
            <attendee-list :attendees="meetup.attendees" :host="meetup.host"></attendee-list>

            <div v-if="isHost">
              <v-btn
                rounded
                color="info"
                x-small
                class="mt-2 mb-4"
                v-if="meetup.attendees.length > 3"
                @click="showAllAttendiesDialog = true"
                >Show more</v-btn
              >
            </div>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="atCapacity" class="py-0">
          <v-list-item-content class="mr-2">
            <v-list-item-subtitle class="font-weight-bold grey-navy--text">
              Event is full
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="attendCheckboxEnabled" class="py-0">
          <v-list-item-content class="py-1 my-0">
            <v-list-item-title>
              <j-checkbox
                v-model="attending"
                :true-value="true"
                :false-value="false"
                label="Attending"
                @change="setAttendance(attending)"
              >
              </j-checkbox>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="isHost" class="py-0">
          <v-list-item-content class="py-1 my-0">
            <v-list-item-title>
              <j-checkbox v-model="setChecked" label="Attending" disabled> </j-checkbox>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-card-actions v-if="isHost">
        <v-spacer />
        <j-btn narrow secondary :to="'/social/community-fitness/edit/' + meetup.id">Edit</j-btn>
        <j-btn narrow primary @click="cancelMeetup">Cancel</j-btn>
      </v-card-actions>
    </j-card>
    <v-dialog v-model="attendDialog" persistent width="500">
      <j-card>
        <v-card-title class="headline grey lighten-2">
          Terms and Conditions
        </v-card-title>
        <v-card-text>
          <p>
            Your mobile number will be shared with the host so they can send you event updates.
          </p>
          <strong>Safety</strong>
          <ul>
            <li>
              In general terms, attend events where you know the people attending.
            </li>
            <li>
              In the unlikely event that you are asked for private information, be thoughtful in sharing this
              information.
            </li>
            <li>Participation in events is done at your own risk.</li>
          </ul>
          <br />
          <strong>Protocols</strong>
          <ul>
            <li>Wear a mask and observe safe social distancing.</li>
            <li>Ensure that the event area is well ventilated.</li>
          </ul>
          <br />
          <p>By tapping 'I accept' you accept the Terms and Conditions.</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <j-btn tertiary @click="cancelAttend()">
            No Thanks
          </j-btn>
          <j-btn primary @click="attendMeetup(true)">
            I accept
          </j-btn>
        </v-card-actions>
      </j-card>
    </v-dialog>
    <v-dialog v-model="showAllAttendiesDialog" width="400">
      <j-card>
        <v-toolbar class="mb-4">
          <v-toolbar-title>Attendees</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="showAllAttendiesDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <p>
            Lists of attendees in your upcoming event
            <strong>{{ meetup.title }} </strong>
          </p>

          <ul v-for="({ attendee }, index) in meetup.attendees" :key="attendee.id">
            <li class="list-style-none">
              {{ index + 1 }}. {{ attendee.firstName }} {{ attendee.lastName }}: {{ attendee.userProfile.phone }}
            </li>
          </ul>
          <br />
        </v-card-text>
      </j-card>
    </v-dialog>
  </div>
</template>

<script>
import { fancy_date_from_iso } from "@/lib/fancy_date";
import AttendeeList from "@/components/community-fitness/AttendeeList";
import { capitalize } from "@/lib/string";

export default {
  name: "MeetingCard",
  props: {
    meetup: {
      type: Object,
      default: null,
    },
  },
  components: {
    AttendeeList,
  },
  data() {
    return {
      setChecked: true,
      attendDialog: false,
      attending: false,
      showAllAttendiesDialog: false,
    };
  },
  mounted() {
    let userId = this.$store.getters.user.id;
    this.attending =
      this.meetup.attendees.filter((entry) => parseInt(entry.attendee.id) === parseInt(userId)).length > 0;
  },
  computed: {
    eventDate() {
      let localDate = this.$moment(this.meetup.eventDate)
        .local(true)
        .format("YYYY-MM-DD HH:mm:ss");
      return fancy_date_from_iso(localDate, "ddd, D MMMM YYYY", false);
    },
    eventTime() {
      let localTime = this.$moment(this.meetup.eventDate)
        .local(true)
        .format("LT");
      return localTime;
    },
    distance() {
      return `Approximately ${this.meetup.distance}km from you`;
    },
    eventType() {
      return this.meetup.type === "PHYSICAL" ? "In Person" : "Virtual";
    },
    isHost() {
      let userId = this.$store.getters.user.id;
      return parseInt(this.meetup.host.id) === parseInt(userId);
    },
    atCapacity() {
      return this.meetup.attendees.length >= this.meetup.capacity;
    },
    attendingEvent() {
      let userId = this.$store.getters.user.id;
      return this.meetup.attendees.filter((entry) => parseInt(entry.attendee.id) === parseInt(userId)).length > 0;
    },
    attendCheckboxEnabled() {
      if (this.isHost) return false;
      else if (this.atCapacity) return false;
      return true;
    },
    showAttendButton() {
      if (this.isHost) return false;
      //user is hosting event
      else if (this.atCapacity) return false;
      //meeting has no more capacity
      else if (this.attendingEvent) return false; //user is already attending meeting
      return true;
    },
    attendees() {
      if (this.isHost) return this.allAttendees;

      let names = [];
      for (let i = 0; i < 5; i++) {
        if (this.meetup.attendees[i]) {
          let name = this.meetup.attendees[i].attendee.firstName;

          names.push(capitalize(name));
        }
      }
      let ret = names.join(", ");
      if (this.meetup.attendees.length > 4) {
        let diff = this.meetup.attendees.length - 4;
        ret += " and " + (this.meetup.attendees.length - 4).toString();
        ret += diff > 1 ? " others" : " other";
      }
      return ret;
    },
    allAttendees() {
      let mapped = this.meetup.attendees.map((elem) => elem.attendee.firstName + " " + elem.attendee.lastName);
      mapped = ["You"].concat(mapped);
      return mapped.join(", ");
    },
  },
  methods: {
    setAttendance(attending) {
      if (attending) {
        this.attendDialog = true;
      } else {
        let confirm = window.confirm("Are you sure you no longer want to attend " + this.meetup.title + "?");
        if (confirm) this.attendMeetup(false);
        else {
          //nasty hack sorry team
          window.setTimeout(() => {
            this.attending = true;
          }, 1);
        }
      }
    },
    attendMeetup(attending = true) {
      this.$emit("attend", this.meetup, attending);
      this.attendDialog = false;
    },
    cancelAttend() {
      this.attending = false;
      this.attendDialog = false;
    },
    getHostName(host) {
      let name = host.firstName;
      let surname = host.lastName;

      return capitalize(name) + " " + capitalize(surname);
    },
    cancelMeetup() {
      this.$emit("cancel", this.meetup);
    },
  },
};
</script>
