import { CURRENT_HABIT_QUERY } from "@/graphql/queries/habits";
import { mapGetters } from "vuex";

export default {
  name: "HabitMixin",
  data() {
    return {
      habitLog: {
        id: null,
        status: null,
      },
      pastHabits: false
    };
  },
  computed: {
    ...mapGetters(["selectedDate"]),
  },
  methods: {
    formattedDate() {
      return this.selectedDate ? this.selectedDate.format("YYYY-MM-DD") : this.$moment().format("YYYY-MM-DD");
    },
  },
  apollo: {
    habit: {
      query: CURRENT_HABIT_QUERY,
      variables() {
        return {
          date: this.selectedDate ? this.selectedDate.format("YYYY-MM-DD") : this.$moment().format("YYYY-MM-DD"),
        }
      },
      fetchPolicy: "network-only",
      update(data) {
        let habit = data.habitsQuery?.edges.map((edge) => edge.node)[0];
        this.pastHabits = data.habitsQuery?.pageInfo?.hasNextPage;
        this.habitLog = habit?.habitentrySet?.edges[0]?.node || {
          id: null,
          status: null,
        };

        return habit;
      },
    },
  },
};
