<template>
  <j-banner-container
    header="Bookmarks"
    :backLink="`/p/${userId}`"
    md="6"
    :gradient="['red-background', 'yellow-background']"
    slot-text-align="left"
  >
    <v-row v-if="!loading" align="center" justify="center">
      <v-col>
        <v-row v-if="loading">
          <v-col cols="12" align="center" justify="center">
            <v-progress-circular indeterminate color="secondary" size="70" class="my-4"></v-progress-circular>
          </v-col>
        </v-row>
        <div v-else>
          <v-tabs v-model="tab" centered>
            <v-tab href="#workouts">
              Workouts
            </v-tab>

            <v-tab href="#videos">
              Video content
            </v-tab>

            <v-tab href="#recipes">
              Recipes
            </v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item value="workouts">
              <div v-for="item in activities" :key="item.node.id" class="mt-4">
                <bookmark-activity-card
                  :showBookmark="isMyPage"
                  :activity="item.node"
                  :includeLive="true"
                  :includeTime="true"
                  :bookmarkId="item.node.id"
                  :refresh-query="''"
                />
              </div>
              <j-alert v-if="!$apollo.loading && activities.length === 0" class="mx-4 mt-4"
                >Nothing bookmarked yet</j-alert
              >
            </v-tab-item>
            <v-tab-item value="videos">
              <div v-for="item in videos" :key="item.node.id" class="mt-4">
                <bookmark-event-card
                  :showBookmark="isMyPage"
                  :event="item.node.event"
                  :includeLive="true"
                  :includeTime="true"
                  :bookmarkId="item.node.id"
                  :bookmarkNotes="item.node.notes"
                  :refresh-query="''"
                />
              </div>
              <j-alert v-if="!$apollo.loading && videos.length === 0" class="mx-4 mt-4">Nothing bookmarked yet</j-alert>
            </v-tab-item>
            <v-tab-item value="recipes">
              <v-row v-if="recipes.length > 0" class="mt-2">
                <v-col v-for="item in recipes" :key="item.node.id" cols="6" md="4">
                  <recipe-listing-card
                    v-if="item.node.recipe"
                    @show-locked-banner="lockedToClubDialog = true"
                    :recipe="item.node.recipe"
                    :hide-bookmark="!isMyPage"
                    :isObjectNote="true"
                  />
                </v-col>
              </v-row>
              <j-alert v-if="!$apollo.loading && recipes.length === 0" class="mx-4 mt-4"
                >Nothing bookmarked anything yet</j-alert
              >
            </v-tab-item>
          </v-tabs-items>
          <scroll-loader :loader-method="loadmore" :loader-disable="!hasNextPage"></scroll-loader>
        </div>
      </v-col>
    </v-row>
  </j-banner-container>
</template>

<script>
import { BOOKMARKS_QUERY } from "@/graphql/queries/bookmark";
import BookmarkEventCard from "@/components/bookmarks/BookmarkEventCard";
import BookmarkActivityCard from "@/components/bookmarks/BookmarkActivityCard";
import UserMixin from "@/mixins/UserMixin";
import RecipeListingCard from "@/components/nutritionblocks/cards/RecipeListingCard";

export default {
  name: "UserBookmark",
  mixins: [UserMixin],
  components: {
    BookmarkEventCard,
    RecipeListingCard,
    BookmarkActivityCard,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      tab: "workouts",
      selectedTab: "workouts",
      loading: true,
      bookmarks: null,
      videos: [],
      recipes: [],
      activities: [],
      after: "",
      endCursor: "",
      hasNextPage: true,
    };
  },
  watch: {
    tab: function() {
      this.recipes = [];
      this.videos = [];
      this.activities = [];
      this.after = "";
    },
  },
  methods: {
    loadmore() {
      if (!this.$apollo.loading) {
        this.after = this.endCursor;
      }
    },
  },
  apollo: {
    bookmarks: {
      query: BOOKMARKS_QUERY,
      variables() {
        let variables = {
          userId: this.userId,
          orderBy: "-created_at",
          after: this.after,
          first: 20,
        };

        switch (this.tab) {
          case "workouts":
            variables["contentResourceId_Isnull"] = true;
            variables["recipeId_Isnull"] = true;
            variables["eventId_Isnull"] = true;
            variables["unitActivityId_Isnull"] = false;
            break;
          case "videos":
            variables["contentResourceId_Isnull"] = true;
            variables["recipeId_Isnull"] = true;
            variables["eventId_Isnull"] = false;
            variables["unitActivityId_Isnull"] = true;
            break;
          case "recipes":
            variables["contentResourceId_Isnull"] = true;
            variables["recipeId_Isnull"] = false;
            variables["eventId_Isnull"] = true;
            variables["unitActivityId_Isnull"] = true;
            break;
        }

        return variables;
      },
      fetchPolicy: "network-only",
      result(response) {
        if (response.data) {
          this.loading = false;
          this.endCursor = response.data.bookmarks.pageInfo.endCursor;
          this.hasNextPage = response.data.bookmarks.pageInfo.hasNextPage;
          this.videos = [];
          this.recipes = [];
          this.activities = [];

          this.videos = [...this.videos, ...response.data.bookmarks.edges.filter((item) => item.node.event != null)];
          this.recipes = [...this.recipes, ...response.data.bookmarks.edges.filter((item) => item.node.recipe != null)];
          this.activities = [
            ...this.activities,
            ...response.data.bookmarks.edges.filter((item) => item.node.unitActivity != null),
          ];
        }
      },
    },
  },
};
</script>

<style scoped>
.recipe-text {
  max-width: 85%;
  text-align: left;
  line-height: 1.45;
  padding-left: 4px;
  cursor: pointer;
}

.bookmark-button {
  position: absolute;
  right: 15px;
  top: 25px;
}
</style>
