<template>
  <div>
    <p>Join JEFF Together now for daily inspiration, motivation and community support. 
    <div>
      <j-btn tertiary class="mb-4" target="_blank" href="https://www.facebook.com/groups/JEFFTogether">
        JEFF Together
        <v-icon color="facebook-blue" class="ml-1 mb-1">mdi-facebook</v-icon>
      </j-btn>
    </div>
    <p>Join JEFF Nourish now to discover new recipes and learn to enjoy eating well.</p>
    <div>
      <j-btn tertiary class="mb-4" target="_blank" href="https://www.facebook.com/groups/jeffnourish">
        JEFF Nourish
        <v-icon color="facebook-blue" class="ml-1 mb-1">mdi-facebook</v-icon>
      </j-btn>
    </div>
    <p>Join JEFF Life now for exclusive content only shared with members of JEFF.</p>
    <div>
      <j-btn tertiary class="mb-4" target="_blank" href="https://www.facebook.com/groups/JEFFMembers">
        JEFF Life
        <v-icon color="facebook-blue" class="ml-1">mdi-facebook</v-icon>
      </j-btn>
    </div>
    <p>JEFF Life is private, so our team will approve your membership shortly after you request to join.</p>
  </div>
</template>

<script>
import { FACEBOOK_GROUPS } from "@/graphql/queries/assistant";

export default {
  name: "JoinFacebookContent",
  apollo: {
    facebookGroups: {
      query: FACEBOOK_GROUPS,
      update(data) {
        let blocks = [...data.home.subscriptionBlocks, ...data.home.upcomingSubscriptionBlocks]
        return blocks.filter((block) => block.block.facebookUrl);
      },
    },
  },
};
</script>
