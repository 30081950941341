
import { mapGetters } from "vuex";
import MissingPreferencesDialog from "@/components/nutritionblocks/dialogs/MissingPreferencesDialog";

export default {
  components: {
    MissingPreferencesDialog,
  },
  data() {
    return {
      openMissingPrefs: false,
    };
  },
  computed: {
    ...mapGetters(["nutritionPreferences"]),
  },
  methods: {
    getMissingPreferences(recipe) {
      const missingPrefs = [];
      if (this.nutritionPreferences && recipe?.preferences) {
        // exclude None from triggering a warning. 
        const prefs = this.nutritionPreferences.filter((element) => element.title != "None");
        prefs.forEach((pref) => {
          let missing = !recipe.preferences.some((el) => el.id == pref.id);
          if (missing) {
            missingPrefs.push(pref);
          }
        });
      }
      return missingPrefs;
    },
  },
 
};
