<template>
  <div>
    <v-row no-gutters>
      <v-col cols="6" :md="mdCols" v-if="breakfast" class="py-2">
        <recommended-recipe-block
          :recipe="breakfast.recipe"
          :log="breakfast.log"
          :blockId="blockId"
          :date="date"
          searchSwappable
          meal="BREAKFAST"
          :swapped="breakfast.recipe.swapped || false"
          :show-action-buttons="showActionButtons"
        />
      </v-col>
      <v-col cols="6" :md="mdCols" v-if="primarySnack" class="py-2">
        <recommended-recipe-block
          :recipe="primarySnack.recipe"
          :log="primarySnack.log"
          :blockId="blockId"
          :date="date"
          searchSwappable
          meal="PRIMARY_SNACK"
          :swapped="primarySnack.recipe.swapped || false"
          :show-action-buttons="showActionButtons"
        />
      </v-col>
      <v-col cols="6" :md="mdCols" v-if="lunch" class="py-2">
        <recommended-recipe-block
          :recipe="lunch.recipe"
          :log="lunch.log"
          :blockId="blockId"
          :date="date"
          searchSwappable
          meal="LUNCH"
          :swapped="lunch.recipe.swapped || false"
          :show-action-buttons="showActionButtons"
        />
      </v-col>

      <v-col cols="6" :md="mdCols" v-if="dinner" class="py-2">
        <recommended-recipe-block
          :recipe="dinner.recipe"
          :log="dinner.log"
          :blockId="blockId"
          :date="date"
          searchSwappable
          meal="DINNER"
          :swapped="dinner.recipe.swapped || false"
          :show-action-buttons="showActionButtons"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import RecommendedRecipeBlock from "@/components/nutritionblocks/RecommendedRecipeBlock";

export default {
  name: "RecipeDayListing",
  components: {
    RecommendedRecipeBlock,
  },
  props: {
    mdCols: {
      type: [Number, String],
      default: 3,
    },
    subscriptionBlockId: {
      type: String,
      required: false,
    },
    blockId: {
      type: String,
      required: false,
    },
    date: {
      type: String,
      required: false,
    },
    breakfast: {
      type: Object,
      required: false,
    },
    lunch: {
      type: Object,
      required: false,
    },
    dinner: {
      type: Object,
      required: false,
    },
    primarySnack: {
      type: Object,
      required: false,
    },
    showActionButtons: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
