<template>
  <div>
    <div class="my-5">
      <h3 class="mb-3">
        {{ handler }} tip #1 - Set a goal filled with emotion
      </h3>
      <span class="font-15">
        Your goal reminds you why you've chosen to join {{ $appName }}. Fill your goal
        with emotion to make your goal more personal and motivating.
      </span>
      <span class="d-inline-block font-15"
        >Go to
        <a class="jeff-life--text" href="/goal/my-goal"> 'Goal' → 'My Goal' </a>
        to review your goal at anytime!</span
      >
    </div>

    <div class="my-5">
      <h3 class="mb-3">
        {{ handler }} tip #2 - Exercise in a way that allows you to be
        consistent
      </h3>
      <span class="font-15">
        Start by building the habit of a sustainable exercise routine. You can
        then increase your intensity once you’re consistent.
      </span>
      <span class="d-inline-block font-15"
        >Go to
        <a class="jeff-life--text" href="/today/live"> 'Home' → 'Live'</a> and
        <a class="jeff-life--text" href="/today/on-demand">
          'Home' → 'On-Demand'
        </a>
        to see the wide variety of sessions for you!</span
      >
    </div>

    <div class="my-5">
      <h3 class="mb-3">
        {{ handler }} tip #3 - Aim to eat well on 5 and a half days out of
        7
      </h3>
      <span class="font-15">
        Create sustainable eating habits that become a lifestyle, not a quick
        fix. Eat and drink well on 5 and a half days a week, and give yourself
        permission to relax for the remaining days.
      </span>
      <span class="d-inline-block font-15"
        >Go to
        <a class="jeff-life--text" href="/plan/life/nutrition"
          >'Plan' → 'Life' → 'Nutrition'</a
        >
        to see cookalongs and recipes!</span
      >
    </div>

    <div>
      <v-btn
        color="secondary"
        large
        rounded
        href="https://f.hubspotusercontent20.net/hubfs/6239229/Food/Recipe%20Books/JEFF%20Winter%20Warmer%20Recipe%20Book-min.pdf"
        target="_blank"
        >recipe book</v-btn
      >
    </div>

    <div class="my-5">
      <h3 class="mb-3">
        {{ handler }} tip #4 - Prioritise sleep for your mood and recovery
      </h3>
      <span class="font-15">
        Good sleep unlocks the benefits of your other healthy habits. Give your
        body the opportunity to rest. Get into bed as early as you can. Reduce
        the amount of time you spend looking at screens before bed.
      </span>
      <span class="d-inline-block font-15"
        >Go to
        <a class="jeff-life--text" href="/today/on-demand"
          >'Home' → 'On-Demand'</a
        >
        and filter by 'Ed' to catch up on past education sessions!</span
      >
    </div>

    <div class="my-5">
      <h3 class="mb-3">
        {{ handler }} tip #5 - Share your journey with the community
      </h3>
      <span class="font-15">
        Get accountability and support from the {{ $appName }} community every day.
      </span>
      <span class="d-inline-block font-15"
        >Go to
        <a class="jeff-life--text" href="/support/life">'Support' → 'Life'</a>
        to join the Facebook groups and WhatsApp groups and interact with
        like-minded people anytime! Go to
        <a class="jeff-life--text" href="/invite-my-friends"
          >'More' → 'Invite My Friends'</a
        >
        to send your friends the link to join for free!</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "OnboardingTips",
  props: {
    handlerName: {
      type: String,
      required: true,
    },
  },
  computed: {
    handler() {
      if (this.handlerName[this.handlerName.length - 1] == "s")
        return `${this.handlerName}'`;
      else return `${this.handlerName}'s`;
    },
  },
};
</script>

<style scoped>
.font-15 {
  font-size: 15px;
}
</style>
