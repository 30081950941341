<template>
  <j-card rounded="lg">
    <v-toolbar class="mb-6">
      <v-toolbar-title class="navy--text headline"> Share your review </v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="closeRatingDialog">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <ValidationObserver ref="obs">
        <div class="pb-0">
          <span class="std-text info-text font-weight-bold blue--text">How would you rate this program?</span>
          <ValidationProvider rules="required" name="Rating" v-slot="{ errors }">
            <v-rating
              class="star-rating-icons"
              background-color="yellow lighten-1"
              color="yellow"
              empty-icon="mdi-star-outline"
              full-icon="mdi-star"
              half-icon="mdi-star-half-full"
              hover
              length="5"
              :size="getSize"
              v-model="rate"
            ></v-rating>
            <p v-if="errors && errors.length > 0" class="sm-text red--text">{{ errors[0] }}</p>
          </ValidationProvider>
        </div>
        <div class="pt-4 pb-0">
          <span class="std-text info-text font-weight-bold blue--text">Help future clients by adding a comment</span>
          <ValidationProvider rules="required" name="Comment" v-slot="{ errors, valid }">
            <j-text-field
              :error-messages="errors"
              :success="valid"
              dense
              ref="comment"
              placeholder=""
              class="pt-0 mt-0"
              v-model="comment"
            />
          </ValidationProvider>
        </div>

        <v-card-actions>
          <j-btn secondary class="ml-2" @click="submitReview" :loading="loading">
            SUBMIT REVIEW
          </j-btn>
        </v-card-actions>
      </ValidationObserver>
    </v-card-text>
  </j-card>
</template>

<script>
import { UPSERT_RATING_MUTATION } from "@/graphql/mutations/rating";

export default {
  name: "RatingCard",
  data() {
    return {
      loading: false,
      rate: null,
      comment: "",
    };
  },
  props: {
    rating: {
      type: Object,
      default: null,
    },
    blockId: {
      type: [String, Number],
      required: true,
      default: null,
    },
  },
  computed: {
    getSize() {
      return this.$vuetify.breakpoint.smAndDown ? 30 : 53;
    },
  },
  methods: {
    closeRatingDialog() {
      this.$emit("closed");
    },
    async submitReview() {
      try {
        const valid = await this.$refs.obs.validate();
        if (!valid) return;

        this.loading = true;

        const input = {
          workoutBlockId: parseInt(this.blockId),
          rating: this.rate,
          comment: this.comment,
        };
        await this.$apollo.mutate({
          mutation: UPSERT_RATING_MUTATION,
          variables: {
            data: input,
          },
        });

        this.$toasted.success("Review has been submitted.", {
          duration: 2000,
          position: "bottom-center",
        });

        this.rate = null;
        this.comment = "";
        this.loading = false;
        this.$refs.obs.reset();
        this.$emit("closed");
      } catch (e) {
        this.$toasted.error("Unable to submit review, please try again.", {
          duration: 2000,
          position: "bottom-center",
        });

        this.$emit("closed");
        this.loading = false;
      }
    },
  },
};
</script>
