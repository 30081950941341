import gql from "graphql-tag";

export const NUTRITION_PREFERENCES_QUERY = gql`
  query nutritionPreferenceList {
    nutritionPreferenceList {
      id
      title
      type
      isPrimary
      showToUser
    }
  }
`;

export const USER_NUTRITION_PREFERENCES_QUERY = gql`
  query userNutritionPreferences {
    userNutritionPreferences {
      nutritionPreference {
        id
        title
        isPrimary
      }
    }
  }
`;

export const USER_NUTRITION_LOG_QUERY = gql`
  query nutritionLogQuery($date: Date, $isNutritiousDay: Boolean, $dateLte: Date, $dateGte: Date) {
    nutritionLog(date: $date, isNutritiousDay: $isNutritiousDay, date_Lte: $dateLte, date_Gte: $dateGte) {
      edges {
        node {
          id
          date
          comments
          isNutritiousDay
          totalQuestions
          truthyQuestions
          usernutritionloganswerSet {
            id
            answer
            nutritionQuestion {
              id
            }
          }
        }
      }
    }
  }
`;

export const NUTRITION_QUESTIONS = gql`
  query nutritionQuestions {
    nutritionQuestions {
      id
      order
      title
      question
    }
  }
`;

export const OPEN_RECIPES_QUERY = gql`
  query recipes($first: Int) {
    recipes(first: $first, openAccess: true, isClientMade: false) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          title
          image
          meal
          openAccess
          createdAt
          preferences {
            id
            title
            type
            isPrimary
          }
          userBookmarks {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const RECIPES_QUERY = gql`
  query recipes(
    $search: String
    $mealIn: [String]
    $preferencesIn: [String]
    $isBookmarked: Boolean
    $isAddedByMe: Boolean
    $isInBlock: Float
    $first: Int
    $after: String
    $orderBy: String
  ) {
    recipes(
      search: $search
      meal_In: $mealIn
      preferencesIn: $preferencesIn
      isBookmarked: $isBookmarked
      isAddedByMe: $isAddedByMe
      isInBlock: $isInBlock
      first: $first
      after: $after
      orderBy: $orderBy
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          title
          image
          meal
          availableToLife
          openAccess
          createdAt
          owner {
            id
          }
          preferences {
            id
            title
            type
            isPrimary
          }
          userBookmarks {
            edges {
              node {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const NUTRITION_PLAN_MACRO_QUERY = gql`
  query nutritionPlanMacros {
    me {
      id
      userProfile {
        id
        weightLossGoal
      }
    }
    nutritionPlanMacros {
      id
      title
      coverImage
      guidePdf
      description
      planmacroSet {
        id
        meal
        units
        protein
        carbs
        fat
        kcal
      }
    }
  }
`;

export const INGREDIENTS_QUERY = gql`
  query ingredientsQuery($orderBy: String) {
    ingredients(orderBy: $orderBy) {
      edges {
        node {
          id
          singular
          plural
        }
      }
    }
  }
`;

export const PREPARATION_QUERY = gql`
  query preparationsQuery {
    preparations {
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

export const MEASUREMENT_UNITS_QUERY = gql`
  query measurementUnitsQuery {
    measurementUnits {
      edges {
        node {
          id
          title
          singular
          plural
        }
      }
    }
  }
`;
