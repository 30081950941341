<template>
  <v-container class="px-6 mt-0 pt-0">
    <div>
      <div class="d-flex flex-wrap align-baseline my-5">
        <h2 class="navy--text mr-2 text-capitalize">{{leaderboardType}} Dots</h2>
      </div>
    </div>
    <leaderboard-entry
      v-for="(entry, index) in leaderboardData"
      :key="`${leaderboardType}-lb-entry-${index}`"
      :image="entry.image"
      :title="entry.title"
      :dots="entry.dots"
      :dot-color="leaderboardType"
      :position="showPosition ? index + 1 : false"
      :highlight="entry.isMine"
      :icon="entry.icon"
    />
  </v-container>
</template>

<script>
import LeaderboardEntry from "@/components/leaderboard/LeaderboardEntry";
export default {
  name: "LeaderboardBoard",
  components: {LeaderboardEntry},
  props: {
    leaderboardType: {
      type: String,
      required: true,
      default: "exercise" // (exercise/nutrition or mindset)
    },
    leaderboardData: {
      type: Array,
      required: true
    },
    showPosition: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      tab: 0,
    };
  },
}
</script>