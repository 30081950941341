<template>
  <v-container>
    <v-row align="center" justify="center" class="ma-1">
      <v-col cols="12" md="6" class="elevation-1" style="background-color: white; border-radius: 8px">
        <h1 class="mt-2 text-center text-h5 appblue--text font-weight-medium">Choose Your Package</h1>
        <div class="mt-2 text-center std-text--text font-weight-bold">You're almost there</div>

        <div class="mt-1 mb-4 text-center p-text grey-navy--text font-weight-medium">
          Choose the best option for you to kickstart your journey
        </div>

        <div class="justify-center text-center mt-xl-5">
          <div v-if="onceOffProductVariantPrice">
            <hero-display-card
              heading="Once-Off Challenge Pass"
              :sub-heading="`R ${parseInt(onceOffProductVariantPrice.price)} once-off`"
              :image="onceOffProductVariant.image"
              description="Get full access to your selected challenge, including all resources and support without purchasing a subscription. Perfect for those looking to commit to a single challenge and experience the transformation."
              :button-url="buttonUrl(onceOffProductVariant, true)"
            ></hero-display-card>
          </div>

          <div v-if="subscriptionProductVariantPrice && clubPrice" class="mt-8">
            <hero-display-card
              heading="Club Challenge Pass"
              :sub-heading="`R ${clubPrice} p/m for Club + R ${parseInt(
                subscriptionProductVariantPrice.price
              )} for Challenge Pass`"
              :image="subscriptionProductVariant.image"
              description="Enjoy all the benefits of the Challenge Pass along with a monthly JEFF Club subscription. Access ongoing support, additional workouts, nutrition plans, and our vibrant community."
              :button-url="buttonUrl(subscriptionProductVariant, false)"
            ></hero-display-card>
          </div>
        </div>

        <need-more-time />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { decodeId } from "@/lib/string";
import { PRODUCT_VARIANT } from "@/graphql/queries/store";
import NeedMoreTime from "@/components/shared/sections/NeedMoreTime";
import HeroDisplayCard from "@/components/shared/blocks/HeroDisplayCard";
import { START_PRODUCTS_QUERY } from "@/graphql/queries/start";

export default {
  name: "Purchase",
  components: {
    NeedMoreTime,
    HeroDisplayCard,
  },
  data() {
    return {
      //
    };
  },
  created() {
    this.productVariantId = this.$route.query.pvi;
    // ACTIVE_SUBSCRIPTION --> LOGGED IN
    if (this.$store.getters.isLoggedIn && this.$store.getters.subscription != null) {
      return this.$router.push(`/start/check`);
    }
  },
  computed: {
    block() {
      return this.productVariant?.blockSet?.edges[0]?.node;
    },
    subscriptionProductVariantPrice() {
      return this.subscriptionProductVariant?.productvariantpriceSet[0];
    },
    onceOffProductVariantPrice() {
      return this.onceOffProductVariant?.productvariantpriceSet[0];
    },
    subscriptionProductVariant() {
      return this.block?.productVariant;
    },
    onceOffProductVariant() {
      return this.block?.secondaryProductVariant;
    },
    clubPrice() {
      const variant = this.product?.productvariantSet?.edges
        .map((variant) => variant.node)
        ?.filter((variant) => variant.code == `standard-jeff-club`)[0];
      const price = variant?.productvariantpriceSet?.filter((set) => set.currency == "ZAR")[0];
      if (price) {
        return parseInt(price.price);
      }
      return null;
    },
  },
  methods: {
    buttonUrl(productVariant, isOnceOff) {
      if (isOnceOff) {
        return `/checkout/challenge-pass?pvi=${decodeId(productVariant.id)}`;
      }
      return `/checkout/premium-block?pvi=${decodeId(productVariant.id)}`;
    },
  },
  apollo: {
    product: {
      query: START_PRODUCTS_QUERY,
      variables() {
        return {
          code: "jeff-club",
        };
      },
      update(data) {
        return data.billingProducts?.edges?.map((product) => product.node)[0];
      },
    },
    productVariant: {
      query: PRODUCT_VARIANT,
      fetchPolicy: "cache-and-network",
      skip() {
        return !this.$route.query.pvi;
      },
      variables() {
        return {
          productVariantId: this.$route.query.pvi,
        };
      },
    },
  },
};
</script>
