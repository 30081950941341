import gql from "graphql-tag";

export const NOTIFICATIONS_QUERY = gql`
  query notifications($first: Int) {
    notifications(first: $first) {
      edges {
        node {
          id
          type
          description
          image
          dateNotify
          dateRead
          isUnread
          post {
            id
          }
        }
      }
    }
  }
`;
