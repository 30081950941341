import { ANSWER_MUTATION } from "@/graphql/mutations/survey";

export default {
  methods: {
    handleSave(answer = null, option = null) {
      const data = {
        subscriptionSurveyId: this.$store.getters.subscriptionSurveyId,
        surveyQuestionId: this.surveyQuestion.id,
        answer: answer,
        optionId: option ? option.id : null,
      };
      this.$apollo
        .mutate({
          mutation: ANSWER_MUTATION,
          variables: {
            data: data,
          },
        })
        .then((response) => {
          if (response.data.answerMutation.success) {
            this.$emit("answerSaved");
            this.$store.dispatch("replaceAnswer", {
              questionId: this.surveyQuestion.question.id,
              answer: option ? option.title : answer,
            });
          }
        });
    },
  },
};
