<template>
  <div>
    <v-row align="center" justify="center" class="pt-0 mt-0">
      <v-col cols="12" class="px-10 pt-0 mt-0 pb-10">
        <v-form class="px-0">
          <v-text-field
            class="px-0 mx-0"
            v-model="bookmarkSearch"
            name="search"
            outlined
            placeholder="Filter saved items"
            label="Search"
            autocomplete="off"
            hide-details
            clearable
            rounded
          >
          </v-text-field>
        </v-form>
      </v-col>
    </v-row>
    <v-row v-if="!loading && bookmarks.edges.length > 0" align="center" justify="center">
      <v-col>
        <v-row v-if="loading">
          <v-col cols="12" align="center" justify="center">
            <v-progress-circular indeterminate color="secondary" size="70" class="my-4"></v-progress-circular>
          </v-col>
        </v-row>
        <div v-else>
          <div v-if="!loading && bookmarks.edges.length > 0">
            <div v-for="item in bookmarks.edges" :key="item.node.id" class="text-left">
              <bookmark-event-card
                v-if="item.node.event"
                :showBookmark="true"
                :event="item.node.event"
                :includeLive="true"
                :includeTime="true"
                :bookmarkId="item.node.id"
              />
              <v-card v-if="item.node.contentResource" class="mb-3 card-radius dashboard-card">
                <div class="bookmark-button">
                  <manage-bookmark class="mt-n4" :bookmarkId="item.node.id" refreshQuery="bookmarks"></manage-bookmark>
                </div>
                <v-card-title
                  @click="viewRecipe(item.node.contentResource.file, locked(item.node.contentResource))"
                  class="recipe-text ml-4 navy--text p-text font-weight-bold"
                >
                  {{ item.node.contentResource.name }}
                </v-card-title>
              </v-card>
              
              <v-card v-if="item.node.recipe" class="mb-3 card-radius dashboard-card pointer">
                <v-card-title
                  class="p-text navy--text font-weight-bold"
                >
                  <div @click="navigateToRecipe(item.node.recipe.id)"><user-avatar :profile-picture="item.node.recipe.image" size="60" class="mr-4" /> {{ item.node.recipe.title }}</div>
                </v-card-title>
                <div class="bookmark-button">
                  <manage-bookmark class="mt-n6" :bookmarkId="item.node.id" refreshQuery="bookmarks"></manage-bookmark>
                </div>
              </v-card>
            </div>
          </div>
          <div v-else>
            <v-row align="center" justify="center" class="pt-0 mt-0">
              <v-col cols="12" md="7" class="px-10 pt-0 mt-0">
                <v-card v-if="search == null">
                  <v-card-title>Bookmark everything you want to find later.</v-card-title>
                </v-card>
                <v-card v-else>
                  <v-card-title>No results from search.</v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { BOOKMARKS_QUERY } from "@/graphql/queries/bookmark";
import ManageBookmark from "@/components/bookmarks/block/ManageBookmark";
import BookmarkEventCard from "@/components/bookmarks/BookmarkEventCard";
import UserMixin from "@/mixins/UserMixin";
import UserAvatar from "@/components/shared/user/UserAvatar";
import { decodeId } from "@/lib/string";

export default {
  name: "BookmarkHistory",
  mixins: [UserMixin],
  components: {
    BookmarkEventCard,
    ManageBookmark,
    UserAvatar
  },
  data() {
    return {
      loading: true,
      bookmarks: null,
      showUpSellModal: false,
      bookmarkSearch: "",
    };
  },
  methods: {
    navigateToRecipe(id) {
      this.$router.push(`/recipes/${decodeId(id)}`);
    },
    viewRecipe(url) {
      window.open(url, "_blank");
    },
    locked(event) {
      if (!this.inActive) {
        return null;
      }

      return !event.openAccess;
    },
  },
  apollo: {
    bookmarks: {
      query: BOOKMARKS_QUERY,
      variables() {
        return {
          userId: btoa(`UserNode: ${this.$store.getters.user.id}`),
          orderBy: "-created_at",
          search: this.bookmarkSearch,
        };
      },
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data) {
          this.loading = false;
        }
      },
    },
  },
};
</script>

<style scoped>
.recipe-text {
  max-width: 85%;
  text-align: left;
  line-height: 1.45;
  padding-left: 4px;
  cursor: pointer;
}

.bookmark-button {
  position: absolute;
  right: 15px;
  top: 25px;
}
</style>
