<template>
  <div>
    <v-skeleton-loader v-if="$apollo.loading" type="card,list-item-two-line,list-item-two-line" />
    <j-card
      v-else
      class="j-elevated-5 my-4"
      :class="{ 'highlight-post': highlightPost }"
      :style="highlightPost ? { 'border-left': `3px solid ${highlightColour}` } : null"
    >
      <v-card-text class="d-flex justify-space-between pb-0">
        <div class="d-flex flex-row">
          <user-avatar
            :profile-picture="post.user.userProfile.profilePicture"
            :user="post.user"
            size="40"
            :highlight-avatar="highlightPost"
            :highlight-colour="highlightColour"
          />
          <div>
            <router-link
              :to="profileLink(post.user)"
              class="subtitle-2 navy--text pl-3 block text-left text-decoration-none"
            >
              {{ post.user.firstName }} {{ post.user.lastName }}
              <user-type-highlight :user="post.user" />
            </router-link>
            <span class="caption navy--text pl-3 block text-left">
              <span v-if="hidePostLink"> {{ $moment(post.createdAt).fromNow() }} </span>
              <router-link v-else :to="{ name: 'Post', params: { postId: getId(post.id) } }">
                {{ $moment(post.createdAt).fromNow() }}
              </router-link>
              <template v-if="post.group && !groupId && !hideGroup">
                |
                <router-link
                  :to="groupLink(post.group)"
                  :class="{ 'text-decoration-none cursor-default': !groupLink(post.group) }"
                  >{{ post.group.title }}</router-link
                >
              </template>
              <template v-if="post.activity && activityLink(post.activity) && !hideGroup">
                | <router-link :to="activityLink(post.activity)"> {{ activityTitle(post.activity) }} </router-link>
              </template>
              <template v-if="post.event && eventLink(post.event) && !hideGroup">
                | <router-link :to="eventLink(post.event)">{{ post.event.name }}</router-link>
              </template>
              <template v-if="post.recipe && recipeLink(post.recipe) && !hideGroup">
                | <router-link :to="recipeLink(post.recipe)">{{ post.recipe.title }}</router-link>
              </template>
              <span v-if="edited" class="grey-navy--text text--lighten-1 ml-1"> (edited) </span>
            </span>
          </div>
        </div>
        <v-menu bottom left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small icon v-bind="attrs" v-on="on">
              <v-icon small>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              v-if="$store.getters.user && $store.getters.user.isStaff && groupId && allowPinned"
              link
              @click="handlePinAction('pin')"
            >
              <v-list-item-title>Pin post</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="$store.getters.user && $store.getters.user.isStaff && groupId && pinnedPost"
              link
              @click="handlePinAction('unpin')"
            >
              <v-list-item-title>Remove pinned post</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="post.user.id != $store.getters.user.id" link @click="reportPost()">
              <v-list-item-title>Report</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="post.user.id == $store.getters.user.id" link @click="editDialog = true">
              <v-list-item-title class="p-text">Edit Post</v-list-item-title>
            </v-list-item>
            <v-list-item v-if="post.user.id == $store.getters.user.id" link @click="deleteDialog = true">
              <v-list-item-title class="p-text">Delete Post</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-text>
      <v-card-text>
        <div class="flex-grow-1">
          <div class="pt-2 px-3 d-flex flex-column mb-4">
            <span
              class="body-2 text-left"
              ref="content"
              :class="{ 'post-body': !seeMore }"
              v-html="postContent(post.content)"
            />
            <span
              v-if="!seeMore && getHeightOverflowed"
              class="pointer navy--text font-weight-medium text-left"
              @click="seeMore = true"
              >...see more</span
            >
          </div>
        </div>

        <template v-if="post.postattachmentSet && post.postattachmentSet.length > 0">
          <div v-if="videoFile">
            <div class="video-post-container">
              <vue-plyr>
                <video v-if="videoFile.file" controls crossorigin playsinline>
                  <source size="600" :src="videoFile.file" type="video/mp4" />
                </video>
                <cld-video
                  v-else-if="videoFile.remoteFile"
                  cloud-name="jeff-fitness"
                  :public-id="videoFile.remoteFile"
                  controls
                  crossorigin
                  playsinline
                >
                  <cld-transformation video_codec="auto" width="600" crop="pad" />
                </cld-video>
              </vue-plyr>
            </div>
          </div>

          <div v-else @click="handleImageClick(post.url)" :class="{ pointer: post.url }">
            <div v-if="post.postattachmentSet.length == 1" class="d-flex child-flex">
              <v-img
                :src="post.postattachmentSet[0].file"
                max-height="300"
                contain
                aspect-ratio="1"
                class="rounded pointer"
                @click="openImage(post.postattachmentSet[0].file)"
              />
            </div>
            <carousel
              v-else-if="post.postattachmentSet.length > 1"
              :perPage="1"
              pagination-color="grey"
              pagination-active-color="navy"
            >
              <slide v-for="image in post.postattachmentSet" :key="image.id">
                <div class="d-flex child-flex">
                  <v-img
                    :src="image.file"
                    class="j-elevated-2 rounded pointer"
                    max-height="300"
                    contain
                    aspect-ratio="1"
                    @click="openImage(image.file)"
                  />
                </div>
              </slide>
            </carousel>
          </div>
        </template>
      </v-card-text>
      <v-divider />
      <v-card-actions class="mx-2">
        <v-spacer />

        <v-btn icon color="navy" @click="showComment = !showComment">
          <v-icon small>mdi-comment-outline</v-icon>
        </v-btn>
        <span class="p-text mr-2 ml-n1">{{ post.commentCount }}</span>

        <post-like :userPostLike="post.userPostLike" :postId="post.id" @likeUpdate="handleLike" />
        <span class="p-text ml-n1">{{ post.likeCount }}</span>
      </v-card-actions>
      <v-card-text v-if="showComment" class="pt-0">
        <post-comment
          :postId="post.id"
          :commentCount="post.commentCount"
          @commentUpdate="handleComment"
          :featuredUserIds="featuredUserIds"
          :highlightColour="highlightColour"
        />
      </v-card-text>
    </j-card>
    <v-dialog v-model="pinDialog" rounded width="500">
      <v-card>
        <v-card-title class="headline-2 grey lighten-2">
          {{
            pinAction == "unpin"
              ? "Are you sure you want to remove this post from being featured?"
              : "Please confirm if you want to pin this post? Any past featured posts will be unpinned."
          }}
        </v-card-title>

        <v-divider />
        <v-card-actions>
          <v-btn color="secondary" @click="pinDialog = null"> Cancel </v-btn>
          <v-spacer />
          <v-btn
            color="background-grey"
            class="secondary--text pa-2"
            @click="pinAction == 'unpin' ? removedPinnedPost() : pinPost()"
          >
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" width="500" id="edit-dialog">
      <j-card v-if="editDialog" class="post-block-edit-card">
        <v-toolbar class="mb-6">
          <v-toolbar-title>Edit post</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="editDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <post-form
          key="post-block-post-form"
          :group-id="post.group ? post.group.id : null"
          :activity-id="post.activity ? post.activity.id : null"
          :event-id="post.event ? post.event.id : null"
          :recipe-id="post.recipe ? post.recipe.id : null"
          :post-id="post.id"
          @saved="updateSaved"
          class="post-block-form pa-4 pt-0"
        />
      </j-card>
    </v-dialog>

    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="headline-2 grey lighten-2"> Are you sure you want to delete this post? </v-card-title>

        <v-divider />
        <v-card-actions>
          <v-btn color="secondary" @click="deleteDialog = false"> Cancel </v-btn>
          <v-spacer />
          <v-btn color="background-grey" class="secondary--text pa-2" @click="deletePost()"> Confirm </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="selectedImage"
      v-model="openImageDialog"
      max-width="800"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <j-card rounded="lg">
        <v-card-text>
          <div class="d-flex justify-end">
            <v-btn icon @click="openImageDialog = false" class="mr-n6">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-img class="rounded-lg" :src="selectedImage" contain />
        </v-card-text>
      </j-card>
    </v-dialog>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import {
  CREATE_MODERATION_MUTATION,
  DELETE_POST_MUTATION,
  PIN_POST_TO_GROUP_MUTATION,
  REMOVE_PINNED_POST_MUTATION,
} from "@/graphql/mutations/group";
import { Carousel, Slide } from "vue-carousel";
import { decodeId, nl2br } from "@/lib/string";
import PostLike from "@/components/groups/interactions/PostLike";
import PostComment from "@/components/groups/interactions/PostComment";
import UserAvatar from "@/components/shared/user/UserAvatar";
import ProfileLinkMixin from "@/mixins/ProfileLinkMixin";
import PostForm from "@/components/groups/forms/PostForm";
import UserTypeHighlight from "@/components/groups/UserTypeHighlight";

export default {
  name: "PostBlock",
  mixins: [ProfileLinkMixin],
  components: {
    PostLike,
    PostComment,
    Carousel,
    Slide,
    UserAvatar,
    PostForm,
    UserTypeHighlight,
  },
  props: {
    post: {
      type: Object,
      required: true,
    },
    groupId: {
      type: [String, Number],
      required: false,
    },
    showCommentOnLoad: {
      type: Boolean,
      default: false,
    },
    featuredUserIds: {
      type: Array,
      required: false,
    },
    highlightColour: {
      type: String,
      default: "#211256",
    },
    hidePostLink: {
      type: Boolean,
      default: false,
    },
    hideGroup: {
      type: Boolean,
      default: false,
    },
    allowPinned: {
      type: Boolean,
      default: false,
    },
    pinnedPost: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showComment: false,
      editDialog: false,
      deleteDialog: false,
      openImageDialog: false,
      selectedImage: null,
      seeMore: false,
      contentHeight: null,
      pinDialog: null,
      pinAction: null,
    };
  },
  created() {
    this.showComment = this.showCommentOnLoad;
  },
  mounted() {
    if (this.contentHeight != this.$refs.content?.clientHeight)
      this.contentHeight = this.$refs.content?.clientHeight ?? 0;
  },
  watch: {
    post: {
      handler(val) {
        // Force the video to change if updated
        if (this.post && (this.post?.postattachmentSet || val.postattachmentSet)) {
          this.post.postattachmentSet = val.postattachmentSet;
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["selectedDate"]),
    encodedPostId() {
      return window.btoa("PostNode:" + this.post.id);
    },
    videoFile() {
      return this.post?.postattachmentSet?.find((element) => element.type === "VIDEO");
    },
    highlightPost() {
      if (this.post?.user?.userProfile?.userType === "INSTRUCTOR") {
        return true;
      }
      return this.featuredUserIds?.includes(this.post.user.id);
    },
    getHeightOverflowed() {
      return (this.contentHeight ?? 0) >= 140;
    },
    edited() {
      return (
        this.$moment(this.post.createdAt).format("YYYY-MM-DD hh:mm") !=
        this.$moment(this.post.updatedAt).format("YYYY-MM-DD hh:mm")
      );
    },
  },
  methods: {
    getId(idString) {
      return decodeId(idString);
    },
    postContent(content) {
      const tagged = this.$options.filters.replaceMentionsWithHref(content);
      return nl2br(this.$options.filters.replaceLinksWithHref(tagged));
    },
    openImage(image) {
      this.selectedImage = image;
      this.openImageDialog = true;
    },
    handleImageClick(url) {
      if (!url) {
        return false;
      }
      //this.$router.push(url);
      // safer to just change location. The URL is most likely absolute, which makes router.push freak out?
      window.location.href = url;
    },
    groupLink(group) {
      if (group.workoutBlock?.visible === false) {
        return "";
      }
      if (group?.workoutBlock?.id) {
        return `/program/${decodeId(group.workoutBlock.id)}`;
      }
      if (group?.nutritionBlock?.id) {
        return `/nutrition/${decodeId(group.nutritionBlock.id)}`;
      }
      return `/group/${decodeId(group.id)}`;
    },
    activityTitle(activity) {
      const blockTitle = activity.unitactivitySet[0]?.unit?.block?.title;
      if (blockTitle) {
        return `${blockTitle} - ${activity.title}`;
      }
      return activity.title;
    },  
    activityLink(activity) {
      const activeBlockIds = this.$store.getters.activeSubscriptionWorkoutBlocks?.map((el) => {
        return decodeId(el.block.id);
      });
      let matchedBlockId;
      let matchedUnitActivity;

      // find the first matching unitActivity belong to a block that is active for the user.
      if (activity?.unitactivitySet) {
        for (const unitActivity of activity?.unitactivitySet) {
          let blockId = decodeId(unitActivity.unit.block.id);
          if (activeBlockIds.includes(blockId)) {
            matchedBlockId = blockId;
            matchedUnitActivity = unitActivity;
            break;
          }
        }
      }
      // now find the matching subscription block for the above matched unit activity
      const matchedSubscriptionBlock = this.$store.getters.activeSubscriptionWorkoutBlocks?.find(
        (obj) => decodeId(obj.block.id) == decodeId(matchedBlockId)
      );

      // now that we have the variables, create the link.
      if (matchedUnitActivity && matchedSubscriptionBlock) {
        const date = this.selectedDate ? this.selectedDate : this.$moment();
        return `/unit-activity/${matchedUnitActivity.id}?sbid=${decodeId(matchedSubscriptionBlock.id)}&d=${date.format(
          "YYYY-MM-DD"
        )}`;
      }
      return null;
    },
    eventLink(event) {
      return `/event/${decodeId(event.id)}`;
    },
    recipeLink(recipe) {
      return `/recipes/${decodeId(recipe.id)}`;
    },
    async reportPost() {
      await this.$apollo.mutate({
        mutation: CREATE_MODERATION_MUTATION,
        variables: {
          id: decodeId(this.post.id),
          type: "POST",
        },
      });
      Vue.toasted.success(`The post has been reported`);
    },
    async pinPost() {
      await this.$apollo.mutate({
        mutation: PIN_POST_TO_GROUP_MUTATION,
        variables: {
          groupId: this.groupId,
          postId: decodeId(this.post.id),
        },
        refetchQueries: ["group"],
      });
      this.pinDialog = false;
      this.pinAction = null;
      setTimeout(() => {
        window.scrollTo({
          top: 400,
          left: 0,
          behavior: "smooth",
        });
      }, 200);
    },
    async removedPinnedPost() {
      await this.$apollo.mutate({
        mutation: REMOVE_PINNED_POST_MUTATION,
        variables: {
          groupId: this.groupId,
          postId: decodeId(this.post.id),
        },
        refetchQueries: ["group"],
      });
      this.pinDialog = false;
      this.pinAction = null;
    },
    async deletePost() {
      if (this.deleteDialog) {
        await this.$apollo.mutate({
          mutation: DELETE_POST_MUTATION,
          variables: {
            id: decodeId(this.post.id),
          },
          refetchQueries: ["posts"],
        });
        this.deleteDialog = false;
        this.$emit("deleted", decodeId(this.post.id));
      }
    },
    handleLike(data) {
      if (data.type == "delete") {
        this.post.userPostLike = null;
        this.post.likeCount -= 1;
      } else {
        this.post.userPostLike = {
          id: data.id,
        };
        this.post.likeCount += 1;
      }
    },
    handleComment(data) {
      if (data.type == "delete") {
        this.post.commentCount -= 1;
      } else {
        this.post.commentCount += 1;
      }
    },
    handlePinAction(pinAction) {
      this.pinDialog = true;
      this.pinAction = pinAction;
    },
    updateSaved() {
      this.editDialog = false;
      this.$emit("reload");
    },
  },
};
</script>

<style lang="scss" scoped>
.post-body-parent {
  min-height: 100px;
  max-height: 140px;
  overflow: hidden;
}
.post-body {
  max-height: 140px;
  overflow: hidden;
}
</style>

<style lang="scss">
.video-post-container .plyr:not(:fullscreen) video {
  max-height: 350px;
}

.post-block-edit-card .post-block-form .j-textarea .emoji-picker {
  position: fixed;
}
</style>
