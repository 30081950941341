<template>
  <v-container>
    <div class="text-center ma-12" v-if="loading">
      <v-progress-circular
        :indeterminate="loading"
        color="blue"
      ></v-progress-circular>
    </div>
    <div v-else>
      <router-link class="subtitle-1 grey-navy--text font-weight-bold" style="text-decoration: none;" to="/on-demand">
        <v-icon small class="pb-1">
          keyboard_backspace
        </v-icon>Library
      </router-link>
      <v-row align="center" justify="center">
        <v-col cols="12" md="6">
          <v-list three-line>
            <v-list-item
            :key="guidedRun.id"
            rounded
            >
              <v-list-item-avatar size="80">
                <v-img v-if="guidedRun.coach && guidedRun.coach.userProfile.profilePicture != ''" :src="guidedRun.coach.userProfile.profilePicture"/>
                <v-img v-if="guidedRun.category === 'MEDITATION'" :src="require('@/assets/images/guided-meditation.png')"/>
                <v-img v-else :src="require('@/assets/images/red-shoes.png')"/>
              </v-list-item-avatar>
              <v-list-item-content three-line>
                <v-list-item-title v-html="guidedRun.name"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col cols="12" md="6">
          <v-card>
            <v-tabs
              v-model="tab"
              centered
              background-color="tabs"
              color="secondary"
              active-class="selected-tab-active"
              slider-size="0"
              class="selected-tabs-active"
            >
              <v-tab key="view-session">
                Do this session
              </v-tab>
              <v-tab key="log-workout">
                Log a session
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item key="view-session">
                <v-card>
                  <v-card-text class="pa-3" align="center" justify="center">
                    <vue-plyr
                      :emit="['play', 'ended', 'pause']"
                      :options="{'controls': ['play', 'progress', 'current-time', 'mute', 'volume', 'pip', 'airplay']}"
                      @ended="ended"
                      @play="play"
                      @pause="pause">
                      <audio :src="guidedRun.file" type="audio/mpeg">
                      </audio>
                    </vue-plyr>
                    <v-btn
                      color="secondary"
                      class="my-2"
                      large
                      rounded
                      :loading="downloading"
                      min-width="200px"
                      @click="download(guidedRun.file, guidedRun.name)"
                    >
                      <strong>Download</strong>
                      <template v-slot:loader>
                        <strong class="mr-4">Downloading </strong> <v-progress-circular :size="15" indeterminate></v-progress-circular>
                      </template>
                    </v-btn>
                    <div v-if="downloading" class="mt-4">
                      <v-progress-linear
                        color="navy"
                        height="15"
                        rounded
                        :value="percentCompleted"
                      ></v-progress-linear>
                    </div>
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item key="log-workout">
                <v-card flat>
                  <v-card-text>
                    <ActivityForm :fullForm="showFullForm" workoutType="run" :eventProperties="eventProperties" :workoutId="$route.params.guidedRunId" />
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
  import { GUIDED_RUN_QUERY } from '@/graphql/queries/guidedRuns';
  import ActivityForm from '@/components/tracker/activity-form/ActivityForm';
  import { DiscoveryVideoTracking } from "@/lib/discovery_video_tracking";
  import FileDownloadMixin from '@/mixins/FileDownloadMixin'; 

  export default {
    name: 'GuidedRun',
    mixins: [FileDownloadMixin],
    components: {
      ActivityForm
    },
    data: () => ({
      guidedRun: {},
      loading: true,
			tab:'',
      videoTracking: null
    }),
    beforeRouteLeave(to, from, next){
      if (this.videoTracking)
        this.videoTracking.stopTracking();
      next();
    },
    mounted () {
      window.analytics.page('Guided Run')
    },
    computed: {
      getUserActivityId() {
        return this.guidedRun.useractivitySet.edges[0] ? this.guidedRun.useractivitySet.edges[0].node.id : '0'
      },
      eventProperties() {
        return {
          workout_id: this.guidedRun.id,
          name: this.guidedRun.name,
          type: 'Guided Run',
          category: 'exercise',
          custom: false,
        }
      },
      showFullForm() {
        return this.guidedRun.category.toLowerCase() !== 'meditation';
      },
    },
    methods: {
      play() {
        this.videoTracking.startTracking();
        this.logEvent('Audio Played')
      },
      ended() {
        this.videoTracking.stopTracking();
        this.logEvent('Audio Ended')
      },
      pause() {
        this.videoTracking.stopTracking();
        this.logEvent('Audio Paused')
      },
      logEvent(eventName) {
        window.analytics.track(eventName, {
          name: this.guidedRun.name,
          type: 'Guided Run',
        })
      },
    },
    apollo: {
      guidedRun: {
        query: GUIDED_RUN_QUERY,
        variables() {
          return {
            id: this.$route.params.guidedRunId,
          }
        },
        fetchPolicy: 'cache-and-network',
        result(response) {
          if (response.data) {
            this.videoTracking = new DiscoveryVideoTracking(this.$apollo, this.guidedRun.id, "guidedRun");
            this.loading = false;
          }
        }
      }
    },
  }
</script>

<style>
.selected-tab-active {
  color: #211256 !important;
  border: 1px solid #dadae2;
  border-bottom: none;
  border-top: 3px solid #211256;
  border-radius: 0;
  background: white;
}
</style>