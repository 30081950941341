import gql from "graphql-tag";

export const LEADER_BOARD_QUERY = gql`
  query leaderboard($leaderboardId: ID!, $startDate: Date, $endDate: Date) {
    leaderboard(leaderboardId: $leaderboardId, startDate: $startDate, endDate: $endDate) {
      id
      title
      logo
      stats
      startDate
      endDate
    }
  }
`;

export const GYM_LEADER_BOARD_QUERY = gql`
  query gymBranchesLeaderboard($startDate: Date!, $endDate: Date!) {
    gymBranchesLeaderboard(startDate: $startDate, endDate: $endDate)
  }
`;

export const FRIENDS_LEADER_BOARD_QUERY = gql`
  query friendsLeaderboard($startDate: Date, $endDate: Date) {
    friendsLeaderboard(startDate: $startDate, endDate: $endDate)
  }
`;

export const USER_LEADER_BOARDS_QUERY = gql`
  query myActiveLeaderboards {
    myActiveLeaderboards {
      id
      title
      logo
      startDate
      endDate
    }
  }
`;