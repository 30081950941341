<template>
  <checkin-stepper :step="7" :title="{ text: 'Earn', strongText: 'rewards points!' }" @next="next">
    <div class="text-left">
      <j-card class="j-elevated-1 pa-1 mx-3 my-5">
        <v-card-title class="grey-navy--text">
          <strong>Use rewards points on discounts and vouchers!</strong>
        </v-card-title>
        <v-card-text>
          Earn points from your involvement in the {{ $appName }} community and redeem your points for epic rewards!
        </v-card-text>
      </j-card>

      <j-card class="j-elevated-1 pa-1 mx-3 my-5">
        <v-card-title class="grey-navy--text">
          <strong>Earn your first points. Invite your friends to join for free!</strong>
        </v-card-title>
        <v-card-text>
          Spread the {{ $appName }} love - invite your friends to create a free profile and join you - and you can claim
          rewards points!
        </v-card-text>
        <v-card-actions class="mb-3">
          <v-spacer />
          <j-btn wide color="whatsapp-green" target="_blank" :href="whatsappShareUrl">
            <strong>Invite now via whatsapp</strong>
            <v-icon class="ml-2">mdi-whatsapp</v-icon>
          </j-btn>
          <v-spacer />
        </v-card-actions>
      </j-card>
    </div>
  </checkin-stepper>
</template>

<script>
import { get_register_share_link } from "@/lib/whatsapp";
import CheckinStepper from "@/components/checkin/CheckinStepper";

export default {
  name: "Rewards",
  components: {
    CheckinStepper,
  },
  computed: {
    whatsappShareUrl() {
      return get_register_share_link(this.$store.getters.user?.userProfile?.inviteFriendDiscountCode, this.$appName, this.$store.getters.settings.get("REWARDS")?.get('invite-friend-url'));
    },
  },
  methods: {
    next(nextStepLink) {
      return this.$router.push(nextStepLink);
    },
  },
};
</script>
