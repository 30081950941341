<template>
  <j-card class="pa-2">
    <div class="d-flex">
      <v-card-title v-if="edit" class="navy--text">Edit daily habit</v-card-title>
      <v-card-title v-else class="navy--text">New daily habit</v-card-title>
      <v-spacer />
      <v-icon @click="$emit('close')" class="px-4">mdi-close</v-icon>
    </div>
    <v-card-text class="pb-0">
      <j-text-field
        v-if="workingHabit.habitOption == null"
        v-model="workingHabit.title"
        dense
        label="Type your habit here..."
        hide-details
        class="mb-2"
        @keypress="showAlert = false"
        :error-messages="showAlert ? 'Missing title' : null"
      />
      <j-select
        v-model="workingHabit.habitOption"
        :items="habitOptions"
        dense
        clearable
        label="Or select a habit here..."
        @change="showAlert = false"
      />
      <div v-if="!edit">
        <p>Your new habit will start tracking from today.</p>
        <p v-if="previousHabit">
          Your previous habit will be deactivated, however you'll still be able to see your completion record.
        </p>
      </div>
    </v-card-text>
    <v-card-actions class="justify-center d-flex flex-column">
      <j-alert v-if="showAlert" type="error" dense class="mb-4">
        Please describe your habit or choose one from our habit options
      </j-alert>
      <j-btn secondary class="mb-2" @click="upsertHabit()">
        <div v-if="edit">Save</div>
        <div v-else>Start new habit</div>
      </j-btn>
    </v-card-actions>
  </j-card>
</template>

<script>
import { HABIT_OPTIONS_QUERY } from "@/graphql/queries/habits";
import { UPSERT_HABIT_MUTATION } from "@/graphql/mutations/habits";
import { decodeId } from "@/lib/string";

export default {
  name: "HabitDialog",
  data() {
    return {
      showAlert: false,
      workingHabit: {},
    };
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
    habit: {
      type: Object,
      required: true,
    },
    previousHabit: {
      type: Boolean,
      default: false
    }
  },
  created() {
    this.workingHabit = this.habit;
    this.workingHabit.habitOption = this.habit.habitOption?.id;
  },
  methods: {
    upsertHabit() {
      if (!this.workingHabit.title && !this.workingHabit.habitOption) {
        this.showAlert = true;
      } else {
        this.showAlert = false;
        let data = {
          habitId: this.edit ? decodeId(this.workingHabit?.id) : null,
          title: this.workingHabit.habitOption ? null : this.workingHabit.title,
          colour: this.workingHabit.color,
          startDate: this.edit ? this.workingHabit.startDate : this.$moment().format("YYYY-MM-DD"),
          optionId: this.workingHabit.habitOption,
        };

        this.$apollo
          .mutate({
            mutation: UPSERT_HABIT_MUTATION,
            variables: data,
            refetchQueries: ["habitsQuery"],
          })
          .then(() => {
            this.$emit("close");
          });
      }
    },
  },
  apollo: {
    habitOptions: {
      query: HABIT_OPTIONS_QUERY,
      fetchPolicy: "no-cache",
      update(data) {
        return data.habitOptionsQuery?.map((option) => {
          return {
            value: option.id,
            text: option.title,
          };
        });
      },
    },
  },
};
</script>
