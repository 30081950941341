<template>
  <v-row v-if="loading" align="center" justify="center">
    <v-skeleton-loader type="avatar@7" class="d-flex" />
  </v-row>
  <v-container v-else class="mb-4 calendar-dots">
    <v-row align="center" justify="center">
      <v-col class="pb-0 relative mt-n2">
        <v-icon color="secondary" @click="updateWeek(-1)" class="navigate-week left">keyboard_arrow_left</v-icon>
        <v-icon @click="updateWeek(1)" color="secondary" class="navigate-week right">keyboard_arrow_right</v-icon>
        <v-tabs
          v-if="!loading"
          v-model="dayTab"
          :centered="true"
          :icons-and-text="true"
          color="secondary"
          slider-size="0"
        >
          <v-tab
            v-for="(key, index) in days"
            :key="index"
            :icons-and-text="true"
            class="py-2 mb-0 pl-1 pr-1 pr-sm-2 day-tab"
            align="start"
            @click.native="changeDate(index)"
          >
            <div class="d-flex mw-40 pt-1 flex-wrap">
              <v-icon v-if="isRestDay(key.date)" color="rest" class="notification-icon" x-small>
                fiber_manual_record
              </v-icon>
              <v-icon v-if="isMindsetDay(key.date)" color="mindset" class="notification-icon" x-small>
                fiber_manual_record
              </v-icon>
              <v-icon v-if="habitColor(key.date)" :color="habitColor(key.date)" class="notification-icon" x-small>
                fiber_manual_record
              </v-icon>
              <v-icon v-if="isWorkoutBlockMindsetDay(key.date)" color="yellow" class="notification-icon" x-small>
                fiber_manual_record
              </v-icon>
              <v-icon
                v-if="nutritionColor(key.date) || isNutritionBlockLogDay(key.date)"
                :color="isNutritionBlockLogDay(key.date) ? 'nutrition' : nutritionColor(key.date)"
                class="notification-icon"
                x-small
              >
                fiber_manual_record
              </v-icon>
              <v-icon v-if="sleepColor(key.date)" :color="sleepColor(key.date)" class="notification-icon" x-small>
                fiber_manual_record
              </v-icon>
              <v-icon
                v-if="hydrationColor(key.date)"
                :color="hydrationColor(key.date)"
                class="notification-icon"
                x-small
              >
                fiber_manual_record
              </v-icon>
              <v-icon
                v-for="blockActivity in getBlockActivities(key.date)"
                color="exercise"
                :key="blockActivity.node.id"
                class="notification-icon"
                x-small
              >
                fiber_manual_record
              </v-icon>
              <v-icon
                v-for="activity in getActivities(key.date)"
                :color="getNotificationColor(activity)"
                :key="activity.id"
                class="notification-icon"
                x-small
              >
                fiber_manual_record
              </v-icon>
            </div>

            <div>
              <v-avatar
                class="elevation-2 caption navy--text"
                :class="[
                  dayTab == index ? accentColor : '',
                  { 'today-border': formatDate(key.date) == formatDate(new Date()) },
                ]"
                :style="borderColorStyle"
                size="30"
                outlined
              >
                <span :class="{ 'white--text': dayTab == index }">
                  {{ key.date.getDate() }}
                </span>
              </v-avatar>
              <div class="pt-2 blue--text">
                {{ getDay(key.date) }}
              </div>
            </div>
          </v-tab>
        </v-tabs>
        <v-row>
          <v-col>
            <h2 class="std-text grey-navy--text font-weight-regular text-center mb-n6">
              {{ getSelectedDate }}
            </h2>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ACTIVITIES_AND_NUTRITION_QUERY } from "@/graphql/queries/tracker";
import { getDayOfWeekString } from "@/lib/timezone";
import { mapGetters, mapActions } from "vuex";
import CalendarDotMixin from "@/mixins/CalendarDotMixin";

export default {
  name: "CalendarDots",
  mixins: [CalendarDotMixin],
  props: {
    accentColor: {
      type: String,
      required: false,
      default: "exercise",
    },
  },
  computed: {
    ...mapGetters([
      "restDays",
      "sleepDays",
      "hydrationDays",
      "mindsetDays",
      "habitEntryDays",
      "nutritionDays",
      "blockWorkoutLogDays",
      "blockNutritionLogDays",
      "selectedDate",
    ]),
  },
  methods: {
    ...mapActions([
      "setRestDays",
      "setSleepDays",
      "setHydrationDays",
      "setMindsetDays",
      "setHabitEntryDays",
      "setNutritionDays",
      "setBlockWorkoutLogDays",
      "setBlockNutritionLogDays",
      "setSelectedDate",
    ]),
    changeDate(index) {
      this.dayTab = index;
      let newDate = this.$moment(this.week, "YYYY-MM-DD")
        .startOf("week")
        .add(index + 1, "days");
      this.setSelectedDate(newDate);
      this.$emit("dateUpdated", newDate);
    },
    setDay() {
      var today = new Date();
      var day = today.getDay();
      this.setSelectedDate(this.$moment());

      this.dayTab = day - (day == 0 ? -6 : 1);
    },
    getActivities(date) {
      if (this.weekActivities && this.weekActivities.edges) {
        return this.weekActivities.edges.filter((activity) => {
          return activity.node.startDate.substr(0, 10) === this.formatDate(date);
        });
      } else {
        return [];
      }
    },
    isRestDay(date) {
      let formattedDate = this.formatDate(date);
      return this.restDays.some((day) => day.restDayDate == formattedDate && day.didRest);
    },
    sleepColor(date) {
      let formattedDate = this.formatDate(date);
      const sleep = this.sleepDays.find((day) => day.node.date == formattedDate);
      if (!sleep) {
        return null;
      }
      if (sleep.node.compliantDay) {
        return "sleep";
      }
      return "grey";
    },
    hydrationColor(date) {
      let formattedDate = this.formatDate(date);
      const hydration = this.hydrationDays.find((day) => day.node.date == formattedDate);
      if (!hydration) {
        return null;
      }

      return "hydration";
      // no, if you've logged, we will give you a blue dot!
      // if (hydration.node.compliantDay) {
      //   return "hydration";
      // }
      // return "grey";
    },
    isMindsetDay(date) {
      let formattedDate = this.formatDate(date);
      return this.mindsetDays.some((day) => day.node.date == formattedDate);
    },
    habitColor(date) {
      let formattedDate = this.formatDate(date);
      const habit = this.habitEntryDays.find((day) => day.node.date === formattedDate);
      if (!habit) {
        return null;
      }
      if (habit.node.status) {
        return "mindset";
      }
      return "grey";
    },
    nutritionColor(date) {
      let formattedDate = this.formatDate(date);
      const nutrition = this.nutritionDays.find((day) => day.node.date === formattedDate);
      if (!nutrition) {
        return null;
      }
      if (nutrition.node.isNutritiousDay) {
        return "nutrition";
      }
      return "grey";
    },
    isNutritionBlockLogDay(date) {
      let formattedDate = this.formatDate(date);
      return this.blockNutritionLogDays.some((day) => day.node.date === formattedDate);
    },
    getBlockActivities(date) {
      let formattedDate = this.formatDate(date);
      return this.blockWorkoutLogDays.filter(
        (day) => day.node.date === formattedDate && day.node.unitActivity.type === "WORKOUT"
      );
    },
    isWorkoutBlockMindsetDay(date) {
      let formattedDate = this.formatDate(date);
      return this.blockWorkoutLogDays.some(
        (day) =>
          day.node.date == formattedDate &&
          (day.node.unitActivity.type == "MODULE" || day.node.unitActivity.type == "ED")
      );
    },
    getNotificationColor(activity) {
      if (activity.node.event) {
        return activity.node.event.eventType.category.toLowerCase();
      } else if (activity.node.unitActivity) {
        if (['WORKOUT', 'LIVE_EVENT'].includes(activity.node.unitActivity.type))
          return "exercise";
        else if (activity.node.unitActivity.type === 'ED')
          return "mindset";
        else if (activity.node.unitActivity.type === 'REST_DAY')
          return "other";
      } else if (activity.node.workout) {
        return "exercise";
      } else if (activity.node.guidedRun) {
        return activity.node.guidedRun.category.toLowerCase() == "meditation" ? "mindset" : "exercise";
      } else if (activity.node.isCustom && activity.node.type) {
        return activity.node.type.category.toLowerCase();
      } else if (activity.node.action) {
        return "exercise";
      } else {
        return "other";
      }
    },
  },
  apollo: {
    weekActivities: {
      query: ACTIVITIES_AND_NUTRITION_QUERY,
      variables() {
        return {
          startDate: getDayOfWeekString(1, false, this.week, false),
          endDate: getDayOfWeekString(7, true, this.week, false),
          userId: btoa(`UserNode: ${this.$store.getters.user.id}`),
        };
      },
      fetchPolicy: "network-only",
      result(response) {
        if (response.loading) return;

        if (response.data) {
          // set logs in Vuex
          this.setRestDays(response.data.restDays.edges);
          this.setSleepDays(response.data.sleepLog.edges);
          this.setHydrationDays(response.data.hydrationLog.edges);
          this.setMindsetDays(response.data.mindsetLog.edges);
          this.setHabitEntryDays(response.data.habitEntriesQuery.edges);
          this.setNutritionDays(response.data.nutritionLog.edges);
          this.setBlockWorkoutLogDays(response.data.workoutBlockWorkoutLogs.edges);
          this.setBlockNutritionLogDays(response.data.nutritionBlockNutritionLogs.edges);
          this.loading = false;
        }
      },
      update(data) {
        return data.activities;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.calendar-dots .v-tabs.theme--light.v-tabs--centered > .v-item-group.theme--light.v-tabs-bar {
  background-color: black;
  display: none;
  .v-slide-group__prev {
    background-color: black;
    display: none;
    visibility: none;
  }
}
</style>

<style lang="scss">
.calendar-dots .v-item-group {
  height: 100%;
}
</style>
