export default {
  methods: {
    getBunnyMp4Url(url) {
      // replace
      // https://vz-05d39b7e-10d.b-cdn.net/fce94ffc-12d1-4177-92df-514dbcec3663/playlist.m3u8
      // with
      // https://vz-05d39b7e-10d.b-cdn.net/fce94ffc-12d1-4177-92df-514dbcec3663/play_720p.mp4
      const segments = url.split("/");
      segments[segments.length - 1] = "play_720p.mp4";
      const newUrl = segments.join("/");
      return newUrl;
    },
  },
};
