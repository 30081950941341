<template>
  <j-banner-container header="Exercise">
    <calendar-dots accent-color="navy"/>
    <today-workout class="mx-4 mt-n6" />
  </j-banner-container>
</template>

<script>
import TodayWorkout from "@/components/shared/sections/TodayWorkout";
import CalendarDots from "@/components/shared/blocks/CalendarDots";

export default {
  name: "TodayExercise",
  components: {
    TodayWorkout,
    CalendarDots,
  },
};
</script>
