<template>
  <div class="d-flex justify-center align-center">
    <div>
      <a href="https://apps.apple.com/vn/app/jeff-fitness/id6470368710" target="_blank"
        ><v-img :src="require('@/assets/images/appstore.png')" max-width="300"></v-img
      ></a>

      <a href="https://play.google.com/store/apps/details?id=fitness.jeff.jeffnative&hl=en_ZA&gl=US" target="_blank"
        ><v-img :src="require('@/assets/images/playstore.png')" max-width="300"></v-img
      ></a>

      <a href="https://appgallery.huawei.com/#/app/C109805715" target="_blank"
        ><v-img :src="require('@/assets/images/huaweistore.png')" max-width="300"></v-img
      ></a>
    </div>
  </div>
</template>

<script>
export default {
  name: "NativeAppIcons",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped></style>
