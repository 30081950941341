<template>
  <div>
    <h3 class="mb-2">{{ surveyQuestion.question.title }} <span v-if="surveyQuestion.isRequired"> * </span></h3>

    <v-textarea outlined v-model="answer" placeholder="Type your answer" @input="handleSave(answer)" />

    <div v-for="child in surveyQuestion.children" :key="child.id" class="my-4">
      <question-block :surveyQuestion="child" />
    </div>
  </div>
</template>

<script>
import SurveyMixin from "@/mixins/SurveyMixin";

export default {
  name: "FreeTextControl",
  mixins: [SurveyMixin],
  props: {
    surveyQuestion: {
      required: true,
    },
  },
  data() {
    return {
      answer: null,
    };
  },
  methods: {},
};
</script>
