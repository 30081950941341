<template>
  <v-container>
    <v-row v-if="hasProductCode" align="center" justify="center">
      <v-col cols="12" md="6" sm="8" align="center" justify="center" class="pb-0">
        <product-title-block />
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" sm="8">
        <v-card class="card-radius mt-0">
          <v-row class="mt-0 pt-0">
            <v-col class="py-0">
              <v-card-text>
                <div class="d-flex">
                  <h2 class="secondary--text">Log in</h2>
                  <v-spacer />
                  <span class="font-16 secondary--text">
                    or
                    <j-btn narrow class="ml-1" @click="registerRedirect">Create Profile</j-btn>
                  </span>
                </div>
                <login-form />
              </v-card-text>
            </v-col>
          </v-row>
          <v-card-actions class="px-4">
            <v-row>
              <v-col cols="12">
                <a href="/account/forgot-password" class="secondary--text font-weight-bold body-2 text-decoration-none"
                  >Forgot Password?</a
                >
              </v-col>
              <v-col cols="12" class="pa-0">
                <v-divider />
              </v-col>
              <v-col cols="12">
                <a
                  href="https://help.fitnessfaculty.club/help-centre/"
                  class="secondary--text font-weight-bold body-2 text-decoration-none"
                  >Help Centre
                </a>
                <span class="px-2 grey--text text--darken-2">•</span>
                <a href="https://bit.ly/jeff_help" class="secondary--text font-weight-bold body-2 text-decoration-none"
                  >Contact Support
                </a>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { BILLING_PRODUCTS_QUERY } from "@/graphql/queries/store";
import ProductTitleBlock from "@/components/store/blocks/ProductTitleBlock.vue";
import LoginForm from "@/components/authentication/blocks/LoginForm";

export default {
  name: "LoginCheckout",
  components: {
    ProductTitleBlock,
    LoginForm,
  },
  computed: {
    hasProductCode() {
      return this.$route.query.code;
    },
  },
  methods: {
    registerRedirect() {
      let registerCheckout = {
        name: "RegisterCheckout",
        query: {
          code: this.$route.query.code,
        },
      };

      this.$router.push(registerCheckout);
    },
  },
  apollo: {
    product: {
      query: BILLING_PRODUCTS_QUERY,
      variables() {
        return {
          code: this.$route.query.code,
          withPackages: false,
        };
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        if (data.billingProducts.edges.length > 0) {
          let product = data.billingProducts.edges[0].node;
          return product;
        }
        return null;
      },
    },
  },
};
</script>
