<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <j-card>
          <div>
            <img
              :src="require('@/assets/images/affilate_form_banner.png')"
              style="height: 150px; width: 100%; object-fit: cover"
            />
          </div>
          <v-card-title class="pb-0 mb-1 justify-center">
            <h2 class="font-weight-bold mb-4 mt-4 appblue--text">Affiliate Sign-Up</h2>
          </v-card-title>
          <v-card-text>
            <div class="px-3">
              <p class="text-center mt-n2 mb-6">
                Join the JEFF Affiliate Program and start earning by sharing the joy of wellness with your community.
                Register below to receive your unique sign-up code and begin your journey as a JEFF Affiliate.
                <br /><br />
                Note: While you will need a
                <router-link to="/start/register" class="text-decoration-none">JEFF account</router-link> to sign up, an
                active subscription is not required.
              </p>

              <ValidationObserver ref="observer">
                <v-form @submit.prevent="submit">
                  <j-text-field
                    v-model="totallyRealFields.name"
                    aria-hidden="true"
                    class="totally_real_input"
                  ></j-text-field>

                  <j-text-field
                    v-model="totallyRealFields.surname"
                    aria-hidden="true"
                    class="totally_real_input"
                  ></j-text-field>

                  <v-row no-gutters>
                    <v-col cols="12">
                      <ValidationProvider id="email" v-slot="{ errors }" name="email" rules="required|email">
                        <j-text-field
                          v-model.trim="data.email"
                          label="Email"
                          placeholder="Enter your JEFF account email"
                          outlined
                          :error-messages="errors"
                          @keydown.space.prevent
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <ValidationProvider
                    id="terms"
                    v-slot="{ errors }"
                    name="Terms"
                    :rules="{ required: { allowFalse: false } }"
                  >
                    <j-checkbox v-model="terms" class="mb-4 mt-n4" :error-messages="errors">
                      <div slot="label">
                        <span class="p-text">
                          I have read and agree to the
                          <a
                            @click.stop
                            href="https://www.jeff.fitness/affiliate-terms-and-conditions"
                            target="_blank"
                            class="text-decoration-none"
                            >Affiliate Terms & Conditions</a
                          >
                        </span>
                      </div>
                    </j-checkbox>
                  </ValidationProvider>

                  <v-row justify="center">
                    <j-alert v-if="errorMessage" class="mb-6 mt-n2" v-html="errorMessage"> </j-alert>
                    <j-btn type="submit" :loading="buttonLoading" width="100%" class="mb-8">Submit</j-btn>
                  </v-row>
                </v-form>
              </ValidationObserver>
            </div>
          </v-card-text>
        </j-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserMixin from "@/mixins/UserMixin";
import { CREATE_AFFILIATE } from "@/graphql/mutations/settings";

export default {
  name: "LeadCapture",
  mixins: [UserMixin],
  data() {
    return {
      data: {
        email: this.$store.getters.user?.email,
      },
      terms: false,
      errors: null,
      buttonLoading: false,
      totallyRealFields: {
        name: null,
        surname: null,
      },
      errorMessage: null,
    };
  },

  methods: {
    async toThankYou() {
      this.$router.push(`/affiliate-success/${this.data.email}`);
    },
    async submit() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;

      if (valid) {
        this.buttonLoading = true;

        if (this.totallyRealFields.name || this.totallyRealFields.surname) {
          this.toThankYou();
          return;
        }

        const result = await this.$apollo.mutate({
          mutation: CREATE_AFFILIATE,
          variables: {
            email: this.data.email,
          },
        });
        if (result.data?.createAffiliate?.success == true) {
          this.toThankYou();
          return;
        } else if (
          result.data?.createAffiliate?.success == false &&
          result.data?.createAffiliate?.error == "NO_ACCOUNT"
        ) {
          this.errorMessage =
            "We cannot find an account for this email. Please <a href='/start/register'>create an account</a> first.";
          this.buttonLoading = false;
          return;
        }

        this.errorMessage = "There was a problem. Please try again.";
        this.buttonLoading = false;
      }
    },
  },
};
</script>
