<template>
  <j-banner-container header="Your posts" :gradient="['green-background', 'yellow-background']">
    <div v-if="$store.getters.user.userProfile.numberOfPosts == 0" class="pa-4">
      <j-alert>Your posts will appear here.</j-alert>
    </div>
    <post-container v-else :userId="$store.getters.user.id" userPostsOnly class="mx-4" />
  </j-banner-container>
</template>

<script>
import PostContainer from "@/components/groups/posts/PostContainer";

export default {
  name: "YourPosts",
  data() {
    return {};
  },
  components: {
    PostContainer,
  },
};
</script>
