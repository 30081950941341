<template>
  <j-card class="text-left mb-4 pa-2 j-elevated-2">
    <v-toolbar flat class="mt-2 float-right">
      <span class="grey-navy--text">{{ eventType }}</span>
    </v-toolbar>
    <v-list class="py-0 my-0">
      <v-list-item class="py-0">
        <v-list-item-content class="py-1 my-0">
          <v-list-item-title class="mt-6 grey-navy--text">{{ eventDate }} | {{ eventTime }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <a :href="'/social/community-fitness/' + meetup.id" class="text-decoration-none">
        <v-list-item>
          <v-list-item-content class="card-list-title mt-2">
            <v-list-item-title class="grey-navy--text std-text"><div>{{meetup.title}}<v-icon>keyboard_arrow_right</v-icon></div></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </a>
      <v-list-item class="py-0">
        <v-list-item-content class="py-1 my-0">
          <v-list-item-title class="text-capitalize">
            <v-alert :type="eventStatusClass.split('-')[1]" :class="eventStatusClass">
              Status: {{ eventStatus }}
            </v-alert>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item class="py-0">
        <v-list-item-content class="py-1 my-0">
          <attendee-list :attendees="meetup.attendees" :host="meetup.host"></attendee-list>
          <v-list-item-subtitle class="mt-2 grey-navy--text">Host: {{ hostName }}</v-list-item-subtitle>
          <v-list-item-subtitle class="mb-2 grey-navy--text">Attendees ({{ meetup.attendees.length }}/{{ meetup.capacity }}): {{ attendees }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="meetup.type === 'PHYSICAL'" class="py-0">
        <v-list-item-content class="py-1 my-0">
          <v-list-item-title><v-icon class="location-pin-icon">location_on</v-icon>{{ meetup.location }}</v-list-item-title>
          <v-list-item-subtitle v-if="meetup.distance" class="grey-navy--text ml-5">{{ distance }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="meetup.status === 'DECLINED'" class="py-0 mt-1">
        <v-list-item-content class="py-1 my-0">
          <v-list-item-subtitle class="section-heading">Why was my event declined?</v-list-item-subtitle>
          <v-list-item-title>{{ meetup.declinedReason }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-card-actions>
      <v-spacer />
      <j-btn narrow secondary :to="'/social/community-fitness/edit/' + meetup.id">Edit</j-btn>
      <j-btn narrow primary @click="cancelMeetup"> Cancel</j-btn>
    </v-card-actions>
  </j-card>
</template>

<script>
import { fancy_date_from_iso } from "@/lib/fancy_date";
import AttendeeList from "@/components/community-fitness/AttendeeList";
import { capitalize } from "@/lib/string";

export default {
  name: "MeetingAdminCard",
  components: {
    AttendeeList
  },
  props: {
    meetup: {
      type: Object,
      default: null
    }
  },
  computed: {
    eventDate() {
      let localDate = this.$moment(this.meetup.eventDate).local(true).format("YYYY-MM-DD HH:mm:ss");
      return fancy_date_from_iso(localDate, 'ddd, D MMMM YYYY', false);
    },
    eventTime() {
      let localTime = this.$moment(this.meetup.eventDate).local(true).format('LT');
      return localTime;
    },
    distance() {
      return `Approximately ${this.meetup.distance}km from you`;
    },
    eventType() {
      return this.meetup.type === 'PHYSICAL' ? 'In Person' : 'Virtual';
    },
    atCapacity() {
      return this.meetup.attendees.length >= this.meetup.capacity;
    },
    eventStatus() {
      return this.meetup.status.replace("_", " ").toLowerCase().replace("approved","active");
    },
    eventStatusClass() {
      switch(this.meetup.status.toLowerCase()) {
        case "pending_moderation":
          return "alert-info";
        case "approved":
          return "alert-success";
        default:
          return "alert-error";
      }
    },
    hostName() {
      let name = this.meetup.host.firstName;
      let surname = this.meetup.host.lastName;

      return capitalize(name) + " " + capitalize(surname);
    },
    attendees() {
      let mapped = this.meetup.attendees.map(elem => elem.attendee.firstName + " " + elem.attendee.lastName);
      mapped = ["You"].concat(mapped);
      return mapped.join(", ");
    },
  },
  methods: {
    cancelMeetup() {
      this.$emit('cancel', this.meetup);
    }
  }
}
</script>