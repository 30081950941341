<template>
  <j-banner-container header="Nutrition">
    <calendar-dots accent-color="navy"/>
    <today-nutrition-section class="mx-4 mt-n6" />
  </j-banner-container>
</template>

<script>
import TodayNutritionSection from "@/components/shared/sections/TodayNutrition";
import CalendarDots from "@/components/shared/blocks/CalendarDots";

export default {
  name: "TodayNutrition",
  components: {
    TodayNutritionSection,
    CalendarDots,
  },
};
</script>
