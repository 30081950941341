<template>
  <div class="d-flex flex-column flex-sm-row">
    <j-card
      class="d-flex flex-column flex-fill col-sm-6 text-center j-elevated-3 mb-2 mr-sm-2"
      :class="!isSelfPaced ? 'grey-background j-elevated-4' : 'white'"
      @click="setOption(false)"
    >
      <v-icon large class="pt-4 pb-2" :color="!isSelfPaced ? 'navy' : 'grey-icon'">mdi-calendar-month</v-icon>
      <v-card-title class="navy--text justify-center pb-2 pt-0">Structured</v-card-title>
      <v-card-text class="pb-2"
        >Our instructors will set the pace, tell you which days to do your activity and when to rest. Follow along on
        your Home page, with each day's recommendation front and center.</v-card-text
      >
      <v-card-actions class="justify-center pb-4 mt-auto">
        <j-btn small :color="!isSelfPaced ? 'navy' : 'grey-icon'">
          <span v-if="current != null">
            <span v-if="!current">Current plan</span>
            <span v-else>Switch to this plan</span>
          </span>
          <span v-else>Choose this plan</span> <v-icon v-if="!isSelfPaced" small>mdi-check</v-icon></j-btn
        >
      </v-card-actions>
    </j-card>
    <j-card
      class="d-flex flex-column flex-fill col-sm-6 text-center j-elevated-3 mb-2 ml-sm-2"
      :class="isSelfPaced ? 'grey-background j-elevated-4' : 'white'"
      @click="setOption(true)"
    >
      <v-icon large class="pt-4 pb-2" :color="isSelfPaced ? 'navy' : 'grey-icon'">mdi-calendar-multiselect</v-icon>
      <v-card-title class="navy--text justify-center pb-2 pt-0">Self-paced</v-card-title>
      <v-card-text class="pb-2"
        >Choose this if you're looking to progress through the program at your own pace. Activities
        will only move on once you have logged them, which means you can take as long as you need to to finish a program. </v-card-text
      >
      <v-card-actions class="justify-center pb-4 mt-auto">
        <j-btn small :color="isSelfPaced ? 'navy' : 'grey-icon'">
          <span v-if="current != null">
            <span v-if="current">Current plan</span>
            <span v-else>Switch to this plan</span>
          </span>
          <span v-else> Choose this plan</span> <v-icon v-if="isSelfPaced" small>mdi-check</v-icon></j-btn
        >
      </v-card-actions>
    </j-card>
  </div>
</template>

<script>
export default {
  name: "PlanOptions",
  data() {
    return {
      isSelfPaced: false,
    };
  },
  props: {
    current: {
      type: Boolean,
      required: false,
      default: null
    },
  },
  created() {
    if (this.current != null) {
      this.isSelfPaced = this.current;
    }
  },
  methods: {
    setOption(isSelfPaced) {
      this.isSelfPaced = isSelfPaced;
      this.$emit("set-self-paced", isSelfPaced);
    },
  },
};
</script>
