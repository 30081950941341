<template>
  <div class="my-1 selfie-alert">
    <j-alert :type="image ? 'info' : 'warning'" rounded="xl" :icon="false">
      <v-skeleton-loader v-if="loading || $apollo.loading" type="image" />
      <template v-else-if="image">
        <div class="img-container pointer" @click="$emit('clickUploader')">
          <v-img contain max-height="300" :src="image" :lazySrc="image" />
          <div class="text-center pointer mt-n12">
            <j-btn icon narrow large tertiary size="40">
              <v-icon size="30" color="navy">mdi-image-edit-outline</v-icon>
            </j-btn>
          </div>
        </div>
        <div v-if="canRemove" class="text-center pointer remove-icon">
          <j-btn icon narrow tertiary>
            <v-icon color="red-icon" size="25" @click="$emit('removeSelfie')"> mdi-trash-can-outline</v-icon>
          </j-btn>
        </div>
      </template>
      <div v-else class="d-flex flex-column mt-4 text-center pointer">
        <div>
          <div class="mt-3">
            <v-btn icon :ripple="false" @click="$emit('clickUploader')">
              <v-icon color="yellow-icon darken-1" size="60">mdi-camera-plus</v-icon>
            </v-btn>
          </div>
          <div class="p-text my-3" @click="$emit('clickUploader')">
            <span class="std-text font-weight-bold yellow-icon--text text--darken-1" v-if="!image">Add photo</span>
          </div>
          <span v-if="prizeText" class="yellow-icon--text text--darken-1">
            Your photo is an entry into the draw to win {{ prizeText }}
          </span>
        </div>
      </div>
    </j-alert>
    <image-uploader
      id="selfie-image-uploader"
      style="position:absolute; opacity: 0.01"
      :maxWidth="1000"
      :preview="false"
      :quality="0.9"
      outputFormat="verbose"
      accept="image/*"
      @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import { capitalize } from "@/lib/string";
import ImageUploader from "vue-image-upload-resize";

export default {
  name: "SelfieAlert",
  props: {
    text: {
      type: String,
      default: "sweaty selfie",
    },
    image: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    canRemove: {
      type: Boolean,
      default: true,
    },
    prizeText: {
      type: String,
      required: false,
    },
  },
  components: {
    ImageUploader,
  },
  filters: {
    capitalize(string) {
      return capitalize(string);
    },
  },
};
</script>

<style lang="scss" scoped>
.remove-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}
.selfie-container {
  border-radius: 20px;
  min-height: 80px;
  background-color: var(--v-grey-background-base);
  border: 1px solid var(--v-grey-navy-base);
}
.img-container {
  position: relative;
  max-width: 90%;
  max-height: 400px;
  margin: 0px auto;

  .v-image {
    max-width: 100%;
  }
}
</style>

<style lang="scss">
.selfie-alert .v-alert .v-alert__wrapper {
  display: block;
}
</style>
