import gql from "graphql-tag";

export const CHATS_FOR_USER = gql`
  query chatsForUser($first: Int, $after: String, $search: String, $onlyUnread: Boolean, $orderBy: String) {
    chatsForUser(first: $first, after: $after, search: $search, onlyUnread: $onlyUnread, orderBy: $orderBy) {
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          title
          description
          image
          type
          unreadCount
          participantCount
          lastMessageTimestamp
          creator {
            id
            firstName
            lastName
          }
          userchatSet {
            id
            isAdmin
            user {
              id
              firstName
            }
          }
          users {
            id
            firstName
            lastName
            userProfile {
              id
              profilePicture
              activeSubscription {
                id
                product {
                  id
                  type
                }
              }
            }
          }
          userChat {
            id
            receiveNotifications
            shareData
            isAdmin
          }
          lastMessage {
            id
            text
            createdAt
            sentDate
            messageattachmentSet {
              id
              type
            }
            user {
              id
              firstName
              userProfile {
                id
                profilePicture
              }
            }
          }
        }
      }
    }
  }
`;

export const SIMPLE_CHATS_FOR_USER = gql`
  query chatsForUser {
    chatsForUser {
      edges {
        node {
          id
          title
          description
          image
          lastMessageTimestamp
          type
        }
      }
    }
  }
`;

export const MESSAGES_FOR_CHAT = gql`
  query messagesForChat(
    $chatId: String!
    $sent: Boolean
    $scheduledDate_Gte: DateTime
    $userId: ID
    $first: Int
    $last: Int
    $after: String
    $before: String
    $orderBy: String
  ) {
    messagesForChat(
      chatId: $chatId
      sent: $sent
      scheduledDate_Gte: $scheduledDate_Gte
      user_Id: $userId
      first: $first
      last: $last
      after: $after
      before: $before
      orderBy: $orderBy
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          text
          createdAt
          scheduledDate
          sentDate
          messagereactionSet {
            id
            reaction
            user {
              id
              firstName
              lastName
            }
          }
          parent {
            id
            text
            user {
              id
              firstName
              lastName
              userProfile {
                id
                profilePicture
              }
            }
            messageattachmentSet {
              id
              type
              file
              timeInSeconds
              remoteFile
            }
          }
          user {
            id
            firstName
            lastName
            isStaff
            isActive
            userProfile {
              id
              profilePicture
            }
          }
          messageattachmentSet {
            id
            type
            file
            remoteFile
            timeInSeconds
          }
        }
      }
    }
  }
`;
export const CHAT = gql`
  query chat($chatId: ID!, $withUserChats: Boolean!) {
    chat(chatId: $chatId) {
      id
      title
      image
      type
      creator {
        id
        firstName
        lastName
      }
      userChat {
        id
        lastViewedTimestamp
        receiveNotifications
      }
      userchatSet @include(if: $withUserChats) {
        id
        shareData
        user {
          id
          firstName
          lastName
          userProfile {
            id
            profilePicture
            activeSubscription {
              id
              product {
                id
                type
              }
            }
          }
        }
      }
    }
  }
`;

export const CHAT_UNREAD_COUNT = gql`
  query unreadChatCount {
    unreadChatCount
  }
`;
