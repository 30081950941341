<template>
  <div>
    <v-text-field v-model="search" placeholder="Search"/>
    <div v-for="category in Object.keys(emojiList)" :key="category">
      <h5 class="mt-3">{{ category }}</h5>
      <v-avatar
        v-for="emojiKey in Object.keys(emojiList[category])"
        :key="emojiKey"
        size="35"
        class="pointer"
        style="font-size:20px"
        @click="$emit('chooseEmoji', emojis[category][emojiKey])"
      >
        {{emojis[category][emojiKey]}}
      </v-avatar>
    </div>
  </div>
</template>

<script>
import emojis from "@/components/chat/plugins/emojis";

const escapeRegExp = (s) => s.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export default {
  name: "SimpleEmojiPicker",
  data() {
    return {
      search: '',
      emojis: emojis
    }
  },
  computed: {
    emojiList() {
      if (this.search) {
          const obj = {}
          for (const category in this.emojis) {
            obj[category] = {}
            for (const emoji in this.emojis[category]) {
              if (new RegExp(`.*${escapeRegExp(this.search)}.*`).test(emoji)) {
                obj[category][emoji] = this.emojis[category][emoji]
              }
            }
            if (Object.keys(obj[category]).length === 0) {
              delete obj[category]
            }
          }
          return obj
        }
      return this.emojis;
    }
  },
}
</script>