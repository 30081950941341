<template>
  <j-card class="rounded-pill d-flex align-center justify-space-between" :class="{ 'is-mine': isMine }">
    <div class="d-flex align-center">
      <v-avatar size="50" class="blue-background">
        <v-img v-if="icon" :src="icon" :aspect-ratio="9 / 9" />
        <span v-else class="white--text">
          {{ avatarText }}
        </span>
      </v-avatar>

      <v-tooltip v-if="isMine" bottom>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" class="ml-4 std-text is-mine">{{ title }}</div>
        </template>
        <span>Your tribe</span>
      </v-tooltip>

      <div v-else class="ml-4 std-text">{{ title }}</div>

      <v-icon v-if="position == 1" class="ml-2" size="25" color="yellow-icon"> mdi-medal</v-icon>
    </div>

    <div class="d-flex align-center mr-2">
      <div class="mr-3 p-text font-weight-bold" :class="{ 'is-mine': isMine }">{{ dots }}</div>
      <v-icon color="exercise" size="25">mdi-circle</v-icon>
    </div>
  </j-card>
</template>

<script>
export default {
  name: "TribeStatListingCard",
  props: {
    icon: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    dots: {
      type: [String, Number],
      required: true,
    },
    avatarText: {
      type: String,
      required: false,
    },
    isMine: {
      type: Boolean,
      default: false,
    },
    position: {
      type: Number,
      required: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.is-mine {
  background-color: #4e4278;
  color: white;
}
</style>
