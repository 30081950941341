<template>
  <div class="my-5">
    <h3 class="mb-3">{{ handler }} tip #1 - Set a goal filled with emotion</h3>
    <span class="font-15">
      Your goal reminds you why you've chosen to join {{ $appName }}. Fill your goal with
      emotion to make your goal more personal and motivating.
    </span>
    <div class="mt-3 mb-5" >
      <v-btn dark color="secondary" rounded large to="/goal/my-goal"
        ><strong>Set a goal now</strong></v-btn
      >
    </div>
    <span class="font-15"> You'll get my next 4 tips after setting a goal! </span>
  </div>
</template>

<script>
export default {
  name: "OnboardingGoalTip",
  props: {
    handlerName: {
      type: String,
      required: true
    }
  },
  computed: {
    handler() {
      if (this.handlerName[this.handlerName.length - 1] == "s")
        return `${this.handlerName}'`;
      else return `${this.handlerName}'s`;
    },
  },
};
</script>

<style scoped>
.font-15 {
  font-size: 15px;
}
</style>