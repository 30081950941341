<template>
  <div v-if="featuredGroups && featuredGroups.length > 0">
    <h4>{{ title }}</h4>
    <v-row no-gutters>
      <v-col cols="6" md="4" v-for="group in featuredGroups" :key="group.id">
        <group-listing-card :group="group" />
      </v-col>
    </v-row>

    <template v-if="otherGroups">
      <j-btn v-if="!showMoreGroups && otherGroups && otherGroups.length > 0" color="primary" small tertiary @click="showMoreGroups = true" class="mt-2">Show More</j-btn>
      <v-row no-gutters v-if="showMoreGroups">
        <v-col cols="6" md="4" v-for="group in otherGroups" :key="group.id">
          <group-listing-card :group="group" />
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import GroupListingCard from "@/components/groups/cards/GroupListingCard";

export default {
  name: "GroupListingRepeater",
  components: {
    GroupListingCard,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    groups: {
      type: Array,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Boolean],
      required: true,
    },
  },
  data() {
    return {
      showMoreGroups: false,
    };
  },
  computed: {
    filteredGroups() {
      return this.groups.filter((group) => group[this.field] == this.value);
    },
    featuredGroups() {
      if (this.filteredGroups?.length > 0) {
        return this.filteredGroups.slice(0, 6);
      }
      return [];
    },
    otherGroups() {
      if (this.filteredGroups?.length > 6) {
        return this.filteredGroups.slice(6);
      }
      return [];
    },
  },
};
</script>
