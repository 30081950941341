import gql from 'graphql-tag'

export const UPDATE_ACCOUNTABILITY_GROUP = gql`
mutation updateAccountabilityJoined($input: [AccountabilityInput]!) {
  updateAccountabilityJoined(input: $input) {
    ok
    error
  }
}
`

export const UPDATE_FACEBOOK_JOINED = gql`
mutation updateFacebookJoined {
  updateFacebookJoined {
    ok
    error
  }
}
`;

export const UPDATE_WHATSAPP_JOINED = gql`
mutation updateWhatsappJoined {
  updateWhatsappJoined {
    ok
    error
  }
}
`;