<template>
  <div class="d-flex mx-auto calendar-dot">
    <!-- filled in  -->
    <div v-if="logged" class="d-flex justify-center">
      <slot name="dot-content" class="tracking-icon"/>
      <v-icon :color="color" class="tracking-icon background" :size="size">mdi-circle</v-icon>
    </div>
    <div v-else-if="partiallyLogged" class="d-flex">
      <v-icon :color="color" class="tracking-icon" :size="size">mdi-circle-outline</v-icon>
      <v-icon :color="color" class="half-tracking-icon" :size="parseInt(size) / 2">mdi-circle</v-icon>
    </div>
    <div v-else-if="isRestDay" class="selfie-icon relative d-flex">
      <v-icon color="grey darken-2" class="tracking-icon" :size="size">mdi-circle</v-icon>
    </div>
    <div v-else-if="active" class="d-flex">
      <v-icon :color="color" class="tracking-icon" :size="size">mdi-plus-circle-outline</v-icon>
    </div>
    <!-- not filled in  -->
    <div v-else class="d-flex">
      <slot name="dot-content" />
      <v-icon :color="isRestDay ? 'grey darken-2' : color" class="tracking-icon" :size="size">
        mdi-circle-outline
      </v-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "CalendarDot",
  props: {
    logged: {
      type: [Boolean, Number],
      default: false,
    },
    partiallyLogged: {
      type: [Boolean, Number],
      default: false,
    },
    color: {
      type: String,
      required: true,
    },
    isRestDay: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false
    },
    size: {
      type: [String, Number],
      default: 40
    }
  },
};
</script>

<style lang="scss" scoped>
.half-tracking-icon {
  position: absolute;
}

.tall-button-group {
  & .half-tracking-icon {
    margin-top: 10px;
    right: 10px;
  }
}
</style>
