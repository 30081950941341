<template>
  <div>
    <j-card class="mb-5 text-left" elevation="0" @click="click">
      <v-row>
        <v-col cols="4" sm="2">
          <v-img class="rounded-xl j-elevated-1" :aspect-ratio="1" :src="image" :lazy-src="image" width="75"/>
        </v-col>
        <v-col cols="8" sm="10" align="left" class="pl-0 mt-2">
          <v-card-title class="pl-0 pt-0 navy--text std-text">
            {{ title }}
          </v-card-title>
          <v-card-subtitle v-if="subtitle" class="pl-0 pb-0 text-left navy--text p-text">
            {{ subtitle }}
          </v-card-subtitle>
          <div v-if="date" class="text-right sm-text mr-5">
            {{ date }}
          </div>
        </v-col>
      </v-row>
    </j-card>
  </div>
</template>

<script>
export default {
  name: "JListCard",
  props: {
    id: {
      required: true
    },
    title: {
      type: String,
      required: true
    },
    image: {
      type: String,
      required: false
    },
    subtitle: {
      type: String,
      required: false
    },
    date: {
      type: String,
      required: false
    }
  },
  methods: {
    click() {
      this.$emit("clicked", this.id);
    },
  }
}
</script>