<template>
  <v-container>
    <div class="d-flex flex-column text-center justify-center">
      <h1>Your 1-on-1 Coaching Info Pack is ready</h1>
    </div>
    <v-row justify="center" align="center">
      <v-col cols="12" md="7">
        <j-card class="pt-2 mt-8">
          <v-card-text class="text-center px-8">
            <j-alert
              >Thank you! <span v-if="isMobile">Tap</span><span v-else>Click</span> on the link below to download your
              info pack.</j-alert
            >
            <j-btn :href="infoPackLink" target="_blank" wide>Download Now</j-btn>
          </v-card-text>
        </j-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { MASTER_SETTINGS } from "@/graphql/queries/settings";

export default {
  name: "InfoPackDownload",
  data() {
    return {};
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  apollo: {
    infoPackLink: {
      query: MASTER_SETTINGS,
      fetchPolicy: "network-only",
      update(data) {
        const setting = data.masterSettings.edges.find((obj) => obj.node.key == "one-on-one-info-pack-link");
        return setting.node?.value;
      },
    },
  },
};
</script>
