import {
  SUBSCRIPTION_NUTRITION_BLOCK_MUTATION,
  DELETE_NUTRITION_SUBSCRIPTION_BLOCK_MUTATION,
} from "@/graphql/mutations/nutritionblock";
import { decodeId } from "@/lib/string";

export default {
  methods: {
    async updateNutritionSubscriptionBlock(data) {
      this.openManageBlockDialog = false;
      this.startDate = data.startDate;
      const input = {
        startDate: this.startDate,
        subscriptionNutritionBlockId: decodeId(data.nutritionSubscriptionBlock.id),
      };
      await this.$apollo.mutate({
        mutation: SUBSCRIPTION_NUTRITION_BLOCK_MUTATION,
        variables: {
          data: input,
        },
        refetchQueries: ["accountQuery", "nutritionBlock", "nutritionSubscriptionBlocks", "recipesForWeek" ],
      });
    },
    async leaveNutritionBlock(subscriptionBlockId = null) {
      if(!subscriptionBlockId && this.currentNutritionSubscriptionBlock) {
        subscriptionBlockId = decodeId(this.currentNutritionSubscriptionBlock.id)
      }
      
      await this.$apollo.mutate({
        mutation: DELETE_NUTRITION_SUBSCRIPTION_BLOCK_MUTATION,
        variables: {
          subscriptionBlockId: subscriptionBlockId,
        },
        refetchQueries: ["accountQuery", "nutritionBlock", "nutritionSubscriptionBlocks", "recipesForWeek" ],
      });
    },
    async leaveNutritionBlocks(subscriptionNutritionBlocks) {
      for (const subscriptionNutritionBlock of subscriptionNutritionBlocks) {
        await this.$apollo.mutate({
          mutation: DELETE_NUTRITION_SUBSCRIPTION_BLOCK_MUTATION,
          variables: {
            subscriptionBlockId: decodeId(subscriptionNutritionBlock.id),
          },
          refetchQueries: ["accountQuery", "nutritionBlock", "nutritionSubscriptionBlocks", "recipesForWeek" ],
        });
      }
    },
  },
};
