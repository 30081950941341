import gql from "graphql-tag";

export const ANSWER_MUTATION = gql`
mutation answerMutation($data: AnswerInput!){
  answerMutation(data: $data){
    error
    success
  }
}`

export const SUBSCRIPTION_SURVEY_MUTATION = gql`
mutation subscriptionSurveyMutation($data: SubscriptionSurveyInput!){
  subscriptionSurveyMutation(data: $data){
    error
    success
  }
}`

export const UPSERT_SUBSCRIPTION_SURVEY_MUTATION = gql`
mutation upsertSubscriptionSurveyMutation($surveyType: String!){
  upsertSubscriptionSurveyMutation(surveyType: $surveyType){
    error
    success
    subscriptionSurveyId
  }
}`