import gql from "graphql-tag";

export const USER_NUTRITION_PREFERENCE_MUTATION = gql`
  mutation setUserNutritionPreference($nutritionPreferences: [Int]!) {
    setUserNutritionPreference(nutritionPreferences: $nutritionPreferences) {
      success
      error
    }
  }
`;

export const LOG_NUTRITION_MUTATION = gql`
  mutation logNutritionMutation($nutritionLogInput: UserNutritionLogInput!) {
    upsertNutritionLog(nutritionLogInput: $nutritionLogInput) {
      nutritionLog {
        id
        isNutritiousDay
        usernutritionloganswerSet {
          id
          answer
          nutritionQuestion {
            id
            title
            question
          }
        }
      }
      earnedBadges {
        title
        description
      }
    }
  }
`;

export const UPDATE_SERVING_SIZE_MUTATION = gql`
  mutation updateServingSize {
    updateServingSize{
      success
      error
    }
  }
`;

export const UPSERT_RECIPE_MUTATION = gql`
  mutation upsertRecipeMutation($data: UpsertRecipeInput!) {
    upsertRecipe(data: $data) {
      success
      recipeId
      error
    }
  }
`