<template>
  <j-card @click="navigate()" class="d-flex pa-2 p-relative pointer" :class="{ unread: notification.isUnread }">
    <v-avatar size="50" color="grey">
      <img v-if="notification.image" :src="notification.image" />
    </v-avatar>
    <div class="pl-4">
      <div class="p-text" v-html="notification.description"></div>
      <span class="sm-text">{{ notifyAt(notification.dateNotify) }}</span>
    </div>
    <v-icon v-if="icon" class="type-icon" :color="icon.color">{{ icon.icon }}</v-icon>
  </j-card>
</template>

<script>
import { fancyShortDate } from "@/lib/fancy_date";
import { decodeId } from "@/lib/string";
import { UPDATE_NOTIFICATION_MUTATION } from "@/graphql/mutations/notifications";

export default {
  name: "NotificationCard",
  data() {
    return {
      notificationIcons: [
        { type: "COMMENT", icon: "mdi-comment", color: "green" },
        { type: "LIKE", icon: "mdi-heart", color: "pink lighten-3" },
        { type: "FRIEND_REQUEST", icon: "mdi-account-plus", color: "facebook-blue" },
      ],
      buttonLoading: false,
      errorMessage: "",
    };
  },
  props: {
    notification: {
      type: Object,
      required: true,
    },
  },
  computed: {
    icon() {
      return this.notificationIcons.find((obj) => {
        return obj.type == this.notification.type;
      });
    },
    link() {
      if (this.notification.post) {
        return `/social/post/${decodeId(this.notification.post.id)}`;
      }
      if (this.notification.type == "FRIEND_REQUEST") {
        return "/friend-requests";
      }
      return "#";
    },
  },
  methods: {
    notifyAt(dateTime) {
      return fancyShortDate(dateTime);
    },
    async navigate() {
      if (this.notification.isUnread) {
        await this.$apollo.mutate({
          mutation: UPDATE_NOTIFICATION_MUTATION,
          variables: {
            notificationId: decodeId(this.notification.id),
            dateRead: this.$moment()
              .add(10, "seconds")
              .toISOString(),
          },
        });
      }

      this.$router.push(this.link);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .notification-item {
  font-weight: bold;
}
.unread {
  background-color: #e9f2ff;
}
.type-icon {
  position: absolute;
  top: 40px;
  left: 40px;
}
</style>
