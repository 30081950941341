import { nl2br } from "@/lib/string";
import { truncate, stripTags } from "@/lib/string";

export default {
  name: "ChatMixin",
  data() {
    return {
      openImageDialog: false,
      selectedImage: null,
    };
  },
  computed: {
    hasClub() {
      return ["CLUB", "ONE_ON_ONE"].includes(this.$store.getters.role);
    },
  },
  methods: {
    isScheduled(message) {
      return !!(message.scheduledDate && this.$moment(message.scheduledDate).isSameOrAfter(this.$moment()));
    },
    getUserProductType(user) {
      return user?.userProfile?.activeSubscription?.product?.type;
    },
    canAccessChat(chat, product) {
      if (!product) {
        return false;
      }

      if (["CLUB", "ONE_ON_ONE"].includes(product)) {
        return true;
      }

      if (product === "JEFF_MEMBER") {
        // if LIFE, then can only access these types of chats
        return ["SUPPORT", "SUBURB"].includes(chat.type);
      }

      // fall through to INACTIVE product/role. Can only access support chat.
      return chat.type === "SUPPORT";
    },
    sentBySelf(message) {
      return message.user?.id == this.$store.getters.user.id;
    },
    truncate(text, chars = 50) {
      return truncate(stripTags(text), chars);
    },
    formatMessage(message) {
      if (message.trim()[0] === "<") return message;
      let text = nl2br(message);
      text = this.$options.filters.replaceLinksWithHref(text);
      return text;
    },
    openImage(image) {
      this.selectedImage = image;
      this.openImageDialog = true;
    },
    getImageGallery(attachments, firstOnly = false) {
      const images = this.getImages(attachments);
      const imagesFormatted = images.map((element) => element.file);
      return firstOnly && imagesFormatted?.length > 0 ? imagesFormatted[0] : imagesFormatted;
    },
    getImages(attachments) {
      const images = attachments.filter((element) => element.type === "IMAGE");
      return images;
    },
    getVideos(attachments) {
      const videos = attachments.filter((element) => element.type === "VIDEO");
      return videos;
    },
    getAudioFiles(attachments) {
      const audioFiles = attachments.filter((element) => element.type === "AUDIO");
      return audioFiles;
    },
    getPdfs(attachments) {
      const pdfFiles = attachments.filter((element) => element.type === "PDF");
      return pdfFiles;
    },
    getGifs(attachments) {
      const gifFiles = attachments.filter((element) => element.type === "GIF");
      return gifFiles;
    },
    formatChatDate(dateString) {
      // sometimes it seems that dates are in the future. Cannot replicate consistently. Subtract some seconds to prevent that.
      return this.$moment(dateString)
        .subtract(10, "seconds")
        .fromNow();
    },
  },
};
