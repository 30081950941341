<template>
  <v-skeleton-loader v-if="$apollo.loading" type="card-heading" />
  <j-card v-else elevation="0" color="background-grey" rounded="pill" class="d-flex justify-space-around pt-1 pr-1 ">
    <compact-nutrition-logger-item
      title="Breakfast"
      meal="BREAKFAST"
      :logged="!!log.breakfast"
      :date="date"
      :image="getImage('BREAKFAST')"
      :nutritionBlockId="blockId"
      :recipe="hasClub ? breakfast(date) : null"
      :macros="getMealMacros('BREAKFAST')"
      :guidePdf="plan ? plan.guidePdf : null"
    />
    <compact-nutrition-logger-item
      title="Snack"
      meal="PRIMARY_SNACK"
      :logged="!!log.primarySnack"
      :date="date"
      :image="getImage('PRIMARY_SNACK')"
      :nutritionBlockId="blockId"
      :recipe="hasClub ? primarySnack(date) : null"
      :macros="getMealMacros('PRIMARY_SNACK')"
      :guidePdf="plan ? plan.guidePdf : null"
    />
    <compact-nutrition-logger-item
      title="Lunch"
      meal="LUNCH"
      :logged="!!log.lunch"
      :date="date"
      :image="getImage('LUNCH')"
      :nutritionBlockId="blockId"
      :recipe="hasClub ? lunch(date) : null"
      :macros="getMealMacros('LUNCH')"
      :guidePdf="plan ? plan.guidePdf : null"
    />
    <compact-nutrition-logger-item
      title="Dinner"
      meal="DINNER"
      :logged="!!log.dinner"
      :date="date"
      :image="getImage('DINNER')"
      :nutritionBlockId="blockId"
      :recipe="hasClub ? dinner(date) : null"
      :macros="getMealMacros('DINNER')"
      :guidePdf="plan ? plan.guidePdf : null"
    />
  </j-card>
</template>

<script>
import { mapGetters } from "vuex";
import { decodeId } from "@/lib/string";
import CompactNutritionLoggerItem from "./CompactNutritionLoggerItem";
import { NUTRITION_QUERY } from "@/graphql/queries/tracker";
import RecipeListMixin from "@/mixins/nutrition/RecipeListMixin";
import UserMixin from "@/mixins/UserMixin";

export default {
  name: "NutritionLogger",
  mixins: [UserMixin, RecipeListMixin],
  props: {
    plan: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      log: {
        breakfast: null,
        lunch: null,
        dinner: null,
        primarySnack: null,
      },
    };
  },
  components: {
    CompactNutritionLoggerItem,
  },
  computed: {
    ...mapGetters(["activeNutritionBlock", "selectedDate", "role"]),
    monday() {
      return this.$moment(this.selectedDate)
        .startOf("isoweek")
        .format("YYYY-MM-DD");
    },
    date() {
      return this.selectedDate?.format("YYYY-MM-DD") ?? this.$moment().format("YYYY-MM-DD");
    },
    blockId() {
      if (this.activeNutritionBlock) {
        return decodeId(this.activeNutritionBlock.id);
      }
      return null;
    },
  },
  methods: {
    getImage(meal) {
      switch (meal) {
        case "BREAKFAST":
          return this.log.breakfast?.image;
        case "LUNCH":
          return this.log.lunch?.image;
        case "DINNER":
          return this.log.dinner?.image;
        case "PRIMARY_SNACK":
          return this.log.primarySnack?.image;
      }
    },
    getMealMacros(type) {
      if (!this.plan || !this.plan.planmacroSet) return null;
      return this.plan.planmacroSet.find((element) => element.meal === type);
    },
  },
  apollo: {
    nutritionLog: {
      query: NUTRITION_QUERY,
      variables() {
        return {
          date: this.date,
        };
      },
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.loading) {
          return;
        }
        let data = response.data.nutritionLog.edges;
        this.log.breakfast = data.find((element) => element.node.meal === "BREAKFAST")?.node;
        this.log.lunch = data.find((element) => element.node.meal === "LUNCH")?.node;
        this.log.dinner = data.find((element) => element.node.meal === "DINNER")?.node;
        this.log.primarySnack = data.find((element) => element.node.meal === "PRIMARY_SNACK")?.node;
      },
    },
  },
};
</script>
