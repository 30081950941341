<template>
  <div>
    <v-row v-if="gifId" align="center" justify="center">
      <v-col cols="10" md="6">
        <v-card tile class="rounded  d-flex mb-2 relative grey-background" style="max-height:200px">
          <v-btn icon color="navy" class="clear-gif-btn">
            <v-icon small @click="gifId = null">mdi-close</v-icon>
          </v-btn>
          <v-row>
            <v-col class="d-flex child-flex" style="max-height:200px">
              <v-img :src="`https://media.giphy.com/media/${gifId}/giphy.gif`" aspect-ratio="1" contain /> </v-col
          ></v-row>
        </v-card> </v-col
    ></v-row>

    <v-tabs v-model="tab" color="secondary" slider-size="0">
      <v-tab key="trending">
        Trending
      </v-tab>
      <v-tab key="search">
        Explore
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item key="trending" class="mx-2"> </v-tab-item>

      <v-tab-item key="search" class="mx-2 pt-2">
        <v-row>
          <v-col cols="12" class="d-flex ">
            <j-text-field v-model="searchTerm" dense label="Search for GIF" />
            <j-btn v-if="searchTerm" tertiary class="ml-2 mt-1" small narrow :disabled="loading" @click="search()">
              <v-icon size="25">mdi-magnify</v-icon>
            </j-btn>
          </v-col>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
    <v-row v-if="loading" class="px-1">
      <v-col :cols="4" v-for="count in 3" :key="count"><v-skeleton-loader type="card" /> </v-col>
    </v-row>
    <v-row v-else no-gutters>
      <v-col cols="12" class="my-2">
        <j-btn
          v-if="total > offset"
          small
          tertiary
          class="ml-2 block"
          narrow
          :disabled="loading"
          @click="tab == 0 ? getTrending() : search()"
        >
          load more
        </j-btn>
      </v-col>
      <v-col v-for="(gif, gifIndex) in gifs" :key="gifIndex" class="d-flex child-flex" cols="4">
        <v-img @click="selectGif(gif.id)" :lazy-src="gif.lazy" :src="gif.url" aspect-ratio="1" class="pointer">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "Giphy",
  data() {
    return {
      loading: false,
      searchTerm: "",
      gifs: [],
      gifId: null,
      total: 0,
      offset: 0,
      tab: "trending",
    };
  },
  watch: {
    tab: function(tab) {
      this.gifs = [];
      this.offset = 0;
      this.total = 0;
      this.searchTerm = "";
      if (tab == 0) {
        this.getTrending();
      }
    },
  },
  methods: {
    selectGif(id) {
      this.$emit("giphy-selected", `https://media.giphy.com/media/${id}/giphy.gif`);
      this.gifId = id;
    },
    search() {
      this.loading = true;
      let apiKey = "tqpeb4Egc7rSbfQLzqhyAkEmkokMmY4i";
      let searchEndPoint = "https://api.giphy.com/v1/gifs/search?";
      let url = `${searchEndPoint}&api_key=${apiKey}&q=${this.searchTerm}&limit=6&offset=${this.offset}`;
      fetch(url)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          this.buildGifs(json);
        });
    },
    getTrending() {
      this.loading = true;
      let apiKey = "tqpeb4Egc7rSbfQLzqhyAkEmkokMmY4i";
      let url = `https://api.giphy.com/v1/gifs/trending?api_key=${apiKey}&limit=6&offset=${this.offset}`;
      fetch(url)
        .then((response) => {
          return response.json();
        })
        .then((json) => {
          this.buildGifs(json);
        });
    },
    buildGifs(json) {
      let pagination = json.pagination;
      this.total = pagination.total_count;
      this.offset = pagination.offset + 6;

      this.gifs = json.data.map((gif) => {
        return {
          url: `https://media1.giphy.com/media/${gif.id}/giphy-preview.gif`,
          lazy: `https://media1.giphy.com/media/${gif.id}/100_s.gif`,
          id: gif.id,
        };
      });
      this.loading = false;
    },
  },
};
</script>

<style scoped>
.clear-gif-btn {
  position: absolute;
  z-index: 1000;
  right: 0px;
  background-color: rgba(255, 255, 255, 0.5);
}
</style>
