import gql from "graphql-tag";

export const COMMUNITY_REPS_QUERY = gql`
  query communityRepLogQuery {
    communityRepLog {
      id
      title
      description
      image
      imageAltText
      linkText
      linkUrl
      repType {
        id
        title
      }
      metric
      activityType {
        id
        name
      }
      startDate
      endDate
      order
      isActive
      totals {
        me
        community
      }
    }
  }
`

export const LOGGED_REPS_QUERY = gql`
  query userRepLogQuery {
    userRepLog(orderBy: "-logged_date") {
      edges {
        node {
          id
          loggedDate
          repetitions
          repType {
            title
          }
        }
      }
    }
  }
`;

export const REP_TYPES_QUERY = gql`
  query repTypes {
    repTypes {
      id
      title
    }
  }
`;

