<template>
  <v-app>
    <v-alert
      v-if="activeBanner && $router.currentRoute.name === 'Home'"
      dense
      type="info"
      :icon="false"
      :color="activeBanner.backgroundColor"
      :style="{ color: activeBanner.fontColor }"
      dismissible
      @input="dismissBanner"
      class="text-center my-0 pointer"
    >
      <span @click="$router.push(activeBanner.url)">{{ activeBanner.copy }}</span>
    </v-alert>
    <the-nav-bar :show-nav="showNav" />
    <v-main class="background-grey">
      <floating-nav v-if="showFloatingNav && showNav" />
      <router-view />
      <v-snackbar top right :value="updateExists" :timeout="-1" color="primary" class="pt-4 mt-16 mr-sm-2">
        <h4 class="mb-2">Update available</h4>
        <div class="mb-2">You must update, otherwise the app may not work as intended.</div>
        <v-btn text @click="refreshApp" color="tertiary" outlined>
          <strong>Update</strong>
        </v-btn>
      </v-snackbar>
      <v-snackbar top right :value="showPWAInstallPrompt" :timeout="-1" color="primary" class="pt-4 mt-16 mr-sm-2">
        Install the {{ $appName }} App to access our content faster.
        <template v-slot:action="{ attrs }">
          <v-btn text @click="goToInstallPage" v-bind="attrs"> Install </v-btn>
          <v-btn text @click="showPWAInstallPrompt = false" v-bind="attrs"> Dismiss </v-btn>
        </template>
      </v-snackbar>
    </v-main>
    <div v-if="showBottomNav">
      <the-bottom-nav v-if="showNav" />
      <inactive-bottom-nav v-else />
    </div>
  </v-app>
</template>

<script>
import { INBOX_QUERY } from "@/graphql/queries/inbox";
import { UPSERT_LAST_ACTIVE } from "@/graphql/mutations/account";
import { mapActions } from "vuex";
import TheBottomNav from "@/components/shared/TheBottomNav";
import TheNavBar from "@/components/shared/TheNavBar";
import UserMixin from "@/mixins/UserMixin";
import CentralStorageMixin from "@/mixins/CentralStorageMixin";
import PWAEventMixin from "@/mixins/PWAEventMixin";
import InstallPromptMixin from "@/mixins/InstallPromptMixin";
import OneSignal from "@/mixins/OneSignal";
import FloatingNav from "@/components/shared/FloatingNav";
import InactiveBottomNav from "@/components/shared/InactiveBottomNav";
import { ACTIVE_BANNER_QUERY } from "@/graphql/queries/cms";
import { DISMISS_BANNER_MUTATION } from "@/graphql/mutations/cms";

export default {
  name: "App",
  mixins: [UserMixin, CentralStorageMixin, PWAEventMixin, OneSignal, InstallPromptMixin],
  components: {
    TheNavBar,
    TheBottomNav,
    FloatingNav,
    InactiveBottomNav,
  },
  data() {
    return {
      activeBanner: null,
    };
  },
  computed: {
    showNav() {
      // corporates should not see nav.
      if (this.$route?.name?.toLowerCase().includes("linkcorporatechallenge")) {
        return false;
      }

      return this.isLoggedIn && (this.hasActiveSub || this.onTrial);
    },
    showBottomNav() {
      return (
        this.isLoggedIn &&
        this.$vuetify.breakpoint.smAndDown &&
        !this.$route?.name?.toLowerCase().includes("checkout") &&
        !this.$route?.name?.toLowerCase().includes("paymentresult") &&
        !this.$route?.name?.toLowerCase().includes("pricing") &&
        !this.$route?.name?.toLowerCase().includes("linkcorporatechallenge")
      );
    },
    showFloatingNav() {
      return (
        this.$vuetify.breakpoint.mdAndUp &&
        !this.$route?.name?.toLowerCase().includes("checkout") &&
        !this.$route?.name?.toLowerCase().includes("paymentresult") &&
        !this.$route?.name?.toLowerCase().includes("pricing") &&
        !this.$route?.name?.toLowerCase().includes("linkcorporatechallenge")
      );
    },
  },
  created() {
    /**
     * Ideally we need to move this elsewhere probably into Router.
     */
    window.addEventListener("online", () => {
      if (this.$route.name === "Offline") {
        this.$router.push("/today");
      }
    });
    // window.addEventListener("offline", () => {
    //   if (this.$route.name !== "Offline") {
    //     this.$router.push("/pwa/offline");
    //   }
    // });
    this.$apollo.mutate({
      mutation: UPSERT_LAST_ACTIVE,
    });
    // set active every 3 min
    setInterval(() => {
      this.$apollo.mutate({
        mutation: UPSERT_LAST_ACTIVE,
      });
    }, 3 * 60 * 1000);
  },
  methods: {
    ...mapActions(["updateUnread"]),
    dismissBanner() {
      this.$apollo.mutate({
        mutation: DISMISS_BANNER_MUTATION,
        variables: {
          bannerId: this.activeBanner.id,
        },
        refetchQueries: ["activeBanner"],
      });
    },
  },
  apollo: {
    inboxMessages: {
      query: INBOX_QUERY,
      fetchPolicy: "no-cache",
      pollInterval: 15 * 60 * 1000,
      variables() {
        return {
          unreadOnly: true,
        };
      },
      result(response) {
        if (response.data && !response.loading) {
          let unread = response.data.inboxMessages.importantCount + response.data.inboxMessages.freshCount;
          this.updateUnread(unread);
          this.featuredInboxMessage = this.inboxMessages.edges.find((obj) => {
            return obj.node.dismissed === false && obj.node.inboxMessage.showAsBanner;
          });
        }
      },
      skip() {
        return !this.isLoggedIn;
      },
    },
    activeBanner: {
      query: ACTIVE_BANNER_QUERY,
      fetchPolicy: "cache-and-network",
      skip() {
        return !this.isLoggedIn;
      },
    },
  },
};
</script>

<style lang="scss">
@import "./styles/global.scss";
@import "./styles/components.scss";
</style>
