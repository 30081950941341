import gql from "graphql-tag";

export const ARCHIVE_INBOX_MUTATION = gql`
mutation archiveInbox {
  archiveInbox {
    success
    error
  }
}
`

export const INBOX_MESSAGE_RECIPIENT_MUTATION = gql`
mutation updateInboxMessageRecipient($messageIds: [ID]!, $read: Boolean, $dismissed: Boolean) {
  updateInboxMessageRecipient(messageIds:$messageIds, read:$read, dismissed:$dismissed) {
    success
  }
}
`