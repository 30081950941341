import { CREATE_MESSAGE_ATTACHMENTS_MUTATION } from "@/graphql/mutations/chat";
import { UPSERT_POST_MUTATION } from "@/graphql/mutations/group";
import { dataURLtoBlob, blobToFile, urlToDataURL } from "@/lib/file";
import { decodeId } from "@/lib/string";

export default {
  name: "SendToChatsGroupsMixin",
  data() {
    return {
      requiresImage: true,
      forceSocketRefresh: false,
      chatData: {
        sendToChats: [],
        sendToGroups: [],
        isSharing: false,
        chatText: null,
      },
    };
  },
  methods: {
    async sendMessages(chatData, image = null) {
      if (this.requiresImage && !image) {
        return;
      }
      if (image) {
        // if incoming image is a remote image.
        if (image.startsWith("https://")) {
          const $vm = this;
          const ready = new Promise((resolve) => {
            urlToDataURL(image, async function(dataUrl) {
              const blob = dataURLtoBlob(dataUrl);
              const file = blobToFile(blob);
              await $vm.saveMessageData(chatData, file);
              resolve();
            });
            return;
          });
          await ready;
          return;
        }

        // incoming image is NOT remote image.
        const blob = dataURLtoBlob(image);
        let file = blobToFile(blob);
        file.dataUrl = image.dataUrl;
        await this.saveMessageData(chatData, file);
      } else {
        this.saveMessageData(chatData);
      }
    },
    async saveMessageData(chatData, file = null) {
      const chatText = chatData?.chatText;
      for await (let chatId of chatData.sendToChats) {
        let attachmentIds;
        if (file) {
          attachmentIds = await this.uploadAttachment(file);
          if (!attachmentIds) {
            this.imageUploadFailure = true;
            return;
          }
        }
        const socket = await this.createSocket(chatId);

        socket.send(
          JSON.stringify({
            text: chatText,
            attachment_ids: attachmentIds,
            action_type: "CREATE",
            access_token: this.$store.getters.accessToken,
          })
        );
        socket.close();
      }
      for await (let groupId of chatData.sendToGroups) {
        await this.$apollo.mutate({
          mutation: UPSERT_POST_MUTATION,
          variables: {
            data: {
              content: chatText,
              groupId: decodeId(groupId),
              files: [file],
              type: "IMAGE",
              ...chatData.shareObject,
            },
          },
          refetchQueries: ["posts"],
        });
      }
    },
    async createSocket(chatId) {
      let endPoint = process.env.VUE_APP_API_ENDPOINT;
      endPoint = endPoint.replace("https://", "wss://");
      endPoint = endPoint.replace("http://", "ws://");

      const socket = new WebSocket(`${endPoint}/ws/chat/${chatId}`);
      const ready = new Promise((resolve) => {
        socket.onopen = resolve;
      });
      await ready;
      return socket;
    },
    async uploadAttachment(attachment, time = null) {
      const uploadResult = await this.$apollo.mutate({
        mutation: CREATE_MESSAGE_ATTACHMENTS_MUTATION,
        variables: {
          data: {
            files: [attachment],
            type: "IMAGE",
            timeInSeconds: time,
          },
        },
      });
      if (uploadResult.data.createMessageAttachmentsMutation.success) {
        return uploadResult.data.createMessageAttachmentsMutation.attachmentIds;
      }
    },
  },
};
