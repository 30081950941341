<template>
  <j-card rounded="lg" class="pb-4">
    <v-toolbar class="mb-6">
      <v-toolbar-title>Welcome to this new chat!</v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="$emit('closed')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <simple-chat-header :chat="chat" classes="justify-left mt-n2" />
      <p v-if="chat.creator && $store.getters.user.id != chat.creator.id">
        You were added by <strong>{{ chat.creator.firstName }} {{ chat.creator.lastName }}</strong>
      </p>

      <div class="d-flex align-center">
        <j-checkbox v-model="shareData" hide-details></j-checkbox>
        <div class="mt-4 ml-2">Share my weekly overview of selfies and food pics with members of the chat</div>
      </div>
    </v-card-text>
    <v-card-actions class="justify-center">
      <j-btn primary @click="confirm()">
        <strong class="white--text">Continue into Chat</strong>
      </j-btn>
    </v-card-actions>
  </j-card>
</template>

<script>
import { decodeId } from "@/lib/string";
import SimpleChatHeader from "@/components/chat/SimpleChatHeader";
import { UPSERT_USER_CHAT_MUTATION } from "@/graphql/mutations/chat";

export default {
  name: "NewChat",
  components: {
    SimpleChatHeader,
  },
  data() {
    return {
      shareData: true,
    };
  },
  props: {
    chat: {
      type: Object,
      required: true,
    },
  },
  methods: {
    confirm() {
      this.$apollo.mutate({
        mutation: UPSERT_USER_CHAT_MUTATION,
        variables: {
          data: {
            chatId: decodeId(this.chat.id),
            receiveNotifications: this.chat.userChat.receiveNotifications,
            shareData: this.shareData,
          },
        },
        refetchQueries: ["chatsForUser"],
      });
      // we are not awaiting. Let's just close it optimistically
      this.$emit("closed");
    },
  },
};
</script>
