<template>
  <!-- Some margin bottom so that the box shadow shows and the card looks complete -->
  <v-row justify="center" class="mb-4 mx-0">
    <v-col
      cols="12"
      :md="md"
      :lg="lg"
      :xl="xl"
      align="center"
      justify="center"
      class="j-card-banner-container white pa-0 pb-5 j-elevated-1 rounded-lg"
    >
      <v-skeleton-loader v-if="loading" type="card-avatar, article" />

      <div v-else :class="color" :style="backgroundGradientStyle" class="pt-4 pb-3">
        <div class="d-flex align-center ml-3 mr-6 justify-space-between">
          <div class="d-flex align-center">
            <back-link v-if="showBackLink" icon large :baseRoute="backLink" />
            <h1 class="secondary--text" :class="{ 'ml-5': !showBackLink }">{{ header }}</h1>
          </div>

          <share-link v-if="showShareLink" :share-name="useHeaderTitleInLink ? headerTitle : ''" />
        </div>

        <j-card rounded="sm" class="mx-2 mx-md-8 my-3 j-elevated-3 text-left">
          <v-chip v-if="imageChip && !videoPlaying" small :color="imageChip.color" class="image-chip">{{
            imageChip.text
          }}</v-chip>

          <div style="position: relative">
            <v-img v-if="image && !videoPlaying" :src="image" :lazy-src="image" class="grey banner-image" />

            <div v-if="introVideo">
              <v-fade-transition>
                <div v-show="videoPlaying" class="cld-video-wrapper" id="cld-video-wrapper">
                  <cld-video cloud-name="jeff-fitness" :public-id="introVideo" crossorigin playsinline start_offset="0">
                    <cld-transformation video_codec="auto" height="250" crop="pad" />
                  </cld-video>
                </div>
              </v-fade-transition>
            </div>
          </div>

          <div v-if="showImageUpload" class="text-right mr-3 mt-n11 pointer">
            <image-uploader
              id="banner-uploader"
              :maxWidth="900"
              hidden
              :preview="false"
              :quality="0.8"
              outputFormat="verbose"
              accept="image/*"
              @input="selectImg($event)"
            >
            </image-uploader>

            <v-avatar color="rgb(255, 255, 255, 0.8)" size="40">
              <v-progress-circular v-if="imageLoading" indeterminate color="primary"></v-progress-circular>
              <v-icon v-else @click="clickUploader"> mdi-camera-plus</v-icon></v-avatar
            >
          </div>

          <v-list-item class="px-4 pb-2">
            <v-list-item-content>
              <v-list-item-title>
                <div class="d-flex justify-space-between">
                  <v-card-title class="text-uppercase secondary--text pa-0">
                    <strong class="heading-4">{{ title }}</strong>
                  </v-card-title>
                  <div class="d-flex">
                    <v-btn v-if="showEdit" medium icon @click="$emit('edit')">
                      <v-icon> mdi-pencil </v-icon>
                    </v-btn>
                    <manage-bookmark
                      v-if="bookmark && bookmark.objectId"
                      :objectId="bookmark.objectId"
                      :objectType="bookmark.objectType"
                      :bookmarkId="bookmark.bookmarkId"
                      :objectNotes="bookmark.objectNotes"
                      :refreshQuery="bookmark.refreshQuery"
                    />
                    <v-btn v-if="showDownload" medium icon @click="$emit('download')">
                      <v-icon>mdi-download</v-icon>
                    </v-btn>
                  </div>
                </div>
              </v-list-item-title>
              <v-list-item-subtitle>
                <strong class="text-uppercase gradient-text d-inline" :style="textGradientStyle">{{ subtitle }}</strong>
              </v-list-item-subtitle>
              <div v-if="caption" class="p-text">
                {{ caption }}
              </div>
            </v-list-item-content>
            <v-list-item-avatar v-if="icon" :color="accentColor" size="50">
              <v-img :src="icon" :lazy-src="icon" />
            </v-list-item-avatar>

            <v-icon v-if="introVideo && !videoPlaying" size="80" @click="playCld" color="white" class="video-control"
              >mdi-play-circle-outline</v-icon
            >
            <v-icon
              v-if="introVideo && videoPlaying"
              size="40"
              @click="pauseCld"
              color="white"
              class="video-control video-control-stop"
              >mdi-pause-circle-outline</v-icon
            >
          </v-list-item>

          <slot name="card-content" />
        </j-card>
      </div>
      <slot />
    </v-col>
  </v-row>
</template>

<script>
import BannerContainerMixin from "@/mixins/BannerContainerMixin";
import ImageUploader from "vue-image-upload-resize";
import ManageBookmark from "@/components/bookmarks/block/ManageBookmark";
import ShareLink from "@/components/shared/dialogs/ShareLinkDialog";

export default {
  name: "JCardBannerContainer",
  mixins: [BannerContainerMixin],
  components: {
    ImageUploader,
    ManageBookmark,
    ShareLink,
  },
  data() {
    return {
      file: null,
      videoPlaying: false,
    };
  },
  props: {
    image: {
      type: String,
      required: false,
    },
    subtitle: {
      type: String,
      required: false,
    },
    caption: {
      type: String,
      required: false,
    },
    showImageUpload: {
      type: Boolean,
      default: false,
    },
    imageLoading: {
      type: Boolean,
      default: false,
    },
    showEdit: {
      type: Boolean,
      default: false,
    },
    bookmark: {
      type: Object,
      required: false,
    },
    showDownload: {
      type: Boolean,
      default: false,
    },
    showShareLink: {
      type: Boolean,
      default: false,
    },
    useHeaderTitleInLink: {
      type: Boolean,
      default: false,
    },
    imageChip: {
      type: Object,
      required: false,
    },
    showBackLink: {
      type: Boolean,
      default: true,
    },
    introVideo: {
      type: String,
      required: false,
    },
  },
  mounted() {
    const video = this.video();
    if (video) {
      const $vm = this;
      video.onended = function () {
        $vm.videoPlaying = false;
      };
    }
  },
  methods: {
    video() {
      const wrapper = document.getElementById("cld-video-wrapper");
      const vids = wrapper?.getElementsByTagName("video");
      if (vids?.length > 0) {
        return vids[0];
      }
    },
    playCld() {
      this.videoPlaying = true;
      const video = this.video();
      if (video) {
        video.play();
      }
    },
    pauseCld() {
      this.videoPlaying = false;
      const video = this.video();
      if (video) {
        video.pause();
      }
    },
    clickUploader() {
      document.getElementById("banner-uploader").click();
    },
    selectImg(data) {
      this.$emit("imageUpload", data);
    },
  },
  computed: {
    headerTitle() {
      return `${this.header}: ${this.title}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-image {
  max-height: 250px;
  object-fit: cover;
}
.j-card-banner-container,
.j-card-content {
  .row {
    margin: 0;
  }
}

.image-chip {
  position: absolute;
  top: 5px;
  left: 5px;
  color: white;
  font-weight: bold;
  z-index: 1;
}

.cld-video-wrapper {
  video {
    height: auto;
    width: 100%;
  }
}

.video-control {
  position: absolute;
  top: -90px;
  right: 10px;
  z-index: 200;
}
.video-control-stop {
  top: -60px;
}
</style>
