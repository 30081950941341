<template>
  <v-container>
    <div class="text-center ma-12" v-if="loading">
      <v-progress-circular :indeterminate="loading" color="blue"></v-progress-circular>
    </div>
    <v-row justify="center" v-else>
      <v-col cols="12" md="6" v-if="subscriptionSurvey">
        <j-alert v-if="$route.query.r"
          >Please answer a few questions below before proceeding to your {{ transitionType }}.</j-alert
        >
        <v-card class="mx-auto pb-2" v-if="subscriptionSurvey">
          <v-card-title>
            <div class="text-left my-3" v-html="surveyTitle" />
          </v-card-title>
          <v-card-text>
            <p class="d-block">{{ subscriptionSurvey.survey.description }}</p>
            <div v-for="surveyQuestion in surveyQuestions" :key="surveyQuestion.id" class="mb-8">
              <question-block :surveyQuestion="surveyQuestion" @answerSaved="answerSaved" />
            </div>
          </v-card-text>

          <v-row class="pb-4">
            <v-col cols="12" align="center" justify="center">
              <v-btn color="secondary" class="px-6" rounded @click="submit()" :loading="btnLoading"> Submit </v-btn>
              <v-alert type="warning" outlined v-if="showError" class="mt-4 mx-8">
                Please complete all required questions *
              </v-alert>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="12" md="4" align="center" justify="center" v-else>
        <v-alert type="warning" outlined> Not found </v-alert>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { SUBSCRIPTION_SURVEY_QUERY } from "@/graphql/queries/survey";
import { SUBSCRIPTION_SURVEY_MUTATION } from "@/graphql/mutations/survey";
import { CREATE_TASK_EVENT_MUTATION } from "@/graphql/mutations/task";

export default {
  name: "Survey",
  data() {
    return {
      subscriptionSurvey: null,
      surveyQuestions: [],
      loading: true,
      btnLoading: false,
      showError: false,
    };
  },
  computed: {
    surveyTitle() {
      if (this.subscriptionSurvey) {
        return this.subscriptionSurvey.title ? this.subscriptionSurvey.title : this.subscriptionSurvey.survey.title;
      }
      return null;
    },
    transitionType() {
      const type = this.$route.query.r;
      if (type == "cancel") {
        return "cancellation";
      }
      return "transition";
    },
  },
  methods: {
    async submit() {
      const requiredQuestions = this.surveyQuestions.filter((el) => el.isRequired);
      const flatRequiredQuestions = requiredQuestions.map((q) => q.question.id);
      const flatSelectedAnswers = this.$store.getters.selectedAnswers.map((q) => q.questionId);
      let checker = (arr, target) => target.every((v) => arr.includes(v));
      this.showError = !checker(flatSelectedAnswers, flatRequiredQuestions);

      if (!this.showError) {
        this.btnLoading = true;
        const data = {
          subscriptionSurveyId: this.$store.getters.subscriptionSurveyId,
          submittedDate: this.$moment().format("YYYY-MM-DD"),
        };

        const response = await this.$apollo.mutate({
          mutation: SUBSCRIPTION_SURVEY_MUTATION,
          variables: {
            data: data,
          },
        });

        if (response.data.subscriptionSurveyMutation.success) {
          await this.raiseTasks();
          const redirect = this.$route.query.r;
          // We were directed here from /account/billing, redirect back
          if (redirect) {
            return this.$router.push(`/account/billing?r=${redirect}`);
          }
          window.location.href = "/today?survey=true";
        }
      }
    },
    answerSaved() {
      // when saving an answer, let's just reset the error. It will trigger again on Submit.
      this.showError = false;
    },
    /**
     * HACK. Hard coded Ids to raise tasks
     */
    raiseTasks() {
      /**
       * question 11 = `How likely are you to recommend your 1-on-1 coach`. If less than 6, raise task.
       */
      const nutritionLeadUserId = this.$store.getters.settings.get("USER")?.get("nutrition-lead-user-id");
      const coachAnswer = this.$store.getters.selectedAnswers.find((el) => el.questionId == 11 && el.answer <= 6);
      if (coachAnswer && nutritionLeadUserId) {
        const surveyLink = `/reporting/survey-results/${this.$store.getters.subscriptionSurveyId}`;
        return this.$apollo.mutate({
          mutation: CREATE_TASK_EVENT_MUTATION,
          variables: {
            data: {
              userId: this.$store.getters?.user?.id,
              assignedTo: nutritionLeadUserId,
              title: "Cancellation - Poor Coach Rating",
              description: `Cancellation with Coach Rating of ${coachAnswer.answer}. <a href='${surveyLink}'>View survey</a>`,
              taskTypeTitle: "Re-engage",
            },
          },
        });
      }
    },
  },
  apollo: {
    subscriptionSurvey: {
      query: SUBSCRIPTION_SURVEY_QUERY,
      variables() {
        const subscriptionSurveyId = this.$router.currentRoute.path.split("/").pop();
        // all the controls in `controls/*` require this, so instead of passing props all over the shop, just set in Vuex
        this.$store.dispatch("updateSubscriptionSurveyId", subscriptionSurveyId);
        return {
          subscriptionSurveyId: subscriptionSurveyId,
        };
      },
      fetchPolicy: "network-only",
      result() {
        this.loading = false;
        this.surveyQuestions = [];

        this.subscriptionSurvey.survey.surveyQuestions.forEach((surveyQuestion) => {
          let children = this.subscriptionSurvey.survey.surveyQuestions.filter(
            (element) => element.question.parent && element.question.parent.id == surveyQuestion.question.id
          );

          surveyQuestion["children"] = children;
          if (!surveyQuestion.question.parent) {
            this.surveyQuestions.push(surveyQuestion);
          }
        });
      },
    },
  },
};
</script>
