<template>
  <div>
    <div v-if="$apollo.loading">
      <v-skeleton-loader width="500" v-if="true" type="card-heading@5" class="mx-auto" />
    </div>
    <div v-else>
      <j-card flat>
        <v-tabs v-model="tab" centered color="secondary">
          <v-tab key="communities"> Communities </v-tab>
          <v-tab key="notifications"> Notifications </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item key="communities">
            <v-row justify="center" align="center">
              <v-col v-if="communities && communities.length > 0" cols="12" md="8">
                <j-card
                  v-for="group in communities"
                  :key="group.id"
                  rounded="pill"
                  @click="viewWhatsApp(group.name, group.url)"
                  class="my-3"
                >
                  <v-card-title class="subtitle-1 font-weight-bold secondary--text text-center d-block">
                    {{ group.name }}
                  </v-card-title>
                </j-card>
              </v-col>
              <v-col v-else cols="12" md="8">
                <h4 class="grey-navy--text pa-5">
                  We're in the process of updating our WhatsApp group links. Please check back soon.
                </h4>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item key="notifications">
            <v-row justify="center" align="center">
              <v-col v-if="notifications && notifications.length > 0" cols="12" md="8">
                <j-card
                  v-for="group in notifications"
                  :key="group.id"
                  rounded="pill"
                  class="my-3"
                  @click="viewWhatsApp(group.name, group.url)"
                >
                  <v-card-title class="subtitle-1 font-weight-bold secondary--text text-center d-block">
                    {{ group.name }}
                  </v-card-title>
                </j-card>
              </v-col>
              <v-col v-else cols="12" md="6">
                <h4 class="grey-navy--text pa-5">
                  We're in the process of updating our WhatsApp group links. Please check back soon.
                </h4>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </j-card>
    </div>
  </div>
</template>

<script>
import { MEDIA_QUERY } from "@/graphql/queries/support";

export default {
  name: "Notifications",
  data() {
    return {
      tab: "",
      notifications: [],
      communities: [],
    };
  },
  mounted() {
    window.analytics.page("Notifications");
  },
  methods: {
    viewWhatsApp(name, url) {
      // run after the window opened
      window.analytics.track("Content Resource Viewed", {
        name: name,
        type: "Whatsapp Link",
      });
      window.open(url, "_blank");
    },
  },

  apollo: {
    notifications: {
      query: MEDIA_QUERY,
      variables() {
        return {
          contentType: "WHATSAPP_GROUP",
          orderBy: "name",
        };
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        return data.contentResources;
      },
    },
    communities: {
      query: MEDIA_QUERY,
      variables() {
        return {
          contentType: "WHATSAPP_COMMUNITY",
          orderBy: "name",
        };
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        return data.contentResources;
      },
    },
  },
};
</script>

<style scoped>
.tab-items {
  border: 1px solid #dadae2;
  margin-top: -1px;
}
</style>
