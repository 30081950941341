<template>
  <v-skeleton-loader v-if="$apollo.loading" type="card" />
  <div v-else-if="hasData">
    <highcharts v-if="weightChart()" :options="weightChart()" />
  </div>
  <div v-else>
    <span class="grey-info--text font-italic">More measurements needed</span>
  </div>
</template>

<script>
import { commonChartOptions } from "@/lib/chart";

export default {
  name: "MeasurementsGraph",
  props: {
    userCheckIns: {
      type: Array,
      required: false
    }
  },
  computed: {
    hip() {
      return this.userCheckIns
        .filter((checkin) => checkin.hip != null && !isNaN(checkin.hip))
        .map((checkin) => {
          return [checkin.date, checkin.hip];
        });
    },
    chest() {
      return this.userCheckIns
        .filter((checkin) => checkin.chest != null && !isNaN(checkin.chest))
        .map((checkin) => {
          return [checkin.date, checkin.chest];
        });
    },
    abdomen() {
      return this.userCheckIns
      .filter((checkin) => checkin.abdomen != null && !isNaN(checkin.abdomen))
      .map((checkin) => {
        return [checkin.date, checkin.abdomen];
      });
    },
    quad() {
      return this.userCheckIns
      .filter((checkin) => checkin.quad != null && !isNaN(checkin.quad))
      .map((checkin) => {
        return [checkin.date, checkin.quad];
      });
    },
    hasData() {
      return this.hip.length > 0 || this.chest.length > 0 || this.abdomen.length > 0 || this.quad.length > 0;
    }
  },
  methods: {
    weightChart() {
      if (this.userCheckIns) {
        if (this.userCheckIns?.length == 0) {
          return null;
        }

        return {
          ...commonChartOptions,
          chart: {
            type: "container",
            height: 200,
            zoomType: 'x'
          },
          yAxis: {
            softMin: 50,
            softMax: 100,
            title: {
              text: "cm",
            },
          },
          xAxis: {
            type: "datetime",
            zoomEnabled: true
          },
          tooltip: {
            valueDecimals: 1,
            shared: true,
            valueSuffix: "cm"
          },
          plotOptions: {
            series: {
              relativeXValue: true,
            },
            spline: {
              marker: {
                enabled: true,
              },
            },
          },
          series: [
            {
              type: "spline",
              name: "Hip",
              showInLegend: true,
              data: this.hip,
            },
            {
              type: "spline",
              name: "Chest",
              showInLegend: true,
              data: this.chest,
            },
            {
              type: "spline",
              name: "Abdomen",
              showInLegend: true,
              data: this.abdomen,
            },
            {
              type: "spline",
              name: "Quad",
              showInLegend: true,
              data: this.quad,
            },
          ],
        };
      }
    },
  },
};
</script>
