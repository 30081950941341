import gql from "graphql-tag";

export const CREATE_MESSAGE_ATTACHMENTS_MUTATION = gql`
  mutation createMessageAttachmentsMutation($data: CreateMessageAttachmentsInput!) {
    createMessageAttachmentsMutation(data: $data) {
      success
      errors
      attachmentIds
    }
  }
`;

export const CREATE_COACHING_CHAT_MUTATION = gql`
  mutation createCoachingChatMutation($userId: ID, $coachId: ID, $description: String, $coachHandle: String,) {
    createCoachingChatMutation(userId: $userId, coachId: $coachId, description: $description, coachHandle: $coachHandle) {
      success
      error
      id
    }
  }
`;

export const UPDATE_USER_CHAT_LAST_VIEWED_TIMESTAMP_MUTATION = gql`
  mutation updateUserChatLastViewedTimestampMutation($chatId: ID!) {
    updateUserChatLastViewedTimestampMutation(chatId: $chatId) {
      success
      error
    }
  }
`;

export const UPSERT_USER_CHAT_MUTATION = gql`
  mutation upsertUserChatMutation($data: UserChatInput!) {
    upsertUserChatMutation(data: $data) {
      success
      error
    }
  }
`;

export const UPSERT_CHAT_MUTATION = gql`
  mutation upsertChatMutation($data: ChatInput!) {
    upsertChatMutation(data: $data) {
      success
      error
    }
  }
`;

export const LEAVE_CHAT_MUTATION = gql`
  mutation leaveChatMutation($chatId: ID!) {
    leaveChatMutation(chatId: $chatId) {
      success
    }
  }
`;

export const CREATE_CHAT_REGISTRATION_MUTATION = gql`
  mutation createChatRegistrationMutation($workoutBlockId: ID) {
    createChatRegistrationMutation(workoutBlockId: $workoutBlockId) {
      success
      error
    }
  }
`;

export const GET_OR_CREATE_USER_CHAT_MUTATION = gql`
  mutation getOrCreateUserChatMutation($friendId: ID!) {
    getOrCreateUserChatMutation(friendId: $friendId) {
      success
      error
      id
    }
  }
`;
