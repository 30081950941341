<template>
  <div>
    <j-banner-container
      header="Past habits"
      :gradient="['green-background', 'yellow-background']"
    >
      <j-card v-for="habit in habits" :key="habit.id" class="j-elevated-2 ma-5 pa-2">
        <v-card-text>
          <div class="d-flex flex-row justify-space-between">
            <div class="d-flex flex-column text-left">
              <h2 v-if="habit.title" class="navy--text heading-3">{{ habit.title }}</h2>
              <h2 v-else-if="habit.habitOption && habit.habitOption.title" class="navy--text heading-3">
                {{ habit.habitOption.title }}
              </h2>
            </div>
          </div>
          <habit-dots :completed="habit.completed" :missed="habit.missed"/>
        </v-card-text>
      </j-card>
    </j-banner-container>
  </div>
</template>

<script>
import { HABITS_QUERY } from "@/graphql/queries/habits";
import HabitDots from "@/components/tracker/habit/HabitDots";

export default {
  name: "PastHabits",
  components: {
    HabitDots,
  },
  apollo: {
    habits: {
      query: HABITS_QUERY,
      variables() {
        return {
          date: this.$moment().format("YYYY-MM-DD"),
        };
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        return data.habitsQuery?.edges.map((edge) => edge.node);
      },
    },
  },
};
</script>
