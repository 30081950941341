var render = function render(){var _vm=this,_c=_vm._self._c;return _c('j-card',[_c('v-card-title',[_vm._v(_vm._s(_vm.pageTitle))]),_c('v-card-text',[(_vm.newMeetup)?_c('div',[_c('p',[_vm._v(" Thank you for submitting your event request. You can print the following details to send to a friend or to save for yourself. ")]),_c('j-btn',{attrs:{"color":"whatsapp-green","large":"","target":"_blank","href":_vm.whatsappShareUrl}},[_c('strong',[_vm._v("Share via Whatsapp"),_c('v-icon',{staticClass:"pb-1 pl-1"},[_vm._v("mdi-whatsapp")])],1)])],1):_c('p',[_vm._v("The details for your event have been updated.")]),_c('v-card-title',[_vm._v("Event Details:")]),_c('v-simple-table',{staticClass:"std-text"},[_c('tbody',[_c('tr',[_c('td',[_vm._v("Event Name")]),_c('td',[_vm._v(_vm._s(_vm.meetingDetails.title))])]),_c('tr',[_c('td',[_vm._v("Contact Number")]),_c('td',[_vm._v(_vm._s(_vm.meetingDetails.contactNumber))])]),_c('tr',[_c('td',[_vm._v("Event Type")]),_c('td',[_vm._v(_vm._s(_vm.meetingType))])]),(_vm.meetingDetails.type === 'virtual')?_c('tr',[_c('td',[_vm._v("Meeting Link")]),_c('td',[_vm._v(_vm._s(_vm.meetingDetails.meetingLink))])]):_vm._e(),_c('tr',[_c('td',[_vm._v("Date & Time")]),_c('td',[_vm._v(_vm._s(_vm.meetingDateTime))])]),_c('tr',[_c('td',[_vm._v("Maximum Attendees")]),_c('td',[_vm._v(_vm._s(_vm.meetingDetails.capacity))])]),(_vm.meetingDetails.location !== '' && _vm.meetingDetails.type === 'physical')?_c('tr',[_c('td',{attrs:{"colspan":"2"}},[_c('p',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.meetingDetails.location))]),_c('google-map',{staticStyle:{"width":"100%","height":"300px","position":"relative"},attrs:{"center":_vm.mapLocation,"zoom":14,"options":{
                zoomControl: false,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUi: false,
                draggable: false,
              }}},[_c('google-marker',{attrs:{"position":_vm.mapLocation,"clickable":true,"draggable":false}})],1)],1)]):_vm._e(),(_vm.meetingDetails.longitude && _vm.meetingDetails.latitude && _vm.meetingDetails.type === 'physical')?_c('tr',[_c('td',[_vm._v("Coordinates")]),_c('td',[_vm._v(_vm._s(_vm.meetingDetails.latitude)+","+_vm._s(_vm.meetingDetails.longitude))])]):_vm._e(),_c('tr',[_c('td',[_vm._v("Information About Event")]),_c('td',[_vm._v(_vm._s(_vm.meetingDetails.notes))])])])]),_c('h3',{staticClass:"mt-5"},[_vm._v("What Now?")]),_c('p',{staticClass:"mt-2"},[_vm._v("Your event is ready to be shared with others.")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","align":"center","justify":"center"}},[_c('j-btn',{attrs:{"secondary":"","large":"","to":"/social/community-fitness"}},[_vm._v("Go to events")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }