<template>
  <j-banner-container
    header="Programs, Menus &amp; Courses"
    :gradient="['red-background', 'yellow-background']"
    search
    :search-val="search"
    @searchValueChanged="updateSearch"
    show-share-link
    use-header-in-link
    back-link="/search"
  >
    <template v-slot:header-section>
      <div class="justify-center">
        <j-btn to="/your-programs" tertiary small narrow class="mx-3 mt-3"
          >Manage programs <v-icon size="19">mdi-sync</v-icon></j-btn
        >
        <j-btn to="/journey" small narrow max-width="310" color="navy" class="mt-3"
          >My {{ $appName }} Journey <v-icon size="19">mdi-calendar-arrow-right</v-icon></j-btn
        >
      </div>
    </template>
    <div class="px-2">
      <div class="d-flex justify-space-between text-left pt-2 mr-2">
        <div>
          <v-btn small text class="grey-navy--text" @click="showFilters = !showFilters">
            <strong>Filter</strong>
          </v-btn>
          <v-avatar color="blue" size="20" class="ml-1 sm-text white--text">{{ selectedFilterCount }}</v-avatar>
        </div>
        <j-btn v-if="selectedFilterCount > 0" tertiary narrow small @click="reset">RESET</j-btn>
      </div>

      <div v-if="showFilters" class="mx-2 mb-n8">
        <v-radio-group v-model="selectedBlockType" row>
          <j-radio
            v-for="blockType in blockTypes"
            :key="blockType.value"
            :label="blockType.text"
            :value="blockType.value"
            :disabled="selectedBlockType == blockType.value"
            @click="changeBlockType"
          />
        </v-radio-group>

        <v-row class="mx-n3">
          <v-col v-if="selectedBlockType == 'EXERCISE'" cols="12" md="6"
            ><j-select
              :class="{ 'mt-n2 mb-n8': $vuetify.breakpoint.smAndDown }"
              v-model="fitnessLevelFilter"
              label="Fitness Level"
              multiple
              :items="fitnessLevelTags"
              @change="triggerFilters"
          /></v-col>

          <v-col cols="12" :md="instructorCols">
            <j-select
              v-model="instructorsFilter"
              label="Instructor"
              multiple
              :items="instructors"
              @change="triggerFilters"
            />
          </v-col>
          <v-col v-if="selectedBlockType == 'EXERCISE'">
            <j-select
              class="mt-n8"
              v-model="specialityFilter"
              label="Speciality"
              multiple
              :items="specialityTags"
              @change="triggerFilters"
            />
          </v-col>

          <v-col v-if="selectedBlockType == 'NUTRITION' && primaryNutritionPreferences" cols="12" class="mt-n8">
            <j-select
              v-model="nutritionPreferenceFilter"
              label="Diet Preference"
              multiple
              :items="primaryNutritionPreferences"
              @change="triggerFilters"
            />
          </v-col>
        </v-row>
      </div>

      <div v-if="$apollo.loading && blockList.length == 0">
        <v-row>
          <v-col cols="6"><v-skeleton-loader type="card-avatar" /> </v-col>
          <v-col cols="6"><v-skeleton-loader type="card-avatar" /> </v-col>
          <v-col cols="6"><v-skeleton-loader type="card-avatar" /> </v-col>
          <v-col cols="6"><v-skeleton-loader type="card-avatar" /> </v-col>
          <v-col class="hidden-sm-and-down" cols="4"><v-skeleton-loader type="card-avatar" /> </v-col>
          <v-col class="hidden-sm-and-down" cols="4"><v-skeleton-loader type="card-avatar" /> </v-col>
        </v-row>
      </div>

      <!-- block listing -->
      <v-row v-if="selectedBlockType != 'NUTRITION' && blockList.length > 0">
        <v-col cols="6" v-for="block in blockList" :key="block.id">
          <block-listing-card :block="block.node" type="EXERCISE" />
        </v-col>
      </v-row>

      <v-row v-if="selectedBlockType == 'NUTRITION' && nutritionBlockList.length > 0">
        <v-col cols="6" v-for="block in nutritionBlockList" :key="block.id">
          <block-listing-card :block="block.node" type="NUTRITION" />
        </v-col>
      </v-row>
      <!-- end block listing -->

      <div v-if="noProgramsFound">
        <j-alert class="ma-4"
          >There are currently no programs available for you to join.
          <span v-if="selectedBlockType == 'NUTRITION_PROGRAM'"
            >Browse our
            <span
              @click="
                selectedBlockType = 'NUTRITION';
                triggerFilters();
              "
              class="pointer font-weight-bold text-decoration-underline"
              >menus</span
            >.</span
          >
        </j-alert>
      </div>
    </div>
    <scroll-loader :loader-method="loadmore" :loader-disable="!hasNextPage || noProgramsFound"> </scroll-loader>
  </j-banner-container>
</template>

<script>
import { WORKOUT_BLOCKS_QUERY } from "@/graphql/queries/workoutblock";
import { CLIENTS_QUERY } from "@/graphql/queries/account";
import { NUTRITION_BLOCKS_QUERY } from "@/graphql/queries/nutritionblock";
import { NUTRITION_PREFERENCES_QUERY } from "@/graphql/queries/nutrition";
import { TAGS_QUERY } from "@/graphql/queries/tag";
import BlockListingCard from "@/components/shared/blocks/BlockListingCard";
import { debounce } from "@/lib/helpers";
import { decodeId } from "@/lib/string";

export default {
  name: "ProgramList",
  components: {
    BlockListingCard,
  },
  data() {
    return {
      blockList: [],
      nutritionBlockList: [],
      search: "",
      after: "",
      endCursor: "",
      hasNextPage: false,
      scrollY: 0,
      showFilters: true,
      // filters
      instructorsFilter: [],
      fitnessLevelFilter: [],
      specialityFilter: [],
      nutritionPreferenceFilter: [],
      primaryNutritionPreferences: null,

      // default to the Exercise Types
      typeFilter: ["PROGRAM", "CHALLENGE"],
      blockTypes: [
        { value: "EXERCISE", text: "Exercise" },
        { value: "NUTRITION_PROGRAM", text: "Nutrition" },
        { value: "NUTRITION", text: "Menus" },
        { value: "COURSE", text: "Courses" },
      ],
      selectedBlockType: "EXERCISE",
    };
  },
  computed: {
    instructors() {
      let instructors;
      if (this.selectedBlockType === "EXERCISE") {
        instructors = this.clients?.edges.filter((item) => item.node.user.userProfile.numExerciseBlocksInstructing > 0);
      } else if (this.selectedBlockType === "COURSE") {
        instructors = this.clients?.edges.filter((item) => item.node.user.userProfile.numMindsetBlocksInstructing > 0);
      } else if (this.selectedBlockType === "NUTRITION") {
        instructors = this.clients?.edges.filter(
          (item) => item.node.user.userProfile.numNutritionBlocksInstructing > 0
        );
      } else if (this.selectedBlockType === "NUTRITION_PROGRAM") {
        instructors = this.clients?.edges.filter(
          (item) => item.node.user.userProfile.numNutritionProgramsInstructing > 0
        );
      }

      if (instructors) {
        return instructors.map((item) => {
          let cnt;
          if (this.selectedBlockType === "EXERCISE") {
            cnt = item.node.user.userProfile.numExerciseBlocksInstructing;
          } else if (this.selectedBlockType === "COURSE") {
            cnt = item.node.user.userProfile.numMindsetBlocksInstructing;
          } else if (this.selectedBlockType === "NUTRITION") {
            cnt = item.node.user.userProfile.numNutritionBlocksInstructing;
          } else if (this.selectedBlockType === "NUTRITION_PROGRAM") {
            cnt = item.node.user.userProfile.numNutritionProgramsInstructing;
          }
          return {
            text: `${item.node.user.firstName} ${item.node.user.lastName} (${cnt})`,
            value: decodeId(item.node.user.id),
          };
        });
      }
      return [];
    },
    selectedFilterCount() {
      let cnt = 0;
      cnt += this.specialityFilter.length;
      cnt += this.fitnessLevelFilter.length;
      cnt += this.instructorsFilter.length;
      cnt += this.nutritionPreferenceFilter.length;

      return cnt;
    },
    instructorCols() {
      return this.selectedBlockType == "EXERCISE" ? 6 : 12;
    },
    noProgramsFound() {
      if (this.selectedBlockType != "NUTRITION") {
        return !this.$apollo.loading && this.blockList.length == 0;
      }
      return !this.$apollo.loading && this.nutritionBlockList.length == 0;
    },
  },
  methods: {
    reset() {
      this.instructorsFilter = [];
      this.fitnessLevelFilter = [];
      this.specialityFilter = [];
      this.nutritionPreferenceFilter = [];
      this.doSearch();
    },
    changeBlockType() {
      this.instructorsFilter = [];
      this.setBlockType();
      this.triggerFilters();
    },
    setBlockType() {
      if (this.selectedBlockType == "EXERCISE") {
        this.typeFilter = ["PROGRAM", "CHALLENGE"];
      }
      if (this.selectedBlockType == "COURSE") {
        this.fitnessLevelFilter = [];
        this.specialityFilter = [];
        this.typeFilter = ["COURSE"];
      }
      if (this.selectedBlockType == "NUTRITION_PROGRAM") {
        this.fitnessLevelFilter = [];
        this.specialityFilter = [];
        this.typeFilter = ["NUTRITION"];
      }
      if (this.selectedBlockType == "NUTRITION") {
        this.fitnessLevelFilter = [];
        this.specialityFilter = [];
        this.typeFilter = [];
      }
    },
    triggerFilters() {
      // if (this.selectedFilterCount == 0) {
      //   return;
      // }
      const searchParams = this.buildSearchParams();
      if (searchParams.length > 0) {
        history.pushState({}, null, this.$route.path + "?" + this.buildSearchParams().join("&"));
      } else {
        history.pushState({}, null, this.$route.path);
      }
      this.doSearch();
    },
    updateSearch(value) {
      this.search = value;
      this.doSearch();
    },
    doSearch() {
      this.blockList = [];
      this.nutritionBlockList = [];
      this.after = "";
      this.endCursor = "";
      this.hasNextPage = true;

      this.$route.query.t = null;
      this.$route.query.f = null;
      // when we update search, start from scratch with regards to url params
      const searchParams = this.buildSearchParams();
      if (searchParams.length > 0) {
        history.pushState({}, null, this.$route.path + "?" + searchParams.join("&"));
      } else {
        history.pushState({}, null, this.$route.path);
      }
    },
    loadmore() {
      if (!this.loading) {
        this.after = this.endCursor;
      }
    },
    handleScroll() {
      if (this.$route.name == "ProgramList") {
        history.pushState({}, null, this.$route.path + "?" + this.buildScrollParams().join("&"));
      }
    },
    buildSearchParams() {
      let paramList = [];

      if (this.selectedBlockType) {
        paramList.push("type=" + encodeURIComponent(this.selectedBlockType));
      }

      if (this.search) {
        paramList.push("s=" + encodeURIComponent(this.search));
      }

      if (this.instructorsFilter.length > 0) {
        // instructors (i)
        paramList.push("i=" + JSON.stringify(this.instructorsFilter));
      }

      if (this.specialityFilter.length > 0) {
        // speciality Tag (st)
        paramList.push("st=" + JSON.stringify(this.specialityFilter));
      }

      if (this.fitnessLevelFilter.length > 0) {
        // fitness leavel Tag (ft)
        paramList.push("ft=" + JSON.stringify(this.fitnessLevelFilter));
      }

      if (this.nutritionPreferenceFilter.length > 0) {
        // nutrition preference Tag (np)
        paramList.push("np=" + JSON.stringify(this.nutritionPreferenceFilter));
      }

      return paramList;
    },
    buildScrollParams() {
      // start with the search params and then add
      let paramList = this.buildSearchParams();

      if (this.scrollY) {
        paramList.push("t=" + encodeURIComponent(this.scrollY));
      }

      let list = this.nutritionBlockList;
      if (this.selectedBlockType != "NUTRITION") {
        list = this.blockList.length;
      }
      if (list.length) {
        paramList.push("f=" + encodeURIComponent(list.length));
      }
      return paramList;
    },
  },
  apollo: {
    workoutBlocks: {
      query: WORKOUT_BLOCKS_QUERY,
      fetchPolicy: "network-only",
      variables() {
        return {
          search: this.search,
          typeIn: this.typeFilter,
          fitnessLevelTagsIn: this.fitnessLevelFilter,
          specialityTagsIn: this.specialityFilter,
          instructorsIn: this.instructorsFilter,
          after: this.after,
          first: this.$route.query.f ?? 12,
          withUsers: false,
          hideOnWebApp: false,
        };
      },
      skip() {
        return this.selectedBlockType == "NUTRITION";
      },
      result(response) {
        if (response.data) {
          this.loading = false;
          this.endCursor = response.data.workoutBlocks.pageInfo.endCursor;
          this.hasNextPage = response.data.workoutBlocks.pageInfo.hasNextPage;
          this.blockList = [...this.blockList, ...response.data.workoutBlocks.edges];

          // if we have a t (top) query string param when loading, then scroll to that point.
          if (this.$route.query.t > 0) {
            setTimeout(() => {
              window.scrollTo({
                top: this.$route.query.t,
                left: 0,
                behavior: "auto",
              });
            }, 200);
          }
        }
      },
    },
    fitnessLevelTags: {
      query: TAGS_QUERY,
      variables() {
        return {
          category: "FITNESS_LEVEL",
        };
      },
      update: (data) => data.tags,
      result(response) {
        if (response.data) {
          this.loading = false;
          this.fitnessLevelTags = response.data.tags.edges.map((item) => {
            return {
              text: item.node.title,
              value: decodeId(item.node.id),
            };
          });
        }
      },
    },
    specialityTags: {
      query: TAGS_QUERY,
      variables() {
        return {
          category: "SPECIALITY",
        };
      },
      update: (data) => data.tags,
      result(response) {
        if (response.data) {
          this.loading = false;
          this.specialityTags = response.data.tags.edges.map((item) => {
            return {
              text: item.node.title,
              value: decodeId(item.node.id),
            };
          });
        }
      },
    },
    clients: {
      query: CLIENTS_QUERY,
      variables() {
        return {
          isWorkoutBlockInstructor: true,
          orderBy: "user__first_name",
        };
      },
      fetchPolicy: "cache-and-network",
    },
    nutritionPreferenceList: {
      query: NUTRITION_PREFERENCES_QUERY,
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data) {
          const primaryNutritionPreferences = response.data.nutritionPreferenceList.filter(
            (el) => el.isPrimary == true
          );

          this.primaryNutritionPreferences = primaryNutritionPreferences.map((pref) => {
            if (pref.title === "None") {
              pref.title = "Balanced";
            }
            return { text: pref.title, value: pref.id };
          });
        }
      },
    },
    nutritionBlocks: {
      query: NUTRITION_BLOCKS_QUERY,
      fetchPolicy: "network-only",
      variables() {
        return {
          isActive: true,
          showInSearches: true,
          search: this.search,
          leadInstructorsIn: this.instructorsFilter,
          nutritionPreferenceIn: this.nutritionPreferenceFilter,
          orderBy: "-createdAt",
        };
      },
      result(response) {
        if (response.data) {
          this.loading = false;
          this.endCursor = response.data.nutritionBlocks.pageInfo.endCursor;
          this.hasNextPage = response.data.nutritionBlocks.pageInfo.hasNextPage;
          this.nutritionBlockList = [...this.nutritionBlockList, ...response.data.nutritionBlocks.edges];

          // if we have a t (top) query string param when loading, then scroll to that point.
          if (this.$route.query.t > 0) {
            setTimeout(() => {
              window.scrollTo({
                top: this.$route.query.t,
                left: 0,
                behavior: "auto",
              });
            }, 200);
          }
        }
      },
      skip() {
        return this.selectedBlockType != "NUTRITION";
      },
    },
  },
  created() {
    if (this.$route.query.type) {
      this.selectedBlockType = this.$route.query.type;
      this.setBlockType();
    }

    if (this.$route.query.s) {
      this.search = this.$route.query.s;
    }

    if (this.$route.query.st) {
      this.specialityFilter = JSON.parse(this.$route.query.st);
    }

    if (this.$route.query.ft) {
      this.fitnessLevelFilter = JSON.parse(this.$route.query.ft);
    }

    if (this.$route.query.i) {
      this.instructorsFilter = JSON.parse(this.$route.query.i);
    }

    if (this.$route.query.np) {
      this.nutritionPreferenceFilter = JSON.parse(this.$route.query.np);
    }

    // listen to scroll event and store in variable. Every x ms, we add it to the querystring.
    window.addEventListener("scroll", () => {
      this.scrollY = scrollY;
      debounce(this.handleScroll, 400);
    });
  },
};
</script>
