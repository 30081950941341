import { render, staticRenderFns } from "./MedicalAssessment.vue?vue&type=template&id=15c94a81&scoped=true&"
import script from "./MedicalAssessment.vue?vue&type=script&lang=js&"
export * from "./MedicalAssessment.vue?vue&type=script&lang=js&"
import style0 from "./MedicalAssessment.vue?vue&type=style&index=0&id=15c94a81&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15c94a81",
  null
  
)

export default component.exports