<template>
  <calendar-dot v-bind="$props" v-on="$listeners" :class="{'white-background': setWhiteBackground }">
    <template v-slot:dot-content>
      <div v-if="workoutImage" class="d-flex selfie-icon relative">
        <v-avatar :size="size * 0.65" class="sweaty-selfie">
          <v-img alt="Sweaty selfie" :src="workoutImage" :aspect-ratio="1 / 1" />
        </v-avatar>
      </div>
    </template>
  </calendar-dot>
</template>

<script>
import CalendarDot from "./CalendarDot";

export default {
  name: "WorkoutCalendarDot",
  extends: CalendarDot,
  components: {
    CalendarDot,
  },
  props: {
    workoutImage: {
      type: String,
      required: false,
    },
    setWhiteBackground: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<style lang="scss" scoped>
.sweaty-selfie {
  position: absolute;
  z-index: 2;
  left: -1.5px;
  top: -1px;
}
</style>
