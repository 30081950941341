import gql from "graphql-tag";

export const POSTS_QUERY = gql`
  query posts(
    $forGroupId: String
    $forActivityId: String
    $forEventId: String
    $forRecipeId: String
    $after: String
    $forUserId: String
    $forUserPostsId: String
    $groupTypeIn: [String]
    $showAll: Boolean
    $dedupeUser: Boolean
    $group_Permission: String
  ) {
    posts(
      forGroupId: $forGroupId
      forActivityId: $forActivityId
      forEventId: $forEventId
      forRecipeId: $forRecipeId
      forUserId: $forUserId
      forUserPostsId: $forUserPostsId
      group_Type_In: $groupTypeIn
      showAll: $showAll
      dedupeUser: $dedupeUser
      first: 10
      after: $after
      group_Permission: $group_Permission
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          likeCount
          commentCount
          createdAt
          updatedAt
          content
          url
          allowSharing
          user {
            id
            firstName
            lastName
            isStaff
            userProfile {
              id
              profilePicture
              userType
              activeTribe {
                id
                title
                hexColour
              }
            }
          }
          postattachmentSet {
            id
            file
            remoteFile
            type
            timeInSeconds
          }
          userPostLike {
            id
          }
          event {
            id
            name
          }
          recipe {
            id
            title
          }
          activity {
            id
            title
            unitactivitySet {
              id
              title
              day
              unit {
                id
                order
                block {
                  id
                  title
                }
              }
            }
          }
          group {
            id
            title
            workoutBlock {
              id
              visible
            }
            nutritionBlock {
              id
              showInSearches
            }
          }
        }
      }
    }
  }
`;

export const POST_QUERY = gql`
  query post($id: ID!) {
    post(id: $id) {
      id
      likeCount
      commentCount
      createdAt
      updatedAt
      content
      url
      allowSharing
      user {
        id
        firstName
        lastName
        userProfile {
          id
          profilePicture
          activeTribe {
            id
            title
            hexColour
          }
        }
      }
      postattachmentSet {
        id
        file
        remoteFile
        type
        timeInSeconds
      }
      userPostLike {
        id
      }
      group {
        id
        title
      }
      activity {
        id
        title
      }
    }
  }
`;

export const COMMENTS_QUERY = gql`
  query comments($postId: ID, $after: String, $hideChildComments: Boolean) {
    comments(postId: $postId, after: $after, parentCommentId_Isnull: $hideChildComments) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          text
          updatedAt
          parentComment {
            id
          }
          user {
            id
            firstName
            lastName
            isStaff
            userProfile {
              id
              profilePicture
              userType
              activeTribe {
                id
                title
                hexColour
              }
            }
          }
          commentSet {
            edges {
              node {
                id
                text
                updatedAt
                parentComment {
                  id
                }
                user {
                  id
                  firstName
                  lastName
                  userProfile {
                    id
                    profilePicture
                    activeTribe {
                      id
                      title
                      hexColour
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
