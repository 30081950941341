<template>
  <j-card class="text-left mb-4 j-elevated-2">
    <div v-if="awardedBy" class="pt-10 text-center">
      <v-avatar size="80">
        <v-img
          max-height="80"
          max-width="80"
          :src="awardedBy.userProfile.profilePicture"
          :lazy-src="awardedBy.userProfile.profilePicture"
          class="center-element"
        ></v-img>
      </v-avatar>
    </div>
    <div v-if="coachComment" class="text-center ma-5">
      <span class="d-inline font-italic font-weight-bold quote pr-2"> "{{ coachComment }}" </span>
    </div>
    <v-toolbar flat class="pa-0 ma-0">
      <v-spacer />
      <v-icon right :color="earned ? 'yellow' : 'grey'">mdi_star</v-icon>
    </v-toolbar>

    <v-card-text class="d-flex my-0 py-0">
      <div class="mr-2">
        <v-avatar size="100">
          <v-img :src="badge.iconLink"></v-img>
        </v-avatar>
      </div>
      <div class="flex-grow-1">
        <h3 class="block heading-4 navy--text pt-2">{{ badge.title }}</h3>
        <span>{{ badge.description }}</span>
      </div>
    </v-card-text>
    <v-toolbar v-if="earned" flat class="pa-0 ma-0">
      <v-spacer />
      <span class="grey-navy--text font-weight-bold p-text">Earned On: {{ earnedOn(earnedDate) }}</span>
    </v-toolbar>
  </j-card>
</template>

<script>
import { fancy_date_from_iso } from "@/lib/fancy_date";

export default {
  name: "BadgeCard",
  props: {
    badge: {
      type: Object,
      default: null,
    },
    earned: {
      type: Boolean,
      default: false,
    },
    earnedDate: {
      type: String,
      default: null,
    },
    coachComment: {
      type: String,
      default: null,
    },
    awardedBy: {
      type: Object,
      default: null,
    },
  },
  methods: {
    earnedOn(date) {
      return fancy_date_from_iso(date, "ddd, D MMMM YYYY", false);
    },
  },
};
</script>

<style scoped>
.quote {
  font-size: 18px;
}
</style>
