import gql from 'graphql-tag'

export const GYM_BRANCHES = gql`
query gymBranches {
  gymBranches {
    id
    title
  }
}
`

export const GYM_TIMETABLE = gql`
  query gymTimetable($gymId: ID!, $weekStart: Date) {
    gymTimetable(gymId: $gymId, weekStart: $weekStart)
  }
`;