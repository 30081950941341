<template>
  <div>
    <div class="d-flex justify-space-between mb-4">
      <j-btn @click="$emit('back')" narrow tertiary class="mt-n1"><v-icon color="navy">mdi-arrow-left</v-icon> Back</j-btn>

      <j-btn @click="scrollBottom()" narrow secondary>Send <v-icon color="white" class="mt-n1">mdi-send</v-icon></j-btn>
    </div>

    <div class="d-flex justify-center mt-n2">
      <j-btn primary @click="download()" narrow class="mb-2">Download <v-icon color="white">mdi-download</v-icon></j-btn>
    </div>
    <p class="mb-2 mt-2">Celebration is ready to share <v-icon color="green">mdi-check-circle</v-icon></p>
    <v-img
      v-if="data.celebrationImage"
      :lazy-src="data.celebrationImage"
      :src="data.celebrationImage"
      class="rounded-lg"
    />

    <h3 class="text-left navy--text mt-4">Share your celebration</h3>
    <share-dialog :share-object="shareObject" :quote="quote" share-with-chats :show-toolbar="false" />
  </div>
</template>

<script>
import { urlToDataURL } from "@/lib/file";
import ShareDialog from "@/components/shared/dialogs/ShareDialog";
export default {
  name: "CelebrationStepThree",
  components: {
    ShareDialog,
  },
  props: {
    celebrationData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      data: {
        celebrationImage: null,
        weight: null,
        size: null,
        cm: null,
        months: null,
      },
    };
  },
  watch: {
    celebrationData() {
      this.setData();
    },
  },
  created() {
    this.setData();
  },
  computed: {
    shareObject() {
      return {
        media: this.data.celebrationImage,
        title: `Check out my progress with ${this.$appName}`,
        quote: `Check my progress with ${this.$appName}`,
        type: "IMAGE",
      };
    },
    quote() {
      return `Check out my progress with ${this.$appName}`;
    },
  },
  methods: {
    scrollBottom() {
      window.scrollTo(0, document.body.scrollHeight);
    },
    setData() {
      this.data.celebrationImage = this.celebrationData.celebrationImage;
      this.data.weight = this.celebrationData.weight;
      this.data.size = null;
      this.data.cm = this.celebrationData.cm;
      this.data.months = this.celebrationData.months;
    },
    async download() {
      const a = document.createElement("a");
      let imgDataUrl;
      const ready = new Promise((resolve) => {
        urlToDataURL(this.data.celebrationImage, async function(dataUrl) {
          imgDataUrl = dataUrl;
          resolve();
        });
        return;
      });
      await ready;

      const date = this.$moment().format("DD-MM-YYYY");
      a.href = imgDataUrl;
      a.download = `celebration_${date}.jpg`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
  },
};
</script>
