import gql from "graphql-tag";

export const SEND_LEAD_CAMPAIGN_EMAIL_MUTATION = gql`
  mutation sendLeadCampaignEmail($leadCampaignId: ID!, $email: String!, $firstName: String!) {
    sendLeadCampaignEmail(leadCampaignId: $leadCampaignId, email: $email, firstName: $firstName) {
      error
      success
    }
  }
`;

export const CREATE_AFFILIATE = gql`
  mutation createAffiliate($email: String!) {
    createAffiliate(email: $email) {
      error
      success
    }
  }
`;
