<template>
  <div>
    <j-emoji-textarea
      v-model="content"
      :disabled="saving"
      class="flex-grow-1 rounded-xl mb-n4"
      :placeholder="`Share something`"
      :include-mentions="true"
      :mention-values-lookup="mentionSearch"
      :position-emojis-up="!groupId"
      auto-grow
      outlined
      rows="1"
      row-height="15"
    />
    <div class="d-flex justify-end align-center">
      <v-btn medium icon class="pointer pr-1" @click="clickUploader">
        <div class="text-left pointer">
          <image-uploader
            id="post-image-uploader"
            ref="imageUploader"
            :maxWidth="800"
            hidden
            :preview="false"
            :quality="0.9"
            outputFormat="verbose"
            accept="image/*"
            @input="selectImg($event)"
          />

          <v-avatar color="rgb(0, 0, 0, 0.1)" size="40">
            <v-icon>mdi-camera-plus</v-icon>
          </v-avatar>
        </div>
      </v-btn>

      <video-recorder class="ml-2" @sendVideo="saveVideoFile($event)" send-icon="mdi-stop" />
      <file-uploader class="d-flex ml-2 align-center" @sendFile="saveFile($event)" @error="errorMessage = $event" />

      <j-btn
        class="ml-4"
        :loading="saving"
        :disabled="!postButtonEnabled"
        @click="submit()"
        :secondary="!!content"
        :tertiary="!content"
      >
        <span v-if="postId">Update</span>
        <span v-else>Post</span>
      </j-btn>
    </div>
    <div v-if="saving && uploadPercentCompleted > 0" class="mt-2 px-4">
      <upload-progress :upload-percent-completed="uploadPercentCompleted" />
    </div>
    <div class="d-flex flex-wrap align-center" v-if="files.length > 0 && type == 'IMAGE'">
      <v-card
        v-for="(file, index) in files"
        :key="index"
        tile
        width="30%"
        class="rounded ma-2 d-flex relative"
        :disabled="saving"
      >
        <v-btn icon color="navy" class="clear-photo-btn" @click="remove(file)">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
        <v-img
          :src="file.dataUrl || file.file"
          :lazy-src="file.dataUrl || file.file"
          contain
          class="rounded align-self-center"
        />
      </v-card>
      <v-avatar class="ml-2" color="rgb(0, 0, 0, 0.1)" size="40">
        <v-icon @click="clickUploader">mdi-camera-plus</v-icon>
      </v-avatar>
    </div>

    <div class="d-flex flex-wrap align-center" v-if="files.length > 0 && type == 'VIDEO'">
      <v-card tile class="rounded ma-2 d-flex relative">
        <v-btn icon color="navy" class="clear-photo-btn">
          <v-icon small @click="files = []">mdi-close</v-icon>
        </v-btn>
        <v-card-text><v-icon color="green" x-large>mdi-video</v-icon></v-card-text>
      </v-card>
    </div>

    <div v-if="errorMessage" class="mx-4 mt-2">
      <j-alert>{{ errorMessage }}</j-alert>
    </div>
  </div>
</template>

<script>
import { POST_QUERY } from "@/graphql/queries/post";
import { UPSERT_POST_MUTATION } from "@/graphql/mutations/group";
import { dataURLtoBlob, blobToFile, blobToDataURL, urlToDataURL } from "@/lib/file";
import { decodeId } from "@/lib/string";
import ImageUploader from "vue-image-upload-resize";
import CloudinaryMixin from "@/mixins/CloudinaryMixin";
import VideoRecorder from "@/components/chat/VideoRecorder";
import FileUploader from "@/components/chat/FileUploader";
import UploadProgress from "@/components/shared/blocks/UploadProgress";
import Mentions from "@/mixins/Mentions";

export default {
  name: "PostForm",
  mixins: [CloudinaryMixin, Mentions],
  components: {
    ImageUploader,
    VideoRecorder,
    FileUploader,
    UploadProgress,
  },
  data() {
    return {
      files: [],
      saving: false,
      type: null,
      timeInSeconds: null,
      errorMessage: null,
      content: "",
      memberQuery: null,
    };
  },
  props: {
    groupId: {
      type: [Number, String],
      required: false,
    },
    activityId: {
      type: [Number, String],
      required: false,
    },
    eventId: {
      type: [Number, String],
      required: false,
    },
    recipeId: {
      type: [Number, String],
      required: false,
    },
    postId: {
      type: [Number, String],
      required: false,
    },
  },
  computed: {
    postButtonEnabled() {
      return this.content || this.files?.length > 0 || this.remoteFiles?.length > 0;
    },
  },
  methods: {
    clearFiles(type) {
      if (this.type == type) {
        this.files = [];
      }
    },
    saveFile(data) {
      this.errorMessage = null;
      if (data.length == 0) {
        return;
      }
      // grab the first element.
      data = data[0];
      if (data.file.type.includes("video") || /.mp4|.mov|.wmv|.webm/.test(data.file.name)) {
        this.clearFiles("IMAGE");
        this.files.push(data.file);
        this.type = "VIDEO";
        return;
      }

      if (data.file.type.includes("image") || /.jpg|.jpeg|.png/.test(data.file.name)) {
        this.clearFiles("VIDEO");

        let file = data.file;
        const $vm = this;
        blobToDataURL(data.file, function (e) {
          file.dataUrl = e;
          $vm.type = "IMAGE";
          $vm.files = [file];
        });
        return;
      }
      this.errorMessage = "Only images or videos are allowed";
    },
    saveVideoFile(data) {
      this.clearFiles("IMAGE");

      this.errorMessage = null;
      this.files.push(data.videoFile);
      this.type = "VIDEO";
      this.timeInSeconds = data.time;
    },
    clickUploader() {
      this.errorMessage = null;
      this.$refs.imageUploader.$el.children["post-image-uploader"].click();
    },
    async selectImg(data) {
      this.clearFiles("VIDEO");

      this.errorMessage = null;
      this.type = "IMAGE";
      const file = this.getFileFromDataUrl(data);
      file.dataUrl = data.dataUrl;
      this.files.push(file);
    },
    getFileFromDataUrl(image) {
      let blob = dataURLtoBlob(image.dataUrl);
      let file = blobToFile(blob);
      file.dataUrl = image.dataUrl;
      return file;
    },
    remove(file) {
      if (file.dataUrl) {
        const dataUrl = file.dataUrl;
        this.files = this.files.filter((el) => el.dataUrl != dataUrl);
      } else if (file.file) {
        this.files = this.files.filter((el) => el.file != file.file);
      }
    },
    async submit() {
      this.uploadPercentCompleted = 0;
      this.errorMessage = null;
      this.saving = true;

      let files;
      let remoteFiles = [];
      if (this.type == "VIDEO") {
        for (const file of this.files) {
          if (file.remoteFile) {
            remoteFiles.push(file.remoteFile);
          } else {
            const result = await this.uploadFileToCloudinary(file, "post_attachments");
            remoteFiles.push(result.public_id);
          }
        }
      } else {
        files = await Promise.all(
          this.files.map(async (el) => {
            if (el.file) {
              let fileDataUrl;
              const ready = new Promise((resolve) => {
                urlToDataURL(el.file, async function (dataUrl) {
                  fileDataUrl = dataUrl;
                  resolve();
                });
                return;
              });
              await ready;
              return this.getFileFromDataUrl({ dataUrl: fileDataUrl });
            }
            return this.getFileFromDataUrl(el);
          })
        );
      }

      let data = {
        id: this.postId ? decodeId(this.postId) : null,
        files: files,
        remoteFiles: remoteFiles,
        type: this.type ?? "IMAGE",
        timeInSeconds: this.timeInSeconds,
        content: this.content,
      };

      if (this.groupId) {
        data["groupId"] = decodeId(this.groupId);
      } else if (this.activityId) {
        data["activityId"] = decodeId(this.activityId);
      } else if (this.eventId) {
        data["eventId"] = decodeId(this.eventId);
      } else if (this.recipeId) {
        data["recipeId"] = decodeId(this.recipeId);
      }

      this.$apollo
        .mutate({
          mutation: UPSERT_POST_MUTATION,
          variables: {
            data: data,
          },
        })
        .then((response) => {
          this.saving = false;
          if (response.data.upsertPost.success) {
            this.content = "";
            this.files = [];
            this.$emit("saved");
          } else {
            this.$toasted.error("Unable to save post. Please try again.", {
              icon: "alert",
              duration: 2000,
              position: "bottom-center",
            });
          }
        });
    },
  },
  apollo: {
    postForm: {
      query: POST_QUERY,
      variables() {
        return {
          id: decodeId(this.postId),
        };
      },
      fetchPolicy: "network-only",
      update(data) {
        this.files = data.post?.postattachmentSet;
        if (this.files?.length > 0) {
          this.type = this.files[0].type;
        }
        this.content = data.post?.content;
        return data.post;
      },
      skip() {
        return !this.postId;
      },
    },
  },
};
</script>

<style scoped>
.clear-photo-btn {
  position: absolute;
  z-index: 1000;
  right: 0px;
  background-color: rgba(255, 255, 255, 0.5);
}
</style>
