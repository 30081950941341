import gql from 'graphql-tag'

export const ACTIVE_BANNER_QUERY = gql`
query activeBanner {
  activeBanner {
    id
    url
    backgroundColor
    fontColor
    copy
  }
}
`
