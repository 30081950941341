<template>
  <div class="d-flex pointer py-1 px-2" @click="navigate" :style="backgroundGradientStyle">
    <v-icon :color="colour" :size="hasCompleted ? 50 : 30" class="my-auto pb-1">
      {{ hasCompleted ? "mdi-circle" : "mdi-circle-outline" }}
    </v-icon>
    <div class="ml-2 flex-grow-1 text-left mr-3 my-auto pb-3">
      <div class="navy--text font-weight-bold my-1">
        {{ title }}
        <v-icon color="navy">mdi-arrow-right</v-icon>
      </div>

      <div :class="dayClass" class="font-weight-bold mb-1">Day {{ currentDay }} of {{ totalDays }}</div>
      <v-progress-linear :color="colour" :value="progressPercent" rounded height="6" />
    </div>
  </div>
</template>

<script>
import { gradientStyle } from "@/lib/gradientStyle";

export default {
  name: "ActivityDayCard",
  props: {
    unitActivity: {
      type: Object,
      required: false,
    },
    subscriptionBlockId: {
      type: String,
      required: false,
    },
    anchorDate: {
      type: String,
      required: false,
    },
    activityType: {
      type: String,
      required: false,
    },
    colour: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    currentDay: {
      type: Number,
      required: true,
    },
    totalDays: {
      type: Number,
      required: true,
    },
    progressPercent: {
      type: Number,
      required: true,
    },
    hasCompleted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dayClass() {
      return `ml-auto p-text ${this.colour}--text text-left`;
    },
    isLiveEvent() {
      return this.unitActivity.type == "LIVE_EVENT";
    },
    isRestDay() {
      return this.unitActivity.type == "REST_DAY";
    },
    backgroundGradientStyle() {
      return gradientStyle(["white lighten-2", "white darken-2"], 180);
    },
  },
  methods: {
    navigate() {
      if (this.unitActivity.activity) {
        return this.$router.push(
          `/unit-activity/${this.unitActivity.id}?&sbid=${this.subscriptionBlockId}&d=${this.anchorDate}`
        );
      }
      if (this.isLiveEvent) {
        return this.$router.push(`/live`);
      }
      if (this.isRestDay) {
        this.$emit('openRestDay');
      }
    },
  },
};
</script>
