<template>
  <div class="j-emoji-textarea">
    <j-textarea
      v-bind="$props"
      v-on="$listeners"
      :append-icon="icon"
      ref="jtextarea"
      :include-mentions="includeMentions"
      :mention-values-lookup="mentionValuesLookup"
    >
      <template v-slot:append>
        <emoji-picker @emoji="addEmoji" :search="search" id="textarea-emoji-picker">
          <div
            class="emoji-invoker"
            slot="emoji-invoker"
            slot-scope="{ events: { click: clickEvent } }"
            @click.stop="clickEvent"
          >
            <v-icon>mdi-emoticon-outline</v-icon>
          </div>
          <template v-slot:emoji-picker="{ emojis, insert, display }">
            <div class="emoji-picker-wrapper" :class="{ 'bottom-50': positionEmojisUp }">
              <div class="emoji-picker py-6 px-4">
                <div class="emoji-picker__search">
                  <j-text-field v-model="search" dense placeholder="Search" @click.stop="focusOnSearch($event)" />
                </div>
                <div>
                  <div v-for="(emojiGroup, category) in emojis" :key="category">
                    <h5 class="grey-navy--text my-1">{{ category }}</h5>
                    <div class="emojis">
                      <span
                        v-for="(emoji, emojiName) in emojiGroup"
                        :key="emojiName"
                        @click="
                          insert(emoji);
                          display.visible = false;
                        "
                        :title="emojiName"
                      >
                        {{ emoji }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </emoji-picker>
      </template>
    </j-textarea>
  </div>
</template>

<script>
import { VTextarea } from "vuetify/lib";
import EmojiPicker from "vue-emoji-picker";

export default {
  name: "JEmojiTextarea",
  extends: VTextarea,
  components: {
    EmojiPicker,
  },
  props: {
    icon: {
      type: String,
      default: "mdi-emoticon-outline",
    },
    includeMentions: {
      type: Boolean,
      default: false,
    },
    mentionValuesLookup: {
      type: Function,
      default: null,
    },
    positionEmojisUp: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showDialog: false,
      search: "",
    };
  },
  methods: {
    addEmoji(emoji) {
      let cursorLocation = this.$refs.jtextarea.$refs.vtextarea.$refs.input.selectionEnd;
      let val = this.$props.value ?? "";
      this.$refs.jtextarea.$emit(
        "input",
        `${val.substring(0, cursorLocation)}${emoji}${val.substring(cursorLocation)}`
      );
    },
    focusOnSearch(event) {
      // prevent weird clicking bug for search bar
      event.target.focus();
    },
    focus() {
      this.$refs.jtextarea.focus();
    },
    select(start = 0, end = null) {
      this.$refs.jtextarea.select(start, end);
    },
  },
};
</script>

<style lang="scss">
#textarea-emoji-picker {
  .wrapper {
    position: relative;
    display: inline-block;
  }

  .regular-input {
    padding: 0.5rem 1rem;
    border-radius: 3px;
    border: 1px solid #ccc;
    width: 20rem;
    height: 12rem;
    outline: none;
  }

  .regular-input:focus {
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
  }

  .emoji-invoker {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s;
  }
  .emoji-invoker:hover {
    transform: scale(1.1);
  }
  .emoji-invoker > svg {
    fill: var(--v-grey-icon-base);
  }

  .emoji-picker-wrapper {
    position: absolute;
    right: 0;
    z-index: 999;

    .emoji-picker {
      font-family: "Robotto";
      border: 1px solid #ccc;
      width: 18rem;
      height: 23rem;
      margin: auto;
      overflow-y: scroll;
      border-radius: 0.5rem;
      background: var(--v-white-base);
      box-shadow: 3px 3px 8px var(--v-grey-base);
    }
  }
  .emoji-picker__search {
    display: flex;
  }
  .emoji-picker__search > input {
    flex: 1;
    border-radius: 10rem;
    border: 1px solid #ccc;
    padding: 0.5rem 1rem;
    outline: none;
  }
  .emoji-picker h5 {
    font-family: "Open Sans";
    cursor: default;
  }
  .emoji-picker .emojis {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 20px;
  }
  .emoji-picker .emojis:after {
    content: "";
    flex: auto;
  }
  .emoji-picker .emojis span {
    padding: 0.4rem;
    cursor: pointer;
    border-radius: 5px;
  }
  .emoji-picker .emojis span:hover {
    background: var(--v-grey--base);
    cursor: pointer;
  }
  .bottom-50 {
    bottom: 50px;
  }
}
</style>
