export default {
  name: "CheckoutMixin",
  methods: {
    paymentPlanText(billingPeriod) {
      if (billingPeriod && billingPeriod.length > 0) {
        switch (billingPeriod) {
          case "MONTHLY":
            return "every month";
          case "QUARTERLY":
            return "every 3 months";
          case "ANNUALLY":
            return "every year";
          case "BIANNUAL":
            return "every 6 months";
          case "ONCE_OFF":
            return "once off";
        }
      }
      return "";
    },
    opportunity(productCode) {
      // maps to OPPORTUNITY_MAP in CreateDeal on master, which is used by Hubspot and Segment.
      switch (productCode) {
        case "jeff-life":
          return "Life";
        case "1-on-1":
          return "1-on-1 coaching";
        case "challenge":
          return "Challenge";
        case "jeff-club":
          return "Club";
        case "premium-block":
          return "Challenge";
      }
      return "";
    },
    getPrimaryProductCode(products) {
      // if the cart contains a challenge, then this is considered the primary product for Hubspot deals
      // i.e a cart may contain a challenge and life product. in that case, the primary product is challenge.
      // else go down the list of importance.
      
      // name is actually code :( sob. 
      if (products.some((e) => e.name === "jeff-club")) {
        return "jeff-club";
      }
      if (products.some((e) => e.name === "challenge" || e.name === "premium-block")) {
        return "challenge";
      }
      if (products.some((e) => e.name === "1-on-1")) {
        return "1-on-1";
      }
      if (products.some((e) => e.name === "1-on-1-grad")) {
        return "1-on-1-grad";
      }
      if (products.some((e) => e.name === "jeff-life")) {
        return "jeff-life";
      }
      return "";
    },
  },
};
