<template>
  <v-container>
    <div>
      <v-row align="center" justify="center">
        <v-col class="mb-0 pb-0">
          <h1 class="mt-4 mb-2 text-center grey-navy--text">Audio Classes</h1>
          <p class="text-center font-weight-bold">Listen along and be guided while you exercise</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center" justify="center">
          <v-card>
            <v-row class="px-4">
              <v-col cols="6">
                <v-card-title class="">
                  <j-select
                    v-model="categories"
                    :items="selectCategories"
                    @change="loadCategories"
                    label="Activity Type"
                    hide-details
                    dense
                  />
                </v-card-title>
              </v-col>

              <v-col cols="6">
                <v-card-title class="" v-if="categories.includes('run')">
                  <j-select
                    v-model="instructor"
                    :items="selectInstructors"
                    @change="loadCategories"
                    label="Instructor"
                    hide-details
                    dense
                  />
                </v-card-title> 
              </v-col>
            </v-row>

            <v-card-text>
              <div v-if="loading" class="text-center ma-5">
                <v-progress-circular :indeterminate="loading" color="blue" />
              </div>
              <div v-if="!loading">
                <up-sell-pop-up :showModal="showUpSellModal" v-on:upSellModalClosed="showUpSellModal = false" />
                <audio-class-card
                  v-for="event in guidedRuns"
                  :event="event"
                  :key="event.id"
                  v-on:accessGated="showUpSellModal = true"
                ></audio-class-card>
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { GUIDED_RUNS_QUERY } from "@/graphql/queries/guidedRuns";
import UpSellPopUp from "@/components/shared/blocks/UpSellPopUp";
import AudioClassCard from "./AudioClassCard";

export default {
  name: "AudioClasses",
  components: {
    AudioClassCard,
    UpSellPopUp,
  },
  data: () => ({
    categories: ["run"],
    instructor: "",
    showUpSellModal: false,
    selectInstructors: [
      { value: "", text: "Any" },
      { value: "Johno", text: "Johno" },
      { value: "Lee", text: "Lee" },
      { value: "Sakkie", text: "Sakkie" },
    ],
    selectCategories: [
      { value: ["run"], text: "Running" },
      { value: ["walk"], text: "Walking" },
      //{ value: ['spin'], text: 'Spinning'},
      { value: ["meditation"], text: "Meditation" },
    ],
    guidedRuns: [],
    loading: true,
    tab: "",
  }),
  mounted() {
    window.analytics.page("Workout Run Library");
  },
  methods: {
    loadCategories() {
      this.key++;
      this.guidedRuns = [];
      this.loading = true;
      this.$apollo.queries.guidedRuns.refetch();
    },
    eventPassed(date) {
      var dt = new Date(date);
      return dt.setHours(dt.getHours() + 1) < new Date();
    },
  },
  apollo: {
    guidedRuns: {
      query: GUIDED_RUNS_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          categories: this.categories,
          search: this.categories.includes("run") ? this.instructor : "",
          orderBy: "-created_at",
        };
      },
      result(response) {
        if (response.data && !response.loading) {
          this.loading = false;
        }
      },
    },
  },
};
</script>

<style>
.card-list-title {
  align-self: flex-start;
  font-weight: bold;
  color: #211256;
}
.selected-tab-active {
  color: var(--v-secondary-base) !important;
  border: 1px solid #dadae2;
  border-bottom: none;
  border-top: 3px solid var(--v-secondary-base);
  border-radius: 0;
  background: white;
}
</style>
