<template>
  <checkin-stepper
    :step="2"
    :title="{ text: 'Any other', strongText: 'interests or goals?' }"
    subtitle="Choose none, one or multiple"
    @next="next"
  >
    <v-row v-if="tags && tags.edges.length > 0" class="mt-6">
      <v-col cols="12" md="6" v-for="tag in tags.edges" :key="tag.id">
        <j-card rounded="lg" :class="{ 'navy-border': tagIsSelected(tag) }">
          <j-checkbox
            color="navy"
            v-model="selectedTags"
            :label="tag.node.title"
            :value="tag.node.code"
            hide-details
            class="px-3 py-4 mt-0"
          >
            <div slot="label" class="std-text navy--text ml-1">{{ tag.node.title }}</div>
          </j-checkbox>
        </j-card>
      </v-col>
    </v-row>
  </checkin-stepper>
</template>

<script>
import TagMixin from "@/mixins/TagMixin";
import CheckinStepper from "@/components/checkin/CheckinStepper";

export default {
  name: "Interests",
  mixins: [TagMixin],
  data() {
    return {
      category: "SECONDARY",
    };
  },
  components: {
    CheckinStepper,
  },
};
</script>

<style lang="scss" scoped>
.j-card.navy-border {
  border: 2px solid var(--v-navy-base);
}
</style>
