<template>
  <v-btn icon color="exercise" @click="handleClick()">
    <v-icon small>{{ userPostLike && userPostLike.id ? "mdi-heart" : "mdi-heart-outline" }}</v-icon>
  </v-btn>
</template>

<script>
import { LIKE_POST_MUTATION, DELETE_LIKE_MUTATION } from "@/graphql/mutations/group";
import { decodeId } from "@/lib/string";

export default {
  name: "PostLike",
  props: {
    postId: {
      type: String,
      required: true,
    },
    userPostLike: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClick() {
      if (this.userPostLike) {
        this.unlike();
      } else {
        this.like();
      }
    },
    getId(idString) {
      return decodeId(idString);
    },
    like() {
      this.$apollo
        .mutate({
          mutation: LIKE_POST_MUTATION,
          variables: {
            postId: this.getId(this.postId),
          },
        })
        .then((response) => {
          this.saving = false;
          if (response.data.likePost.success) {
            this.emitLikeSaved(response.data.likePost.id, 'add');
          } else {
            this.$toasted.error("Unable to save for later. Sorry.", {
              icon: "alert",
              duration: 2000,
              position: "bottom-center",
            });
          }
        });
    },
    unlike() {
      this.$apollo
        .mutate({
          mutation: DELETE_LIKE_MUTATION,
          variables: {
            id: this.userPostLike.id,
          },
        })
        .then((response) => {
          this.saving = false;
          if (response.data.deleteLike.success) {
            this.emitLikeSaved(null, 'delete');
          } else {
            this.$toasted.error("Unable to save for later. Sorry.", {
              icon: "alert",
              duration: 2000,
              position: "bottom-center",
            });
          }
        });
    },
    emitLikeSaved(id, type) {
      this.$emit("likeUpdate", {
        postId: this.postId,
        id: id,
        type: type
      });
    },
  },
};
</script>

<style scoped>
.clear-photo-btn {
  position: absolute;
  z-index: 1000;
  right: 0px;
}
</style>
