<template>
  <v-container class="text-center" fill-height>
    <v-row align="center">
      <v-col>
        <p class="std-text">The page you were looking for does not exist.</p>

        <j-btn :to="`/`">
          Go to 'Home' →
        </j-btn>

        <p class="std-text mt-4">Do you need help finding what you were looking for?</p>

        <j-btn :to="`/support`" tertiary>
          Go to 'Support' →
        </j-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "FourOhFour",
  created() {
    this.$ssrContext && this.$ssrContext.res.status(404);
  },
};
</script>
