import gql from "graphql-tag";

export const CREATE_FRIEND_REQUEST = gql`
  mutation createFriendRequest($userId: ID!) {
    createFriendRequest(userId: $userId)  {
      success
      error
    }
  }
`;


export const UPSERT_FRIEND_REQUEST = gql`
  mutation upsertFriendRequest($id: ID!, $status: String!) {
    upsertFriendRequest(id: $id, status: $status) {
      success
      error
    }
  }
`;

export const DELETE_FRIEND_REQUEST = gql`
  mutation deleteFriendRequest($id: ID!) {
    deleteFriendRequest(id: $id) {
      success
      error
    }
  }
`;