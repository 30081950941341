<template>
  <!-- NOTE: only handeling tribes linked to workout blocks at this stage. -->
  <div v-if="tribesActive && tribe && tribe.workoutBlock">
    <!-- workout block has not yet begun-->
    <div v-if="countdownMiliseconds > 0" class="wrapper" :style="`background-image: url('${tribe.banner}');`">
      <div>
        <div class="countdown-title">
          <div class="countdown-title-bg">{{ tribe.workoutBlock.title }} starts in</div>
        </div>
        <generic-countdown-timer :milisecondsToEnd="countdownMiliseconds" />
      </div>
    </div>

    <!-- workout block has begun-->
    <div v-else class="wrapper text-left py-2" :style="`background-image: url('${tribe.workoutBlock.image}');`">
      <div v-if="tribe.bannerTitle" class="text-center">
        <div class="countdown-title">
          <div class="countdown-title-bg">{{ tribe.bannerTitle }}</div>
        </div>
      </div>

      <div v-for="stat in tribe.stats" :key="stat.tribe.id">
        <tribe-stat-listing-card
          :icon="stat.tribe.icon"
          :title="stat.tribe.title"
          :dots="stat.dots"
          :is-mine="stat.isMine"
          :position="stat.position"
          class="mx-9 my-2"
        />
      </div>

      <div class="mx-9 mt-2">
        <tribe-stat-listing-card
          :icon="profilePic"
          title="Your contribution"
          :dots="tribe.myDots"
          :avatar-text="avatarText"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { TRIBE_QUERY } from "@/graphql/queries/account";
import { MASTER_SETTINGS } from "@/graphql/queries/settings";
import GenericCountdownTimer from "@/components/shared/GenericCountdownTimer";
import TribeStatListingCard from "@/components/tribe/TribeStatListingCard";
import { mapGetters } from "vuex";

export default {
  name: "TribeSummaryCard",
  components: {
    GenericCountdownTimer,
    TribeStatListingCard,
  },
  computed: {
    ...mapGetters(["user"]),
    countdownMiliseconds() {
      if (!this.tribe?.workoutBlock?.startDate) {
        return 0;
      }
      return this.$moment().diff(this.tribe?.workoutBlock?.startDate, "seconds") * -1 * 1000;
    },
    profilePic() {
      return this.$store.getters.user.userProfile.profilePicture;
    },
    avatarText() {
      return `${this.user.firstName.charAt(0).toUpperCase()}${this.user.lastName.charAt(0).toUpperCase()}`;
    },
  },
  apollo: {
    tribe: {
      query: TRIBE_QUERY,
      fetchPolicy: "network-only",
      update(data) {
        if (data.tribes?.edges && data.tribes?.edges.length > 0) {
          return data.tribes?.edges[0].node;
        }
      },
    },
    tribesActive: {
      query: MASTER_SETTINGS,
      fetchPolicy: "network-only",
      update(data) {
        this.$store.dispatch(
          "updateSettings",
          data.masterSettings.edges.map((setting) => setting.node)
        );

        return (this.$store.getters.settings.get("LEADERBOARD")?.get("leaderboard-tribes-active") ?? false) === "true";
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  border-radius: 40px;
  background-repeat: no-repeat;
  background-size: cover;
}

.countdown-title {
  padding-top: 10px;
  color: white;
  font-size: 18px;
  font-weight: bold;

  .countdown-title-bg {
    display: inline-block;
    padding: 5px 30px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 40px;
  }
}

h1 {
  font-weight: normal;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
}

.badge-position {
  position: relative;
  right: 18px;
  top: 4px;
  color: white;
}

.line-height-15 {
  line-height: 15px;
}
</style>
