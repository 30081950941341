<template>
  <j-avatar-list v-if="friends" :user-list="friends" />
</template>

<script>
import { USER_CONNECTIONS_QUERY } from "@/graphql/queries/friends";

export default {
  name: "FriendAvatarListing",
  props: {
    userId: {
      type: [Number, String],
      required: true,
    },
  },
  apollo: {
    friends: {
      query: USER_CONNECTIONS_QUERY,
      variables() {
        return {
          status: "APPROVED",
          forUserId: this.userId,
        };
      },
      // network-only, otherwise doesn't update the listing card nicely on update
      fetchPolicy: "network-only",
      update(data) {
        return data.userConnections.edges.map((edge) => {
          // get the profile picture that isn't of this user
          return edge.node.user.id == this.userId ? edge.node.requester : edge.node.user;
        });
      },
    },
  },
};
</script>
