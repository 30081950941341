<template>
  <v-card class="px-4 mb-2">
    <v-card-title
      class="title ma-0 pa-0 pt-6 ml-lg-4 ml-0 pointer"
      :style="{ color: primaryColor }"
      @click="navigateToBlock"
      >{{ subscriptionBlock.block.title }}</v-card-title
    >
    <div class="d-flex py-2 pointer" @click="navigateToBlock">
      <v-avatar
        v-if="
          subscriptionBlock.block.leadInstructor && subscriptionBlock.block.leadInstructor.userProfile.profilePicture
        "
        size="65"
        class="ml-4 my-auto"
        :color="avatarHighlightColor"
      >
        <v-img
          v-if="subscriptionBlock.block.leadInstructor.userProfile.profilePicture"
          :src="subscriptionBlock.block.leadInstructor.userProfile.profilePicture"
        />
      </v-avatar>
      <div class="ml-4">
        <div class="date-title" :style="{ color: dateColor }">
          Starting on {{ subscriptionBlock.startDate | humanize_yyyy_mm_dd }}
        </div>
        <div class="instructor-title std-text">with {{ subscriptionBlock.block.leadInstructor.firstName }}</div>
      </div>
    </div>
    <div class="pb-3" :class="this.$vuetify.breakpoint.smAndUp ? 'd-flex flex-row-reverse' : 'mobile-button-grid'">
      <v-btn
        v-if="subscriptionBlock.block.nutritionPlan"
        class="action-button mt-1"
        :style="{ color: primaryColor }"
        :href="subscriptionBlock.block.nutritionPlan"
        target="_blank"
      >
        <v-icon class="mr-1">mdi-download</v-icon>
        Nutrition Guide
      </v-btn>
      <v-btn
        v-if="subscriptionBlock.block.activityPlanner"
        class="action-button primary-color mr-3 mt-1"
        elevation="0"
        :style="{ color: primaryColor }"
        :href="subscriptionBlock.block.activityPlanner"
        target="_blank"
      >
        <v-icon class="mr-1">mdi-download</v-icon>
        Activity Plan
      </v-btn>
      <v-btn
        v-if="subscriptionBlock.block.relatedNutritionBlock"
        class="action-button primary-color mr-3 mt-1"
        elevation="0"
        :style="{ color: primaryColor }"
        @click="downloadShoppingList"
        :disabled="loading.shoppingList"
      >
        <v-icon class="mr-1">mdi-download</v-icon>
        Shopping List
      </v-btn>
      <v-btn
        v-if="subscriptionBlock.block.relatedNutritionBlock"
        class="action-button primary-color mr-3 mt-1"
        elevation="0"
        :style="{ color: primaryColor }"
        @click="navigateWeekMenu"
      >
        <v-icon class="mr-1">mdi-food-apple</v-icon>
        Menu
      </v-btn>
    </div>

    <!-- PDF of shoppinglist-->
    <generate-pdf v-if="formattedShoppingList" ref="refGeneratePdf" :title="`Shopping List - ${shoppingListDates}`">
      <template v-slot:content>
        <span v-for="category in Object.keys(formattedShoppingList)" :key="category">
          <h2 class="pdf-heading-2" style="margin-bottom: 10px; margin-top: 10px">
            {{ formatCategoryTitle(category) }}
          </h2>

          <span class="pdf-copy" v-for="(item, index) in formattedShoppingList[category]" :key="item.id">
            <span :class="{ strikethrough: item.purchased }">{{ itemTitle(item) }}</span>
            <span v-if="itemQuantity(item)" style="font-size: 11px" :class="{ strikethrough: item.purchased }">
              {{ itemQuantity(item) }}</span
            ><span v-if="index != formattedShoppingList[category].length - 1">,</span>
            <br v-if="(index + 1) % 3 === 0 && category !== 'X__Pantry'" />
            <br v-if="(index + 1) % 5 === 0 && category === 'X__Pantry'" />
          </span>
        </span>
      </template>
    </generate-pdf>
  </v-card>
</template>

<script>
import { SHOPPING_LIST_QUERY } from "@/graphql/queries/shoppingList";
import { GENERATE_SHOPPING_LIST_MUTATION } from "@/graphql/mutations/shoppingList";
import { decodeId } from "@/lib/string";
import ShoppingListPDFMixin from "@/mixins/ShoppingListPDFMixin";
import GeneratePdf from "@/components/pdf/GeneratePdf";
import ShoppingListMixin from "@/mixins/ShoppingListMixin";

export default {
  name: "UpcomingSubscriptionBlock",
  mixins: [ShoppingListMixin, ShoppingListPDFMixin],
  components: {
    GeneratePdf,
  },
  props: {
    subscriptionBlock: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      shoppingList: null,
      formattedShoppingList: null,
      loading: {
        shoppingList: false,
      },
    };
  },
  computed: {
    primaryColor() {
      return "#211256";
    },
    avatarHighlightColor() {
      return "#211256";
      //return '#008fd5';
    },
    dateColor() {
      if (this.subscriptionBlock.block.type === "NUTRITION") return "#2eb8a7";
      return "#fc3a64";
    },
    shoppingListDates() {
      if (!this.shoppingList) return "";
      return `${this.formatDate(this.shoppingList.startDate)} - ${this.formatEndDate(this.shoppingList.startDate)}`;
    },
  },
  methods: {
    navigateToBlock() {
      this.$router.push(`/program/${decodeId(this.subscriptionBlock.block.id)}`);
    },
    navigateWeekMenu() {
      this.$router.push(`/nutrition/${decodeId(this.subscriptionBlock.block.relatedNutritionBlock.id)}/overview?tab=0&upcoming=1&sd=${this.subscriptionBlock.startDate}`);
    },
    async downloadShoppingList() {
      this.shoppingList = null;
      this.formattedShoppingList = null;
      this.loading.shoppingList = true;
      /**
       * 1. Check if there is an existing shopping list for the block start date
       * 2. If not -> Generate a new shopping list.
       * 3. Download shopping list.
       */
      let shoppingListResult = await this.$apollo.query({
        query: SHOPPING_LIST_QUERY,
        variables: {
          startDate: this.subscriptionBlock.startDate,
        },
        fetchPolicy: "no-cache",
      });
      if (shoppingListResult.data.shoppingList) {
        this.shoppingList = shoppingListResult.data.shoppingList;
      } else {
        // we cant find a shopping list, so we need to try generate one.
        let generateShoppingListResult = await this.$apollo.mutate({
          mutation: GENERATE_SHOPPING_LIST_MUTATION,
          variables: {
            data: {
              startDate: this.subscriptionBlock.startDate,
              nutritionBlockId: decodeId(this.subscriptionBlock.block.relatedNutritionBlock.id),
            },
          },
        });
        if (generateShoppingListResult.data.generateShoppingListMutation.success)
          this.shoppingList = generateShoppingListResult.data.generateShoppingListMutation.shoppingList;
      }

      if (!this.shoppingList) {
        //we couldnt generate a shopping list for some reason... bugger
        this.$toasted.error("Sorry. We were unable to generate your shopping list.");
        return;
      }

      this.formattedShoppingList = this.mutateShoppingList(this.shoppingList);

      //wait for the shopping list to get injected into the DOM
      await this.$nextTick();

      this.generateShoppingListPdf();
      this.loading.shoppingList = false;
    },
  },
};
</script>

<style scoped>
.title {
  font-size: 24px !important;
  font-weight: bold;
}
.date-title {
  text-align: left;
  font-size: 18px;
}
.instructor-title {
  text-align: left;
  font-weight: bold;
}
.action-button {
  background-color: white !important;
  border: 1px solid #005a9e;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0px;
}
</style>
