export default {
  computed: {
    showOnboardingSummary() {
      const outstandingOnboardingTodos = this.$store.getters.outstandingUserTodos?.find(
        (obj) => obj.type == "ONBOARDING"
      );
      // if we have outstanding todos, we can alwasy show this and not hit the setting.
      if (outstandingOnboardingTodos) {
        return true;
      }

      // if the user has not outstanding onboarding todos and does not have the hide-onboarding setting, we SHOw!!
      return !this.$store.getters.user?.userSettings?.find((obj) => obj.key == "hide-onboarding");
    },
  },
};
