<template>
  <div>
    <v-avatar size="45" class="mr-2 float-left br-25">
      <img alt="Sender Profile Picture" :src="senderProfilePic" />
    </v-avatar>
    <v-card-subtitle class="my-0 py-0 px-0 sender-block">
      <div class="grey-navy--text font-weight-bold">{{ senderName }}</div>
      <div class="text--secondary">{{ deliveredAt }}</div>
    </v-card-subtitle>
  </div>
</template>

<script>
import {fancyShortDate} from "@/lib/fancy_date";

export default {
  name: "SenderBlock",
  props: {
    message: {
      type: Object,
      default: null
    }
  },
  computed: {
    senderName() {
      if (this.message.inboxMessage.sender) {
        return this.message.inboxMessage.sender.firstName + " " + this.message.inboxMessage.sender.lastName;
      }
      return this.$appName;
    },
    deliveredAt() {
      return fancyShortDate(this.message.inboxMessage.deliveredAt)
    },
    senderProfilePic() {
      if (this.message.inboxMessage.sender && this.message.inboxMessage.sender.userProfile.profilePicture) {
        return this.message.inboxMessage.sender.userProfile.profilePicture;
      }
      return require("@/assets/images/jeffinbox.jpg");
    }
  }
}
</script>

<style scoped>
  .br-25 {
    border-radius: 25px;
  }
</style>