<template>
  <j-card rounded="lg">
    <v-toolbar class="mb-3">
      <v-toolbar-title> <v-icon color="green" :size="30">mdi-circle</v-icon> {{ meal | titleize }} </v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="$emit('closed')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <validation-observer ref="obs" v-slot="{ valid, errors, pristine, validate }">
      <v-card-text class="pb-0">
        <j-card v-if="macros" class="mb-5" elevation="0">
          <v-row>
            <v-col cols="6" class="py-0">
              <div class="text-left ml-5 pt-1 font-weight-bold navy--text">~{{ macros.kcal }} kcal</div>
            </v-col>
            <v-col v-if="guidePdf" cols="6" class="mt-1 text-right pr-10 py-0">
              <a target="_blank" :href="guidePdf" class="font-weight-bold heading-6 navy--text text-decoration-none"
                >SEE FULL PLAN</a
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pl-8 pt-0">
              <macro-card
                :macros="{ UNITS: macros.units, PROTEIN: macros.protein, CARBS: macros.carbs, FAT: macros.fat }"
              />
            </v-col>
          </v-row>
        </j-card>
        <div class="text-left pointer">
          <selfie-alert
            text="meal pic"
            :image="nutritionBlockNutritionLog ? nutritionBlockNutritionLog.image : null"
            :loading="imageLoading"
            @clickUploader="clickUploader"
            @input="selectImg($event)"
            :canRemove="false"
          />
        </div>

        <share-options
          v-if="nutritionBlockNutritionLog && nutritionBlockNutritionLog.image"
          :image="nutritionBlockNutritionLog.image"
          :date="data.date"
          :chatData="chatData"
          class="mb-4"
          :message-at-bottom="true"
          :with-groups="true"
        />
        <div v-if="coach">
          <div class="d-flex align-center my-4">
            <v-avatar class="mr-4" size="60" v-if="coach.userProfile.profilePicture">
              <v-img :lazy-src="coach.userProfile.profilePicture" :src="coach.userProfile.profilePicture" />
            </v-avatar>
            <h4>Feedback for {{ coachFirstName }}</h4>
          </div>
        </div>
        <j-text-field :label="coach ? `Comments for ${coachFirstName}` : 'Add a note'" v-model="data.comments" />
        <v-checkbox v-model="data.onTrack" label="My meal was on track" class="mt-n2" />
      </v-card-text>
      <v-card-actions class="justify-center pb-6 flex-column">
        <j-alert v-if="errors && errors.message && errors.message.length > 0" type="error" class="mb-3">
          {{ errors.message[0] }}
        </j-alert>
        <j-btn
          color="green"
          :loading="imageLoading || loading"
          @click="
            pristine ? validate() : null;
            confirm(valid);
          "
        >
          <strong>
            UPDATE
            <span v-if="chatData.isSharing">AND SEND</span>
          </strong>
        </j-btn>

        <div class="mt-4 p-text pointer font-weight-bold" @click="$emit('closed')">Close</div>
      </v-card-actions>
    </validation-observer>
  </j-card>
</template>

<script>
import { decodeId } from "@/lib/string";
import { UPSERT_NUTRITION_LOG_MUTATION } from "@/graphql/mutations/nutritionblock";
import { NUTRITION_BLOCK_NUTRITION_LOG } from "@/graphql/queries/nutritionblock";
import { dataURLtoBlob, blobToFile } from "@/lib/file";
import ShareOptions from "@/components/tracker/activity-form/ShareOptions";
import BadgeToaster from "@/mixins/BadgeToaster";
import SelfieAlert from "@/components/tracker/activity-form/SelfieAlert";
import UserMixin from "@/mixins/UserMixin";
import ImageSaveMixin from "@/mixins/chats/ImageSaveMixin";
import MacroCard from "@/components/nutritionblocks/cards/MacroCard";

export default {
  name: "NutritionLog",
  components: {
    SelfieAlert,
    MacroCard,
    ShareOptions,
  },
  mixins: [UserMixin, BadgeToaster, ImageSaveMixin],
  data() {
    return {
      imageLoading: false,
      menu: false,
      data: {
        onTrack: true,
      },
      updated: false,
      chatData: {
        sendToChats: [],
        sendToGroups: [],
        isSharing: false,
        chatText: null,
      },
      errorAlert: null,
    };
  },
  props: {
    blockId: {
      type: [Number, String],
      required: false,
    },
    recipe: {
      type: [Object],
      required: false,
    },
    date: {
      type: String,
      required: true,
    },
    logged: {
      type: Boolean,
      default: false,
    },
    meal: {
      type: String,
      required: true,
    },
    macros: {
      type: Object,
      required: false,
    },
    guidePdf: {
      type: String,
      required: false,
    },
  },
  created() {
    if (!this.logged && this.date) {
      this.data.date = this.date;
    }
  },
  computed: {
    coachFirstName() {
      if (!this.coach) {
        return null;
      }
      if (this.coach.firstName.toLowerCase() == "coach") {
        return this.coach.lastName;
      }
      return this.coach.firstName;
    },
  },
  methods: {
    saveDate(date) {
      this.$refs.menu.save(date);
    },
    clickUploader() {
      document.getElementById("selfie-image-uploader").click();
    },
    async selectImg(data) {
      this.imageLoading = true;
      const blob = dataURLtoBlob(data.dataUrl);
      const file = blobToFile(blob);
      let input = {
        nutritionBlockId: decodeId(this.blockId),
        recipeId: this.recipe ? decodeId(this.recipe.id) : null,
        image: file,
        date: this.date,
        meal: this.meal.toUpperCase(),
        customTitle: this.data.customTitle,
        onTrack: this.data.onTrack,
      };

      await this.$apollo
        .mutate({
          mutation: UPSERT_NUTRITION_LOG_MUTATION,
          variables: {
            data: input,
          },
          refetchQueries: [
            "recipesForWeek",
            "nutritionQuery",
            "dailyTrackerSummary",
            "activitiesQuery",
            "nutritionBlockLogs",
            "nutritionBlockNutritionLog",
            "nutritionBlockNutritionLogs",
            "prizeEligibilityQuery",
            "assistantQuery",
            "recipesForDay",
          ],
        })
        .then((response) => {
          let badges = response.data?.upsertNutritionBlockLog?.earnedBadges;
          if (badges) {
            this.toastBadges(badges);
          }
        });

      this.updated = true;
      setTimeout(() => (this.imageLoading = false), 250);
    },
    confirm(valid) {
      if (valid) {
        this.data.meal = this.meal.toUpperCase();
        this.enrichDot(null, this.data, this.chatData, "nutrition");
      }
    },
  },
  apollo: {
    nutritionBlockNutritionLog: {
      query: NUTRITION_BLOCK_NUTRITION_LOG,
      fetchPolicy: "network-only",
      variables() {
        return {
          date: this.date,
          nutritionBlockId: decodeId(this.blockId),
          // should the log be tied to the recipe? No. Date and Meal Type should be sufficient.
          // block still required I think, since if a user is not on a subscription nutrition block, they are on the default block.
          //recipeId: decodeId(this.recipeId),
          meal: this.meal.toUpperCase(),
        };
      },
      result(response) {
        if (response.data?.nutritionBlockNutritionLog) {
          if (this.updated) {
            this.data.image = response.data.nutritionBlockNutritionLog.image;
          } else {
            this.data = response.data.nutritionBlockNutritionLog;
          }

          if (this.data.id) {
            delete this.data.id;
          }
          if (this.data.__typename) {
            delete this.data.__typename;
          }
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.nutrition-log-image {
  border-radius: 10px;
}
</style>
