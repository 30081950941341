<template>
  <v-treeview
    selected-color="secondary"
    on-icon="mdi-check-circle"
    off-icon="mdi-checkbox-blank-circle-outline"
    indeterminate-icon="mdi-check-circle"
    v-bind="$props"
    v-on="$listeners"
    open-on-click
    class="j-treeview text-left font-weight-medium grey-navy--text"
    :expand-icon="expandIcon"
    transition
  >
    <template v-slot:append="{ item }">
      <slot name="append" v-bind="item" />
    </template>
    <slot />
  </v-treeview>
</template>

<script>
import { VTreeview } from "vuetify/lib";

export default {
  name: "JTreeview",
  extends: VTreeview,
  props: {
    expandIcon: {
      type: String,
      default: "mdi-chevron-down"
    }
  }
};
</script>

<style lang="scss">
// NOTE, not scoped, so use the j-checkbox wrapper class
.j-treeview {
  .v-treeview-node__content {
    font-size: 14px;
  }
}
</style>
