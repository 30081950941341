<template>
  <div>
    <v-row v-if="$apollo.loading" justify="center">
      <v-col cols="12" md="6">
        <v-skeleton-loader v-for="count in 2" :key="count" type="list-item-two-line" />
      </v-col>
    </v-row>
    <checkin-stepper
      v-else
      :step="4"
      :title="{ text: 'What level of support do you need to', strongText: 'achieve your goals?' }"
      @next="next"
    >
      <v-row justify="center">
        <v-col cols="8">
          <div class="mb-8" v-for="requiredSupportOption in requiredSupportOptions" :key="requiredSupportOption.id">
            <j-checkbox
              v-model="requiredSupport"
              :key="requiredSupportOption.id"
              :label="requiredSupportOption.text"
              :value="requiredSupportOption.value"
            ></j-checkbox>
            <p class="mt-n2 subtitle text-left">{{ requiredSupportOption.caption }}</p>
          </div>
        </v-col>
      </v-row>
    </checkin-stepper>
  </div>
</template>

<script>
import { ACCOUNT_QUERY } from "@/graphql/queries/account";
import { UPDATE_PROFILE_MUTATION } from "@/graphql/mutations/account";
import { requiredSupportOptions } from "@/lib/user";
import CheckinStepper from "@/components/checkin/CheckinStepper";

export default {
  name: "Support",
  components: {
    CheckinStepper,
  },
  data() {
    return {
      me: {},
      requiredSupportOptions: requiredSupportOptions,
      requiredSupport: [],
    };
  },
  methods: {
    async next(nextStepLink) {
      // clone and remove empty elements
      let requiredSupport = [...this.requiredSupport.filter((n) => n)];
      // SOLO is not an option on the front end. Remove it.
      requiredSupport = requiredSupport.filter((n) => n != "SOLO");

      if (requiredSupport.length == 2) {
        requiredSupport = "BOTH";
      } else {
        requiredSupport = requiredSupport[0];
      }
      let userData = {
        requiredSupport: requiredSupport,
      };

      await this.$apollo.mutate({
        mutation: UPDATE_PROFILE_MUTATION,
        variables: {
          userProfileInput: userData,
        },
        refetchQueries: ["accountQuery"],
      });

      if (sessionStorage.getItem("registerCompleted")) {
        sessionStorage.removeItem("registerCompleted");
        // we set this in ColdCheckoutPassword and other places, which indicates that the user has registered and can bounce
        // away from this wizard at this stage.
        const workoutBlockId = sessionStorage.getItem("workoutBlockId");
        if (workoutBlockId) {
          sessionStorage.removeItem("workoutBlockId");
          return this.$router.push(`/program/${workoutBlockId}?confirm=1`);
        }
        // fall through and go to next step link
      }
      if (requiredSupport == "BOTH" || requiredSupport == "COACH") {
        localStorage.setItem("requiredSupport", "COACH");
      } else {
        localStorage.removeItem("requiredSupport");
      }

      return this.$router.push(nextStepLink);
    },
  },
  apollo: {
    me: {
      query: ACCOUNT_QUERY,
      fetchPolicy: "network-only",
      result(response) {
        if (response.data) {
          const userProfile = response.data.me.userProfile;
          let requiredSupport = [];

          if (userProfile.requiredSupport) {
            if (userProfile.requiredSupport == "BOTH") {
              requiredSupport = ["COMMUNITY", "COACH"];
            } else {
              requiredSupport = [userProfile.requiredSupport];
            }
          }

          // bit of a hack job if the user is of type one on one
          if (requiredSupport.length == 1 && requiredSupport == "SOLO" && this.$store.getters.role == "ONE_ON_ONE") {
            requiredSupport = ["COACH"];
          }

          // if the user is already 1-1, let's bypass this completely, not go to any recommendation page and just go straight to the home page.
          if (this.$store.getters.role == "ONE_ON_ONE") {
            return this.$router.push("/");
          }
          // if the user is already Club, let's bypass this completely and go to next page. 
          if (this.$store.getters.role == "CLUB") {
            // hard coded next step, but I cannot make heads or tails fo CheckinStepper
            return this.$router.push('/checkin/done');
          }
          this.requiredSupport = requiredSupport;
        }
      },
    },
  },
};
</script>
