<template>
  <v-row class="pb-4 mx-5">
    <template v-if="checkinBookingRequired">
      <todo-listing-card
        :todo="{
          title: 'Book check-in with your Coach',
          url: checkinBookingLink ? checkinBookingLink : '/today/to-do/checkin',
        }"
      />
    </template>

    <template v-if="checkinRequired">
      <todo-listing-card
        :todo="{
          title: 'Add check-in to track progress',
          url: checkinLink,
        }"
      />
    </template>

    <template v-if="outstandingUserTodos.length > 0">
      <todo-listing-card v-for="todo in outstandingUserTodos" :key="todo.id" :todo="todo" />
    </template>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import UserMixin from "@/mixins/UserMixin";
import CentralStorageMixin from "@/mixins/CentralStorageMixin";
import TodoListingCard from "@/components/todo/TodoListingCard";

export default {
  name: "TodoItems",
  mixins: [UserMixin, CentralStorageMixin],
  components: {
    TodoListingCard,
  },
  computed: {
    ...mapGetters([
      "checkinRequired",
      "checkinBookingRequired",
      "checkinBookingLink",
      "checkinId",
      "outstandingUserTodos",
    ]),
    checkinLink() {
      return this.checkinId ? `/checkin/assessment/edit/${this.checkinId}` : "/checkins/new";
    },
  },
};
</script>
