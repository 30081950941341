<template>
  <v-container>
    <v-row v-if="!retryPayment" align="center" justify="center">
      <v-col cols="12" md="8" align="center" justify="center">
        <v-progress-circular indeterminate color="secondary" size="70" class="my-4" />
        <span class="d-block"> Processing order </span>
      </v-col>
    </v-row>
    <v-container v-else>
      <secure-redirect-form ref="redirectForm" :redirect-form="redirectForm"></secure-redirect-form>
      <v-row align="center" justify="center">
        <v-col cols="12" md="8" align="center" justify="center">
          <v-alert type="warning" class="alert-warning"
            >Your payment has failed, if you would like to try again, please enter your card details and click 'Retry
            Payment'. Please make sure the card you are using has 3D secure enabled with your bank.</v-alert
          >
        </v-col>
      </v-row>
      <validation-observer ref="observer">
        <v-row align="center" justify="center">
          <v-col cols="12" md="8" align="center" justify="center">
            <v-card>
              <v-card-title class="text-h4-5 font-weight-bold secondary--text"> Payment Card Details </v-card-title>
              <card-details :card-details="cardDetails" />
            </v-card>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12" md="6" sm="8">
            <v-btn block rounded large color="secondary" :loading="buttonLoading" @click="doRetryPayment"
              >Retry Payment</v-btn
            >
          </v-col>
        </v-row>
      </validation-observer>
    </v-container>
  </v-container>
</template>

<script>
import Vue from "vue";
import { decodeId } from "@/lib/string";
import { mapGetters } from "vuex";
import { TRANSACTION_STATUS_QUERY } from "@/graphql/queries/store";
import { RETRY_PAYMENT_MUTATION } from "@/graphql/mutations/store";
import { UPDATE_SUBSCRIPTION_MUTATION } from "@/graphql/mutations/account";

import CardDetails from "@/components/store/blocks/CardDetails";
import SecureRedirectForm from "@/views/store/SecureRedirectForm";
import CheckoutMixin from "@/components/store/mixins/CheckoutMixin";

export default {
  name: "PaymentResult",
  components: {
    SecureRedirectForm,
    CardDetails,
  },
  mixins: [CheckoutMixin],
  data() {
    return {
      gatewayReference: "",
      retryPayment: false,
      buttonLoading: false,
      cardDetails: {},
      redirectForm: {},
      voucher: null,
      workoutBlockId: null,
    };
  },
  created() {
    this.gatewayReference = this.$route.query.id;
    this.voucher = this.$route.query.voucher;
  },
  computed: {
    ...mapGetters(["checkoutCode", "coldCheckout"]),
  },
  methods: {
    async handleSuccessRedirect(data) {
      const payment = data.transactionStatus.transaction.payment;
      const products = payment.order.orderproductvariantSet.map((e) => ({
        product_id: e.productVariant.id,
        sku: e.productVariant.id,
        name: e.productVariant.product.code,
        price: e.basePrice,
        quantity: e.quantity,
      }));

      const primaryProductCode = this.getPrimaryProductCode(products);
      const order = {
        checkout_id: this.$store.getters.orderId,
        order_id: this.$store.getters.orderId,
        total: payment.totalAmount,
        discount: payment.discountAmount,
        currency: payment.order.currency,
        products: products,
        opportunity: this.opportunity(primaryProductCode),
      };

      if (data.transactionStatus.voucher) {
        this.$store.dispatch("updateTransactionVoucher", data.transactionStatus.voucher);
      }

      // reset checkout opportunity
      this.$store.dispatch("updateTrackedCheckoutOpportunity", "");

      const userProfile = payment.order.user.userProfile;
      if (payment.order.user.subscriptions.edges.length > 0) {
        // since we are filtering by active: true, there should really only be one.
        let subscription = payment.order.user.subscriptions.edges[0].node;
        subscription.id = decodeId(subscription.id);
        await this.$store.dispatch("updateRole", { role: subscription.product.type });
        await this.$store.dispatch("setSubscription", subscription);
      }
      let redirectPath;
      if (userProfile.idNumber === null && userProfile.passportNumber === null && userProfile.isDiscoveryMember) {
        // using router here doesn't work. Seems like page needs a full refresh.
        redirectPath = "/account/discovery";
      } else {
        redirectPath = `/success/${payment.id}`;
        let voucher = data.transactionStatus.voucher || this.voucher;
        if (voucher) {
          redirectPath += "?voucher=" + voucher;
        }
      }
      let workoutBlockId = sessionStorage.getItem("workoutBlockId");
      if (!workoutBlockId && data.transactionStatus?.workoutBlocks?.length > 0) {
        // API will return an array with block ids. [1,2,3]
        // There should only be one main block that the user purchased, this is the main thing we want to return
        // this may break if you purchase more than one block at the same time. But can you?? For now, let's just pick the first one.
        workoutBlockId = data.transactionStatus.workoutBlocks[0];
      }
      if (workoutBlockId) {
        // this is the one that seems to trigger when purchasing challenge. either via web app or via /start/check
        //redirectPath = `/program/${workoutBlockId}?confirm=1&success=1`;
        redirectPath = `/success/${payment.id}`;
      }
      if (this.coldCheckout) {
        sessionStorage.setItem("redirectPath", redirectPath);
        sessionStorage.setItem("paymentId", payment.id);
        if (workoutBlockId) {
          //redirectPath = `/program/${workoutBlockId}?confirm=1&success=1`;
          redirectPath = `/success/${payment.id}`;
        } else {
          redirectPath = `/checkout/password/${this.checkoutCode}`;
        }
      } else {
        const nutritionBlockId = sessionStorage.getItem("nutritionBlockId");
        if (nutritionBlockId) {
          redirectPath = `/nutrition/${nutritionBlockId}?confirm=1`;
        }
        sessionStorage.removeItem("nutritionBlockId");
      }
      sessionStorage.removeItem("workoutBlockId");

      // if we have a 1-1 checkout and a selected coach. Set it on the subscription.
      // it may also come through as an ID already.
      const selectedCoach = sessionStorage.getItem("selectedCoach");
      const selectedCoachId = sessionStorage.getItem("selectedCoachId");
      if (
        (selectedCoach || selectedCoachId) &&
        primaryProductCode.includes("1-on-1") &&
        this.$store.getters.subscription?.id
      ) {
        let coachId;
        if (selectedCoachId) {
          coachId = selectedCoachId;
          sessionStorage.removeItem("selectedCoachId");
        } else if (selectedCoach) {
          const decodedCoach = JSON.parse(selectedCoach);
          coachId = decodedCoach.userId;
          sessionStorage.removeItem(selectedCoach);
        }
        if (!coachId) {
          return;
        }

        await this.$apollo.mutate({
          mutation: UPDATE_SUBSCRIPTION_MUTATION,
          variables: {
            input: {
              coachId: coachId,
            },
          },
          refetchQueries: ["accountQuery"],
        });
      }

      // window location href so that page doesn't reload twice.
      try {
        Vue.analytics.fbq.event("Purchase", {
          value: payment.totalAmount,
          currency: "ZAR",
        });
        window.analytics.track("Order Completed", order);
        // setting timeout to make sure that the events have time to fire.
        setTimeout(() => {
          window.location.href = redirectPath;
        }, 1000);
      } catch (_err) {
        window.location.href = redirectPath;
      }
    },
    doRetryPayment() {
      this.$refs.observer.validate().then((valid) => {
        if (valid) {
          this.buttonLoading = true;
          let brands = {
            visa: "VISA",
            mastercard: "MASTER",
          };

          let card = {
            holder: this.cardDetails.holder,
            cvv: this.cardDetails.cvv,
            number: this.cardDetails.number.replace(/\s/g, ""),
            brand: brands[this.cardDetails.brand] || "PRIVATE_LABEL",
            expiry: ["20" + this.cardDetails.expiryYear, this.cardDetails.expiryMonth, "01"].join("-"),
          };

          let data = {
            failedTransactionId: this.gatewayReference,
            card: card,
          };

          this.$apollo
            .mutate({
              mutation: RETRY_PAYMENT_MUTATION,
              variables: {
                input: data,
              },
            })
            .then((response) => {
              if (response.data && !response.loading) {
                let data = response.data.retryPaymentMutation;
                // non 3d secure payment that is successful
                if (data.gatewayResult.transactionStatus === "SUCCESS") {
                  // hitting TRANSACTION_STATUS_QUERY so that we can trigger events and redirect using `handleSuccessRedirect()`
                  this.$apollo
                    .query({
                      query: TRANSACTION_STATUS_QUERY,
                      variables: {
                        reference: this.gatewayReference,
                      },
                    })
                    .then((response) => {
                      if (response.data) {
                        // dont' check for success here. It will return false. We know it' successful, so just redirect.
                        this.handleSuccessRedirect(response.data);
                      }
                    });
                } else {
                  this.redirectForm = JSON.parse(data.gatewayResult.redirect);
                  this.$refs.redirectForm.submit();
                }
              }
            });
        }
      });
    },
  },
  apollo: {
    transactionStatus: {
      query: TRANSACTION_STATUS_QUERY,
      variables() {
        return {
          reference: this.gatewayReference,
        };
      },
      fetchPolicy: "network-only",
      update(data) {
        if (data.transactionStatus && data.transactionStatus.transaction) {
          if (data.transactionStatus.transaction.transactionStatus == "SUCCESS") {
            this.$store.dispatch("clearPreferredVariant");
            this.handleSuccessRedirect(data);
          } else {
            this.$store.dispatch("updatePaymentRetry", true);
            this.retryPayment = true;
          }
        }
        return null;
      },
      skip() {
        return !this.gatewayReference;
      },
    },
  },
};
</script>
