<template>
  <div class="pt-3 px-2">
    <h2 class="heading-2 text-left navy--text ml-2">
      {{ header }}
      <slot name="subheader" />
    </h2>
    <v-row class="pa-2">
      <v-col v-for="product in filteredProducts" :key="product.node.id" cols="12" md="6" class="d-flex flex-column">
        <j-card
          rounded="lg"
          class="my-1 j-elevated-2 d-flex flex-column flex-grow-1"
          :disabled="productDisabled(product.node)"
          :to="`/checkout/${product.node.code}`"
        >
          <v-img
            v-if="product.node.image"
            :src="product.node.image"
            :lazy-src="product.node.image"
            :aspect-ratio="10 / 9"
            contain
            class="flex-grow-0"
          />
          <j-gradient-text v-if="hasPrice(product.node)" :gradient="['pink', 'yellow']" class="text-uppercase">
            R {{ product.node.productvariantSet.edges[0].node.productvariantpriceSet[0].price }}
          </j-gradient-text>
          <v-card-text class="d-flex flex-column flex-grow-1">
            <div class="navy--text heading-5 font-weight-bold">{{ product.node.title }}</div>
            {{ product.node.shortDescription }}
          </v-card-text>
        </j-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "ProductSection",
  props: {
    header: {
      type: String,
      required: true,
    },
    products: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  computed: {
    filteredProducts() {
      if (this.$store.getters.role === "CLUB") {
        return this.products.filter((product) => product.node.code != "jeff-club");
      }
      return this.products;
    },
  },
  methods: {
    hasPrice(product) {
      if (product.productvariantSet.edges[0]?.node?.productvariantpriceSet[0]?.price) {
        return true;
      }
      return false;
    },
    productDisabled(product) {
      if (!this.subscriptionProduct) {
        return false;
      }
      let mapping = {
        "JEFF Club + 1-on-1 Coaching": ["JEFF Club + 1-on-1 Coaching", "JEFF Club"],
        "JEFF Club": ["JEFF Club"],
      };
      if (!mapping[this.subscriptionProduct.product.title]) {
        return false;
      }
      return mapping[this.subscriptionProduct.product.title].includes(product.title);
    },
  },
};
</script>
