<template>
  <j-card rounded="lg">
    <v-toolbar class="mb-6">
      <v-toolbar-title>Confirm program</v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="$emit('closed')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <div v-if="workoutBlock.price > 0">
        <h4 class="navy--text mb-6 mt-4">
          {{ blockStarted ? "Started" : "Starts" }} {{ workoutBlock.startDate | moment("ddd, D MMM 'YY") }}
        </h4>
      </div>

      <div v-else>
        <plan-options @set-self-paced="selfPacedOption = $event" />
        <div v-if="!selfPacedOption">
          <div v-if="!blockStarted">
            <h3 class="navy--text mb-2 mt-4">Starts {{ startDate | moment("ddd, D MMM 'YY") }}</h3>
          </div>
          <div v-else>
            <h3 class="navy--text mb-2 mt-4">Start date</h3>
            <j-radio-group v-model="startDateOption">
              <div
                v-if="
                  exerciseWorkoutSubscriptionBlocks &&
                  exerciseWorkoutSubscriptionBlocks.length > 0 &&
                  workoutSubscriptionBlockEndDates
                "
              >
                <j-radio label="When my current program ends" value="CURRENT_ENDS" />
                <p>
                  Set start date to {{ lastEndDate | moment("ddd, D MMM YY") }}.
                  <span v-if="hasMultiplePrograms">
                    End dates of current programs: {{ workoutSubscriptionBlockEndDates }}
                  </span>
                </p>
              </div>
              <j-radio label="Start immediately" value="IMMEDIATELY" />
              <p>You'll start immediately within week 1, since programs begin on Mondays.</p>
              <j-radio label="Set start date myself" value="SET" />
            </j-radio-group>

            <v-menu
              v-if="startDateOption === 'SET'"
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <j-text-field prepend-inner-icon="mdi-calendar" v-model="startDate" readonly v-bind="attrs" v-on="on" />
              </template>
              <v-date-picker
                ref="picker"
                color="navy"
                v-model="startDate"
                :min="immediateStartDate"
                :allowed-dates="allowedDates"
                @change="saveStartDate"
              />
            </v-menu>
          </div>
        </div>

        <div v-else>
          <div v-if="!blockStarted">
            <h3 class="navy--text mb-2 mt-4">Starts {{ startDate | moment("ddd, D MMM 'YY") }}</h3>
          </div>
          <h3 v-else class="navy--text mb-2 mt-4">Get started</h3>
          The plan will kick off immediately. Your Home page will give you your activity. Once logged you will get the
          next activity, each to do at your own pace.
        </div>
      </div>
    </v-card-text>
    <v-card-actions class="justify-center pb-4">
      <j-btn secondary @click="confirm()" :disabled="!canSubmit" :loading="loading">
        <strong class="white--text">{{ buttonText }}</strong>
      </j-btn>
    </v-card-actions>
    <p v-if="workoutBlock.price" class="text-center pb-4">
      <span v-if="workoutBlock.strikePrice" class="text-decoration-line-through">R {{ workoutBlock.strikePrice }}</span>
      R {{ workoutBlock.price }} with
      <strong>{{ $appName }}</strong>
    </p>
  </j-card>
</template>

<script>
import { nextDateForDay } from "@/lib/fancy_date";
import PlanOptions from "@/components/workoutblocks/PlanOptions";

export default {
  name: "ConfirmProgram",
  components: {
    PlanOptions,
  },
  data() {
    return {
      selectedNutritionBlockId: null,
      startDate: null,
      allowedDates: (val) => new Date(val).getUTCDay() === 1, // only Monday!
      menu: false,
      makePayment: true, //setting to true, since entire block is hidden for now.
      startDateOption: null,
      lastEndDate: null,
      selfPacedOption: false,
    };
  },
  props: {
    workoutBlock: {
      type: Object,
      required: true,
    },
    workoutSubscriptionBlocks: {
      type: Array,
      required: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    buttonText() {
      if (this.workoutBlock.price > 0) {
        return "Continue to Payment";
      }
      return "Confirm";
    },
    canSubmit() {
      if (this.workoutBlock.price > 0) {
        return this.makePayment;
      }
      return this.selfPacedOption || (!this.selfPacedOption && this.startDateOption != null);
    },
    immediateStartDate() {
      return this.$moment().startOf("isoweek").format("YYYY-MM-DD");
    },
    workoutSubscriptionBlockEndDates() {
      let endDates = [];
      // should be ordered by start_date ASC on backend.
      if (this.exerciseWorkoutSubscriptionBlocks.length > 0) {
        // only add if end date is a Monday. A user may have manually ended blocks and then the date is not valid
        this.exerciseWorkoutSubscriptionBlocks.forEach((el) => {
          if (this.$moment(el.endDate).isoWeekday() === 1 && this.$moment(el.endDate).isAfter()) {
            endDates.push(this.$moment(el.endDate).subtract(1, "days").format("D MMM 'YY"));
            this.lastEndDate = el.endDate;
          }
        });
      }
      //$moment(workoutBlock.startDate).format("ddd, D MMM 'YY")
      return endDates.join(", ");
    },
    exerciseWorkoutSubscriptionBlocks() {
      return this.workoutSubscriptionBlocks?.filter((element) => element.block.type === "PROGRAM") ?? [];
    },
    hasMultiplePrograms() {
      return this.exerciseWorkoutSubscriptionBlocks.length > 1;
    },
    isCourse() {
      return this.workoutBlock.type == "COURSE";
    },
    blockStarted() {
      return this.workoutBlock.startDate == null || this.$moment(this.workoutBlock.startDate) <= this.$moment();
    },
  },
  methods: {
    confirm() {
      if (!this.selfPacedOption) {
        if (this.startDateOption === "CURRENT_ENDS") {
          this.startDate = this.lastEndDate;
        }
        if (this.startDateOption === "IMMEDIATELY") {
          this.startDate = this.immediateStartDate;
        }
        // let's keep the child components dumb and do all saving of data in the parent. just emit here.
        this.$emit("saved", {
          startDate: this.startDate,
          selectedNutritionBlockId: this.selectedNutritionBlockId,
          isSelfPaced: false,
        });
      } else {
        let startDate = this.immediateStartDate;

        if (this.workoutBlock.startDate && this.$moment().isBefore(this.workoutBlock.startDate)) {
          startDate = this.workoutBlock.startDate;
        }
        this.$emit("saved", {
          startDate: startDate,
          selectedNutritionBlockId: this.selectedNutritionBlockId,
          isSelfPaced: true,
        });
      }
    },
    saveStartDate(date) {
      this.$refs.menu.save(date);
    },
  },
  created() {
    if (this.workoutBlock.price > 0 && this.workoutBlock.startDate) {
      this.startDate = this.workoutBlock.startDate;
    } else {
      this.startDate = nextDateForDay(1);
    }
    this.selectedNutritionBlockId = this.workoutBlock.relatedNutritionBlock
      ? this.workoutBlock.relatedNutritionBlock.id
      : null;

    if (!this.blockStarted && this.workoutBlock.startDate) {
      this.startDateOption = "SET";
      this.startDate = this.workoutBlock.startDate;
    }
  },
};
</script>

<style lang="scss" scoped>
.half-width {
  min-width: 50%;
}
</style>
