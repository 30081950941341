// Country names object using 2-letter country codes to reference country name
// ISO 3166 Alpha-2 Format: [2 letter Country Code]: [Country Name]
// Sorted alphabetical by country name (special characters on bottom)
export const countryListAlpha2 = [
  { id: "AF", title: "Afghanistan" },
  { id: "AL", title: "Albania" },
  { id: "DZ", title: "Algeria" },
  { id: "AS", title: "American Samoa" },
  { id: "AD", title: "Andorra" },
  { id: "AO", title: "Angola" },
  { id: "AI", title: "Anguilla" },
  { id: "AQ", title: "Antarctica" },
  { id: "AG", title: "Antigua and Barbuda" },
  { id: "AR", title: "Argentina" },
  { id: "AM", title: "Armenia" },
  { id: "AW", title: "Aruba" },
  { id: "AU", title: "Australia" },
  { id: "AT", title: "Austria" },
  { id: "AZ", title: "Azerbaijan" },
  { id: "BS", title: "Bahamas (the)" },
  { id: "BH", title: "Bahrain" },
  { id: "BD", title: "Bangladesh" },
  { id: "BB", title: "Barbados" },
  { id: "BY", title: "Belarus" },
  { id: "BE", title: "Belgium" },
  { id: "BZ", title: "Belize" },
  { id: "BJ", title: "Benin" },
  { id: "BM", title: "Bermuda" },
  { id: "BT", title: "Bhutan" },
  { id: "BO", title: "Bolivia (Plurinational State of)" },
  { id: "BQ", title: "Bonaire}, Sint Eustatius and Saba" },
  { id: "BA", title: "Bosnia and Herzegovina" },
  { id: "BW", title: "Botswana" },
  { id: "BV", title: "Bouvet Island" },
  { id: "BR", title: "Brazil" },
  { id: "IO", title: "British Indian Ocean Territory (the)" },
  { id: "BN", title: "Brunei Darussalam" },
  { id: "BG", title: "Bulgaria" },
  { id: "BF", title: "Burkina Faso" },
  { id: "BI", title: "Burundi" },
  { id: "CV", title: "Cabo Verde" },
  { id: "KH", title: "Cambodia" },
  { id: "CM", title: "Cameroon" },
  { id: "CA", title: "Canada" },
  { id: "KY", title: "Cayman Islands (the)" },
  { id: "CF", title: "Central African Republic (the)" },
  { id: "TD", title: "Chad" },
  { id: "CL", title: "Chile" },
  { id: "CN", title: "China" },
  { id: "CX", title: "Christmas Island" },
  { id: "CC", title: "Cocos (Keeling) Islands (the)" },
  { id: "CO", title: "Colombia" },
  { id: "KM", title: "Comoros (the)" },
  { id: "CD", title: "Congo (the Democratic Republic of the)" },
  { id: "CG", title: "Congo (the)" },
  { id: "CK", title: "Cook Islands (the)" },
  { id: "CR", title: "Costa Rica" },
  { id: "HR", title: "Croatia" },
  { id: "CU", title: "Cuba" },
  { id: "CW", title: "Curaçao" },
  { id: "CY", title: "Cyprus" },
  { id: "CZ", title: "Czechia" },
  { id: "CI", title: "Côte d'Ivoire" },
  { id: "DK", title: "Denmark" },
  { id: "DJ", title: "Djibouti" },
  { id: "DM", title: "Dominica" },
  { id: "DO", title: "Dominican Republic (the)" },
  { id: "EC", title: "Ecuador" },
  { id: "EG", title: "Egypt" },
  { id: "SV", title: "El Salvador" },
  { id: "GQ", title: "Equatorial Guinea" },
  { id: "ER", title: "Eritrea" },
  { id: "EE", title: "Estonia" },
  { id: "SZ", title: "Eswatini" },
  { id: "ET", title: "Ethiopia" },
  { id: "FK", title: "Falkland Islands (the) [Malvinas]" },
  { id: "FO", title: "Faroe Islands (the)" },
  { id: "FJ", title: "Fiji" },
  { id: "FI", title: "Finland" },
  { id: "FR", title: "France" },
  { id: "GF", title: "French Guiana" },
  { id: "PF", title: "French Polynesia" },
  { id: "TF", title: "French Southern Territories (the)" },
  { id: "GA", title: "Gabon" },
  { id: "GM", title: "Gambia (the)" },
  { id: "GE", title: "Georgia" },
  { id: "DE", title: "Germany" },
  { id: "GH", title: "Ghana" },
  { id: "GI", title: "Gibraltar" },
  { id: "GR", title: "Greece" },
  { id: "GL", title: "Greenland" },
  { id: "GD", title: "Grenada" },
  { id: "GP", title: "Guadeloupe" },
  { id: "GU", title: "Guam" },
  { id: "GT", title: "Guatemala" },
  { id: "GG", title: "Guernsey" },
  { id: "GN", title: "Guinea" },
  { id: "GW", title: "Guinea-Bissau" },
  { id: "GY", title: "Guyana" },
  { id: "HT", title: "Haiti" },
  { id: "HM", title: "Heard Island and McDonald Islands" },
  { id: "VA", title: "Holy See (the)" },
  { id: "HN", title: "Honduras" },
  { id: "HK", title: "Hong Kong" },
  { id: "HU", title: "Hungary" },
  { id: "IS", title: "Iceland" },
  { id: "IN", title: "India" },
  { id: "ID", title: "Indonesia" },
  { id: "IR", title: "Iran (Islamic Republic of)" },
  { id: "IQ", title: "Iraq" },
  { id: "IE", title: "Ireland" },
  { id: "IM", title: "Isle of Man" },
  { id: "IL", title: "Israel" },
  { id: "IT", title: "Italy" },
  { id: "JM", title: "Jamaica" },
  { id: "JP", title: "Japan" },
  { id: "JE", title: "Jersey" },
  { id: "JO", title: "Jordan" },
  { id: "KZ", title: "Kazakhstan" },
  { id: "KE", title: "Kenya" },
  { id: "KI", title: "Kiribati" },
  { id: "KP", title: "Korea (the Democratic People's Republic of)" },
  { id: "KR", title: "Korea (the Republic of)" },
  { id: "KW", title: "Kuwait" },
  { id: "KG", title: "Kyrgyzstan" },
  { id: "LA", title: "Lao People's Democratic Republic (the)" },
  { id: "LV", title: "Latvia" },
  { id: "LB", title: "Lebanon" },
  { id: "LS", title: "Lesotho" },
  { id: "LR", title: "Liberia" },
  { id: "LY", title: "Libya" },
  { id: "LI", title: "Liechtenstein" },
  { id: "LT", title: "Lithuania" },
  { id: "LU", title: "Luxembourg" },
  { id: "MO", title: "Macao" },
  { id: "MG", title: "Madagascar" },
  { id: "MW", title: "Malawi" },
  { id: "MY", title: "Malaysia" },
  { id: "MV", title: "Maldives" },
  { id: "ML", title: "Mali" },
  { id: "MT", title: "Malta" },
  { id: "MH", title: "Marshall Islands (the)" },
  { id: "MQ", title: "Martinique" },
  { id: "MR", title: "Mauritania" },
  { id: "MU", title: "Mauritius" },
  { id: "YT", title: "Mayotte" },
  { id: "MX", title: "Mexico" },
  { id: "FM", title: "Micronesia (Federated States of)" },
  { id: "MD", title: "Moldova (the Republic of)" },
  { id: "MC", title: "Monaco" },
  { id: "MN", title: "Mongolia" },
  { id: "ME", title: "Montenegro" },
  { id: "MS", title: "Montserrat" },
  { id: "MA", title: "Morocco" },
  { id: "MZ", title: "Mozambique" },
  { id: "MM", title: "Myanmar" },
  { id: "NA", title: "Namibia" },
  { id: "NR", title: "Nauru" },
  { id: "NP", title: "Nepal" },
  { id: "NL", title: "Netherlands (the)" },
  { id: "NC", title: "New Caledonia" },
  { id: "NZ", title: "New Zealand" },
  { id: "NI", title: "Nicaragua" },
  { id: "NE", title: "Niger (the)" },
  { id: "NG", title: "Nigeria" },
  { id: "NU", title: "Niue" },
  { id: "NF", title: "Norfolk Island" },
  { id: "MP", title: "Northern Mariana Islands (the)" },
  { id: "NO", title: "Norway" },
  { id: "OM", title: "Oman" },
  { id: "PK", title: "Pakistan" },
  { id: "PW", title: "Palau" },
  { id: "PS", title: "Palestine}, State of" },
  { id: "PA", title: "Panama" },
  { id: "PG", title: "Papua New Guinea" },
  { id: "PY", title: "Paraguay" },
  { id: "PE", title: "Peru" },
  { id: "PH", title: "Philippines (the)" },
  { id: "PN", title: "Pitcairn" },
  { id: "PL", title: "Poland" },
  { id: "PT", title: "Portugal" },
  { id: "PR", title: "Puerto Rico" },
  { id: "QA", title: "Qatar" },
  { id: "MK", title: "Republic of North Macedonia" },
  { id: "RO", title: "Romania" },
  { id: "RU", title: "Russian Federation (the)" },
  { id: "RW", title: "Rwanda" },
  { id: "RE", title: "Réunion" },
  { id: "BL", title: "Saint Barthélemy" },
  { id: "SH", title: "Saint Helena}, Ascension and Tristan da Cunha" },
  { id: "KN", title: "Saint Kitts and Nevis" },
  { id: "LC", title: "Saint Lucia" },
  { id: "MF", title: "Saint Martin (French part)" },
  { id: "PM", title: "Saint Pierre and Miquelon" },
  { id: "VC", title: "Saint Vincent and the Grenadines" },
  { id: "WS", title: "Samoa" },
  { id: "SM", title: "San Marino" },
  { id: "ST", title: "Sao Tome and Principe" },
  { id: "SA", title: "Saudi Arabia" },
  { id: "SN", title: "Senegal" },
  { id: "RS", title: "Serbia" },
  { id: "SC", title: "Seychelles" },
  { id: "SL", title: "Sierra Leone" },
  { id: "SG", title: "Singapore" },
  { id: "SX", title: "Sint Maarten (Dutch part)" },
  { id: "SK", title: "Slovakia" },
  { id: "SI", title: "Slovenia" },
  { id: "SB", title: "Solomon Islands" },
  { id: "SO", title: "Somalia" },
  { id: "ZA", title: "South Africa" },
  { id: "GS", title: "South Georgia and the South Sandwich Islands" },
  { id: "SS", title: "South Sudan" },
  { id: "ES", title: "Spain" },
  { id: "LK", title: "Sri Lanka" },
  { id: "SD", title: "Sudan (the)" },
  { id: "SR", title: "Suriname" },
  { id: "SJ", title: "Svalbard and Jan Mayen" },
  { id: "SE", title: "Sweden" },
  { id: "CH", title: "Switzerland" },
  { id: "SY", title: "Syrian Arab Republic" },
  { id: "TW", title: "Taiwan" },
  { id: "TJ", title: "Tajikistan" },
  { id: "TZ", title: "Tanzania}, United Republic of" },
  { id: "TH", title: "Thailand" },
  { id: "TL", title: "Timor-Leste" },
  { id: "TG", title: "Togo" },
  { id: "TK", title: "Tokelau" },
  { id: "TO", title: "Tonga" },
  { id: "TT", title: "Trinidad and Tobago" },
  { id: "TN", title: "Tunisia" },
  { id: "TR", title: "Turkey" },
  { id: "TM", title: "Turkmenistan" },
  { id: "TC", title: "Turks and Caicos Islands (the)" },
  { id: "TV", title: "Tuvalu" },
  { id: "UG", title: "Uganda" },
  { id: "UA", title: "Ukraine" },
  { id: "AE", title: "United Arab Emirates (the)" },
  { id: "GB", title: "United Kingdom of Great Britain and Northern Ireland (the)" },
  { id: "UM", title: "United States Minor Outlying Islands (the)" },
  { id: "US", title: "United States of America (the)" },
  { id: "UY", title: "Uruguay" },
  { id: "UZ", title: "Uzbekistan" },
  { id: "VU", title: "Vanuatu" },
  { id: "VE", title: "Venezuela (Bolivarian Republic of)" },
  { id: "VN", title: "Viet Nam" },
  { id: "VG", title: "Virgin Islands (British)" },
  { id: "VI", title: "Virgin Islands (U.S.)" },
  { id: "WF", title: "Wallis and Futuna" },
  { id: "EH", title: "Western Sahara" },
  { id: "YE", title: "Yemen" },
  { id: "ZM", title: "Zambia" },
  { id: "ZW", title: "Zimbabwe" },
  { id: "AX", title: "Åland Islands" },
];
