<template>
  <v-container>
    <v-alert v-if="failed" type="error">Unable to log you in</v-alert>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { TOKEN_LOGIN_MUTATION } from "@/graphql/mutations/authentication";
import VueJwtDecode from "vue-jwt-decode";

export default {
  name: "TokenLogin",
  data() {
    return {
      failed: false,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  async created() {
    await this.loginUser();

    if (!this.$store.getters.accessToken) {
      return (this.failed = true);
    }
    const redirect = this.$route.query?.redirect ?? "/";
    await this.$router.push(redirect);
  },
  methods: {
    async loginUser() {
      const token = this.$route.params.token;
      const result = await this.$apollo.mutate({
        mutation: TOKEN_LOGIN_MUTATION,
        variables: {
          token: token,
        },
      });
      const data = result.data.loginViaToken;
      if (!data.success) return;

      //log the user in
      const accessToken = data.token;
      const tokenPayload = VueJwtDecode.decode(accessToken);
      const refreshToken = tokenPayload.exp;
      let user = data.user;
      user.phone = data.user.userProfile ? data.user.userProfile.phone : "";

      const profileImage = data.user.userProfile ? data.user.userProfile.profilePicture : "";
      let timezone = "Africa/Johannesburg";
      let userRole = "INACTIVE";
      let programType = "";

      if (data.subscription && data.subscription.product) {
        userRole = data.subscription.product.type;
        programType = data.subscription.product.programType;
      }

      if (data.subscription) {
        await this.$store.dispatch("setSubscription", data.subscription);
      }

      await this.$store.dispatch("login", {
        accessToken,
        refreshToken,
        user,
        userRole,
        programType,
        timezone,
        profileImage,
      });
    },
  },
};
</script>
