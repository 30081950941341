<template>
  <div class="pt-2">
    <span v-if="isRequiredProduct" class="text-h6 text--secondary">
      <strong>{{ product.title }}</strong>
    </span>
    <!-- if we are passing a product variant id, then show it in the dropdown (will be only one item) -->
    <v-row
      v-if="paymentOptions.length > 0 && allowBillingPeriodSelection"
      align="center"
      justify="center"
    >
      <v-col cols="12" class="pb-0">
        <v-select v-model="product.variant" :items="paymentOptions" dense outlined class="mb-n5 mt-0 pt-0" />
      </v-col>
    </v-row>
    <v-row v-if="product.variant" align="center" justify="center" :class="allowBillingPeriodSelection ? 'mt-0' : 'mt-n6'">
      <v-col cols="12">
        <v-card-title class="p-text navy--text pa-0 mt-1 py-1">
          {{ currencySymbol }} {{ product.variant.price.price | currency }}
          <span v-if="paymentPlan" class="ml-1">{{ paymentPlan }}</span>
        </v-card-title>
        <v-card-text
          v-if="
            product.variant &&
              product.variant.price &&
              product.variant.description
          "
          class="pa-0 mt-3 p-text"
        >
          {{ product.variant.description }}
        </v-card-text>
        
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { decodeId } from "@/lib/string";
import CheckoutMixin from "../mixins/CheckoutMixin";

export default {
  name: "ProductBlock",
  props: {
    product: {
      type: Object,
      required: true,
    },
    // if we pass in a product variant id, then only show that one one.
    productVariantId: {
      type: [Number, String],
      required: false,
    },
    isRequiredProduct: {
      type: Boolean,
      default: false,
    },
    allowBillingPeriodSelection: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [CheckoutMixin],
  data() {
    return {
      currency: "zar",
      paymentOptions: [],
      defaultPrice: null,
    };
  },
  created() {
    this.paymentOptions = this.getPaymentPeriods();
    if (this.paymentOptions.length == 0) {
      //this is a one time payment
      this.product.variant = this.getOnceOffProductPrice();
    } else if (!this.product.variant || this.product.variant.price == null) {
      this.product.variant = this.defaultPrice;
    }
  },
  watch: {
    "product.variant": function(value) {
      this.product.variant = value;
      if (!this.isRequiredProduct) {
        this.$store.dispatch("updateCheckoutCode", this.$route.params.code);
        this.$store.dispatch("updateCheckoutProduct", this.product);
      }
    },
  },
  computed: {
    currencySymbol() {
      let symbols = {
        zar: "R",
        usd: "$",
        gbp: "£",
      };
      return symbols[this.currency];
    },
    paymentPlan() {
      let period = this.product.variant.billingPeriod;
      return this.paymentPlanText(period);
    },
  },
  methods: {
    getPaymentPeriods() {
      let periods = [];
      let variants = [];

      if (this.product && this.product.productvariantSet) {
        let vm = this;
        variants = this.product.productvariantSet;
        periods = variants
          .filter((variant) => variant.billingPeriod != null && !variant.isHidden)
          .filter(function(variant) {
            // gift vouchers are not gated.
            if (vm.product.code == "gift-voucher") {
              return true;
            }
            // this is how we determine grad. Grad can buy challenge passes, i.e everything,
            if (vm.$store.getters.role == "ONE_ON_ONE" && vm.$store.getters.programType.includes("WEEKLY")) {
              return true;
            }
            if (
              vm.product.code === "premium-block" &&
              vm.$store.getters.role === "ONE_ON_ONE" ||
              vm.$store.getters.role === "CLUB"
            ) {
              return true;
            }

            // do nothing with this so that my IDE doesn't freak out. 
            variant;
            return true;
          })
          .filter(function(variant) {
            // if we are passing a product variant id (via GET) then only display/use that one variant.
            // we only want to gate it for the premium-block/challenge product
            if (vm.productVariantId) {
              if (decodeId(vm.productVariantId) === decodeId(variant.id)) {
                vm.product.variant = variant;
                return true;
              }
              return false;
            }
            return true;
          })
          .map((variant) => {
            let result = variant;
            result.price = variant.productvariantpriceSet[0];

            if (result.isDefault) {
              this.defaultPrice = result;
            }

            return {
              value: result,
              text: `${variant.title}`,
            };
          });

        if (!this.defaultPrice && periods && periods.length > 0) {
          this.defaultPrice = periods[0].value;
        }
      }

      return periods;
    },
    getOnceOffProductPrice() {
      if (this.product && this.product.productvariantSet) {
        let variants = this.product.productvariantSet;
        variants = variants.filter((variant) => variant.billingPeriod == "ONCE_OFF");

        if (variants.length > 0) {
          let variant = variants[0];
          variant.price = variant.productvariantpriceSet[0];

          return variant;
        }
      }

      return null;
    },
  },
};
</script>

<style scoped>
.row + .row {
  margin-top: 0;
}
.font-18 {
  font-size: 18px;
}
.checkout-paragraph {
  font-size: 16px;
  line-height: 1.45rem;
}
</style>
