<template>
  <v-container class="pa-0 ma-0">
    <span>
      <span v-if="host.userProfile && host.userProfile.profilePicture">
        <v-avatar size="35px" class="mr-2 mb-2 p-text">
          <img :alt="host.firstName" :src="host.userProfile.profilePicture" />
        </v-avatar>
      </span>
      <span v-else>
        <v-avatar :color="getRandomColor()" size="35px" class="mr-2 mb-2 p-text">
          {{ getTitle(host) }}
        </v-avatar>
      </span>
    </span>
    <span v-for="attendee in attendeesFiltered" :key="attendee.id">
      <span v-if="attendee.attendee.userProfile && attendee.attendee.userProfile.profilePicture">
        <v-avatar size="35px" class="mr-2 mb-2 p-text">
          <img :alt="attendee.attendee.firstName" :src="attendee.attendee.userProfile.profilePicture" />
        </v-avatar>
      </span>
      <span v-else>
        <v-avatar :color="getRandomColor()" size="35px" class="mr-2 mb-2 p-text">
          {{ getTitle(attendee.attendee) }}
        </v-avatar>
      </span>
    </span>
  </v-container>
</template>

<script>
export default {
  name: "AttendeeList",
  props: {
    attendees: {
      type: Array,
      default: null,
    },
    host: {
      type: Object,
      default: null,
    },
  },
  computed: {
    attendeesFiltered() {
      let copy = JSON.parse(JSON.stringify(this.attendees));
      return copy.sort((a) => {
        if (a.attendee.userProfile == null) {
          return 1;
        }
        return a.attendee.userProfile.profilePicture ? -1 : 1;
      });
    },
  },
  methods: {
    getTitle(attendee) {
      let firstName = attendee.firstName;
      let lastName = attendee.lastName;

      if (firstName === "" && lastName === "") {
        //somehow the user does not have a first name or last name??
        return "NA";
      }

      return firstName[0].toUpperCase() + " " + lastName[0].toUpperCase();
    },
    getRandomColor() {
      let colors = ["exercise", "mindset", "nutrition"];
      return colors[Math.floor(Math.random() * colors.length)];
    },
  },
};
</script>
