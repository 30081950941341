<template>
  <j-card>
    <v-toolbar class="mb-2">
      <v-toolbar-title>
        <v-card-title class="secondary--text">
          <h2>Swap out {{ recipe.title }}</h2>
        </v-card-title>
        <v-card-subtitle>
          <j-gradient-text :gradient="['yellow', 'green']" class="text-uppercase">
            <strong>{{ mealSubtitle }}</strong>
          </j-gradient-text>
        </v-card-subtitle>
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="mt-4">
      <j-text-field
        rounded
        v-model="search"
        hide-details
        prepend-inner-icon="mdi-magnify"
        dense
        placeholder="Search recipe database"
        @input="updateSearch"
      />

      <recipe-filter-sort
        @triggerFilters="triggerFilters"
        :incoming-params="incomingParams"
        :meal="meal"
        :block-id="blockId"
        showBookmark
      />

      <div v-if="$apollo.loading && recipeList.length == 0">
        <v-row>
          <v-col cols="6" md="4"><v-skeleton-loader type="card-avatar" /> </v-col>
          <v-col cols="6" md="4"><v-skeleton-loader type="card-avatar" /> </v-col>
          <v-col cols="6" md="4"><v-skeleton-loader type="card-avatar" /> </v-col>
        </v-row>
      </div>
      <h3 class="my-2">All Menu Recipes</h3>
      <v-row v-if="recipeList.length > 0">
        <v-col cols="6" md="4" v-for="swapRecipe in recipeList" :key="swapRecipe.id">
          <recipe-listing-card
            @showLockedBanner="lockedToClubDialog = true"
            :recipe="swapRecipe.node"
            :originalRecipe="recipe"
            :meal="meal"
            :date="date"
            @close="$emit('close')"
          />
        </v-col>
      </v-row>
      <div v-if="!$apollo.loading && recipeList.length == 0">
        <j-alert class="ma-4">No recipes found</j-alert>
      </div>
      <scroll-loader :loader-method="loadmore" :loader-disable="!hasNextPage"> </scroll-loader>
      <v-dialog v-model="lockedToClubDialog" width="500">
        <j-card>
          <v-card-title class="headline grey lighten-2">
            <v-icon>mdi-diamond-stone</v-icon> Join JEFF Club to access
          </v-card-title>
          <v-card-text> Get full access to all the {{ $appName }} recipes when you're on JEFF Club! </v-card-text>
          <v-divider />
          <v-card-actions>
            <j-btn color="secondary" href="https://www.jeff.fitness/pages/jeff-club" target="_blank">
              Find Out More
            </j-btn>
          </v-card-actions>
        </j-card>
      </v-dialog>
    </v-card-text>
  </j-card>
</template>

<script>
import { RECIPES_QUERY } from "@/graphql/queries/nutrition";
import RecipeListingCard from "@/components/nutritionblocks/cards/RecipeListingCard";
import IngredientsMixin from "@/mixins/nutrition/IngredientsMixin";
import RecipeFilterSort from "@/components/nutritionblocks/blocks/RecipeFilterSort";

export default {
  name: "RecipeSwapSearch",
  mixins: [IngredientsMixin],
  components: {
    RecipeFilterSort,
    RecipeListingCard,
  },
  props: {
    recipe: {
      type: Object,
      required: true,
    },
    meal: {
      type: String,
      required: false,
    },
    date: {
      type: String,
      required: false,
    },
    blockId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      keyUpSearchInterval: null,
      search: null,
      searchString: null,
      loading: false,
      recipeList: [],
      after: "",
      endCursor: "",
      hasNextPage: true,
      scrollY: 0,
      lockedToClubDialog: false,
      filterVariables: {},
      incomingParams: {
        thisMenuFilter: this.blockId,
        recipeTypeFilter: this.meal,
      },
    };
  },
  methods: {
    updateSearch(val) {
      if (this.keyUpSearchInterval) {
        clearTimeout(this.keyUpSearchInterval);
        this.keyUpSearchInterval = null;
      }
      this.keyUpSearchInterval = setTimeout(() => {
        this.doSearch();
        this.searchString = val;
      }, 500);
    },
    triggerFilters(filterVariables) {
      this.filterVariables = filterVariables;
      this.doSearch();
    },
    loadmore() {
      if (!this.loading) {
        this.after = this.endCursor;
      }
    },
    doSearch() {
      this.recipeList = [];
      this.after = "";
      this.endCursor = "";
      this.hasNextPage = true;
    },
  },
  apollo: {
    recipes: {
      query: RECIPES_QUERY,
      fetchPolicy: "network-only",
      variables() {
        return {
          search: this.searchString,
          after: this.after,
          first: 12,
          ...this.filterVariables,
        };
      },
      result(response) {
        if (response.data) {
          this.loading = false;
          this.endCursor = response.data.recipes.pageInfo.endCursor;
          this.hasNextPage = response.data.recipes.pageInfo.hasNextPage;
          this.recipeList = [...this.recipeList, ...response.data.recipes.edges];
        }
      },
    },
  },
};
</script>
