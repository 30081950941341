<template>
  <div class="j-input-mask">
    <the-mask v-on="$listeners" :value="value" :mask="mask" :masked="masked" :placeholder="placeholder" class="input-control" />
    <div v-if="suffix" class="suffix">{{ suffix }}</div>
  </div>
</template>

<script>
// https://vuejs-tips.github.io/vue-the-mask/
import { TheMask } from "vue-the-mask";

export default {
  name: "JInputMask",
  components: {
    TheMask,
  },
  props: {
    mask: {
      type: Array,
      required: true,
    },
    value: {
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    masked: {
      type: Boolean,
      default: false,
    },
    suffix: {
      type: String,
      required: false,
    },
  },
};
</script>

<style lang="scss">
.j-input-mask {
  position: relative;

  .input-control {
    font-size: 16px;
    border: 1px solid #9e9e9e;
    width: 100%;
    border-radius: 25px;
    padding: 8px 20px;
    color: #212121;
  }
  .suffix {
    color: #2d3a46;
    position: absolute;
    top: 10px;
    right: 23px;
    font-size: 12px;
  }
}
</style>
