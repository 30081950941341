<template>
  <ValidationObserver ref="observer">
    <v-form @submit.prevent="submit">
      <j-text-field v-model="totallyRealFields.name" aria-hidden="true" class="totally_real_input"></j-text-field>

      <j-text-field v-model="totallyRealFields.surname" aria-hidden="true" class="totally_real_input"></j-text-field>

      <v-row no-gutters>
        <v-col v-if="requiresEmployeeCode" cols="12">
          <ValidationProvider id="employeeCode" v-slot="{ errors }" name="Employer Code" rules="required">
            <j-text-field
              v-model="employeeCode"
              outlined
              label="Please enter the code provided by your employer"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="6">
          <ValidationProvider id="firstName" v-slot="{ errors }" name="First name" rules="required">
            <j-text-field
              v-model="firstName"
              outlined
              label="First name"
              :error-messages="errors"
              class="mr-0 mr-md-1"
            />
          </ValidationProvider>
        </v-col>
        <v-col cols="12" md="6">
          <ValidationProvider id="lastName" v-slot="{ errors }" name="Last name" rules="required">
            <j-text-field v-model="lastName" outlined label="Last name" :error-messages="errors" class="mr-0 ml-md-1" />
          </ValidationProvider>
        </v-col>
        <v-col cols="12">
          <ValidationProvider id="username" v-slot="{ errors }" name="Email" rules="required|email">
            <j-text-field
              v-model.trim="username"
              label="Email"
              outlined
              :error-messages="errors"
              @keydown.space.prevent
            />
          </ValidationProvider>
        </v-col>

        <v-col cols="12">
          <ValidationProvider id="phone" v-slot="{ errors }" name="Phone Number" rules="required">
            <j-text-field
              v-model.trim="phone"
              label="Phone Number"
              outlined
              :error-messages="errors"
              @keydown.space.prevent
            />
          </ValidationProvider>
        </v-col>

        <v-col cols="12">
          <ValidationProvider
            v-if="includePassword"
            id="password"
            v-slot="{ errors }"
            name="Password"
            rules="required|verify_password"
          >
            <j-text-field
              v-model="password"
              outlined
              :append-icon="showPassword ? 'visibility' : 'visibility_off'"
              :type="showPassword ? 'text' : 'password'"
              label="Password"
              :error-messages="errors"
              @click:append="showPassword = !showPassword"
            />
          </ValidationProvider>
        </v-col>

        <v-col cols="12">
          <ValidationProvider
            v-if="includePassword"
            id="confirmPassword"
            v-slot="{ errors }"
            name="Confirm Password"
            rules="required|verify_password"
          >
            <j-text-field
              v-model="confirmPassword"
              outlined
              :append-icon="showPassword ? 'visibility' : 'visibility_off'"
              :type="showPassword ? 'text' : 'password'"
              label="Confirm Password"
              :error-messages="errors"
              @click:append="showPassword = !showPassword"
            />
          </ValidationProvider>
        </v-col>
      </v-row>

      <!-- <div class="pb-7">
        <ValidationProvider id="phone" v-slot="{ errors }" name="phone" rules="required">
          <vue-tel-input mode="international" v-model="phone" styleClasses="input-padding"></vue-tel-input>
          <span v-if="errors.length > 0" class="error--text">The Phone field is required</span>
        </ValidationProvider>
      </div>

      <ValidationProvider id="country" v-slot="{ errors }" name="country" rules="required">
        <v-autocomplete
          v-model="country"
          :items="countries"
          rounded
          filled
          label="Country"
          item-text="title"
          item-value="id"
          :error-messages="errors"
        />
      </ValidationProvider> -->

      <!-- <ValidationProvider id="suburb" v-slot="{ errors }" name="Suburb" rules="required">
        <j-text-field v-model="suburb" outlined label="Suburb" :error-messages="errors" />
      </ValidationProvider> -->

      <ValidationProvider id="terms" v-slot="{ errors }" name="Terms" :rules="{ required: { allowFalse: false } }">
        <j-checkbox v-model="terms" class="mt-n2" :error-messages="errors">
          <div slot="label">
            <span class="p-text">
              I have read and agree to the
              <a @click.stop href="https://www.jeff.fitness/privacy-policy" target="_blank" class="text-decoration-none"
                >Privacy Policy</a
              >
              &amp;
              <a
                @click.stop
                href="https://www.jeff.fitness/terms-and-conditions"
                target="_blank"
                class="text-decoration-none"
                >Terms</a
              >
            </span>
          </div>
        </j-checkbox>
      </ValidationProvider>
      <div v-if="!!$slots.terms" class="mb-8 p-text">
        <slot name="terms" />
      </div>

      <v-row v-if="errors != null" align="center" justify="center">
        <j-alert style="width: 100%" class="mt-2">
          <span v-for="(value, key, index) in errors" :key="key + index">
            <span v-for="(error, indexError) in value" :key="indexError" v-html="displayError(error['message'])">
            </span>
          </span>
        </j-alert>
      </v-row>

      <v-row justify="center">
        <j-btn type="submit" :loading="buttonLoading" width="100%" class="mt-4 mb-8">Continue</j-btn>
      </v-row>
    </v-form>
  </ValidationObserver>
</template>

<script>
import RegistrationMixin from "@/mixins/RegistrationMixin";

export default {
  name: "RegistrationForm",
  mixins: [RegistrationMixin],
};
</script>

<style scoped>
.input-padding {
  padding: 10px;
}
</style>
