<template>
  <div class="text-center">
    <ShareNetwork
      network="facebook"
      :url="shareUrl"
      :title="shareObject.title"
      :description="shareObject.description"
      :quote="shareObject.quote"
      :hashtags="shareObject.hashtags"
      class="text-decoration-none"
    >
      <j-btn color="facebook-blue">
        SHARE VIA FACEBOOK
        <v-icon>mdi-facebook</v-icon>
      </j-btn>
    </ShareNetwork>

    <br />
    <ShareNetwork
      network="whatsapp"
      :url="shareUrl"
      :title="shareObject.title"
      :description="shareObject.description"
      :quote="shareObject.quote"
      :hashtags="shareObject.hashtags"
      class="text-decoration-none"
    >
      <j-btn color="whatsapp-green" class="mt-3">
        SHARE VIA WHATSAPP
        <v-icon>mdi-whatsapp</v-icon>
      </j-btn>
    </ShareNetwork>
    <br />
    <j-btn tertiary @click="copyLink" class="mt-3">
      <span v-if="copied">Link Copied <v-icon>mdi-link-variant</v-icon></span>
      <span v-else>Copy Link <v-icon>mdi-link-variant-plus</v-icon></span>
    </j-btn>
  </div>
</template>

<script>
export default {
  name: "ShareButtonGroup",
  props: {
    shareObject: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      copied: false,
    };
  },
  computed: {
    shareUrl() {
      let url = this.shareObject?.url;
      if (!url && this.shareObject?.media?.startsWith("http")) {
        url = this.shareObject.media;
      }
      return url;
    }
  },
  methods: {
    copyLink() {
      this.copied = true;
      navigator.clipboard.writeText(this.shareUrl);
    },
  },
};
</script>
