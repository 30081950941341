<template>
  <div>
    <div class="d-flex justify-space-between align-center py-3">
      <j-checkbox
        v-model="exerciseCompleted"
        dense
      />
      <div
        class="ml-2 mr-auto"
        :class="{ 'activity-completed': exerciseCompleted, 'strikethrough': exerciseCompleted }"
      >
        <div
          class="d-flex"
        >
          <div class="std-text font-weight-bold mb-1">{{ exercise.exercise.title }}</div>
          <v-icon
            v-if="exerciseHasHowTo  && $vuetify.breakpoint.smAndUp"
            @click="$emit('open-exercise-info-dialog', exercise.exercise)"
            size="20"
            class="ml-2 mt-n1">mdi-information-outline
          </v-icon>
        </div>
        <p
          class="p-text activity-description"
          :class="{ strikethrough: exerciseCompleted }"
          v-html="exercise.description"
        />
      </div>
      <activity-exercise-image
        v-if="exercise.exercise.staticImage"
        class="d-none d-sm-flex ml-auto"
        :class="{ 'activity-completed': exerciseCompleted }"
        :exercise="exercise.exercise"
        :max-width="90"
      />
      <v-icon
        v-if="exerciseHasHowTo && !$vuetify.breakpoint.smAndUp"
        class="align-self-start"
        size="20"
        @click="$emit('open-exercise-info-dialog', exercise.exercise)">
        mdi-information-outline
      </v-icon>
    </div>

  </div>

</template>

<script>
import ActivityExerciseImage from "@/components/workoutblocks/cards/ActivityExerciseImage";
import ExercisePanelMixin from "@/mixins/ExercisePanelMixin";

export default {
  name: "UpcomingExercisePanel",
  components: {
    ActivityExerciseImage
  },
  mixins: [ExercisePanelMixin],
  props: {
    index: {
      type: Number,
      required:true
    },
    totalExercises: {
      type: Number,
      required: true
    },
  },
}
</script>

<style lang="scss" scoped>
.activity-completed {
  opacity: 0.4;
}
</style>