<template>
  <validation-observer ref="obs">
    <v-skeleton-loader v-if="$apollo.loading" type="article" />
    <v-form v-else>
      <validation-provider
        v-if="!messageAtBottom && chatData.isSharing"
        name="message"
        rules="required|max:250"
        v-slot="{ errors }"
      >
        <j-textarea
          v-model="chatData.chatText"
          auto-grow
          persistent-hint
          dense
          :error-messages="errors"
          rows="1"
          label="Message"
          counter="250"
        />
      </validation-provider>
      <j-treeview
        v-if="hasChats"
        v-model="chatData.sendToChats"
        :items="chatItems"
        :active="chatActive"
        dense
        selection-type="leaf"
        selectable
        activatable
        expand-icon=""
        :open="openChatTreeview"
        ref="chatTreeview"
        @update:active="updateChatSelected($event)"
      >
        <template v-slot:append="{ image }">
          <v-avatar v-if="image" size="30">
            <v-img alt="Chat image" :src="image" :lazy-src="image" />
          </v-avatar>
        </template>
      </j-treeview>
      <j-treeview
        v-if="withGroups && hasGroups"
        v-model="chatData.sendToGroups"
        :active="groupActive"
        :items="groupItems"
        dense
        selection-type="leaf"
        selectable
        activatable
        expand-icon=""
        :open="openGroupTreeview"
        ref="groupTreeview"
        @update:active="updateGroupSelected($event)"
      >
        <template v-slot:append="{ image }">
          <v-avatar v-if="image" size="30">
            <v-img alt="Group image" :src="image" :lazy-src="image" :aspect-ratio="1" />
          </v-avatar>
        </template>
      </j-treeview>
      <validation-provider
        v-if="messageAtBottom && chatData.isSharing"
        name="message"
        rules="required|max:250"
        v-slot="{ errors }"
      >
        <j-textarea
          v-model="chatData.chatText"
          auto-grow
          persistent-hint
          dense
          :error-messages="errors"
          rows="1"
          label="Message"
          counter="250"
          class="mt-3"
        />
      </validation-provider>
    </v-form>
  </validation-observer>
</template>

<script>
import { decodeId } from "@/lib/string";
import { extractNodes } from "@/lib/array";
import { SIMPLE_CHATS_FOR_USER } from "@/graphql/queries/chat";
import { GROUPS_QUERY } from "@/graphql/queries/group";

export default {
  name: "ShareOptions",
  props: {
    date: {
      type: String,
      required: true,
    },
    activity: {
      type: [Object, String],
      required: false,
    },
    chatData: {
      type: Object,
      required: true,
    },
    messageAtBottom: {
      type: Boolean,
      default: false,
    },
    withGroups: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      chatItems: [
        {
          id: 0,
          name: "Send to chats",
        },
      ],
      groupItems: [
        {
          id: 0,
          name: "Post in groups",
        },
      ],
      openChatTreeview: [0],
      openGroupTreeview: [],
      chatActive: [],
      groupActive: [],
    };
  },
  watch: {
    "chatData.sendToChats": function(value) {
      if (value.length > 0) {
        this.openChatTreeview = [0];
      }
      this.chatData.isSharing = this.isSharing;
    },
    "chatData.sendToGroups": function(value) {
      if (value.length > 0) {
        this.openGroupTreeview = [0];
      }
      this.chatData.isSharing = this.isSharing;
    },
  },
  computed: {
    hasChats() {
      return this.chatItems[0].children?.length > 0;
    },
    hasGroups() {
      return this.groups?.length > 0;
    },
    isSharing() {
      return this.chatData.sendToChats.length > 0  || this.chatData.sendToGroups.length > 0;
    },
  },
  methods: {
    // leveraging the component's activatability as a makeshift selection when clicking anywhere on the item
    updateChatSelected(activeIdArray) {
      let id = activeIdArray[0];
      this.chatData.sendToChats.includes(id)
        ? (this.chatData.sendToChats = this.chatData.sendToChats.filter((val) => val !== id))
        : this.chatData.sendToChats.push(id);

      this.chatActive = [];
    },
    updateGroupSelected(activeIdArray) {
      let id = activeIdArray[0];
      this.chatData.sendToGroups.includes(id)
        ? (this.chatData.sendToGroups = this.chatData.sendToGroups.filter((val) => val !== id))
        : this.chatData.sendToGroups.push(id);

      this.groupActive = [];
    },
  },
  apollo: {
    chatsForUser: {
      query: SIMPLE_CHATS_FOR_USER,
      update: (data) => extractNodes(data.chatsForUser.edges),
      result(response) {
        const chats = extractNodes(response?.data?.chatsForUser.edges);
        const chatsForUser = chats.filter((element) => element.type !== "SUPPORT" && element.type !== "COACH" && element.type !== 'ASSISTANT');
        if (!chatsForUser) {
          return null;
        }

        this.chatItems[0].children = chatsForUser.map((chat) => {
          return {
            id: decodeId(chat.id),
            name: chat.title,
            image: chat.image,
          };
        });

        return this.chatItems[0].children;
      },
    },
    groups: {
      query: GROUPS_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          type: "GYM",
          mine: true,
          withUsers: false,
        };
      },
      update(data) {
        let groups = data.groups.edges;
        // a user can only share to their home gym group. 
        groups = groups?.filter((group) => decodeId(group.node.id) == decodeId(this.$store.getters.user?.userProfile?.gymBranch?.group?.id));
        this.groupItems[0].children = groups?.map((group) => {
          return {
            id: group.node.id,
            name: group.node.title,
            image: group.node.banner,
          };
        });

        return groups;
      },
      skip() {
        return !this.withGroups;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.j-treeview.text-left.v-treeview--dense {
  margin-left: -22px;

  .v-treeview-node__root {
    min-height: 35px;
  }
}
</style>
