<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" md="5">
        <v-alert v-if="success" type="success">
          Password successfully set. Please wait while we automatically log you in.
        </v-alert>
        <j-card v-else class="pa-2 card-radius">
          <v-card-title class="py-1 my-1 mb-6">
            <span>Set your new password</span>
          </v-card-title>
          <v-card-text class="my-1 py-0">
            <j-text-field
              v-model="newPassword"
              :append-icon="showPassword1 ? 'visibility' : 'visibility_off'"
              :type="showPassword1 ? 'text' : 'password'"
              :disabled="success"
              label="New password"
              @click:append="showPassword1 = !showPassword1"
            >
            </j-text-field>
            <j-text-field
              v-model="newPasswordRepeat"
              :append-icon="showPassword2 ? 'visibility' : 'visibility_off'"
              :type="showPassword2 ? 'text' : 'password'"
              :disabled="success"
              label="New password repeated"
              @click:append="showPassword2 = !showPassword2"
            >
            </j-text-field>
          </v-card-text>
          <v-alert v-if="defaultError" type="info">
            An error has occurred and we are unable to reset your password.<br />
            <a href="https://bit.ly/jeff_help" target="_blank"
              >Please contact support if you are unable to reset your password.</a
            >
          </v-alert>
          <v-alert v-if="passwordMismatchError" type="warning">
            Your passwords dont match.
          </v-alert>
          <v-alert v-if="validationErrors" type="warning">
            <span v-for="(error, index) in validationErrors" :key="index">- {{ error }}<br /></span>
          </v-alert>
          <v-card-actions class="pt-0 mt-0">
            <v-spacer />
            <j-btn color="secondary" :loading="saving" :disabled="success" rounded @click="save()"
              >Set new password</j-btn
            >
          </v-card-actions>
        </j-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { RESET_PASSWORD_MUTATION } from "@/graphql/mutations/account";
import LoginMixin from "@/mixins/LoginMixin";

export default {
  name: "ResetPassword",
  mixins: [LoginMixin],
  data: () => ({
    username: null,
    password: null,
    newPassword: "",
    newPasswordRepeat: "",
    saving: false,
    errorMessage: "",
    success: false,
    defaultError: false,
    passwordMismatchError: false,
    showPassword1: false,
    showPassword2: false,
    validationErrors: null,
  }),
  methods: {
    save() {
      this.passwordMismatchError = false;
      this.validationErrors = null;
      if (this.newPassword !== this.newPasswordRepeat) {
        this.passwordMismatchError = true;
        return;
      }

      this.$apollo
        .mutate({
          mutation: RESET_PASSWORD_MUTATION,
          variables: {
            hash: this.$route.params.hash,
            userId: this.$route.params.userId,
            newPassword: this.newPassword,
          },
        })
        .then((response) => {
          if (response.data.resetPassword.success) {
            this.success = true;
            this.username = response.data.resetPassword.username;
            this.password = this.newPassword;
            setTimeout(() => {
              this.login();
            }, 3000);
          } else {
            this.validationErrors = response.data.resetPassword.validationErrors;
            this.defaultError = response.data.resetPassword.error;
          }
        })
        .catch(() => {
          this.defaultError = true;
        });
    },
  },
};
</script>
