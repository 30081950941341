import gql from "graphql-tag";

export const HABIT_OPTIONS_QUERY = gql`
  query habitOptionsQuery {
    habitOptionsQuery {
      id
      title
    }
  }
`;

export const CURRENT_HABIT_QUERY = gql`
  query habitsQuery($date: Date) {
    habitsQuery(first: 1, startDate_Lte: $date, currentEndDate: $date) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          title
          startDate
          habitOption {
            id
            title
          }
          completed
          missed
          habitentrySet(date: $date) {
            edges {
              node {
                id
                date
                status
              }
            }
          }
        }
      }
    }
  }
`;

export const MONTH_HABIT_ENTRIES_QUERY = gql`
  query habitEntriesQuery($startDate: Date, $endDate: Date) {
    habitEntriesQuery(date_Gte: $startDate, date_Lte: $endDate) {
      edges {
        node {
          id
          date
          status
        }
      }
    }
  }
`;

export const HABIT_QUERY = gql`
  query habitsQuery($id: String, $startDate: Date, $endDate: Date) {
    habitsQuery(habitId: $id) {
      edges {
        node {
          title
          startDate
          habitentrySet(date_Lte: $endDate, date_Gte: $startDate) {
            edges {
              node {
                date
                status
              }
            }
          }
        }
      }
    }
  }
`;

export const HABITS_QUERY = gql`
  query habitsQuery($date: Date) {
    habitsQuery(endDate_Lte: $date) {
      edges {
        node {
          id
          title
          completed
          missed
          habitOption {
            id
            title
          }
        }
      }
    }
  }
`;
