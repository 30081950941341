<template>
  <j-banner-container
    header="Badges"
    :backLink="`/p/${userId}`"
    md="6"
    :gradient="['red-background', 'yellow-background']"
    slot-text-align="left"
  >
    <v-row align="center" justify="center" class="py-0 my-0">
      <v-col cols="12" class="py-0 my-0">
        <div v-if="loadingSection.earned">
          <v-progress-circular indeterminate class="ma-7" color="secondary"></v-progress-circular>
        </div>
        <div v-else>
          <v-row v-if="earned.length > 0">
            <v-col v-for="badge in earned" :key="badge.node.id" cols="6" md="4">
              <j-card rounded="lg" class="mr-3 my-1 j-elevated-2 badge-card">
                <v-img
                  v-if="badge.node.badge.iconLink"
                  :src="badge.node.badge.iconLink"
                  :lazy-src="badge.node.badge.iconLink"
                  :aspect-ratio="10 / 9"
                />
                <j-gradient-text :gradient="['pink', 'yellow']" class="text-uppercase caption">
                  <v-icon class="grey--text" small>mdi-calendar</v-icon>
                  {{ earnedOn(badge.node.createdAt) }}
                </j-gradient-text>
                <v-card-text>
                  <div class="navy--text heading-5 font-weight-bold">
                    <v-icon small @click="showDescription = badge.node.id">
                      mdi-information-outline
                    </v-icon>

                    {{ badge.node.badge.title }}
                  </div>
                  <p v-if="showDescription == badge.node.id">{{ badge.node.badge.description }}</p>
                </v-card-text>
              </j-card>
            </v-col>
          </v-row>
          <j-alert v-if="earned.length == 0" class="mt-6">
            No badges received yet
          </j-alert>
        </div>
      </v-col>
    </v-row>
  </j-banner-container>
</template>

<script>
import { fancy_date_from_iso } from "@/lib/fancy_date";

import { AWARDED_BADGES_QUERY } from "@/graphql/queries/badges";

export default {
  name: "BadgeList",
  props: {
    userId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      earned: [],
      showDescription: null,
      loadingSection: {
        earned: true,
      },
    };
  },
  methods: {
    earnedOn(date) {
      return fancy_date_from_iso(date, "MMMM YYYY", false);
    },
  },
  apollo: {
    awardedBadges: {
      query: AWARDED_BADGES_QUERY,
      variables() {
        return {
          userId: this.userId,
        };
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        if (data.awardedBadges.edges) {
          this.earned = data.awardedBadges.edges.sort((a, b) => (a.node.createdAt < b.node.createdAt ? 1 : -1));

          this.loadingSection.earned = false;
        }
      },
    },
  },
};
</script>

<style scoped>
.badge-card {
  min-height: 320px;
}
</style>
