<template>
  <v-skeleton-loader v-if="$apollo.loading" type="card" />
  <div v-else-if="hasData">
    <highcharts v-if="weightChart()" :options="weightChart()" />
  </div>
  <div v-else>
    <span class="grey-info--text font-italic">More fitness data needed</span>
  </div>
</template>

<script>
import { commonChartOptions } from "@/lib/chart";

export default {
  name: "FitnessGraph",
  props: {
    userCheckIns: {
      type: Array,
      required: false
    }
  },
  computed: {
    pushUps() {
      return this.userCheckIns
        .filter((checkin) => checkin.pushUps != null && !isNaN(checkin.pushUps))
        .map((checkin) => {
          return [checkin.date, checkin.pushUps];
        });
    },
    sitUps() {
      return this.userCheckIns
        .filter((checkin) => checkin.sitUps != null && !isNaN(checkin.sitUps))
        .map((checkin) => {
          return [checkin.date, checkin.sitUps];
        });
    },
    burpeeChirpee() {
      return this.userCheckIns
        .filter((checkin) => checkin.burpeeChirpee != null && !isNaN(checkin.burpeeChirpee))
        .map((checkin) => {
          return [checkin.date, checkin.burpeeChirpee];
        });
    },
    elbowPlank() {
      return this.userCheckIns
        .filter((checkin) => checkin.elbowPlank != null && !isNaN(checkin.elbowPlank))
        .map((checkin) => {
          return [checkin.date, checkin.elbowPlank];
        });
    },
    squat() {
      return this.userCheckIns
        .filter((checkin) => checkin.squat != null && !isNaN(checkin.squat))
        .map((checkin) => {
          return [checkin.date, checkin.squat];
        });
    },
    hasData() {
      return (
        this.pushUps.length > 0 ||
        this.sitUps.length > 0 ||
        this.burpeeChirpee.length > 0 ||
        this.elbowPlank.length > 0 ||
        this.squat.length > 0
      );
    },
  },
  methods: {
    weightChart() {
      if (this.userCheckIns) {
        if (this.userCheckIns?.length == 0) {
          return null;
        }

        return {
          ...commonChartOptions,
          chart: {
            type: "container",
            height: 200,
            zoomType: "x",
          },
          yAxis: [
            {
              title: {
                text: "count",
              },
              softMin: 5,
              softMax: 10,
            },
            {
              opposite: true,
              title: { text: "seconds" },
              softMin: 0,
              softMax: 10,
            },
          ],
          xAxis: {
            type: "datetime",
            zoomEnabled: true,
          },
          tooltip: {
            shared: true,
          },
          plotOptions: {
            series: {
              relativeXValue: true,
            },
            spline: {
              marker: {
                enabled: true,
              },
            },
          },
          series: [
            {
              type: "spline",
              name: "Push-ups (2 mins)",
              showInLegend: true,
              data: this.pushUps,
            },
            {
              type: "spline",
              name: "Sit-ups (1 min)",
              showInLegend: true,
              data: this.sitUps,
            },
            {
              type: "spline",
              name: "Burpees/chirpees (1 min)",
              showInLegend: true,
              data: this.burpeeChirpee,
            },
            {
              type: "spline",
              name: "Elbow plank",
              showInLegend: true,
              data: this.elbowPlank,
              yAxis: 1,
              tooltip: {
                valueSuffix: " seconds",
              },
            },
            {
              type: "spline",
              name: "Squat hold/wall squat",
              showInLegend: true,
              data: this.squat,
              yAxis: 1,
              tooltip: {
                valueSuffix: " seconds",
              },
            },
          ],
        };
      }
    },
  },
};
</script>
