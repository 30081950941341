<template>
  <v-container>
    <j-main-banner-container header="Bookmarks" :gradient="['red-background', 'yellow-background']">
      <v-row align="center" justify="center" class="mt-2">
        <v-col cols="5">
          <v-chip to="/bookmarks/upcoming" outlined pill medium active-class="active-chip">Upcoming</v-chip>
        </v-col>
        <v-col cols="5">
          <v-chip to="/bookmarks/all" outlined pill medium active-class="active-chip">Everything</v-chip>
        </v-col>
      </v-row>
      <v-row v-if="!$apollo.loading" align="center" justify="center" class="my-4">
        <v-col cols="12" class="py-0 my-0 px-10">
          <v-switch inset class="py-0 my-0" :input-value="notificationsEnabled" @change="upsertBookmarkSetting($event)">
            <template v-slot:label>
              <span class="p-text">Get an email 30 minutes before upcoming sessions.</span>
            </template>
          </v-switch>
        </v-col>
      </v-row>
      <router-view></router-view>
    </j-main-banner-container>
  </v-container>
</template>

<script>
import JMainBannerContainer from "@/layouts/sections/banners/JMainBannerContainer";
import UserMixin from "@/mixins/UserMixin";

export default {
  name: "BookmarkBase",
  mixins: [UserMixin],
  components: {
    JMainBannerContainer,
  },
  data() {
    return {};
  },
  computed: {
    notificationsEnabled() {
      return this.settingEnabled("send-event-reminders");
    },
  },
  methods: {
    upsertBookmarkSetting(value) {
      this.upsertSetting("send-event-reminders", value);
    },
  },
};
</script>

<style scoped>
.active-chip {
  color: var(--v-light-purple-base);
  border: 2px solid var(--v-light-purple-base);
  font-weight: 600;
}
</style>
