export const REST_OPTIONS = {
  planned: [
    { filled: true, text: "Planned", value: 1 },
    { filled: true, text: "Unplanned", value: 0 },
  ],
  details: [
    { text: "Sick", value: "sick" },
    { text: "Injured", value: "injured" },
    { text: "Feeling drained", value: "feeling_drained" },
    { text: "Low motivation", value: "low_motivation" },
    { text: "Other", value: "other" },
  ]
};
