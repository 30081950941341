import gql from 'graphql-tag'

export const CREATE_MEDICAL_ASSESSMENT_MUTATION = gql`
mutation createMedicalAssessment($medicalAssessmentInput: MedicalAssessmentInput!, $userProfileInput: UserProfileInput!) {
  createMedicalAssessment(medicalAssessmentInput: $medicalAssessmentInput, userProfileInput: $userProfileInput) {
    medicalAssessment {
      id
      consent
      indemnity
    }
  }
}
`
