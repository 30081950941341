import {fancy_date, getNextSunday} from "@/lib/fancy_date";

export default {
  methods: {
    formatCategoryTitle(category) {
      return category.replace("X__", "").replace("A__", "");
    },
    itemTitle(item) {
      let str = "";
      if (item.ingredient) {
        str += " " + item.ingredient.plural;
      }
      if (item.isCustomItem) {
        str += " " + item.customItem;
      }
      return str;
    },
    itemQuantity(item) {
      return item.quantities;
    },
    generateShoppingListPdf() {
      this.$refs.refGeneratePdf.generatePdf();
    },
    formatDate(dateString) {
      return fancy_date(dateString, "ddd, D MMM", false);
    },
    formatEndDate(dateString) {
      return this.formatDate(getNextSunday(dateString));
    },
  }
}