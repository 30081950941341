<template>
  <v-container>
    <v-row>
      <v-col cols="12" align="center" justify="center">
        <h1>Layout Examples</h1>
      </v-col>
    </v-row>
    <v-sheet color="grey-background" elevation="2" rounded>
      <v-row>
        <v-col cols="12" align="center" justify="center">
          <h2>Layouts</h2>
        </v-col>
      </v-row>
      <div id="banner-section">
        <v-row>
          <v-col cols="12" align="center" justify="center">
            <h3>Banner: <span class="pink--text">j-banner-container</span></h3>
            <h3>Main banner: <span class="pink--text">j-main-banner-container</span></h3>
            <h3>Card banner: <span class="pink--text">j-card-banner-container</span></h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" lg="12" xl="12" align="center" justify="center">
            <p>Use inside a <strong>v-container</strong></p>
          </v-col>
        </v-row>
        <v-row class="j-main-banner-container">
          <v-col cols="12" sm="4" align="center" justify="center">
            <v-card color="grey" flat>
              <j-main-banner-container md="12" lg="12" xl="12" header="Main" color="alert-navy-background">
                <v-row justify="center">
                  <v-col cols="12">
                    <p class="pink--text"><strong>j-main-banner-container</strong></p>
                    <p><strong>header=</strong>"Main"</p>
                    <p><strong>color=</strong>"blue-background"</p>
                  </v-col>
                </v-row>
              </j-main-banner-container>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4" align="center" justify="center">
            <v-card color="grey" flat>
              <j-main-banner-container
                search
                md="12"
                lg="12"
                xl="12"
                header="Search"
                :gradient="['yellow-background', 'green-background']"
              >
                <v-row justify="center">
                  <v-col cols="12">
                    <p class="pink--text"><strong>j-main-banner-container</strong></p>
                    <p><strong>search</strong></p>
                    <p><strong>header=</strong>"Search"</p>
                    <p><strong>:gradient=</strong>"['yellow-background', 'green-background']"</p>
                    <p>use <strong>@search</strong> to get the search event's value.</p>
                  </v-col>
                </v-row>
              </j-main-banner-container>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4" align="center" justify="center">
            <v-card color="grey" flat>
              <j-main-banner-container
                sm="6"
                md="8"
                lg="8"
                xl="8"
                header="Main"
                :gradient="['exercise', 'yellow', 'yellow-background']"
              >
                <v-row justify="center">
                  <v-col cols="12">
                    <p class="pink--text"><strong>j-main-banner-container</strong></p>
                    <p><strong>:gradient=</strong>"['exercise', 'yellow', 'yellow-background']"</p>
                    <p><strong>sm=</strong>"6"</p>
                    <p><strong>md=</strong>"8"</p>
                    <p><strong>lg=</strong>"8"</p>
                    <p><strong>xl=</strong>"8"</p>
                  </v-col>
                </v-row>
              </j-main-banner-container>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="j-banner-container pt-3">
          <v-col cols="12" sm="4" align="center" justify="center">
            <v-card color="grey" flat>
              <j-banner-container
                backLink="/test"
                header="Program"
                md="12"
                lg="12"
                xl="12"
                title="Foundations of nutrition"
                subtitle="with Adrian"
                :icon="require('@/assets/images/JeffLogo.png')"
                color="green-background"
                :textGradient="['sleep', 'nutrition']"
              >
                <v-row justify="center">
                  <v-col cols="12">
                    <p class="pink--text"><strong>j-banner-container</strong></p>
                    <p><strong>backLink=</strong>"/test"</p>
                    <p><strong>header=</strong>"Program"</p>
                    <p><strong>title=</strong>"Foundations of nutrition"</p>
                    <p><strong>subtitle=</strong>"with Adrian"</p>
                    <p><strong>:icon=</strong>"require('@/assets/images/JeffLogo.png')"</p>
                    <p><strong>color=</strong>"green-background"</p>
                    <p><strong>:textGradient=</strong>"['sleep', 'nutrition']"</p>
                  </v-col>
                </v-row>
              </j-banner-container>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4" align="center" justify="center">
            <v-card color="grey" flat>
              <j-banner-container
                backLink="/test"
                header="Header"
                md="12"
                lg="12"
                xl="12"
                title="A title"
                subtitle="colourful text"
                :gradient="['white', 'grey']"
                :textGradient="['green', 'pink']"
              >
                <v-row justify="center">
                  <v-col cols="12">
                    <p class="pink--text"><strong>j-banner-container</strong></p>
                    <p><strong>header=</strong>"Header"</p>
                    <p><strong>title=</strong>"A title"</p>
                    <p><strong>subtitle=</strong>"colourful text"</p>
                    <p><strong>:gradient=</strong>"['white', 'grey']"</p>
                    <p><strong>:textGradient=</strong>"['green', 'pink']"</p>
                  </v-col>
                </v-row>
              </j-banner-container>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4" align="center" justify="center">
            <v-card color="grey" flat>
              <j-banner-container
                backLink="/test"
                md="12"
                lg="12"
                xl="12"
                header="Simple header"
                :gradient="['yellow', 'pink']"
                :textGradient="['navy', 'navy']"
              >
                <v-row justify="center">
                  <v-col cols="12">
                    <p class="pink--text"><strong>j-banner-container</strong></p>
                    <p><strong>header=</strong>"Simple header"</p>
                  </v-col>
                </v-row>
              </j-banner-container>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="j-card-banner-container pt-3">
          <v-col cols="12" sm="4" align="center" justify="center">
            <v-card color="grey" flat>
              <j-card-banner-container
                backLink="/test"
                md="12"
                lg="12"
                xl="12"
                header="Program"
                title="Foundations of nutrition"
                subtitle="with Adrian"
                :image="require('@/assets/images/JeffLogo.png')"
                :icon="require('@/assets/images/JeffLogo.png')"
                color="nutrition"
                :textGradient="['mindset', 'nutrition']"
              >
                <v-row justify="center">
                  <v-col cols="12">
                    <p class="pink--text"><strong>j-card-banner-container</strong></p>
                    <p><strong>backLink=</strong>"/test"</p>
                    <p><strong>header=</strong>"Program"</p>
                    <p><strong>title=</strong>"Foundations of nutrition"</p>
                    <p><strong>subtitle=</strong>"with Adrian"</p>
                    <p><strong>:image=</strong>"require('@/assets/images/JeffLogo.png')"</p>
                    <p><strong>:icon=</strong>"require('@/assets/images/JeffLogo.png')"</p>
                    <p><strong>color=</strong>"nutrition"</p>
                    <p><strong>:textGradient=</strong>"['sleep', 'nutrition']"</p>
                  </v-col>
                </v-row>
              </j-card-banner-container>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4" align="center" justify="center">
            <v-card color="grey" flat>
              <j-card-banner-container
                backLink="/test"
                md="12"
                lg="12"
                xl="12"
                header=""
                title="Foundations of nutrition"
                subtitle="with Adrian"
                :image="require('@/assets/images/JeffLogo.png')"
                :gradient="['green-background', 'yellow-background']"
                :textGradient="['sleep', 'nutrition']"
              >
                <v-row justify="center">
                  <v-col cols="12">
                    <p class="pink--text"><strong>j-card-banner-container</strong></p>
                    <p><strong>header=</strong>""</p>
                    <p><strong>title=</strong>"Foundations of nutrition"</p>
                    <p><strong>subtitle=</strong>"with Adrian"</p>
                    <p><strong>:image=</strong>"require('@/assets/images/JeffLogo.png')"</p>
                    <p><strong>:gradient==</strong>"['green-background', 'yellow-background']"</p>
                    <p><strong>:textGradient=</strong>"['sleep', 'nutrition']"</p>
                  </v-col>
                </v-row>
              </j-card-banner-container>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4" align="center" justify="center">
            <v-card color="grey" flat>
              <j-card-banner-container
                backLink="/test"
                md="12"
                lg="12"
                xl="12"
                header="Program"
                title="Foundations of nutrition"
                subtitle="with Adrian"
                :gradient="['green-background', 'yellow-background']"
                :textGradient="['mindset', 'nutrition']"
              >
                <v-row justify="center">
                  <v-col cols="12">
                    <p class="pink--text"><strong>j-card-banner-container</strong></p>
                    <p><strong>header=</strong>"Program"</p>
                    <p><strong>title=</strong>"Foundations of nutrition"</p>
                    <p><strong>subtitle=</strong>"with Adrian"</p>
                    <p><strong>:gradient=</strong>"['green-background', 'yellow-background']"</p>
                    <p><strong>:textGradient=</strong>"['sleep', 'nutrition']"</p>
                  </v-col>
                </v-row>
              </j-card-banner-container>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" align="center" justify="center">
            <h3>
              Card Banner Contents:
              <span class="pink--text">j-card-banner-container, j-card-content, j-avatar-list</span>
            </h3>
          </v-col>
        </v-row>
        <v-row class="j-card-content pt-3">
          <v-col cols="12" sm="4" align="center" justify="center">
            <v-card color="grey" flat>
              <j-card-banner-container
                backLink="/test"
                md="12"
                lg="12"
                xl="12"
                header="Program"
                title="Example"
                subtitle="Content example"
                :image="require('@/assets/images/JeffLogo.png')"
                color="mindset"
                :textGradient="['mindset', 'exercise']"
              >
                <template v-slot:card-content>
                  <v-card-title class="align-center">&lt;template v-slot:card-content&gt;</v-card-title>
                  <v-card-subtitle>v-card content like v-card-title</v-card-subtitle>
                </template>

                <v-row justify="center">
                  <v-col cols="12">
                    <p class="pink--text"><strong>j-card-banner-container</strong></p>
                    <p>default slot content</p>
                  </v-col>
                </v-row>
              </j-card-banner-container>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4" align="center" justify="center">
            <v-card color="grey" flat>
              <j-card-banner-container
                backLink="/test"
                md="12"
                lg="12"
                xl="12"
                header="Program"
                title="example"
                subtitle="Content example"
                :image="require('@/assets/images/JeffLogo.png')"
                :gradient="['pink-background', 'mindset']"
                :textGradient="['mindset', 'exercise']"
              >
                <v-row justify="center">
                  <v-col cols="12">
                    <p class="pink--text"><strong>j-card-banner-container</strong></p>
                    <p>default slot content</p>
                  </v-col>
                </v-row>

                <template v-slot:card-content>
                  <j-card-content
                    instructor="Sally"
                    :icon="require('@/assets/images/JeffLogo.png')"
                    :info="[
                      { icon: 'mdi-magnify', text: 'search' },
                      { icon: 'mdi-facebook', text: 'anything' },
                      { icon: 'mdi-alien-outline', text: 'as many/few as you want' },
                    ]"
                    :summaryInfo="[
                      { title: 'title', value: 'value' },
                      { title: 'another', value: '1,024' },
                    ]"
                    leftButton="left"
                    @leftButtonClicked="$emit('doSomething')"
                  />

                  <v-card-title class="text-center">&lt;template v-slot:card-content&gt;</v-card-title>
                  <v-card-subtitle class="pink--text text-center"><strong>j-card-content</strong></v-card-subtitle>
                  <v-card-text class="text-center">
                    <p><strong>instructor=</strong>Sally</p>
                    <p><strong>:icon=</strong>"require('@/assets/images/JeffLogo.png')"</p>
                    <p>
                      <strong>:info=</strong>"[{ icon: 'mdi-alien-outline', text: 'as many/few as you want' }, ...]">
                    </p>
                    <p><strong>:summaryInfo=</strong>"[{ title: 'Program Type', value: 'Exercise' }, ...]"</p>
                    <p><strong>leftButton=</strong>"left"</p>
                    <p><strong>@leftButtonClicked=</strong>onClick</p>
                  </v-card-text>
                </template>
              </j-card-banner-container>
            </v-card>
          </v-col>
          <v-col cols="12" sm="4" align="center" justify="center">
            <v-card color="grey" flat>
              <j-card-banner-container
                backLink="/test"
                md="12"
                lg="12"
                xl="12"
                header="Card"
                title="Another"
                subtitle="Content example"
                :image="require('@/assets/images/JeffLogo.png')"
                :gradient="['yellow-background', 'pink-background', 'mindset']"
                :textGradient="['exercise', 'mindset']"
              >
                <v-row justify="center">
                  <v-col cols="12">
                    <p class="pink--text"><strong>j-card-banner-container</strong></p>
                    <p>default slot content</p>
                  </v-col>
                </v-row>

                <template v-slot:card-content>
                  <j-card-content
                    :info="[{ icon: 'mdi-numeric-1-box-outline', text: 'just one this time' }]"
                    :summaryInfo="[
                      { title: 'icon', value: 'mdi-magnify', icon: true },
                      { title: 'text', value: '1,024' },
                    ]"
                    summaryAvatarList
                    accentColor="mindset"
                    leftButton="left"
                    leftButtonIcon="mdi-account"
                    rightButton="right"
                    rightButtonIcon="mdi-account-outline"
                    @leftButtonClicked="$emit('doSomething')"
                    @rightButtonClicked="$emit('doSomething')"
                    primaryButton="primary"
                    @primaryButtonClicked="$emit('doSomething')"
                  >
                    <template v-slot:summaryAvatarList>
                      <j-avatar-list :user-list="userList" accentColor="mindset" />
                    </template>
                  </j-card-content>

                  <v-card-title class="text-center">&lt;template v-slot:card-content&gt;</v-card-title>
                  <v-card-subtitle class="pink--text text-center"><strong>j-card-content</strong></v-card-subtitle>
                  <v-card-text class="text-center">
                    <p><strong>:info=</strong>"[{ icon: 'mdi-numeric-1-box-outline', text: 'just one this time' }]"></p>
                    <p>
                      <strong>:summaryInfo=</strong>"[ { title: 'icon', value: 'mdi-magnify', icon: true }, { title:
                      'text', value: '1,024' }, ]"
                    </p>
                    <p><strong>accentColor=</strong>"mindset"</p>
                    <p>
                      <strong>&lt;template v-slot:summaryAvatarList&gt;</strong> using
                      <span class="pink--text"><strong>j-avatar-list</strong></span> and
                      <strong>summaryAvatarList</strong> prop
                    </p>
                    <p><strong>leftButton=</strong>"left"</p>
                    <p><strong>leftButtonIcon=</strong>"mdi-account"</p>
                    <p><strong>rightButton=</strong>"right"</p>
                    <p><strong>rightButtonIcon=</strong>"mdi-account-outline"</p>
                    <p><strong>@leftButtonClicked=</strong>onClick</p>
                    <p><strong>@rightButtonClicked=</strong>onClick</p>
                    <p><strong>primaryButton=</strong>"primary"</p>
                    <p><strong>@primaryButtonClicked=</strong>onClick</p>
                  </v-card-text>
                </template>
              </j-card-banner-container>
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div id="subsection-row-section" class="mt-10">
        <v-row>
          <v-col cols="12" align="center" justify="center">
            <h3>Subsection Row: <span class="one-on-one--text">j-subsection-row</span></h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" align="center" justify="center">
            <h4>title =</h4>
            <p>String</p>
            <h4>subtitle =</h4>
            <p>String</p>
            <h4>seeAll =</h4>
            <p>text = String; link = String</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="3" sm="4" align="center" justify="center">
            <j-card color="grey" flat class="pa-3">
              <j-subsection-row title="Example row" :see-all="{ text: 'More', link: '/layout-examples' }">
                <div class="d-flex flex-row">
                  <j-card class="white py-5 mb-2 mr-2 flex-fill">
                    A card
                  </j-card>
                  <j-card class="white py-5 mb-2 flex-fill">
                    Another card
                  </j-card>
                </div>
              </j-subsection-row>
            </j-card>
          </v-col>
          <v-col cols="12" md="3" sm="4" align="center" justify="center">
            <j-card color="grey" flat class="pa-3">
              <j-subsection-row
                title="With subtitle"
                subtitle="Subtitle"
                :see-all="{ text: 'Any text', link: '/layout-examples' }"
              >
                <j-card class="white pa-2 mb-2">
                  A card
                </j-card>
                <j-card class="white pa-2 mb-2">
                  Another card
                </j-card>
              </j-subsection-row>
            </j-card>
          </v-col>
          <v-col cols="12" md="3" sm="4" align="center" justify="center">
            <j-card color="grey" flat class="pa-3">
              <j-subsection-row title="Compact" compact :see-all="{ text: 'More', link: '/layout-examples' }">
                <j-card flat rounded="0" class="white pa-7 mb-2">
                  A large card
                </j-card>
              </j-subsection-row>
            </j-card>
          </v-col>
          <v-col cols="12" md="3" sm="4" align="center" justify="center">
            <j-card color="grey" flat class="pa-3">
              <j-subsection-row
                title="Compact with subtitle"
                compact
                subtitle="Subtitle"
                :see-all="{ text: 'See all', link: '/layout-examples' }"
              >
                <j-card class="white pa-2 mb-2">
                  A card
                </j-card>
                <j-card class="white pa-2 mb-2">
                  Another card
                </j-card>
              </j-subsection-row>
            </j-card>
          </v-col>
        </v-row>
      </div>
    </v-sheet>
    <v-sheet color="grey-background" elevation="2" rounded>
      <v-row>
        <v-col cols="12" align="center" justify="center">
          <h2>Components</h2>
        </v-col>
      </v-row>
      <div id="card-section">
        <v-row>
          <v-col cols="12" align="center" justify="center">
            <h3>Card: <span class="one-on-one--text">j-card</span></h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" align="center" justify="center">
            <h4>rounded =</h4>
            <p>["sm", "md", "lg", "xl", "pill", "circle"]</p>
            <h4>+ any v-card props</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" align="center" justify="center">
            <j-card rounded="sm">
              <v-card-title>
                square
              </v-card-title>
              <v-card-subtitle>
                <p>rounded="sm | md"</p>
              </v-card-subtitle>
            </j-card>
          </v-col>
          <v-col cols="12" sm="3" align="center" justify="center">
            <j-card rounded="lg">
              <v-card-title>
                lightly rounded
              </v-card-title>
              <v-card-subtitle>
                rounded="lg"
              </v-card-subtitle>
            </j-card>
          </v-col>
          <v-col cols="12" sm="3" align="center" justify="center">
            <j-card>
              <v-card-title>
                default
              </v-card-title>
              <v-card-subtitle>
                No rounded property, or rounded="xl"
              </v-card-subtitle>
            </j-card>
          </v-col>
          <v-col cols="12" sm="3" align="center" justify="center">
            <j-card rounded="pill">
              <v-card-title>
                very round
              </v-card-title>
              <v-card-subtitle>
                rounded="pill"
              </v-card-subtitle>
            </j-card>
          </v-col>
          <v-col cols="12" sm="3" align="center" justify="center">
            <j-card rounded="sm" flat>
              <v-card-title>
                flat
              </v-card-title>
              <v-card-subtitle>
                <p>rounded="sm | md"</p>
                <p>flat</p>
              </v-card-subtitle>
            </j-card>
          </v-col>
          <v-col cols="12" sm="3" align="center" justify="center">
            <j-card>
              <v-card-title>
                with button
              </v-card-title>
              <v-card-actions>
                <v-spacer />
                <j-btn wide small to="/test">
                  Test button
                </j-btn>
                <v-spacer />
              </v-card-actions>
            </j-card>
          </v-col>
          <v-col cols="12" sm="3" align="center" justify="center">
            <j-card class="j-elevated-2">
              <v-card-title>
                with j-elevated
              </v-card-title>
              <v-card-subtitle>
                <p>class="j=elevated-2"</p>
              </v-card-subtitle>
              <v-card-actions class="mx-2">
                <j-btn narrow to="/test">
                  Save
                </j-btn>
                <v-spacer />
                <j-btn narrow text to="/test">
                  <span class="black--text">Cancel</span>
                </j-btn>
              </v-card-actions>
            </j-card>
          </v-col>
          <v-col cols="12" sm="3" align="center" justify="center">
            <j-card color="sleep">
              <v-card-title>
                With color
              </v-card-title>
              <v-card-subtitle>
                color="sleep"
              </v-card-subtitle>
            </j-card>
          </v-col>
          <v-col cols="12" sm="3" align="center" justify="center">
            <j-card :gradient="['white', 'exercise']" rounded="pill">
              <v-card-title>
                With gradient color
              </v-card-title>
              <v-card-subtitle>
                :gradient="['sleep', 'pink']"
              </v-card-subtitle>
            </j-card>
          </v-col>
        </v-row>
      </div>
      <div id="block-card-section" class="mt-10">
        <v-row>
          <v-col cols="12" align="center" justify="center">
            <h3>Block Card: <span class="one-on-one--text">j-block-card</span></h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" align="center" justify="center">
            <h4>title =</h4>
            <p>String</p>
            <h4>shortened-title</h4>
            <h4>sub-title =</h4>
            <p>String</p>
            <h4>member-count =</h4>
            <p>Number</p>
            <h4>badge-count =</h4>
            <p>Number</p>
            <h4>show-new-chip</h4>
            <h4>show-icons</h4>
            <h4>editable</h4><p> used with show-icons </p>
            <h4>unfilled-exercise-dot</h4>
            <h4>filled-exercise-dot</h4>
            <h4>dot-color =</h4>
            <p>String</p>
            <p>Defaults to 'exercise'</p>
          </v-col>
        </v-row>
        <v-row class="white">
          <v-col cols="12" sm="3" lg="2" align="center" justify="center">
            <j-block-card
              id="1"
              title="Block card example"
              sub-title="sub title"
              :image="require('@/assets/images/JeffLogo.png')"
            />
          </v-col>
          <v-col cols="12" sm="3" lg="2" align="center" justify="center">
            <j-block-card
              id="1"
              title="Block card with member count"
              :member-count="3"
              :image="require('@/assets/images/JeffLogo.png')"
            />
          </v-col>
          <v-col cols="12" sm="3" lg="2" align="center" justify="center">
            <j-block-card
              id="1"
              title="Block card"
              sub-title="with badge count"
              :badge-count="5"
              :image="require('@/assets/images/JeffLogo.png')"
            />
          </v-col>
          <v-col cols="12" sm="3" lg="2" align="center" justify="center">
            <j-block-card
              id="1"
              title="Block card"
              sub-title="with new chip"
              show-new-chip
              :image="require('@/assets/images/JeffLogo.png')"
            />
          </v-col>
          <v-col cols="12" sm="3" lg="2" align="center" justify="center">
            <j-block-card
              id="1"
              title="Block card"
              sub-title="with icons"
              show-icons
              :image="require('@/assets/images/JeffLogo.png')"
            />
          </v-col>
          <v-col cols="12" sm="3" lg="2" align="center" justify="center">
            <j-block-card
              id="1"
              title="Block card"
              sub-title="with icons and editable"
              show-icons
              editable
              :image="require('@/assets/images/JeffLogo.png')"
            />
          </v-col>
          <v-col cols="12" sm="3" lg="2" align="center" justify="center">
            <j-block-card
              id="1"
              title="Block card"
              sub-title="with empty pink dot"
              unfilled-exercise-dot
              :image="require('@/assets/images/JeffLogo.png')"
            />
          </v-col>
          <v-col cols="12" sm="3" lg="2" align="center" justify="center">
            <j-block-card
              id="1"
              title="Block card"
              sub-title="with filled pink dot"
              filled-exercise-dot
              :image="require('@/assets/images/JeffLogo.png')"
            />
          </v-col>
          <v-col cols="12" sm="3" lg="2" align="center" justify="center">
            <j-block-card
              id="1"
              title="Block card"
              sub-title="with new chip and dot"
              show-new-chip
              filled-exercise-dot
              dot-color="sleep"
              :image="require('@/assets/images/JeffLogo.png')"
            />
          </v-col>
          <v-col cols="12" sm="3" lg="2" align="center" justify="center">
            <j-block-card
              id="1"
              title="Block card"
              sub-title="with new chip and empty dot"
              show-new-chip
              unfilled-exercise-dot
              dot-color="green"
              :image="require('@/assets/images/JeffLogo.png')"
            />
          </v-col>
          <v-col cols="12" sm="3" lg="2" align="center" justify="center">
            <j-block-card
              id="1"
              title="Block card with long name just to show"
              sub-title=""
              :image="require('@/assets/images/JeffLogo.png')"
            />
          </v-col>
          <v-col cols="12" sm="3" lg="2" align="center" justify="center">
            <j-block-card
              id="1"
              title="Block card with shortened title"
              sub-title=""
              shortened-title
              :image="require('@/assets/images/JeffLogo.png')"
            />
          </v-col>
        </v-row>
        <!-- more here -->
      </div>
      <div id="button-section" class="mt-10">
        <v-row>
          <v-col cols="12" align="center" justify="center">
            <h3>Button: <span class="one-on-one--text">j-btn</span></h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" align="center" justify="center">
            <h4>primary, secondary, tertiary, wide, narrow, color='color', gradient=['any number of colors'], flatten</h4>
            <h4>+ any v-btn props</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4" lg="3" align="center" justify="center">
            <span class="d-block">j-btn primary</span>
            <j-btn primary to="/">
              Primary button
            </j-btn>
          </v-col>
          <v-col cols="12" md="4" lg="3" align="center" justify="center">
            <span class="d-block">j-btn secondary</span>
            <j-btn secondary to="/">
              Secondary button
            </j-btn>
          </v-col>
          <v-col cols="12" md="4" lg="3" align="center" justify="center">
            <span class="d-block">j-btn tertiary</span>
            <j-btn tertiary to="/">
              Tertiary button
            </j-btn>
          </v-col>
          <v-col cols="12" md="4" lg="3" align="center" justify="center">
            j-btn primary wide
            <j-btn primary wide to="/">
              Wide button
            </j-btn>
          </v-col>
          <v-col cols="12" md="4" lg="3" align="center" justify="center">
            j-btn wide color="green" large
            <j-btn wide color="green" large to="/">
              Green
            </j-btn>
          </v-col>
          <v-col cols="12" md="4" lg="3" align="center" justify="center">
            j-btn color="nutrition"
            <j-btn color="nutrition" to="/">
              Nutrition
            </j-btn>
          </v-col>
          <v-col cols="12" md="4" lg="3" align="center" justify="center">
            <span class="d-block">j-btn color="hydration" narrow</span>
            <j-btn color="hydration" narrow to="/">
              Narrow
            </j-btn>
          </v-col>
          <v-col cols="12" md="4" lg="3" align="center" justify="center">
            <span class="d-block">j-btn color="yellow" small</span>
            <j-btn color="yellow" small to="/">
              Yellow
            </j-btn>
          </v-col>
          <v-col cols="12" md="4" lg="3" align="center" justify="center">
            j-btn color="mindset"
            <j-btn color="mindset" to="/">
              Mindset
            </j-btn>
          </v-col>
          <v-col cols="12" md="4" lg="3" align="center" justify="center">
            <span class="d-block">j-btn wide :gradient="['nutrition', 'sleep']</span>
            <j-btn wide :gradient="['nutrition', 'sleep']" to="/">
              Green to blue
            </j-btn>
          </v-col>
          <v-col cols="12" md="4" lg="3" align="center" justify="center">
            <span class="d-block">j-btn wide :gradient="['nutrition', 'sleep', 'mindset', 'secondary']"</span>
            <j-btn wide :gradient="['nutrition', 'sleep', 'mindset', 'secondary']" to="/">
              Many gradients
            </j-btn>
          </v-col>
          <v-col cols="12" md="4" lg="3" align="center" justify="center">
            <span class="d-block">j-btn :gradient="['one-on-one', 'yellow']"</span>
            <j-btn :gradient="['one-on-one', 'yellow']" to="/">
              Pink to yellow
            </j-btn>
          </v-col>
          <v-col cols="12" md="4" lg="3" align="center" justify="center">
            <span class="d-block">j-btn <strong>&amp;&amp;</strong> &lt;strong&gt;</span>
            <j-btn to="/">
              <strong>Bold</strong>
            </j-btn>
          </v-col>
          <v-col cols="12" md="4" lg="3" align="center" justify="center">
            <span class="d-block">j-btn tertiary narrow <strong>&amp;&amp;</strong> &lt;v-icon&gt;</span>
            <j-btn tertiary narrow to="/">
              <span>Icon button</span>
              <v-icon class="ml-2">mdi-content-save</v-icon>
            </j-btn>
          </v-col>
          <v-col cols="12" md="4" lg="3" align="center" justify="center">
            <span class="d-block">j-btn <strong>&amp;&amp;</strong> &lt;v-icon&gt;</span>
            <j-btn to="/">
              <span>Icon button</span>
              <v-icon class="ml-2">mdi-close</v-icon>
            </j-btn>
          </v-col>
          <v-col cols="12" md="4" lg="3" align="center" justify="center">
            <span class="d-block">j-btn color="sleep"</span>
            <j-btn color="sleep">
              <span>Sleep</span>
            </j-btn>
          </v-col>
          <v-col cols="12" md="4" lg="3" align="center" justify="center">
            <span class="d-block">j-btn flatten color="navy"</span>
            <j-btn flatten color="navy">
              <span>Navy flattened button</span>
            </j-btn>
          </v-col>
        </v-row>
      </div>
      <div id="dotted-button-section" class="mt-10">
        <v-row>
          <v-col cols="12" align="center" justify="center">
            <h3>Dotted button: <span class="one-on-one--text">j-dotted-btn</span></h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" align="center" justify="center">
            <h4>All the same j-btn values</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4" lg="3" align="center" justify="center">
            <span class="d-block">j-dotted-btn primary</span>
            <j-dotted-btn primary to="/">
              Primary button
            </j-dotted-btn>
          </v-col>
          <v-col cols="12" md="4" lg="3" align="center" justify="center">
            <span class="d-block">j-dotted-btn secondary wide</span>
            <j-dotted-btn secondary wide>
              Secondary wide button
            </j-dotted-btn>
          </v-col>
          <v-col cols="12" md="4" lg="3" align="center" justify="center">
            <span class="d-block">j-dotted-btn color="green" large</span>
            <j-dotted-btn color="green" large>
              Green large
            </j-dotted-btn>
          </v-col>
        </v-row>
      </div>
      <div id="inputs-section" class="mt-10">
        <v-row>
          <v-col cols="12" align="center" justify="center">
            <h3>Inputs:</h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4" lg="3" align="center" justify="center">
            <strong class="d-block pink--text">j-checkbox</strong>
            <j-checkbox label="hello" />
          </v-col>
          <v-col cols="12" md="4" lg="3" align="center" justify="center">
            <strong class="d-block pink--text">j-treeview</strong>
            <j-treeview selectable dense :items="[{id: 1, name: 'parent', children: [{id: 2, name: 'child'}]}]"/>
          </v-col>
          <v-col cols="12" md="4" lg="3" align="center" justify="center">
            <strong class="d-block pink--text">j-radio</strong>
            <j-radio-group>
              <j-radio label="Yes" :value="false" />
              <j-radio label="No" :value="true" />
            </j-radio-group>
          </v-col>
          <v-col cols="12" md="4" lg="3" align="center" justify="center">
            <strong class="d-block pink--text">j-text-field</strong>
            <j-text-field label="Text field" placeholder="Placeholder"></j-text-field>
          </v-col>
        </v-row>
      </div>
      <div id="gradient-text-section" class="mt-10">
        <v-row>
          <v-col cols="12" align="center" justify="center">
            <h3>Gradient text: <span class="one-on-one--text">j-gradient-text</span></h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" align="center" justify="center">
            Use inside type of text you want eg. p, h1 or default span
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" align="center" justify="center">
            <p>
              <j-gradient-text :gradient="['pink', 'yellow']">
                <strong>Example &lt;p&gt; with &lt;strong&gt;</strong>
              </j-gradient-text>
            </p>
          </v-col>
          <v-col cols="12" sm="3" align="center" justify="center">
            <h1><j-gradient-text :gradient="['sleep', 'nutrition']">Example &lt;h1&gt;</j-gradient-text></h1>
          </v-col>
          <v-col cols="12" sm="3" align="center" justify="center">
            <j-gradient-text :gradient="['pink', 'navy']"><i>Example with &lt;i&gt;</i></j-gradient-text>
          </v-col>
          <v-col cols="12" sm="3" align="center" justify="center">
            <p>
              <j-gradient-text :gradient="['nutrition', 'exercise', 'mindset']">
                A longer example with a sentence.
              </j-gradient-text>
            </p>
          </v-col>
        </v-row>
      </div>
      <div id="alert-section" class="mt-10">
        <v-row>
          <v-col cols="12" align="center" justify="center">
            <h3>Alert: <span class="one-on-one--text">j-alert</span></h3>
          </v-col>
          <v-col cols="12" align="center" justify="center">
            <h4 class="font-weight-regular">
              It is better to use the type rather then the color, but both are available.
            </h4>
            <h5>Available: [info, error, warning, success, grey-info]</h5>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <j-alert>
              [DEFAULT] type="info"
            </j-alert>
          </v-col>
          <v-col cols="4">
            <j-alert type="error">
              type="error"
            </j-alert>
          </v-col>
          <v-col cols="4">
            <j-alert type="warning">
              type="warning"
            </j-alert>
          </v-col>
          <v-col cols="4">
            <j-alert type="success">
              type="success"
            </j-alert>
          </v-col>
          <v-col cols="4">
            <j-alert icon="mdi-help-circle-outline" color="mid-grey">
              icon="mdi-help-circle-outline" color="mid-grey"
            </j-alert>
          </v-col>
          <v-col cols="4">
            <j-alert color="navy">
              color="navy"
            </j-alert>
          </v-col>
          <v-col cols="4">
            <j-alert color="green">
              color="green"
            </j-alert>
          </v-col>
          <v-col cols="4">
            <j-alert icon="mdi-magnify">
              icon="mdi-magnify"
            </j-alert>
          </v-col>
          <v-col cols="4">
            <j-alert color="yellow" :icon="false">
              color="yellow" :icon="false"
            </j-alert>
          </v-col>
        </v-row>
      </div>
      <div id="emoji-section" class="mt-10">
        <v-row>
          <v-col cols="12" align="center" justify="center">
            <h3>Emoji-textarea: <span class="one-on-one--text">j-emoji-textarea</span></h3>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" align="center" justify="center">
            <h4>icon</h4>
            <h4>+ any v-textarea props</h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4">
            <j-emoji-textarea v-model="textExample" rows="2" label="Plain with 2 rows" />
          </v-col>
          <v-col cols="4">
            <j-emoji-textarea v-model="textExample" clearable rows="3" label="clearable" />
          </v-col>
          <v-col cols="4">
            <j-emoji-textarea v-model="textExample" color="green" rows="1" label="color=green" />
          </v-col>
          <v-col cols="4">
            <j-emoji-textarea v-model="textExample" icon="mdi-notebook" label="icon='mdi-notebook'" />
          </v-col>
          <v-col cols="4">
            <j-emoji-textarea v-model="textExample" required label="required" />
          </v-col>
        </v-row>
      </div>
    </v-sheet>
    <v-sheet color="grey-background" elevation="2" rounded>
      <v-row>
        <v-col cols="12" align="center" justify="center">
          <h2>Classes</h2>
        </v-col>
      </v-row>
      <div id="elevation-section">
        <v-row>
          <v-col cols="12" align="center" justify="center">
            <h3 class="one-on-one--text">j-elevated</h3>
            <span>[-1 to -4]</span>
            <p class="font-weight-light">elevation is still available to use when appropriate</p>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="3" align="center" justify="center">
            <j-card class="j-elevated-1">
              <v-card-title>
                1
              </v-card-title>
              <v-card-subtitle>
                j-elevated-1
              </v-card-subtitle>
            </j-card>
          </v-col>
          <v-col cols="12" sm="3" align="center" justify="center">
            <j-card class="j-elevated-2">
              <v-card-title>
                2
              </v-card-title>
              <v-card-subtitle>
                j-elevated-2
              </v-card-subtitle>
            </j-card>
          </v-col>
          <v-col cols="12" sm="3" align="center" justify="center">
            <j-card class="j-elevated-3">
              <v-card-title>
                3
              </v-card-title>
              <v-card-subtitle>
                j-elevated-3
              </v-card-subtitle>
            </j-card>
          </v-col>
          <v-col cols="12" sm="3" align="center" justify="center">
            <j-card class="j-elevated-4">
              <v-card-title>
                4
              </v-card-title>
              <v-card-subtitle>
                j-elevated-4
              </v-card-subtitle>
            </j-card>
          </v-col>
        </v-row>
      </div>
    </v-sheet>
  </v-container>
</template>

<script>
import JSubsectionRow from "@/layouts/sections/JSubsectionRow";
import JBannerContainer from "@/layouts/sections/banners/JBannerContainer";
import JCardBannerContainer from "@/layouts/sections/banners/JCardBannerContainer";
import JMainBannerContainer from "@/layouts/sections/banners/JMainBannerContainer";
import JCardContent from "@/layouts/sections/JCardContent";

export default {
  name: "Layouts",
  components: {
    JSubsectionRow,
    JBannerContainer,
    JCardBannerContainer,
    JMainBannerContainer,
    JCardContent
  },
  data() {
    return {
      textExample: "Test"
    }
  },
  computed: {
    userList() {
      return [
        {
          firstName: "Kate",
        },
        {
          profilePicture: require("@/assets/images/JeffLogo.png"),
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.v-sheet:not(.v-card) {
  margin: 35px 0;
  padding: 20px;
}
</style>
