<template>
  <v-card elevation="0" rounded="pill" @click="$emit('click')">
    <div class="d-flex py-2">
      <div class="d-flex flex-row flex-grow-1 align-center">
        <v-avatar size="50" class="ml-3 my-auto" :color="avatarColor">
          <v-img v-if="avatarPicture" :src="avatarPicture" :lazy-src="avatarPicture" height="42" max-width="42" :aspect-ratio="1/1" />
        </v-avatar>
        <v-avatar v-if="secondaryAvatarPicture" size="50" class="ml-n4 my-auto" :color="avatarColor" >
          <v-img :src="secondaryAvatarPicture" :lazy-src="secondaryAvatarPicture" height="42" max-width="42" :aspect-ratio="1/1" />
        </v-avatar>
        <div class="d-flex ml-2 text-left flex-grow-1 flex-column">
          <div class="d-flex justify-space-between">
            <h6 class="secondary--text">{{ title }}<v-icon v-if="showArrow" class="secondary--text mb-1">mdi-chevron-right</v-icon></h6>
            <v-chip v-if="showCurrent" small color="exercise" class="white--text font-weight-bold">CURRENT</v-chip>
            <v-chip v-else-if="showDone" small color="nutrition" class="white--text font-weight-bold">DONE</v-chip>
            <v-chip v-else-if="showUpcoming" small color="yellow" class="white--text font-weight-bold">UPCOMING</v-chip>
          </div>
          <div class="mid-grey--text d-flex">with {{ subTitle }}
          <j-avatar-list
            v-if="userProfileList"
            :user-list="userProfileList"
            :max-items="5"
            :accent-color="accentColor"
            :force-show-more-icon="true"
            size="small"
            class="ml-6"
          />
          </div>
        </div>
        <div class="d-flex flex-column mr-6 text-right ">
          <div class="mt-n1 mb-1" v-if="blockDateFormatted">
            <v-icon :color="accentColor" size="16">{{ clockIcon }}</v-icon>
            <span class='p-text' :class="accentColor + '--text'"> {{ blockDateFormatted }}</span>
          </div>
        </div>
      </div>
    </div>
    <v-row v-if="showActions">
      <v-col cols="12" align="right" justify="right">
        <v-card-actions class="float-right mr-10">
          <v-btn @click="manage">Manage</v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  name: "JBlockHeaderCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: false,
    },
    userProfileList: {
      type: Array,
      required: false,
    },
    accentColor: {
      type: String,
      required: false,
      default: "navy",
    },
    avatarPicture: {
      type: String,
      required: false,
    },
    secondaryAvatarPicture: {
      type: String,
      required: false,
    },
    startDate: {
      type: String,
      required: false,
    },
    endDate: {
      type: String,
      required: false,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    showArrow: {
      type: Boolean,
      default: true
    },
    showDone: {
      type: Boolean,
      default: false
    },
    showCurrent: {
      type: Boolean,
      default: false
    },
    showUpcoming: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      blockDate: null,
    };
  },
  computed: {
    blockDateFormatted() {
      if(this.blockDate && !this.isActive(this.blockDate, this.endDate)) {
        return this.blockDate.format("D MMM");
      }
      return null;
    },
    avatarColor() {
      return this.accentColor;
    },
    clockIcon() {
      if (this.blockDate && this.isActive(this.blockDate, this.endDate))
        return "mdi-calendar-month";
      return "mdi-calendar-clock";
    }
  },
  methods: {
    manage() {
      this.$emit("manage");
    },
    isActive(start_date, end_date) {

      let s = this.$moment(start_date, "YYYY-MM-DD");
      let date = this.$store.getters.selectedDate || this.$moment();

      if (!end_date) {
        return date.isSameOrAfter(s);
      }

      let e = this.$moment(end_date, "YYYY-MM-DD");

      return date.isBetween(s, e, "days", "[]");
    },
  },
  mounted() {
    this.blockDate = this.startDate ? moment(this.startDate, "YYYY-MM-DD") : null;
  },
};
</script>
