<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" md="7" class="pb-0">
        <back-link icon large />
        <j-card v-if="message" class="message-v-card j-elevated-1 py-3">
          <v-row justify="center" class="pb-0 mb-0 pt-3 ml-3">
            <v-col cols="12" class="pa-0 ma-0">
              <sender-block :message="message"></sender-block>
            </v-col>
          </v-row>
          <v-card-title>{{ message.inboxMessage.subject }}</v-card-title>
          <v-card-subtitle>{{ replaceTemplateTags(message.inboxMessage.content) }}</v-card-subtitle>
          <v-card-text v-if="ctaUrl" class="layout justify-center">
            <v-btn color="secondary" align="center" class="mt-1" large rounded :href="ctaUrl">
              <strong>{{ message.inboxMessage.ctaCopy }}</strong>
            </v-btn>
          </v-card-text>
          <video-block v-if="message.inboxMessage.youtubeId" class="mx-7" :show-video-cast-message="false" :youtubeId="message.inboxMessage.youtubeId" />
          <v-img v-if="message.inboxMessage.image" class="ma-4" :src="message.inboxMessage.image" />
        </j-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { INBOX_QUERY } from "@/graphql/queries/inbox";
import { INBOX_MESSAGE_RECIPIENT_MUTATION } from "@/graphql/mutations/inbox";
import { fancyShortDate } from "@/lib/fancy_date";
import SenderBlock from "@/components/inbox/SenderBlock";
import TemplateReplacerMixin from "@/mixins/inbox/TemplateReplacerMixin";
import VideoBlock from "@/components/events/VideoBlock";

export default {
  name: "Message",
  mixins: [TemplateReplacerMixin],
  components: { SenderBlock, VideoBlock },
  data() {
    return {
      loading: true,
      message: null,
      error: false,
    };
  },
  computed: {
    deliveredAt() {
      return fancyShortDate(this.message.inboxMessage.deliveredAt);
    },
    ctaUrl() {
      if (!this.message.inboxMessage.ctaUrl) {
        return null;
      }

      if (this.message.inboxMessage.ctaUrl.startsWith("https://")) {
        const domain = new URL(this.message.inboxMessage.ctaUrl);
        if (domain.origin.includes("club.jeff.fitness")) {
          return this.message.inboxMessage.ctaUrl.replace(domain.origin, "");
        }
      }

      return this.message.inboxMessage.ctaUrl;
    },
  },
  methods: {
    backLink() {
      window.history.back();
    },
    markMessageAsRead() {
      if (this.message.readAt !== null) return;

      this.$apollo
        .mutate({
          mutation: INBOX_MESSAGE_RECIPIENT_MUTATION,
          variables: {
            messageIds: [this.message.inboxMessage.id],
            read: true,
          },
          refetchQueries: ["inboxMessages"],
        })
        .then(() => {
          //update the store
          let unread = this.$store.getters.unread.unread;
          if (unread >= 1) unread -= 1;
          this.$store.dispatch("updateUnread", { unread });
        })
        .catch(() => {});
    },
  },
  apollo: {
    inboxMessages: {
      query: INBOX_QUERY,
      variables() {
        return {
          id: this.$route.params.messageId,
        };
      },
      result(response) {
        if (response.data && !response.loading) {
          let messages = response.data.inboxMessages.edges;
          if (messages.length === 0) this.error = true;
          else {
            this.message = messages[0].node;
            this.markMessageAsRead();
          }
          this.$nextTick(() => {
            this.loading = false;
          });
        }
      },
    },
  },
};
</script>

<style scoped>
.message-v-card {
  max-width: 540px;
  margin: 0 auto;
}
</style>
