import gql from "graphql-tag";

export const UPDATE_BILLING_DETAILS_MUTATION = gql`
  mutation billingDetailsMutation($input: InputBillingDetails!) {
    billingDetailsMutation(billingDetails: $input) {
      success
    }
  }
`;

export const CARD_UPDATE_MUTATION_3DS2 = gql`
  mutation updateCardDetailsMutation($input: InputTokenizeCard!) {
    tokenizeCardMutation3ds2(data: $input) {
      success
      error
      message
      redirect
    }
  }
`;

export const FINALIZE_CARD_TOKENIZATION_MUTATION = gql`
  mutation finalizeCardTokenizationMutation($tokenId: String!) {
    finalizeCardTokenizationMutation(tokenId: $tokenId) {
      success
      message
      transitioningFromPayfast
    }
  } 
`;

export const PRODUCT_ORDER_MUTATION = gql`
  mutation createProductOrderMutation($input: InputProductOrder!) {
    productOrderMutation(productOrder: $input) {
      success
      error
      order {
        id
      }
      gatewayResult {
        localTransactionId
        transactionStatus
        gatewayReference
        timestamp
        code
        redirect
        testLink
        voucher
        workoutBlocks
      }
    }
  }
`;

export const RETRY_PAYMENT_MUTATION = gql`
  mutation retryPaymentMutation($input: InputRetryPayment!) {
    retryPaymentMutation(data: $input) {
      success
      error
      gatewayResult {
        localTransactionId
        transactionStatus
        gatewayReference
        timestamp
        code
        redirect
        testLink
        voucher
      }
    }
  }
`;

export const LINK_GROUP_SUBSCRIPTION_MUTATION = gql`
  mutation linkGroupSubscriptionMutation($code: String!) {
    linkGroupSubscriptionMutation(linkingCode: $code) {
      error
      success
      error
      groupSubscription {
        id
        name
      }
    }
  }
`;
