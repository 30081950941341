<template>
  <j-card rounded="lg" class="pb-4">
    <v-toolbar class="mb-6">
      <v-toolbar-title>
        <simple-chat-header :chat="chat" classes="justify-center" />
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="$emit('closed')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <div v-html="chat.description" />
    </v-card-text>
  </j-card>
</template>

<script>
import SimpleChatHeader from "@/components/chat/SimpleChatHeader";

export default {
  name: "ChatDescription",
  components: {
    SimpleChatHeader,
  },
  props: {
    chat: {
      type: Object,
      required: true,
    },
  },
};
</script>
