export default {
  props: {
    exercise: {
      type: Object,
      required: true
    },
    completedExercises: {
      type: Array,
      required: true
    }
  },
  computed: {
    exerciseHasHowTo() {
      return this.exercise.exercise.coach && this.exercise.exercise.quickHowTo;
    },
    exerciseCompleted: {
      get() { return this.completedExercises.includes(this.exercise.id) },
      set(newValue) {
        this.$emit('exercise-completed', this.exercise.id, newValue, true);
      }
    },
  },
};