<template>
  <div>
    <v-row>
      <v-col class="pt-8">
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
          class="my-4"
        ></v-progress-circular>
        <v-card v-else class="speech-arrow card-radius">
          <v-card-text class="py-0 px-2">
            <v-row>
              <v-col
                v-if="handler.userProfile && handler.userProfile.profilePicture"
                cols="12"
                md="3"
              >
                <v-avatar size="80">
                  <img
                    :lazy-src="handler.userProfile.profilePicture"
                    :src="handler.userProfile.profilePicture"
                  />
                </v-avatar>
              </v-col>
              <v-col cols="12" md="9" class="pl-md-8 pl-lg-0">
                <h3 class="mb-4 mt-1">Hey {{ firstName }}! Welcome!</h3>
                <div>
                  <p class="font-15">
                    See all that {{ $appName }} has to offer by using the menu bars at the
                    top and bottom! While exploring the web app, you'll find
                    more information about our Life, Challenges and 1-on-1
                    Coaching products.
                  </p>
                  <p class="font-15">
                    You can also message me directly for more information.
                  </p>
                  <v-btn
                    color="whatsapp-green"
                    class="pt-1 mb-4"
                    large
                    rounded
                    dark
                    target="_blank"
                    :href="
                      `https://api.whatsapp.com/send?phone=${handler.userProfile.phone}`
                    "
                  >
                    <strong
                      >MESSAGE {{ handler.firstName }}
                      <v-icon class="pb-1 pl-1">mdi-whatsapp</v-icon></strong
                    >
                  </v-btn>
                  <p class="font-15">
                    I'm available to help you Monday - Friday, 8:00 - 17:00
                    SAST. Please go to 'More' → 'Help & Suggestions Box' for
                    urgent queries.
                  </p>

                  <h2 class="my-5">I have 5 tips to get you started</h2>
                  <onboarding-goal-tip
                    v-if="showGoal"
                    :handler-name="handler.firstName"
                    class="mt-6 mb-2"
                  />
                  <onboarding-tips
                    v-if="!showGoal"
                    :handler-name="handler.firstName"
                    class="mt-6 mb-2"
                  />
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- vi-if false!! -->
    <v-row v-if="false">
      <v-col cols="12">
        <v-card>
          <v-card-title class="card-title">Explore our App </v-card-title>
          <v-row>
            <v-col cols="6" align="center" justify="center">
              <v-btn dark class="mt-2" color="secondary" to="/today/live"
                >View live schedule</v-btn
              >
            </v-col>
            <v-col cols="6" align="center" justify="center">
              <v-btn dark class="mt-2" color="secondary" to="/today/on-demand"
                >View past workouts</v-btn
              >
            </v-col>
          </v-row>
          <v-card-title class="card-title">Collect a green dot</v-card-title>
          <v-row align="center" justify="center">
            <v-col cols="12" class="my-0 py-0 mb-3 pb-0">
              <nutrition-card class="mb-3" />
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { HANDLER_QUERY } from "@/graphql/queries/account";
import NutritionCard from "@/components/shared/blocks/NutritionCard";
import OnboardingGoalTip from "./OnboardingGoalTip";
import OnboardingTips from "./OnboardingTips";

export default {
  name: "OpenOnboarding",
  components: {
    NutritionCard,
    OnboardingGoalTip,
    OnboardingTips,
  },
  props: {
    firstName: {
      type: String,
      required: true,
    },
    showGoal: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      loading: true,
      handler: {
        firstName: "Julie",
        userProfile: {
          profilePicture: require("@/assets/images/Julie.jpg"),
          phone: "+27633789267",
        },
      },
    };
  },
  apollo: {
    handler: {
      query: HANDLER_QUERY,
      fetchPolicy: "cache-and-network",
      update(data) {
        this.loading = false;

        if (data.onboardingHandler) {
          return data.onboardingHandler;
        } else return this.handler;
      },
    },
  },
};
</script>

<style scoped>
.speech-arrow:before {
  content: "";
  position: absolute;
  top: -18px;
  left: 25px;
  width: 0;
  border-bottom: 20px solid #fff;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
}

.font-15 {
  font-size: 15px;
}
</style>
