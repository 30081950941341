import { ASSISTANT_QUERY, NOTIFICATION_QUERY } from "@/graphql/queries/assistant";
import { MASTER_SETTINGS } from "@/graphql/queries/settings";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "CentralStorageMixin",
  computed: {
    ...mapGetters(["isLoggedIn", "tasks", "subscription", "role"]),
  },
  methods: {
    ...mapActions([
      "updateCheckinStatus",
      "updateCheckinBookinglink",
      "updateCheckinId",
      "updateOutstandingUserTodos",
      "updateUnreadNotificationCount",
      "updateUnreadChatCount",
      "updateUnreadRewardNotificationCount",
      "updateCheckinIsInitial"
    ]),
  },
  apollo: {
    notificationResults: {
      query: NOTIFICATION_QUERY,
      fetchPolicy: "network-only",
      // make this the same as CHATS_FOR_USER on Home.vue
      pollInterval: 3 * 60 * 1000,
      update: (data) => data,
      async result(response) {
        // update unread counts of Group and Chat Notifications.
        if (response.data) {
          await this.updateUnreadNotificationCount(response.data.unreadNotificationCount);
          await this.updateUnreadChatCount(response.data.unreadChatCount);
          await this.updateUnreadRewardNotificationCount(response.data.unreadRewardNotifications);
        }
      },
      skip() {
        return !this.isLoggedIn;
      },
    },
    assistantTasks: {
      query: ASSISTANT_QUERY,
      fetchPolicy: "network-only",
      pollInterval: 10 * 60 * 1000,
      async result(response) {
        /**
         * Update Subscription and Role
         */
        // A poll that yields the same results will not trigger the `result` hook.
        await this.$store.dispatch("setSubscription", response.data.subscription);
        const role = response.data.subscription?.product?.type || "INACTIVE";
        if (this.role != role) {
          await this.$store.dispatch("updateRole", { role: role });
        }
        // if the subscription type in the DB is not what we have in Vuex, we need to reload the page.
        if (this.subscription && this.subscription.product?.type !== response.data?.subscription?.product?.type) {
          location.reload();
        }

        let checkinStatus = response.data?.userCheckInStatus;
        await this.updateCheckinStatus(checkinStatus?.status);
        await this.updateCheckinBookinglink(checkinStatus?.bookingLink);
        await this.updateCheckinId(checkinStatus?.checkIn?.id);
        await this.updateCheckinIsInitial(checkinStatus?.initial);

        // dynamic TODOs
        await this.updateOutstandingUserTodos(response.data.outstandingUserTodos);

        if (this.$store.getters.tasks > this.$store.getters.skipTasks.taskCount) {
          let skipTasks = { ...this.$store.getters.skipTasks };
          skipTasks.skipped = false;
          await this.$store.dispatch("updateSkipTasks", skipTasks);
        }
      },
      update() {
        return this.tasks;
      },
      skip() {
        return !this.isLoggedIn;
      },
    },
    masterSettings: {
      query: MASTER_SETTINGS,
      result(response) {
        this.$store.dispatch(
          "updateSettings",
          response.data.masterSettings.edges.map((setting) => setting.node)
        );
      },
    },
  },
};
