<template>
  <div>
    <checkin-stepper
      :step="5"
      :title="{
        text: 'Based on what you\'ve told us, we\'re ',
        strongText: 'generating personalised recommendations for you',
      }"
      @next="next"
      button-text="Get Access Now"
      :button-disabled="buttonDisabled"
    >
      <v-img class="mx-auto mx-sm-14 mx-md-auto text-center" src="@/assets/images/offer.png" />

      <j-card class="text-left mx-5 pa-4 mt-4">
        <h3 class="mt-2 mb-4">One last step to get access to your {{ $appName }} experience!</h3>

        <v-autocomplete
          v-model="data.country"
          :items="countries"
          rounded
          filled
          label="Country"
          item-text="title"
          item-value="id"
        />

        <v-row>
          <!-- asking this on Identify now -->
          <!-- <v-col cols="12">
            <vue-tel-input mode="international" :default-country="data.country" v-model="data.phone"></vue-tel-input>
          </v-col> -->
          <v-col cols="12">
            <j-text-field v-model="data.suburb" label="Suburb" />
          </v-col>
        </v-row>
      </j-card>
    </checkin-stepper>
    <v-row justify="center" class="mt-2">
      <v-col cols="12" md="4" align="center" justify="center">
        <p class="sm-text">
          Private <v-icon small>mdi-lock </v-icon> Your details are used to offer personalised recommendations for extra
          support and are never shared with any third parties.
        </p>
      </v-col></v-row
    >
  </div>
</template>

<script>
import { ACCOUNT_QUERY } from "@/graphql/queries/account";
import { UPDATE_PROFILE_MUTATION } from "@/graphql/mutations/account";
import { userData } from "@/lib/user";
import { countryListAlpha2 } from "@/lib/countries";
import CheckinStepper from "@/components/checkin/CheckinStepper";

export default {
  name: "Done",
  components: {
    CheckinStepper,
  },
  data() {
    return {
      me: {},
      data: { ...userData },
      countries: [...countryListAlpha2],
    };
  },
  computed: {
    buttonDisabled() {
      return !this.data.suburb || !this.data.phone || !this.data.country;
    },
  },
  methods: {
    // countryChanged(country) {
    //   this.data.country = country.iso2;
    // },
    previous() {
      return this.$router.push("/checkin/support");
    },
    async next() {
      let userData = {
        phone: this.data.phone,
        suburb: this.data.suburb,
        country: this.data.country,
      };

      await this.$apollo.mutate({
        mutation: UPDATE_PROFILE_MUTATION,
        variables: {
          userProfileInput: userData,
        },
        refetchQueries: ["accountQuery"],
      });
      let redirectPath = sessionStorage.getItem("contentRedirectPath") || "";
      if (redirectPath && redirectPath !== "") {
        sessionStorage.removeItem("contentRedirectPath");
        return this.$router.push(redirectPath);
      }
      return this.$router.push("/start/recommendation");
    },
  },
  apollo: {
    me: {
      query: ACCOUNT_QUERY,
      fetchPolicy: "network-only",
      result(response) {
        if (response.data) {
          let userProfile = response.data.me.userProfile;
          let user = response.data.me;
          // overwrite the ID with the non hashed version
          user.id = userProfile.user.id;
          this.data.suburb = userProfile.suburb;
          this.data.phone = userProfile.phone || "";
          this.data.country = userProfile.country && userProfile.country.code ? userProfile.country.code : "ZA";
        }
      },
    },
  },
  created() {
    if (this.$store.getters.role == "ONE_ON_ONE" || this.$store.getters.role == "CLUB") {
      // if we are one on one or club i.e we have paid already, then let's bounce from this screen. doesnt' apply.
      return this.$router.push("/start/recommendation");
    }
  },
};
</script>

<style lang="scss" scoped>
h3 {
  color: var(--v-grey-navy-base);
  margin-top: 36px;
}

p.subtitle {
  color: var(--v-grey-navy-base);
  font-size: 14px;
  margin-bottom: 8px;
}
</style>
