<template>
  <v-container>
    <div v-if="$apollo.loading && !workoutBlock">
      <v-row justify="center">
        <v-col cols="12" md="6"><v-skeleton-loader type="card-avatar" /> </v-col>
      </v-row>
    </div>
    <div v-else>
      <!-- alerts -->
      <v-row v-if="showSuccessMessage" justify="center">
        <v-col cols="12" md="6">
          <j-alert type="success" class="mx-2">
            You've successfully joined this program.
            <router-link :to="navigateOverview()" class="success--text text-decoration-underline font-weight-bold"
              >View plan</router-link
            >
          </j-alert>

          <j-alert v-if="appSourceIsNative" class="mx-2">
            If you've purchased this from your native app, you can close this window and return to your app. Find the
            program and pull down to refresh 🎉
          </j-alert>
        </v-col>
      </v-row>
      <v-row v-if="joinAccountabilityGroupAlert" justify="center">
        <v-col cols="12" md="6">
          <j-alert type="success"> Success! You will be sent an email to join the group once created. </j-alert>
        </v-col>
      </v-row>

      <v-row v-if="joinAccountabilityChatAlert" justify="center">
        <v-col cols="12" md="6">
          <j-alert type="success">
            Success! You will find your accountability group in
            <router-link to="/chats" class="green--text"> your list of chats </router-link>
          </j-alert>
        </v-col>
      </v-row>

      <j-card-banner-container
        :header="header"
        :title="workoutBlock.title"
        :intro-video="workoutBlock.introVideo"
        :subtitle="withCopy"
        :image="workoutBlock.image"
        :gradient="
          workoutBlock.type === 'NUTRITION'
            ? ['green-background', 'yellow-background']
            : ['yellow-background', 'pink-background']
        "
        :textGradient="workoutBlock.type === 'NUTRITION' ? ['green', 'yellow'] : ['pink', 'yellow']"
        show-share-link
        use-header-title-in-link
        :image-chip="isPremium(workoutBlock) ? { color: 'navy', text: 'CHALLENGE' } : null"
      >
        <template v-slot:card-content>
          <div v-if="isCurrent && hasAccess && dailyWorkoutActivities && !hasEnded" class="px-4 mb-6">
            <j-subsection-row title="" :see-all="{ text: 'Full program view', link: fullProgramLink }">
              <template v-slot:custom-see-all>
                <v-btn small text @click="openManageBlockDialog = true" class="grey-navy--text">
                  <strong> Manage </strong>
                </v-btn>
              </template>

              <!-- nutriion block needs calendar for recipes -->
              <div v-if="!isSelfPaced || workoutBlock.type === 'NUTRITION'" class="px-1 pb-4">
                <block-calendar-dots
                  v-if="activities && activities.edges"
                  :activity-logs="workoutBlockWorkoutLogs"
                  :activities="activities.edges"
                  :rest-days="restDays"
                  :accent-color="accentColor"
                  :force-logged-day-colour="workoutBlock.type === 'NUTRITION' ? 'nutrition' : null"
                  @dateUpdated="calendarUpdated($event)"
                />
              </div>

              <div v-if="isSelfPaced">
                <today-activity-block
                  v-for="dailyWorkoutActivity in dailyWorkoutActivities[0].activities"
                  :key="dailyWorkoutActivity.id"
                  :unit-activity="dailyWorkoutActivity"
                  :subscription-block="workoutSubscriptionBlock"
                  :anchor-date="anchorDate"
                  show-arrows
                  :completed="
                    hasCompleted(
                      dailyWorkoutActivity,
                      restDays,
                      customActivities,
                      anchorDate,
                      workoutSubscriptionBlock.id
                    )
                  "
                  @change-activity="activityCount += $event"
                  class="mb-3"
                />
              </div>
              <div v-else>
                <activity-day-card
                  class="pa-4 card-radius mb-5 v-card v-sheet theme--light"
                  v-for="dailyWorkoutActivity in dailyWorkoutActivities[0].activities"
                  :key="dailyWorkoutActivity.id"
                  :colour="getColour(dailyWorkoutActivity)"
                  :title="getActivityTitle(dailyWorkoutActivity)"
                  :unit-activity="dailyWorkoutActivity"
                  :current-day="dailyWorkoutActivity.currentDay"
                  :progress-percent="workoutSubscriptionBlock.progressPercent"
                  :total-days="workoutBlock.duration"
                  :subscription-block-id="workoutSubscriptionBlockId"
                  :anchor-date="anchorDate"
                  :has-completed="
                    hasCompleted(
                      dailyWorkoutActivity,
                      restDays,
                      customActivities,
                      anchorDate,
                      workoutSubscriptionBlockId
                    )
                  "
                />
              </div>

              <div class="d-flex justify-space-around" :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }">
                <plan-download-card
                  v-if="workoutBlock.activityPlanner"
                  style="width: 100%"
                  :attachment="workoutBlock.activityPlanner"
                  title="Activity Planner"
                  border-color="exercise"
                  icon="plan_icon"
                  class="mr-2"
                  :class="{ 'mr-0': $vuetify.breakpoint.smAndDown }"
                />

                <plan-download-card
                  v-if="workoutBlock.nutritionPlan && workoutBlock.type !== 'NUTRITION'"
                  style="width: 100%"
                  :attachment="workoutBlock.nutritionPlan"
                />
              </div>

              <div
                v-if="workoutBlock.type === 'NUTRITION' && workoutBlock.relatedNutritionBlock && hasAccess && !hasEnded"
              >
                <div class="d-flex justify-center mb-4">
                  <j-btn
                    narrow
                    :to="'/nutrition/' + decodeId(workoutBlock.relatedNutritionBlock.id) + '/overview'"
                    small
                    class="mx-2 my-2"
                  >
                    <span>Week's Menu</span>
                    <v-icon small class="ml-2">mdi-calendar-month</v-icon>
                  </j-btn>
                  <j-btn
                    tertiary
                    narrow
                    :to="'/nutrition/shopping-list/' + decodeId(workoutBlock.relatedNutritionBlock.id)"
                    small
                    class="mx-2 my-2"
                  >
                    <span>Shopping List</span>
                    <v-icon small class="ml-2">mdi-cart-outline</v-icon>
                  </j-btn>
                </div>

                <nutrition-summary-card
                  v-if="nutritionPlanMacros && mealPlan && mealPlan.attachment"
                  title="Eating Guidelines"
                  :img="nutritionPlanMacros.coverImage"
                  :subtitle="nutritionPlanMacros.title"
                  :caption="weightLossGoalText"
                  :link="mealPlan.attachment ? mealPlan.attachment : nutritionPlanMacros.guidePdf"
                  target="_blank"
                  raise-event
                />

                <j-subsection-row :title="recipeHeading" :show-see-all="false" class="mt-6">
                  <recipe-day-listing
                    v-if="recipesForWeek && recipesForWeek.length > 0"
                    :blockId="decodeId(workoutBlock.relatedNutritionBlock.id)"
                    :date="anchorDate"
                    :breakfast="breakfast(anchorDate)"
                    :lunch="lunch(anchorDate)"
                    :dinner="dinner(anchorDate)"
                    :primarySnack="primarySnack(anchorDate)"
                    :md-cols="6"
                  />
                  <j-alert
                    v-if="!$apollo.loading && (!recipesForWeek || (recipesForWeek && recipesForWeek.length == 0))"
                    class="mt-4"
                  >
                    No recipes for this week

                    <j-btn secondary narrow @click="restartNutritionProgram()" class="d-block mt-2">
                      Restart Recipes
                    </j-btn>
                  </j-alert>
                </j-subsection-row>
              </div>
            </j-subsection-row>
          </div>
        </template>
        <div class="px-md-8 pt-4">
          <j-subsection-row title="Overview" :show-see-all="false">
            <div v-if="youtubeId" class="pb-4">
              <video-block :youtubeId="youtubeId" :show-video-cast-message="false" />
            </div>

            <p
              class="text-left p-text"
              v-if="workoutBlock.description"
              v-html="formatDescription(workoutBlock.description)"
            ></p>

            <j-card v-if="workoutBlock.getReadyDescription && hasAccess" class="pa-4 mb-4">
              <h4 class="navy--text text-left mb-2">Get Ready</h4>
              <p class="text-left p-text" v-html="workoutBlock.getReadyDescription"></p>
            </j-card>
            <j-card class="mb-2">
              <v-skeleton-loader v-if="loading || $apollo.loading" type="card" />
              <block-header
                v-else
                :avg-rating="workoutBlock.avgRating"
                :total-reviews="workoutBlock.totalReviews"
                :workout-block="workoutBlock"
                :workout-subscription-block="workoutSubscriptionBlock"
                :is-mine="hasAccess"
                :is-current="isCurrent"
                :has-ended="hasEnded"
                @manage="openManageBlockDialog = true"
                @doProgram="doProgram"
                @openRatingDialog="openRatingDialog = true"
                @showRatings="showRatings"
                @joinAccountabilityGroup="openAccountabilityGroupDialog()"
                @restartProgram="restartProgram"
              />
            </j-card>
          </j-subsection-row>
          <!-- NOTE v-if="false", WE ARE HIDING ABILITY TO POST ON WORKOUT BLOCKS -->
          <j-subsection-row
            v-if="false && primaryGroupId && hasAccess"
            title="Posts"
            :show-see-all="workoutBlock.facebookUrl != null"
            :see-all="{ text: 'Go to Facebook Group', link: workoutBlock.facebookUrl }"
            class="mt-2"
          >
            <!-- if is mine -->

            <div class="pa-md-4 pt-0">
              <post-container
                ref="postContainer"
                :group-id="primaryGroupId"
                :featured-user-ids="[workoutBlock.leadInstructor.id]"
                :highlight-colour="highlightColour"
                hide-post-group-name
              />
            </div>
          </j-subsection-row>
        </div>
      </j-card-banner-container>
    </div>

    <!-- Rating dialog -->
    <v-dialog
      v-if="workoutBlock"
      v-model="openRatingDialog"
      width="600"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <rating-card
        :block-id="parseInt(blockId)"
        :user-can-rate="workoutBlock.userCanRate"
        @closed="closeRatingsDialog"
      />
    </v-dialog>

    <!-- All Ratings dialog -->
    <v-dialog
      v-if="workoutBlock"
      v-model="openAllRatingDialog"
      width="600"
      transition="dialog-bottom-transition"
      class="limited-height"
      scrollable
    >
      <rating-list
        :reviews="reviews"
        :hasNextPage="hasNextPage"
        @closed="closeRatingsDialog"
        @showMore="showRatings"
        @addReview="openRatingDialog = true"
        :user-can-rate="workoutBlock.userCanRate"
      >
      </rating-list>
    </v-dialog>

    <!-- Confirm program dialog -->
    <v-dialog
      v-if="workoutBlock"
      v-model="confirmProgramDialog"
      width="600"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <confirm-program
        :workout-block="workoutBlock"
        :workout-subscription-blocks="workoutSubscriptionBlocks"
        :loading="loading"
        @closed="confirmProgramDialog = false"
        @saved="confirmProgram"
      />
    </v-dialog>

    <!-- join chat accountability groups dialog -->
    <v-dialog
      v-if="workoutBlock"
      v-model="joinAccountabilityChatsDialog"
      width="600"
      transition="dialog-bottom-transition"
      class="limited-height"
      persistent
    >
      <join-accountability-chats
        :chats="workoutBlock.chats"
        @confirm="joinAccountabilityChat($event)"
        @closed="joinAccountabilityChatsDialog = false"
      />
    </v-dialog>

    <!-- join accountability group dialog -->
    <v-dialog
      v-if="workoutBlock"
      v-model="joinAccountabilityGroupDialog"
      width="600"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <join-chat @confirm="createChatRegistration" @closed="joinAccountabilityGroupDialog = false" />
    </v-dialog>

    <!-- manage block dialog -->
    <v-dialog
      v-if="workoutSubscriptionBlock"
      v-model="openManageBlockDialog"
      width="600"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <manage-block-dialog
        @leave="leaveBlockHandler"
        @saved="updateSubscriptionBlockHandler"
        :workout-subscription-block="workoutSubscriptionBlock"
        :loading="loading"
        @closed="openManageBlockDialog = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import {
  WORKOUT_BLOCK_QUERY,
  WORKOUT_SUBSCRIPTION_BLOCKS_QUERY,
  DAILY_WORKOUT_ACTIVITIES_QUERY,
  WORKOUT_BLOCK_WORKOUT_LOGS,
  WORKOUT_BLOCK_RATING_QUERY,
} from "@/graphql/queries/workoutblock";
import { WEEK_RECOMMENDED_RECIPES_QUERY, NUTRITION_BLOCK_NUTRITION_LOGS } from "@/graphql/queries/nutritionblock";
import { ACTIVITIES_AND_NUTRITION_QUERY, USER_ACTIVITIES_QUERY } from "@/graphql/queries/tracker";
import { SUBSCRIPTION_NUTRITION_BLOCK_MUTATION } from "@/graphql/mutations/nutritionblock";
import { NUTRITION_PLAN_MACRO_QUERY } from "@/graphql/queries/nutrition";
import { CREATE_SUBSCRIPTION_BLOCK_MUTATION } from "@/graphql/mutations/workoutblock";
import { CREATE_CHAT_REGISTRATION_MUTATION } from "@/graphql/mutations/chat";
import { UPSERT_USER_CHAT_MUTATION } from "@/graphql/mutations/chat";
import { mapGetters, mapActions } from "vuex";
import { getYouTubeId } from "@/lib/string";
import { decodeId } from "@/lib/string";
import { nl2br } from "@/lib/string";
import { getDayOfWeekString } from "@/lib/timezone";
import BlockMixin from "@/mixins/BlockMixin";
import PremiumBlockMixin from "@/mixins/PremiumBlockMixin";
import OnboardingMixin from "@/mixins/OnboardingMixin";
import BlockHeader from "@/components/workoutblocks/cards/BlockHeader";
import ConfirmProgram from "@/components/workoutblocks/cards/ConfirmProgram";
import BlockCalendarDots from "@/components/shared/blocks/BlockCalendarDots";
import ActivityDayCard from "@/components/workoutblocks/cards/ActivityDayCard";
import ManageBlockDialog from "@/components/workoutblocks/dialogs/ManageBlockDialog";
import PostContainer from "@/components/groups/posts/PostContainer";
import VideoBlock from "@/components/events/VideoBlock";
import JoinChat from "@/components/chat/dialogs/JoinChat";
import JoinAccountabilityChats from "@/components/chat/dialogs/JoinAccountabilityChats";
import TodayActivityBlock from "@/components/workoutblocks/cards/TodayActivityBlock";
import RatingCard from "@/components/rating/RatingCard";
import RatingList from "@/components/rating/RatingList";
import { extractNodes } from "@/lib/array";
import RecipeListMixin from "@/mixins/nutrition/RecipeListMixin";
import NutritionSummaryCard from "@/components/nutritionblocks/cards/NutritionSummaryCard";
import PlanDownloadCard from "@/components/nutritionblocks/cards/PlanDownloadCard";
import NutritionBlockMixin from "@/mixins/nutrition/NutritionBlockMixin";

export default {
  name: "WorkoutBlock",
  mixins: [BlockMixin, OnboardingMixin, PremiumBlockMixin, RecipeListMixin, NutritionBlockMixin],
  components: {
    BlockHeader,
    ConfirmProgram,
    BlockCalendarDots,
    ActivityDayCard,
    PostContainer,
    ManageBlockDialog,
    VideoBlock,
    JoinChat,
    TodayActivityBlock,
    RatingCard,
    RatingList,
    JoinAccountabilityChats,
    NutritionSummaryCard,
    PlanDownloadCard,
  },
  data() {
    return {
      loading: false,
      anchorDate: null,
      workoutBlock: null,
      workoutSubscriptionBlocks: null,
      confirmProgramDialog: false,
      joinAccountabilityGroupDialog: false,
      joinAccountabilityGroupAlert: false,
      joinAccountabilityChatsDialog: false,
      joinAccountabilityChatAlert: false,
      dailyWorkoutActivities: null,
      workoutBlockWorkoutLogs: null,
      openManageBlockDialog: false,
      activities: null,
      customActivities: [], // custom dots
      showSuccessMessage: false,
      activityCount: null,
      openRatingDialog: false,
      openAllRatingDialog: false,
      reviews: [],
      ratingsCursor: null,
      hasNextPage: false,
      weightLossGoal: null,
    };
  },
  props: {
    blockId: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters(["restDays"]),
    appSourceIsNative() {
      // did we check out and purchase from the native app?
      const appSource = sessionStorage.getItem("appSource");
      if (appSource == "native") {
        return true;
      }
      return false;
    },
    recipeHeading() {
      let when = this.$moment(this.anchorDate).format("dddd, D MMM");
      if (this.anchorDate === this.$moment().format("YYYY-MM-DD")) {
        when = "today";
      }
      return `Recipes for ${when}`;
    },
    header() {
      if (this.workoutBlock.type === "COURSE") {
        return "Course";
      }
      if (this.workoutBlock.type === "NUTRITION") {
        return "Nutrition Program";
      }
      return "Program";
    },
    mealPlan() {
      return this.workoutBlock?.blockattachmentSet?.find((obj) => {
        return obj.code?.toLowerCase() === this.$store.getters.user?.userProfile?.calculatedServingSize?.toLowerCase();
      });
    },
    // careful, duplicated in TodayNutrition.vue
    weightLossGoalText() {
      let values = {
        fat_loss: "for Weight Loss",
        maintenance: "to Maintain Weight",
        toning: "for Toning",
        weight_gain: "for Gaining Weight",
      };
      return this.weightLossGoal in values ? values[this.weightLossGoal] : "";
    },
    // the workout_SUBSCRIPTION_block for the user for this specific block. i.e gives us start date / end date.
    youtubeId() {
      return getYouTubeId(this.workoutBlock?.introYoutubeVideo);
    },
    fullProgramLink() {
      if (this.workoutSubscriptionBlock && this.workoutSubscriptionBlockId) {
        return `/program/${this.workoutBlock.id}/overview?sbid=${this.workoutSubscriptionBlockId}&sd=${this.workoutSubscriptionBlock.startDate}`;
      }
      return `/program/${this.workoutBlock.id}/overview`;
    },
    highlightColour() {
      // colours duplicated from vuetify.js
      if (this.workoutBlock.type === "COURSE") {
        return "#FFD13C";
      }
      if (this.workoutBlock.type === "NUTRITION") {
        return "#3ADEB4";
      }
      return "#FD89A2";
    },
    hasAccess() {
      const role = this.$store.getters.role;
      return this.workoutSubscriptionBlock != null && (role === "ONE_ON_ONE" || role === "CLUB");
    },
    workoutSubscriptionBlock() {
      const blockId = this.blockId;
      // there may be more than one workoutSubscriptionBlocks
      // i.e user may have done one, ended it, started the same block again.
      // sort by latest Start Date
      if (this.workoutSubscriptionBlocks?.length > 0) {
        const filteredSubBlocks = this.workoutSubscriptionBlocks.filter((el) => decodeId(el.block.id) == blockId) ?? [];
        const sortedSubBlocks = filteredSubBlocks.sort((a, b) => (a.startDate < b.startDate ? 1 : -1));
        if (sortedSubBlocks?.length > 0) {
          return sortedSubBlocks[0];
        }
      }
      return null;
    },
    workoutSubscriptionBlockId() {
      if (this.workoutSubscriptionBlock?.id) {
        return decodeId(this.workoutSubscriptionBlock.id);
      }
      return null;
    },
    isSelfPaced() {
      return this.workoutSubscriptionBlock?.isSelfPaced ?? false;
    },
    totalActivities() {
      return (
        (this.workoutBlock?.totalWorkouts ?? 0) +
        (this.workoutBlock?.totalModules ?? 0) +
        (this.workoutBlock?.totalEds ?? 0) +
        (this.workoutBlock?.totalLiveEventsRestDays ?? 0)
      );
    },
    isCurrent() {
      return (
        this.workoutSubscriptionBlock &&
        this.$moment().format("YYYY-MM-DD") >= this.workoutSubscriptionBlock?.startDate &&
        this.workoutSubscriptionBlock?.progressPercent < 100
      );
    },
    hasEnded() {
      return (
        (this.workoutSubscriptionBlock &&
          this.$moment().format("YYYY-MM-DD") >= this.workoutSubscriptionBlock?.endDate) ||
        (!this.workoutSubscriptionBlock?.endDate && this.workoutSubscriptionBlock?.progressPercent >= 100)
      );
    },
    primaryGroupId() {
      if (this.workoutBlock?.groupSet.edges.length > 0) {
        // should only be one.
        return decodeId(this.workoutBlock.groupSet.edges[0].node.id);
      }
      return null;
    },
  },
  methods: {
    ...mapActions(["setRestDays"]),
    extractNodes(nodes) {
      return extractNodes(nodes);
    },
    formatDescription(string) {
      return nl2br(string);
    },
    calendarUpdated(date) {
      this.anchorDate = date.format("YYYY-MM-DD");
    },
    restartNutritionProgram() {
      const nutritionSubscriptionBlock = this.getSubscriptionBlockIdForBlock(
        decodeId(this.workoutBlock.relatedNutritionBlock.id)
      );
      const data = {
        nutritionSubscriptionBlock: { id: nutritionSubscriptionBlock },
        startDate: this.$moment().startOf("isoweek").format("YYYY-MM-DD"),
      };
      this.updateNutritionSubscriptionBlock(data);
    },
    async showRatings() {
      const result = await this.$apollo.query({
        query: WORKOUT_BLOCK_RATING_QUERY,
        variables: {
          first: 3,
          orderBy: "-createdAt",
          after: this.ratingsCursor,
          workoutBlockId: this.blockId,
        },
        fetchPolicy: "no-cache",
      });

      this.ratingsCursor = result.data.workoutBlock.ratingSet.pageInfo.endCursor;
      this.hasNextPage = result.data.workoutBlock.ratingSet.pageInfo.hasNextPage;
      this.reviews.push(...extractNodes(result.data.workoutBlock.ratingSet.edges));

      this.openAllRatingDialog = true;
    },
    closeRatingsDialog() {
      this.openAllRatingDialog = false;
      this.openRatingDialog = false;
      this.ratingsCursor = null;
      this.reviews = [];
      this.$apollo.queries.workoutBlock.refetch();
    },
    doProgram() {
      this.showSuccessMessage = false;
      this.joinAccountabilityGroupAlert = false;
      const role = this.$store.getters.role;
      if (!this.isPremium(this.workoutBlock) && (role === "ONE_ON_ONE" || role === "CLUB")) {
        // if on club, then we can go ahead and assign user and make it self paced.
        //return (this.confirmProgramDialog = true);
        let startDate;
        if (this.workoutBlock.startDate && this.$moment().isBefore(this.workoutBlock.startDate)) {
          startDate = this.workoutBlock.startDate;
        } else {
          startDate = this.$moment().startOf("isoweek").format("YYYY-MM-DD");
        }
        const input = {
          startDate: startDate,
          blockId: decodeId(this.workoutBlock.id),
          isSelfPaced: !this.isPremium(this.workoutBlock),
        };

        return this.confirmProgram(input);
      }

      // user needs to buy club.
      // set the block Id for the purposes of redirecting.
      sessionStorage.setItem("workoutBlockId", this.workoutBlock.id);

      if (this.isPremium(this.workoutBlock)) {
        const pvi = decodeId(this.workoutBlock.productVariant.id);
        return this.$router.push(`/checkout/premium-block?pvi=${pvi}`);
      }

      return this.$router.push(`/checkout/jeff-club`);
    },
    async confirmProgram(data) {
      this.confirmProgramDialog = false;

      // if workoutBlock.price > 0 (premium workout block), we need to first hit the end point to make payment
      // this end point is then also responsible for creating the subscription block.
      // i.e the code beneath this block will never run for a premius workout block.
      if (this.isPremium(this.workoutBlock)) {
        // when we come back to this page after payment, set the nutrition block
        if (data.selectedNutritionBlockId) {
          sessionStorage.setItem(
            "selectedNutritionBlock",
            JSON.stringify({
              id: data.selectedNutritionBlockId,
              startDate: data.startDate,
            })
          );
        }
        const pvi = decodeId(this.workoutBlock.productVariant.id);
        return this.$router.push(`/checkout/premium-block?pvi=${pvi}`);
      }

      this.loading = true;
      this.startDate = data.startDate;
      this.activityCount = null;

      const input = {
        startDate: this.startDate,
        blockId: this.blockId,
        isSelfPaced: data.isSelfPaced,
      };

      await this.$apollo.mutate({
        mutation: CREATE_SUBSCRIPTION_BLOCK_MUTATION,
        variables: {
          data: input,
        },
        refetchQueries: ["assistantQuery"],
      });

      if (data.selectedNutritionBlockId) {
        const nutritionInput = {
          startDate: data.startDate,
          nutritionBlockId: decodeId(data.selectedNutritionBlockId),
        };

        await this.$apollo.mutate({
          mutation: SUBSCRIPTION_NUTRITION_BLOCK_MUTATION,
          variables: {
            data: nutritionInput,
          },
        });
      }
      await this.$apollo.queries.workoutSubscriptionBlocks.refetch();
      await this.$apollo.queries.dailyWorkoutActivities.refetch();
      await this.$apollo.queries.nutritionSubscriptionBlocks.refetch();
      await this.$apollo.queries.recipesForWeek.refetch();
      this.loading = false;

      // if we are confirming a program and have outstanding onboarding todos, then show the success alert with a link back to Home
      this.showSuccessMessage = this.showOnboardingSummary;
      if (this.showSuccessMessage) {
        window.scrollTo(0, 0);
      }
      this.joinAccountabilityGroupDialog = this.workoutBlock.showAccountabilityGroupRegistration;
      this.joinAccountabilityChatsDialog = this.workoutBlock.showAccountabilityChats;
    },
    async restartProgram() {
      this.loading = true;
      // if restarting, create a new record!
      const input = {
        startDate: this.$moment().startOf("isoweek").format("YYYY-MM-DD"),
        blockId: this.blockId,
        isSelfPaced: true, // on restart, must be self paced
      };

      await this.$apollo.mutate({
        mutation: CREATE_SUBSCRIPTION_BLOCK_MUTATION,
        variables: {
          data: input,
        },
        refetchQueries: ["assistantQuery", "workoutSubscriptionBlocks", "dailyWorkoutActivities"],
      });
      // wait for refetches to complete. Hack.
      setTimeout(() => (this.loading = false), 1000);
    },
    async updateSubscriptionBlockHandler(data) {
      this.loading = true;
      await this.updateSubscriptionBlock(data);
      await this.$apollo.queries.workoutSubscriptionBlocks.refetch();
      await this.$apollo.queries.dailyWorkoutActivities.refetch();
      this.openManageBlockDialog = false;
      this.loading = false;
    },
    async leaveBlockHandler() {
      this.loading = true;
      await this.leaveBlock();
      await this.$apollo.queries.workoutSubscriptionBlocks.refetch();
      await this.$apollo.queries.dailyWorkoutActivities.refetch();
      this.openManageBlockDialog = false;
      this.loading = false;
    },
    reloadGroup() {
      const postContainer = this.$refs.postContainer;
      postContainer.reloadThread();
    },
    async createChatRegistration() {
      this.joinAccountabilityGroupDialog = false;
      await this.$apollo.mutate({
        mutation: CREATE_CHAT_REGISTRATION_MUTATION,
        variables: {
          workoutBlockId: this.blockId,
        },
        refetchQueries: ["assistantQuery", "workoutBlock"],
      });
      this.joinAccountabilityGroupAlert = true;
      window.scrollTo(0, 0);
    },
    async joinAccountabilityChat(chatId) {
      this.joinAccountabilityChatsDialog = false;
      await this.$apollo.mutate({
        mutation: UPSERT_USER_CHAT_MUTATION,
        variables: {
          data: {
            chatId: decodeId(chatId),
            receiveNotifications: true,
            shareData: true,
          },
        },
        refetchQueries: ["chatsForUser"],
      });
      this.joinAccountabilityChatAlert = true;
      window.scrollTo(0, 0);
    },
    openAccountabilityGroupDialog() {
      if (this.workoutBlock.showAccountabilityGroupRegistration) {
        return (this.joinAccountabilityGroupDialog = true);
      }
      if (this.workoutBlock.showAccountabilityChats) {
        return (this.joinAccountabilityChatsDialog = true);
      }
    },
  },
  apollo: {
    workoutBlock: {
      query: WORKOUT_BLOCK_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          workoutBlockId: this.blockId,
          withProductVariant: true,
          groupType: "PRIMARY",
        };
      },
    },
    // get the blocks for the user
    workoutSubscriptionBlocks: {
      query: WORKOUT_SUBSCRIPTION_BLOCKS_QUERY,
      fetchPolicy: "network-only",
      result(response) {
        if (response.data) {
          //  only show the dialog if the user is not yet on this block
          // check if this block is already subscribed to. if so, don't ask to confirm again.
          let currentBlock;
          const blockId = this.blockId;
          const workoutSubscriptionBlocks = response.data.workoutSubscriptionBlocks.edges;
          if (workoutSubscriptionBlocks && workoutSubscriptionBlocks.length > 0) {
            currentBlock = workoutSubscriptionBlocks.find(function (el) {
              return decodeId(el.node.block.id) == blockId;
            });
          }

          const role = this.$store.getters.role;
          if ((role === "ONE_ON_ONE" || role === "CLUB") && this.$route.query.confirm && !currentBlock) {
            this.$route.query.confirm = null;
            history.pushState({}, null, this.$route.path);
            return this.doProgram();
          } else if ((role === "ONE_ON_ONE" || role === "CLUB") && this.$route.query.confirm) {
            // if we are asking to confirm, we need to popup the accountability group modal.
            this.joinAccountabilityGroupDialog = currentBlock.node?.block?.showAccountabilityGroupRegistration;
            this.joinAccountabilityChatsDialog = currentBlock.node?.block?.showAccountabilityChats;
            this.$route.query.confirm = null;
          }

          if (this.$route.query.success) {
            this.showSuccessMessage = true;
          }
          // remove all query strings from URL
          history.pushState({}, null, this.$route.path);
          this.workoutSubscriptionBlocks = this.workoutSubscriptionBlocks.edges.map((element) => {
            return element.node;
          });
        }
      },
    },
    // activities for the block
    dailyWorkoutActivities: {
      query: DAILY_WORKOUT_ACTIVITIES_QUERY,
      fetchPolicy: "cache-and-network",
      skip() {
        return (
          !this.anchorDate ||
          !this.workoutBlock ||
          !this.workoutSubscriptionBlocks ||
          !this.workoutSubscriptionBlock ||
          !this.isCurrent
        );
      },
      variables() {
        return {
          anchorDate: this.anchorDate,
          activityCount: this.activityCount,
          subscriptionBlockId: decodeId(this.workoutSubscriptionBlock.id),
        };
      },
      update(data) {
        if (
          !this.activityCount &&
          data.dailyWorkoutActivities.length > 0 &&
          data.dailyWorkoutActivities[0].activities?.length > 0
        ) {
          this.activityCount = data.dailyWorkoutActivities[0]?.activities[0]?.currentActivity;
        }

        return data.dailyWorkoutActivities;
      },
    },
    // logs for the block
    workoutBlockWorkoutLogs: {
      query: WORKOUT_BLOCK_WORKOUT_LOGS,
      fetchPolicy: "network-only",
      skip() {
        return !this.workoutSubscriptionBlock;
      },
      variables() {
        return {
          subscriptionBlockId: this.workoutSubscriptionBlock.id,
        };
      },
      result(response) {
        if (response.data) {
          this.workoutBlockWorkoutLogs = response.data.workoutBlockWorkoutLogs.edges;
        }
      },
    },
    // here we only care about rest days
    activities: {
      query: ACTIVITIES_AND_NUTRITION_QUERY,
      variables() {
        return {
          startDate: getDayOfWeekString(1, false, this.week, false),
          endDate: getDayOfWeekString(7, true, this.week, false),
          userId: btoa(`UserNode: ${this.$store.getters.user.id}`),
        };
      },
      fetchPolicy: "network-only",
      result(response) {
        if (response.data) {
          // set logs in Vuex
          this.setRestDays(response.data.restDays.edges);
        }
      },
    },
    // activity logs against live events for a specific date. i.e this is only used in activity-day-card
    customActivities: {
      query: USER_ACTIVITIES_QUERY,
      skip() {
        return !this.anchorDate;
      },
      variables() {
        return {
          selectedDate: this.anchorDate,
        };
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        return data.activities.edges.map((item) => {
          return item.node;
        });
      },
    },
    // nutrition specific queries
    recipesForWeek: {
      query: WEEK_RECOMMENDED_RECIPES_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          anchorDate: this.monday,
          nutritionBlockId: decodeId(this.workoutBlock.relatedNutritionBlock.id),
          type: "STANDARD",
          subscriptionBlockId: this.getSubscriptionBlockIdForBlock(
            decodeId(this.workoutBlock.relatedNutritionBlock.id)
          ),
        };
      },
      skip() {
        return !this.workoutBlock?.relatedNutritionBlock?.id || !this.isMine;
      },
    },
    nutritionBlockLogs: {
      query: NUTRITION_BLOCK_NUTRITION_LOGS,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          nutritionBlockId: decodeId(this.workoutBlock.relatedNutritionBlock.id),
        };
      },
      update(data) {
        return data.nutritionBlockNutritionLogs.edges;
      },
      skip() {
        return !this.workoutBlock?.relatedNutritionBlock?.id || !this.isCurrent;
      },
    },
    nutritionPlanMacros: {
      query: NUTRITION_PLAN_MACRO_QUERY,
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data && !response.loading) {
          this.weightLossGoal = response.data.me.userProfile?.weightLossGoal;
        }
      },
      skip() {
        return !this.workoutBlock?.relatedNutritionBlock?.id || !this.isMine;
      },
    },
  },
  created() {
    // we are not handling auth in router/index.js so that we can redirect to jeff club checkout and not login
    if (!this.$store.getters.user) {
      return this.$router.push("/checkout/jeff-club");
    }
    this.anchorDate = this.$moment().format("YYYY-MM-DD");

    // this session variable was set when redirecting to checkout via premium program (pvi)
    // if it's set, we can set the nutrition block for the user, if they are club and then destroy.
    const selectedNutritionBlock = JSON.parse(sessionStorage.getItem("selectedNutritionBlock"));
    if (selectedNutritionBlock && (this.$store.getters.role == "ONE_ON_ONE" || this.$store.getters.role === "CLUB")) {
      const nutritionInput = {
        startDate: selectedNutritionBlock.startDate,
        nutritionBlockId: decodeId(selectedNutritionBlock.id),
      };
      this.$apollo.mutate({
        mutation: SUBSCRIPTION_NUTRITION_BLOCK_MUTATION,
        variables: {
          data: nutritionInput,
        },
      });
      sessionStorage.removeItem("selectedNutritionBlock");
    }
  },
};
</script>
