<template>
  <div>
    <v-card-text v-if="infoContent">
      <v-row class="mx-0">
        <v-col v-if="icon || instructor" cols="12" md="6" class="py-1">
          <div class="d-flex align-center mt-1">
            <template v-if="icon"
              ><v-avatar size="32px" class="mr-2">
                <v-img :lazy-src="icon" :src="icon" />
              </v-avatar>
              <v-avatar
                v-if="secondaryInstructor && secondaryInstructor.userProfile.profilePicture"
                size="32px"
                class="ml-n4 mr-2"
              >
                <v-img
                  :lazy-src="secondaryInstructor.userProfile.profilePicture"
                  :src="secondaryInstructor.userProfile.profilePicture"
                />
              </v-avatar>
            </template>
            <span v-if="instructor" class="p-text">{{ instructor }}</span>
          </div>
        </v-col>
        <v-col v-for="(col, index) in info" :key="index" cols="12" md="6" class="py-1">
          <div class="d-flex align-center mt-1">
            <v-icon color="secondary" class="mr-2"> {{ col.icon }} </v-icon>
            <span v-html="col.text" class="p-text info-text"></span>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="hasReviewRating">
        <v-col v-if="totalReviews > 0" cols="7" md="6" class="py-1">
          <div class="d-flex align-center mt-1 pointer pl-2" @click="emitAllRatings">
            <v-icon color="yellow" class="mr-1"> mdi-star </v-icon>
            <span class="p-text info-text mr-2 font-weight-bold">{{ avgRating }}</span>
            <span class="p-text info-text text-decoration-underline font-weight-bold green--text">{{
              numberOfReviewers
            }}</span>
          </div>
        </v-col>
        <v-col v-if="userCanRate" cols="5" md="6" class="py-1">
          <div class="d-flex align-center mt-1 pointer" :class="{ 'ml-2': !totalReviews }" @click="emitRatingButton">
            <span class="p-text info-text text-decoration-underline font-weight-bold blue--text">Add Review</span>
            <v-icon color="secondary" class="mr-2" size="18"> mdi-plus </v-icon>
          </div>
        </v-col>
      </v-row>
    </v-card-text>

    <j-card
      v-if="summaryInfo.length > 1 || (summaryInfo.length > 0 && summaryInfo[0].title)"
      color="grey-background"
      flat
      rounded="lg"
      class="ma-3 mt-1 pa-3 d-flex justify-space-around text-center"
    >
      <v-row align="center" justify="center">
        <v-col v-for="(col, index) in summaryInfo" :key="index">
          <v-icon v-if="col.icon" :color="accentColor">{{ col.value }}</v-icon>
          <span v-else :class="accentColor + '--text'">{{ col.value }}</span>
          <br />
          <span class="grey-navy--text smaller-font">{{ col.title }}</span>
        </v-col>

        <v-col v-if="summaryAvatarList">
          <slot name="summaryAvatarList" class="align-center" />
        </v-col>
      </v-row>
    </j-card>

    <j-card v-if="description" class="pa-4 mx-4 p-text mb-4">
      <span class="p-text">{{ description }}</span>
    </j-card>

    <v-card-actions v-if="showCta">
      <v-row class="mx-lg-0">
        <v-col v-if="topButton" cols="12" class="pb-4">
          <j-btn block secondary narrow @click="emitTopButton">
            <span>{{ topButton }}</span>
            <v-icon v-if="topButtonIcon" small class="mt-n1 ml-2">{{ topButtonIcon }}</v-icon>
          </j-btn>
        </v-col>

        <v-col v-if="leftButton" cols="6" class="py-0">
          <j-btn
            :disabled="disableActions"
            block
            :secondary="leftButtonSecondary"
            :tertiary="!leftButtonSecondary"
            @click="emitLeftButton"
            >{{ leftButton }}
            <v-icon v-if="leftButtonIcon" small class="mt-n1 mr-n2">{{ leftButtonIcon }}</v-icon>
          </j-btn>
        </v-col>
        <v-col v-if="rightButton" cols="6" class="pb-0">
          <j-btn :disabled="disableActions" block tertiary @click="emitRightButton"
            >{{ rightButton }}
            <v-icon v-if="rightButtonIcon" small class="mt-n1 mr-n2">{{ rightButtonIcon }}</v-icon>
          </j-btn>
        </v-col>
        <v-col v-if="primaryButton" cols="12" class="pb-0">
          <j-btn
            :disabled="disableActions || primaryButtonDisabled"
            block
            :loading="primaryButtonLoading"
            @click="emitPrimaryButton"
            :color="accentColor"
          >
            <span>{{ primaryButton }}</span>
          </j-btn>
        </v-col>
      </v-row>
    </v-card-actions>

    <!-- no longer in use -->
    <!-- <v-row v-if="facebookUrl || whatsappUrl" class="mt-2">
      <v-col v-if="facebookUrl" cols="6">
        <j-btn tertiary target="_blank" :href="facebookUrl"
          >Group
          <v-icon color="facebook-blue">mdi-facebook</v-icon>
        </j-btn>
      </v-col>

      <v-col v-if="whatsappUrl" cols="6">
        <j-btn tertiary target="_blank" :href="whatsappUrl"
          >Updates
          <v-icon color="whatsapp-green">mdi-whatsapp</v-icon>
        </j-btn>
      </v-col>
    </v-row> -->
  </div>
</template>

<script>
export default {
  name: "JCardContent",
  props: {
    icon: {
      type: String,
      required: false,
    },
    instructor: {
      type: String,
      required: false,
    },
    secondaryInstructor: {
      type: Object,
      required: false,
    },
    description: {
      type: String,
      required: false,
    },
    disableActions: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Array,
      required: false,
      default: () => {
        return [
          {
            icon: "",
            text: "",
          },
        ];
      },
    },
    summaryInfo: {
      type: Array,
      required: false,
      default: () => {
        return [
          {
            title: "",
            value: "",
            icon: false,
          },
        ];
      },
    },
    summaryAvatarList: {
      type: Boolean,
      required: false,
      default: false,
    },
    accentColor: {
      type: String,
      required: false,
      default: "pink",
    },
    topButton: {
      type: String,
      required: false,
    },
    topButtonIcon: {
      type: String,
      required: false,
    },
    leftButton: {
      type: String,
      required: false,
    },
    leftButtonSecondary: {
      type: Boolean,
      default: false,
    },
    leftButtonIcon: {
      type: String,
      required: false,
    },
    rightButton: {
      type: String,
      required: false,
    },
    rightButtonIcon: {
      type: String,
      required: false,
    },
    primaryButton: {
      type: String,
      required: false,
    },
    primaryButtonLoading: {
      type: Boolean,
      default: false,
    },
    primaryButtonDisabled: {
      type: Boolean,
      default: false,
    },
    showCta: {
      type: Boolean,
      default: true,
    },
    facebookUrl: {
      type: String,
      required: false,
    },
    whatsappUrl: {
      type: String,
      required: false,
    },
    avgRating: {
      type: [Number, String],
      required: false,
    },
    totalReviews: {
      type: Number,
      required: false,
    },
    hasReviewRating: {
      type: Boolean,
      default: false,
    },
    userCanRate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    numberOfReviewers() {
      return `${this.totalReviews} Review${this.totalReviews != 1 ? "s" : ""}`;
    },
    infoContent() {
      return (this.icon && this.instructor) || (this.info.length > 1 && this.info[0].text != "");
    },
  },
  methods: {
    emitLeftButton(value) {
      this.$emit("leftButtonClicked", value);
    },
    emitRightButton(value) {
      this.$emit("rightButtonClicked", value);
    },
    emitPrimaryButton(value) {
      this.$emit("primaryButtonClicked", value);
    },
    emitRatingButton(value) {
      this.$emit("ratingButtonClicked", value);
    },
    emitAllRatings(value) {
      this.$emit("allRatingsButtonClicked", value);
    },
    emitTopButton(value) {
      this.$emit("topButtonClicked", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.smaller-font {
  font-size: 15px;
}
.info-text {
  margin-top: 2px;
}
</style>
