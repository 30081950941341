<template>
  <v-container class="py-0 my-0 workout-form">
    <validation-observer ref="obs" v-slot="{ valid, errors, pristine, validate }">
      <v-form ref="form">
        <div v-if="eventProperties.category == 'exercise'">
          <div class="text-left pointer mt-4">
            <selfie-alert
              :image="activity.image"
              :loading="imageLoading"
              :prize-text="activePrizeText"
              @clickUploader="clickUploader"
              @removeSelfie="removeSelfie"
              @input="selectImg($event, valid)"
            />
            <j-alert v-if="!saving && activePrizeText && submitted && !shared" type="warning" :icon="false" rounded="xl" class="text-center my-4">
              <h3 class="mb-2">You're getting 1 entry ☝️</h3>
              <span>Get a <strong>bonus entry</strong> by sharing your photo to a Group or Chat</span>
            </j-alert>
          </div>
        </div>
        <v-row v-if="eventProperties.timeRequired">
          <v-col cols="12" class="py-0">
            <span class="secondary--text font-weight-bold">DURATION</span>
            <j-input-mask
              v-model="activity.seconds"
              :value="activity.seconds"
              :mask="['##:##:##']"
              masked
              placeholder=""
              suffix="HH:MM:SS or MM:SS"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="my-0 py-4">
            <share-options
              v-if="activity.image"
              :image="activity.image"
              :date="date"
              :activity="eventProperties.name"
              :chatData="chatData"
              :with-groups="true"
              class="mt-4"
            />
            <j-checkbox v-model="showMoreFields" label="Show more fields" class="ml-4" />
          </v-col>
        </v-row>
        <v-row v-if="showMoreFields" class="text-left mt-6">
          <v-col v-if="workoutType == 'run'" cols="12" class="py-0">
            <span class="secondary--text font-weight-bold">DISTANCE</span>
            <j-text-field
              class="session-logging-field"
              persistent-hint
              hint="KM"
              type="number"
              @wheel="$event.target.blur()"
              :rules="[rules.decimal]"
              v-model="activity.distance"
            />
          </v-col>
          <v-col v-if="workoutType == 'run'" cols="12" class="py-0 pb-2">
            <span class="secondary--text font-weight-bold">AVERAGE PACE</span>
            <j-text-field
              class="session-logging-field"
              persistent-hint
              hint="MIN/KM"
              type="text"
              :rules="[runningRule]"
              v-model="activity.averagePace"
            />
          </v-col>
          <v-row v-if="!eventProperties.timeRequired" class="mx-0">
            <v-col cols="12" class="pb-4">
              <span class="secondary--text font-weight-bold">DURATION</span>
              <j-input-mask
                v-model="activity.seconds"
                :value="activity.seconds"
                :mask="['##:##:##']"
                masked
                placeholder=""
                suffix="HH:MM:SS or MM:SS"
              />
            </v-col>
          </v-row>
          <v-col cols="12" class="py-4">
            <span class="secondary--text font-weight-bold">CALORIES BURNED</span>
            <j-text-field
              class="session-logging-field"
              type="number"
              @wheel="$event.target.blur()"
              v-model="activity.calories"
              persistent-hint
              hint="CALORIES"
            />
          </v-col>
          <v-col cols="12" class="py-0">
            <span class="secondary--text font-weight-bold">AVERAGE HEART RATE</span>
            <j-text-field
              class="session-logging-field"
              type="number"
              @wheel="$event.target.blur()"
              v-model="activity.heartRate"
              persistent-hint
              hint="BPM"
            />
          </v-col>
          <v-col cols="12" class="my-0 py-0" v-if="mode == 'update' && activity.rating">
            <j-alert dismissible type="info" class="ma-20">
              How you felt during the exercise has been replaced by our new effort rating. Try it out below.<br />
              Previous Rating: {{ activity.rating }}
            </j-alert>
          </v-col>
          <v-col cols="12" class="my-0 py-0 mt-2">
            <span class="secondary--text font-weight-bold">EFFORT YOU EXERTED</span>
            <v-select v-model="activity.effort" @change="updateEffortText" :items="ratingLabelsForm"> </v-select>
          </v-col>
          <v-col cols="12" class="py-0 text-right pa-0 ma-0" v-if="isFieldVisible().effort()">
            <span class="pa-0 ma-0">{{ ratingLabel }}</span>
          </v-col>
          <v-col cols="12" class="py-0">
            <span class="secondary--text font-weight-bold">NOTE TO SELF</span>
            <j-textarea v-model="activity.comment" rows="3" auto-grow />
          </v-col>
          <v-col cols="12" class="my-0 py-0">
            <span class="secondary--text font-weight-bold d-block pb-2 ">
              DATE OF COMPLETION
            </span>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <j-text-field v-model="event_date" v-bind="attrs" v-on="on" class="pt-0 mt-0" readonly></j-text-field>
              </template>
              <v-date-picker v-if="date" v-model="date" no-title scrollable @input="menu = false"> </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" align="center" justify="center">
            <j-alert v-if="errors && errors.message && errors.message.length > 0" type="error" class="mb-3">
              {{ errors.message[0] }}
            </j-alert>
            <j-alert v-if="!activePrizeText && submitted" type="success">
              {{ submitMessage }}
            </j-alert>
            <div v-if="eventProperties.timeRequired">
              Time spent is required for this event.
            </div>
            <j-btn
              secondary
              large
              class="mt-1 mb-4"
              :loading="saving"
              :disabled="timeRequiredValidation()"
              @click="
                pristine ? validate() : null;
                save(valid);
              "
            >
              <strong class="white-font">
                {{ userActivityId === "0" ? "Done" : "Update" }}
                <span v-if="chatData.isSharing"> and send</span>
              </strong>
            </j-btn>
          </v-col>
        </v-row>
        <div v-if="!$apollo.loading && shared" class="mt-4">
          <successful-entry-alerts v-if="activePrizeText" :active-prize-text="activePrizeText" />
        </div>
      </v-form>
    </validation-observer>
  </v-container>
</template>

<script>
import ActivityFormBase from "@/mixins/ActivityFormBase";
import SelfieAlert from "./SelfieAlert";
import SuccessfulEntryAlerts from "@/components/tracker/activity-form/SuccessfulEntryAlerts.vue";

export default {
  name: "ContractedActivityForm",
  mixins: [ActivityFormBase],
  components: {
    SelfieAlert,
    SuccessfulEntryAlerts
  },
  data() {
    return {
      requiredTimeErrors: null,
    };
  },
  computed: {
    inActive() {
      return this.$store.getters.role == "INACTIVE" || this.$store.getters.inActive;
    },
  },
  methods: {
    validateTime() {
      let duration = this.getDurationInSeconds();
      let valid = !isNaN(duration) && duration > 0;

      this.requiredTimeErrors = !valid ? ["Time spent is required"] : [];
    },
    timeRequiredValidation() {
      if (this.eventProperties.timeRequired) {
        return !(this.requiredTimeErrors && this.requiredTimeErrors.length == 0);
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
.workout-form .j-text-field__details {
  position: absolute;
  right: 0;
  margin: 22px;
}
.feeling {
  width: 100%;
  margin-top: 8px;
}
.workout-status {
  padding: 0px;
  margin: 0px;
  color: #fff;
}
.white-font {
  color: #fff !important;
}
.smaller-dashboard-button {
  color: #fff !important;
  width: 150px;
}
</style>
