<template>
  <v-skeleton-loader v-if="$apollo.loading" type="card-heading" />
  <div v-else>
    <div class="d-flex justify-space-between">
      <h4>Stress</h4>
      <v-switch class="py-0 my-0" hide-details :input-value="stressEnabled" @change="updateStressSetting($event)" />
    </div>
    <logging-selection-button
      v-if="stressEnabled"
      :key="formattedDate + 'mindset'"
      :optionList="options.stress"
      :value="mindsetLog"
      type="mindset"
      @value="mindsetLog = $event"
      class="reflection-logging-button my-2 pb-4"
    />
  </div>
</template>

<script>
import { MINDSET_QUERY } from "@/graphql/queries/tracker";
import { REFLECTION_OPTIONS } from "@/constants/json/reflectionOptions";
import { mapGetters } from "vuex";
import LoggingSelectionButton from "@/components/tracker/buttons/LoggingSelectionButton";
import UserMixin from "@/mixins/UserMixin";

export default {
  name: "StressTracker",
  mixins: [UserMixin],
  components: {
    LoggingSelectionButton,
  },
  data() {
    return {
      stress: null,
      options: REFLECTION_OPTIONS,
      loadingStress: true,
    };
  },
  computed: {
    ...mapGetters(["selectedDate"]),
    stressEnabled() {
      return this.settingEnabled("stress-tracking");
    },
  },
  methods: {
    formattedDate() {
      return this.selectedDate ? this.selectedDate.format("YYYY-MM-DD") : this.$moment().format("YYYY-MM-DD");
    },
    updateStressSetting(value) {
      this.upsertSetting("stress-tracking", value);
    },
  },
  apollo: {
    mindsetLog: {
      query: MINDSET_QUERY,
      variables() {
        return {
          date: this.formattedDate(),
        };
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        this.loadingStress = false;
        if (data.mindsetLog.edges && data.mindsetLog.edges.length > 0) {
          return data.mindsetLog.edges[0].node.rating;
        } else {
          return null;
        }
      },
    },
  },
};
</script>
