<template>
  <v-row>
    <v-col cols="12">
      <j-card class="j-elevated-2 ">
        <v-card-title class="secondary--text">
          To do
          <v-spacer />
          <v-badge overlap class="mr-4" color="pink-background">
            <template v-slot:badge>
              <div class="pink--text">{{ $store.getters.tasks }}</div>
            </template>
          </v-badge>
        </v-card-title>
        <todo-items />
      </j-card>
      <div class="text-decoration-none ma-1 font-weight-bold text-right heading-6 mt-6">
        <a @click="skip()">Skip for later <v-icon color="navy" size="16">mdi-arrow-right</v-icon></a>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import TodoItems from "@/components/todo/TodoItems";

export default {
  name: "TodoBlock",
  components: {
    TodoItems,
  },
  methods: {
    skip() {
      let skipTasks = { ...this.$store.getters.skipTasks };
      skipTasks.lastSkip = this.$moment().format("YYYY-MM-DD HH:mm:ss");
      skipTasks.taskCount = this.$store.getters.tasks;
      skipTasks.skipped = true;
      this.$store.dispatch("updateSkipTasks", skipTasks);
      this.$emit("skip");
    },
  },
};
</script>
