import gql from "graphql-tag";

export const UPSERT_BOOKMARK_MUTATION = gql`
  mutation upsertBookmark($objectId: ID!, $objectType: String!) {
    upsertBookmark(objectId: $objectId, objectType: $objectType) {
      error
      success
      bookmark {
        id
      }
    }
  }
`;

export const ADD_BOOKMARK_NOTE_MUTATION = gql`
  mutation addBookmarkNote($bookmarkId: ID!, $notes: String) {
    addBookmarkNote(bookmarkId: $bookmarkId, notes: $notes) {
      error
      success
    }
  }
`;

export const DELETE_BOOKMARK_MUTATION = gql`
  mutation deleteBookmark($bookmarkId: ID!) {
    deleteBookmark(bookmarkId: $bookmarkId) {
      error
      success
    }
  }
`;
