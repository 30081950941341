import gql from 'graphql-tag'

export const ARTICLE_QUERY = gql`
query article($slug: String!) {
  article(slug: $slug) {
    title
    slug
    bannerImage
    content
    allowOpenAccess
    isActive
    buttonText
    buttonUrl
    buttonOpenNewTab
    pdf
    youtubeVideo
  }
}
`
