<template>
  <j-banner-container
    header="Fresh Classes"
    md="6"
    :gradient="['red-background', 'yellow-background']"
    :secondaryBtn="{
      to: '/on-demand',
      text: 'On Demand',
      icon: 'mdi-animation-play',
    }"
    show-share-link
    use-header-in-link
  >
    <div class="px-2"><calendar-dots class="mt-4" /></div>
    <div v-if="$apollo.loading && events.length == 0">
      <div v-for="count of 3" :key="count" class="d-flex flex-row mx-5 my-2">
        <v-skeleton-loader type="image" class="skeleton-size" />
        <v-skeleton-loader type="list-item-three-line, actions" class="flex" />
      </div>
    </div>

    <!-- LIVE events -->
    <div v-if="!$apollo.loading && events.length > 0" class="mt-3">
      <v-row>
        <v-col v-for="(item, index) in events" :key="index" cols="12">
          <event-card
            :event="item"
            :activityLogged="item.useractivitySet.edges && item.useractivitySet.edges.length < 1"
          />
        </v-col>
      </v-row>
    </div>

    <!-- FRESH events -->
    <div v-if="!$apollo.loading && freshClasses && freshClasses.length > 0" class="text-left mt-1">
      <v-row>
        <v-col>
          <span class="std-text navy--text font-weight-bold">This week's fresh classes</span>
          <v-row>
            <v-col v-for="(item, index) in freshClasses" :key="index" cols="12">
              <event-card
                v-if="item.featureForWeek"
                :event="item"
                :activityLogged="item.useractivitySet.edges && item.useractivitySet.edges.length < 1"
                :show-time="false"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <v-alert text prominent type="success" icon="mdi-cloud-alert" class="mx-4 mt-4 text-left"
      >For structured programs, browse our catalog of
      <router-link to="/search" class="success--text text-decoration-underline">
        programs, menus and courses</router-link
      >.
    </v-alert>

    <v-row v-if="events.length > 3" align="center" justify="center">
      <v-col cols="2">
        <scroll-to-top-btn />
      </v-col>
    </v-row>
  </j-banner-container>
</template>

<script>
import { SCHEDULED_EVENTS_QUERY } from "@/graphql/queries/events";
import { FRESH_CLASSES } from "@/graphql/queries/home";
import { convertSATimeToLocalTime } from "@/lib/timezone";
import { mapActions, mapGetters } from "vuex";
import CalendarDots from "@/components/shared/blocks/CalendarDots";
import EventCard from "@/components/events/EventCard";
import ScrollToTopBtn from "@/layouts/buttons/ScrollToTopBtn";

export default {
  name: "Events",
  components: {
    CalendarDots,
    EventCard,
    ScrollToTopBtn,
  },
  data() {
    return {
      events: [],
      loading: true,
      freshClasses: [],
    };
  },
  mounted() {
    window.analytics.page("Live Events");
  },
  computed: {
    ...mapGetters(["selectedDate", "role"]),
    inActive() {
      return this.role == "INACTIVE" || this.$store.getters.inActive;
    },
  },
  methods: {
    ...mapActions(["updateRestDay", "setRestDays"]),
  },
  apollo: {
    events: {
      query: SCHEDULED_EVENTS_QUERY,
      variables() {
        return {
          scheduledDate: this.selectedDate
            ? this.selectedDate.format("YYYY-MM-DD")
            : this.$moment().format("YYYY-MM-DD"),
          excludeCategories: ["Dance", "Prehab", "Warm-up", "Booster"],
          featureForWeek: false,
          hideOnWebApp: false,
        };
      },
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data) {
          this.loading = false;
        }
      },
      update(data) {
        let dateFormatted = data.events.map((event) => {
          event.startTime = convertSATimeToLocalTime(event.startTime);
          event.endTime = convertSATimeToLocalTime(event.endTime);
          return event;
        });
        return dateFormatted;
      },
    },
    freshClasses: {
      query: FRESH_CLASSES,
      fetchPolicy: "network-only",
      variables() {
        return {
          scheduledDate_Lte: this.$moment(this.selectedDate).endOf("isoweek").subtract(1, "days"),
          scheduledDate_Gte: this.$moment(this.selectedDate).startOf("isoweek").subtract(1, "days"),
          first: 12,
          featureForWeek: true,
          hideOnWebApp: false,
        };
      },
      update(data) {
        return data.paginatedEvents.edges.map((edge) => edge.node);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.skeleton-size {
  height: 140px;
  width: 130px;
}
</style>
