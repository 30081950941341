<template>
  <j-card rounded="lg" class="pb-4">
    <v-toolbar class="mb-6">
      <v-toolbar-title>Confirm leaving of Chat</v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="$emit('closed')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <p>Are you sure you want to leave <strong>{{chat.title}}</strong>?</p>
    </v-card-text>
    <v-card-actions class="justify-center">
      <j-btn secondary @click="confirm()">
        <strong class="white--text">Confirm</strong>
      </j-btn>
    </v-card-actions>
  </j-card>
</template>

<script>
export default {
  name: "ConfirmLeaveChat",
  props: {
    chat: {
      type: Object,
      required: true,
    },
  },
  methods: {
    confirm() {
      this.$emit("confirm", this.chat);
    },
  },
};
</script>
