<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" sm="8">
        <j-card class="text-left mb-4 pa-2 j-elevated-2">
          <back-link baseRoute="/badges" />
          <div class="image-block">
            <div class="text-center" v-if="loading">
              <v-progress-circular :size="50" indeterminate class="ma-7" color="secondary"> </v-progress-circular>
            </div>
            <v-img
              :alt="badge.title"
              class="shrink mx-auto"
              contain
              v-on:load="loading = false"
              min-width="100"
              :src="badge.iconLink"
              width="100"
            />
          </div>
          <v-card-title class="secondary--text badge-title" align="center" justify="center">{{
            badge.title
          }}</v-card-title>

          <v-card-text>
            <j-alert type="success">
              You've earned this badge
            </j-alert>

            <span class="secondary--text">How to earn the badge</span>
            <v-spacer />
            <span class="card-opacity">{{ badge.description }}</span>
          </v-card-text>
        </j-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { BADGE_QUERY } from "@/graphql/queries/badges";
import BackLink from "@/layouts/BackLink";

export default {
  name: "Badge",
  props: ["id"],
  components: {
    BackLink,
  },
  data() {
    return {
      badge: {},
      loading: true,
    };
  },
  apollo: {
    badge: {
      query: BADGE_QUERY,
      variables() {
        return {
          id: this.id,
        };
      },
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data) {
          this.badge = response.data.badge;
        }
      },
      update: (data) => data.badge,
    },
  },
};
</script>

<style scoped>
.badge-title {
  display: block;
}

.image-block {
  height: 100px;
}
</style>
