<template>
  <calendar-dot
    v-bind="$props"
    v-on="$listeners"
    :class="{ 'white-background': setWhiteBackground }"
    class="dot-content"
  >
    <template v-slot:dot-content class="dot-content">
      <span v-if="logged" class="white--text nutrition-no heading-4">
        {{ nutritionLogged }}
      </span>
      <span v-else-if="nutritionLogged" class="green--text nutrition-no heading-4">
        {{ nutritionLogged }}
      </span>
    </template>
  </calendar-dot>
</template>

<script>
import CalendarDot from "./CalendarDot";

export default {
  name: "NutritionCalendarDot",
  extends: CalendarDot,
  components: {
    CalendarDot,
  },
  props: {
    nutritionLogged: {
      type: Number,
      required: false,
    },
    setWhiteBackground: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.nutrition-no {
  position: absolute;
  z-index: 2;
  top: 9px;
}
</style>

<style lang="scss">
.dot-content .d-flex {
  justify-content: center;
}
</style>
