export const VOUCHER_ERRORS = {
  NO_APPLICABLE_PRODUCTS: "The voucher you have entered is not applicable to the selected products",
  DOES_NOT_EXIST: "The voucher you have entered does not exist",
  NOT_APPLICABLE: "The voucher you have entered is not applicable",
  EXPIRED: "The voucher you have entered has expired",
  MAX_USES_EXCEEDED: "The voucher you have entered is not applicable",
  PROMO_HIGHER:
    "The active promotion gives you a higher discount so we're applying the promotion to your purchase instead of your voucher",
  NEW_USERS_ONLY: "The voucher can only be redeemed by new users",
  INVALID_SUBSCRIPTION: "The voucher is not valid for your subscription",
};
