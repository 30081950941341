import gql from 'graphql-tag'

export const USER_BADGES_QUERY = gql`
query userBadgesQuery {
  userBadges {
    createdAt
    badge {
      id
      title
      description
      iconLink
      
    }
    coachComment
    awardedBy {
      userProfile {
        id
        profilePicture
      }
    }
  }
}
`

export const BADGES_QUERY = gql`
query badgesQuery {
  badges {
    id
    title
    isEnabled
    startDate
    endDate
    isVisible
    description
    iconLink
    graceDaysVisible
    graceDaysAchievable
    type
    functionValues
  }
}
`

export const BADGE_QUERY = gql`
query badgeQuery($id: ID!) {
  badge(id: $id) {
    title
    description
    iconLink
    startDate
    endDate
    type
    functionValues
  }
}
`

export const EVENT_BADGE_QUERY = gql`
query eventBadgeQuery($id: ID!) {
  event(id: $id) {
    name
    scheduledDate
  }
}
`

export const CHALLENGE_BADGE_QUERY = gql`
query challengeBadgeQuery($badgeId: ID!) {
  progress(badgeId: $badgeId) {
    progress
  }
}
`

export const AWARDED_BADGES_QUERY = gql`
  query awardedBadges($userId: ID!, $first: Int){
    awardedBadges(userId: $userId, first: $first){
      edges {
        node {
          id 
          createdAt
          coachComment
          awardedBy {
            id
            firstName
            lastName
          }
          badge {
            id
            title
            description
            iconLink
          }
        }
      }
    }
  }`
