<template>
  <div class="d-flex justify-space-between align-center">
    <div class="d-flex align-center">
      <v-avatar :size="small ? 36 : 48" class="ml-3 my-auto" color="white">
        <v-img
          v-if="instructorProfilePic"
          :src="instructorProfilePic"
          :lazy-src="instructorProfilePic"
          :height="small ? 36 : 48"
          :max-width="small ? 36 : 48"
          :aspect-ratio="1 / 1"
        />
      </v-avatar>

      <div class="text-left pl-2">
        <div v-if="topHeading" class="d-flex align-center">
          <div class="font-weight-bold sm-text pt-1">
            {{ topHeading }}
          </div>
          <div><v-icon v-if="hasDot" class="ml-2" color="exercise">fiber_manual_record</v-icon></div>
        </div>

        <div class="font-weight-bold navy--text">{{ title | shortened(small) }}</div>
        <div v-if="instructorName" class="grey-navy--text sm-text">
          {{ instructorName }}
        </div>
      </div>
      <div v-if="duration" class="d-flex flex-fill justify-end align-end mx-4">
        <v-chip small color="grey-info" label class="font-weight-bold white--text text-body-2">{{ duration }}</v-chip>
      </div>
    </div>

    <div v-if="(buttonText && !$vuetify.breakpoint.smAndDown) || (buttonText && !duration)" class="mr-4">
      <j-btn @click="$emit('navigate')" tertiary narrow small>{{ buttonText }}</j-btn>
    </div>
  </div>
</template>

<script>
export default {
  name: "ArrowRowContent",
  props: {
    instructorProfilePic: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    instructorName: {
      type: String,
      required: false,
    },
    duration: {
      type: String,
      required: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      required: false,
    },
    topHeading: {
      type: String,
      required: false,
    },
    hasDot: {
      type: Boolean,
      default: false,
    },
  },
  filters: {
    shortened(value, small) {
      const maxLength = small ? 15 : 30;
      if (value?.length > maxLength) return `${value.substring(0, maxLength)}...`;
      return value;
    },
  },
};
</script>
