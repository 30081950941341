<template>
  <v-bottom-navigation v-model="value" bottom app color="green" class="elevation-15">
    <v-btn to="/start/pricing">
      <span :style="textGradientStyle">Home</span>
      <v-icon size="32" :style="textGradientStyle">mdi-home</v-icon>
    </v-btn>
  </v-bottom-navigation>
</template>

<script>
import { gradientStyle } from "@/lib/gradientStyle";

export default {
  name: "BottomNav",
  data() {
    return {
      value: null,
    };
  },
  computed: {
    textGradientStyle() {
      return gradientStyle(["yellow", "green"], 90);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/global.scss";

.v-bottom-navigation--fixed {
  position: fixed !important;
}

.v-item-group.v-bottom-navigation .v-btn {
  height: inherit !important;
  background-color: #fff !important;
}

.v-btn span {
  text-transform: uppercase;
}

.v-btn--active .v-btn__content span,
.v-btn--active .v-btn__content .v-icon {
  @extend .gradient-text;
  font-weight: bold;
}
</style>
