<template>
  <v-progress-linear v-if="this.$apollo.loading" :indeterminate="true" />
  <v-container v-else>
    <v-row>
      <v-col class="text-left pb-0 py-0 mt-5">
        <p class="heading-3 mb-0">{{ $moment(date, "YYYY-MM-DD").format("MMM YYYY") }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 mt-3 mb-5">
        <v-calendar :value="date" type="month" :weekdays="[1, 2, 3, 4, 5, 6, 0]">
          <template v-slot:day-label="{ date }" class="mx-10">
            <v-avatar :color="getIconColor(date)" size="30" class="my-1"></v-avatar>
          </template>
        </v-calendar>
        <p class="mt-2">{{ getLabel() }}</p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { CONSISTENCY_QUERY } from "@/graphql/queries/stats";

export default {
  name: "ConsistencyCalendar",
  props: {
    date: {
      type: String,
      required: true,
    },
    mode: {
      type: String,
      required: true,
      default: "EXERCISE",
    },
  },
  methods: {
    getIconColor(date) {
      switch (this.mode) {
        case "EXERCISE":
          if (this.consistency.exercise[date] === "EXERCISE") return "exercise";
          else if (this.consistency.exercise[date] === "REST") return "grey";
          return "transparent";
        case "NUTRITION":
          return this.consistency.nutrition[date] ? "nutrition" : "transparent";
        case "MINDSET":
          return this.consistency.mindset[date] ? "mindset" : "transparent";
      }
    },
    getLabel() {
      let c = 0;
      let k = this.mode.toLowerCase();
      Object.keys(this.consistency[k]).forEach((element) => {
        if (this.consistency[k][element]) {
          c++;
        }
      });
      return `You logged ${c} out of ${Object.keys(this.consistency.exercise).length} days`;
    },
  },
  apollo: {
    consistency: {
      query: CONSISTENCY_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          month: this.date,
        };
      },
    },
  },
};
</script>
