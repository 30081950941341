<template>
  <v-container class="d-flex justify-center">
    <div>
      <j-alert>You are currently offline.</j-alert>
      <j-btn href="/today"> Try again </j-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "Offline",
};
</script>
