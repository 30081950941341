<template>
  <div>
    <v-card
      @click="showNutritionModal = true"
      class='pa-0 card-radius'>
      <v-toolbar flat class="pa-0 ma-0">
        <span
          v-if="!nutritionLog"
          class="font-weight-bold secondary--text">
          Log my nutrition 
        </span>
        <span
          v-if="nutritionLog && nutritionLog.isNutritiousDay"
          class="font-weight-bold secondary--text">
          Green Dot Day {{ dateLabel }} 
        </span>
        <span
          v-if="nutritionLog && !nutritionLog.isNutritiousDay"
          class="font-weight-bold secondary--text">
          Nutrition logged {{ nutritionLog.truthyQuestions }}/{{ nutritionLog.totalQuestions }} {{ dateLabel }}
        </span>
        <v-spacer />
        <span class="pt-0 pr-1">Nutrition</span>
        <v-icon v-if="nutritionLog && nutritionLog.isNutritiousDay" color="nutrition">fiber_manual_record</v-icon>
        <v-icon v-else color="nutrition">radio_button_unchecked</v-icon>
      </v-toolbar>
    </v-card>
    <v-dialog
      v-model="showNutritionModal"
      max-width="600px"
    >
      <v-card class="pa-2 card-radius">
        <v-btn icon color="text-grey" class="float-right" @click="showNutritionModal = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-card-title class="mt-1 py-0">
          <span class="secondary--text" style="margin: 0px auto;">Log Nutrition</span>
        </v-card-title>

        <nutrition-logging :nutrition-log="nutritionLog" :key="setKey()" @updated="onUpdated" />

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { USER_NUTRITION_LOG_QUERY } from '@/graphql/queries/nutrition'
  import NutritionLogging from '@/components/shared/blocks/NutritionLogging'
  import { mapGetters } from 'vuex'

  export default {
    props: {
      showDate: {
        type: Boolean,
        required: false,
      },
      closeOnUpdated: {
        type: Boolean,
        required: false,
        default: false,
      },
      // in case we pass a prop into this component and don't compute it. 
      nutritionLogProp: {
        type: Object,
        required: false,
      }
    },
    name: "NutritionCard",
    components: {
      NutritionLogging,
    },
    data: () => ({
      showNutritionModal: false,
      nutritionLog: null,
    }),
    computed: {
        ...mapGetters(['selectedDate']),
      dateLabel() {
        if(this.showDate) {
          return ` - ${this.$moment(this.nutritionLog.date).format("ddd, D MMMM YYYY")}`
        }
        return ''
      },
    },
    methods: {
      onUpdated(data) {
        if(data == 'updated' && this.closeOnUpdated) {
          this.showNutritionModal = false
        }
      },
      setKey() {
        if(this.nutritionLog && this.nutritionLog.date) {
          return this.nutritionLog.date
        }
        if(this.selectedDate) {
          return this.selectedDate.format('YYYY-MM-DD')
        } 
        return 1
      }
    },
    apollo: {
      nutritionLog: {
        query: USER_NUTRITION_LOG_QUERY,
        fetchPolicy: 'cache-and-network',
        variables() {
          let date
          if(this.nutritionLogProp) {
            date = this.nutritionLogProp.date
          } else if (this.selectedDate) {
            date = this.selectedDate.format("YYYY-MM-DD")
          } else {
             date = this.$moment().format("YYYY-MM-DD") 
          }
          return {
            date:  date
          }
        },
        result(response) {
          if (response.data) {
            this.loading = false
          }
        },
        update: (data) => {
          if (data.nutritionLog.edges.length > 0) {
            return data.nutritionLog.edges[0].node;
          }
          return null;
        },
      }
    }
  }
</script>

