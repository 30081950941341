import { USER_ACTIVITY_QUERY, ACTIVITY_TYPES_QUERY } from "@/graphql/queries/tracker";
import { UPSERT_ACTIVITY_MUTATION } from "@/graphql/mutations/tracker";
import { ACTIVE_PRIZE_QUERY } from "@/graphql/queries/prizeCalendar";
import { COLORGRADIENT } from "@/lib/ColorGradient";
import { toLocalString } from "@/lib/timezone";
import { runningRule } from "@/lib/validationRules";
import { dataURLtoBlob, blobToFile } from "@/lib/file";
import { decodeId, secondsToTimeString } from "@/lib/string";
import { maskedTimeToSeconds } from "@/lib/string";
import { getMonday } from "@/lib/calendarWeek";
import ShareOptions from "@/components/tracker/activity-form/ShareOptions";
import BadgeToaster from "./BadgeToaster";
import SendToChatsGroupsMixin from "@/mixins/nutrition/SendToChatsGroupsMixin";

export default {
  name: "ActivityFormBaseMixin",
  mixins: [BadgeToaster, SendToChatsGroupsMixin],
  components: {
    ShareOptions,
  },
  props: {
    onSave: {
      type: Function,
      default: null,
    },
    workoutType: {
      type: String,
      default: null,
    },
    // this is so that we can have a workoutType=workout but secondaryType=run, to show running inputs.
    secondaryType: {
      type: String,
      default: "",
    },
    userActivityId: {
      type: [String, Number],
      default: 0,
    },
    workoutId: {
      type: String,
      default: null,
    },
    eventProperties: {
      type: Object,
      default: null,
    },
    defaultDate: {
      type: String,
      default: toLocalString(new Date(), false),
    },
    fullForm: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      activity: {},
      activityId: this.userActivityId,
      customActivity: false,
      categories: {},
      otherCategoryIds: [],
      customWorkoutProps: {
        selectedWorkoutType: "",
        customWorkoutType: "",
      },
      loading: true,
      imageLoading: false,
      image: null,
      imageChanged: false,
      saving: false,
      error: false,
      submitted: false,
      shared: false,
      imageUploadFailure: false,
      submitMessage: "Well done!",
      ratingLabels: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      ratingLabel: "",
      ratingColor: "red",
      rules: {
        decimal: (value) => {
          if (!value) return true;
          let regexp = /^[0-9]+(\.[0-9]{1,2})?$/;
          return regexp.test(value);
        },
      },
      runningRule: runningRule,
      date: this.defaultDate,
      menu: false,
      duration: {
        h: "",
        m: "",
        s: "",
      },
      showMoreFields: true,
    };
  },
  computed: {
    event_date: {
      get() {
        let date = this.$moment(this.date, "YYYY-MM-DD");
        let today = this.$moment();
        let dayDelta = today.diff(date, "days", true);
        let dateString = this.$moment(this.date, "YYYY-MM-DD").format("ddd, D MMMM YYYY");
        if (dayDelta >= 0) {
          if (dayDelta > 1 && dayDelta < 2) {
            dateString = "Yesterday: " + dateString;
          } else if (dayDelta > 0 && dayDelta < 1) {
            dateString = "Today: " + dateString;
          } else dateString = Math.floor(dayDelta.toString()) + " days ago: " + dateString;
        }
        return dateString;
      },
      set(newValue) {
        this.date = newValue;
      },
    },
    mode() {
      return this.userActivityId === 0 ? "new" : "update";
    },
    ratingLabelsForm() {
      let labels = this.ratingLabels.reverse().map((value) => {
        return { text: value, value: value };
      });
      return labels;
    },
    runActivityType() {
      if (this.categories) {
        let run = this.categories["EXERCISE"].filter((category) => category.text.toLowerCase() == "run")[0];
        return run.value;
      }
    },
    eventType() {
      return this.activity?.event?.eventType?.category;
    },
  },
  methods: {
    setActivityTypeOptions() {
      let options = this.categories[this.categoryName];
      if (this.userActivityId !== 0) {
        if (this.activity.type) this.customWorkoutProps.selectedWorkoutType = this.activity.type.id;
        else this.customWorkoutProps.selectedWorkoutType = options[0].value;
      } else this.customWorkoutProps.selectedWorkoutType = options[0].value;

      this.categoryActivities = options;
    },
    updateEffortText() {
      this.ratingColor = COLORGRADIENT.generateGradientColor(this.activity.effort - 1, 10);
      if (this.activity.effort == 10) this.ratingLabel = "You're killing it";
      else if (this.activity.effort > 7) this.ratingLabel = "Going hard";
      else if (this.activity.effort > 5) this.ratingLabel = "Out of your comfort zone";
      else if (this.activity.effort === null) this.ratingLabel = "";
      else this.ratingLabel = "";
    },
    getDurationInSeconds() {
      return Math.floor(
        (parseInt(this.duration.s) || 0) +
          (parseInt(this.duration.m) || 0) * 60 +
          (parseInt(this.duration.h) || 0) * 3600
      );
    },
    isFieldVisible() {
      return {
        distance: () => {
          if (["MINDSET", "NUTRITION"].includes(this.categoryName)) {
            return false;
          }
          let supportedWorkoutTypes = [
            "Run",
            "Cycle",
            "Swim",
            "Walk",
            "Other",
            "Running",
            "Swimming",
            "Cycling",
            "Walking",
          ];
          return supportedWorkoutTypes.includes(this.getActivityName());
        },
        effort: () => {
          if (["MINDSET", "NUTRITION"].includes(this.categoryName)) {
            return false;
          }
          return true;
        },
        run: () => {
          return this.getActivityName().toLowerCase() == "run";
        },
      };
    },
    clickUploader() {
      document.getElementById("selfie-image-uploader").click();
    },
    removeSelfie() {
      this.imageLoading = true;
      this.image = null;
      this.imageChanged = true;
      this.save(true);
    },
    async selectImg(data, valid = true) {
      this.imageLoading = true;
      const blob = dataURLtoBlob(data.dataUrl);
      const file = blobToFile(blob);

      this.image = file;
      this.imageChanged = true;
      this.save(valid);
    },
    save(valid, close = false) {
      if (valid) {
        this.saving = true;
        let userActivityInput = {
          isCustom: false,
          distance: this.activity.distance || null,
          averagePace: this.activity.averagePace || null,
          calories: this.activity.calories || null,
          seconds: maskedTimeToSeconds(this.activity.seconds),
          heartRate: this.activity.heartRate || null,
          startDate: this.date,
          effort: this.activity.effort || null,
          comment: this.activity.comment || null,
          image: this.image || null,
          imageChanged: this.imageChanged || false,
          time: this.$moment().format("HH:mm:ss"),
        };

        switch (this.workoutType) {
          case "event":
            userActivityInput["eventId"] = decodeId(this.workoutId);
            break;
          case "run":
            userActivityInput["guidedRunId"] = decodeId(this.workoutId);
            break;
          case "workout":
            userActivityInput["actionId"] = decodeId(this.workoutId);
        }

        if (this.activity.rating) {
          userActivityInput["rating"] = this.activity.rating;
        }

        this.upsertUserActivity(userActivityInput, close);
      }
    },
    async upsertUserActivity(userActivityInput, close = false) {
      let variables = {
        data: userActivityInput,
      };
      if (this.activityId != 0) {
        variables["data"]["id"] = decodeId(this.activityId);
      }
      if (this.customActivity) {
        variables["typeId"] = this.customWorkoutProps.selectedWorkoutType;
      }
      if (this.secondaryType == "RUN") {
        variables["typeId"] = this.runActivityType;
      }

      await this.$apollo
        .mutate({
          mutation: UPSERT_ACTIVITY_MUTATION,
          variables: variables,
          refetchQueries: [
            "activitiesQuery",
            "activitiesListQuery",
            "userDotsQuery",
            "userStatsQuery",
            "activityQuery",
            "prizeEligibilityQuery",
            "assistantQuery",
            "accountQuery",
            "event",
          ],
        })
        .then(async (response) => {
          if (response.data) {
            this.activityId = response.data.upsertUserActivity?.upsertedUserActivity?.userActivity?.id;
            this.submitted = true;
            this.submitMessage = "Well done! Session updated successfully.";
            window.analytics.track("Activity Upserted", this.eventProperties);

            if (this.onSave && !this.imageChanged) {
              this.onSave(this.activityId);
            }

            if (response.data.upsertUserActivity.upsertedUserActivity.earnedBadges) {
              let badges = response.data.upsertUserActivity.upsertedUserActivity.earnedBadges;
              this.toastBadges(badges);
            }

            if (
              this.activity.image &&
              (this.chatData.sendToChats?.length > 0 || this.chatData.sendToGroups?.length > 0)
            ) {
              await this.sendMessages(this.chatData, this.activity.image);
              this.shared = true;
            }

            this.saving = false;
            this.showMoreFields = false;
            if (close) {
              this.$emit("activityLogged", this.activityId);
              this.$emit("close");
            }
          }
        })
        .catch((error) => {
          this.saving = false;
          this.error = true;
          throw error;
        });
    },
    handleData() {
      this.loading = false;
      this.updateEffortText();
      if (this.activity.seconds && !isNaN(this.activity.seconds)) {
        this.activity.seconds = secondsToTimeString(this.activity.seconds);
      }
      if (this.activity.startDate) {
        this.date = this.activity.startDate.substr(0, 10);
      }
      this.imageLoading = false;
    },
  },
  apollo: {
    activity: {
      query: USER_ACTIVITY_QUERY,
      variables() {
        return {
          id: this.activityId,
        };
      },
      fetchPolicy: "cache-and-network",
      skip() {
        return this.activityId == 0;
      },
      result(response) {
        if (response.data) {
          this.handleData();
        }
      },
    },
    activityTypes: {
      query: ACTIVITY_TYPES_QUERY,
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.loading) return;

        if (response.data) {
          let categories = {};
          response.data.activityTypes.forEach((item) => {
            if (item.category != "OTHER") {
              if (!categories[item.category]) categories[item.category] = [];
              categories[item.category].push({ text: item.name, value: item.id });
            }
            if (item.name.toLowerCase() == "other") {
              this.otherCategoryIds.push(item.id);
            }
          });
          this.categories = categories;
          this.setActivityTypeOptions();
        }
      },
      skip() {
        return !(this.customActivity || this.secondaryType == "RUN");
      },
    },
    activePrizeText: {
      query: ACTIVE_PRIZE_QUERY,
      variables() {
        return {
          date: this.$moment(getMonday(this.$moment().format("YYYY-MM-DD"))).format("YYYY-MM-DD"),
        };
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        let prize = data.activePrize;
        return prize?.entryPerSelfie ? prize?.entryPerSelfiePrizeText : null;
      },
    },
  },
};
