<template>
  <div v-if="image" style="position: relative;">
    <v-img :lazy-src="image" :src="image" class="pointer rounded-lg elevation-8" @click="$emit('open')" elevation="25"/>
    <v-chip small color="navy" class="celebration-chip">{{ type }}</v-chip>
    <v-btn icon @click="$emit('remove')" color="navy" class="remove">
      <v-icon color="navy" dark>mdi-close-circle</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "SelectedImageCard",
  props: {
    image: {
      type: String,
      requried: true,
    },
    type: {
      type: String,
      requried: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.celebration-chip {
  position: absolute;
  top: 5px;
  left: 5px;
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.remove {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
