import gql from "graphql-tag";

export const ACCOUNT_QUERY = gql`
  query accountQuery {
    me {
      id
      username
      firstName
      lastName
      email
      dateJoined
      isStaff
      usermedicalconditionSet {
        edges {
          node {
            note
            medicalCondition {
              id
              condition
            }
          }
        }
      }
      userSettings {
        id
        value
        key
      }
      userProfile {
        id
        user {
          id
        }
        activeSubscription {
          id
          acquisitionType
        }
        nutritionPlan {
          id
          pdf
        }
        externalCompany {
          id
          title
          recurringDiscount {
            id
            percent
            code
          }
        }
        phone
        handle
        nextPaymentDate
        dateOfBirth
        idNumber
        biography
        passportNumber
        isDiscoveryMember
        claimDiscount
        profilePicture
        gender
        dietaryRequirement
        weightLossGoal
        desiredWeight
        desiredWeightLoss
        calculatedServingSize
        suburb
        country {
          name
          iocCode
          code
        }
        extrovertScale
        fitnessLevel
        hasMedicalConditions
        height
        weight
        companyRole
        company
        companySize
        requiredSupport
        showInSearches
        pregnant
        breastfeeding
        trimester
        receiveChatNotifications
        inviteFriendDiscountCode
        weeklyGoalAchieved
        friendsCount
        previousSubscriptionType
        numberOfPosts
        userType
        gymBranch {
          id
          title
          image
          phone
          address
          position
          group {
            id
            title
            memberCount
            banner
          }
        }
        supportChat {
          id
        }
        coachingChat {
          id
        }
        coach {
          id
          firstName
          lastName
          currentCoachSecondaryPeriod {
            id
            startDate
            endDate
            outOfOffice
          }
          userProfile {
            id
            profilePicture
            phone
            assessmentBookingLink
            consultBookingLink
            biography
            youtubeIntroVideo
            coachIntroduction
            user {
              id
              isStaff
              isActive
            }
          }
        }
        corporateChallenge {
          id
          title
          logo
          article {
            id
            slug
          }
          primaryGroup {
            id
            title
          }
        }
      }
    }
    subscription {
      id
      startDate
      endDate
      active
      transitionProductVariant {
        id
        title
        product {
          id
          title
        }
      }
      coach {
        id
        firstName
        lastName
        userProfile {
          id
          profilePicture
          phone
        }
      }
      product {
        id
        name
        type
        programType
        requiresIdentification
      }
      billingOrder {
        id
        nextBillingDate
        nextPaymentAmount
        pausePaymentUntilDate
        currency
        paymentMethod
        subscriptionProductVariant {
          id
          code
          title
          product {
            id
            title
            code
          }
        }
        payments {
          id
          totalAmount
          createdAt
          tokenizedCard {
            id
            lastDigits
          }
        }
      }
    }
    tokenizedCard {
      id
      lastDigits
    }
    newNutritionPlan {
      id
      servingSize
      dietaryRestriction
      pdf
    }
    userNutritionPreferences {
      id
      nutritionPreference {
        id
        title
        type
        isPrimary
      }
    }
    upcomingNutritionBlock {
      id
      title
      startDate
      image
      groupSet {
        edges {
          node {
            id
          }
        }
      }
      leadInstructor {
        id
        firstName
        lastName
        userProfile {
          id
          profilePicture
        }
      }
      subscriptionnutritionblockSet {
        edges {
          node {
            id
            startDate
            nutritionBlock {
              id
              title
              leadInstructor {
                id
                firstName
                lastName
                userProfile {
                  id
                  profilePicture
                }
              }
              groupSet {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
    activeSubscriptionWorkoutBlocks {
      id
      startDate
      block {
        id
        title
      }
    }
    activeNutritionBlock {
      id
      title
      startDate
      image
      groupSet {
        edges {
          node {
            id
          }
        }
      }
      leadInstructor {
        id
        firstName
        lastName
        userProfile {
          id
          profilePicture
        }
      }
      subscriptionnutritionblockSet {
        edges {
          node {
            id
            startDate
            nutritionBlock {
              id
              title
              leadInstructor {
                id
                firstName
                lastName
                userProfile {
                  id
                  profilePicture
                }
              }
              groupSet {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
    tribes(userTribe: true, active: true) {
      edges {
        node {
          id
          title
          description
          banner
          icon
          stats {
            position
            dots
          }
          group {
            id
          }
          coach {
            id
            firstName
            lastName
            profile {
              id
              profilePic
            }
          }
          article {
            id
            slug
          }
        }
      }
    }
  }
`;

export const HANDLE_AVAILABLE_QUERY = gql`
  query handleAvailable($handle: String!) {
    handleAvailable(handle: $handle)
  }
`;

export const HANDLE_TO_USER_ID = gql`
  query handleToUserId($handle: String!) {
    handleToUserId(handle: $handle)
  }
`;

export const CLIENTS_QUERY = gql`
  query clients(
    $featured: Boolean
    $isActive: Boolean
    $userType: String
    $firstName: String
    $lastName: String
    $userIdIn: [String]
    $userSubscriptionsActive: Boolean
    $userIsStaff: Boolean
    $hasClub: Boolean
    $isWorkoutBlockInstructor: Boolean
    $isEventInstructor: Boolean
    $search: String
    $first: Int
    $after: String
    $orderBy: String
    $isMemberOfGroup: String
  ) {
    clients(
      featured: $featured
      user_IsActive: $isActive
      userType: $userType
      user_FirstName_Icontains: $firstName
      user_LastName_Icontains: $lastName
      user_Id_In: $userIdIn
      user_Subscriptions_Active: $userSubscriptionsActive
      user_IsStaff: $userIsStaff
      hasClub: $hasClub
      search: $search
      isWorkoutBlockInstructor: $isWorkoutBlockInstructor
      isEventInstructor: $isEventInstructor
      first: $first
      after: $after
      orderBy: $orderBy
      isMemberOfGroup: $isMemberOfGroup
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          profilePicture
          featured
          biography
          oneOnOneClientAllocation
          oneOnOneClients
          youtubeIntroVideo
          coachIntroduction
          friendship {
            id
            status
            mutualFriendsCount
          }
          activeSubscription {
            id
            product {
              id
              type
            }
          }
          user {
            id
            firstName
            lastName
            email
            isStaff
            userProfile {
              id
              handle
              numEventsInstructing
              numExerciseBlocksInstructing
              numMindsetBlocksInstructing
              numNutritionBlocksInstructing
              numNutritionProgramsInstructing
            }
          }
        }
      }
    }
  }
`;

export const FAVOURITE_USERS_QUERY = gql`
  query favouriteUsers {
    favouriteUsers {
      staff {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const HANDLER_QUERY = gql`
  query handler {
    onboardingHandler {
      id
      firstName
      userProfile {
        id
        profilePicture
        phone
      }
    }
  }
`;

export const TRIBE_QUERY = gql`
  query tribeQuery {
    tribes(userTribe: true, active: true) {
      edges {
        node {
          id
          title
          description
          bannerTitle
          banner
          icon
          myDots
          stats {
            tribe {
              id
              title
              banner
              icon
            }
            position
            dots
            isMine
          }
          group {
            id
          }
          coach {
            id
            firstName
            lastName
            profile {
              id
              profilePic
            }
          }
          article {
            id
            slug
          }
          workoutBlock {
            id
            title
            startDate
            image
          }
        }
      }
    }
  }
`;

export const AUTHORISATION_QUERY = gql`
  query canView($userId: ID!) {
    canView(userId: $userId)
  }
`;

export const SIMPLE_ME_QUERY = gql`
  query accountQuery {
    me {
      id
      username
      firstName
      lastName
      email

      userProfile {
        id
        supportChat {
          id
        }
      }
    }
  }
`;
