<template>
  <j-card rounded="lg" class="pb-4">
    <v-toolbar class="mb-6">
      <v-toolbar-title>Confirm menu</v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="$emit('closed')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <h3 class="navy--text mb-6">Confirm the menu you are choosing</h3>
      <j-avatar-card
        :title="nutritionBlock.title"
        :subtitle="nutritionBlock.leadInstructor.firstName"
        :avatar-url="nutritionBlock.leadInstructor.userProfile.profilePicture"
      >
      </j-avatar-card>

      <div v-if="!blockStarted">
        <h3 class="navy--text mb-2 mt-4">Starts {{ startDate | moment("ddd, D MMM 'YY") }}</h3>
      </div>
      <div v-else>
        <h3 class="navy--text mb-2 mt-4">Start date</h3>
        <j-radio-group v-model="startDateOption">
          <j-radio label="Start immediately" value="IMMEDIATELY"></j-radio>
          <j-radio label="Set start date myself" value="SET"></j-radio>
        </j-radio-group>

        <v-menu
          v-if="startDateOption === 'SET'"
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <j-text-field prepend-inner-icon="mdi-calendar" v-model="startDate" readonly v-bind="attrs" v-on="on" />
          </template>
          <v-date-picker
            ref="picker"
            color="navy"
            v-model="startDate"
            :min="immediateStartDate"
            :allowed-dates="allowedDates"
            @change="saveStartDate"
          ></v-date-picker>
        </v-menu>
      </div>
    </v-card-text>
    <v-card-actions class="justify-center">
      <j-btn secondary @click="confirm()" :disabled="!canSubmit" :loading="loading">
        <strong class="white--text">Confirm</strong>
      </j-btn>
    </v-card-actions>
  </j-card>
</template>

<script>
import { nextDateForDay } from "@/lib/fancy_date";
export default {
  name: "ConfirmNutritionProgram",
  data() {
    return {
      startDate: null,
      allowedDates: (val) => new Date(val).getUTCDay() === 1, // only Monday!
      menu: false,
      startDateOption: null,
      lastEndDate: null,
    };
  },
  props: {
    nutritionBlock: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    canSubmit() {
      return this.startDateOption != null;
    },
    immediateStartDate() {
      return this.$moment()
        .startOf("isoweek")
        .format("YYYY-MM-DD");
    },
    blockStarted() {
      return this.nutritionBlock.startDate == null || this.$moment(this.nutritionBlock.startDate) <= this.$moment();
    },
  },
  methods: {
    confirm() {
      if (this.startDateOption === "IMMEDIATELY") {
        this.startDate = this.immediateStartDate;
      }
      // let's keep the child components dumb and do all saving of data in the parent. just emit here.
      this.$emit("saved", {
        startDate: this.startDate,
      });
    },
    saveStartDate(date) {
      this.$refs.menu.save(date);
    },
  },
  created() {
    if (this.nutritionBlock.price > 0 && this.nutritionBlock.startDate) {
      this.startDate = this.nutritionBlock.startDate;
    } else {
      this.startDate = nextDateForDay(1);
    }

    if (!this.blockStarted && this.nutritionBlock.startDate) {
      this.startDateOption = "SET";
      this.startDate = this.nutritionBlock.startDate;
    }
  },
};
</script>
