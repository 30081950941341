<template>
  <div class="d-flex flex-column-reverse mb-0">
    <div class="mw-40 mb-0 day-tracking d-flex" :class="{ 'mx-1': $vuetify.breakpoint.mdAndUp }">
      <div class="mx-auto justify-center mw-40">
        <!-- Workout dots -->
        <div class="btn-wrapper">
          <v-btn icon elevation="3" class="white" @click.stop="$emit('openWorkout')">
            <workout-calendar-dot
              :logged="workoutImage != null"
              :partiallyLogged="loggedWorkout && workoutImage == null"
              color="pink"
              :isRestDay="restLogged"
              :active="active"
              class="py-special"
              :workoutImage="workoutImage"
            />
          </v-btn>
        </div>
        <hr v-if="prizesEnabled" class="prize-line pink" />

        <!-- Nutrition dots -->
        <div class="btn-wrapper" :class="{ 'my-1': !prizesEnabled }">
          <v-btn icon elevation="3" class="white" @click="$emit('openNutrition')">
            <nutrition-calendar-dot
              :logged="greenDay"
              :nutritionLogged="loggedNutrition"
              color="green"
              class="py-special"
              :active="active && !loggedNutrition"
            />
          </v-btn>
        </div>
        <hr v-if="prizesEnabled" class="prize-line green" />

        <div v-if="!showSimpleTab && weeklyGoalSet" class="btn-wrapper" :class="{ 'my-1': !prizesEnabled }">
          <v-btn icon elevation="3" class="white" @click="$emit('openNutrition')">
            <calendar-dot :logged="loggedFocus" color="green" :active="active" class="py-special" />
          </v-btn>
        </div>

        <div v-if="!showSimpleTab" class="btn-wrapper" :style="tallButtonHeight">
          <v-btn
            elevation="3"
            class="tall-button px-0 rounded-pill white"
            :style="tallButtonHeight"
            @click="$emit('openMindset')"
          >
            <div class="d-flex flex-column tall-button-group py-special">
              <calendar-dot v-if="trackingMindset" :logged="mindsetLogged" color="yellow" :active="active" />
              <calendar-dot v-if="trackingHydration" :logged="hydrationLogged" color="hydration" :active="active" />
              <calendar-dot
                v-if="trackingSleep"
                :logged="sleepLogged"
                :partiallyLogged="partialSleepLogged"
                color="sleep"
                :active="active"
              />
            </div>
          </v-btn>
        </div>
      </div>

      <!-- Prize details -->
      <slot name="prize-details" />
    </div>

    <div v-if="!showSimpleTab">
      <v-avatar
        class="elevation-2 caption navy--text mx-auto"
        :class="[active ? accentColor : '', { 'today-border': today }]"
        :style="borderColorStyle"
        size="30"
        outlined
      >
        <span :class="{ 'white--text': active }">
          {{ keyDate.getDate() }}
        </span>
      </v-avatar>
      <div class="blue--text mb-2">
        {{ keyDay }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getMonday } from "@/lib/calendarWeek";
import WorkoutCalendarDot from "@/components/shared/sections/WorkoutCalendarDot";
import NutritionCalendarDot from "@/components/shared/sections/NutritionCalendarDot";
import CalendarDot from "@/components/shared/sections/CalendarDot";

export default {
  name: "CalendarDotTab",
  components: {
    WorkoutCalendarDot,
    NutritionCalendarDot,
    CalendarDot,
  },
  props: {
    hasOpenPanel: {
      type: Boolean,
      default: false,
    },
    showSimpleTab: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      required: true,
    },
    prizesEnabled: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      required: false,
    },
    keyDay: {
      type: String,
      required: false,
    },
    keyDate: {
      type: Date,
      required: false,
    },
    loggedWorkout: {
      type: Boolean,
      required: false,
    },
    workoutImage: {
      type: String,
      required: false,
    },
    greenDay: {
      type: Boolean,
      default: false,
    },
    loggedNutrition: {
      type: [Number, null],
      default: null,
    },
    loggedFocus: {
      type: Boolean,
      default: false,
    },
    borderColorStyle: {
      type: String,
      required: false,
    },
    accentColor: {
      type: String,
      required: false,
      default: "blue",
    },
    trackingSleep: {
      type: Boolean,
      default: false,
    },
    sleepLogged: {
      type: Boolean,
      default: false,
    },
    partialSleepLogged: {
      type: Boolean,
      default: false,
    },
    trackingHydration: {
      type: Boolean,
      default: false,
    },
    hydrationLogged: {
      type: Boolean,
      default: false,
    },
    trackingMindset: {
      type: Boolean,
      default: false,
    },
    mindsetLogged: {
      type: Boolean,
      default: false,
    },
    restLogged: {
      type: Boolean,
      default: false,
    },
    weeklyGoalSet: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters(["selectedDate"]),
    monday() {
      if (this.selectedDate) {
        return this.formatDate(getMonday(this.selectedDate.format("YYYY-MM-DD")));
      }
      return this.formatDate(getMonday(this.formatDate()));
    },
    iconColor() {
      return this.qualified ? "yellow darken-2" : "grey darken-1";
    },
    tallButtonHeight() {
      let trackingCount = this.trackingSleep + this.trackingHydration + this.trackingMindset;
      return `height: calc((48px * ${trackingCount}) - 6px);`;
    },
    today() {
      return this.formatDate(this.keyDate) == this.formatDate(new Date());
    },
  },
  methods: {
    formatDate(date) {
      return this.$moment(date).format("YYYY-MM-DD");
    },
  },
};
</script>

<style lang="scss" scoped>
.v-avatar {
  margin: 6px 0;
}
.tracking-icon {
  margin: 4px 0 0;
}

.mw-40 {
  width: 40px;
  max-width: 40px;
}

.prize-summary {
  border: 3px solid var(--v-yellow-base);
  min-height: 70px;
  max-height: 100px;
  z-index: 2;

  &,
  div {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: space-around;
    border-radius: 100px;
  }

  & div i {
    padding: 3px 0;
  }
}

.prize-line {
  overflow: visible;
  height: 3px;
  left: 3px;
  position: relative;
  bottom: 25px;
  z-index: 1;
  border: none;
}

.v-tab {
  &:not(:last-of-type) .prize-line {
    margin-left: 33px;
  }
  &:last-of-type .prize-line {
    left: 10px;
    margin-left: 13px;
  }
  &:not(.active.v-tab--active) {
    .btn-wrapper .v-btn:not(:hover) {
      display: contents;
    }
  }

  .day-tracking {
    &.mx-1 .prize-line {
      margin-left: 23px;
      left: 12px;
    }

    .v-btn {
      height: 40px;
      width: 40px;
      color: white;
      margin-bottom: 3px;

      &:first-child {
        margin-top: 2px;
      }

      &.tall-button {
        min-width: 40px;
      }

      .py-special {
        padding: 11px 0;
      }

      .tall-button-group {
        & > :first-child {
          margin-top: -9px;
        }

        & > :not(:first-child) {
          padding-top: 8px;
        }

        & > :last-child {
          margin-bottom: -9px;
        }
      }
    }
  }
}
</style>
