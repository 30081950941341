<template>
  <div>
    <j-alert v-if="errorMessage" class="mt-2 mb-6">
      <span> {{ errorMessage }} </span>
    </j-alert>

    <ValidationObserver ref="observer">
      <v-form @submit.prevent="submit">
        <ValidationProvider id="username" v-slot="{ errors }" name="email" rules="required|email">
          <j-text-field v-model.trim="username" label="Email" :error-messages="errors" @keydown.space.prevent />
        </ValidationProvider>

        <ValidationProvider id="password" v-slot="{ errors }" name="password" rules="required">
          <j-text-field
            v-model="password"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            :type="showPassword ? 'text' : 'password'"
            label="Password"
            :error-messages="errors"
            @click:append="showPassword = !showPassword"
          />
        </ValidationProvider>

        <j-btn :loading="buttonLoading" width="100%" type="submit" @click="save()">log in</j-btn>
      </v-form>
    </ValidationObserver>
  </div>
</template>

<script>
import LoginMixin from "@/mixins/LoginMixin";

export default {
  name: "LoginForm",
  mixins: [LoginMixin],
  data() {
    return {
      username: "",
      password: "",
      showPassword: false,
      buttonLoading: false,
      errorMessage: "",
    };
  },
  methods: {
    submit() {
      this.$refs.observer.validate().then((valid) => {
        if (valid) {
          this.buttonLoading = true;
          this.errorMessage = "";
          this.login();
        }
      });
    },
  },
  created() {
    // if you are logging in, after coming from a partner page, let's redirect you to the pricing page after login.
    if (sessionStorage.getItem("product-code")) {
      sessionStorage.setItem("redirectPath", "/start/pricing");
      // in router we send `case` in the query string when redirecting. We also set the redirect path there, so let's not clear it now.
    } else if (!this.$route.query.case) {
      sessionStorage.removeItem("redirectPath");
    }
  },
};
</script>
