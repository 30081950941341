<template>
  <v-container></v-container>
</template>

<script>
export default {
  name: "Logout",
  mounted() {
    sessionStorage.removeItem("contentRedirectPath");
    this.$store.dispatch("logout").then(() => {
      this.$router.push("/login");
    });
  },
};
</script>
