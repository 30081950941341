<template>
  <v-skeleton-loader v-if="$apollo.loading" type="card-heading" />
  <div v-else>
    <div class="d-flex justify-space-between">
      <h4>Habit</h4>
      <v-spacer />
      <v-btn small text to="/accountability/habits" class="grey-navy--text">
        <strong>SEE HABITS</strong>
      </v-btn>
      <v-switch class="py-0 my-0" hide-details :input-value="habitEnabled" @change="updateHabitSetting($event)" />
    </div>
    <div v-if="habitEnabled" class="pb-2">
      <logging-selection-button
        v-if="habit"
        :title="habit.title || (habit.habitOption && habit.habitOption.title)"
        link="/accountability/habits"
        :key="formattedDate + 'habit'"
        :optionList="options.habit"
        :value="habitLog.status"
        @selected="upsertHabitLog($event)"
        class="reflection-logging-button"
      />
      <j-card v-else rounded="xl" elevation="6" class="mt-2 mb-1 pa-2" to="/accountability/habits">
        <div class="d-inline-flex ml-2 navy--text">
          <strong>SET A NEW HABIT</strong>
        </div>
      </j-card>
    </div>
  </div>
</template>

<script>
import { UPSERT_HABIT_ENTRY_MUTATION } from "@/graphql/mutations/habits";
import { REFLECTION_OPTIONS } from "@/constants/json/reflectionOptions";
import { decodeId } from "@/lib/string";
import LoggingSelectionButton from "@/components/tracker/buttons/LoggingSelectionButton";
import UserMixin from "@/mixins/UserMixin";
import HabitMixin from "@/mixins/HabitMixin";
import BadgeToaster from "@/mixins/BadgeToaster";

export default {
  name: "HabitTracker",
  mixins: [UserMixin, HabitMixin, BadgeToaster],
  components: {
    LoggingSelectionButton,
  },
  data() {
    return {
      options: REFLECTION_OPTIONS,
    };
  },
  computed: {
    habitEnabled() {
      return this.settingEnabled("habit-tracking");
    },
  },
  methods: {
    updateHabitSetting(value) {
      this.upsertSetting("habit-tracking", value);
    },
    async upsertHabitLog(value) {
      await this.$apollo
        .mutate({
          mutation: UPSERT_HABIT_ENTRY_MUTATION,
          variables: {
            habitId: decodeId(this.habit.id),
            entryId: decodeId(this.habitLog.id),
            date: this.formattedDate(),
            status: !!value,
            comment: null,
          },
          refetchQueries: ["activitiesQuery", "weeklyTrackerSummary"],
        })
        .then((response) => {
          if (response.data) {
            let badges = response.data.habitEntryMutation?.earnedBadges;
            if (badges) {
              this.toastBadges(badges);
            }
          }
        });
    },
  },
};
</script>
