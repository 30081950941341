<template>
  <div>
    <div class="d-flex align-baseline mt-1">
      <div class="text-left pt-2">
        <v-btn small text class="grey-navy--text" @click="showFilters = !showFilters">
          <strong>Filter / Sort</strong>
        </v-btn>
        <v-avatar color="nutrition" size="20" class="ml-1 sm-text white--text">{{ selectedFilterCount }}</v-avatar>
        <v-btn v-if="selectedFilterCount > 0" small text @click="resetFilters" class="grey-navy--text">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </div>
      <v-spacer />
      <j-btn tertiary narrow @click="$router.push({ name: 'CreateRecipe' })">
        Add<v-icon class="pl-1">mdi-plus-circle</v-icon>
      </j-btn>
    </div>

    <div v-if="showFilters">
      <v-row class="py-2">
        <v-col cols="8" md="4">
          <j-select
            v-model="orderBy"
            :items="[
              {
                text: 'Latest recipe',
                value: '-createdAt',
              },
              {
                text: 'Name',
                value: 'title',
              },
            ]"
            label="Sort by"
            hide-details
            dense
            @change="triggerFilters"
            class="my-1"
          />
        </v-col>
        <v-col v-if="showBookmark" class="col-auto ml-auto">
          <v-switch
            class="p-text recipe-switch hidden-xs-only ma-2 mr-7"
            v-model="bookmarkedFilter"
            label="Bookmarked"
            color="nutrition"
            hide-details
            @click="triggerFilters"
          />
          <v-switch
            class="p-text recipe-switch hidden-xs-only ma-2 mr-7 pt-0"
            v-model="addedByMeFilter"
            label="Added by me"
            color="nutrition"
            hide-details
            @click="triggerFilters"
          />
        </v-col>
      </v-row>
      <div class="text-left d-flex flex-wrap justify-space-between mx-2">
        <div class="d-flex flex-wrap mb-3">
          <div v-for="recipeType in recipeTypes" :key="recipeType" class="mr-2">
            <j-checkbox
              v-model="recipeTypeFilter"
              :key="recipeType"
              :label="recipeType | humanizeMeal"
              :value="recipeType"
              @click="triggerFilters"
              hide-details
              class="mt-2"
            />
          </div>
        </div>

        <div class="d-flex flex-wrap mb-3">
          <div v-for="preference in preferences" :key="preference.id" class="mr-2">
            <j-checkbox
              v-model="preferencesFilter"
              :key="preference.id"
              :label="preference.title"
              :value="preference.id"
              @click="triggerFilters"
              hide-details
              class="mt-2"
            />
          </div>
          <j-checkbox
            v-model="thisMenuFilter"
            label="Current Program"
            @click="triggerFilters"
            hide-details
            class="mt-2"
          />
        </div>
        <!-- Duplicate, this is showing for small, beneath all the checkboxes above. -->
        <div>
          <v-switch
            v-if="showBookmark"
            class="p-text mt-2 mt-md-1 recipe-switch hidden-sm-and-up"
            v-model="bookmarkedFilter"
            label="Bookmarked"
            color="nutrition"
            hide-details
            @click="triggerFilters"
          />
          <v-switch
            class="p-text mt-1 recipe-switch hidden-sm-and-up"
            v-model="addedByMeFilter"
            label="Added by me"
            color="nutrition"
            hide-details
            @click="triggerFilters"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RecipeMixin from "@/mixins/nutrition/RecipeMixin";

export default {
  name: "RecipeFilterSort",
  mixins: [RecipeMixin],
  props: {
    showBookmark: {
      type: Boolean,
      default: false,
    },
    meal: {
      type: String,
      required: false,
    },
    incomingParams: {
      type: Object,
      required: false,
    },
    blockId: {
      type: [String, Number],
      required: false,
    },
  },
  data() {
    return {
      showFilters: false,
    };
  },
  created() {
    if (this.meal) {
      this.recipeTypeFilter = [this.meal];
      this.triggerFilters();
    }

    if (this.incomingParams) {
      this.recipeTypeFilter = this.incomingParams.recipeTypeFilter;
      this.preferencesFilter = this.incomingParams.preferencesFilter;
      this.orderBy = this.incomingParams.orderBy;
      this.bookmarkedFilter = this.incomingParams.bookmarkedFilter;
      this.addedByMeFilter = this.incomingParams.addedByMeFilter;
      this.thisMenuFilter = this.incomingParams.thisMenuFilter;

      this.triggerFilters();
    }
  },
  computed: {
    selectedFilterCount() {
      let cnt = 0;
      if (this.bookmarkedFilter) {
        cnt++;
      }
      if (this.addedByMeFilter) {
        cnt++;
      }
      if (this.orderBy) {
        cnt++;
      }
      if (this.recipeTypeFilter) {
        cnt += Array.isArray(this.recipeTypeFilter) ? this.recipeTypeFilter.length : 1;
      }
      if (this.preferencesFilter) {
        cnt += Array.isArray(this.preferencesFilter) ? this.preferencesFilter.length : 1;
      }
      if (this.thisMenuFilter) {
        cnt++;
      }
      return cnt;
    },
  },
  methods: {
    resetFilters() {
      this.bookmarkedFilter = false;
      this.addedByMeFilter = false;
      this.orderBy = null;
      this.recipeTypeFilter = [];
      this.preferencesFilter = [];
      this.thisMenuFilter = false;
      this.triggerFilters(true);
    },
    triggerFilters(force = false) {
      if (!force && this.selectedFilterCount == 0) {
        return;
      }

      let filterVariables = {
        mealIn: this.recipeTypeFilter,
        isBookmarked: this.bookmarkedFilter,
        isAddedByMe: this.addedByMeFilter ?? false,
        preferencesIn: this.preferencesFilter,
      };
      if (this.thisMenuFilter) {
        filterVariables.isInBlock = parseInt(this.blockId);
      }
      if (this.orderBy) {
        filterVariables.orderBy = this.orderBy;
      }

      this.$emit("triggerFilters", filterVariables);
    },
  },
};
</script>
