<template>
  <j-card rounded="lg">
    <v-toolbar class="mb-6">
      <v-toolbar-title>Manage Program</v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="$emit('closed')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <j-btn block secondary @click="navigateOverview">
            Plan
            <v-icon small class="mt-n1 mr-n6">mdi-format-list-bulleted</v-icon>
          </j-btn>
        </v-col>
        <v-col cols="6">
          <j-btn block tertiary @click="leaveBlock" :loading="loading">
            Leave
            <v-icon small class="mt-n1 mr-n6">mdi-logout</v-icon>
          </j-btn>
        </v-col>
      </v-row>

      <!-- 
        CAREFUL. The below is wrapped in v-if="false". You cannot switch between self paced and structured. 
        Challenges are structured, Programs are self paced. 
      -->
      <div v-if="false">
        <!-- we can now make challenges self paced -->
        <plan-options :current="isSelfPaced" @set-self-paced="selfPacedOption = $event" class="mt-4" />

        <!-- can't change start date on a challenge, or on self-paced plan -->
        <div
          class="mb-2 mt-6"
          v-if="
            workoutSubscriptionBlock.block.type == 'PROGRAM' &&
            !workoutSubscriptionBlock.block.price &&
            !selfPacedOption
          "
        >
          <h4>Your current program dates</h4>
          <p>
            {{ workoutSubscriptionBlock.startDate | moment("ddd, D MMM") }} -
            {{ workoutSubscriptionBlock.endDate | moment("ddd, D MMM") }}
            <span v-if="currentWeek">({{ currentWeek }})</span>
          </p>

          <h5>Change dates</h5>
          <j-radio-group v-model="selectedChangeDate" class="mt-1">
            <j-radio label="Set new start date" value="START_DATE" />
            <j-radio label="Swap into week" value="CURRENT_WEEK" />
          </j-radio-group>

          <div v-if="selectedChangeDate == 'CURRENT_WEEK'">
            <p class="p-text font-weight-bold mb-1">Swap into this week now</p>
            <j-select
              v-model="selectedWeekOption"
              dense
              :items="workoutSubscriptionBlock.block.unitSet"
              item-text="title"
              item-value="order"
              @input="swapWeek"
            />
          </div>
          <v-menu
            v-if="selectedChangeDate == 'START_DATE'"
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <j-text-field prepend-inner-icon="mdi-calendar" v-model="startDate" readonly v-bind="attrs" v-on="on" />
            </template>
            <v-date-picker
              ref="picker"
              color="navy"
              v-model="startDate"
              :min="$moment().startOf('isoweek').format('YYYY-MM-DD')"
              :allowed-dates="allowedDates"
              @change="saveStartDate"
            ></v-date-picker>
          </v-menu>
          <h5>Your new program dates will be</h5>
          <p>{{ computedBlockDates }}</p>
          <j-btn block primary :loading="loading" @click="updateBlock">
            <span v-if="switchPlans">Switch plans</span>
            <span v-else>Update Dates</span>
          </j-btn>
        </div>
        <div v-else-if="switchPlans">
          <j-btn block primary :loading="loading" @click="updateBlock"> Switch plans </j-btn>
        </div>
      </div>
    </v-card-text>
  </j-card>
</template>

<script>
import { decodeId } from "@/lib/string";
import PlanOptions from "@/components/workoutblocks/PlanOptions";

export default {
  name: "ManageBlockDialog",
  components: {
    PlanOptions,
  },
  data() {
    return {
      startDate: this.workoutSubscriptionBlock.startDate,
      allowedDates: (val) => new Date(val).getUTCDay() === 1, // only Monday!
      menu: false,
      selectedChangeDate: "START_DATE",
      selectedWeekOption: 0,
      selfPacedOption: null,
    };
  },
  props: {
    workoutSubscriptionBlock: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.selfPacedOption = this.isSelfPaced;
  },
  computed: {
    blockLength() {
      const start = this.$moment(this.workoutSubscriptionBlock.startDate);
      const end = this.$moment(this.workoutSubscriptionBlock.endDate);
      return end.diff(start, "days");
    },
    currentWeek() {
      const unitIndex = Math.floor(this.workoutSubscriptionBlock.currentDay / 7);
      if (unitIndex >= 0 && this.workoutSubscriptionBlock.block.unitSet) {
        const unit = this.workoutSubscriptionBlock.block.unitSet[unitIndex];
        return unit ? unit.title : null;
      }
      return null;
    },
    isSelfPaced() {
      return this.workoutSubscriptionBlock.isSelfPaced ?? false;
    },
    // computed block dates, based on the startdate chosen on the fly
    computedBlockDates() {
      //const startDate =
      const endDate = this.$moment(this.startDate).add(this.blockLength, "days").format("ddd, D MMM");
      return `${this.$moment(this.startDate).format("ddd, D MMM")} - ${endDate}`;
    },
    switchPlans() {
      return this.selfPacedOption != this.isSelfPaced;
    },
  },
  methods: {
    swapWeek() {
      // if 0 then startDate Monday of this week!
      // if 1, then startDate is Monday of last week
      // if 2, then startDate is monday of two weeks ago.
      // i.e we subtract weeks from this monday! and we're good with start date!
      const monday = this.$moment().startOf("isoweek");
      const startDate = monday.subtract(this.selectedWeekOption, "weeks");
      this.startDate = startDate.format("YYYY-MM-DD");
    },
    saveStartDate(date) {
      this.$refs.menu.save(date);
    },
    navigateOverview() {
      this.$router.push(
        `/program/${decodeId(this.workoutSubscriptionBlock.block.id)}/overview?sbid=${decodeId(
          this.workoutSubscriptionBlock.id
        )}&sd=${this.startDate}`
      );
    },
    leaveBlock() {
      this.$emit("leave");
    },
    updateBlock() {
      this.$emit("saved", {
        workoutSubscriptionBlock: this.workoutSubscriptionBlock,
        startDate: this.startDate,
        isSelfPaced: this.selfPacedOption ?? this.isSelfPaced,
      });
    },
  },
  apollo: {},
};
</script>
