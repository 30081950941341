import gql from "graphql-tag";

export const UPSERT_MEETUP_MUTATION = gql`
mutation upsertMeetupMutation($input: UserMeetupInput!) {
  upsertMeetup(input: $input) {
    success
    error
    new
    meetup {
      id
    }
  }
}
`;

export const ATTEND_MEETUP_MUTATION = gql`
mutation attendMeetup($attending:Boolean!, $meetupId: ID!) {
  attendMeetup(attending: $attending, meetupId: $meetupId) {
    success
    error
  }
}
`;

export const CANCEL_MEETUP_MUTATION = gql`
mutation cancelMeetup($meetupId: ID!) {
  cancelMeetup(meetupId: $meetupId) {
    success
    error
  }
}
`;