import gql from "graphql-tag";

export const SUBSCRIPTION_NUTRITION_BLOCK_MUTATION = gql`
mutation subscriptionNutritionBlockMutation($data: NutritionBlockInput!){
  subscriptionNutritionBlockMutation(data: $data){
    error
    success
  }
}`

export const DELETE_NUTRITION_SUBSCRIPTION_BLOCK_MUTATION = gql`
mutation deleteNutritionSubscriptionBlock($subscriptionBlockId: ID!){
  deleteNutritionSubscriptionBlock(subscriptionBlockId: $subscriptionBlockId){
    error
    success
  }
}`

export const SWAP_RECIPE_MUTATION = gql`
mutation($data: UserSwappedRecipeInput!) {
  upsertUserSwappedRecipe(data: $data) {
    success
    error
  }
}
`

export const REMOVE_RECIPE_MUTATION = gql`
mutation($date: Date!, $meal: String!) {
  removeUserRecipe(date: $date, meal: $meal) {
    success
    error
  }
}
`

export const RESET_RECIPE_MUTATION = gql`
mutation($date: Date!, $meal: String!) {
  resetUserRecipe(date: $date, meal: $meal) {
    success
    error
  }
}
`

export const UPSERT_NUTRITION_LOG_MUTATION = gql`
mutation upsertNutritionLog($data: BlockNutritionLogInput!){
  upsertNutritionBlockLog(data: $data){
    error
    success
    nutritionLogId
    earnedBadges{
      id
      title
      description
    }
  }
}`

export const DELETE_NUTRITION_LOG_MUTATION = gql`
mutation deleteNutritionLog($subscriptionBlockId: ID!, $subscriptionRecipeId: ID!){
  deleteNutritionLog(subscriptionBlockId: $subscriptionBlockId, subscriptionRecipeId: $subscriptionRecipeId){
    ok
  }
}`