<template>
  <div v-if="locked" class="sm-text error--text">
    <v-icon small color="error" class="mb-1 mr-1">lock</v-icon>
    <strong>Locked</strong>
  </div>
  <div v-else class="sm-text jeff-life--text">
    <v-icon small color="jeff-life" class="mb-1 mr-1">check </v-icon>
    <strong>Available</strong>
  </div>
</template>

<script>
export default {
  name: "OpenAvailablity",
  props: {
    locked: {
      type: Boolean,
      required: true
    }
  }
};
</script>

<style scoped>
.font-16 {
  font-size: 16px;
}
</style>