<template>
  <span class="gradient-text" :style="textGradientStyle">
    <slot />
  </span>
</template>

<script>
import { gradientStyle } from "@/lib/gradientStyle";

export default {
  name: 'JGradientText',
  props: {
    gradient: {
      type: Array,
      required: false,
    },
  },
  computed: {
    textGradientStyle() {
      return gradientStyle(this.gradient, 90);
    },
  }
}
</script>
