<template>
  <j-card class="mb-6 j-elevated-1" @click="$router.push('/today/inbox/message/' + message.node.id)">

    <v-row justify="center" class="px-7">
      <v-col :lg="colspanLg" :cols="colspan" class="pr-0 mr-0">
        <v-row justify="center" class="pb-0 mb-0 pt-4">
          <v-col cols="12" class="pa-0 ma-0">
            <sender-block :message="message.node"></sender-block>
          </v-col>
        </v-row>
        <v-row justify="center" class="pb-0 mb-0">
          <v-col cols="12" class="py-0 my-0 px-0">
            <v-card-title class="size-16 pa-0 my-0 font-weight-bold card-list-title message-block-title">{{ message.node.inboxMessage.subject }}</v-card-title>
            <v-card-text class="size-16 text--secondary mb-0 message-block-copy mb-1 mt-1 pa-0">
              {{ replaceTemplateTags(message.node.inboxMessage.content) }}
            </v-card-text>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="message.node.inboxMessage.image" cols="6" lg="4" align="right" class="pr-0">
        <v-img
          class="br-10"
          :src="message.node.inboxMessage.image"
          contain
          max-width="125px"
          max-height="125px"
        />
      </v-col>
    </v-row>
  </j-card>
</template>

<script>

import SenderBlock from "@/components/inbox/SenderBlock";
import TemplateReplacerMixin from "@/mixins/inbox/TemplateReplacerMixin";

export default {
  name: "InboxMessageBlock",
  mixins: [TemplateReplacerMixin],
  components: {SenderBlock},
  props: {
    message: {
      type: Object,
      default: null
    },
    messageId: {
      type: String,
      default: null
    },
  },
  computed: {
    colspan() {
      if (this.message.node.inboxMessage.image)
        return 6;
      return 12;
    },
    colspanLg() {
      if (this.message.node.inboxMessage.image)
        return 8;
      return 12;
    },
    rowClass() {
      if (this.message.node.inboxMessage.image)
        return "pa-0 my-0 pr-3"
      return "ml-2 pa-0 my-0 pr-3"
    },
  },
}
</script>

<style scoped>
  .message-block-title {
    line-height:20px;
    font-size:18px
  }
  .message-block-copy {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .sender-block {
    float:left;
    font-size:16px;
    margin-top:2px;
  }
  .size-16 {
    font-size:16px;
  }
</style>