<template>
  <j-banner-container header="Shop" :gradient="['green-background', 'yellow-background']">
    <v-skeleton-loader
      v-if="$apollo.loading || recurring == null || onceOff == null"
      type="list-item-avatar,list-item-avatar,list-item-avatar"
    />
    <template v-else>
      <package-section v-if="getApplicablePackages().length > 0" :packages="getApplicablePackages()"/>
      <product-section v-if="recurring && $store.getters.role !== 'ONE_ON_ONE'" header="Upgrade" :products="recurring">
        <template v-slot:subheader>
          <span v-if="subscriptionProduct" class="caption grey-navy--text">
            You're on {{ subscriptionProduct.product.title }}
            <a href="/account/billing" class="font-weight-bold grey-navy--text">Manage</a>
          </span>
        </template>
      </product-section>
      <product-section v-if="onceOff" header="Buy" :products="onceOff" />
    </template>
  </j-banner-container>
</template>

<script>
import { BILLING_PRODUCTS_QUERY } from "@/graphql/queries/store";
import { ACCOUNT_QUERY } from "@/graphql/queries/account";
import UserMixin from "@/mixins/UserMixin";
import ProductSection from "@/views/store/ProductSection";
import PackageSection from "@/views/store/PackageSection";

export default {
  name: "Products",
  mixins: [UserMixin],
  components: {
    ProductSection,
    PackageSection
  },
  data() {
    return {
      billingProducts: null,
      recurring: null,
      onceOff: null,
      subscriptionProduct: null,
      packages: []
    };
  },
  created() {
    if (this.isLoggedIn) {
      this.$store.dispatch("updateColdCheckout", false);
    } else {
      this.$store.dispatch("updateColdCheckout", true);
    }
  },
  methods: {
    getApplicablePackages() {
      return this.packages.filter(p => p.products.some(product => product.type.includes(this.$store.getters.role)))
    }
  },
  apollo: {
    billingProducts: {
      query: BILLING_PRODUCTS_QUERY,
      variables() {
        return {
          status: "ACTIVE",
          withPackages: true
        };
      },
      fetchPolicy: "no-cache",
      result(response) {
        if (response.data && !response.loading) {
          if (response.data.billingProducts.edges.length > 0) {
            this.onceOff = response.data.billingProducts.edges.filter((p) => !p.node.isRecurring);
            this.recurring = response.data.billingProducts.edges.filter((p) => p.node.isRecurring);
            this.packages = response.data.packages;
          }
        }
      },
    },
    me: {
      query: ACCOUNT_QUERY,
      fetchPolicy: "no-cache",
      result(response) {
        if (response.data) {
          if (response.data?.subscription?.billingOrder?.subscriptionProductVariant) {
            this.subscriptionProduct = response.data?.subscription?.billingOrder.subscriptionProductVariant;
          }
        }
      },
      skip() {
        return !this.isLoggedIn;
      },
    },
  },
};
</script>
