import gql from "graphql-tag";

export const LOGIN_MUTATION = gql`
  mutation tokenAuth($username: String!, $password: String!) {
    tokenAuth(username: $username, password: $password) {
      token
      firstLogin
      subscription {
        id
        product {
          id
          type
          programType
          requiresIdentification
        }
        billingOrder {
          id
        }
      }
      user {
        id
        firstName
        lastName
        email
        dateJoined
        userProfile {
          id
          isDiscoveryMember
          idNumber
          passportNumber
          profilePicture
          phone
          activeSubscription {
            id
            acquisitionType
          }
        }
        isStaff
        challengeRegistrations {
          id
          active
          challenge {
            id
            endDate
          }
        }
        lastLogin
      }
    }
  }
`;

export const TOKEN_LOGIN_MUTATION = gql`
  mutation loginViaToken($token: String!) {
    loginViaToken(token: $token) {
      success
      token
      subscription {
        id
        product {
          id
          type
          programType
          requiresIdentification
        }
        billingOrder {
          id
        }
      }
      user {
        id
        firstName
        lastName
        email
        dateJoined
        userProfile {
          id
          isDiscoveryMember
          idNumber
          passportNumber
          profilePicture
          phone
        }
        isStaff
        challengeRegistrations {
          id
          active
          challenge {
            id
            endDate
          }
        }
        lastLogin
      }
    }
  }
`;

export const REGISTER_MUTATION = gql`
  mutation ($username: String!, $password1: String!, $password2: String!, $email: String!) {
    register(username: $username, password1: $password1, password2: $password2, email: $email) {
      errors
      token
      success
    }
  }
`;

export const VERIFY_ACCOUNT_MUTATION = gql`
  mutation verifyAccount($token: String!) {
    verifyAccount(token: $token) {
      success
      errors
    }
  }
`;

export const CHECK_ACCOUNT_MUTATION = gql`
  mutation checkAccount($email: String!) {
    checkAccount(email: $email) {
      firstName
      accountStatus
    }
  }
`;
