/* eslint-disable no-console */

import { register } from 'register-service-worker'

const IS_IOS = ['iPhone', 'iPad', 'iPod', 'iPad Simulator', 'iPhone Simulator', 'iPod Simulator',].includes(navigator.platform)
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document);

// turn off service worker permanently. it's reloading on iPhone. 
if (process.env.NODE_ENV === 'never_set') {

  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log(
        'App is being served from cache by a service worker.\n' +
        'For more details, visit https://goo.gl/AFskqB'
      )
    },
    registered (registration) {
      console.log('Service worker has been registered.');
      setInterval(() => {
        console.log("Checking for new update");
        registration.update();
      }, 1000 * 60); // check for new updates
    },
    cached () {
      console.log('Content has been cached for offline use.')
    },
    updatefound () {
      //alert("Update found");
    },
    updated (registration) {
      if (IS_IOS)
        return;

      console.log('New content is available; please refresh.');
      document.dispatchEvent(
        new CustomEvent('swUpdated', { detail: registration })
      );
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.')
    },
    error (error) {
      console.error('Error during service worker registration:', error)
    },
  });
}
