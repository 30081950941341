<template>
  <div class="px-0 mx-0">
    <image-uploader
      id="image-uploader"
      :maxWidth="800"
      hidden
      :preview="false"
      :quality="0.9"
      outputFormat="verbose"
      accept="image/*"
      @input="selectImg($event)"
    >
    </image-uploader>

    <div class="d-flex flex-wrap align-center" v-if="files.length > 0">
      <v-img
        v-for="(file, index) in files"
        :key="index"
        width="30%"
        :src="file.dataUrl"
        contain
        class="rounded-lg ma-2 d-flex relative"
      >
        <v-btn icon color="navy" class="remove-image-button" @click="remove(file)">
          <v-icon small>mdi-close</v-icon>
        </v-btn>
      </v-img>
    </div>
  </div>
</template>

<script>
import ImageUploader from "vue-image-upload-resize";
import { blobToFile, dataURLtoBlob } from "@/lib/file";

export default {
  name: "MultipleImageUploader",
  components: {
    ImageUploader,
  },
  data() {
    return {
      files: [],
    };
  },
  methods: {
    clickUploader() {
      document.getElementById("image-uploader").click();
    },
    async selectImg(data) {
      const blob = dataURLtoBlob(data.dataUrl);
      const file = blobToFile(blob);
      file.dataUrl = data.dataUrl;
      this.files.push(file);
      this.$emit("setImage", this.files);
    },
    remove(file) {
      const dataUrl = file.dataUrl;
      this.files = this.files.filter((element) => element.dataUrl !== dataUrl);
      this.$emit("setImage", this.files);
    },
  },
};
</script>

<style scoped>
.remove-image-button {
  background-color: #ccc;
  position: absolute;
  left: 5px;
  top: 5px;
}
</style>
