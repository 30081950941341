<template>
  <j-main-banner-container
    header="Search"
    search
    :gradient="['green-background', 'yellow-background']"
    @searchValueChanged="updateSearch"
  >
    <div
      v-if="
        search &&
          (searchResults.workoutBlocks.data.length > 0 ||
            searchResults.nutritionBlocks.data.length > 0 ||
            searchResults.events.data.length > 0 ||
            searchResults.groups.data.length > 0)
      "
      class="mt-2 pa-2 pr-0"
    >
      <j-subsection-row
        title="Exercise Programs"
        v-if="searchResults.workoutBlocks.data.length > 0"
        :show-see-all="false"
      >
        <v-row no-gutters class="mb-2">
          <v-col
            cols="6"
            md="3"
            v-for="result in searchResults.workoutBlocks.data"
            :key="result.id"
            :id="result.id"
            @click="navigateToProgram(result.id)"
          >
            <search-result-card :title="result.title" :subtitle="result.subtitle" :image="result.thumbnail" />
          </v-col>
        </v-row>
        <j-btn v-if="searchResults.workoutBlocks.meta.hasMore" small narrow tertiary @click="loadMoreWorkoutBlocks">
          Load More
        </j-btn>
      </j-subsection-row>

      <j-subsection-row
        title="Nutrition Menus"
        v-if="searchResults.nutritionBlocks.data.length > 0"
        :show-see-all="false"
      >
        <v-row no-gutters class="mb-2">
          <v-col
            cols="6"
            md="3"
            v-for="result in searchResults.nutritionBlocks.data"
            :key="result.id"
            :id="result.id"
            @click="navigateToNutritionProgram(result.id)"
          >
            <search-result-card :title="result.title" :subtitle="result.subtitle" :image="result.thumbnail" />
          </v-col>
        </v-row>
        <j-btn v-if="searchResults.nutritionBlocks.meta.hasMore" small narrow tertiary @click="loadMoreNutritionBlocks">
          Load More
        </j-btn>
      </j-subsection-row>

      <j-subsection-row title="Sessions" v-if="searchResults.events.data.length > 0" :show-see-all="false">
        <v-row no-gutters class="mb-2">
          <v-col
            cols="6"
            md="3"
            v-for="result in searchResults.events.data"
            :key="result.id"
            :id="result.id"
            @click="navigateToEvent(result.id)"
          >
            <search-result-card :title="result.title" :subtitle="result.subtitle" :image="result.thumbnail" />
          </v-col>
        </v-row>
        <j-btn v-if="searchResults.events.meta.hasMore" small narrow tertiary @click="loadMoreEvents">
          Load More
        </j-btn>
      </j-subsection-row>

      <j-subsection-row title="Groups" v-if="searchResults.groups.data.length > 0" :show-see-all="false">
        <v-row no-gutters class="mb-2">
          <v-col
            cols="6"
            md="3"
            v-for="result in searchResults.groups.data"
            :key="result.id"
            :id="result.id"
            @click="navigateToGroup(result.id)"
          >
            <search-result-card :title="result.title" subtitle="Group" :image="result.banner" />
          </v-col>
        </v-row>
        <j-btn v-if="searchResults.groups.meta.hasMore" small narrow tertiary @click="loadMoreGroups">
          Load More
        </j-btn>
      </j-subsection-row>
    </div>

    <j-alert
      class="mt-4 mx-8"
      v-if="
        search &&
          !loading.workoutBlockResults &&
          !loading.eventResults &&
          !loading.nutritionBlockResults &&
          searchResults.workoutBlocks.data.length === 0 &&
          searchResults.nutritionBlocks.data.length === 0 &&
          searchResults.events.data.length === 0 &&
          searchResults.groups.data.length === 0
      "
    >
      No results found for your search
    </j-alert>

    <div v-if="hasInitiatedSearch === false">
      <v-row justify="center">
        <v-col cols="12">
          <h1 class="secondary--text header heading-2 my-5">Explore</h1>
          <div class="mb-4">
            <j-btn primary to="/programs" class="main-section-button">
              Exercise Programs
              <v-icon class="ml-2">mdi-file-star</v-icon>
            </j-btn>
          </div>
          <div class="mb-4">
            <j-btn to="/programs?type=NUTRITION_PROGRAM" color="nutrition" class="main-section-button">
              Nutrition Programs
              <v-icon class="ml-2">mdi-file-table</v-icon>
            </j-btn>
          </div>
          <div class="mb-4">
            <j-btn to="/programs?type=COURSE" color="yellow" class="main-section-button">
              Courses
              <v-icon class="ml-2">mdi-file-certificate</v-icon>
            </j-btn>
          </div>

          <div class="mb-10 d-flex justify-space-between" style="max-width: 295px;">
            <j-btn v-if="recommendedWorkoutJourney" to="/journey" small narrow max-width="310" color="navy">
              My {{ $appName }} Journey <v-icon size="19">mdi-calendar-arrow-right</v-icon>
            </j-btn>
            <j-btn to="/shop" small narrow max-width="310" tertiary color="grey">
              shop <v-icon size="19">mdi-cart-outline</v-icon>
            </j-btn>
          </div>

          <div class="mb-4">
            <j-btn to="/live" tertiary class="main-section-button">
              Fresh Classes
              <v-icon class="ml-2">mdi-play-speed</v-icon>
            </j-btn>
          </div>
          <div class="mb-4">
            <j-btn to="/on-demand" tertiary class="main-section-button">
              On Demand Classes
              <v-icon class="ml-2">mdi-animation-play</v-icon>
            </j-btn>
          </div>
          <div class="mb-4">
            <j-btn to="/recipes" tertiary class="main-section-button">
              Recipes
              <v-icon class="ml-2">mdi-silverware-fork-knife</v-icon>
            </j-btn>
          </div>
        </v-col>
      </v-row>
    </div>
  </j-main-banner-container>
</template>

<script>
import { SEARCH_QUERIES } from "@/graphql/queries/search";
import { decodeId } from "@/lib/string";
import UserMixin from "@/mixins/UserMixin";
import SearchResultCard from "@/components/search/SearchResultCard";
import { RECOMMENDED_WORKOUT_JOURNEY_QUERY } from "@/graphql/queries/workoutblock";

const metaBlock = {
  totalResults: 0,
  currentPage: 0,
  hasMore: false,
  carouselPage: 0,
};

export default {
  name: "Search",
  components: { SearchResultCard },
  mixins: [UserMixin],
  data() {
    return {
      hasInitiatedSearch: false,
      searchResultLimit: 50,
      search: "",
      pageSize: 8,
      resultsPerPage: 3,
      loading: {
        workoutBlockResults: false,
        eventResults: false,
        nutritionBlockResults: false,
        groupResults: false,
      },
      searchResults: {
        workoutBlocks: {
          meta: { ...metaBlock },
          data: [],
        },
        events: {
          meta: { ...metaBlock },
          data: [],
        },
        nutritionBlocks: {
          meta: { ...metaBlock },
          data: [],
        },
        groups: {
          meta: { ...metaBlock },
          data: [],
        },
      },
      upcomingPrograms: [],
      recommendedWorkoutBlocks: [],
      recommendedNutritionBlocks: [],
      recommendedCourses: [],
      recommendedGroups: [],
    };
  },
  methods: {
    withInstructorCopy(block) {
      if (block && block.leadInstructor) {
        return `with ${block.leadInstructor.firstName} ${block.leadInstructor.lastName}`;
      }
      return "";
    },
    updateSearch(value) {
      //clear the search results
      this.resetSearchResults(this.searchResults.events);
      this.resetSearchResults(this.searchResults.workoutBlocks);
      this.resetSearchResults(this.searchResults.nutritionBlocks);
      this.resetSearchResults(this.searchResults.groups);

      this.search = value;
      if (this.search && this.search.trim() !== "") this.hasInitiatedSearch = true;
      else this.hasInitiatedSearch = false;

      if (this.search && this.search.trim() !== "") this.setLoading();
      history.pushState({}, null, this.$route.path + "?s=" + encodeURIComponent(this.search));
    },
    setLoading() {
      this.loading.nutritionBlockResults = true;
      this.loading.eventResults = true;
      this.loading.workoutBlockResults = true;
      this.loading.groupResults = true;
    },
    loadMoreEvents(fetchMore, page) {
      if (fetchMore && this.searchResults.events.data.length < this.searchResultLimit)
        this.searchResults.events.meta.currentPage++;
      this.searchResults.events.meta.carouselPage = page;
    },
    loadMoreWorkoutBlocks(fetchMore, page) {
      if (fetchMore && this.searchResults.workoutBlocks.data.length < this.searchResultLimit)
        this.searchResults.workoutBlocks.meta.currentPage++;
      this.searchResults.workoutBlocks.meta.carouselPage = page;
    },
    loadMoreNutritionBlocks(fetchMore, page) {
      if (fetchMore && this.searchResults.nutritionBlocks.data.length < this.searchResultLimit)
        this.searchResults.nutritionBlocks.meta.currentPage++;
      this.searchResults.nutritionBlocks.meta.carouselPage = page;
    },
    loadMoreGroups(fetchMore, page) {
      if (fetchMore && this.searchResults.groups.data.length < this.searchResultLimit)
        this.searchResults.groups.meta.currentPage++;
      this.searchResults.groups.meta.carouselPage = page;
    },
    resetSearchResults(section) {
      section.data = [];
      section.meta.totalResults = 0;
      section.meta.currentPage = 0;
    },
    navigateToProgram(id) {
      return this.$router.push(`/program/${decodeId(id)}`);
    },
    navigateToGroup(id) {
      return this.$router.push(`/group/${id}`);
    },
    navigateToNutritionProgram(id) {
      return this.$router.push(`/nutrition/${decodeId(id)}`);
    },
    navigateToEvent(id) {
      return this.$router.push(`/event/${decodeId(id)}`);
    },
    decodeTheId(id) {
      return decodeId(id);
    },
  },
  apollo: {
    searchWorkoutBlocks: {
      query: SEARCH_QUERIES.WORKOUT_BLOCKS,
      // if cached, then if you search multiple times it doesn't reset properly.
      fetchPolicy: "network-only",
      variables() {
        return {
          search: this.search,
          page: this.searchResults.workoutBlocks.meta.currentPage,
          pageSize: this.pageSize,
        };
      },
      result(response) {
        if (response.data && !response.loading) {
          this.searchResults.workoutBlocks.meta.hasMore = response.data.searchWorkoutBlocks.hasMore;
          this.searchResults.workoutBlocks.meta.totalResults = response.data.searchWorkoutBlocks.totalResults;
          response.data.searchWorkoutBlocks.workoutBlocks.forEach((item) => {
            let subtitle = this.withInstructorCopy(item);
            let resultObj = {
              id: item.id,
              title: item.title,
              subtitle: subtitle,
              thumbnail: item.image,
              visible: item.visible,
            };
            // graphene. Querset filtering doesn't work. https://github.com/graphql-python/graphene/issues/1198
            // so we are gating for active blocks here. Don't add to result, unless block is active.
            if (resultObj.visible) {
              this.searchResults.workoutBlocks.data.push(resultObj);
            }
          });

          if (this.searchResults.workoutBlocks.data.length >= this.searchResultLimit) {
            this.searchResults.workoutBlocks.meta.hasMore = false;
          }
          this.loading.workoutBlockResults = false;
        }
      },
      skip() {
        return this.search == null || (this.search && this.search.trim() === "");
      },
    },
    searchNutritionBlocks: {
      query: SEARCH_QUERIES.NUTRITION_BLOCKS,
      fetchPolicy: "network-only",
      variables() {
        return {
          search: this.search,
          page: this.searchResults.nutritionBlocks.meta.currentPage,
          pageSize: this.pageSize,
        };
      },
      result(response) {
        if (response.data && !response.loading) {
          this.searchResults.nutritionBlocks.meta.hasMore = response.data.searchNutritionBlocks.hasMore;
          this.searchResults.nutritionBlocks.meta.totalResults = response.data.searchNutritionBlocks.totalResults;
          response.data.searchNutritionBlocks.nutritionBlocks.forEach((item) => {
            let resultObj = {
              id: item.id,
              title: item.title,
              subtitle: "Nutrition Menu",
              thumbnail: item.image,
            };
            this.searchResults.nutritionBlocks.data.push(resultObj);
          });

          if (this.searchResults.nutritionBlocks.data.length >= this.searchResultLimit) {
            this.searchResults.nutritionBlocks.meta.hasMore = false;
          }
          this.loading.nutritionBlockResults = false;
        }
      },
      skip() {
        return this.search == null || (this.search && this.search.trim() === "");
      },
    },
    searchEvents: {
      query: SEARCH_QUERIES.EVENTS,
      fetchPolicy: "network-only",
      variables() {
        return {
          search: this.search,
          page: this.searchResults.events.meta.currentPage,
          pageSize: this.pageSize,
        };
      },
      result(response) {
        if (response.data && !response.loading) {
          this.searchResults.events.meta.hasMore = response.data.searchEvents.hasMore;
          this.searchResults.events.meta.totalResults = response.data.searchEvents.totalResults;
          response.data.searchEvents.events.forEach((item) => {
            let resultObj = {
              id: item.id,
              title: item.name,
              subtitle: this.$moment(item.scheduledDate).format("ddd, D MMM YYYY"),
              thumbnail: item.thumbnail,
              logged: item.useractivitySet?.edges.length > 0 ?? false,
              color: item.eventType?.category?.toLowerCase(),
            };
            this.searchResults.events.data.push(resultObj);
          });

          if (this.searchResults.events.data.length >= this.searchResultLimit) {
            this.searchResults.events.meta.hasMore = false;
          }
          this.loading.eventResults = false;
        }
      },
      skip() {
        return this.search == null || (this.search && this.search.trim() === "");
      },
    },
    searchGroups: {
      query: SEARCH_QUERIES.GROUPS,
      fetchPolicy: "network-only",
      variables() {
        return {
          search: this.search,
          page: this.searchResults.groups.meta.currentPage,
          pageSize: this.pageSize,
        };
      },
      result(response) {
        if (response.data && !response.loading) {
          this.searchResults.groups.meta.hasMore = response.data.searchGroups.hasMore;
          this.searchResults.groups.meta.totalResults = response.data.searchGroups.totalResults;
          response.data.searchGroups.groups.forEach((item) => {
            let resultObj = {
              id: item.id,
              title: item.title,
              banner: item.banner,
            };
            this.searchResults.groups.data.push(resultObj);
          });
          if (this.searchResults.groups.data.length >= this.searchResultLimit) {
            this.searchResults.groups.meta.hasMore = false;
          }
          this.loading.groupResults = false;
        }
      },
      skip() {
        return this.search == null || (this.search && this.search.trim() === "");
      },
    },
    recommendedWorkoutJourney: {
      query: RECOMMENDED_WORKOUT_JOURNEY_QUERY,
      fetchPolicy: "cache-and-network",
    },
  },
  created() {
    // if we don't wrap in if, apollo calls query twice
    if (this.$route.query.s) {
      this.search = this.$route.query.s;
    }

    if (this.search) {
      this.hasInitiatedSearch = true;
    }
  },
};
</script>

<style scoped>
.main-section-button {
  width: 300px;
}
</style>
