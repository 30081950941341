<template>
  <div v-if="youtubeId">
    <div class="video-container">
      <!-- This works because when youtube initializes, it will sit on top of this never ending loader...-->
      <v-progress-linear indeterminate color="blue"></v-progress-linear>
      <youtube
        :key="youTubeKey"
        :video-id="youtubeId"
        host="https://www.youtube-nocookie.com"
        :player-vars="playerVars"
        @ended="ended()"
        @playing="playing()"
        @paused="paused()"
        @ready="youTubeReady"
      ></youtube>
    </div>

    <div v-if="showCastMessage">
      <div class="d-flex justify-end pointer font-weight-bold p-text navy--text pt-2" @click="toggleCastAlert">
        How to cast to TV <v-icon small color="navy" class="ml-2">mdi-cast</v-icon>
      </div>
      <j-alert v-if="showCastAlert" class="mt-2" icon="mdi-arrow-up">
        Start the video and then press the "<em>YouTube</em>" icon, which will open the video in your YouTube app. Then,
        start the video on YouTube and press the "Cast icon" <v-icon color="navy" class="mt-n1">mdi-cast</v-icon> on the
        YouTube player.

        <div @click="upsertSetting" class="pointer text-decoration-underline font-weight-bold mt-3">
          Hide this message
        </div>
      </j-alert>
    </div>
    <v-btn v-if="eventStartTime > 0" color="secondary" class="mt-4 d-block" large rounded @click="jumpToStart">
      <strong>Session Start </strong><v-icon class="ml-1" small>mdi-fast-forward</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { UPSERT_MY_SETTINGS_MUTATION } from "@/graphql/mutations/account";

export default {
  name: "VideoBlock",
  components: {},
  props: {
    videoStartTime: {
      type: [String, Number],
      default: null,
    },
    eventStartTime: {
      type: [String, Number],
      default: null,
    },
    youtubeId: {
      type: String,
      default: null,
    },
    eventProperties: {
      type: Object,
      default: null,
    },
    showVideoCastMessage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      youTubePlayer: null,
      youTubeKey: 1,
      isPlaying: false,
      showCastMessage: false,
      showCastAlert: false,
      playerVars: {
        start: this.videoStartTime,
        // we want to allow watching on YouTube so that you can cast!
        //modestbranding: 1,
        //showinfo: 0,
        rel: 0,
        autoplay: 0,
        enablecastapi: 1,
      },
    };
  },
  created() {
    if (this.showVideoCastMessage) {
      const castSetting = this.$store.getters.user?.userSettings?.find((obj) => obj.key == "hide-cast-message");
      this.showCastMessage = !castSetting?.value;
    }
  },
  methods: {
    toggleCastAlert() {
      this.showCastAlert = !this.showCastAlert;
    },
    youTubeReady(event) {
      this.youTubePlayer = event.target;
    },
    jumpToStart() {
      this.playerVars.start = this.eventStartTime;
      this.youTubeKey++;
    },
    playing() {
      this.isPlaying = true;
      this.$emit("playing");
    },
    ended() {
      this.isPlaying = false;
      this.$emit("ended");
    },
    paused() {
      this.isPlaying = false;
      this.$emit("paused");
    },
    upsertSetting() {
      this.showCastMessage = false;
      this.$apollo.mutate({
        mutation: UPSERT_MY_SETTINGS_MUTATION,
        variables: {
          settingName: "hide-cast-message",
          value: true,
        },
        refetchQueries: ["accountQuery"],
      });
    },
  },
};
</script>

<style scoped></style>
