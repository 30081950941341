import vuetify from '@/plugins/vuetify'

export const getColor = (color) => {
  let multiple = color.split(' ');

  // handle lighten-2 etc. light theme framework doesn't have these values pre-stored
  if (multiple.length > 1) {
    let color = multiple[0];
    let application = multiple[1].replace('-', '');
    return `var(--v-${color}-${application})`;
  }
  else {
    let vuetifyColor = vuetify.framework.theme.themes.light[color];
    return vuetifyColor ? (vuetifyColor.base ? vuetifyColor.base : vuetifyColor) : color;
  }
}