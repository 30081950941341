<template>
  <div class="pt-2">
    <v-row v-if="$apollo.loading" class="px-1">
      <v-col cols="12" v-for="count in 3" :key="count"><v-skeleton-loader type="list-item-avatar" /> </v-col>
    </v-row>
    <div v-else>
      <template v-if="userConnections.length > 0">
        <v-row v-for="friend in userConnections" :key="friend.node.id">
          <v-col class="my-1 py-1">
            <div class="d-flex justify-space-between">
              <div class="d-flex">
                <user-avatar
                  :profile-picture="getFriend(friend.node).userProfile.profilePicture"
                  :user="getFriend(friend.node)"
                  size="80"
                />
                <div class="navy--text font-weight-medium ml-4 mt-3" align="left">
                  <router-link :to="profileLink(getFriend(friend.node))" class="heading-3 ml-1 text-decoration-none">
                    {{ getFriend(friend.node).firstName }} {{ getFriend(friend.node).lastName }}
                  </router-link>
                  <div v-if="friend.node.status == 'PENDING'" class="d-flex mt-1">
                    <j-btn
                      tertiary
                      small
                      class="mx-1"
                      @click="upsertFriendRequest(friend.node.id, 'APPROVED')"
                      >Approve
                    </j-btn>
                    <j-btn tertiary small class="mx-1" @click="rejectRequest(friend.node.id)">Reject </j-btn>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </template>
      <v-row v-else-if="!$apollo.loading">
        <v-col class="my-1 py-1">
          <j-alert class="mt-n3">No {{ status.toLowerCase() }} friend requests</j-alert>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { USER_CONNECTIONS_QUERY } from "@/graphql/queries/friends";
import { UPSERT_FRIEND_REQUEST, DELETE_FRIEND_REQUEST } from "@/graphql/mutations/friends";
import { decodeId } from "@/lib/string";
import UserAvatar from "@/components/shared/user/UserAvatar";
import ProfileLinkMixin from "@/mixins/ProfileLinkMixin";

export default {
  name: "FriendsListing",
  mixins: [ProfileLinkMixin],
  components: {
    UserAvatar,
  },
  props: {
    userId: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    limit: {
      type: Number,
      required: false,
    },
    ordered: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      userConnections: [],
      loading: true,
    };
  },
  methods: {
    getFriend(userConnection) {
      let userId = decodeId(this.userId);
      if (userConnection.user.id != userId) {
        return userConnection.user;
      }
      return userConnection.requester;
    },
    upsertFriendRequest(id, status) {
      this.$apollo.mutate({
        mutation: UPSERT_FRIEND_REQUEST,
        variables: {
          id: decodeId(id),
          status: status,
        },
        refetchQueries: ["userConnections"],
      });
    },
    rejectRequest(id) {
      this.$apollo.mutate({
        mutation: DELETE_FRIEND_REQUEST,
        variables: {
          id: decodeId(id),
        },
        refetchQueries: ["userConnections"],
      });

      this.deleteDialog = false;
    },
  },
  apollo: {
    userConnections: {
      query: USER_CONNECTIONS_QUERY,
      variables() {
        let variables = { status: this.status };
        if (this.status == "PENDING") {
          let userId = this.userId;

          if (parseInt(userId)) {
            userId = btoa(`UserNode: ${this.userId}`);
          }

          variables["userId"] = userId;
        } else {
          variables["forUserId"] = decodeId(this.userId);
        }
        if (this.limit) {
          variables["first"] = this.limit;
        }
        if (this.ordered) {
          variables["orderBy"] = "-created_at";
        }
        return variables;
      },
      // network-only, otherwise doesn't update the listing card nicely on update
      fetchPolicy: "network-only",
      update: (data) => data.userConnections.edges,
    },
  },
};
</script>
