import { TODOS_QUERY } from "@/graphql/queries/todo";

export default {
  data() {
    return {
      onboardingTotalCount: 1,
    };
  },
  computed: {
    isComplete() {
      return this.onboardingCompletedCount >= this.onboardingTotalCount;
    },
    onboardingCompletedCount() {
      const outstandingTodoCount =
        this.$store.getters.outstandingUserTodos?.filter((obj) => obj.type == "ONBOARDING")?.length || 0;
      const onboardingTotalCount = this.todos?.edges?.length || 0;
      // hack the +1 since the sign up todo is always done if you are viewing Onboarding Summary screen
      return Math.max(0, onboardingTotalCount - outstandingTodoCount) + 1;
    },
    progressValue() {
      return this.onboardingTotalCount > 0 ? (this.onboardingCompletedCount / this.onboardingTotalCount) * 100 : 0;
    },
  },
  apollo: {
    todos: {
      query: TODOS_QUERY,
      variables() {
        return {
          type: "ONBOARDING",
          forSubscription: true, // only get todos for this user's subscription.
        };
      },
      result(response) {
        if (response.data) {
          // hack the +1 since the sign up todo is always done if you are viewing Onboarding Summary screen
          this.onboardingTotalCount = response.data.todos?.edges?.length + 1;
        }
      },
    },
  },
};
