import gql from "graphql-tag";

export const WORKOUT_BLOCK_QUERY = gql`
  query workoutBlock($workoutBlockId: ID!, $withProductVariant: Boolean!, $groupType: String) {
    workoutBlock(workoutBlockId: $workoutBlockId) {
      id
      title
      image
      price
      type
      facebookUrl
      whatsappUrl
      description
      getReadyDescription
      priceDescription
      showAccountabilityGroupRegistration
      introYoutubeVideo
      introVideo
      strikePrice
      hasChatRegistration
      hasAccountabilityChat
      avgRating
      activityPlanner
      nutritionPlan
      userCanRate
      totalReviews
      showAccountabilityChats
      blockattachmentSet {
        id
        code
        attachment
      }
      chats {
        id
        title
        image
        description
      }
      productVariant @include(if: $withProductVariant) {
        id
        title
        code
        productvariantpriceSet {
          id
          price
          currency
        }
      }
      userList {
        id
        firstName
        lastName
        profilePicture
      }
      unitSet {
        id
        title
        order
        unitactivitySet {
          id
          day
          dayTitle
          type
          activity {
            id
            title
            image
            shortDescription
            duration
            durationMinutes
            leadInstructor {
              id
              userProfile {
                id
                profilePicture
              }
            }
          }
          alternateActivity {
            id
            title
            image
            shortDescription
            duration
            durationMinutes
            leadInstructor {
              id
              userProfile {
                id
                profilePicture
              }
            }
          }
        }
      }
      leadInstructor {
        id
        firstName
        lastName
        userProfile {
          id
          profilePicture
        }
      }
      secondaryInstructor {
        id
        firstName
        lastName
        userProfile {
          id
          profilePicture
        }
      }
      startDate
      salesCloseDate
      duration
      completedUsers
      activeUsers
      relatedBlock {
        id
        title
        image
        userList {
          id
          firstName
          lastName
          profilePicture
        }
        leadInstructor {
          id
          firstName
          lastName
          userProfile {
            id
            profilePicture
          }
        }
      }
      groupSet(type: $groupType) {
        edges {
          node {
            id
          }
        }
      }
      relatedNutritionBlock {
        id
        title
        image
        showInSearches
        userList {
          id
          firstName
          lastName
          profilePicture
        }
        leadInstructor {
          id
          firstName
          lastName
          userProfile {
            id
            profilePicture
          }
        }
      }
    }
  }
`;

export const WORKOUT_BLOCKS_QUERY = gql`
  query workoutBlocks(
    $type: String
    $typeIn: [String]
    $leadInstructor: ID
    $leadInstructorsIn: [ID]
    $instructorsIn: [String]
    $fitnessLevelTagsIn: [String]
    $specialityTagsIn: [String]
    $search: String
    $tags: String
    $startDateLte: Date
    $startDateGte: Date
    $first: Int
    $after: String
    $orderBy: String
    $withUsers: Boolean!
    $productVariant_Id: ID
    $hideOnWebApp: Boolean
  ) {
    workoutBlocks(
      type: $type
      type_In: $typeIn
      leadInstructor: $leadInstructor
      leadInstructor_In: $leadInstructorsIn
      instructorsIn: $instructorsIn
      tags: $tags
      fitnessLevelTagsIn: $fitnessLevelTagsIn
      specialityTagsIn: $specialityTagsIn
      search: $search
      startDate_Lte: $startDateLte
      startDate_Gte: $startDateGte
      first: $first
      after: $after
      orderBy: $orderBy
      productVariant_Id: $productVariant_Id
      hideOnWebApp: $hideOnWebApp
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          blockId
          title
          image
          thumbnailImage
          price
          type
          startDate
          facebookUrl
          whatsappUrl
          hasCompleted
          avgRating
          activityPlanner
          nutritionPlan
          userCanRate
          totalReviews
          description
          featured
          productVariant {
            id
          }
          userList @include(if: $withUsers) {
            id
            firstName
            lastName
            profilePicture
          }
          leadInstructor {
            id
            firstName
            lastName
            userProfile {
              id
              profilePicture
            }
          }
          secondaryInstructor {
            id
            firstName
            lastName
            userProfile {
              id
              profilePicture
            }
          }
        }
      }
    }
  }
`;

export const LEAD_INSTRUCTOR_QUERY = gql`
  query leadInstructors {
    leadInstructors {
      id
      firstName
      lastName
    }
  }
`;

export const WORKOUT_SUBSCRIPTION_BLOCKS_QUERY = gql`
  query workoutSubscriptionBlocks($orderBy: String, $userId: ID) {
    workoutSubscriptionBlocks(orderBy: $orderBy, userId: $userId) {
      edges {
        node {
          id
          currentDay
          progressPercent
          startDate
          originalStartDate
          endDate
          isSelfPaced
          block {
            id
            title
            type
            totalWorkouts
            totalModules
            totalEds
            totalLiveEventsRestDays
            facebookUrl
            whatsappUrl
            showAccountabilityGroupRegistration
            showAccountabilityChats
            chats {
              id
              title
              image
              description
            }
            leadInstructor {
              id
              firstName
              lastName
              userProfile {
                id
                profilePicture
              }
            }
            secondaryInstructor {
              id
              firstName
              lastName
              userProfile {
                id
                profilePicture
              }
            }
            userList {
              id
              firstName
              lastName
              profilePicture
            }
            price
            type
            unitSet {
              id
              title
              order
              unitactivitySet {
                order
                currentDay
                type
                activity {
                  title
                  description
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const WORKOUT_BLOCK_UNIT_ACTIVITY = gql`
  query workoutBlockUnitActivity($unitActivityId: ID!) {
    workoutBlockUnitActivity(unitActivityId: $unitActivityId) {
      id
      currentDay
      currentActivity
      type
      unit {
        id
        block {
          id
          duration
          totalWorkouts
          totalModules
          totalEds
          totalLiveEventsRestDays
          type
        }
      }
      workoutLogCount
      activity {
        id
        title
        embedUrl
        cdnUrl
        facebookUrl
        description
        duration
        durationMinutes
        activitysectionSet {
          id
          title
          description
          activitysectionexerciseSet {
            id
            order
            description
            longDescription
            isAutoStart
            activitysectionexercisetimerSet {
              id
              title
              order
              seconds
              isRest
            }
            exercise {
              id
              title
              howTo
              animatedImage
              quickHowTo
              staticImage
              coach {
                id
                userProfile {
                  id
                  profilePicture
                }
              }
            }
          }
        }
        activityattachmentSet {
          id
          title
          type
          file
        }
        activityType {
          name
        }
        leadInstructor {
          id
          firstName
          lastName
          userProfile {
            id
            profilePicture
          }
        }
      }
      alternateActivity {
        id
        title
        embedUrl
        facebookUrl
        description
        duration
        durationMinutes
        activitysectionSet {
          id
          title
          description
          activitysectionexerciseSet {
            id
            order
            description
            longDescription
            isAutoStart
            activitysectionexercisetimerSet {
              id
              title
              order
              seconds
              isRest
            }
            exercise {
              id
              title
              howTo
              animatedImage
              quickHowTo
              staticImage
              coach {
                id
                userProfile {
                  id
                  profilePicture
                }
              }
            }
          }
        }
        activityattachmentSet {
          id
          title
          type
          file
        }
        activityType {
          name
        }
        leadInstructor {
          id
          firstName
          lastName
          userProfile {
            id
            profilePicture
          }
        }
      }
      useractivitySet(last: 3) {
        edges {
          node {
            subscriptionBlock {
              id
              user {
                id
                firstName
                lastName
                userProfile {
                  id
                  profilePicture
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const SUBSCRIPTION_BLOCK_SELF_PACED = gql`
  query subscriptionBlockSelfPaced($id: ID!) {
    subscriptionBlock(id: $id) {
      id
      isSelfPaced
    }
  }
`;

export const DAILY_WORKOUT_ACTIVITIES_QUERY = gql`
  query dailyWorkoutActivities($anchorDate: Date!, $subscriptionBlockId: ID!, $activityCount: Int) {
    dailyWorkoutActivities(
      anchorDate: $anchorDate
      subscriptionBlockId: $subscriptionBlockId
      activityCount: $activityCount
    ) {
      subscriptionBlock {
        id
        block {
          id
          title
          type
          facebookUrl
          whatsappUrl
        }
      }
      activities {
        id
        day
        order
        type
        currentDay
        currentActivity
        activity {
          id
          title
          shortDescription
          description
          leadInstructor {
            id
            firstName
            lastName
            userProfile {
              id
              profilePicture
            }
          }
          image
          embedUrl
          activityattachmentSet {
            id
            title
            type
            file
          }
          duration
          durationMinutes
          activityType {
            name
          }
        }
      }
    }
  }
`;

export const WORKOUT_BLOCK_WORKOUT_LOG = gql`
  query workoutBlockWorkoutLog($subscriptionBlockId: ID!, $unitActivityId: ID!) {
    workoutBlockWorkoutLog(subscriptionBlockId: $subscriptionBlockId, unitActivityId: $unitActivityId) {
      id
      image
      calories
      heartRate
      seconds
      effort
      averagePace
      distance
      comment
      startDate
      activityType
      isCustom
      unitActivity {
        id
        activity {
          id
          title
          leadInstructor {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const WORKOUT_BLOCK_WORKOUT_LOGS = gql`
  query workoutBlockWorkoutLogs($subscriptionBlockId: ID, $date: Date) {
    workoutBlockWorkoutLogs(subscriptionBlock_Id: $subscriptionBlockId, startDate: $date) {
      edges {
        node {
          id
          image
          calories
          heartRate
          seconds
          effort
          averagePace
          distance
          comment
          startDate
          subscriptionBlock {
            id
            block {
              id
              title
            }
          }
          unitActivity {
            id
            type
            activity {
              id
              title
              activityType {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const RECOMMENDED_WORKOUT_BLOCKS = gql`
  query recommendedWorkoutBlocks($anchorDate: Date!, $programType: String, $limit: Int) {
    recommendedWorkoutBlocks(anchorDate: $anchorDate, programType: $programType, limit: $limit) {
      score
      breakdown {
        favouriteInstructor
        fitnessLevel
        recentlyCompleted
        matchingTags {
          tag
        }
      }
      block {
        id
        title
        image
        leadInstructor {
          id
          firstName
          lastName
          userProfile {
            id
            profilePicture
          }
        }
      }
    }
  }
`;

export const RECOMMENDED_WORKOUT_BLOCK_QUERY = gql`
  query recommendedWorkoutBlock {
    recommendedWorkoutBlock {
      id
      title
      image
      price
      type
      facebookUrl
      whatsappUrl
      description
      duration
      strikePrice
      leadInstructor {
        id
        firstName
        lastName
        userProfile {
          id
          profilePicture
        }
      }
      secondaryInstructor {
        id
        firstName
        lastName
        userProfile {
          id
          profilePicture
        }
      }
    }
  }
`;

export const RECOMMENDED_WORKOUT_JOURNEY_QUERY = gql`
  query recommendedWorkoutJourney {
    recommendedWorkoutJourney {
      id
      title
      image
      price
      type
      duration
      strikePrice
      hasCompleted
      isCurrent
      isUpcoming
      leadInstructor {
        id
        firstName
        lastName
        userProfile {
          id
          profilePicture
        }
      }
      secondaryInstructor {
        id
        firstName
        lastName
        userProfile {
          id
          profilePicture
        }
      }
    }
  }
`;

export const WORKOUT_BLOCK_RATING_QUERY = gql`
  query workoutBlock($workoutBlockId: ID!, $first: Int, $after: String, $orderBy: String) {
    workoutBlock(workoutBlockId: $workoutBlockId) {
      id
      ratingSet(first: $first, after: $after, orderBy: $orderBy) {
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            user {
              firstName
            }
            rating
            comment
            createdAt
          }
        }
      }
    }
  }
`;

export const WORKOUT_BLOCK_EXERCISE_LOGS = gql`
  query workoutBlockExerciseLogs($subscriptionBlock: ID, $unitActivity: ID, $event: ID) {
    workoutBlockExerciseLogs(subscriptionBlock: $subscriptionBlock, unitActivity: $unitActivity, event: $event) {
      edges {
        node {
          id
          activitySectionExercise {
            id
            exercise {
              id
            }
          }
        }
      }
    }
  }
`;
