import { mapActions, mapGetters } from "vuex";
import { SET_REST_DAY_MUTATION, UNSET_REST_DAY_MUTATION } from "@/graphql/mutations/tracker";

export default {
  methods: {
    ...mapActions(["updateRestDay", "removeRestDay"]),
    formattedDate() {
      return this.selectedDate ? this.selectedDate.format("YYYY-MM-DD") : this.$moment().format("YYYY-MM-DD");
    },
    setRestDay(input) {
      let payload = {
        restDayDate: this.formattedDate(),
        didRest: input.didRest,
        planned: input.planned,
        details: input.details,
        description: input.description,
        subscriptionBlockId: input?.subscriptionBlockId,
        unitActivityId: input?.unitActivityId,
      };

      this.$apollo
        .mutate({
          mutation: SET_REST_DAY_MUTATION,
          variables: {
            input: payload,
          },
          refetchQueries: ["weeklyTrackerSummary", "assistantQuery", "accountQuery", "workoutBlockWorkoutLogs"]
        })
        .then(() => {
          this.updateRestDay(payload);
        });
    },
    unsetRestDay() {
      let date = this.formattedDate();

      this.$apollo
        .mutate({
          mutation: UNSET_REST_DAY_MUTATION,
          variables: {
            date: date,
          },
        })
        .then(() => {
          this.removeRestDay(date);
        });
    },
  },
  computed: {
    ...mapGetters(["restDays", "selectedDate"]),
    didRest() {
      return this.restDays.some((day) => day.restDayDate == this.formattedDate() && day.didRest);
    },
  },
};
