<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col cols="12" md="5">
        <v-card class="mx-auto pa-3 mt-6 card-radius">
          <v-card-title class="pb-0 mb-1 justify-center">
            <h2 class="font-weight-bold mb-2 appblue--text">Web Portal Login</h2>
          </v-card-title>
          <v-card-text>
            <login-form />
          </v-card-text>

          <div class="d-flex justify-center flex-column align-center font-weight-bold p-text mt-4">
            <div>
              <router-link to="/account/forgot-password" class="text-decoration-none">Forgot Password?</router-link>
            </div>

            <div class="my-4">
              <router-link to="/contact-us" class="text-decoration-none">Need to contact support?</router-link>
            </div>
          </div>

          <!-- 
          <j-card class="j-elevated-1 my-2 mx-auto text-center" align="left">
           <v-list-item-group class="px-6">
              <v-list-item class="px-0" to="/account/forgot-password">
                <v-list-item-icon class="mr-2">
                  <v-icon class="navy--text">mdi-lock-reset</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="navy--text text-uppercase font-weight-bold">
                    Password Instructions
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon class="navy--text">mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-divider />
              <v-list-item class="px-0" to="/contact-us">
                <v-list-item-icon class="mr-2">
                  <v-icon class="navy--text">mdi-email</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="navy--text text-uppercase font-weight-bold">Contact Us</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon class="navy--text">mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-divider />
              <v-list-item class="px-0" to="/start/get-going">
                <v-list-item-icon class="mr-2">
                  <v-icon class="navy--text">mdi-creation</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="navy--text text-uppercase font-weight-bold">
                    Sign Up For {{ $appName }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon class="navy--text">mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group> 
          </j-card>
          -->
        </v-card>

        <div class="d-flex justify-center align-center mt-6 pt-6">
          Not a JEFF member?
          <router-link to="/start/register" class="ml-2 text-decoration-none font-weight-bold"
            >Create an account</router-link
          >
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginForm from "./blocks/LoginForm";

export default {
  name: "Login",
  components: {
    LoginForm,
  },
};
</script>
