<template>
  <div>
    <v-card class="text-left pa-0 mb-3 card-radius dashboard-card" style="max-width: 100%">
      <v-toolbar flat class="pa-0 ma-0" color="rgba(255, 0, 0, 0.0)">
        <span class="p-text grey-navy--text"
          >{{ event.scheduledDate | moment("ddd, D MMM YY") }}
          <span v-if="includeTime">
            {{ event.scheduledDate | moment("HH:mm") }}
          </span>
        </span>
        <v-spacer />
        <span class="p-text secondary--text pt-0 pr-1">
          {{ event.eventType.name }}
        </span>
        <v-icon
          v-if="
            (event.useractivitySet.edges && event.useractivitySet.edges.length < 1) ||
              event.useractivitySet.edges[0].node.status == 'IN_PROGRESS'
          "
          :color="event.eventType.category.toString().toLowerCase()"
        >
          radio_button_unchecked
        </v-icon>
        <v-icon v-else :color="event.eventType.category.toString().toLowerCase()" style="font-size: 28px"
          >fiber_manual_record
        </v-icon>
        <manage-bookmark
          v-if="showBookmark"
          :isObjectNote="true"
          :objectId="event.id"
          objectType="event"
          :objectNotes="bookmarkNotes"
          :bookmarkId="!deletedBookmark ? bookmarkId : null"
          refreshQuery="bookmarks"
          @saved="onSaved"
        ></manage-bookmark>
      </v-toolbar>
      <v-card-subtitle class="timing-display pl-5 py-0">
        <span v-if="eventPassed(event.scheduledDate)" class="grey-navy--text ml-n1">
          <v-icon class="pb-1">history </v-icon>
          Past
        </span>
        <div v-if="isLive(event) && event.url">
          <v-chip class="ml-n1 mb-2" color="error" text-color="white" label small>
            <h1 class="pb-1 pr-1">&#8226;</h1>
            LIVE NOW
          </v-chip>
        </div>
      </v-card-subtitle>
      <v-list three-line style="background-color: #fff" class="pa-0 ma-0">
        <v-list-item @click="handleEventNavigation(event)">
          <v-list-item-avatar size="80" class="mt-0 pt-2">
            <v-img :lazy-src="image(event)" :src="image(event)"></v-img>
          </v-list-item-avatar>
          <v-list-item-content :class="hasTimeState ? '' : 'pt-0'">
            <v-list-item-subtitle v-if="inActive">
              <open-availability :locked="locked(event)" />
            </v-list-item-subtitle>
            <v-list-item-title class="card-list-title"
              >{{ event.name }}
              <div
                v-if="event.location && includeLocation"
                class="grey-navy--text mt-2"
                style="
                  line-height: 20px;
                  margin-left: -2px;
                  font-weight: normal;
                "
              >
                <v-icon class="location-pin-icon">location_on</v-icon>Streamed from {{ event.location }}
              </div></v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import ManageBookmark from "@/components/bookmarks/block/ManageBookmark";
import { mapGetters } from "vuex";
import UserMixin from "@//mixins/UserMixin";
import { decodeId } from "@/lib/string";

export default {
  name: "EventCard",
  mixins: [UserMixin],
  components: {
    ManageBookmark,
  },
  props: {
    event: {
      type: Object,
      default: null,
    },
    includeLive: {
      type: Boolean,
      default: false,
    },
    includeTime: {
      type: Boolean,
      default: false,
    },
    includeLocation: {
      type: Boolean,
      default: true,
    },
    showBookmark: {
      type: Boolean,
      default: false,
    },
    bookmarkId: {
      type: String,
      required: false,
    },
    bookmarkNotes: {
      type: String,
      required: false,
    },
    refreshQuery: {
      type: String,
      default: "bookmarks",
    },
  },
  data: () => ({
    showUpSellModal: false,
    deletedBookmark: false,
  }),
  computed: {
    ...mapGetters(["selectedDate", "role"]),
    hasTimeState() {
      return this.eventPassed(this.event.scheduledDate) && this.isLive(this.event) && this.event.url;
    },
  },
  methods: {
    onSaved(bookmarkId) {
      this.deletedBookmark = bookmarkId < 0;
    },
    handleEventNavigation(event) {
      if (this.locked(event)) {
        this.showUpSellModal = true;
      } else {
        this.$router.push(`/event/${decodeId(event.id)}`);
      }
    },
    locked(event) {
      if (!this.inActive) {
        return null;
      }

      return !event.openAccess;
    },
    eventPassed(date) {
      let now = this.$moment().utc();
      let eventDate = this.$moment(date).utc();
      return now > eventDate.add(1, "h");
    },
    image(event) {
      return event.thumbnail ? event.thumbnail : event.userProfile && event.userProfile.profilePicture;
    },
    isLive(event) {
      let now = this.$moment().utc();
      let eventDate = this.$moment(event.scheduledDate).utc();

      //calculate duration & end date of event
      let startTime = this.$moment(event.startTime, "HH:mm:ss");
      let endTime = this.$moment(event.endTime, "HH:mm:ss");
      let duration = this.$moment.duration(endTime.diff(startTime));
      let passedDate = eventDate.clone().add(duration);

      /**
       * If the embed url is set to: https://www.facebook.com/groups/jefftogether
       * Then the video is NOT live and the OPS team needs to first add the video
       */
      if (!this.fbLiveVideoUpdated(event)) {
        return false;
      } else if (this.fbLiveVideoUpdated(event) && event.vimeoVideoId) {
        return false;
      } else if (this.fbLiveVideoUpdated(event) && !event.vimeoVideoId) {
        /**
         * If the vimeo video has not been added but the FB live video has
         * then this video is live
         */
        if (now > passedDate) return false;

        return true;
      }

      return now > eventDate && now < passedDate;
    },
    fbLiveVideoUpdated(event) {
      if (!event.url) return false;

      let placeholderUrls = [
        "https://www.facebook.com/groups/jeffmembers",
        "https://www.facebook.com/groups/jefftogether",
      ];
      let event_url = event.url
        .trim()
        .toLowerCase()
        .replace(/\/$/, "");
      return !placeholderUrls.includes(event_url);
    },
  },
};
</script>

<style scoped>
.timing-display {
  display: block;
  margin-top: -18px;
}

.location-display {
  line-height: 20px;
  margin-left: -2px;
  font-weight: normal;
}
</style>
