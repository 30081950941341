<template>
  <j-card class="mx-3 my-1 j-elevated-3">
    <v-card-title class="secondary--text mb-0 pb-0">
      <span class="font-weight-bold mx-auto headline">{{ title }}</span>
    </v-card-title>
    <v-card-text class="pb-0 mt-6">
      <v-row>
        <v-col cols="4" align="center" class="pa-0">
          <h5 class="font-weight-regular text-body-1 grey-navy--text">Exercise</h5>
          <v-avatar color="red-icon" class="mt-2" size="80">
            <span class="font-weight-bold white--text headline" v-if="!loaded">{{exercise}}</span>
            <v-progress-circular
              v-if="loaded"
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-avatar>
        </v-col>
        <v-col cols="4" align="center" class="pa-0">
          <h5 class="font-weight-regular text-body-1 grey-navy--text">Nutrition</h5>
          <v-avatar color="green-icon" class="mt-2" size="80">
            <span class="font-weight-bold white--text headline" v-if="!loaded">{{nutrition}}</span>
            <v-progress-circular
              v-if="loaded"
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-avatar>
        </v-col>
        <v-col cols="4" align="center" class="pa-0">
          <h5 class="font-weight-regular text-body-1 grey-navy--text">Mindset</h5>
          <v-avatar color="yellow-icon" class="mt-2" size="80">
            <span class="font-weight-bold white--text headline" v-if="!loaded">{{mindset}}</span>
            <v-progress-circular
              v-if="loaded"
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-avatar>
        </v-col>
      </v-row>
      <v-row v-if="loadedStats">
        <v-col cols="12" class="mt-5" align="center" justify="center">
          <v-progress-circular
            v-if="loadedStats"
            indeterminate
            color="grey"
          ></v-progress-circular>
        </v-col>
      </v-row>
      <v-row v-else class="mt-7">
        <v-col cols="12" class="py-0 my-0">
          <v-row align="center" justify="center" class="margin-x-16 bt-1">
            <v-col cols="5" md="4" class="px-1 pb-0 text-left">
              <div class="mb-1 text-body-1">Time</div>
              <v-icon class="mr-2 mb-2 text-body-1">mdi-clock-outline</v-icon>
              <span class="font-weight-bold text-body-1">{{ secondsToTimeString(stats.duration[statsPeriod]) }}</span>
            </v-col>
            <v-col cols="5" md="4" align="right" justify="center" class="px-1 pb-0">
              <div class="mb-1 text-body-1">Calories Burned</div>
              <v-icon class="mr-2 mb-2 text-body-1">mdi-fire</v-icon>
              <span class="font-weight-bold text-body-1">{{ stats.calories[statsPeriod] }}</span>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" class="margin-x-16">
            <v-col cols="5" md="4" class="px-1 pb-0 text-left">
              <div class="mb-1 text-body-1">Avg Heart Rate</div>
              <v-icon class="mr-2 mb-2 text-body-1">mdi-cards-heart</v-icon>
              <span class="font-weight-bold text-body-1">{{ stats.heartRate[statsPeriod].toFixed(0) }} bpm</span>
            </v-col>
            <v-col cols="5" md="4" align="right" justify="center" class="px-1 pb-0">
              <div class="mb-1 text-body-1">Green Dot Days</div>
              <v-icon class="mr-2 mb-2 text-body-1">mdi-food-apple</v-icon>
              <span class="font-weight-bold text-body-1">{{ stats.nutrition[statsPeriod]}}</span>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" class="margin-x-16 pb-3">
            <v-col cols="5" md="4" class="px-1 pb-0 text-left">
              <div class="mb-1 text-body-1">Distance</div>
              <v-icon class="mr-2 mb-2 text-body-1">mdi-map-marker-distance</v-icon>
              <span class="font-weight-bold text-body-1">{{ stats.distance[statsPeriod].toFixed(2) }} km</span>
            </v-col>
            <v-col cols="5" md="4" align="right" justify="center" class="px-1 pb-0">
              <div class="mb-1 text-body-1">Avg Effort</div>
              <v-icon class="mr-2 mb-2 text-body-1">mdi-arm-flex</v-icon>
              <span class="font-weight-bold text-body-1">{{ stats.effort[statsPeriod].toFixed(2) }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </j-card>
</template>

<script>
export default {
  name: "ActivityTotalBlock",
  props: {
    loaded: {
      type: Boolean,
      default: false
    },
    loadedStats: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    },
    link: {
      type: String,
      default: ""
    },
    exercise: {
      type: Number,
      default: 0
    },
    nutrition: {
      type: Number,
      default: 0
    },
    mindset: {
      type: Number,
      default: 0
    },
    stats: {
      type: Object,
      default:null
    },
    statsPeriod: {
      type: String,
      default: ""
    }
  },
  methods: {
    secondsToTimeString(secs) {
      var hours = Math.floor(secs / (60 * 60)).toString();

      var divisor_for_minutes = secs % (60 * 60);
      var minutes = Math.floor(divisor_for_minutes / 60).toString().padStart(2,'0');

      var divisor_for_seconds = divisor_for_minutes % 60;
      var seconds = Math.ceil(divisor_for_seconds).toString().padStart(2,'0');
      return `${hours}:${minutes}:${seconds}`;
    }
  }
}
</script>

<style scoped>
.margin-x-16 {
  margin:  0 -16px;
}
.bt-1 {
  border-top: 1px solid #eee;
}
</style>