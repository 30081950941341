export const userData = {
    profile_image: null,
    is_discovery_member: false,
    claim_discount: false,
    date_of_birth: null,
    id_number: "",
    passport_number: "",
    errorChecked: null,
    consent: null,
    firstName: "",
    lastName: "",
    gender: null,
    phone: "",
    suburb: "",
    country: 'ZA',
    dietaryRequirement: null,
    extrovertScale: null,
    fitnessLevel: "",
    hasMedicalConditions: "",
    height: null,
    weight: null,
    companyRole: "",
    company: "",
    companySize: null,
    trimester: null,
    pregnant: false,
    breastfeeding: false,
    weightLossGoal: null,
    showInSearches: null,
    handle: null
};


export const genderOptions = [
    {
        text: "",
        value: null,
    },
    {
        text: "Female",
        value: "FEMALE",
    },
    {
        text: "Male",
        value: "MALE",
    },
];

export const pregnantOptions = [
  {
    text: "First",
    value: 1,
  },
  {
    text: "Second",
    value: 2,
  },
  {
    text: "Third",
    value: 3,
  },
];

export const weightLossGoalOptions = [
  {
    text: "Weight Loss",
    value: "fat_loss"
  },
  {
    text: "Maintain My Current Weight",
    value: "maintenance"
  },
  {
    text: "Toning",
    value: "toning"
  },
  {
    text: "Gain Weight",
    value: "weight_gain"
  },
];
  
export const dietaryOptions =[
    {
      text: "",
      value: null,
    },
    {
      text: "No preference",
      value: "NA",
    },
    {
      text: "Vegetarian",
      value: "VEGETARIAN",
    },
    {
      text: "Vegan",
      value: "VEGAN",
    },
];

export const fitnessOptions =[
  {
    text: "Beginner",
    value: "BEGINNER",
    description: "Starting or getting started again"
  },
  {
    text: "Intermediate",
    value: "INTERMEDIATE",
    description: "Fit and active a few times a week"
  },
  {
    text: "Advanced",
    value: "ADVANCED",
    description: "Consistently getting fitter and stronger"
  },
];

export const requiredSupportOptions =[
  // latest designs don't have this. 
  // {
  //   text: "I prefer to go solo",
  //   value: "SOLO",
  // },
  {
    text: "Group Support",
    value: "COMMUNITY",
    caption: 'I want to be in supportive groups for motivation, accountability and connection!'
  },
  {
    text: "1-on-1 Coach Support",
    value: "COACH",
    caption: 'I want a world-class coach for daily 1-on-1 accountability, direction and guidance!'
  },
];
        
export const companySizeOptions =[
  {
    text: "1 - 10",
    value: "SMALL",
  },
  {
    text: "11 - 200",
    value: "MEDIUM",
  },
  {
    text: "201+",
    value: "LARGE",
  },
];
       