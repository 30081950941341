import gql from "graphql-tag";

export const NUTRITION_BLOCK_QUERY = gql`
  query nutritionBlock($nutritionBlockId: ID!) {
    nutritionBlock(nutritionBlockId: $nutritionBlockId) {
      id
      title
      image
      description
      isDefault
      userList {
        firstName
        lastName
        profilePicture
      }
      leadInstructor {
        id
        firstName
        lastName
        userProfile {
          id
          profilePicture
        }
      }
      groupSet {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

export const NUTRITION_BLOCKS_QUERY = gql`
  query nutritionBlocks($leadInstructorsIn: [ID], $nutritionPreferenceIn: [ID], $tags: String, $startDateLteOrNull: Date, $isActive: Boolean, $search: String, $showInSearches: Boolean, $orderBy: String) {
    nutritionBlocks(
      leadInstructor_In: $leadInstructorsIn
      nutritionPreference_In: $nutritionPreferenceIn
      tags: $tags
      startDateLteOrNull: $startDateLteOrNull
      isActive: $isActive
      search: $search,
      showInSearches: $showInSearches
      orderBy: $orderBy
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          title
          image
          showInSearches
          leadInstructor {
            id
            firstName
            lastName
            userProfile {
              id
              profilePicture
            }
          }
          
        }
      }
    }
  }
`;

export const BLOCK_RECIPE_QUERY = gql`
  query subscriptionBlockRecipeQuery($blockRecipeId: ID!) {
    subscriptionNutritionBlockRecipe(id: $blockRecipeId) {
      id
      date
      meal
      recipe {
        id
        title
        description
        image
        videoUrl
        directions
        meal
        rating
        preferences {
          id
          title
          type
          isPrimary
        }
        recipeingredientSet {
          id
          sortOrder
          quantitySmall
          quantityMedium
          quantityLarge
          quantityExtraLarge
          measurementUnit {
            id
            singular
            plural
          }
          preparation {
            id
            title
          }
          ingredient {
            id
            singular
            plural
            kcal100Grams
            protein100Grams
            fat100Grams
            carbs100Grams
            energyDensity
            serving
            reference
            referenceServingWeight
          }
        }
      }
    }
  }
`;

export const RECIPE_QUERY = gql`
  query recipe($id: ID!) {
    recipe(id: $id) {
      id
      title
      description
      image
      videoUrl
      directions
      meal
      rating
      createdAt
      updatedAt
      openAccess
      availableToLife
      isClientMade
      owner {
        id
        firstName
        lastName
        isStaff
      }
      preferences {
        id
        title
        type
        isPrimary
      }
      recipemacroSet {
        id
        type
        servingSize
        quantity
      }
      recipeingredientSet {
        id
        sortOrder
        quantitySmall
        quantityMedium
        quantityLarge
        quantityExtraLarge
        measurementUnit {
          id
          singular
          plural
        }
        preparation {
          id
          title
        }
        ingredient {
          id
          singular
          plural
          kcal100Grams
          protein100Grams
          fat100Grams
          carbs100Grams
          energyDensity
          serving
          reference
          referenceServingWeight
        }
      }
    }
  }
`;

export const RECOMMENDED_RECIPES_QUERY = gql`
  query recommendedRecipes($input: InputRecommendedRecipeFilter!) {
    recommendedRecipes(searchFilter: $input) {
      id
      title
      description
      meal
      image
      preferences {
        id
        title
        type
        isPrimary
      }
    }
  }
`;

export const RECIPE_ALTERNATES_QUERY = gql`
  query recipeAlternates($input: InputRecipeAlternatesFilter!) {
    recipeAlternates(searchFilter: $input) {
      id
      title
      description
      meal
      image
      preferences {
        id
        title
        type
        isPrimary
      }
    }
  }
`;

export const RECOMMENDED_PORTION_QUERY = gql`
  query recommendedPortion {
    me {
      id
      userProfile {
        id
        calculatedServingSize
      }
    }
  }
`;

export const WEEK_RECOMMENDED_RECIPES_QUERY = gql`
  query recipesForWeek($anchorDate: Date!, $nutritionBlockId: ID!, $type: String!, $subscriptionBlockId: ID) {
    recipesForWeek(
      anchorDate: $anchorDate
      nutritionBlockId: $nutritionBlockId
      subscriptionBlockId: $subscriptionBlockId
    ) {
      id
      day
      nutritionblockdayrecipeSet(type: $type) {
        edges {
          node {
            id
            type
            meal
            recipe {
              id
              title
              description
              meal
              image
              preferences {
                id
                title
                type
                isPrimary
              }
            }
          }
        }
      }
    }
  }
`;

export const DAY_RECOMMENDED_RECIPES_QUERY = gql`
  query recipesForDay($anchorDate: Date!, $nutritionBlockId: ID!, $type: String!, $subscriptionBlockId: ID) {
    recipesForDay(
      anchorDate: $anchorDate
      nutritionBlockId: $nutritionBlockId
      subscriptionBlockId: $subscriptionBlockId
    ) {
      id
      day
      nutritionblockdayrecipeSet(type: $type) {
        edges {
          node {
            id
            type
            meal
            recipe {
              id
              title
              description
              meal
              image
              preferences {
                id
                title
                type
                isPrimary
              }
            }
          }
        }
      }
    }
  }
`;

export const NUTRITION_BLOCK_NUTRITION_LOG = gql`
  query nutritionBlockNutritionLog($nutritionBlockId: ID, $recipeId: ID, $meal: String, $date: Date!) {
    nutritionBlockNutritionLog(nutritionBlockId: $nutritionBlockId, recipeId: $recipeId, meal: $meal, date: $date) {
      id
      image
      comments
      date
      onTrack
      customTitle
    }
  }
`;

export const NUTRITION_BLOCK_NUTRITION_LOGS = gql`
  query nutritionBlockLogs($nutritionBlockId: String!) {
    nutritionBlockNutritionLogs(nutritionBlockId: $nutritionBlockId) {
      edges {
        node {
          id
          image
          comments
          date
          meal
          customTitle
        }
      }
    }
  }
`;

export const RECOMMENDED_NUTRITION_BLOCKS_QUERY = gql`
  query recommendedNutritionBlocks($limit: Int) {
    recommendedNutritionBlocks(limit: $limit) {
      score
      breakdown {
        favouriteInstructor
        matchingTags {
          tag
          score
        }
      }
      block {
        id
        title
        image
        leadInstructor {
          id
          firstName
          lastName
          userProfile {
            id
            profilePicture
          }
        }
      }
    }
  }
`;

export const USER_SWAPPED_RECIPES_QUERY = gql`
  query userSwappedRecipes($startDate: Date!, $endDate: Date!) {
    userSwappedRecipes(date_Gte: $startDate, date_Lte: $endDate) {
      edges {
        node {
          id
          date
          meal
          recipe {
            id
            title
            description
            meal
            image
            preferences {
              id
              title
              type
              isPrimary
            }
          }
        }
      }
    }
  }
`;

export const NUTRITION_SUBSCRIPTION_BLOCKS = gql`
  query nutritionSubscriptionBlocks($nutritionBlock_IsActive: Boolean, $startDate_Gte: Date) {
    nutritionSubscriptionBlocks(nutritionBlock_IsActive: $nutritionBlock_IsActive, startDate_Gte: $startDate_Gte) {
      edges {
        node {
          id
          startDate
          endDate
          nutritionBlock {
            id
            isActive
          }
        }
      }
    }
  }
`;
