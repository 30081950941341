export default {
  data() {
    return {
      initializing: true,
      loadingNewData: false,
      tab: 0,
      activeTab: 0,
    };
  },
  watch: {
    dateRange() {
      this.loadingNewData = true;
    },
  },
  computed: {
    dateRange() {
      if (this.activeTab == 0) {
        return "this_week";
      }
      if (this.activeTab == 1) {
        return "last_week";
      }
      return "all_time";
    },
  },
  methods: {
    queryDateRange() {
      switch (this.dateRange) {
        case "this_week":
          return {
            startDate: this.$moment().startOf("isoweek").format("YYYY-MM-DD"),
            endDate: this.$moment().endOf("isoweek").format("YYYY-MM-DD"),
          };
        case "last_week":
          return {
            startDate: this.$moment().add(-1, "weeks").startOf("isoweek").format("YYYY-MM-DD"),
            endDate: this.$moment().add(-1, "weeks").endOf("isoweek").format("YYYY-MM-DD"),
          };
        default:
          return { startDate: null, endDate: null };
      }
    },
  },
};
