import gql from 'graphql-tag'

export const SEARCH_QUERIES = {

  WORKOUT_BLOCKS: gql`
    query searchWorkoutBlocks($search: String!, $page: Int!, $pageSize: Int!) {
      searchWorkoutBlocks(search:$search, page:$page, pageSize:$pageSize) {
        totalPages
        totalResults
        hasMore
        workoutBlocks {
          id
          title
          image
          visible
          leadInstructor {
            id
            firstName
            lastName
          }
        }
      }
    }
  `,

  EVENTS: gql`
    query searchEvents($search: String!, $page: Int!, $pageSize: Int!) {
      searchEvents(search:$search, page:$page, pageSize:$pageSize) {
        totalPages
        totalResults
        hasMore
        events {
          id
          name
          thumbnail
          scheduledDate
          eventType {
            id
            category
          }
          useractivitySet(last:1){
            edges {
              node {
                id
                startDate
              }
            }
          }
        }
      }
    }
  `,

  NUTRITION_BLOCKS: gql`
    query searchNutritionBlocks($search: String!, $page: Int!, $pageSize: Int!) {
      searchNutritionBlocks(search:$search, page:$page, pageSize:$pageSize) {
        totalPages
        totalResults
        hasMore
        nutritionBlocks {
          id
          title
          description
          image
        }
      }
    }
  `,

  GROUPS: gql`
    query searchGroups($search: String!, $page: Int!, $pageSize: Int!) {
      searchGroups(search:$search, page:$page, pageSize:$pageSize) {
        totalPages
        totalResults
        hasMore
        groups {
          id
          title
          banner
        }
      }
    }
  `

}