<template>
  <v-container>
    <div>
      <v-row align="center" justify="center">
        <v-col cols="12">
          <v-card>
            <v-tabs
              v-model="tab"
              centered
              background-color="tabs"
              color="secondary"
              active-class="selected-tab-active"
              slider-size="0"
              class="selected-tabs-active"
            >
              <v-tab key="log">
                Log
              </v-tab>
              <v-tab key="principals">
                Principles
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="tab" class="py-2">
              <v-tab-item key="log" class="mx-2 pb-2">
                <v-container class="py-0 my-0">
                  <div class="text-center ma-12" v-if="!loaded">
                    <v-progress-circular
                      :indeterminate="!loaded"
                      color="blue"
                    ></v-progress-circular>
                  </div>
                  <v-form v-if="loaded" ref="form">
                    <v-row
                      v-for="question in nutritionQuestions"
                      :key="question.id"
                      align="center"
                      justify="center"
                    >
                      <v-col cols="11" class="pa-0">
                        <div>
                          <strong class="secondary--text">{{
                            question.title
                          }}</strong>
                        </div>
                        <div>
                          <v-checkbox
                            class="mt-0"
                            v-model="question.answer"
                            :label="question.question"
                          />
                        </div>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="nutritionLogMutable && allQuestionsTrue"
                      align="center"
                      justify="center"
                    >
                      <v-col cols="11" class="pa-1">
                        <v-alert type="success">
                          Awesome, {{ nutritionQuestions.length }} out of
                          {{ nutritionQuestions.length }}! You've achieved a
                          Green Dot Day!
                        </v-alert>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="
                        nutritionLogMutable &&
                          !nutritionLogMutable.isNutritiousDay
                      "
                      align="center"
                      justify="center"
                    >
                      <v-col cols="11" class="mb-0 pa-1 pb-0">
                        <v-alert type="success" class="mb-0">
                          Well done for logging your nutrition!
                        </v-alert>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="
                        nutritionLogMutable &&
                          !nutritionLogMutable.isNutritiousDay
                      "
                      align="center"
                      justify="center"
                    >
                      <v-col cols="11" class="mb-0 pa-1 pb-0">
                        <v-alert type="info" class="mb-0">
                          Earn a green dot on days you complete all
                          {{ nutritionQuestions.length }} principles.
                        </v-alert>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                      <v-col cols="11" align="center" justify="center">
                        <div v-if="nutritionLogMutable">
                          Nutrition saved. If needed, make changes and tap
                          UPDATE.
                        </div>
                        <v-btn
                          color="secondary"
                          class="dashboard-button mt-1"
                          large
                          rounded
                          :loading="saving"
                          @click="save()"
                        >
                          <span v-if="nutritionLogMutable">Update</span>
                          <span v-else>Save</span>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-tab-item>
              <v-tab-item key="principals" class="mx-2 pb-2">
                <v-row align="center" justify="center">
                  <v-col cols="11" md="8" style="color:rgba(0, 0, 0, 0.6)">
                    <h2 class="secondary--text">{{ $appName }} Nutrition Principles</h2>
                    <br />
                    <p class="mb-10">
                      At {{ $appName }} we appreciate the role of each pillar of health:
                      exercise, mindset and nutrition. We believe in the power
                      of food and a positive relationship with what, how and
                      when we eat. These are our nutrition principles we strive
                      for 5.5 days of every week.
                    </p>
                    <ol>
                      <li class="mb-6">
                        <p
                          class="pb-1 font-weight-bold"
                          style="border-bottom: 1px solid #cccccc"
                        >
                          MY MEALS NOURISH ME
                        </p>
                        Meals should combine sources of protein, fiber and fat,
                        the best quality you can access. Eating well is not
                        about restriction, fuel your body well. Eat frequently
                        enough to sustain your energy and lifestyle.
                      </li>
                      <li class="mb-6">
                        <p
                          class="pb-1 font-weight-bold"
                          style="border-bottom: 1px solid #cccccc"
                        >
                          I CHOOSE MY DRINKS WITH CARE
                        </p>
                        Daily fluids should come from drinking enough water or
                        calorie free teas and infusions. Limit sugar containing
                        drinks, juices, caffeine and alcohol.
                      </li>
                      <li class="mb-6">
                        <p
                          class="pb-1 font-weight-bold"
                          style="border-bottom: 1px solid #cccccc"
                        >
                          I SNACK WITH INTENT
                        </p>
                        Snacks should be an opportunity to eat well, prevent
                        hunger or avoid other poor food choices. Be mindful of
                        snacking from habit or emotional cues.
                      </li>
                      <li class="mb-6">
                        <p
                          class="pb-1 font-weight-bold"
                          style="border-bottom: 1px solid #cccccc"
                        >
                          I AM AWARE OF MY PORTIONS
                        </p>
                        Eat enough to sustain you and your goals. You should
                        never feel as though you have eaten too much and are
                        stuffed or too little and deprived or starving. Eat
                        until satisfied.
                      </li>
                      <li class="mb-6">
                        <p
                          class="pb-1 font-weight-bold"
                          style="border-bottom: 1px solid #cccccc"
                        >
                          I PLAN, PREPARE AND REVIEW WHAT I AM DOING
                        </p>
                        Create a food environment to support your journey. Plan
                        your meals, prepare as much of your own as you can and
                        assess your progress as you go. What you are doing now
                        should be a sustainable choice.
                      </li>
                    </ol>
                  </v-col>
                </v-row>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import { NUTRITION_QUESTIONS } from "@/graphql/queries/nutrition";
import { LOG_NUTRITION_MUTATION } from "@/graphql/mutations/nutrition";
import { mapGetters } from "vuex";
import BadgeToaster from "@/mixins/BadgeToaster";

export default {
  name: "NutritionLogging",
  mixins: [BadgeToaster],
  props: {
    nutritionLog: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loaded: false,
      tab: "",
      nutritionLogMutable: this.nutritionLog,
      saving: false,
      nutritionQuestions: [],
    };
  },
  computed: {
    ...mapGetters(["selectedDate"]),
    allQuestionsTrue() {
      return this.nutritionQuestions.every((answer) => answer.answer != false);
    },
  },
  methods: {
    getQuestionAnswer(id) {
      if (this.nutritionLog == null) return false;
      let existingAnswer = this.nutritionLog.usernutritionloganswerSet.find(
        (item) => item.nutritionQuestion.id === id
      );
      if (existingAnswer) return existingAnswer.answer;
      return false;
    },
    save() {
      this.saving = true;

      let date;
      if (this.nutritionLog && this.nutritionLog.date) {
        date = this.nutritionLog.date;
      } else if (this.selectedDate) {
        date = this.selectedDate.format("YYYY-MM-DD");
      } else {
        date = this.$moment().format("YYYY-MM-DD");
      }
      let nutritionInput = {
        date: date,
        answers: this.nutritionQuestions.map((question) => {
          return { questionId: question.id, answer: question.answer };
        }),
      };
      this.$apollo
        .mutate({
          mutation: LOG_NUTRITION_MUTATION,
          variables: {
            nutritionLogInput: nutritionInput,
          },
          refetchQueries: ["activitiesQuery", "nutritionLogQuery", "nutritionQuery", "recipesForWeek", "nutritionBlockLogs", "nutritionBlockNutritionLogs"],
        })
        .then((response) => {
          if (response.data) {
            this.nutritionLogMutable =
              response.data.upsertNutritionLog.nutritionLog;
            this.$emit("updated", "updated");
            this.saving = false;

            if (response.data.upsertNutritionLog.earnedBadges) {
              let badges = response.data.upsertNutritionLog.earnedBadges;

              this.toastBadges(badges);
            }
          }
        })
        .catch((error) => {
          throw error;
        });
    },
  },
  apollo: {
    nutritionQuestions: {
      query: NUTRITION_QUESTIONS,
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data) {
          this.nutritionQuestions = response.data.nutritionQuestions.map(
            (item) => {
              return {
                id: item.id,
                order: item.order,
                question: item.question,
                title: item.title,
                answer: this.getQuestionAnswer(item.id),
              };
            }
          );

          this.loaded = true;
        }
      },
    },
  },
};
</script>
