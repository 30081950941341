<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" class="mb-0 pb-0">
        <router-link
          class="subtitle-1 grey-navy--text font-weight-bold text-decoration-none"
          to="/social/community-fitness"
        >
          <v-icon small class="pb-1">
            keyboard_backspace
          </v-icon>
          Community Events
        </router-link>
        <h1 class="text-center grey-navy--text">Event Details</h1>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" class="mb-0 pb-0" align="center" justify="center">
        <v-progress-circular v-if="loading" :indeterminate="true" color="blue" />
        <j-card v-else class="j-elevated-1">
          <v-list>
            <v-list-item align="left" class="mb-0">
              <v-list-item-content class="card-list-title mt-2 mb-0">
                <v-list-item-title class="std-text text-uppercase">{{ meetup.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item align="left">
              <v-list-item-content class="card-list-title my-0 py-0">
                <v-row class="my-0 py-0">
                  <v-col cols="4" class="my-0 py-0">
                    <j-btn color="whatsapp-green" large dark target="_blank" :href="whatsappShareUrl">
                      <strong>Share via Whatsapp<v-icon class="pb-1 pl-1">mdi-whatsapp</v-icon></strong>
                    </j-btn>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-list-item align="left" class="py-0">
              <v-list-item-content class="py-1 my-0">
                <v-list-item-subtitle class="mt-3">When</v-list-item-subtitle>
                <v-list-item-title class="mt-0 grey-navy--text">{{ eventDate }} | {{ eventTime }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item align="left" class="py-0">
              <v-list-item-content class="py-1 my-0">
                <v-list-item-subtitle class="mt-2">Hosted By</v-list-item-subtitle>
                <v-list-item-title class="grey-navy--text text-capitalize">
                  {{ hostName }}
                  <span v-if="attendingEvent"> - ({{ meetup.contactNumber }})</span>
                  <span v-if="!attendingEvent && !isHost"> (Attend event to view contact details)</span>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item align="left" class="py-0">
              <v-list-item-content class="py-1 my-0">
                <v-list-item-subtitle class="mt-2">Attendees</v-list-item-subtitle>
                <attendee-list :attendees="meetup.attendees" :host="meetup.host"></attendee-list>
                <v-list-item-subtitle class="mt-2">{{ attendees }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="showAttendButton || attendingEvent" align="left" class="py-0">
              <v-list-item-content class="py-1 my-0">
                <v-row class="pa-0 ma-0">
                  <v-col cols="12" align="left" class="pa-0 ma-0">
                    <j-btn
                      v-if="showAttendButton"
                      class="mr-2 mb-2 pa-1"
                      @click="attendDialog=true"
                      :disabled="changingAttendance"
                    >
                      Attend Event
                    </j-btn>
                    <j-btn v-if="attendingEvent" @click="attend(false)">Cancel Attendance</j-btn>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="meetup.notes !== ''" align="left" class="py-0">
              <v-list-item-content class="py-1 my-0">
                <v-list-item-subtitle class="mt-2">Information</v-list-item-subtitle>
                <v-list-item-title class="grey-navy--text">{{ meetup.notes }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="meetup.type === 'PHYSICAL'" align="left" class="py-0">
              <v-list-item-content class="py-1 my-0">
                <v-list-item-subtitle>Location</v-list-item-subtitle>
                <v-list-item-title class="grey-navy--text mb-2">{{ meetup.location }}</v-list-item-title>
                <google-map
                  :center="mapLocation"
                  :zoom="14"
                  :options="{
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: true,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: true,
                    disableDefaultUi: false,
                    draggable: true,
                  }"
                  style="width: 100%; height: 300px; position: relative"
                >
                  <google-marker :position="mapLocation" :clickable="true" :draggable="false"></google-marker>
                </google-map>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="meetup.type === 'VIRTUAL'" align="left" class="py-0">
              <v-list-item-content class="py-1 my-0">
                <v-list-item-subtitle class="mt-2">Meeting Link</v-list-item-subtitle>
                <v-list-item-title class="grey-navy--text">
                  <a :href="meetup.meetingLink" target="_blank">{{ meetup.meetingLink }}</a>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-actions v-if="isHost" align="right">
            <v-spacer />
            <j-btn class="mb-2 mr-2 pa-0" :to="'/social/community-fitness/edit/' + id">Edit</j-btn>
          </v-card-actions>
        </j-card>
      </v-col>
    </v-row>
    
    <v-dialog
      v-model="attendDialog"
      persistent
      width="500"
    >
      <j-card>
        <v-card-title class="headline grey lighten-2">
          Terms and Conditions
        </v-card-title>
        <v-card-text>
          <p>Your mobile number will be shared with the host so they can send you event updates.</p>
          <strong>Safety</strong>
          <ul>
            <li>In general terms, attend events where you know the people attending.</li>
            <li>In the unlikely event that you are asked for private information, be thoughtful in sharing this information.</li>
            <li>Participation in events is done at your own risk.</li>
          </ul>
          <br>
          <strong>Protocols</strong>
          <ul>
            <li>Wear a mask and observe safe social distancing.</li>
            <li>Ensure that the event area is well ventilated.</li>
          </ul>
          <br/>
          <p>By tapping 'I accept' you accept the Terms and Conditions.</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <j-btn
            tertiary
            @click="attendDialog=false"
          >
            No Thanks
          </j-btn>
          <j-btn
            primary
            @click="attend(true)"
          >
            I accept
          </j-btn>
        </v-card-actions>
      </j-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { MEETUP_QUERY } from "@/graphql/queries/communityFitness";
import { ATTEND_MEETUP_MUTATION } from "@/graphql/mutations/communityFitness";
import { fancy_date_from_iso } from "@/lib/fancy_date";
import { get_community_event_share_link } from "@/lib/whatsapp";
import AttendeeList from "@/components/community-fitness/AttendeeList";
import { capitalize } from "@/lib/string";

export default {
  name: "MeetingInfo",
  components: {
    AttendeeList,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
      changingAttendance: false,
      attendDialog: false
    };
  },
  computed: {
    actualID() {
      return atob(this.id).split(":")[1];
    },
    eventDate() {
      let localDate = this.$moment(this.meetup.eventDate)
        .local(true)
        .format("YYYY-MM-DD HH:mm:ss");
      return fancy_date_from_iso(localDate, "ddd, D MMMM YYYY", false);
    },
    eventTime() {
      let localTime = this.$moment(this.meetup.eventDate)
        .local(true)
        .format("LT");
      return localTime;
    },
    eventType() {
      return this.meetup.type === "PHYSICAL" ? "In Person" : "Virtual";
    },
    attendees() {
      if (this.isHost) return this.allAttendees;

      let count = `(${this.meetup.attendees.length} / ${this.meetup.capacity}) `;

      let names = [];
      for (let i = 0; i < 5; i++) {
        if (this.meetup.attendees[i]) {
          let name = this.meetup.attendees[i].attendee.firstName;
          if (name.trim() === "") {
            name = this.meetup.attendees[i].attendee.firstName;
          }
          names.push(capitalize(name));
        }
      }
      let ret = names.join(", ");
      if (this.meetup.attendees.length > 4) {
        let diff = this.meetup.attendees.length - 4;
        ret += " and " + (this.meetup.attendees.length - 4).toString();
        ret += diff > 1 ? " others" : " other";
      }
      return count + ret;
    },
    allAttendees() {
      let count = `(${this.meetup.attendees.length} / ${this.meetup.capacity}) `;
      let mapped = this.meetup.attendees.map((elem) => {
        return capitalize(elem.attendee.firstName) + " " + capitalize(elem.attendee.lastName);
      });
      mapped = ["You"].concat(mapped);
      return count + " " + mapped.join(", ");
    },
    mapLocation() {
      return {
        lat: parseFloat(this.meetup.latitude),
        lng: parseFloat(this.meetup.longitude),
      };
    },
    isHost() {
      let userId = this.$store.getters.user.id;
      return parseInt(this.meetup.host.id) === parseInt(userId);
    },
    atCapacity() {
      return this.meetup.attendees.length >= this.meetup.capacity;
    },
    attendingEvent() {
      let userId = this.$store.getters.user.id;
      return this.meetup.attendees.filter((entry) => parseInt(entry.attendee.id) === parseInt(userId)).length > 0;
    },
    showAttendButton() {
      if (this.isHost) return false;
      //user is hosting event
      else if (this.atCapacity) return false;
      //meeting has no more capacity
      else if (this.attendingEvent) return false; //user is already attending meeting
      return true;
    },
    whatsappShareUrl() {
      return get_community_event_share_link(this.id, this.meetup.title, this.$appName);
    },
    hostName() {
      let name = this.meetup.host.firstName;
      let surname = this.meetup.host.lastName;

      return `${name} ${surname}`;
    },
  },
  methods: {
    attend(attending = true) {
      this.attendDialog = false

      if (!attending) {
        let confirm = window.confirm("Are you sure you no longer want to attend: " + this.meetup.title + "?");
        if (!confirm) return;
      }

      this.changingAttendance = true;

      this.$apollo
        .mutate({
          mutation: ATTEND_MEETUP_MUTATION,
          variables: {
            meetupId: atob(this.id).split(":")[1],
            attending: attending,
          },
          refetchQueries: ["meetup"],
        })
        .then((response) => {
          this.changingAttendance = false;
          if (response.data && response.data.attendMeetup.success) {
            let message = attending ? "You are now attending " : "You are no longer attending ";
            this.$toasted.success(message + this.meetup.title, {
              icon: "arm-flex",
              duration: 2000,
              position: "bottom-center",
            });
            this.loading = true;
          } else if (response.data && !response.data.attendMeetup.success) {
            this.$toasted.error(response.data.attendMeetup.error, {
              icon: "warn",
              duration: 2000,
              position: "bottom-center",
            });
          }
        })
        .catch((error) => {
          throw error;
        });
    },
  },
  apollo: {
    meetup: {
      query: MEETUP_QUERY,
      fetchPolicy: "network-only",
      result(response) {
        if (response.data) {
          this.loading = false;
        }
      },
      variables() {
        return { id: this.actualID };
      },
    },
  },
};
</script>
