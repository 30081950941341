<template>
  <v-alert
    outlined
    text
    :color="getTextColor"
    :icon="getIcon"
    v-bind="$props"
    v-on="$listeners"
    :class="getAlertColor + '--text'"
    class="text-left"
  >
    <span :class="getTextColor + '--text'"><slot /></span>
  </v-alert>
</template>

<script>
import { VAlert } from "vuetify/lib";

export default {
  name: "JAlert",
  extends: VAlert,
  props: {
    type: {
      type: String,
      default: "info",
    },
    // icon is a prop of VAlert, but if I don't declare here, console throws error
    // ```Invalid prop: custom validator check failed for prop```
    // delcaring it here to supress the error.
    icon: {
      type: [Boolean, String],
      default: undefined,
    },
  },
  data() {
    return {
      typeMap: {
        error: "pink",
        info: "blue",
        success: "green",
        warning: "yellow",
      },
      typeIconMap: {
        error: "mdi-close-circle-outline",
        info: "mdi-star-circle-outline",
        success: "mdi-check-circle-outline",
        warning: "mdi-alert-outline",
      },
      colourOptions: ["blue", "grey", "pink", "red", "yellow", "green"],
    };
  },
  computed: {
    getAlertColor() {
      let color = this.color ? this.color : this.typeMap[this.type];
      if (this.colourOptions.includes(color)) return `${color}-icon`;

      return color;
    },
    getTextColor() {
      let color = this.color ? this.color : this.type;
      return `${color}`;
    },
    getIcon() {
      if (this.icon === false) return false;
      return this.icon ?? this.typeIconMap[this.type];
    },
  },
};
</script>
