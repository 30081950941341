<template>
    <v-form>
      <gmap-autocomplete @place_changed="setLocation" autocomplete="off">
        <template v-slot:input="slotProps">
          <ValidationProvider name="billing_location" v-slot="{ errors }">
            <v-text-field
              v-model="billing.tempLocation"
              name="billing_location"
              dense
              outlined
              prepend-inner-icon="place"
              placeholder="Type Your Address"
              :error-messages="errors"
              ref="input"
              v-on:listeners="slotProps.listeners"
              v-on:attrs="slotProps.attrs"
              autocomplete="false"
              list="autocompleteOff"
            >
            </v-text-field>
          </ValidationProvider>
        </template>
      </gmap-autocomplete>
      <p class="sm-text mt-n5">Helps us keep you informed of things happening near by</p>
      <div v-if="billing.tempLocation !== ''">
        <v-text-field
          v-model="billing.address.addressLine1"
          dense
          outlined
          label="Address Line 1"
          disabled
        >
        </v-text-field>
        <div class="d-flex half-width">
          <v-text-field
            v-model="billing.address.suburb"
            outlined
            dense
            label="Suburb"
            disabled
          ></v-text-field>
          <v-text-field
            class="ml-2"
            v-model="billing.address.city"
            outlined
            dense
            label="City"
            disabled
          ></v-text-field>
        </div>
        <div class="d-flex half-width">
          <v-text-field
            v-model="billing.address.country.code"
            outlined
            dense
            label="Country"
            disabled
          ></v-text-field>
          <v-text-field
            class="ml-2"
            v-model="billing.address.postalCode"
            outlined
            dense
            label="Postal Code"
            disabled
          ></v-text-field>
        </div>
      </div>
    </v-form>

</template>

<script>
import { mapGetters } from "vuex";
import {
  BILLING_DETAILS_QUERY,
} from "@/graphql/queries/store";
import {
  UPDATE_BILLING_DETAILS_MUTATION,
} from "@/graphql/mutations/store";


export default {
  name: "BillingDetails",
  data() {
    return {
      billing: {
        firstName: "",
        lastName: "",
        company: "",
        tempLocation: "",
        address: {
          addressLine1: "",
          addressLine2: "",
          suburb: "",
          city: "",
          country: {
            code: "",
            name: "",
          },
          postalCode: "",
          longitude: "",
          latitude: "",
        },
      },
    };
  },
  computed: {
    ...mapGetters(["billingDetails"]),
  },
  methods: {
    emitSaved() {
      this.$emit("billingSaved", true);
    },
    setLocation(location) {
      this.billing.tempLocation = location.formatted_address;
      this.billing.address.addressLine1 = location.formatted_address;

      if (location.vicinity) this.billing.address.suburb = location.vicinity;
      else this.billing.address.suburb = "";

      let postalCode = location.address_components.find((element) =>
        element.types.includes("postal_code")
      );
      if (postalCode) this.billing.address.postalCode = postalCode.long_name;
      let country = location.address_components.find((element) =>
        element.types.includes("country")
      );
      if (country) {
        this.billing.address.country.code = country.short_name;
        this.billing.address.country.name = country.long_name;
      }
      let city = location.address_components.find(
        (element) =>
          element.types.includes("locality") ||
          element.types.includes("postal_town")
      );
      if (city) {
        this.billing.address.city = city.long_name;
      } else {
        this.city = "";
      }
      this.billing.address.latitude = location.geometry.location.lat();
      this.billing.address.longitude = location.geometry.location.lng();

      this.updateBillingDetails();
    },
    updateBillingDetails() {
      let data = {
        paymentId: sessionStorage.getItem("paymentId"),
        address: this.billing.address.addressLine1,
        addressLine2: this.billing.address.addressLine2,
        suburb: this.billing.address.suburb,
        city: this.billing.address.city,
        postalCode: this.billing.address.postalCode,
        country: this.billing.address.country.code,
        longitude: this.billing.address.longitude,
        latitude: this.billing.address.latitude,
      };
      
      this.$apollo
        .mutate({
          mutation: UPDATE_BILLING_DETAILS_MUTATION,
          variables: {
            input: data,
          },
        })
        .then((response) => {
          if (response.data && !response.loading) {
            if (response.data.billingDetailsMutation.success) {
              this.emitSaved();

              data.gmapLine = this.billing.tempLocation;
              this.$store.dispatch("updateBillingDetails", data);
            }
          }
        })
        .catch((error) => {
          throw error;
        });
    },
  },
  apollo: {
    billingDetails: {
      query: BILLING_DETAILS_QUERY,
      fetchPolicy: "cache-and-network",
      update(data) {
        if (data.me && data.me.userProfile && data.me.userProfile.address) {
          let profile = data.me.userProfile;
          this.billing = {
            tempLocation: profile.address,
            company: profile.company,
            address: {
              addressLine1: profile.address,
              addressLine2: profile.addressLine2,
              suburb: profile.suburb,
              city: profile.city,
              postalCode: profile.postcode,
              country: profile.country,
              longitude: profile.longitude,
              latitude: profile.latitude
            },
          };

          this.emitSaved();
          return this.billing;
        }
      },
      skip() {
        if (this.billingDetails) {
          let details = this.billingDetails;

          this.billing = {
            tempLocation: details.address,
            company: details.company,
            address: {
              addressLine1: details.address,
              addressLine2: details.addressLine2,
              suburb: details.suburb,
              city: details.city,
              postalCode: details.postalCode,
              country: {
                code: details.country
              },
              longitude: details.longitude,
              latitude: details.latitude
            },
          }

          this.emitSaved();
          return true;
        }
        return false;
      },
    },
  },
};
</script>

<style scoped>
#billingSection div.d-flex .v-input:first-of-type {
  margin-right: 8px;
}
#billingSection div.d-flex .v-input:last-of-type {
  margin-left: 8px;
}
</style>
