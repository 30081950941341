<template>
  <v-container class="fill-height pt-0">
    <v-row align="center" justify="center" class="fill-height">
      <v-col cols="12" md="6" class="background-grey-3 fill-height">
        <div class="d-flex pb-2">
          <v-chip color="green-background" dense text-color="black" class="font-16 mx-auto">
            <strong>1 of 3</strong>
          </v-chip>
        </div>
        <h1 class="heading-2 navy--text text-center px-5 pt-3">Choose your biggest obstacle to getting started</h1>

        <j-radio-group
          v-model="selectedObstacle"
          mandatory
          @change="$store.dispatch('updateObstacle', selectedObstacle)"
          class="mt-0"
        >
          <div v-for="option in obstacleOptions" :key="option.value" class="d-flex flex-column flex-column-reverse">
            <j-card v-if="option.value == 'other' && isOther" rounded="lg" class="mt-n5">
              <v-card-text class="pb-0 px-2">
                <v-textarea
                  v-model="otherText"
                  flat
                  solo
                  rows="1"
                  hide-details
                  auto-grow
                  placeholder="Type here"
                  @change="$store.dispatch('updateOtherObstacleText', otherText)"
                />
              </v-card-text>
            </j-card>
            <j-card rounded="lg" :class="{ 'pink-border': option.value == selectedObstacle }" class="mt-4">
              <j-radio color="pink" :value="option.value" hide-details class="pa-3 mt-0">
                <div slot="label" class="std-text navy--text ml-1">
                  {{ option.text }}
                </div>
              </j-radio>
            </j-card>
          </div>
        </j-radio-group>

        <j-btn to="/start/education" color="pink" wide class="d-flex mx-auto">Continue</j-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Obstacle",
  data() {
    return {
      selectedObstacle: null,
      otherText: null,
      obstacleOptions: [
        { text: "Not enough money", value: "money" },
        { text: "Not enough time", value: "time" },
        { text: "Not enough willpower", value: "willpower" },
        { text: "Other", value: "other" },
      ],
    };
  },
  created() {
    this.selectedObstacle = this.$store.getters.obstacle;
    this.otherText = this.$store.getters.otherObstacleText;
  },
  computed: {
    isOther() {
      return this.selectedObstacle == "other";
    },
  },
};
</script>

<style lang="scss" scoped>
.j-card.pink-border {
  border: 2px solid var(--v-pink-base);
}
.foreground {
  z-index: 1;
}
.background {
  z-index: 0;
}
</style>
