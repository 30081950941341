import gql from "graphql-tag";

export const UPDATE_NOTIFICATION_MUTATION = gql`
  mutation updateNotification($notificationId: ID, $postId: ID, $dateRead: DateTime) {
    updateNotification(
      data: {notificationId: $notificationId, postId: $postId, dateRead: $dateRead}
    ) {
      success
      error
    }
  }
`;
