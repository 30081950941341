<template>
  <div v-if="hasUserMedia()">
    <v-avatar v-if="!isRecording" color="rgb(0, 0, 0, 0.1)" size="40">
      <v-icon @click="recordAudio()"> mdi-microphone </v-icon>
    </v-avatar>

    <div v-if="isRecording" class="d-flex align-center justify-space-between">
      <div class="pulsing-blob"></div>
      <div class="sm-text">{{ time }} secs</div>
      <v-avatar color="rgb(0, 0, 0, 0.1)" size="40" class="ml-2">
        <v-icon @click="stop()" color="green">mdi-send</v-icon>
      </v-avatar>

      <v-avatar color="rgb(0, 0, 0, 0.1)" size="40" class="ml-2">
        <v-icon @click="close()">mdi-trash-can-outline </v-icon>
      </v-avatar>
    </div>
  </div>
</template>

<script>
import { blobToFile } from "@/lib/file";
import MediaMixin from "@/mixins/MediaMixin";

export default {
  name: "AudioRecorder",
  mixins: [MediaMixin],
  data() {
    return {
      recorder: null,
      stream: null,
      chunks: [],
      device: null,
      isRecording: false,
    };
  },
  methods: {
    getDevice() {
      this.device = navigator.mediaDevices.getUserMedia({ audio: true, video: true });
    },
    async recordAudio() {
      this.getDevice();
      this.chunks = [];
      this.device.then((stream) => {
        this.stream = stream;
        this.isRecording = true;
        this.$emit("startRecording");
        this.recorder = new MediaRecorder(stream);
        this.recorder.ondataavailable = async (e) => {
          this.chunks.push(e.data);

          if (this.recorder?.state === "inactive") {
            let blob = new Blob(this.chunks, { type: "audio/wav" });
            this.chunks = [];

            const audioFile = blobToFile(blob);
            // we can also upload here via CREATE_MESSAGE_ATTACHMENTS_MUTATION, but rather emit to parent.
            this.$emit("sendAudio", { audioFile: audioFile, time: this.time });
          }
        };
        this.recorder.start();
        this.startTimer();
      });
    },
    async stop() {
      this.recorder.stop();
      this.stopTimer();
      this.isRecording = false;
      this.$emit("stopRecording");
      if (this.stream) this.stream.getTracks().forEach((track) => track.stop());
    },
    close() {
      this.stopTimer();
      this.recorder = null;
      if (this.stream) this.stream.getTracks().forEach((track) => track.stop());
      this.stream = null;
      this.chunks = [];
      this.device = null;
      this.isRecording = false;
      this.$emit("stopRecording");
    },
  },
};
</script>

<style scoped lang="scss">
.pulsing-blob {
  background: #fc3a64;
  border-radius: 50%;
  margin: 10px;
  height: 20px;
  width: 20px;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 1.5s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
</style>
