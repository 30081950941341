<template>
  <div>
    <v-card class="text-left pa-0 mb-3 card-radius dashboard-card">
      <v-toolbar flat class="pa-0 ma-0" color="rgba(255, 0, 0, 0.0)">
        <span class="p-text grey-navy--text">
          {{ activity.createdAt | moment("ddd, D MMM YY") }}
        </span>
        <v-spacer />
        <span class="p-text secondary--text pt-0 pr-1">
          {{ activity.unitActivity.activity.title }}
        </span>
        <manage-bookmark
          v-if="showBookmark"
          :isObjectNote="true"
          :objectId="activity.unitActivity.id"
          :objectNotes="activity.notes"
          objectType="unit_activity"
          :bookmarkId="!deletedBookmark ? bookmarkId : null"
          refreshQuery="bookmarks"
          @saved="onSaved"
        ></manage-bookmark>
      </v-toolbar>
      <v-list three-line class="pa-0 ma-0">
        <v-list-item @click="handleActivityNavigation(activity)">
          <v-list-item-avatar size="80" class="mt-2 pt-2">
            <v-img :lazy-src="image(activity)" :src="image(activity)"></v-img>
          </v-list-item-avatar>
          <v-list-item-content class="mt-2 pt-2">
            <v-list-item-title class="card-list-title">{{ activity.unitActivity.title }} </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import ManageBookmark from "@/components/bookmarks/block/ManageBookmark";
import { decodeId } from "@/lib/string";

export default {
  name: "BookmarkActivityCard",
  components: {
    ManageBookmark,
  },
  props: {
    activity: {
      type: Object,
      default: null,
    },
    bookmarkId: {
      type: String,
      required: false,
    },
    showBookmark: {
      type: Boolean,
      default: false,
    },
    refreshQuery: {
      type: String,
      default: "bookmarks",
    },
  },
  data: () => ({
    deletedBookmark: false,
  }),
  methods: {
    onSaved(bookmarkId) {
      this.deletedBookmark = bookmarkId < 0;
    },
    handleActivityNavigation(activity) {
      this.$router.push(`/unit-activity/${decodeId(activity.unitActivity.id)}`);
    },
    image(activity) {
      return activity.unitActivity.activity.leadInstructor.userProfile.profilePicture;
    },
  },
};
</script>
<style scoped>
.theme--light.v-list-item:hover::before {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}
.v-list-item--link:before {
  border-bottom-left-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
}

.card-list-title {
  align-self: center !important;
}

.card-radius {
  max-width: 100%;
}
</style>
