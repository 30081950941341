<template>
  <v-container class="fill-height pt-0">
    <v-row align="center" justify="center" class="fill-height">
      <v-col cols="12" md="6" class="background-grey-3 fill-height">
        <div class="d-flex pb-2">
          <v-chip color="green-background" dense ttext-color="black" class="font-16 mx-auto">
            <strong>3 of 3</strong>
          </v-chip>
        </div>
        <h1 class="heading-2 navy--text text-center px-5 pt-3">
          Is there anything else you'd like to tell us about your health & fitness journey & how we can help you?
        </h1>

        <v-textarea
          v-model="otherText"
          flat
          solo
          rows="3"
          auto-grow
          background-color="white"
          color="navy"
          hide-details
          class="ma-3 elevation-1 rounded-lg"
          @change="$store.dispatch('updateOtherText', otherText)"
        />

        <j-btn to="/start/register" color="pink" wide class="d-flex mx-auto mt-4">Continue</j-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AnythingElse",
  data() {
    return {
      otherText: null,
    };
  },
  created() {
    this.otherText = this.$store.getters.otherText;
  },
};
</script>

<style lang="scss" scoped>
.j-card.pink-border {
  border: 2px solid var(--v-pink-base);
}
.foreground {
  z-index: 1;
}
.background {
  z-index: 0;
}
</style>
