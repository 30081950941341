<template>
  <countdown
    ref="countdown"
    :time="milisecondsToEnd"
    :auto-start="true"
    v-slot="{ days, hours, minutes, seconds }"
    @end="timerEnded()"
  >
    <ul>
      <li :class="{ 'large-padding': $vuetify.breakpoint.mdAndUp }" :style="{ color: textColor }">
        <span id="days">{{ days }}</span
        >days
      </li>
      <li :class="{ 'large-padding': $vuetify.breakpoint.mdAndUp }" :style="{ color: textColor }">
        <span id="hours">{{ hours }}</span
        >Hours
      </li>
      <li :class="{ 'large-padding': $vuetify.breakpoint.mdAndUp }" :style="{ color: textColor }">
        <span id="minutes">{{ minutes }}</span
        >Minutes
      </li>
      <li :style="{ color: textColor }">
        <span id="seconds">{{ seconds }}</span
        >Seconds
      </li>
    </ul>
  </countdown>
</template>

<script>
export default {
  name: "GenericCountdownTimer",
  props: {
    milisecondsToEnd: {
      type: Number,
      required: true,
    },
    textColor: {
      type: String,
      default: "white",
    },
  },

  data() {
    return {};
  },
  methods: {
    timerEnded() {
      this.$emit("timerEnded");
    },
  },
};
</script>

<style lang="scss" scoped>
li {
  display: inline-block;
  font-size: 1em;
  list-style-type: none;
  padding: 1em;
  text-transform: uppercase;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.37);
}
.large-padding {
  padding-right: 3.5em;
}

li span {
  display: block;
  font-size: 3.5rem;
}

@media all and (max-width: 768px) {
  h1 {
    font-size: calc(1.5rem * var(--smaller));
  }

  li {
    font-size: calc(1.125rem * var(--smaller));
  }

  li span {
    font-size: calc(3.375rem * var(--smaller));
  }
}
</style>
```
