var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-6"},[_c('j-card-content',{attrs:{"summaryInfo":_vm.summaryInfo,"description":_vm.isCurrent ? '' : _vm.nutritionBlock.description,"accentColor":"green","leftButton":_vm.isMine && !_vm.nutritionBlock.isDefault ? _vm.leftButtonText : null,"leftButtonIcon":_vm.isMine && !_vm.nutritionBlock.isDefault ? 'mdi-format-list-bulleted' : null,"primaryButton":_vm.primaryButtonText,"primaryButtonLoading":_vm.loading},on:{"leftButtonClicked":function($event){return _vm.clickLeftButton()},"primaryButtonClicked":function($event){return _vm.$emit('doProgram')}}}),(_vm.nutritionBlock.isDefault && _vm.isCurrent)?_c('div',{staticClass:"px-4 pt-4 p-text"},[_vm._v(" This is our standard default Nutrition Menu. You can also "),_c('router-link',{staticClass:"text-decoration-underline",attrs:{"to":"/programs?type=NUTRITION"}},[_vm._v(" choose any other specialist Nutrition Menu ")]),_vm._v(". ")],1):_vm._e(),(
      _vm.nutritionSubscriptionBlock &&
        _vm.nutritionSubscriptionBlock.endDate &&
        _vm.$moment(_vm.$moment()).isSameOrAfter(_vm.$moment(_vm.nutritionSubscriptionBlock.endDate))
    )?_c('j-alert',{staticClass:"mt-6 mx-4"},[_vm._v(" Your program ended "),(_vm.nutritionSubscriptionBlock.endDate)?_c('span',[_vm._v("on "+_vm._s(_vm._f("moment")(_vm.nutritionSubscriptionBlock.endDate,"ddd, D MMM 'YY"))+" ")]):_vm._e(),_c('br'),_c('j-btn',{staticClass:"d-block mt-2",attrs:{"secondary":"","narrow":""},on:{"click":function($event){return _vm.restartProgram()}}},[_vm._v(" Restart Program ")])],1):_vm._e(),(
      _vm.nutritionSubscriptionBlock &&
        _vm.$moment(_vm.nutritionSubscriptionBlock.startDate).isSameOrAfter(_vm.$moment()) &&
        !_vm.isCurrent
    )?_c('div',{staticClass:"ma-4 mt-6"},[_c('j-alert',[_vm._v(" Your program starts on "+_vm._s(_vm._f("moment")(_vm.nutritionSubscriptionBlock.startDate,"ddd, D MMM 'YY"))+" ")])],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }