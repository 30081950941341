<template>
  <j-card rounded="lg">
    <v-toolbar class="mb-6">
      <v-toolbar-title
        ><v-icon class="warning--text mr-2 mt-n1">mdi-alert-outline</v-icon>Review Ingredients</v-toolbar-title
      >
      <v-spacer />
      <v-btn icon @click="$emit('closed')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <p>
        This recipe has ingredients outside of
        <router-link to="/account/preferences" class="nutrition--text">your preferences</router-link>
      </p>
      <ul class="mt-n2">
        <li v-for="missingPreference in missingPreferences" :key="missingPreference.id">
          {{ missingPreference.title }}
        </li>
      </ul>
      <p class="mt-4">Replace with your own ingredients or swap the whole meal when viewing the recipe.</p>
    </v-card-text>
  </j-card>
</template>

<script>
export default {
  name: "MissingPreferencesDialog",
  props: {
    missingPreferences: {
      type: Array,
      required: true,
    },
  },
};
</script>
