export const get_community_event_share_link = (event_id, title, appName) => {
  let str = "https://api.whatsapp.com/send?text=";
  str += encodeURIComponent(`Hey, join me at the ${appName} Community Fitness event "${title}": `);
  str += encodeURIComponent("https://" + window.location.hostname + "/social/community-fitness/");
  str += encodeURIComponent(event_id);
  return str;
};

export const get_register_share_link = (code, appName, inviteFriendUrl) => {
  let str = "https://api.whatsapp.com/send?text=";
  str += encodeURIComponent(
    `Hey! Do you want to join ${appName} with me? There are fresh workouts every week, exciting programs and monthly challenges. Just create a free profile to get started: `
  );
  str += encodeURIComponent("https://" + window.location.hostname + inviteFriendUrl + "?referral_code=" + code);
  return str;
};

export const get_friend_request_share_link = (code, appName, inviteFriendUrl) => {
  let str = "https://api.whatsapp.com/send?text=";
  str += encodeURIComponent(`Hey! Do you want to join ${appName} and be my friend? You'll see what I'm doing for exercise, nutrition and mindset. I think you'll enjoy it! `);
  str += encodeURIComponent("https://" + window.location.hostname + inviteFriendUrl + "?referral_code=" + code);
  return str;
};
