<template>
  <div class="d-flex py-2 align-center" :class="classes">
    <router-link v-if="showBack" to="/chats" class="text-decoration-none"
      ><v-icon class="pr-2"> keyboard_backspace </v-icon></router-link
    >
    <v-avatar v-if="chat.image" size="40"><v-img :src="chat.image"/></v-avatar>
    <div class="ml-2 navy--text font-weight-bold">{{ chat.title }}</div>
  </div>
</template>

<script>
export default {
  name: "SimpleChatHeader",
  props: {
    chat: {
      type: Object,
      required: true,
    },
    showBack: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      required: true,
    }
  },
};
</script>
