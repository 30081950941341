import gql from "graphql-tag";

export const COACH_DETAIL_QUERY = gql`
  query coachDetail {
    me {
      id
      userProfile {
        id
        canBookCoachingCheckin
        coachingChat {
          id
        }
      }
    }
    coachDetail {
      id
      firstName
      lastName
      userProfile {
        id
        profilePicture
        phone
        assessmentBookingLink
        consultBookingLink
        biography
        youtubeIntroVideo
        coachIntroduction
      }
    }
  }
`;

export const MEDIA_QUERY = gql`
  query contentResources($contentType: ContentEnumType, $orderBy: String) {
    contentResources(contentType: $contentType, orderBy: $orderBy) {
      id
      name
      url
      file
      published
      createdAt
      tags
      openAccess
      userBookmark {
        id
      }
    }
  }
`;
