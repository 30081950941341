<template>
  <div>
    <v-skeleton-loader v-if="$apollo.loading" type="list-item-avatar" />
    <div v-else>
      <div class="d-flex justify-space-between">
        <h4>Course</h4>
        <v-spacer />
        <v-btn small text to="/your-programs" class="grey-navy--text">
          <strong>See courses</strong>
        </v-btn>
      </div>
      <div v-if="activeCourses.length > 0">
        <workout-block-overview
          v-for="activeCourse in activeCourses"
          :key="activeCourse.id"
          :workout-block="activeCourse.block"
          :anchor-date="activeCourse.isSelfPaced ? $moment($store.getters.selectedDate).format('YYYY-MM-DD') : null"
          :subscription-block="activeCourse"
          :startDate="activeCourse.startDate"
          type="COURSE"
          :show-unit="false"
          class="course-block-section"
        />
      </div>
      <div v-if="upcomingCourses.length > 0">
        <h5>Upcoming Courses</h5>
        <workout-block-overview
          class="mb-4"
          v-for="course in upcomingCourses"
          :key="course.id"
          :workout-block="course.block"
          :startDate="course.startDate"
          type="COURSE"
          :show-unit="false"
        />
      </div>
      <div v-if="activeCourses.length === 0" align="center" class="mt-4">
        <j-dotted-btn wide color="yellow" to="/programs?type=COURSE">Choose Course</j-dotted-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { HOME_QUERY } from "@/graphql/queries/home";
import { dedupeNodes } from "@/lib/array";
import WorkoutBlockOverview from "@/components/workoutblocks/cards/WorkoutBlockOverview";

export default {
  name: "TodayCourse",
  components: {
    WorkoutBlockOverview,
  },
  data() {
    return {
      activeCourses: [],
      upcomingCourses: [],
    };
  },
  apollo: {
    home: {
      query: HOME_QUERY,
      variables() {
        return {
          withGroups: true,
          withGroupUsers: false,
          withUsers: true,
        };
      },
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data && !response.loading) {
          let data = response.data.home;
          if (data.subscriptionBlocks) {
            // do not filter courses by endDate. Still want access even if they have passed
            // at some point, we will want a dedicated page for your past courses and only show active here.
            let activeCourses = data.subscriptionBlocks.filter((element) => element.block.type === "COURSE") ?? null;
            // if you have done the course more than once, only display once here i.e dedupe on `block.id`
            this.activeCourses = dedupeNodes(activeCourses, "block");
          }
          if (data.upcomingSubscriptionBlocks) {
            this.upcomingCourses =
              data.upcomingSubscriptionBlocks.filter((element) => element.block.type === "COURSE") ?? null;
          }
          this.loading = false;
        }
      },
    },
  },
};
</script>
