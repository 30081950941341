
import { GET_OR_CREATE_USER_CHAT_MUTATION } from "@/graphql/mutations/chat";
import { decodeId } from "@/lib/string";

export default {
  data() {
    return {
      chatLoading: false,
    };
  },
  methods: {
    async goToChat(friendId) {
      this.chatLoading = true;
      const result = await this.$apollo.mutate({
        mutation: GET_OR_CREATE_USER_CHAT_MUTATION,
        variables: {
          friendId: decodeId(friendId),
        },
      });
      this.chatLoading = false;
      if (result.data?.getOrCreateUserChatMutation?.id) {
        return this.$router.push(`/chats/${result.data.getOrCreateUserChatMutation.id}`);
      }
    },
  },

};
