<template>
  <v-btn
    v-if="!tertiary"
    :color="getColor"
    :rounded="rounded"
    :ripple="false"
    v-bind="$props"
    v-on="$listeners"
    class="j-btn"
    :class="[getClass, { 'elevation-5': !flatten, 'font-weight-bold': true }]"
    :disabled="disabled"
  >
    <span class="white--text"><slot /></span>
  </v-btn>
  <v-btn
    v-else
    :color="getColor"
    :rounded="rounded"
    :ripple="false"
    v-bind="$props"
    v-on="$listeners"
    class="j-btn"
    :class="[getClass, { 'elevation-5': !flatten }]"
    :disabled="disabled"
  >
    <span class="navy--text"><slot /></span>
  </v-btn>
</template>

<script>
import { VBtn } from "vuetify/lib";
import { gradientStyle } from "@/lib/gradientStyle";

export default {
  name: "JBtn",
  extends: VBtn,
  props: {
    primary: {
      type: Boolean,
      required: false,
    },
    secondary: {
      type: Boolean,
      required: false,
    },
    tertiary: {
      type: Boolean,
      required: false,
    },
    color: {
      type: String,
      default: "appblue",
    },
    flatten: {
      type: Boolean,
      default: false,
    },
    wide: {
      type: Boolean,
      default: false,
    },
    superNarrow: {
      type: Boolean,
      default: false,
    },
    narrow: {
      type: Boolean,
      default: false,
    },
    gradient: {
      type: Array,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getClass() {
      let value = "px-6";
      if (this.wide) {
        value = "wide";
      } else if (this.narrow) {
        value = "px-3";
      } else if (this.superNarrow) {
        value = "px-2";
      }

      return value;
    },
    getColor() {
      if (this.disabled) {
        return "primary";
      }
      if (this.tertiary) {
        return "white";
      }
      if (this.primary) {
        return "pink";
      }
      if (this.secondary) {
        return "secondary";
      }
      return this.color;
    },
    backgroundGradientStyle() {
      return gradientStyle(this.processedGradient, 180);
    },
    processedGradient() {
      if (!this.flatten && !this.gradient) {
        return [`${this.getColor} lighten-2`, `${this.getColor} darken-2`];
      } else {
        return this.gradient;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn.wide {
  width: 90%;
}
.theme--light.v-btn.v-btn--disabled span {
  color: #726c6c !important;
}
</style>
