import gql from "graphql-tag";

export const UPSERT_CELEBRATION_MUTATION = gql`
  mutation upsertCelebrationMutation($data: CelebrationInput!) {
    upsertCelebration(data: $data) {
      id
      image
      success
      error
    }
  }
`;
