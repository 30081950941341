<template>
  <v-radio v-bind="[$props, $attrs]" v-on="$listeners">
    <template v-slot:label>
      <slot name="label" />
    </template>
  </v-radio>
</template>

<script>
import { VRadio } from "vuetify/lib";

export default {
  name: "JRadio",
  extends: VRadio,
  props: {
    color: {
      type: String,
      default: "secondary",
    },
  },
};
</script>
