<template>
  <v-skeleton-loader v-if="$apollo.loading" type="card-heading" />
  <div v-else>
    <div class="d-flex justify-space-between">
      <h4>Hydration</h4>
      <v-switch class="py-0 my-0" hide-details :input-value="hydrationEnabled" @change="updateHydrationSetting($event)" />
    </div>
    <logging-selection-button
      v-if="hydrationEnabled"
      :key="formattedDate + 'hydration'"
      :optionList="options.hydration"
      :value="hydrationLog"
      type="hydration"
      @value="hydrationLog = $event"
      class="reflection-logging-button my-2"
    />
  </div>
</template>

<script>
import { HYDRATION_QUERY } from "@/graphql/queries/tracker";
import { REFLECTION_OPTIONS } from "@/constants/json/reflectionOptions";
import { mapGetters } from "vuex";
import LoggingSelectionButton from "@/components/tracker/buttons/LoggingSelectionButton";
import UserMixin from "@/mixins/UserMixin";

export default {
  name: "HydrationTracker",
  mixins: [UserMixin],
  components: {
    LoggingSelectionButton,
  },
  data() {
    return {
      hydrationLog: null,
      options: REFLECTION_OPTIONS,
      loadingStress: true,
    };
  },
  computed: {
    ...mapGetters(["selectedDate"]),
    hydrationEnabled() {
      return this.settingEnabled("hydration-tracking");
    },
  },
  methods: {
    formattedDate() {
      return this.selectedDate ? this.selectedDate.format("YYYY-MM-DD") : this.$moment().format("YYYY-MM-DD");
    },
    updateHydrationSetting(value) {
      this.upsertSetting("hydration-tracking", value);
    },
  },
  apollo: {
    hydrationLog: {
      query: HYDRATION_QUERY,
      variables() {
        return {
          date: this.formattedDate(),
        };
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        this.loadingStress = false;
        if (data.hydrationLog.edges && data.hydrationLog.edges.length > 0) {
          return data.hydrationLog.edges[0].node.rating;
        } else {
          return null;
        }
      },
    },
  },
};
</script>
