<template>
  <j-banner-container header="Welcome to 1-1 Coaching" :gradient="['green-background', 'yellow-background']">
    <v-row v-if="loading">
      <v-col cols="12" align="center" justify="center">
        <v-row align="center" justify="center">
          <j-text-field color="success" loading disabled></j-text-field>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-else>
      <v-col v-if="!this.hasError" cols="12" align="center" justify="center">
        <v-row>
          <v-col cols="12" align="center" justify="center">
            <h3 class="navy--text text-left">Hey {{ $store.getters.user.firstName }}, let's get you started!</h3>
            <p class="mt-3 mb-0 text-left">
              To begin, please fill out the below form completing all questions. It should take about 15 minutes and
              will help us coach you better.
            </p>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col>
            <v-form id="medical-form" ref="form" class="col-md-12 text-left pt-2 px-1">
              <v-row>
                <v-col>
                  <v-expansion-panels accordion>
                    <!-- ****************** -->
                    <!-- LIFESTYLE -->
                    <!-- ****************** -->
                    <v-expansion-panel>
                      <v-expansion-panel-header @click="verifyForm(1)" disable-icon-rotate>
                        <h3 class="navy--text mb-3">Lifestyle</h3>

                        <template v-slot:actions v-if="isObs2_Touched">
                          <v-icon v-if="isObs1_Valid" color="teal" large> mdi-check </v-icon>
                          <v-icon v-else color="error" large> mdi-alert-circle </v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <ValidationObserver ref="obs1">
                          <p class="p-text">Let's start off with a few questions to get to know you a little better.</p>
                          <span class="pr-3 d-none">1. Set your profile picture</span>
                          <div class="mt-5 ml-8 mb-3 d-none">
                            <cropper
                              v-if="profileImgSrc != null"
                              class="cropper mt-2 mb-2"
                              :src="profileImgSrc"
                              :stencil-props="{
                                aspectRatio: 1,
                                movable: true,
                                resizable: true,
                                resizeImage: false,
                              }"
                              @change="updateProfilePicture"
                            />
                            <image-uploader
                              :preview="false"
                              :quality="1"
                              style="max-width: 100%"
                              outputFormat="verbose"
                              accept=".jpg,.jpeg,.png"
                              @input="setProfilePicture"
                            >
                            </image-uploader>
                            <v-btn
                              v-if="profileImgSrc != null"
                              color="secondary"
                              class="dashboard-button mt-1"
                              large
                              rounded
                              :loading="savingProfilePicture"
                              @click="saveProfilePicture()"
                            >
                              Update Profile Picture
                            </v-btn>
                          </div>
                          <div class="mb-5 d-none">
                            <v-avatar
                              v-if="userProfile.profilePicture != null"
                              class="mx-auto"
                              color="error"
                              size="100"
                            >
                              <img :lazy-src="userProfile.profilePicture" :src="userProfile.profilePicture" />
                            </v-avatar>
                          </div>

                          <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <ValidationProvider rules="required" v-slot="{ errors }" name="date of birth">
                                <span class="pr-3 mt-4">1. When were you born? </span>
                                <j-text-field
                                  v-model="date"
                                  label="YYYY-MM-DD"
                                  prepend-icon="event"
                                  class="pt-0 mt-4"
                                  v-bind="attrs"
                                  v-on="on"
                                  readonly
                                  :error-messages="errors"
                                ></j-text-field>
                              </ValidationProvider>
                            </template>
                            <v-date-picker
                              ref="picker"
                              v-model="date"
                              no-title
                              scrollable
                              @input="menu = false"
                              :max="$moment().subtract(15, 'years').format('YYYY-MM-DD')"
                              min="1900-01-01"
                              @change="save"
                            ></v-date-picker>
                          </v-menu>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="gender">
                            <span class="pr-3">2. Please confirm your gender </span>
                            <j-select
                              v-model="userProfile.gender"
                              :items="[
                                {
                                  text: 'Female',
                                  value: 'FEMALE',
                                },
                                {
                                  text: 'Male',
                                  value: 'MALE',
                                },
                              ]"
                              :error-messages="errors"
                            >
                            </j-select>
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="occupation">
                            <span class="pr-3"> 4. What is your occupation? </span>
                            <j-textarea
                              v-model="userProfile.occupation"
                              outlined
                              rows="3"
                              auto-grow
                              class="mb-3 mt-0 pt-0"
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="hrs per week">
                            <span class="pr-3"> 5. How many hours per week do you work? </span>
                            <j-select
                              v-model="userProfile.occupation_hrs_per_week"
                              :items="[
                                {
                                  text: 'Less than 20',
                                  value: 'Less than 20',
                                },
                                {
                                  text: '20-30',
                                  value: '20-30',
                                },
                                {
                                  text: '30-40',
                                  value: '30-40',
                                },
                                {
                                  text: '40-50',
                                  value: '40-50',
                                },
                                {
                                  text: '50+',
                                  value: '50+',
                                },
                              ]"
                              :error-messages="errors"
                            >
                            </j-select>
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="work from">
                            <span class="pr-3"> 6. Where do you work from? </span>
                            <j-select
                              v-model="userProfile.occupation_work_from_home"
                              :items="[
                                {
                                  text: 'Home',
                                  value: 'home',
                                },
                                {
                                  text: 'Office',
                                  value: 'Office',
                                },
                                {
                                  text: 'Hybrid',
                                  value: 'Hybrid',
                                },
                                {
                                  text: 'N/A',
                                  value: 'N/A',
                                },
                              ]"
                              :error-messages="errors"
                            >
                            </j-select>
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="daily routine">
                            <span class="pr-3">
                              7. What level of activity best describes your occupation or daily routine?
                            </span>
                            <j-select
                              v-model="userProfile.occupation_daily_routine"
                              :items="[
                                {
                                  text: 'Sedentary/Desk Bound',
                                  value: 'Sedentary or Desk Bound',
                                },
                                {
                                  text: 'Lightly Active (Exercise 1-3 days per week)',
                                  value: 'Lightly Active',
                                },
                                {
                                  text: 'Moderately Active (Exercise 4-5 days per week)',
                                  value: 'Moderately Active',
                                },
                                {
                                  text: 'Very Active (Exercise 6-7 days per week)',
                                  value: 'Very Active',
                                },
                                {
                                  text: 'Extremely Active (Exercise twice per day)',
                                  value: 'Extremely Active',
                                },
                              ]"
                              :error-messages="errors"
                            >
                            </j-select>
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="level of stress">
                            <span class="pr-3">
                              8. How would you rate your current level of stress? <br />
                              <em class="ml-3 sm-text">(1 = no stress and 5 = highly stressed)</em>
                            </span>
                            <v-slider
                              v-model="assessment.level_of_stress"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.level_of_stress)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="ability to handle stress">
                            <span class="pr-3">
                              9. How would you rate your ability to handle stress? <br />
                              <em class="ml-3 sm-text">(1 = very poorly and 5 = extremely well)</em>
                            </span>
                            <v-slider
                              v-model="assessment.handle_stress"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.handle_stress)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="hours of sleep">
                            <span class="pr-3">
                              10. On average, how many hours of sleep are you getting per night?<br />
                            </span>
                            <j-select
                              v-model="assessment.hours_of_sleep"
                              :items="[
                                {
                                  text: '5 or less',
                                  value: '5 or less',
                                },
                                {
                                  text: '6',
                                  value: '6',
                                },
                                {
                                  text: '7',
                                  value: '7',
                                },
                                {
                                  text: '8',
                                  value: '8',
                                },
                                {
                                  text: 'More than 8',
                                  value: 'More than 8',
                                },
                              ]"
                              :error-messages="errors"
                            >
                            </j-select>
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="quality of sleep">
                            <span class="pr-3">
                              11. How would you rate your quality of sleep? <br />
                              <em class="ml-3 sm-text">(1 = very poor and 5 = perfect)</em>
                            </span>
                            <v-slider
                              v-model="assessment.quality_of_sleep"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.quality_of_sleep)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="relationship status">
                            <span class="pr-3"> 12. What is your relationship status? </span>
                            <j-select
                              v-model="userProfile.relationship_status"
                              :items="[
                                {
                                  text: 'Single',
                                  value: 'Single',
                                },
                                {
                                  text: 'Married',
                                  value: 'Married',
                                },
                                {
                                  text: 'In a relationship',
                                  value: 'In a relationship',
                                },
                                {
                                  text: 'Divorced/Separated',
                                  value: 'Divorced or Separated',
                                },
                              ]"
                              :error-messages="errors"
                            >
                            </j-select>
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="heart condition">
                            <span class="pr-3">13. Do you have any children? </span>
                            <j-radio-group
                              row
                              v-model="userProfile.has_children"
                              class="pr-3 mt-2"
                              :error-messages="errors"
                            >
                              <j-radio label="Yes" :value="true" />
                              <j-radio label="No" :value="false" />
                            </j-radio-group>
                          </ValidationProvider>

                          <ValidationProvider
                            v-if="userProfile.has_children"
                            rules="required"
                            v-slot="{ errors }"
                            name="no of children"
                          >
                            <span class="pr-3"> 14. How many children do you have? </span>
                            <j-text-field
                              v-model="userProfile.no_of_children"
                              type="number"
                              outlined
                              rows="3"
                              auto-grow
                              class="mb-3 mt-0 pt-0"
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <ValidationProvider
                            v-if="userProfile.has_children"
                            rules="required"
                            v-slot="{ errors }"
                            name="children's names"
                          >
                            <span class="pr-3"> 15. What are your children's names? </span>
                            <j-textarea
                              v-model="userProfile.children_name"
                              outlined
                              rows="3"
                              auto-grow
                              class="mb-3 mt-0 pt-0"
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="support">
                            <span class="pr-3">
                              {{ userProfile.has_children ? "16" : "14" }}. From those people around you, who would you
                              rely on for support?
                            </span>
                            <j-select
                              v-model="assessment.support"
                              multiple
                              :menu-props="{
                                closeOnClick: true,
                                closeOnContentClick: true,
                              }"
                              :items="[
                                {
                                  text: 'My partner/spouse',
                                  value: 'My partner',
                                },
                                {
                                  text: 'Other family members',
                                  value: 'Other family members',
                                },
                                {
                                  text: 'Friends',
                                  value: 'Friends',
                                },
                                {
                                  text: 'Colleagues',
                                  value: 'Colleagues',
                                },
                                {
                                  text: 'A healthcare professional',
                                  value: 'A healthcare professional',
                                },
                              ]"
                              :error-messages="errors"
                            >
                            </j-select>
                          </ValidationProvider>
                        </ValidationObserver>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- ****************** -->
                    <!-- GOALS -->
                    <!-- ****************** -->
                    <v-expansion-panel :disabled="!isObs1_Touched">
                      <v-expansion-panel-header @click="verifyForm(2)" disable-icon-rotate>
                        <h3 :class="isObs1_Touched ? 'navy--text' : 'gray--text'" class="mb-3">Goals</h3>

                        <template v-slot:actions v-if="isObs3_Touched">
                          <v-icon v-if="isObs2_Valid" color="teal" large> mdi-check </v-icon>
                          <v-icon v-else color="error" large> mdi-alert-circle </v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <ValidationObserver ref="obs2">
                          <p class="p-text">
                            Our coaching is really about helping you reach your goals, setting them realistically,
                            keeping you accountible to them and supporting you to get there.
                          </p>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="goal definition">
                            <span class="pr-3">
                              1. Which of these goals would best define your main focus right now?
                            </span>
                            <j-select
                              v-model="assessment.goal_definition"
                              :items="[
                                {
                                  text: 'Lose weight and/or centimeters',
                                  value: 'Lose weight andor centimeters',
                                },
                                {
                                  text: 'Tone (reduce body fat and increase muscle)',
                                  value: 'Tone',
                                },
                                {
                                  text: 'Improve my health',
                                  value: 'Improve my health',
                                },
                                {
                                  text: 'Get stronger',
                                  value: 'Get stronger',
                                },
                                {
                                  text: 'Get fitter or perform better',
                                  value: 'Get fitter or perform better',
                                },
                              ]"
                              :error-messages="errors"
                            >
                            </j-select>
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="another goal">
                            <span class="pr-3">
                              2. Do you have another goal you would like to achieve through our coaching?</span
                            >
                            <j-radio-group
                              row
                              v-model="assessment.another_goal"
                              class="pr-3 mt-2"
                              :error-messages="errors"
                            >
                              <j-radio label="Yes" :value="true" />
                              <j-radio label="No" :value="false" />
                            </j-radio-group>
                          </ValidationProvider>

                          <ValidationProvider
                            v-if="assessment.another_goal"
                            rules="required"
                            v-slot="{ errors }"
                            name="goal description"
                          >
                            <span class="pr-3"> 3. Please describe that for me. </span>
                            <j-textarea
                              v-model="assessment.another_goal_description"
                              outlined
                              rows="3"
                              auto-grow
                              class="mb-3 mt-0 pt-0"
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="goal time frame">
                            <span class="pr-3">
                              {{ assessment.another_goal ? "4" : "3" }}. Do you have a time frame in mind for when you
                              would like to achieve your goal by?
                            </span>
                            <j-select
                              v-model="assessment.goal_time_frame"
                              multiple
                              :menu-props="{
                                closeOnClick: true,
                                closeOnContentClick: true,
                              }"
                              :items="[
                                {
                                  text: 'As soon as possible',
                                  value: 'As soon as possible',
                                },
                                {
                                  text: '2-3 months',
                                  value: '2-3 months',
                                },
                                {
                                  text: '4-6 months',
                                  value: '4-4-6 months',
                                },
                                {
                                  text: '6 months +',
                                  value: '6 months+',
                                },
                                {
                                  text: 'It is not time bound, as long as I achieve it.',
                                  value: 'Not time bound',
                                },
                              ]"
                              :error-messages="errors"
                            >
                            </j-select>
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="goal importance">
                            <span class="pr-3">
                              {{ assessment.another_goal ? "5" : "4" }}. Why is achieving this goal important to you?
                            </span>
                            <j-textarea
                              v-model="assessment.goal_important"
                              outlined
                              rows="3"
                              auto-grow
                              class="mb-3 mt-0 pt-0"
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <p>
                            I'd like to know a little more about what hurdles are currently getting in the way of you
                            achieving your goals. For each of the possibilities I mention below please rate them as
                            challenges you battle with from 1 (never) to 5 (very regularly).
                          </p>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="lack of motivation">
                            <span class="pr-3"> {{ assessment.another_goal ? "6" : "5" }}. Lack of motivation </span>
                            <v-slider
                              v-model="assessment.lack_of_motivation"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.lack_of_motivation)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="lack of energy">
                            <span class="pr-3"> {{ assessment.another_goal ? "7" : "6" }}. Lack of energy </span>
                            <v-slider
                              v-model="assessment.lack_of_energy"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.lack_of_energy)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="lack of consistency">
                            <span class="pr-3"> {{ assessment.another_goal ? "8" : "7" }}. Lack of consistency </span>
                            <v-slider
                              v-model="assessment.lack_of_consistency"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.lack_of_consistency)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="injury">
                            <span class="pr-3"> {{ assessment.another_goal ? "9" : "8" }}. Injury </span>
                            <v-slider
                              v-model="assessment.injury"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.injury)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="what type of exercise">
                            <span class="pr-3">
                              {{ assessment.another_goal ? "10" : "9" }}. Not knowing what type of exercise I should do
                            </span>
                            <v-slider
                              v-model="assessment.what_type_of_exercise"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.what_type_of_exercise)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="certain exercises properly">
                            <span class="pr-3">
                              {{ assessment.another_goal ? "11" : "10" }}. Not knowing how to do certain exercises
                              properly
                            </span>
                            <v-slider
                              v-model="assessment.certain_exercises_properly"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.certain_exercises_properly)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="enough time to exercise">
                            <span class="pr-3">
                              {{ assessment.another_goal ? "12" : "11" }}. Not having enough time to exercise
                            </span>
                            <v-slider
                              v-model="assessment.enough_time_to_exercise"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.enough_time_to_exercise)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="what to eat">
                            <span class="pr-3">
                              {{ assessment.another_goal ? "13" : "12" }}. Not knowing what to eat
                            </span>
                            <v-slider
                              v-model="assessment.what_to_eat"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.what_to_eat)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="how much to eat">
                            <span class="pr-3">
                              {{ assessment.another_goal ? "14" : "13" }}. Not knowing how much to eat
                            </span>
                            <v-slider
                              v-model="assessment.how_much_to_eat"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.how_much_to_eat)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="planning meals">
                            <span class="pr-3">
                              {{ assessment.another_goal ? "15" : "14" }}. Not planning or preparing my meals
                              effectively
                            </span>
                            <v-slider
                              v-model="assessment.planning_meals"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.planning_meals)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="enough time to cook">
                            <span class="pr-3">
                              {{ assessment.another_goal ? "16" : "15" }}. Not having enough time to cook
                            </span>
                            <v-slider
                              v-model="assessment.enough_time_to_cook"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.enough_time_to_cook)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="how to cook">
                            <span class="pr-3">
                              {{ assessment.another_goal ? "17" : "16" }}. Not knowing how to cook
                            </span>
                            <v-slider
                              v-model="userProfile.how_to_cook"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(userProfile.how_to_cook)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="food cravings">
                            <span class="pr-3"> {{ assessment.another_goal ? "18" : "17" }}. Food cravings </span>
                            <v-slider
                              v-model="assessment.food_cravings"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.food_cravings)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="eating poorly">
                            <span class="pr-3">
                              {{ assessment.another_goal ? "19" : "18" }}. Eating poorly due to stress or emotions
                            </span>
                            <v-slider
                              v-model="userProfile.eating_poorly"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(userProfile.eating_poorly)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="always hungry">
                            <span class="pr-3">
                              {{ assessment.another_goal ? "20" : "19" }}. Always hungry or always snacking
                            </span>
                            <v-slider
                              v-model="userProfile.always_hungry"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(userProfile.always_hungry)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="over_eating">
                            <span class="pr-3"> {{ assessment.another_goal ? "21" : "20" }}. Over-eating </span>
                            <v-slider
                              v-model="assessment.over_eating"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.over_eating)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="weekend wobbles">
                            <span class="pr-3"> {{ assessment.another_goal ? "22" : "21" }}. The weekend wobbles </span>
                            <v-slider
                              v-model="assessment.weekend_wobbles"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.weekend_wobbles)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="cultural challenges">
                            <span class="pr-3">
                              {{ assessment.another_goal ? "23" : "22" }}. Social or cultural challenges to eating well
                            </span>
                            <v-slider
                              v-model="userProfile.cultural_challenges"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(userProfile.cultural_challenges)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="regularly cycling">
                            <span class="pr-3">
                              {{ assessment.another_goal ? "24" : "23" }}. Regularly cycling between dieting and falling
                              off the wagon
                            </span>
                            <v-slider
                              v-model="assessment.regularly_cycling"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.regularly_cycling)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="excessive alcohol">
                            <span class="pr-3">
                              {{ assessment.another_goal ? "25" : "24" }}. Excessive alcohol intake
                            </span>
                            <v-slider
                              v-model="assessment.excessive_alcohol"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.excessive_alcohol)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>
                        </ValidationObserver>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- ****************** -->
                    <!-- MEDICAL INFORMATION -->
                    <!-- ****************** -->
                    <v-expansion-panel :disabled="!isObs2_Touched">
                      <v-expansion-panel-header @click="verifyForm(3)" disable-icon-rotate>
                        <h3 :class="isObs2_Touched ? 'navy--text' : 'gray--text'" class="mb-3">Medical Information</h3>

                        <template v-slot:actions v-if="isObs4_Touched">
                          <v-icon v-if="isObs3_Valid" color="teal" large> mdi-check </v-icon>
                          <v-icon v-else color="error" large> mdi-alert-circle </v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <ValidationObserver ref="obs3">
                          <p>
                            Thank you, before I find out a bit more about your exercise and nutrition habits I need to
                            know if there are any medical issues I need to consider. Please let me know if any of these
                            are applicable to you?
                          </p>
                          <ValidationProvider v-slot="{ errors }" name="heart condition">
                            <j-checkbox
                              v-model="assessment.heart_condition"
                              label="A Heart Condition"
                              hide-details
                              class="pr-3 mt-2"
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <ValidationProvider v-slot="{ errors }" name="high blood pressure">
                            <j-checkbox
                              v-model="assessment.high_blood_pressure"
                              label="High Blood Pressure"
                              hide-details
                              class="pr-3 mt-2"
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <ValidationProvider v-slot="{ errors }" name="diabetes">
                            <j-checkbox
                              v-model="assessment.diabetes"
                              label="Diabetes"
                              hide-details
                              class="pr-3 mt-2"
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <ValidationProvider v-slot="{ errors }" name="high_cholesterol">
                            <j-checkbox
                              v-model="assessment.high_cholesterol"
                              label="High cholesterol"
                              hide-details
                              class="pr-3 mt-2"
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <ValidationProvider v-slot="{ errors }" name="chest pain">
                            <j-checkbox
                              v-model="assessment.chest_pain"
                              label="Chest Pain"
                              hide-details
                              class="pr-3 mt-2"
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <ValidationProvider v-slot="{ errors }" name="dizziness">
                            <j-checkbox
                              v-model="assessment.dizziness"
                              label="Dizziness"
                              hide-details
                              class="pr-3 mt-2"
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <ValidationProvider v-slot="{ errors }" vid="joint pain" name="joint pain">
                            <j-checkbox
                              v-model="assessment.joint_pain"
                              label="Joint Pain/Injury"
                              hide-details
                              class="pr-3 mt-2"
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <ValidationProvider v-slot="{ errors }" name="back neck pain">
                            <j-checkbox
                              v-model="assessment.back_neck_pain"
                              label="Back or Neck Pain"
                              hide-details
                              class="pr-3 mt-2"
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <ValidationProvider v-slot="{ errors }" name="chronic condition">
                            <j-checkbox
                              v-model="assessment.chronic_condition"
                              label="Any other chronic pain"
                              hide-details
                              class="pr-3 mt-2"
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <ValidationProvider v-slot="{ errors }" name="arthritis">
                            <j-checkbox
                              v-model="assessment.arthritis"
                              label="Rheumatoid/Arthritic Conditions"
                              hide-details
                              class="pr-3 mt-2"
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <ValidationProvider v-slot="{ errors }" name="soft tissue problem">
                            <j-checkbox
                              v-model="assessment.soft_tissue_problem"
                              label="Soft Tissue Problems"
                              hide-details
                              class="pr-3 mt-2"
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <ValidationProvider v-slot="{ errors }" name="recent injury">
                            <j-checkbox
                              v-model="assessment.recent_injury"
                              label="Recent injury that would limit exercise"
                              hide-details
                              class="pr-3 mt-2"
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                          <ValidationProvider
                            v-if="assessment.recent_injury"
                            v-slot="{ errors }"
                            name="recent injury notes"
                            :rules="assessment.recent_injury ? 'required' : ''"
                          >
                            <j-textarea
                              outlined
                              rows="2"
                              auto-grow
                              v-model="assessment.recent_injury_notes"
                              class="mb-0 mt-0 pt-0"
                              label="Please describe this for me."
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <ValidationProvider v-slot="{ errors }" name="musculoskeletal surgery">
                            <j-checkbox
                              v-model="assessment.musculoskeletal_surgery"
                              label="Recent surgery"
                              hide-details
                              class="pr-3 mt-2"
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                          <ValidationProvider
                            v-if="assessment.musculoskeletal_surgery"
                            v-slot="{ errors }"
                            name="musculoskeletal surgery notes"
                            :rules="assessment.musculoskeletal_surgery ? 'required' : ''"
                          >
                            <j-textarea
                              outlined
                              rows="2"
                              auto-grow
                              v-model="assessment.musculoskeletal_surgery_notes"
                              class="mb-0 mt-0 pt-0"
                              label="Please describe this for me."
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <ValidationProvider v-slot="{ errors }" name="thyroid hormonal problems">
                            <j-checkbox
                              v-model="assessment.thyroid_hormonal_problem"
                              label="Thyroid or hormonal Conditions"
                              hide-details
                              class="pr-3 mt-2"
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <ValidationProvider v-slot="{ errors }" name="covid">
                            <j-checkbox
                              v-model="assessment.has_had_covid_19"
                              label="COVID in the last 6 months"
                              hide-details
                              class="pr-3 mt-2"
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <ValidationProvider v-slot="{ errors }" name="medically supervised">
                            <j-checkbox
                              v-model="assessment.medically_supervised"
                              label="Has your doctor ever said you should not be exercising for any reason?"
                              hide-details
                              class="pr-3 mt-2"
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                          <ValidationProvider
                            v-if="assessment.medically_supervised"
                            v-slot="{ errors }"
                            name="musculoskeletal surgery notes"
                            :rules="assessment.medically_supervised ? 'required' : ''"
                          >
                            <j-textarea
                              outlined
                              rows="2"
                              auto-grow
                              v-model="assessment.medically_supervised_notes"
                              class="mb-0 mt-0 pt-0"
                              label="Please describe this for me."
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <ValidationProvider v-if="assessment.high_blood_pressure" v-slot="{ errors }" name="pregnant">
                            <j-checkbox
                              v-model="assessment.possibly_pregnant"
                              label="Are you currently pregnant?"
                              hide-details
                              class="pr-3 mt-2"
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <ValidationProvider
                            v-if="assessment.high_blood_pressure"
                            v-slot="{ errors }"
                            name="given birth"
                          >
                            <j-checkbox
                              v-model="assessment.gave_birth_last_six_months"
                              label="Have you given birth in the last 6 months?"
                              hide-details
                              class="pr-3 mt-2"
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <ValidationProvider v-slot="{ errors }" name="reasons to not exercise">
                            <j-checkbox
                              v-model="assessment.reasons_to_not_exercise"
                              label="Are you aware of any reason why you
                              should not be exercising?"
                              hide-details
                              class="pr-3 mt-2 mb-5"
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                          <ValidationProvider
                            v-if="assessment.reasons_to_not_exercise"
                            :rules="assessment.reasons_to_not_exercise ? 'required' : ''"
                            v-slot="{ errors }"
                            name="musculoskeletal surgery notes"
                          >
                            <j-textarea
                              outlined
                              rows="2"
                              auto-grow
                              v-model="assessment.reasons_to_not_exercise_notes"
                              class="mb-0 mt-0 pt-0"
                              label="Please describe this for me."
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="prescribed medication">
                            <span class="pr-3">Please list any medication you are currently taking. </span>
                            <j-textarea
                              outlined
                              rows="2"
                              auto-grow
                              v-model="assessment.prescribed_medication_notes"
                              class="mt-0 pt-0"
                              label='Please list conditions and medications here or specify "None"'
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                        </ValidationObserver>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- ****************** -->
                    <!-- EXERCISE -->
                    <!-- ****************** -->
                    <v-expansion-panel :disabled="!isObs3_Touched">
                      <v-expansion-panel-header @click="verifyForm(4)" disable-icon-rotate>
                        <h3 :class="isObs3_Touched ? 'navy--text' : 'gray--text'" class="mb-3">Exercise</h3>

                        <template v-slot:actions v-if="isObs5_Touched">
                          <v-icon v-if="isObs4_Valid" color="teal" large> mdi-check </v-icon>
                          <v-icon v-else color="error" large> mdi-alert-circle </v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <ValidationObserver ref="obs4">
                          <p>Okay let's jump into your current fitness and exercise info.</p>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="level of fitness">
                            <span class="pr-3">
                              1. On a scale of 1 (very unfit) to 5 (very fit), how would you rate your current level of
                              fitness
                            </span>
                            <v-slider
                              v-model="assessment.level_of_fitness"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.level_of_fitness)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="days commit">
                            <span class="pr-3"> 2. How many days per week can you commit to exercising right now?</span>
                            <j-select
                              v-model="assessment.days_commit"
                              :items="[
                                {
                                  text: '1',
                                  value: '1',
                                },
                                {
                                  text: '2',
                                  value: '2',
                                },
                                {
                                  text: '3',
                                  value: '3',
                                },
                                {
                                  text: '4',
                                  value: '4',
                                },
                                {
                                  text: '5+',
                                  value: '5+',
                                },
                              ]"
                              :error-messages="errors"
                            >
                            </j-select>
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="days commit">
                            <span class="pr-3"> 3. How much time for each session can you commit to?</span>
                            <j-select
                              v-model="assessment.time_commit"
                              :items="[
                                {
                                  text: 'Less than 20 minutes',
                                  value: 'Less than 20 minutes',
                                },
                                {
                                  text: '20-30 minutes',
                                  value: '20-30 minutes',
                                },
                                {
                                  text: '30-45 minutes',
                                  value: '30-45 minutes',
                                },
                                {
                                  text: '45-60 minutes',
                                  value: '45-60 minutes',
                                },
                                {
                                  text: '60 minutes +',
                                  value: '60 minutes+',
                                },
                              ]"
                              :error-messages="errors"
                            >
                            </j-select>
                          </ValidationProvider>

                          <p>
                            There are many different types of exercise to use to get to your goals. Please rate these
                            options from 1 (those you enjoy least) to 5 (those you enjoy most).
                          </p>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="walking">
                            <span class="pr-3"> 4. Walking </span>
                            <v-slider
                              v-model="assessment.walking"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.walking)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="hiking">
                            <span class="pr-3"> 5. Hiking </span>
                            <v-slider
                              v-model="assessment.hiking"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.hiking)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="running">
                            <span class="pr-3"> 6. Running </span>
                            <v-slider
                              v-model="assessment.running"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.running)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="swimming">
                            <span class="pr-3"> 7. Swimming </span>
                            <v-slider
                              v-model="assessment.swimming"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.swimming)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="strength training">
                            <span class="pr-3"> 8. Strength training </span>
                            <v-slider
                              v-model="assessment.strength_training"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.strength_training)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="intensity training">
                            <span class="pr-3"> 9. High intensity interval training </span>
                            <v-slider
                              v-model="assessment.intensity_training"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.intensity_training)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="working out at home">
                            <span class="pr-3"> 11. Working out at home </span>
                            <v-slider
                              v-model="assessment.working_out_at_home"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.working_out_at_home)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="working out at gym">
                            <span class="pr-3"> 12. Working out at gym </span>
                            <v-slider
                              v-model="assessment.working_out_at_gym"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.working_out_at_gym)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="yoga">
                            <span class="pr-3"> 13. Yoga </span>
                            <v-slider
                              v-model="assessment.yoga"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.yoga)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="Pilates">
                            <span class="pr-3"> 14. Pilates </span>
                            <v-slider
                              v-model="assessment.pilates"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.pilates)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="Cycling">
                            <span class="pr-3"> 15. Cycling </span>
                            <v-slider
                              v-model="assessment.cycling"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.cycling)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="Other team sports">
                            <span class="pr-3"> 16. Other team sports </span>
                            <v-slider
                              v-model="assessment.other_team_sports"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.other_team_sports)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="Other individual sports">
                            <span class="pr-3"> 17. Other individual sports </span>
                            <v-slider
                              v-model="assessment.other_individual_sports"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.other_individual_sports)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="enjoy working">
                            <span class="pr-3">
                              18. Do you enjoy working out on your own, with a friend or in groups?
                            </span>
                            <j-select
                              v-model="assessment.enjoy_working"
                              multiple
                              :menu-props="{
                                closeOnClick: true,
                                closeOnContentClick: true,
                              }"
                              :items="[
                                {
                                  text: 'On your own',
                                  value: 'On your own',
                                },
                                {
                                  text: 'With a friend',
                                  value: 'With a friend',
                                },
                                {
                                  text: 'In a group',
                                  value: 'In a group',
                                },
                              ]"
                              :error-messages="errors"
                            >
                            </j-select>
                          </ValidationProvider>
                        </ValidationObserver>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <!-- ****************** -->
                    <!-- NUTRITIONAL INFORMATION -->
                    <!-- ****************** -->
                    <v-expansion-panel :disabled="!isObs4_Touched">
                      <v-expansion-panel-header @click="verifyForm(5)" disable-icon-rotate>
                        <h3 :class="isObs4_Touched ? 'navy--text' : 'gray--text'" class="mb-3">
                          Nutritional Information
                        </h3>

                        <template v-slot:actions v-if="isSubmitButton_Touched">
                          <v-icon v-if="isObs5_Valid" color="teal" large> mdi-check </v-icon>
                          <v-icon v-else color="error" large> mdi-alert-circle </v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <ValidationObserver ref="obs5">
                          <p>We're going to finish off with a dive into your food and nutrition habits.</p>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="food allergies">
                            <span class="pr-3">
                              1. Do you have any food allergies, suspected intolerances or restrictions?</span
                            >
                            <j-radio-group
                              row
                              v-model="assessment.food_allergies"
                              class="pr-3 mt-2"
                              :error-messages="errors"
                            >
                              <j-radio label="Yes" :value="true" />
                              <j-radio label="No" :value="false" />
                            </j-radio-group>
                          </ValidationProvider>
                          <ValidationProvider
                            v-if="assessment.food_allergies"
                            v-slot="{ errors }"
                            rules="required"
                            name="food allergies"
                          >
                            <j-textarea
                              outlined
                              rows="2"
                              auto-grow
                              v-model="assessment.food_allergies_notes"
                              class="mb-3 mt-0 pt-0"
                              label="Please list those"
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="special diet">
                            <span class="pr-3"> 2. Are you currently following and special diet?</span>
                            <j-radio-group
                              row
                              v-model="assessment.special_diet"
                              class="pr-3 mt-2"
                              :error-messages="errors"
                            >
                              <j-radio label="Yes" :value="true" />
                              <j-radio label="No" :value="false" />
                            </j-radio-group>
                          </ValidationProvider>
                          <ValidationProvider
                            v-if="assessment.special_diet"
                            v-slot="{ errors }"
                            rules="required"
                            name="food allergies"
                          >
                            <j-textarea
                              outlined
                              rows="2"
                              auto-grow
                              v-model="assessment.special_diet_notes"
                              class="mb-3 mt-0 pt-0"
                              label="Please describe that"
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="nutritional supplements">
                            <span class="pr-3">
                              3. Are you currently taking any nutritional supplements, vitamins or minerals?</span
                            >
                            <j-radio-group
                              row
                              v-model="assessment.nutritional_supplements"
                              class="pr-3 mt-2"
                              :error-messages="errors"
                            >
                              <j-radio label="Yes" :value="true" />
                              <j-radio label="No" :value="false" />
                            </j-radio-group>
                          </ValidationProvider>
                          <ValidationProvider
                            v-if="assessment.nutritional_supplements"
                            v-slot="{ errors }"
                            rules="required"
                            name="nutritional_supplements"
                          >
                            <j-textarea
                              outlined
                              rows="2"
                              auto-grow
                              v-model="assessment.nutritional_supplements_notes"
                              class="mb-3 mt-0 pt-0"
                              label="Please list these"
                              :error-messages="errors"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="nutritional habit rating">
                            <span class="pr-3">
                              4. How would you rate your nutrition habits at the moment? <br />
                              <em class="ml-3 sm-text">(1 = very poor and 5 = perfect)</em>
                            </span>
                            <v-slider
                              v-model="assessment.nutritional_habit_rating"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.nutritional_habit_rating)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="eat frequently">
                            <span class="pr-3"> 5. On a typical day, how many times do you eat a meal or snack? </span>
                            <j-select
                              v-model="assessment.eat_frequently"
                              :items="[
                                {
                                  text: '1-2',
                                  value: '1-2',
                                },
                                {
                                  text: '3',
                                  value: '3',
                                },
                                {
                                  text: '4',
                                  value: '4',
                                },
                                {
                                  text: '5+',
                                  value: '5+',
                                },
                              ]"
                              :error-messages="errors"
                            >
                            </j-select>
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="how many meals">
                            <span class="pr-3"> 6. On average, how many meals do you eat at home? </span>
                            <j-select
                              v-model="userProfile.how_many_meals"
                              :items="[
                                {
                                  text: 'None',
                                  value: 'None',
                                },
                                {
                                  text: 'Some of them',
                                  value: 'Some of them',
                                },
                                {
                                  text: 'Most of them',
                                  value: 'Most of them',
                                },
                                {
                                  text: 'All of them',
                                  value: 'All of them',
                                },
                              ]"
                              :error-messages="errors"
                            >
                            </j-select>
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="eat out">
                            <span class="pr-3">
                              7. On average, how many times per week do you eat out or purchase take-away/ready-made
                              meals?
                            </span>
                            <j-select
                              v-model="assessment.eat_out"
                              :items="[
                                {
                                  text: '0',
                                  value: '0',
                                },
                                {
                                  text: '1-2',
                                  value: '1-2',
                                },
                                {
                                  text: '3-5',
                                  value: '3-5',
                                },
                                {
                                  text: '6+',
                                  value: '6+',
                                },
                              ]"
                              :error-messages="errors"
                            >
                            </j-select>
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="consume fruit vegetables">
                            <span class="pr-3">
                              8. How many times per day do you consume a portion of fruit or vegetables? <br />
                              <em class="ml-3 sm-text">One portion is roughly one fist size</em>
                            </span>

                            <j-select
                              v-model="assessment.consume_fruit_vegetables"
                              :items="[
                                {
                                  text: '0',
                                  value: '0',
                                },
                                {
                                  text: '1-2',
                                  value: '1-2',
                                },
                                {
                                  text: '3-5',
                                  value: '3-5',
                                },
                                {
                                  text: '6+',
                                  value: '6+',
                                },
                              ]"
                              :error-messages="errors"
                            >
                            </j-select>
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="consume starch">
                            <span class="pr-3">
                              9. How many times per day do you consume a portion of starch or grains (rice, pasta,
                              bread, potatoes, cereals etc)? <br />
                              <em class="ml-3 sm-text">One portion is roughly one cupped handful</em>
                            </span>
                            <j-select
                              v-model="assessment.consume_starch"
                              :items="[
                                {
                                  text: '0',
                                  value: '0',
                                },
                                {
                                  text: '1-2',
                                  value: '1-2',
                                },
                                {
                                  text: '3-5',
                                  value: '3-5',
                                },
                                {
                                  text: '6+',
                                  value: '6+',
                                },
                              ]"
                              :error-messages="errors"
                            >
                            </j-select>
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="consume protein">
                            <span class="pr-3">
                              10. How many times per day do you consume a portion of protein
                              (meat/fish/eggs/dairy/legumes etc)? <br />
                              <em class="ml-3 sm-text">One portion is roughly one palm size</em>
                            </span>
                            <j-select
                              v-model="assessment.consume_protein"
                              :items="[
                                {
                                  text: '0',
                                  value: '0',
                                },
                                {
                                  text: '1-2',
                                  value: '1-2',
                                },
                                {
                                  text: '3-5',
                                  value: '3-5',
                                },
                                {
                                  text: '6+',
                                  value: '6+',
                                },
                              ]"
                              :error-messages="errors"
                            >
                            </j-select>
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="consume fats">
                            <span class="pr-3">
                              11. How many times per day do you consume a portion of added fats (oils, dressings,
                              cheese, nuts, seeds, avo etc)? <br />
                              <em class="ml-3 sm-text">One portion is roughly one thumb size</em>
                            </span>
                            <j-select
                              v-model="assessment.consume_fats"
                              :items="[
                                {
                                  text: '0',
                                  value: '0',
                                },
                                {
                                  text: '1-2',
                                  value: '1-2',
                                },
                                {
                                  text: '3-5',
                                  value: '3-5',
                                },
                                {
                                  text: '6+',
                                  value: '6+',
                                },
                              ]"
                              :error-messages="errors"
                            >
                            </j-select>
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="daily hydration">
                            <span class="pr-3">
                              12. How would you rate your daily hydration? <br />
                              <em class="ml-3 sm-text">(1 = very poor and 5 = perfect)</em>
                            </span>
                            <v-slider
                              v-model="assessment.daily_hydration"
                              class="align-center mb-8"
                              step="1"
                              ticks="always"
                              tick-size="6"
                              track-color="grey"
                              :color="colorDecrement(assessment.daily_hydration)"
                              :max="5"
                              :min="1"
                              :error-messages="errors"
                              :tick-labels="ticksLabels"
                            />
                          </ValidationProvider>

                          <ValidationProvider rules="required" v-slot="{ errors }" name="consume alcohol">
                            <span class="pr-3"> 13. How many days per week do you consume alcohol? </span>
                            <j-select
                              v-model="assessment.consume_alcohol"
                              :items="[
                                {
                                  text: '0',
                                  value: '0',
                                },
                                {
                                  text: '1-2',
                                  value: '1-2',
                                },
                                {
                                  text: '3-5',
                                  value: '3-5',
                                },
                                {
                                  text: '6+',
                                  value: '6+',
                                },
                              ]"
                              :error-messages="errors"
                            >
                            </j-select>
                          </ValidationProvider>

                          <hr />
                          <br />
                          <p>
                            Thank you so much for taking the time to complete our form. We coach our best, by getting to
                            know you better.
                          </p>
                          <p>
                            Please can you confirm that all the above information is true to the best of your knowledge,
                            that you consent to this information being stored and used for the purposes of our coaching
                            and that you will update us on any relevant changes.
                          </p>
                          <p>
                            Once you submit the form, you will be prompted to submit your latest body measurements and a
                            few pictures before our kickoff meeting. You will be able to book this after you have
                            submitted your information.
                          </p>

                          <ValidationProvider
                            v-slot="{ errors }"
                            name="consent"
                            :rules="{ required: { allowFalse: false } }"
                          >
                            <j-checkbox
                              v-model="assessment.consent"
                              :label="
                                'I consent that all this information is true and I am responsible for notifying ' +
                                $appName +
                                ' if my conditions change  '
                              "
                              :error-messages="errors"
                            />
                          </ValidationProvider>
                          <ValidationProvider
                            v-slot="{ errors }"
                            name="indemnity"
                            :rules="{ required: { allowFalse: false } }"
                          >
                            <j-checkbox v-model="assessment.indemnity" :error-messages="errors">
                              <div slot="label">
                                I confirm that I have read the
                                <a
                                  href="https://www.jeff.fitness/pages/termsandconditions"
                                  target="_blank"
                                  class="font-weight-bold"
                                  @click.stop
                                >
                                  <u>T&Cs</u>
                                </a>
                                and will not hold {{ $appName }} liable for any loss caused by using the program
                              </div>
                            </j-checkbox>
                          </ValidationProvider>
                        </ValidationObserver>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <j-alert type="error" v-if="showAlert"> Please answer all questions. </j-alert>
                </v-col>
              </v-row>
              <v-row>
                <v-col align="center" justify="center">
                  <j-btn
                    secondary
                    large
                    wide
                    :loading="buttonLoading"
                    type="button"
                    :disabled="!isObs5_Touched"
                    @click.prevent="submit()"
                  >
                    Submit
                  </j-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="mt-2" align="center" justify="center">
            <span class="col-md-6 grey-navy--text">Having trouble submitting this form?</span>
            <br />
            <router-link to="/contact" class="col-md-6 contact-support">Contact our support team</router-link>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-else cols="12" align="center" justify="center">
        <j-alert type="error"> Sorry, something went wrong! </j-alert>
      </v-col>
    </v-row>
  </j-banner-container>
</template>

<script>
import { ACCOUNT_QUERY } from "@/graphql/queries/account";
import { UPDATE_PROFILE_PICTURE_MUTATION } from "@/graphql/mutations/account";
import { CREATE_MEDICAL_ASSESSMENT_MUTATION } from "@/graphql/mutations/medicalAssessment";
import "vue-advanced-cropper/dist/style.css";
import { Cropper } from "vue-advanced-cropper";
import ImageUploader from "vue-image-upload-resize";
import { dataURLtoBlob, blobToFile } from "@/lib/file";

export default {
  name: "MedicalAssessment",
  components: {
    ImageUploader,
    Cropper,
  },
  data() {
    return {
      isObs1_Touched: false,
      isObs2_Touched: false,
      isObs3_Touched: false,
      isObs4_Touched: false,
      isObs5_Touched: false,
      isObs1_Valid: false,
      isObs2_Valid: false,
      isObs3_Valid: false,
      isObs4_Valid: false,
      isObs5_Valid: false,
      isSubmitButton_Touched: false,
      assessment: {},
      userProfile: {
        profilePicture: null,
      },
      me: {},
      date: null,
      profileImgSrc: null,
      savingProfilePicture: false,
      loading: false,
      hasError: false,
      buttonLoading: false,
      showAlert: false,
      menu: false,
      dropdownOptions: {
        relationshipStatus: [
          { text: "Single", value: "Single" },
          { text: "In a Relationship", value: "In-Relationship" },
          { text: "Married", value: "Married" },
          { text: "Divorced", value: "Divorced" },
        ],
      },
      notesText: "Please fill in the text boxes with extra information in order to submit the form.",
      ticksLabels: [1, 2, 3, 4, 5],
    };
  },
  created() {
    let profile = this.$store.getters.profileImage;
    this.userProfile.profilePicture = profile ? profile : require("@/assets/images/JEFFProfilePicture.png");
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
    },
  },
  computed: {
    scrollOptions() {
      return {
        duration: 400,
        offset: 0,
        easing: "easeInOutCubic",
      };
    },
  },
  methods: {
    async verifyForm(formNumber) {
      this.$vuetify.goTo("#medical-form", this.scrollOptions);

      if (formNumber === 1) {
        this.isObs1_Touched = true;
      } else if (formNumber === 2) {
        this.isObs2_Touched = true;
        const obs1 = await this.$refs.obs1.validate();
        this.isObs1_Valid = obs1;
      } else if (formNumber === 3) {
        this.isObs3_Touched = true;
        const obs2 = await this.$refs.obs2.validate();
        this.isObs2_Valid = obs2;
      } else if (formNumber === 4) {
        this.isObs4_Touched = true;
        const obs3 = await this.$refs.obs3.validate();
        this.isObs3_Valid = obs3;
      } else if (formNumber === 5) {
        this.isObs5_Touched = true;
        const obs4 = await this.$refs.obs4.validate();
        this.isObs4_Valid = obs4;
      }
    },
    colorDecrement(val) {
      if (val == 1) return "indigo";
      if (val == 2) return "teal";
      if (val == 3) return "green";
      if (val == 4) return "orange";
      return "red";
    },
    handleAlert(invalid) {
      if (invalid) {
        this.showAlert = true;
      }
    },
    fieldOrNull(parent, key) {
      return this[parent][key] ? this[parent][key] : null;
    },
    fieldOrFalse(parent, key) {
      return this[parent][key] ? this[parent][key] : false;
    },
    updateProfilePicture({ canvas }) {
      this.userProfile.profilePicture = canvas.toDataURL();
    },
    setProfilePicture: function (output) {
      this.profileImgSrc = output.dataUrl;
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    saveProfilePicture() {
      this.savingProfilePicture = true;
      let blob = dataURLtoBlob(this.userProfile.profilePicture);
      let file = blobToFile(blob);
      this.$apollo
        .mutate({
          mutation: UPDATE_PROFILE_PICTURE_MUTATION,
          variables: {
            file: file,
          },
        })
        .then((response) => {
          this.savingProfilePicture = false;
          this.profileImgSrc = null;
          if (response.data.updateProfileImage.ok) {
            let url = response.data.updateProfileImage.profilePictureUrl;
            this.$store.dispatch("updateProfileImage", { url });
            this.$toasted.success("Profile Picture Updated", {
              icon: "arm-flex",
              duration: 2000,
              position: "bottom-center",
            });
          } else {
            this.$toasted.error("Unable to upload photo. Sorry.", {
              icon: "alert",
              duration: 2000,
              position: "bottom-center",
            });
          }
        });
    },
    async submit() {
      this.isSubmitButton_Touched = true;

      const obs1 = await this.$refs.obs1.validate();
      this.isObs1_Valid = obs1;
      this.handleAlert(!obs1);

      const obs2 = await this.$refs.obs2.validate();
      this.isObs2_Valid = obs2;
      this.handleAlert(!obs2);

      const obs3 = await this.$refs.obs3.validate();
      this.isObs3_Valid = obs3;
      this.handleAlert(!obs3);

      const obs4 = await this.$refs.obs4.validate();
      this.isObs4_Valid = obs4;
      this.handleAlert(!obs4);

      const obs5 = await this.$refs.obs5.validate();
      this.isObs5_Valid = obs5;
      this.handleAlert(!obs5);

      if (!obs1 || !obs2 || !obs3 || !obs4 || !obs5) return;

      this.buttonLoading = true;
      this.showAlert = false;
      let userProfileInput = {};
      let medicalAssessmentInput = {};

      // Lifestyle
      userProfileInput.dateOfBirth = this.date;
      userProfileInput.gender = this.userProfile.gender;
      userProfileInput.occupation = this.userProfile.occupation;
      userProfileInput.occupationHrsPerWeek = this.userProfile.occupation_hrs_per_week;
      userProfileInput.occupationWorkFromHome = this.userProfile.occupation_work_from_home;
      userProfileInput.occupationDailyRoutine = this.userProfile.occupation_daily_routine;
      medicalAssessmentInput.levelOfStress = this.assessment.level_of_stress || 0;
      medicalAssessmentInput.handleStress = this.assessment.handle_stress || 0;
      medicalAssessmentInput.hoursOfSleep = this.fieldOrNull("assessment", "hours_of_sleep");
      medicalAssessmentInput.qualityOfSleep = this.assessment.quality_of_sleep || 0;
      userProfileInput.relationshipStatus = this.userProfile.relationship_status || "";
      userProfileInput.hasChildren = this.fieldOrFalse("userProfile", "has_children");
      userProfileInput.noOfChildren = this.userProfile.no_of_children || 0;
      userProfileInput.childrenName = this.fieldOrNull("userProfile", "children_name");
      medicalAssessmentInput.support = this.fieldOrNull("assessment", "support");

      // Goals
      medicalAssessmentInput.goalDefinition = this.fieldOrNull("assessment", "goal_definition");
      medicalAssessmentInput.anotherGoal = this.fieldOrFalse("assessment", "another_goal");
      medicalAssessmentInput.anotherGoalDescription = this.fieldOrNull("assessment", "another_goal_description");
      medicalAssessmentInput.goalTimeFrame = this.fieldOrNull("assessment", "goal_time_frame");
      medicalAssessmentInput.goalImportant = this.fieldOrNull("assessment", "goal_important");
      medicalAssessmentInput.lackOfMotivation = this.assessment.lack_of_motivation || 0;
      medicalAssessmentInput.lackOfEnergy = this.assessment.lack_of_energy || 0;
      medicalAssessmentInput.lackOfConsistency = this.assessment.lack_of_consistency || 0;
      medicalAssessmentInput.injury = this.assessment.injury || 0;
      medicalAssessmentInput.whatTypeOfExercise = this.assessment.what_type_of_exercise || 0;
      medicalAssessmentInput.certainExercisesProperly = this.assessment.certain_exercises_properly || 0;
      medicalAssessmentInput.enoughTimeToExercise = this.assessment.enough_time_to_exercise || 0;
      medicalAssessmentInput.whatToEat = this.assessment.what_to_eat || 0;
      medicalAssessmentInput.howMuchToEat = this.assessment.how_much_to_eat || 0;
      medicalAssessmentInput.planningMeals = this.assessment.planning_meals || 0;
      medicalAssessmentInput.enoughTimeToCook = this.assessment.enough_time_to_cook || 0;
      medicalAssessmentInput.howToCook = this.assessment.how_to_cook || 0;
      medicalAssessmentInput.foodCravings = this.assessment.food_cravings || 0;
      medicalAssessmentInput.eatingPoorly = this.assessment.eating_poorly || 0;
      medicalAssessmentInput.alwaysHungry = this.assessment.always_hungry || 0;
      medicalAssessmentInput.overEating = this.assessment.over_eating || 0;
      medicalAssessmentInput.weekendWobbles = this.assessment.weekend_wobbles || 0;
      medicalAssessmentInput.culturalChallenges = this.assessment.cultural_challenges || 0;
      medicalAssessmentInput.regularlyCycling = this.assessment.regularly_cycling || 0;
      medicalAssessmentInput.excessiveAlcohol = this.assessment.excessive_alcohol || 0;

      // Medical Information
      medicalAssessmentInput.heartCondition = this.assessment.heart_condition || "";
      medicalAssessmentInput.highBloodPressure = this.assessment.high_blood_pressure || "";
      medicalAssessmentInput.diabetes = this.fieldOrFalse("assessment", "diabetes");
      medicalAssessmentInput.highCholesterol = this.fieldOrFalse("assessment", "high_cholesterol");
      medicalAssessmentInput.chestPain = this.assessment.chest_pain || "";
      medicalAssessmentInput.dizziness = this.assessment.dizziness || "";
      medicalAssessmentInput.jointPain = this.assessment.joint_pain || "";
      medicalAssessmentInput.backNeckPain = this.assessment.back_neck_pain || "";
      medicalAssessmentInput.chronicCondition = this.assessment.chronic_condition || "";
      medicalAssessmentInput.arthritis = this.assessment.arthritis || "";
      medicalAssessmentInput.softTissueProblem = this.assessment.soft_tissue_problem || "";
      medicalAssessmentInput.recentInjury = this.assessment.recent_injury || "";
      medicalAssessmentInput.recentInjuryNotes = this.fieldOrNull("assessment", "recent_injury_notes");
      medicalAssessmentInput.musculoskeletalSurgery = this.assessment.musculoskeletal_surgery || "";
      medicalAssessmentInput.musculoskeletalSurgeryNotes = this.fieldOrNull(
        "assessment",
        "musculoskeletal_surgery_notes"
      );
      medicalAssessmentInput.thyroidHormonalProblem = this.assessment.thyroid_hormonal_problem || "";
      medicalAssessmentInput.hasHadCovid19 = this.assessment.has_had_covid_19 || "";
      medicalAssessmentInput.medicallySupervised = this.assessment.medically_supervised || "";
      medicalAssessmentInput.medicallySupervisedNotes = this.fieldOrNull("assessment", "medically_supervised_notes");
      medicalAssessmentInput.prescribedMedicationNotes = this.fieldOrFalse("assessment", "prescribed_medication_notes");
      medicalAssessmentInput.possiblyPregnant = this.fieldOrFalse("assessment", "possibly_pregnant");
      medicalAssessmentInput.gaveBirthLastSixMonths = this.fieldOrFalse("assessment", "gave_birth_last_six_months");
      medicalAssessmentInput.reasonsToNotExercise = this.assessment.reasons_to_not_exercise || "";
      medicalAssessmentInput.reasonsToNotExerciseNotes = this.fieldOrNull(
        "assessment",
        "reasons_to_not_exercise_notes"
      );

      // Exercise
      medicalAssessmentInput.levelOfFitness = this.assessment.level_of_fitness || 0;
      medicalAssessmentInput.daysCommit = this.fieldOrNull("assessment", "days_commit");
      medicalAssessmentInput.timeCommit = this.fieldOrNull("assessment", "time_commit");
      medicalAssessmentInput.walking = this.fieldOrNull("assessment", "walking");
      medicalAssessmentInput.hiking = this.assessment.hiking || 0;
      medicalAssessmentInput.running = this.assessment.running || 0;
      medicalAssessmentInput.swimming = this.assessment.swimming || 0;
      medicalAssessmentInput.strengthTraining = this.assessment.strength_training || 0;
      medicalAssessmentInput.intensityTraining = this.assessment.intensity_training || 0;
      medicalAssessmentInput.workingOutAtHome = this.assessment.working_out_at_home || 0;
      medicalAssessmentInput.workingOutAtGym = this.assessment.working_out_at_gym || 0;
      medicalAssessmentInput.yoga = this.assessment.yoga || 0;
      medicalAssessmentInput.pilates = this.assessment.pilates || 0;
      medicalAssessmentInput.cycling = this.assessment.cycling || 0;
      medicalAssessmentInput.otherTeamSports = this.assessment.other_team_sports || 0;
      medicalAssessmentInput.otherIndividualSports = this.assessment.other_individual_sports || 0;
      medicalAssessmentInput.enjoyWorking = this.assessment.enjoy_working || 0;

      // Nutritional Information
      medicalAssessmentInput.foodAllergies = this.fieldOrFalse("assessment", "food_allergies");
      medicalAssessmentInput.foodAllergiesNotes = this.fieldOrNull("assessment", "food_allergies_notes");
      medicalAssessmentInput.specialDiet = this.fieldOrFalse("assessment", "special_diet");
      medicalAssessmentInput.specialDietNotes = this.fieldOrNull("assessment", "special_diet_notes");
      medicalAssessmentInput.nutritionalSupplements = this.fieldOrFalse("assessment", "nutritional_supplements");
      medicalAssessmentInput.nutritionalSupplementsNotes = this.fieldOrNull(
        "assessment",
        "nutritional_supplements_notes"
      );
      medicalAssessmentInput.nutritionalHabitRating = this.assessment.nutritional_habit_rating || 0;
      medicalAssessmentInput.eatFrequently = this.fieldOrNull("assessment", "eat_frequently");
      medicalAssessmentInput.howManyMeals = this.fieldOrNull("assessment", "how_many_meals");
      medicalAssessmentInput.eatOut = this.fieldOrNull("assessment", "eat_out");
      medicalAssessmentInput.consumeFruitVegetables = this.fieldOrNull("assessment", "consume_fruit_vegetables");
      medicalAssessmentInput.consumeStarch = this.fieldOrNull("assessment", "consume_starch");
      medicalAssessmentInput.consumeProtein = this.fieldOrNull("assessment", "consume_protein");
      medicalAssessmentInput.consumeFats = this.fieldOrNull("assessment", "consume_fats");
      medicalAssessmentInput.dailyHydration = this.assessment.daily_hydration || 0;
      medicalAssessmentInput.consumeAlcohol = this.fieldOrNull("assessment", "consume_alcohol");
      medicalAssessmentInput.consent = this.fieldOrFalse("assessment", "consent");
      medicalAssessmentInput.indemnity = this.fieldOrFalse("assessment", "indemnity");

      // Others
      medicalAssessmentInput.assessmentDate = new Date();
      medicalAssessmentInput.pregnantWeeks = this.fieldOrNull("assessment", "pregnant_weeks");
      medicalAssessmentInput.clearedByProfessional = this.fieldOrFalse("assessment", "cleared_by_professional");
      medicalAssessmentInput.heartConditionNotes = this.fieldOrNull("assessment", "heart_condition_notes");
      medicalAssessmentInput.highBloodPressureNotes = this.fieldOrNull("assessment", "high_blood_pressure_notes");
      medicalAssessmentInput.chestPainNotes = this.fieldOrNull("assessment", "chest_pain_notes");
      medicalAssessmentInput.dizzinessNotes = this.fieldOrNull("assessment", "dizziness_notes");
      medicalAssessmentInput.jointPainNotes = this.fieldOrNull("assessment", "joint_pain_notes");
      medicalAssessmentInput.backNeckPainNotes = this.fieldOrNull("assessment", "back_neck_pain_notes");
      medicalAssessmentInput.chronicConditionNotes = this.fieldOrNull("assessment", "chronic_condition_notes");
      medicalAssessmentInput.arthritisNotes = this.fieldOrNull("assessment", "arthritis_notes");
      medicalAssessmentInput.softTissueProblemNotes = this.fieldOrNull("assessment", "soft_tissue_problem_notes");
      medicalAssessmentInput.thyroidHormonalProblemNotes = this.fieldOrNull(
        "assessment",
        "thyroid_hormonal_problem_notes"
      );
      medicalAssessmentInput.prescribedMedication = this.fieldOrFalse("assessment", "prescribed_medication");
      medicalAssessmentInput.recentBaby = this.fieldOrFalse("assessment", "recent_baby");
      medicalAssessmentInput.recentBabyWeeks = this.fieldOrNull("assessment", "recent_baby_weeks");
      medicalAssessmentInput.otherInjuries = this.assessment.other_injuries || "";

      //personal information
      medicalAssessmentInput.dailyRoutine = this.assessment.daily_routine;
      medicalAssessmentInput.spousalDetails = this.assessment.spousal_details;
      medicalAssessmentInput.childrenDetails = this.assessment.children_details;

      //nutritional information
      medicalAssessmentInput.nutritionalKnowledgeRating = this.assessment.nutritional_knowledge_rating || 0;
      medicalAssessmentInput.previousSuccessfulDiets = this.assessment.previous_successful_diets;
      medicalAssessmentInput.nutritionalTriggers = this.assessment.nutritional_triggers;
      medicalAssessmentInput.externalShopper = this.assessment.external_shopper;

      //fitness information
      medicalAssessmentInput.exerciseHistory = this.assessment.exercise_history;
      medicalAssessmentInput.bestWeight = this.assessment.best_weight;
      medicalAssessmentInput.currentJeanSize = this.assessment.current_jean_size;

      const result = await this.$apollo.mutate({
        mutation: CREATE_MEDICAL_ASSESSMENT_MUTATION,
        variables: {
          medicalAssessmentInput: medicalAssessmentInput,
          userProfileInput: userProfileInput,
        },
        refetchQueries: ["assistantQuery"],
      });

      if (result.data) {
        this.buttonLoading = false;
        this.$router.push("/today/medical-assessment/thank-you");
      }
    },
  },
  apollo: {
    me: {
      query: ACCOUNT_QUERY,
      fetchPolicy: "network-only",
      result() {
        this.date = this.me.userProfile.dateOfBirth;
        this.userProfile.gender = this.me.userProfile.gender;
      },
    },
  },
};
</script>

<style scoped>
a {
  text-decoration-line: none;
}

a.contact-support {
  font-weight: bold;
}

.heading {
  font-weight: bold;
  text-align: center;
}

::v-deep .v-slider__tick-label {
  left: 4px;
}

::v-deep .v-slider__track-container {
  height: 5px;
}
</style>
