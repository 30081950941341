<template>
  <div v-if="isVisible">
    <nps-control
      v-if="surveyQuestion.question.questionType == 'NPS'"
      :surveyQuestion="surveyQuestion"
      @answerSaved="answerSaved"
    />
    <free-text-control
      v-if="surveyQuestion.question.questionType == 'FREE_TEXT'"
      :surveyQuestion="surveyQuestion"
      @answerSaved="answerSaved"
    />
    <mcq-control
      v-if="surveyQuestion.question.questionType == 'MCQ'"
      :surveyQuestion="surveyQuestion"
      @answerSaved="answerSaved"
    />
    <bool-control
      v-if="surveyQuestion.question.questionType == 'BOOL'"
      :surveyQuestion="surveyQuestion"
      @answerSaved="answerSaved"
    />
  </div>
</template>

<script>
import NpsControl from "../controls/NpsControl.vue";
import BoolControl from "../controls/BoolControl.vue";
import FreeTextControl from "../controls/FreeTextControl.vue";
import McqControl from "../controls/McqControl.vue";

export default {
  name: "QuestionBlock",
  components: {
    NpsControl,
    BoolControl,
    FreeTextControl,
    McqControl,
  },
  props: {
    surveyQuestion: {
      required: true,
    },
  },
  computed: {
    isVisible() {
      // if no parent, then it must always show. only children toggle between show/hide
      if (!this.surveyQuestion.question.parent) {
        return true;
      }
      // this is a child question. So now we determine whether we need to show it.
      const displayCriteria = this.surveyQuestion.question.displayCriteria;
      const matchedQuestion = this.$store.getters.selectedAnswers.filter(
        (el) => el.questionId == this.surveyQuestion.question.parent.id
      );
      if (matchedQuestion.length > 0) {
        if (displayCriteria.comparisonType == "LESS_THAN" && matchedQuestion[0].answer < displayCriteria.value) {
          return true;
        }
        if (displayCriteria.comparisonType == "EQUAL" && matchedQuestion[0].answer == displayCriteria.value) {
          return true;
        }
        if (displayCriteria.comparisonType == "GREATER_THAN" && matchedQuestion[0].answer > displayCriteria.value) {
          return true;
        }

        if (displayCriteria.comparisonType == "BETWEEN") {
          let parts = displayCriteria.value.split("-");
          if (
            parts.length == 2 &&
            matchedQuestion[0].answer > parts[0].trim() &&
            matchedQuestion[0].answer < parts[1].trim()
          ) {
            return true;
          }
          return false;
        }
      }
      return false;
    },
  },
  methods: {
    answerSaved() {
      this.$emit("answerSaved");
    },
  },
};
</script>
