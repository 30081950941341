import { getColor } from "@/lib/vuetifyColorHelper";
import { fancy_date } from "@/lib/fancy_date";
import { getDayOfWeekString } from "@/lib/timezone";

export default {
  data() {
    return {
      loading: true,
      dayTab: 0,
      weekActivities: null,
      week: null,
    };
  },
  created() {
    this.week = getDayOfWeekString(1, false, this.$moment().format("YYYY-MM-DD"), false).substring(0, 10);
    this.setDay();
  },
  computed: {
    days() {
      var days = [];
      var monday = this.getMonday();
      var dayStr = ["M", "T", "W", "T", "F", "S", "S"];

      for (var i = 0; i < 7; i++) {
        var day = {};
        var date = new Date(monday);
        date.setDate(date.getDate() + i);
        day["day"] = dayStr[i];
        day["date"] = date;
        days.push(day);
      }
      return days;
    },
    getSelectedDate() {
      let newDate = this.$moment(this.week, "YYYY-MM-DD")
        .startOf("week")
        .add(this.dayTab + 1, "days");
      return fancy_date(newDate);
    },
    borderColorStyle() {
      return `border-color: ${getColor(this.accentColor)}`;
    },
  },
  methods: {
    updateWeek(direction) {
      this.week = this.$moment(this.week, "YYYY-MM-DD")
        .add(direction * 7, "days")
        .format("YYYY-MM-DD");
      this.changeDate(this.dayTab);
    },
    getMonday() {
      var startDate = new Date(`${this.week}T00:00:00`);
      startDate.setHours(0, 0, 0, 0);
      this.week = startDate;

      var day = startDate.getDay(),
        diff = startDate.getDate() - day + (day == 0 ? -6 : 1);
      return new Date(startDate.setDate(diff));
    },
    formatDate(date) {
      return this.$moment(date).format("YYYY-MM-DD");
    },
    getDay(date) {
      return this.$moment(date).format("dd")[0];
    },
  },
};
