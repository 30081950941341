import gql from "graphql-tag";

export const CELEBRATION_QUERY = gql`
  query celebrationQuery($id: ID!) {
    celebration(id: $id) {
      id
      weight
      size
      months
      cm
      leftImage
      rightImage
      celebrationImage
    }
  }
`;

export const CELEBRATIONS_QUERY = gql`
  query celebrationsQuery($orderBy: String, $userId: ID, $hasCelebrationImage: Boolean) {
    celebrations(orderBy: $orderBy, userId: $userId, hasCelebrationImage: $hasCelebrationImage) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
      edges {
        node {
          id
          weight
          size
          months
          cm
          leftImage
          rightImage
          celebrationImage
          createdAt
        }
      }
    }
  }
`;
