<template>
  <j-subsection-row
    v-if="dailyActivities && dailyActivities.length > 0"
    :title="subscriptionBlock.block.title"
    :color="accentColor"
    :see-all="{
      text: seeAllText,
      link: '/program/' + blockId,
    }"
    class="mt-2"
  >
    <div v-if="$apollo.loading" class="mb-3 py-1">
      <v-responsive :aspect-ratio="16 / 9" class="rounded-t-xl">
        <v-skeleton-loader type="image" class="full-height" />
      </v-responsive>
      <v-skeleton-loader type="list-item-avatar-two-line" class="rounded-b-xl" />
    </div>
    <div v-else>
      <today-activity-block
        v-for="(unitActivity, index) in dailyActivities"
        :key="unitActivity.id"
        :unit-activity="unitActivity"
        :subscription-block="subscriptionBlock"
        :anchor-date="anchorDate"
        :showArrows="index == 0"
        class="mb-3"
        :completed="hasCompleted(unitActivity, restDays, customActivities, anchorDate, subscriptionBlock.id)"
        :skip-fresh-count="skipFreshCount"
        @fresh-block="$emit('fresh-block', $event)"
        @change-activity="updateActivity($event)"
      />
    </div>
  </j-subsection-row>
</template>

<script>
import { DAILY_WORKOUT_ACTIVITIES_QUERY, WORKOUT_BLOCK_WORKOUT_LOGS } from "@/graphql/queries/workoutblock";
import { ACTIVITIES_AND_NUTRITION_QUERY } from "@/graphql/queries/tracker";
import { getDayOfWeekString } from "@/lib/timezone";
import { mapGetters, mapActions } from "vuex";
import { decodeId, toGraphqlID } from "@/lib/string";
import BlockMixin from "@/mixins/BlockMixin";
import TodayActivityBlock from "@/components/workoutblocks/cards/TodayActivityBlock";

export default {
  name: "TodayWorkoutBlock",
  mixins: [BlockMixin],
  components: {
    TodayActivityBlock,
  },
  props: {
    subscriptionBlock: {
      type: Object,
      required: true,
    },
    anchorDate: {
      type: String,
      required: true,
    },
    skipFreshCount: {
      type: Number,
      default: 0,
    },
    seeAllText: {
      type: String,
      default: "Full Program",
    },
  },
  data() {
    return {
      activityCount: null,
      currentActivity: null, // store a handle on the current activity that the user has done. This handle is not meant to be mutated!
    };
  },
  watch: {
    anchorDate() {
      // we are watching the anchor date for changes. If it changes, update activity count by adding/subtracting the days it changed.
      // we aer using currentActivity, which is not mutated.
      const dayDiff = this.$moment(this.anchorDate).startOf("day").diff(this.$moment().startOf("day"), "days");
      this.activityCount = this.currentActivity + dayDiff;
    },
  },
  computed: {
    ...mapGetters(["restDays", "role"]),
    accentColor() {
      if (this.subscriptionBlock.block.type === "COURSE") {
        return "mindset";
      }
      if (this.subscriptionBlock.block.type === "NUTRITION") {
        return "nutrition";
      }
      return "pink";
    },
    dailyActivities() {
      return this.dailyWorkoutActivities?.length > 0 && this.dailyWorkoutActivities[0].activities;
    },
    blockId() {
      return decodeId(this.subscriptionBlock.block?.id);
    },
    hasAccess() {
      return this.subscriptionBlock != null && (this.role === "ONE_ON_ONE" || this.role === "CLUB");
    },
  },
  methods: {
    ...mapActions(["setRestDays"]),
    decodeId(value) {
      return decodeId(value);
    },
    updateActivity(direction) {
      this.activityCount += direction;
    },
  },
  apollo: {
    dailyWorkoutActivities: {
      query: DAILY_WORKOUT_ACTIVITIES_QUERY,
      fetchPolicy: "cache-and-network",
      skip() {
        return !this.subscriptionBlock || !this.anchorDate;
      },
      variables() {
        return {
          anchorDate: this.anchorDate,
          activityCount: this.activityCount,
          subscriptionBlockId: decodeId(this.subscriptionBlock?.id),
        };
      },
      update(data) {
        if (!this.activityCount && data.dailyWorkoutActivities.length > 0) {
          this.activityCount = data.dailyWorkoutActivities[0]?.activities[0]?.currentActivity;
          // only set it once, the first time! 
          if (this.currentActivity == null) {
            this.currentActivity = this.activityCount;
          }
        }

        return data.dailyWorkoutActivities;
      },
    },
    customActivities: {
      query: ACTIVITIES_AND_NUTRITION_QUERY,
      variables() {
        return {
          startDate: getDayOfWeekString(1, false, this.week, false),
          endDate: getDayOfWeekString(7, true, this.week, false),
          userId: window.btoa(`UserNode: ${this.$store.getters?.user?.id}`),
        };
      },
      fetchPolicy: "network-only",
      update(data) {
        this.setRestDays(data.restDays.edges);

        return data.activities.edges.map((item) => item.node) ?? [];
      },
    },
    workoutBlockWorkoutLogs: {
      query: WORKOUT_BLOCK_WORKOUT_LOGS,
      fetchPolicy: "network-only",
      skip() {
        return !this.subscriptionBlock;
      },
      variables() {
        return {
          subscriptionBlockId: toGraphqlID("SubscriptionBlock", this.subscriptionBlock?.id),
        };
      },
      update(data) {
        return data.workoutBlockWorkoutLogs.edges;
      },
    },
  },
};
</script>

<style lang="scss">
.v-skeleton-loader.full-height {
  height: 100%;
  .v-skeleton-loader__image {
    height: 100%;
  }
}
</style>
