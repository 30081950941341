<template>
  <div>
    <j-alert type="success" :icon="false" rounded="xl" class="text-center">
      <h2>Photo logged and shared!</h2>
    </j-alert>
    <j-alert type="warning" :icon="false" rounded="xl" class="text-center">
      <h2 class="mb-4">You got 2 entries ✌️🎉</h2>
      <span class="std-text text--darken-1 yellow-icon--text">
        You could be the lucky winner of {{ activePrizeText }}
      </span>
    </j-alert>
  </div>
</template>

<script>
import { getColor } from "@/lib/vuetifyColorHelper";

export default {
  name: "SuccessfulEntryAlerts",
  props: {
    activePrizeText: {
      type: String,
      default: "our weekly prize",
    },
  },
  created() {
    this.$confetti.start({
      particlesPerFrame: 1.5,
      particles: [
        {
          type: "heart",
        },
        {
          type: "circle",
        },
        {
          type: "rect",
        },
      ],
      defaultColors: [
        getColor("exercise"),
        getColor("nutrition"),
        getColor("mindset"),
        getColor("sleep"),
        getColor("hydration"),
        getColor("gold"),
      ],
    });
    setTimeout(() => {
      this.$confetti.stop();
    }, 2000);
  },
};
</script>
