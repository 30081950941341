
export const dedupeNodes = (items, elementName = 'node') => {
  const seen = new Set();
  // dedupe but also get latest if on update
  return items.reverse().filter((el) => {
    const duplicate = seen.has(el[elementName].id);
    seen.add(el[elementName].id);
    return !duplicate;
  }).reverse(); //then set back to the original order again
};


export const isIterable = (items) => {
  return Symbol.iterator in Object(items);
};

export const sortObject = (original) => {
  const ordered = Object.keys(original).sort().reduce(
    (obj, key) => {
      obj[key] = original[key];
      return obj;
    },
    {}
  );
  return ordered;
}

export const extractNodes = (edges = []) => {
  return edges?.map(edge => {
    return edge.node;
  }) ?? [];
}
