<template>
  <div>
    <v-container v-if="!bookmarkId && !savedBookmarkId" class="pa-0 pt-1">
      <v-btn small icon>
        <v-icon v-if="!bookmarkId && !savedBookmarkId" @click.stop="addItem()" color="light-purple">
          mdi-bookmark-outline
        </v-icon>
        <v-icon v-else color="light-purple"> mdi-bookmark </v-icon>
      </v-btn>
    </v-container>
    <v-container v-else class="pa-0 pt-1">
      <v-btn @click.stop="removeItem()" small icon>
        <v-icon color="light-purple"> mdi-bookmark </v-icon>
      </v-btn>
      <v-btn small icon v-if="isObjectNote">
        <v-icon @click.stop="bookMarkNoteModal = true" color="light-purple">
          mdi-pencil
        </v-icon>
      </v-btn>
    </v-container>

    <!-- Bookmark note dialog -->
    <v-dialog v-if="bookMarkNoteModal" v-model="bookMarkNoteModal" max-width="600px">
      <j-card rounded="lg">
        <v-toolbar class="mb-6">
          <v-toolbar-title class="navy--text headline"> Bookmark Notes </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="bookMarkNoteModal = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <ValidationObserver ref="bf">
            <div class="pt-2 pb-0">
              <ValidationProvider rules="required" name="Notes" v-slot="{ errors }">
                <j-text-field
                  :error-messages="errors"
                  dense
                  ref="bookmarkNotes"
                  placeholder=""
                  class="pt-0 mt-0"
                  v-model="bookmarkNotes"
                />
              </ValidationProvider>
            </div>

            <v-card-actions>
              <j-btn secondary class="ml-auto" @click="addItemNote()" :loading="loading">
                Save
              </j-btn>
            </v-card-actions>
          </ValidationObserver>
        </v-card-text>
      </j-card>
    </v-dialog>
  </div>
</template>

<script>
import { decodeId } from "@/lib/string";
import {
  UPSERT_BOOKMARK_MUTATION,
  ADD_BOOKMARK_NOTE_MUTATION,
  DELETE_BOOKMARK_MUTATION,
} from "@/graphql/mutations/bookmark";

export default {
  name: "ManageBookmark",
  components: {},
  props: {
    isObjectNote: {
      type: Boolean,
      default: false,
    },
    bookmarkId: {
      type: [Number, String],
      required: false,
    },
    objectType: {
      type: String,
      required: false,
    },
    objectNotes: {
      type: String,
      required: false,
    },
    objectId: {
      type: String,
      required: false,
    },
    refreshQuery: {
      type: String,
      required: false,
      default: "bookmarks",
    },
  },
  data() {
    return {
      bookmarkNotes: null,
      loading: false,
      notes: null,
      bookMarkNoteModal: false,
      saving: false,
      savedBookmarkId: null,
    };
  },
  mounted() {
    this.bookmarkNotes = this.objectNotes;
  },
  methods: {
    async addItemNote() {
      const valid = await this.$refs.bf.validate();
      if (!valid) return;

      this.loading = true;

      const { data } = await this.$apollo.mutate({
        mutation: ADD_BOOKMARK_NOTE_MUTATION,
        variables: {
          notes: this.bookmarkNotes,
          bookmarkId: decodeId(this.bookmarkId),
        },
        refetchQueries: [this.refreshQuery],
      });

      if (data.addBookmarkNote.success) {
        this.$toasted.success("Bookmark notes saved.", {
          icon: "info",
          duration: 2000,
          position: "bottom-center",
        });
      } else {
        this.$toasted.error("Unable to save notes.", {
          icon: "alert",
          duration: 2000,
          position: "bottom-center",
        });
      }

      this.$refs.bf.reset();
      this.bookMarkNoteModal = false;
      this.loading = false;
    },
    async addItem() {
      const { data } = await this.$apollo.mutate({
        mutation: UPSERT_BOOKMARK_MUTATION,
        variables: {
          objectType: this.objectType,
          objectId: decodeId(this.objectId),
        },
        refetchQueries: [this.refreshQuery],
      });

      if (data.upsertBookmark.success) {
        this.savedBookmarkId = data.upsertBookmark.bookmark.id;
        this.$emit("saved", this.savedBookmarkId);
      } else {
        this.$toasted.error("Unable to save for later. Sorry.", {
          icon: "alert",
          duration: 2000,
          position: "bottom-center",
        });
      }
    },
    async removeItem() {
      const { data } = await this.$apollo.mutate({
        mutation: DELETE_BOOKMARK_MUTATION,
        variables: {
          bookmarkId: decodeId(this.bookmarkId),
        },
        refetchQueries: [this.refreshQuery],
      });

      if (data.deleteBookmark.success) {
        this.savedBookmarkId = null;
        // a typical JS hack? FML
        this.$emit("saved", -1);
      } else {
        this.$toasted.error("Unable to remove item. Please try again later.", {
          icon: "alert",
          duration: 2000,
          position: "bottom-center",
        });
      }
    },
  },
};
</script>
