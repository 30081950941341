import moment from "moment";
import { capitalize } from "./string";

export const fancy_date = (current_date, format = "ddd, D MMM YY", showDaysAgo = true) => {
  let date = moment(current_date, "YYYY-MM-DD");
  let today = moment();
  let dayDelta = today.diff(date, "days", true);
  let dateString = moment(current_date, "YYYY-MM-DD").format(format);

  if (!showDaysAgo) return dateString;

  if (dayDelta >= 0) {
    if (dayDelta > 1 && dayDelta < 2) {
      dateString = "Yesterday: " + dateString;
    } else if (dayDelta > 0 && dayDelta < 1) {
      dateString = "Today: " + dateString;
    } else dateString = Math.floor(dayDelta.toString()) + " days ago: " + dateString;
  }
  return dateString;
};

export const fancy_date_from_iso = (current_date, format = "ddd, D MMMM YYYY", showDaysAgo = true) => {
  let date = moment(current_date).format("YYYY-MM-DD");
  return fancy_date(date, format, showDaysAgo);
};

export const fancyShortDate = (current_date) => {
  return capitalize(moment(current_date).fromNow(false));
};

export const formatDate = (current_date, format = "ddd, D MMMM YYYY", showDaysAgo = true) => {
  let dateString = moment(current_date, "YYYY-MM-DD").format(format);
  if (!showDaysAgo) return dateString;

  let precursor = daysAgoDescriptor(current_date);
  return `${precursor}: ${dateString}`;
};

export const daysAgoDescriptor = (current_date) => {
  let date = moment(current_date);

  let dayDelta = moment().endOf('day').diff(date, "days", true);
  if (dayDelta > -1 && dayDelta < 2) {
    if (dayDelta > 1 && dayDelta < 2) {
      return "Yesterday";
    } else if (dayDelta > 0 && dayDelta < 1) {
      return "Today";
    } else if (dayDelta < 0 && dayDelta > -1) {
      return "Tomorrow";
    }
  } else return date.fromNow();
};

/**
 * Pass an integer for day required and get back the next instance of this day. e.g On Tuesday, 19 October,
 * pass 4 for Thursday will return 21 October
 * @param {int} dayINeed
 * @returns YYYY-MM-DD formatted date.
 */
export const nextDateForDay = (dayINeed, nextWeekIfToday = false) => {
  //dayINeed = 1 is Monday etc...
  const today = moment().isoWeekday();

  if (!nextWeekIfToday && today <= dayINeed) {
    return moment()
      .isoWeekday(dayINeed)
      .format("YYYY-MM-DD");
  }
  if (nextWeekIfToday && today < dayINeed) {
    return moment()
      .isoWeekday(dayINeed)
      .format("YYYY-MM-DD");
  }
  // otherwise, give me *next week's* instance of that same day
  return moment()
    .add(1, "weeks")
    .isoWeekday(dayINeed)
    .format("YYYY-MM-DD");
};

export const getThisMonday = () => {
  return moment().startOf('isoweek').format("YYYY-MM-DD");
};

export const getNextMonday = () => {
  return moment().add(1, 'weeks').startOf('isoWeek').format("YYYY-MM-DD");
};

export const getNextSunday = (dateString) => {
  return moment(dateString).add(6, 'days').format("YYYY-MM-DD");
}

export const getDayFromNumber = (number) => {
  let weekday = [];
      weekday[0] = "Monday";
      weekday[1] = "Tuesday";
      weekday[2] = "Wednesday";
      weekday[3] = "Thursday";
      weekday[4] = "Friday";
      weekday[5] = "Saturday";
      weekday[6] = "Sunday";
      return weekday[number];
}