import Vue from "vue";
import Router from "vue-router";
//import moment from "moment";
import { store } from "@/store";
import { AUTHORISATION_QUERY } from "@/graphql/queries/account";
import { decodeId } from "@/lib/string";
import { apolloProvider } from "@/graphql/apollo";
import Cookies from "js-cookie";

import Login from "@/components/authentication/Login";
import RequestNewPassword from "@/views/account/RequestNewPassword";
import ResetPassword from "@/views/account/ResetPassword";
import Account from "@/views/account/Account";
import LinkCorporateChallenge from "@/views/account/LinkCorporateChallenge";
import Discovery from "@/views/account/Discovery";
import Register from "@/views/account/Register";
import Team from "@/views/support/Team";
import TeamSuccess from "@/views/support/TeamSuccess";
import AccountGeneral from "@/views/account/AccountGeneral";
import AccountBilling from "@/views/account/AccountBilling";
import Preferences from "@/views/account/Preferences";

import MedicalAssessment from "@/views/home/assistant/MedicalAssessment";
import MedicalAssessmentThankYou from "@/views/home/assistant/MedicalAssessmentThankYou";
import Assistant from "@/views/home/assistant/Assistant";
import AssistantCheckin from "@/views/home/assistant/Checkin";
import JoinFacebook from "@/views/home/assistant/JoinFacebook";
import JoinWhatsapp from "@/views/home/assistant/JoinWhatsapp";
import Tips from "@/views/home/assistant/Tips";
import Inbox from "@/views/home/inbox/Inbox";
import Message from "@/views/home/inbox/Message";

import Profile from "@/views/profile/Profile";

import PointsPage from "@/views/rewards/Points";
import EarnPointsPage from "@/views/rewards/Earn";
import UsePointsPage from "@/views/rewards/Use";

import RecipePage from "@/views/recipes/Recipe";
import UpsertRecipePage from "@/views/recipes/UpsertRecipe";
import RecipeList from "@/views/recipes/RecipeList";

import CheckoutPage from "@/views/store/Checkout";
import PaymentResultPage from "@/views/store/PaymentResult";
import LoginCheckout from "@/views/store/LoginCheckout";
import CreateUserCheckout from "@/views/store/CreateUserCheckout";
import ColdCheckoutPassword from "@/views/store/ColdCheckoutPassword";
import CheckoutSuccess from "@/views/store/CheckoutSuccess";
import Products from "@/views/store/Products";

import Home from "@/views/home/Home";
import Goal from "@/views/home/Goal";
import TodayExercise from "@/views/home/Exercise";
import TodayNutrition from "@/views/home/Nutrition";
import TodayMindset from "@/views/home/Mindset";
import Tracker from "@/views/tracker/Tracker";
import ActivityHistory from "@/views/tracker/ActivityHistory";
import Habits from "@/views/tracker/Habits";
import PastHabits from "@/views/tracker/PastHabits";

import Search from "@/views/search/Search";

import Events from "@/views/live/Events";
import Event from "@/views/live/Event";
import EventRegister from "@/views/event/Register";

import Support from "@/views/support/Support";
import Help from "@/views/support/Help";

import Survey from "@/views/survey/Survey";

import BadgeList from "@/views/badges/BadgeList";
import UserBadgeList from "@/views/badges/UserBadgeList";
import Badge from "@/views/badges/Badge";

import GoalsCheckin from "@/views/checkin/Goals";
import InterestsCheckin from "@/views/checkin/Interests";
import InstructorsCheckin from "@/views/checkin/Instructors";
import PersonalCheckin from "@/views/checkin/Personal";
import CoachesCheckin from "@/views/checkin/Coaches";
import SupportCheckin from "@/views/checkin/Support";
import RewardsCheckin from "@/views/checkin/Rewards";
import ThankYouCheckin from "@/views/checkin/ThankYou";
import DoneCheckin from "@/views/checkin/Done";

import CheckInForm from "@/views/checkin/Form";
import CheckinSummary from "@/views/checkin/CheckinSummary";
import CheckinList from "@/views/checkin/CheckinList";
import CoachingGoals from "@/views/checkin/CoachingGoals";

import WorkoutBlock from "@/views/workoutblocks/WorkoutBlock";
import WorkoutBlockOverview from "@/views/workoutblocks/WorkoutBlockOverview";
import WorkoutBlockPublic from "@/views/workoutblocks/WorkoutBlockPublic";

import Activity from "@/views/activity/Activity";
import UserStats from "@/views/activity/UserStats";

import NutritionBlock from "@/views/nutritionblocks/NutritionBlock";
import NutritionOverview from "@/views/nutritionblocks/NutritionOverview";

import BookmarksUpcomingPage from "@/components/bookmarks/Upcoming";
import BookmarksPage from "@/components/bookmarks/History";
import BaseBookmarks from "@/views/bookmarks/Bookmarks";
import UserBookmarks from "@/views/bookmarks/UserBookmarks";

import GroupList from "@/views/groups/GroupList";
import Group from "@/views/groups/Group";

import Feed from "@/views/feed/Feed";
import Post from "@/views/feed/Post";
import YourPosts from "@/views/feed/YourPosts";
import CommunityFitness from "@/views/feed/community-fitness/CommunityFitness";
import MeetingInfo from "@/views/feed/community-fitness/MeetingInfo";
import UpsertCommunityFitness from "@/views/feed/community-fitness/UpsertCommunityFitness";
import CommunityStats from "@/views/feed/community-stats/CommunityStats";
import CommunityStatsReps from "@/views/feed/community-stats/CommunityStatsReps";
import FriendRequest from "@/views/feed/FriendRequest";

import YourPrograms from "@/views/programs/YourPrograms";
import UserProgram from "@/views/programs/UserProgram";
import Journey from "@/views/programs/Journey";
import OnDemand from "@/views/ondemand/OnDemand";
import GuidedRun from "@/views/ondemand/GuidedRun";
import Article from "@/views/articles/Article";

import Start from "@/views/start/Start";
import ChosenStart from "@/views/start/Chosen";
import DetailsStart from "@/views/start/Details";
import LevelStart from "@/views/start/Level";
import PlanStart from "@/views/start/Plan";
import PricingStart from "@/views/start/Pricing";
import Purchase from "@/views/start/Purchase";
import ObstacleStart from "@/views/start/Obstacle";
import EducationStart from "@/views/start/Education";
import AnythingElseStart from "@/views/start/AnythingElse";
import RegisterStart from "@/views/start/Register";
import Check from "@/views/start/Check";
import RecommendationStart from "@/views/start/Recommendation";
import ChallengesStart from "@/views/start/Challenges";
import CoachesStart from "@/views/start/Coaches";

import PageNotFound from "@/views/404";
import LayoutsPage from "@/views/Layouts";
import ProgramList from "@/views/programs/ProgramList";
import ChooseNutritionProgram from "@/views/programs/ChooseNutritionProgram";
import Calendar from "@/views/programs/Calendar";
import FeaturedGroups from "@/views/groups/FeaturedGroups";
import Logout from "@/views/account/Logout";
import ShoppingList from "@/views/nutritionblocks/ShoppingList";
import Notifications from "@/views/notifications/Notifications";
import Install from "@/views/pwa/Install";
import Offline from "@/views/pwa/Offline";

import ChatList from "@/views/chat/ChatList";
import ChatView from "@/views/chat/ChatView";
import ChatMemberView from "@/views/chat/ChatMemberView";
import ChatJoin from "@/views/chat/ChatJoin";

import TodoView from "@/views/todo/TodoView";
import TodoOnboardingView from "@/views/todo/TodoOnboardingView";
import CreateCelebration from "@/views/celebration/CreateCelebration";
import CelebrationList from "@/views/celebration/CelebrationList";
import Consistency from "@/views/activity/Consistency";
import Contact from "@/views/support/Contact";
import ContactUs from "@/views/support/ContactUs";
import Gate from "@/views/support/Gate";
import TokenizeCardResult from "@/views/account/TokenizeCardResult";
import LeaderboardView from "@/views/leaderboards/LeaderboardView";
import GymLeaderboardView from "@/views/leaderboards/GymLeaderboardView";
import GlobalLeaderboardView from "@/views/leaderboards/GlobalLeaderboardView";
import LeaderboardList from "@/views/leaderboards/LeaderboardList";
import FriendsLeaderboardView from "@/views/leaderboards/FriendsLeaderboardView";
import Classes from "@/views/live/Classes";
import TokenLogin from "@/views/account/TokenLogin.vue";
import CreateCoachingChat from "@/views/chat/CreateCoachingChat.vue";
import CreateDeal from "@/views/deals/CreateDeal";
import InfoPackDownload from "@/views/deals/InfoPackDownload";

import LeadCapture from "@/views/support/LeadCapture";
import LeadSuccess from "@/views/support/LeadSuccess";

import Affiliate from "@/views/support/Affiliate";
import AffiliateSuccess from "@/views/support/AffiliateSuccess";

import PartnerRegister from "@/views/account/PartnerRegister";

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(Router);

const routes = [
  {
    path: "/layout-examples",
    name: "Layout",
    component: LayoutsPage,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  // for you
  {
    path: "/today",
    name: "Home",
    component: Home,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/today/goal",
    name: "Goal",
    component: Goal,
    meta: {
      requiresAuth: true,
      active: true,
    },
  },
  {
    path: "/today/exercise",
    name: "TodayExercise",
    component: TodayExercise,
    meta: {
      requiresAuth: true,
      active: true,
    },
  },
  {
    path: "/today/nutrition",
    name: "TodayNutrition",
    component: TodayNutrition,
    meta: {
      requiresAuth: true,
      active: true,
    },
  },
  {
    path: "/today/mindset",
    name: "TodayMindset",
    component: TodayMindset,
    meta: {
      requiresAuth: true,
      active: true,
    },
  },
  {
    path: "/today/medical-assessment/",
    name: "MedicalAssessment",
    component: MedicalAssessment,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/today/medical-assessment/thank-you",
    name: "MedicalAssessmentThankYou",
    component: MedicalAssessmentThankYou,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/today/to-do/",
    name: "Assistant",
    component: Assistant,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/today/to-do/checkin",
    name: "Checkin",
    component: AssistantCheckin,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/today/to-do/join-facebook",
    name: "JoinFacebook",
    component: JoinFacebook,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/today/to-do/join-whatsapp",
    name: "JoinWhatsapp",
    component: JoinWhatsapp,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/today/to-do/tips",
    name: "Tips",
    component: Tips,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/today/inbox",
    name: "Inbox",
    component: Inbox,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/today/inbox/message/:messageId",
    name: "Message",
    component: Message,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      guest: true,
    },
  },
  // events
  {
    path: "/live",
    name: "Live",
    component: Events,
    props: true,
    meta: {
      guest: false,
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  {
    path: "/classes",
    name: "Classes",
    component: Classes,
    props: true,
    meta: {
      guest: false,
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  {
    path: "/event/:id",
    name: "EventView",
    component: Event,
    props: true,
    meta: {
      guest: false,
      requiresAuth: true,
    },
  },
  {
    path: "/event/register/:id",
    name: "EventRegister",
    component: EventRegister,
    props: true,
    meta: {
      guest: false,
      requiresAuth: true,
      requiresSubscription: true,
    },
  },

  {
    path: "/on-demand/audio/:guidedRunId",
    name: "GuidedRun",
    component: GuidedRun,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  // activity
  {
    path: "/activity-stats/:userId",
    name: "UserStats",
    component: UserStats,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },

  // account
  {
    path: "/join/:linkingCode?",
    name: "Register",
    //redirect: '/start',
    component: Register,
    meta: {
      guest: true,
    },
  },
  {
    path: "/account",
    name: "Account",
    component: Account,
    props: true,
    meta: {
      guest: false,
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  {
    path: "/account/general",
    name: "General",
    component: AccountGeneral,
    props: true,
    meta: {
      guest: false,
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  {
    path: "/account/discovery",
    name: "Discovery",
    component: Discovery,
    props: true,
    meta: {
      guest: false,
      requiresAuth: false,
      requiresSubscription: false,
    },
  },
  {
    path: "/account/billing",
    name: "Billing",
    component: AccountBilling,
    props: true,
    meta: {
      guest: false,
      requiresAuth: true,
      requiresSubscription: false,
    },
  },
  {
    path: "/account/login/:token",
    name: "TokenLogin",
    component: TokenLogin,
    props: true,
    meta: {
      guest: false,
      requiresAuth: false,
      requiresSubscription: false,
    },
  },
  {
    path: "/tokenize-card/result",
    name: "TokenizeCardResult",
    component: TokenizeCardResult,
    props: true,
    meta: {
      guest: false,
      requiresAuth: true,
      requiresSubscription: false,
    },
  },
  {
    path: "/account/preferences",
    name: "Preferences",
    component: Preferences,
    props: true,
    meta: {
      guest: false,
      requiresAuth: false,
      requiresSubscription: false,
    },
  },
  {
    path: "/account/reset-password/:hash/:userId",
    name: "ResetPassword",
    component: ResetPassword,
    props: true,
    meta: {
      guest: false,
      requiresAuth: false,
      requiresSubscription: false,
    },
  },
  {
    path: "/account/forgot-password",
    name: "RequestNewPassword",
    component: RequestNewPassword,
    props: true,
  },
  {
    path: "/account/link-corporate-challenge/:linkingCode",
    name: "LinkCorporateChallenge",
    component: LinkCorporateChallenge,
    props: true,
    meta: {
      guest: false,
      requiresAuth: false,
      requiresSubscription: false,
    },
  },
  {
    path: "/account/logout",
    name: "Logout",
    component: Logout,
    props: true,
    meta: {
      guest: false,
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  //profile
  {
    path: "/account",
    name: "AccountProfile",
    component: Profile,
    props: true,
    meta: {
      guest: false,
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  {
    path: "/p/:handle",
    name: "Profile",
    component: Profile,
    props: true,
    meta: {
      guest: false,
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  {
    path: "/shop",
    name: "ProductsPage",
    component: Products,
    meta: {
      requiresAuth: false,
      requiresSubscription: false,
    },
  },
  // checkout
  {
    path: "/checkout/login",
    name: "LoginCheckout",
    component: LoginCheckout,
    meta: {
      guest: true,
    },
  },
  {
    path: "/checkout/join",
    name: "RegisterCheckout",
    component: CreateUserCheckout,
    meta: {
      guest: true,
    },
  },
  {
    path: "/checkout/password/:code",
    name: "ColdCheckoutPassword",
    component: ColdCheckoutPassword,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/success/:paymentId",
    name: "CheckoutSuccess",
    component: CheckoutSuccess,
    props: true,
    meta: {
      requiresAuth: true,
      active: true,
    },
  },

  {
    path: "/checkout/:code",
    name: "CheckoutPage",
    component: CheckoutPage,
    props: true,
    meta: {
      requiresAuth: true,
      active: true,
    },
  },
  {
    path: "/checkout/payment/result",
    name: "PaymentResultPage",
    component: PaymentResultPage,
    props: true,
    meta: {
      requiresAuth: true,
      active: true,
    },
  },
  // {
  //   path: '/checkout/link-group-subscription/:code',
  //   name: 'LinkGroupSubscription',
  //   component: LinkGroupSubscriptionPage,
  //   props: true,
  //   meta: {
  //     requiresAuth: true,
  //     requiresSubscription: true,
  //     active: true
  //   },
  // },
  // survey
  {
    path: "/survey/:subscriptionSurveyId",
    name: "Survey",
    component: Survey,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  // search
  {
    path: "/search",
    name: "Search",
    component: Search,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  // badges
  {
    path: "/badges/:userId",
    name: "UserBadges",
    component: UserBadgeList,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/badges",
    name: "Badges",
    component: BadgeList,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/badges/:id",
    name: "Badge",
    component: Badge,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  // rewards
  {
    path: "/rewards/points",
    name: "Points",
    component: PointsPage,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: false,
      active: true,
    },
  },
  {
    path: "/rewards/points/earn",
    name: "EarnPoints",
    component: EarnPointsPage,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: false,
      active: true,
    },
  },
  {
    path: "/rewards/points/use",
    name: "UsePoints",
    component: UsePointsPage,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: false,
      active: true,
    },
  },
  //actual checkins
  {
    path: "/checkins/new/:type?",
    name: "NewCheckin",
    component: CheckInForm,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/checkins/edit/:id",
    name: "EditCheckin",
    component: CheckInForm,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/checkins",
    name: "CheckinSummary",
    component: CheckinSummary,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/checkins/list",
    name: "CheckinList",
    component: CheckinList,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  // onboarding checkin
  {
    path: "/checkin/coaching/goals",
    name: "CoachingGoals",
    component: CoachingGoals,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/checkin/goals",
    name: "GoalsCheckin",
    component: GoalsCheckin,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/checkin/interests",
    name: "InterestsCheckin",
    component: InterestsCheckin,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/checkin/instructors",
    name: "InstructorsCheckin",
    component: InstructorsCheckin,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/checkin/personal",
    name: "PersonalCheckin",
    component: PersonalCheckin,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/checkin/coaches",
    name: "CoachesCheckin",
    component: CoachesCheckin,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/checkin/support",
    name: "SupportCheckin",
    component: SupportCheckin,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/checkin/rewards",
    name: "RewardsCheckin",
    component: RewardsCheckin,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/checkin/thank-you",
    name: "ThankYouCheckin",
    component: ThankYouCheckin,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/checkin/done",
    name: "DoneCheckin",
    component: DoneCheckin,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  // workout blocks
  {
    path: "/program/:blockId",
    name: "WorkoutBlock",
    component: WorkoutBlock,
    props: true,
    meta: {
      // we are handling the redirect in the component.
      requiresAuth: false,
      requiresSubscription: false,
      active: true,
    },
  },
  {
    path: "/program/public/:blockId",
    name: "WorkoutBlockPublic",
    component: WorkoutBlockPublic,
    props: true,
    meta: {
      // we are handling the redirect in the component.
      requiresAuth: false,
      requiresSubscription: false,
      active: true,
    },
  },
  {
    path: "/program/:blockId/overview",
    name: "WorkoutBlockOverview",
    component: WorkoutBlockOverview,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/unit-activity/:unitActivityId",
    name: "Activity",
    component: Activity,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/nutrition/shopping-list/:nutritionBlockId",
    name: "ShoppingList",
    component: ShoppingList,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  //nutrition blocks
  {
    path: "/nutrition/:blockId",
    name: "NutritionBlock",
    component: NutritionBlock,
    props: true,
    meta: {
      // we are handling the redirect in the component.
      requiresAuth: false,
      requiresSubscription: false,
      active: true,
    },
  },
  {
    path: "/nutrition/:blockId/overview",
    name: "NutritionOverview",
    component: NutritionOverview,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  // recipes
  {
    path: "/recipes",
    name: "Recipes",
    component: RecipeList,
    props: false,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/recipes/:id",
    name: "Recipe",
    component: RecipePage,
    props: true,
    meta: {
      requiresAuth: false,
      active: true,
    },
  },
  {
    path: "/recipe/edit/:id",
    name: "EditRecipe",
    component: UpsertRecipePage,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/recipe/create",
    name: "CreateRecipe",
    component: UpsertRecipePage,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },

  // events
  {
    path: "/accountability",
    name: "Tracker",
    component: Tracker,
    props: true,
    meta: {
      guest: false,
      requiresAuth: false,
      requiresSubscription: false,
    },
  },
  {
    path: "/accountability/dots",
    name: "ActivityHistory",
    component: ActivityHistory,
    props: true,
    meta: {
      guest: false,
      requiresAuth: false,
      requiresSubscription: false,
    },
  },
  {
    path: "/accountability/habits",
    name: "Habits",
    component: Habits,
    props: true,
    meta: {
      guest: false,
      requiresAuth: false,
      requiresSubscription: false,
    },
  },
  {
    path: "/accountability/habits/past",
    name: "PastHabits",
    component: PastHabits,
    props: true,
    meta: {
      guest: false,
      requiresAuth: false,
      requiresSubscription: false,
    },
  },
  // Bookmarks
  {
    path: "/bookmarks/:userId",
    name: "UserBookmarks",
    component: UserBookmarks,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
    beforeEnter: (to, from, next) => {
      guard(to, from, next);
    },
  },
  {
    path: "/bookmarks",
    name: "BaseBookmarks",
    component: BaseBookmarks,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
    children: [
      {
        path: "upcoming",
        component: BookmarksUpcomingPage,
        props: true,
        name: "UpcomingBookmarks",
        meta: {
          requiresAuth: true,
          requiresSubscription: true,
        },
      },
      {
        path: "all",
        name: "BookmarksPage",
        component: BookmarksPage,
        props: true,
        meta: {
          requiresAuth: true,
          requiresSubscription: true,
        },
      },
    ],
  },
  // Groups
  {
    path: "/manage-groups",
    name: "GroupList",
    component: GroupList,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  {
    path: "/group/:groupId",
    name: "Group",
    component: Group,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/groups",
    name: "FeaturedGroups",
    component: FeaturedGroups,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  {
    path: "/leaderboards",
    name: "Leaderboards",
    component: LeaderboardList,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  {
    path: "/leaderboard/gym",
    name: "GymLeaderboard",
    component: GymLeaderboardView,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  {
    path: "/leaderboard/global",
    name: "GlobalLeaderboard",
    component: GlobalLeaderboardView,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  {
    path: "/leaderboard/friends",
    name: "FriendsLeaderboard",
    component: FriendsLeaderboardView,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  {
    path: "/leaderboard/:id",
    name: "Leaderboard",
    component: LeaderboardView,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  {
    path: "/friend-requests",
    name: "FriendRequest",
    component: FriendRequest,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  // Feed
  {
    path: "/social",
    name: "Feed",
    component: Feed,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  {
    path: "/social/post/:postId",
    name: "Post",
    component: Post,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  {
    path: "/social/your-posts",
    name: "YourPosts",
    component: YourPosts,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  {
    path: "/social/community-fitness/",
    name: "CommunityFitness",
    component: CommunityFitness,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  {
    path: "/social/community-fitness/create",
    name: "CreateCommunityFitness",
    component: UpsertCommunityFitness,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  {
    path: "/social/community-fitness/edit/:id",
    name: "UpdateCommunityFitness",
    component: UpsertCommunityFitness,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  {
    path: "/social/community-fitness/:id",
    name: "CommunityFitnessEvent",
    component: MeetingInfo,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  {
    path: "/social/community-stats",
    name: "CommunityStats",
    component: CommunityStats,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  {
    path: "/social/community-stats/reps",
    name: "CommunityStatsReps",
    component: CommunityStatsReps,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  //support
  {
    path: "/support",
    name: "Support",
    component: Support,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/help",
    name: "Help",
    component: Help,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/your-programs",
    name: "Your Programs",
    component: YourPrograms,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/programs/:userId",
    name: "User Programs",
    component: UserProgram,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/journey",
    name: "Journey",
    component: Journey,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: Calendar,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/programs",
    name: "ProgramList",
    component: ProgramList,
    meta: {
      requiresAuth: true,
      requiresSubscription: false,
      active: true,
    },
  },
  {
    path: "/nutrition-programs",
    name: "Choose your program",
    component: ChooseNutritionProgram,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  // Article
  {
    path: "/article/:slug",
    name: "Article",
    component: Article,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  // On Demand
  {
    path: "/on-demand",
    name: "OnDemand",
    component: OnDemand,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
    children: [
      {
        path: "video",
        component: OnDemand,
      },
      {
        path: "audio",
        component: OnDemand,
      },
    ],
  },
  // Funnel
  {
    path: "/start/get-going/:dealOwner?",
    name: "Start",
    component: Start,
    props: true,
    meta: {
      guest: true,
      requiresAuth: false,
      requiresSubscription: false,
    },
  },
  {
    path: "/start/chosen",
    name: "ChosenStart",
    component: ChosenStart,
    props: true,
    meta: {
      guest: true,
      requiresAuth: false,
      requiresSubscription: false,
    },
  },
  {
    path: "/start/details",
    name: "DetailsStart",
    component: DetailsStart,
    props: true,
    meta: {
      guest: true,
      requiresAuth: false,
      requiresSubscription: false,
    },
  },
  {
    path: "/start/level",
    name: "LevelStart",
    component: LevelStart,
    props: true,
    meta: {
      guest: true,
      requiresAuth: false,
      requiresSubscription: false,
    },
  },
  {
    path: "/start/plan",
    name: "PlanStart",
    component: PlanStart,
    props: true,
    meta: {
      guest: true,
      requiresAuth: false,
      requiresSubscription: false,
    },
  },
  {
    path: "/start/pricing",
    name: "PricingStart",
    component: PricingStart,
    props: true,
    meta: {
      guest: true,
      requiresAuth: false,
      requiresSubscription: false,
    },
  },
  {
    path: "/start/purchase",
    name: "Purchase",
    component: Purchase,
    props: true,
    meta: {
      guest: false,
      requiresAuth: true,
      requiresSubscription: false,
    },
  },
  {
    path: "/start/obstacle",
    name: "ObstacleStart",
    component: ObstacleStart,
    props: true,
    meta: {
      guest: true,
      requiresAuth: false,
      requiresSubscription: false,
    },
  },
  {
    path: "/start/education",
    name: "EducationStart",
    component: EducationStart,
    props: true,
    meta: {
      guest: true,
      requiresAuth: false,
      requiresSubscription: false,
    },
  },
  {
    path: "/start/anything-else",
    name: "AnythingElseStart",
    component: AnythingElseStart,
    props: true,
    meta: {
      guest: true,
      requiresAuth: false,
      requiresSubscription: false,
    },
  },
  {
    path: "/start/register",
    name: "RegisterStart",
    component: RegisterStart,
    props: true,
    meta: {
      guest: true,
      requiresAuth: false,
      requiresSubscription: false,
    },
  },
  {
    path: "/start/check",
    name: "Check",
    component: Check,
    props: true,
    meta: {
      guest: true,
      requiresAuth: false,
      requiresSubscription: false,
    },
  },
  {
    path: "/partner/:productCode",
    name: "PartnerRegister",
    component: PartnerRegister,
    props: true,
    meta: {
      guest: true,
      requiresAuth: false,
      requiresSubscription: false,
    },
  },
  {
    path: "/start/recommendation",
    name: "RecommendationStart",
    component: RecommendationStart,
    props: true,
    meta: {
      guest: false,
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  {
    path: "/start/challenges",
    name: "ChallengesStart",
    component: ChallengesStart,
    props: true,
    meta: {
      guest: false,
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  {
    path: "/start/coaches",
    name: "CoachesStart",
    component: CoachesStart,
    props: true,
    meta: {
      guest: false,
      requiresAuth: false,
      requiresSubscription: false,
    },
  },
  // Notifications
  {
    path: "/notifications",
    name: "Notifications",
    component: Notifications,
    props: false,
    meta: {
      guest: false,
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  {
    path: "/install",
    name: "Install",
    component: Install,
    props: false,
    meta: {
      guest: false,
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  {
    path: "/pwa/offline",
    name: "Offline",
    component: Offline,
    props: false,
    meta: {
      guest: false,
      requiresAuth: true,
      requiresSubscription: true,
    },
  },
  // chats
  // Accountability
  {
    path: "/chats",
    name: "ChatList",
    component: ChatList,
    props: false,
    meta: {
      guest: false,
      requiresAuth: true,
      requiresSubscription: false,
    },
  },
  {
    path: "/chats/:chatId",
    name: "ChatView",
    component: ChatView,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: false,
      active: true,
    },
  },
  {
    path: "/chats/create-coach-chat/:handle",
    name: "CreateCoachingChat",
    component: CreateCoachingChat,
    props: true,
    meta: {
      guest: false,
      requiresAuth: true,
      requiresSubscription: false,
    },
  },
  {
    path: "/chats/members/:chatId",
    name: "ChatMemberView",
    component: ChatMemberView,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: false,
      active: true,
    },
  },
  {
    path: "/chats/join/:chatId",
    name: "ChatJoin",
    component: ChatJoin,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: false,
      active: true,
    },
  },
  // todos
  {
    path: "/to-do/:todoId",
    name: "TodoView",
    component: TodoView,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/today/to-do/onboarding",
    name: "TodoOnboardingView",
    component: TodoOnboardingView,
    props: false,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/celebration",
    name: "CreateCelebration",
    component: CreateCelebration,
    props: false,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/celebrations",
    name: "CelebrationList",
    component: CelebrationList,
    props: false,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/consistency",
    name: "Consistency",
    component: Consistency,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: true,
      active: true,
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: false,
      active: true,
    },
  },
  {
    path: "/team",
    name: "Team",
    component: Team,
    meta: {
      requiresAuth: false,
      requiresSubscription: false,
      active: true,
    },
  },
  {
    path: "/team/thank-you",
    name: "TeamSuccess",
    component: TeamSuccess,
    meta: {
      requiresAuth: false,
      requiresSubscription: false,
      active: true,
    },
  },
  {
    path: "/campaign/:leadCampaignId",
    name: "LeadCapture",
    props: true,
    component: LeadCapture,
    meta: {
      requiresAuth: false,
      requiresSubscription: false,
      active: true,
    },
  },
  {
    path: "/campaign-success/:email",
    name: "LeadSuccess",
    props: true,
    component: LeadSuccess,
    meta: {
      requiresAuth: false,
      requiresSubscription: false,
      active: true,
    },
  },
  {
    path: "/affiliate",
    name: "Affiliate",
    props: false,
    component: Affiliate,
    meta: {
      requiresAuth: false,
      requiresSubscription: false,
      active: true,
    },
  },
  {
    path: "/affiliate-success/:email",
    name: "AffiliateSuccess",
    props: true,
    component: AffiliateSuccess,
    meta: {
      requiresAuth: false,
      requiresSubscription: false,
      active: true,
    },
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: ContactUs,
    props: true,
    meta: {
      // we are handling the redirect in the component.
      requiresAuth: false,
      requiresSubscription: false,
      active: true,
    },
  },
  {
    path: "/gate",
    name: "Gate",
    component: Gate,
    props: true,
    meta: {
      requiresAuth: true,
      requiresSubscription: false,
      active: true,
    },
  },

  // redirects
  {
    path: "/start",
    redirect: "/start/get-going",
  },
  {
    path: "/today/my-day",
    redirect: "/today",
  },
  {
    path: "/checkin/assessments",
    redirect: "/checkins",
  },
  {
    path: "/checkin/assessment/edit/:id",
    redirect: "/checkins/edit/:id",
  },
  {
    path: "/checkin/assessments/new",
    redirect: "/checkins/new",
  },
  {
    path: "/checkin/assessment/new",
    redirect: "/checkins/new",
  },
  {
    path: "/create-deal",
    name: "CreateDeal",
    component: CreateDeal,
    props: true,
    meta: {
      requiresAuth: false,
      requiresSubscription: false,
      active: true,
    },
  },
  {
    path: "/info-pack-download",
    name: "InfoPackDownload",
    component: InfoPackDownload,
    props: true,
    meta: {
      requiresAuth: false,
      requiresSubscription: false,
      active: true,
    },
  },
  {
    path: "*",
    name: "PageNotFound",
    component: PageNotFound,
  },
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const guard = function (to, from, next) {
  let userId = decodeId(to.params.userId);

  if (store.getters.isLoggedIn) {
    apolloProvider.defaultClient
      .query({
        query: AUTHORISATION_QUERY,
        variables: { userId: userId },
      })
      .then((response) => {
        if (response.data.canView) {
          next();
        } else {
          next("/not-authorised");
          return;
        }
      });
  } else {
    next();
  }
};

// need to move these functions out
router.beforeEach((to, from, next) => {
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);

  if (to.path === "/") {
    next("/today");
    return;
  }

  // if we pass the voucher code .vc via param on the register page, we save it!
  if (to.name == "RegisterStart" || to.name == "Check") {
    const voucherCode = to.query.vc;
    if (voucherCode) {
      store.dispatch("updateCheckoutVoucher", { code: voucherCode });
    }
  }

  // automatic token login
  if (to.name == "TokenLogin") {
    // set appSource if coming from native.
    // e.g `native`.
    const appSource = to.query.app_source;
    sessionStorage.setItem("appSource", appSource);
  }

  const UTMcookieExpiry = new Date(new Date().getTime() + 30 * 60 * 1000);
  ["utm_campaign", "utm_medium", "utm_source"].forEach((element) => {
    if (element in to.query) Cookies.set(element, to.query[element], { expires: UTMcookieExpiry });
  });

  // maintain utm params for tracking
  if (
    [
      "ChooseStart",
      "SupportStart",
      "IdentifyStart",
      "CheckoutPage",
      "ExploreStart",
      "Register",
      "GoalsCheckin",
      "Start",
      "RegisterCheckout",
    ].includes(to.name)
  ) {
    if (to.query.referral_code) {
      sessionStorage.setItem("referral_code", to.query.referral_code);
    }

    if (
      Object.keys(from.query).filter((key) => ["utm_campaign", "utm_medium", "utm_source"].includes(key)).length > 0 &&
      Object.keys(to.query).filter((key) => ["utm_campaign", "utm_medium", "utm_source"].includes(key)).length == 0
    ) {
      next({ name: to.name, query: from.query, params: to.params });
    } else {
      next();
    }
  }

  if (to.matched.some((record) => record.meta.requiresSubscription)) {
    // allow users access to some of the content for a week if they sign up or if they have an active subscription
    if (
      store.getters.isLoggedIn &&
      store.getters.subscription == null
      // no! we are no longer giving any access
      // && moment(store.getters.user.dateJoined) < moment().subtract(10, "minutes")
    ) {
      next(`/start/pricing`);

      return;
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (
      !store.getters.isLoggedIn &&
      ["EventRegister", "Article", "Recipes", "Recipe", "EventView", "CommunityFitness", "Live"].includes(to.name)
    ) {
      sessionStorage.setItem("contentRedirectPath", to.path);
      // Join component will check redirectPath for /article/ and then display the teaser
      next(`/start/get-going`);

      return;
    }

    if (!store.getters.isLoggedIn && to.name == "Contact") {
      // Join component will check redirectPath for /article/ and then display the teaser
      next(`/register`);

      return;
    }

    // Handle redirecting to /login if not logged in
    if (store.getters.isLoggedIn) {
      if (store.getters.isDiscoveryMember && !store.getters.providedIdentification) {
        let blockedRoutes = ["/today", "/search", "/live", "/social", "/today/inbox", "/today/to-do"];
        if (blockedRoutes.includes(to.path.trim())) {
          next("/account/discovery");
          return;
        }
      }
      next();
    } else if (to.name == "CheckoutPage") {
      // jeff life no longer exists. Redirect to club.
      const fullPath = to.fullPath.replace("jeff-life", "jeff-club");
      sessionStorage.setItem("redirectPath", fullPath);

      const path = to.path.split("/");
      let code = path[path.length - 1];
      // jeff life no longer exists. Redirect to club.
      code = code === "jeff-life" ? "jeff-club" : code;

      let queryParams = {};
      if (code) {
        queryParams["code"] = code;
      }
      const pvi = to.query.pvi;
      if (pvi) {
        queryParams["pvi"] = pvi;
      }

      const voucherCode = to.query.vc;

      if (voucherCode) {
        store.dispatch("updateCheckoutVoucher", { code: voucherCode });
      }

      next({
        name: "RegisterCheckout",
        query: {
          ...queryParams,
          ...to.query,
        },
      });
    } else {
      // append the current path + query string
      sessionStorage.setItem("redirectPath", window.location.pathname + window.location.search);
      next({
        path: "/login",
        query: { case: 1 },
      });
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isLoggedIn) {
      var path = sessionStorage.getItem("redirectPath") || "";
      if (path != "") {
        sessionStorage.removeItem("redirectPath");
        next(path);
      } else {
        if (to.path === "/start/get-going") {
          const blockId = to.query.b;
          if (blockId) {
            next(`/program/${blockId}`);
          } else {
            // do we have a code in the url?
            const productCode = to.query.code;
            if (productCode) {
              // brittle. Duplicated in CreateUserCheckout.vue
              if (!sessionStorage.getItem("selectedCoach") && productCode.includes("1-on-1")) {
                next(`/start/coaches?redirect=/checkout/${productCode}`);
              } else {
                next(`/checkout/${productCode}`);
              }
            } else {
              next("/programs");
            }
          }
          // some routes should not go to /today. We allow these through.
        } else if (to.path === "/start/pricing" || to.path.includes("/partner/") || to.path.includes("/start/check")) {
          next();
        } else {
          if (to.params.linkingCode) {
            next(`/account/link-corporate-challenge/${to.params.linkingCode}?loggedin=1`);
          } else {
            next("/today");
          }
        }
      }
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
