import { USER_TAGS_QUERY } from "@/graphql/queries/tag";

export default {
  name: "JourneyDetailsMixin",
  filters: {
    formatAmount(val = 0) {
      return parseFloat(val.toFixed(1));
    },
  },
  computed: {
    user() {
      return this.$store.getters.user;
    },
    fitnessLevel() {
      return this.user?.userProfile?.fitnessLevel;
    },
    goal() {
      return this.user?.userProfile?.desiredWeightLoss > 0 ? "lose" : "gain";
    },
    weight() {
      return this.user?.userProfile?.weight;
    },
    goalWeight() {
      return this.user?.userProfile?.desiredWeight;
    },
    amount() {
      let val = this.user?.userProfile?.desiredWeightLoss;
      if (!isNaN(val)) return Math.abs(val);
      else return "";
    },
    primaryGoal() {
      if (this.primaryGoals?.length > 0) {
        let goal = this.primaryGoals[0].replace("-", " ");
        return `${goal.slice(0, 1).toUpperCase()}${goal.slice(1).toLowerCase()}`;
      }
      return "Lose Weight";
    },
  },
  methods: {
    validateUserProfile() {
      if (
        this.primaryGoals?.length == 0 ||
        !this.primaryGoals ||
        !this.fitnessLevel ||
        !this.weight ||
        !this.goalWeight
      ) {
        return false;
      }
      return true;
    },
  },
  apollo: {
    primaryGoals: {
      query: USER_TAGS_QUERY,
      variables() {
        return {
          category: "PRIMARY",
        };
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        return data.userTags.map((userTag) => userTag.tag.code);
      },
    },
  },
};
