<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="5" align="left" class="white px-8 pb-12 pt-6">
        <v-skeleton-loader v-if="$apollo.loading" type="card, button" />
        <div v-else>
          <div v-if="skip">
            <j-alert type="success">
              You have successfully captured your data 💥
            </j-alert>
            <book-consult />
          </div>

          <div v-if="recommendedWorkoutJourney">
            <div class="text-end">
              <j-btn tertiary small narrow class="mb-4" @click="showDialog = true">Update My Goal</j-btn>
            </div>
            <j-alert type="success" v-if="showUpdatedAlert">
              Your details have been updated, with your adjusted recommended journey below.
            </j-alert>
            <recommendation-journey @block-selected="blockId = $event" />

            <div class="text-center mt-8">
              <div>
                <j-btn primary large @click="start()">Choose program start date</j-btn>
              </div>
              <div v-if="skip">
                <j-btn tertiary small narrow class="mt-4" to="/checkins">Skip</j-btn>
              </div>
            </div>
          </div>
          <div v-else class="d-flex justify-center">
            <j-btn secondary to="/checkins">
              <v-icon class="mr-2">mdi-arrow-left</v-icon>
              <span>Back to Check-Ins</span>
            </j-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-dialog v-model="showDialog" max-width="600" transition="dialog-bottom-transition" class="limited-height">
      <journey-details-dialog
        show-journey-text
        @close="showDialog = false"
        @update="
          showDialog = false;
          showUpdatedAlert = true;
        "
      />
    </v-dialog>
  </v-container>
</template>

<script>
import RecommendationJourney from "@/components/shared/blocks/RecommendationJourney";
import JourneyDetailsDialog from "@/components/shared/dialogs/JourneyDetails";
import BookConsult from "@/components/coach/BookConsult";
import { RECOMMENDED_WORKOUT_JOURNEY_QUERY } from "@/graphql/queries/workoutblock";

export default {
  name: "Journey",
  components: { RecommendationJourney, JourneyDetailsDialog, BookConsult },
  data() {
    return {
      showDialog: false,
      showUpdatedAlert: false,
      blockId: null,
    };
  },
  computed: {
    skip() {
      return this.$route.params?.skip ?? false;
    },
  },
  methods: {
    start() {
      // set the block Id for the purposes of redirecting.
      sessionStorage.setItem("workoutBlockId", this.blockId);

      if (this.$store.getters.role == "ONE_ON_ONE" || this.$store.getters.role == "CLUB") {
        // if we are one on one or club i.e we have paid already, then we can go straight to the program and open the modal
        return this.$router.push(`/program/${this.blockId}?confirm=1`);
      }
      // if (this.requiredSupport) {
      //   return this.$router.push("/checkout/1-on-1");
      // }
      return this.$router.push("/checkout/jeff-club");
    },
  },
  apollo: {
    recommendedWorkoutJourney: {
      query: RECOMMENDED_WORKOUT_JOURNEY_QUERY,
      fetchPolicy: "cache-and-network",
    },
  },
};
</script>
