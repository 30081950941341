<template>
  <div class="d-flex mt-3">
    <div class="d-flex align-center">
      <v-avatar color="yellow-background" size="40">
        <h4 class="yellow-icon--text text--darken-2 font-weight-bold">{{ completed }}</h4>
      </v-avatar>
      <span class="grey-navy--text ml-2">Completed</span>
    </div>
    <div class="d-flex align-center ml-5">
      <v-avatar color="grey-background" size="40">
        <h4 class="grey-icon--text text--darken-1 font-weight-bold">{{ missed }}</h4>
      </v-avatar>
      <span class="grey-navy--text ml-2">Missed</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "HabitDots",
  props: {
    completed: {
      type: Number,
      default: 0,
    },
    missed: {
      type: Number,
      default: 0,
    },
  },
};
</script>
