import { TRACK_VIDEO_VIEW_MUTATION } from "../graphql/mutations/tracker"

export class DiscoveryVideoTracking {

  constructor(apollo, linkId, eventType = "event") {

    this.params = {
      tracking_interval: 30,
      linkId: linkId,
      eventType: eventType
    };

    this.interval = null;
    this.apollo = apollo;
  }

  startTracking() {

    if (this.interval)
      return;

    this.interval = setInterval(() => {

      let variables = {
        duration: this.params.tracking_interval,
      };
      variables[this.params.eventType] = this.params.linkId;

      this.apollo.mutate({
        mutation: TRACK_VIDEO_VIEW_MUTATION,
        variables: variables
      });

    }, this.params.tracking_interval * 1000);
  }

  stopTracking() {
    if (!this.interval)
      return;

    clearInterval(this.interval);
    this.interval = null;
  }
}