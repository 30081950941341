<template>
  <div>
    <j-banner-container header="Your Groups" :gradient="['green-background', 'yellow-background']">
      <v-card-text v-if="groups">
        <div class="d-flex">
          <v-spacer />
          <j-btn
            class="my-4"
            primary
            @click="
              selectedGroup = null;
              createEditGroupDialog = true;
            "
          >
            Create a Group
          </j-btn>
        </div>

        <v-row v-if="groups && groups.length > 0">
          <v-col cols="6" sm="3" v-for="group in groups" :key="group.node.id" class="pa-0">
            <j-block-card
              :id="group.node.id"
              :title="group.node.title"
              :badgeCount="group.node.postCount"
              :memberCount="group.node.memberCount"
              :image="group.node.banner || require('@/assets/images/group_banner_default.png')"
              @clicked="navigateGroup(group.node.id)"
              :showIcons="true"
              :editable="group.node.createdBy && group.node.createdBy.id == $store.getters.user.id"
              @clickEdit="opencreateEditGroupDialog(group.node)"
              @clickLeave="openLeaveGroupDialog(group.node)"
            />
          </v-col>
        </v-row>

        <div v-if="groups.length == 0">
          <j-alert>Groups you've joined will appear here.</j-alert>
        </div>
      </v-card-text>
    </j-banner-container>

    <!-- Create group dialog -->
    <v-dialog v-model="createEditGroupDialog" width="600" transition="dialog-bottom-transition" class="limited-height">
      <create-edit-group :group="selectedGroup" @saved="createEditGroup" @closed="createEditGroupDialog = false" />
    </v-dialog>

    <!-- leave group dialog -->
    <v-dialog
      v-if="groups"
      v-model="leaveGroupDialog"
      width="400"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <leave-group :group="selectedGroup" @saved="leaveGroup" @closed="leaveGroupDialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import { decodeId } from "@/lib/string";
import { GROUPS_QUERY } from "@/graphql/queries/group";
import CreateEditGroup from "@/components/groups/dialogs/CreateEditGroup";
import LeaveGroup from "@/components/groups/dialogs/LeaveGroup";
import GroupMixin from "@/mixins/GroupMixin";
import { UPSERT_GROUP_MUTATION, LEAVE_GROUP_MUTATION } from "@/graphql/mutations/group";

export default {
  name: "GroupList",
  mixins: [GroupMixin],
  components: {
    CreateEditGroup,
    LeaveGroup,
  },
  data() {
    return {
      groups: null,
      selectedGroup: null,
      createEditGroupDialog: false,
      leaveGroupDialog: false,
    };
  },

  methods: {
    navigateGroup(groupId) {
      this.$router.push(`/group/${decodeId(groupId)}`);
    },
    opencreateEditGroupDialog(group) {
      this.selectedGroup = group;
      this.createEditGroupDialog = true;
    },
    async createEditGroup(data) {
      this.createEditGroupDialog = false;
      let input = {
        title: data.groupName,
        permission: data.permission,
        users: data.selectedUsers,
      };
      if (data.id) {
        input.id = decodeId(data.id);
      }
      await this.$apollo.mutate({
        mutation: UPSERT_GROUP_MUTATION,
        variables: {
          data: input,
        },
      });
      await this.$apollo.queries.groups.refetch();
    },
    openLeaveGroupDialog(group) {
      this.selectedGroup = group;
      this.leaveGroupDialog = true;
    },
    async leaveGroup(groupId) {
      this.leaveGroupDialog = false;
      await this.$apollo.mutate({
        mutation: LEAVE_GROUP_MUTATION,
        variables: {
          groupId: decodeId(groupId),
        },
      });
      await this.$apollo.queries.groups.refetch();
    },
  },
  apollo: {
    groups: {
      query: GROUPS_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          mine: true,
          withUsers: false,
          typeIn: ["GENERAL", "ACCOUNTABILITY", "SUBURB", "GYM"],
        };
      },
      result(response) {
        if (response.data && !response.loading) {
          this.groups = response.data.groups.edges;
        }
      },
    },
  },
};
</script>
