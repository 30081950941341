<template>
  <validation-observer ref="obs" v-slot="{ valid }">
    <div class="d-flex flex-fill align-baseline">
      <div v-if="ordering" class="mr-4 ml-2 std-text">{{ ordering }}.</div>
      <v-icon v-else class="ml-1 mr-3">mdi-plus</v-icon>
      <div class="d-flex flex-fill text-left flex-column">
        <span v-if="valid">{{ ingredientName }}</span>

        <v-form class="mt-2 py-2 sub-form rounded-xl">
          <validation-provider name="Quantity" rules="required|min:1" v-slot="{ errors }">
            <j-text-field
              v-model="ingredient.quantity"
              dense
              label="Quantity"
              type="number"
              min="1"
              :hide-details="errors.length == 0"
              class="mb-2"
              :error-messages="errors"
            />
          </validation-provider>
          <j-autocomplete
            v-model="ingredient.measurementUnit"
            :items="measurementUnitItems"
            item-text="singular"
            :item-value="(item) => item"
            dense
            label="Measurement Unit"
            hide-details
            clearable
            class="mb-2"
          />
          <j-autocomplete
            v-model="ingredient.preparation"
            :items="preparationItems"
            item-text="title"
            :item-value="(item) => item"
            dense
            label="Preparation"
            hide-details
            clearable
            class="mb-2"
          />
          <validation-provider name="Ingredient" rules="required" v-slot="{ errors }">
            <j-autocomplete
              v-model="ingredient.ingredient"
              :items="ingredientItems"
              item-text="singular"
              :item-value="(ingredient) => ingredient"
              dense
              label="Ingredient"
              clearable
              :hide-details="errors.length == 0"
              class="mb-2"
              :error-messages="errors"
            />
          </validation-provider>
        </v-form>
      </div>
      <v-btn icon @click="$emit('cancel')" class="ml-auto">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-btn icon @click="closeForm" class="ml-auto">
        <v-icon>mdi-check</v-icon>
      </v-btn>
    </div>
  </validation-observer>
</template>

<script>
export default {
  name: "IngredientForm",
  props: {
    ordering: {
      type: Number,
      required: false,
    },
    ingredient: {
      type: Object,
      required: true,
    },
    ingredientName: {
      type: String,
      required: true,
    },
    ingredientItems: {
      type: Array,
      required: true,
    },
    preparationItems: {
      type: Array,
      required: true,
    },
    measurementUnitItems: {
      type: Array,
      required: true,
    },
    isEmptyIngredient: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    closeForm() {
      this.$refs.obs.validate().then((valid) => {
        if (valid) {
          this.$emit('save');
        }
      });        
    }
  }
};
</script>
