<template>
  <j-card v-if="whatsappGroups && whatsappGroups.length > 0" class="j-elevated-1">
    <div v-if="canComplete" class="d-flex flex-wrap justify-space-between pt-3 px-5">
      <j-btn secondary @click="$emit('complete')" class="mt-2">
        Tick
        <v-icon>mdi-check-circle-outline</v-icon>
      </j-btn>
      <j-btn tertiary @click="$emit('complete')" class="mt-2">
        Remove
        <v-icon>mdi-trash-can-outline</v-icon>
      </j-btn>
    </div>
    <v-card-title class="navy--text">
      WhatsApp groups for you
    </v-card-title>
    <v-card-text class="text-left">
      <p>Ensure you join all of these WhatsApp groups.</p>
      <p>
        These WhatsApp groups are essential for you get the latest updates and stay connected to what’s happening.
      </p>
      <div v-for="subscriptionBlock in whatsappGroups" :key="subscriptionBlock.id" class="mt-4">
        <strong>{{ subscriptionBlock.block.title }} with {{ subscriptionBlock.block.leadInstructor.firstName }}</strong>
        <div>
          <j-btn tertiary target="_blank" :href="subscriptionBlock.block.whatsappUrl" class="mt-1">
            {{ subscriptionBlock.block.type }}
            <v-icon color="whatsapp-green" class="ml-1">mdi-whatsapp</v-icon>
          </j-btn>
        </div>
      </div>
    </v-card-text>
  </j-card>
</template>

<script>
import { WHATSAPP_GROUPS } from "@/graphql/queries/assistant";

export default {
  name: "JoinWhatsappBlock",
  props: {
    canComplete: {
      type: Boolean,
      default: false
    }
  },
  apollo: {
    whatsappGroups: {
      query: WHATSAPP_GROUPS,
      update(data) {
        let blocks = [...data.home.subscriptionBlocks, ...data.home.upcomingSubscriptionBlocks];
        return blocks.filter(
          (block) => (block.block.type == "CHALLENGE" || block.block.type == "COURSE") && block.block.whatsappUrl
        );
      },
    },
  },
};
</script>
