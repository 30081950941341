<template>
  <j-banner-container header="Global Leaderboard" :gradient="['green-background', 'yellow-background']">
    <v-container v-if="initializing" class="pa-0 ma-0">
      <v-skeleton-loader v-for="item in 2" :key="item" type="list-item-avatar" class="mt-2" />
    </v-container>
    <v-container v-else class="pa-0 ma-0">
      <v-tabs v-model="activeTab" centered>
        <v-tab key="this_week"> This Week </v-tab>
        <v-tab key="last_week"> Last Week </v-tab>
      </v-tabs>
      <leaderboard-board
        :leaderboard-data="formatLeaderboardData()"
        :show-position="false"
        leaderboard-type="exercise"
      />
    </v-container>
  </j-banner-container>
</template>

<script>
import LeaderboardBoard from "@/components/leaderboard/LeaderboardBoard";
import LeaderboardMixin from "@/mixins/LeaderboardMixin";
import { GLOBAL_LEADERBOARD_QUERY } from "@/graphql/queries/group";

export default {
  name: "GlobalLeaderboardView",
  components: {
    LeaderboardBoard,
  },
  mixins: [LeaderboardMixin],
  methods: {
    formatLeaderboardData() {
      let data = this.globalLeaderboard;
      return [
        {
          title: `${this.$appName} Community`,
          dots: data.totalDots,
          isMine: false,
          image: require("@/assets/images/community.png"),
        },
        {
          title: "Your Contribution",
          dots: data.myDots,
          isMine: true,
          image: this.$store.getters.user?.userProfile?.profilePicture,
        },
      ];
    },
  },
  apollo: {
    globalLeaderboard: {
      query: GLOBAL_LEADERBOARD_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          startDate: this.queryDateRange().startDate,
          endDate: this.queryDateRange().endDate,
        };
      },
      result(response) {
        if (!response.loading) {
          this.loadingNewData = false;
          this.initializing = false;
        }
      },
    },
  },
};
</script>
