<template>
  <v-container>
    <v-snackbar v-model="hasError" :top="true" :timeout="3000">
      An error has occurred, please try again or contact us directly.
    </v-snackbar>
    <v-row align="center" justify="center">
      <v-col cols="12" md="5">
        <v-card class="pa-2 card-radius">
          <ValidationObserver ref="obs">
            <v-card-subtitle v-if="!sent" class="py-1 my-1">
              <h3 class="mt-2 secondary--text">We'll email your password instructions to you</h3>
              <p class="mt-2">You can set your password or reset your forgotten password</p>
            </v-card-subtitle>
            <v-card-text>
              <ValidationProvider name="Email" rules="required|email" v-slot="{ errors, valid }">
                <j-text-field
                  label="Your e-mail"
                  v-model.trim="email"
                  :error-messages="errors"
                  :success="valid"
                  required
                  @keydown.space.prevent
                ></j-text-field>
                <v-alert v-if="errors.length > 0 || hasError" type="warning" class="mt-2"
                  >Something isn't right. Please check your input and try again. Or
                  <a href="https://bit.ly/jeff_help" class="white--text">contact our support team.</a></v-alert
                >
              </ValidationProvider>
              <j-btn :loading="saving" width="100%" @click="save()"> Send Password Instructions </j-btn>

              <j-alert v-if="sent" type="success" class="mt-4">
                If a {{ $appName }} Web App account exists for {{ email }}, an email has been sent with your password
                reset instructions.
              </j-alert>
            </v-card-text>
          </ValidationObserver>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { SEND_PASSWORD_RESET_MUTATION } from "@/graphql/mutations/account";

export default {
  name: "RequestNewPassword",
  data: () => ({
    email: "",
    saving: false,
    sent: false,
    hasError: false,
    success: false,
  }),
  methods: {
    save() {
      this.$apollo
        .mutate({
          mutation: SEND_PASSWORD_RESET_MUTATION,
          variables: {
            email: this.email.toLowerCase(),
          },
        })
        .then((response) => {
          if (response.data.initiatePasswordReset.success) {
            this.sent = true;
          } else {
            this.hasError = true;
          }
        })
        .catch(() => {
          this.hasError = true;
        });
    },
  },
};
</script>
