import gql from "graphql-tag";

export const ACTIVITY_TYPES_QUERY = gql`
  query activityTypesQuery {
    activityTypes {
      id
      name
      category
    }
  }
`;

export const USER_ACTIVITY_QUERY = gql`
  query activityQuery($id: ID!) {
    activity(id: $id) {
      id
      image
      rating
      calories
      heartRate
      startDate
      seconds
      status
      distance
      averagePace
      effort
      isCustom
      activityType
      type {
        id
        name
        category
      }
      event {
        id
        name
        eventType {
          id
          category
        }
      }
      guidedRun {
        id
        name
      }
      workout {
        id
        name
      }
      comment
    }
  }
`;

export const USER_ACTIVITIES_QUERY = gql`
  query activitiesListQuery($startDate: Date, $endDate: Date, $status: String, $selectedDate: Date) {
    activities(startDate_Lte: $endDate, startDate_Gte: $startDate, status: $status, startDate: $selectedDate) {
      edges {
        node {
          id
          rating
          calories
          heartRate
          startDate
          seconds
          status
          distance
          averagePace
          effort
          isCustom
          activityType
          comment
          createdAt
          type {
            id
            name
            category
          }
          guidedRun {
            id
            name
            published
          }
          event {
            id
            name
            eventType {
              id
              name
              category
            }
            thumbnail
            url
            scheduledDate
            duration
            durationMinutes
            description
            createdAt
            instructor {
              id
              firstName
              lastName
              userProfile {
                id
                profilePicture
              }
            }
          }
          action {
            id
            workout {
              id
              name
              label
              workoutProgram {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const USER_ACTIVITIES_PAGINATED_QUERY = gql`
  query activitiesListQuery($after: String, $first: Int) {
    activities(after: $after, first: $first) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          rating
          calories
          heartRate
          startDate
          seconds
          status
          distance
          averagePace
          effort
          isCustom
          activityType
          comment
          subscriptionBlock {
            id
          }
          unitActivity {
            id
            type
            activity {
              id
              title
              activityType {
                id
                name
              }
            }
          }
          type {
            id
            name
            category
          }
          guidedRun {
            id
            name
            published
            category
          }
          event {
            id
            name
            eventType {
              id
              name
              category
            }
            thumbnail
            url
            scheduledDate
            duration
            durationMinutes
            description
            createdAt
            instructor {
              id
              firstName
              lastName
              userProfile {
                id
                profilePicture
              }
              
            }
          }
          action {
            workout {
              id
              name
              label
              workoutProgram {
                id
              }
            }
          }
          workout {
            id
            name
            label
            workoutProgram {
              id
            }
          }
        }
      }
    }
  }
`;

export const ACTIVITIES_AND_NUTRITION_QUERY = gql`
  query activitiesQuery($startDate: Date, $endDate: Date, $status: String, $userId: ID) {
    sleepLog(date_Lte: $endDate, date_Gte: $startDate) {
      edges {
        node {
          id
          date
          compliantDay
        }
      }
    }
    nutritionLog(date_Lte: $endDate, date_Gte: $startDate) {
      edges {
        node {
          id
          date
          comments
          isNutritiousDay
          totalQuestions
          truthyQuestions
          usernutritionloganswerSet {
            id
            answer
            nutritionQuestion {
              id
            }
          }
        }
      }
    }
    hydrationLog(date_Lte: $endDate, date_Gte: $startDate) {
      edges {
        node {
          id
          date
          compliantDay
        }
      }
    }
    mindsetLog(date_Lte: $endDate, date_Gte: $startDate) {
      edges {
        node {
          id
          date
          compliantDay
        }
      }
    }
    habitEntriesQuery(date_Lte: $endDate, date_Gte: $startDate) {
      edges {
        node {
          id
          date
          status
        }
      }
    }
    activities(startDate_Lte: $endDate, startDate_Gte: $startDate, status: $status) {
      edges {
        node {
          id
          rating
          calories
          heartRate
          startDate
          seconds
          status
          distance
          averagePace
          effort
          isCustom
          activityType
          subscriptionBlock {
            id
          }
          unitActivity {
            id
            type
          }
          action {
            id
          }
          type {
            id
            name
            category
          }
          guidedRun {
            id
            name
            published
            category
          }
          event {
            id
            name
            eventType {
              id
              name
              category
            }
            thumbnail
            url
            scheduledDate
            duration
            durationMinutes
            description
            createdAt
            instructor {
              id
              firstName
              lastName
              userProfile {
                id
                profilePicture
              }
            }
          }
          workout {
            id
            name
            label
            workoutProgram {
              id
            }
          }
        }
      }
    }
    recommendedProgram {
      monday {
        primaryActivity
        secondaryActivity
      }
      tuesday {
        primaryActivity
        secondaryActivity
      }
      wednesday {
        primaryActivity
        secondaryActivity
      }
      thursday {
        primaryActivity
        secondaryActivity
      }
      friday {
        primaryActivity
        secondaryActivity
      }
      saturday {
        primaryActivity
        secondaryActivity
      }
      sunday {
        primaryActivity
        secondaryActivity
      }
    }
    goals {
      id
    }
    restDays(restDayDate_Lte: $endDate, restDayDate_Gte: $startDate, userId: $userId) {
      edges {
        node {
          id
          restDayDate
          didRest
          unitActivity {
            id
          }
          subscriptionBlock {
            id
          }
        }
      }
    }
    workoutBlockWorkoutLogs(startDate_Lte: $endDate, startDate_Gte: $startDate, subscriptionBlock_UserId: $userId) {
      edges {
        node {
          id
          startDate
          unitActivity {
            id
            type
          }
        }
      }
    }
    nutritionBlockNutritionLogs(date_Lte: $endDate, date_Gte: $startDate) {
      edges {
        node {
          id
          date
        }
      }
    }
  }
`;

export const USER_DOTS_QUERY = gql`
  query userDotsQuery($startDate: DateTime!, $endDate: DateTime, $userId: ID) {
    userDots(startDate: $startDate, endDate: $endDate, userId: $userId) {
      exercise
      mindset
      nutrition
    }
  }
`;

export const SLEEP_QUERY = gql`
  query sleepQuery($date: Date) {
    sleepLog(date: $date) {
      edges {
        node {
          id
          seconds
          compliantDay
        }
      }
    }
  }
`;

export const HYDRATION_QUERY = gql`
  query hydrationQuery($date: Date) {
    hydrationLog(date: $date) {
      edges {
        node {
          id
          rating
          compliantDay
        }
      }
    }
  }
`;

export const NUTRITION_QUERY = gql`
  query nutritionQuery($date: Date) {
    nutritionLog(date: $date) {
      edges {
        node {
          id
          rating
          isNutritiousDay
          meal
          image
        }
      }
    }
  }
`;

export const MINDSET_QUERY = gql`
  query mindsetQuery($date: Date) {
    mindsetLog(date: $date) {
      edges {
        node {
          id
          rating
          compliantDay
        }
      }
    }
  }
`;

export const TRACKER_SUMMARY_QUERY = gql`
  query dailyTrackerSummary($summaryDate: Date!) {
    dailyTrackerSummary(summaryDate: $summaryDate) {
      day
      nutrition
      mindset
      exercise
      sleep
      hydration
      rest
    }
  }
`;

export const WEEKLY_TRACKER_SUMMARY_QUERY = gql`
  query weeklyTrackerSummary($summaryDate: Date!, $userId: ID) {
    weeklyTrackerSummary(summaryDate: $summaryDate, userId: $userId) {
      day
      nutrition
      mindset
      exercise
      sleep
      partialSleep
      hydration
      rest
    }
  }
  `

export const REST_DAYS_QUERY = gql`
  query restDaysQuery($date: Date!) {
    userRestDay(restDayDate: $date) {
      id
      restDayDate
      didRest
      planned
      details
      description
    }
  }
`;

export const USER_STATS_QUERY = gql`
query userStatsQuery($userId: ID) {
  userStats(userId: $userId) {
    currentStreak {
      id
      startDate
      endDate
      activitiesLogged
      uniqueDailyActivitiesLogged
    }
    previousStreaks {
      id
      startDate
      endDate
      activitiesLogged
      uniqueDailyActivitiesLogged
    }
    calories {
      thisWeek
      thisYear
      thisMonth
      allTime
    }
    duration {
      thisWeek
      thisYear
      thisMonth
      allTime
    }
    nutrition {
      thisWeek
      thisYear
      thisMonth
      allTime
    }
    workouts {
      thisWeek
      thisYear
      thisMonth
      allTime
    }
    heartRate {
      thisWeek
      thisYear
      thisMonth
      allTime
    }
    distance {
      thisWeek
      thisYear
      thisMonth
      allTime
    }
    effort {
      thisWeek
      thisYear
      thisMonth
      allTime
    }
  }
}
`