<template>
  <v-container>
    <v-row v-if="hasProductCode" align="center" justify="center">
      <v-col cols="12" md="6" sm="8" align="center" justify="center" class="pb-0">
        <product-title-block />
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" sm="8">
        <v-card class="card-radius mt-0">
          <v-row class="mt-0 pt-0">
            <v-col class="py-0">
              <v-card-text>
                <div class="d-flex pb-2">
                  <v-chip color="green-background" dense text-color="alert-green" class="font-16 mx-auto">
                    <strong>Step 1 of 3</strong>
                  </v-chip>
                </div>
                <div class="d-flex align-center justify-space-between mb-2">
                  <h2 class="secondary--text ml-1">Fill in your details</h2>
                  <div v-if="$vuetify.breakpoint.smAndDown" class="p-text">or</div>
                  <div class="d-flex align-center">
                    <div v-if="!$vuetify.breakpoint.smAndDown" class="p-text mr-2">or</div>
                    <j-btn narrow class="ml-1" @click="loginRedirect">log in</j-btn>
                  </div>
                </div>
                <div class="pt-2">
                  <registration-form :submit-button="registerButton" :include-password="false" />
                </div>
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProductTitleBlock from "@/components/store/blocks/ProductTitleBlock";
import RegistrationForm from "@/components/authentication/blocks/RegistrationForm";

export default {
  name: "CreateUserCheckout",
  components: {
    ProductTitleBlock,
    RegistrationForm,
  },
  data() {
    return {
      registerButton: {
        text: "Next",
        color: "one-on-one",
        textColor: "white",
      },
    };
  },
  created() {
    // covers 1-on-1 and 1-on-1-grad. This is simple but quite brittle. Careful. Duplicated in router/index.js and RegistrationMixin
    if (this.hasProductCode && !sessionStorage.getItem("selectedCoach") && this.$route.query.code.includes("1-on-1")) {
      return this.$router.push(`/start/coaches?redirect=/checkout/join?code=${this.$route.query.code}`);
    }
  },
  computed: {
    hasProductCode() {
      return this.$route.query.code;
    },
  },
  methods: {
    loginRedirect() {
      this.$router.push({
        name: "LoginCheckout",
        query: {
          code: this.$route.query.code,
          case: 1,
        },
      });
    },
  },
};
</script>
