import gql from "graphql-tag";

export const MEETUPS_QUERY = gql`
query meetups(
  $status: String, $category: String, $type: String, $eventDate_Gte: DateTime, $eventDate_Lte: DateTime, 
  $title_contains: String, $maxDistance: Float, $originLatitude: Float, $originLongitude: Float, 
  $locationContains: String, $host_Id: ID, $attending: Boolean, $orderBy: String) 
  {
  meetups(status: $status, category: $category, type: $type, eventDate_Lte: $eventDate_Lte, eventDate_Gte: $eventDate_Gte, 
  title_Icontains: $title_contains, maxDistance: $maxDistance, originLatitude: $originLatitude, 
  originLongitude: $originLongitude, location_Icontains: $locationContains, host_Id: $host_Id, attending: $attending, orderBy: $orderBy) 
  {
    edges {
      node {
        id
        status
        host {
          id
          firstName
          lastName
          userProfile {
            id
            profilePicture
          }
        }
        eventDate
        title
        capacity
        type
        category
        location
        longitude
        latitude
        notes
        contactNumber
        meetingLink
        googlePlaceId
        declinedReason
        attendees {
          attendee {
            id
            firstName
            lastName
            userProfile {
              id
              profilePicture
              phone
            }
          }
        }
      }
    }
  }
}
`;

export const MEETUP_QUERY = gql`
  query meetup($id: ID!) {
    meetup(id:$id) {
      id
      status
      host {
        id
        firstName
        lastName
        userProfile {
          id
          profilePicture
        }
      }
      eventDate
      title
      capacity
      type
      category
      location
      longitude
      latitude
      notes
      contactNumber
      meetingLink
      googlePlaceId
      declinedReason
      contactNumber
      attendees {
        attendee {
          id
          firstName
          lastName
          userProfile {
            id
            profilePicture
          }
        }
      }
    }
  }
`;