export default {
  state: {
    restDays: [
      {
        restDayDate: null,
        didRest: false,
        planned: null,
        details: null,
      },
    ],
    sleepDays: [
      {
        date: null,
        compliantDay: false,
      },
    ],
    hydrationDays: [
      {
        date: null,
        compliantDay: false,
      },
    ],
    mindsetDays: [
      {
        date: null,
        compliantDay: false,
      },
    ],
    habitEntryDays: [
      {
        date: null,
        status: null
      }
    ],
    nutritionDays: [
      {
        date: null,
        isNutritiousDay: false,
      },
    ],
    blockWorkoutLogDays: [
      {
        date: null,
      },
    ],
    blockNutritionLogDays: [
      {
        date: null,
      },
    ],
    selectedDate: null,
  },
  mutations: {
    mutateRestDay(state, { restDay }) {
      let foundDay = state.restDays.find(
        (day) => day.restDayDate == restDay.restDayDate
      );

      if (foundDay) {
        foundDay.didRest = restDay.didRest;
        foundDay.planned = restDay.planned;
        foundDay.details = restDay.details;
      } else {
        state.restDays.push({
          restDayDate: restDay.restDayDate,
          didRest: restDay.didRest,
          planned: restDay.planned,
          details: restDay.details,
        });
      }
    },
    unsetRestDay(state, {date}) {
      state.restDays = state.restDays.filter(day => day.restDayDate != date);
    },
    mutateRestDays(state, { restDays }) {
      state.restDays = restDays.map((day) => day.node);
    },
    mutateSleepDays(state, { sleepDays }) {
      state.sleepDays = sleepDays;
    },
    mutateHydrationDays(state, { hydrationDays }) {
      state.hydrationDays = hydrationDays;
    },
    mutateMindsetDays(state, { mindsetDays }) {
      state.mindsetDays = mindsetDays;
    },
    mutateHabitEntryDays(state, { habitEntryDays }) {
      state.habitEntryDays = habitEntryDays;
    },
    mutateNutritionDays(state, { nutritionDays }) {
      state.nutritionDays = nutritionDays;
    },
    mutateBlockWorkoutLogDays(state, { blockWorkoutLogDays }) {
      state.blockWorkoutLogDays = blockWorkoutLogDays;
    },
    mutateBlockNutritionLogDays(state, { blockNutritionLogDays }) {
      state.blockNutritionLogDays = blockNutritionLogDays;
    },
    mutateSelectedDate(state, { selectedDate }) {
      state.selectedDate = selectedDate;
    },
  },
  actions: {
    updateRestDay({ commit }, payload) {
      commit("mutateRestDay", { restDay: payload });
    },
    removeRestDay({ commit }, date) {
      commit("unsetRestDay", { date });
    },
    setRestDays({ commit }, restDays) {
      commit("mutateRestDays", { restDays });
    },
    setSleepDays({ commit }, sleepDays) {
      commit("mutateSleepDays", { sleepDays });
    },
    setHydrationDays({ commit }, hydrationDays) {
      commit("mutateHydrationDays", { hydrationDays });
    },
    setMindsetDays({ commit }, mindsetDays) {
      commit("mutateMindsetDays", { mindsetDays });
    },
    setHabitEntryDays({ commit }, habitEntryDays) {
      commit("mutateHabitEntryDays", { habitEntryDays });
    },
    setNutritionDays({ commit }, nutritionDays) {
      commit("mutateNutritionDays", { nutritionDays });
    },
    setBlockWorkoutLogDays({ commit }, blockWorkoutLogDays) {
      commit("mutateBlockWorkoutLogDays", { blockWorkoutLogDays });
    },
    setBlockNutritionLogDays({ commit }, blockNutritionLogDays) {
      commit("mutateBlockNutritionLogDays", { blockNutritionLogDays });
    },
    setSelectedDate({ commit }, selectedDate) {
      commit("mutateSelectedDate", { selectedDate });
    },
  },
  getters: {
    restDays: (state) => state.restDays,
    sleepDays: (state) => state.sleepDays,
    hydrationDays: (state) => state.hydrationDays,
    mindsetDays: (state) => state.mindsetDays,
    habitEntryDays: (state) => state.habitEntryDays,
    nutritionDays: (state) => state.nutritionDays,
    blockWorkoutLogDays: (state) => state.blockWorkoutLogDays,
    blockNutritionLogDays: (state) => state.blockNutritionLogDays,
    selectedDate: (state) => state.selectedDate,
  },
};
