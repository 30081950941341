export default {
  state: {
    // used as a unique identifier for the checkout session. Used by segment
    orderId: localStorage.getItem("orderId") || null,
    checkoutCode: localStorage.getItem("checkoutCode") || null,
    billingDetails: localStorage.getItem("billingDetails") || null,
    checkoutProduct: localStorage.getItem("checkoutProduct") || null,
    checkoutVoucher: localStorage.getItem("checkoutVoucher") || null,
    paymentRetry: localStorage.getItem("paymentRetry") || false,
    coldCheckout: localStorage.getItem("coldCheckout") || false,
    // used to determine whether to raise the segement event and create deal. Stores String like `Jeff Life`
    trackedCheckoutOpportunity:
      localStorage.getItem("trackedCheckoutOpportunity") || null,
  },
  mutations: {
    mutateOrderId(state, { orderId }) {
      state.orderId = orderId;
    },
    mutateCheckoutCode(state, { checkoutCode }) {
      state.checkoutCode = checkoutCode;
    },
    mutateBillingDetails(state, { billingDetails }) {
      state.billingDetails = JSON.stringify(billingDetails);
    },
    mutateCheckoutProduct(state, { checkoutProduct }) {
      state.checkoutProduct = JSON.stringify(checkoutProduct);
    },
    mutateCheckoutVoucher(state, { checkoutVoucher }) {
      state.checkoutVoucher = JSON.stringify(checkoutVoucher);
    },
    mutatePaymentRetry(state, val) {
      state.paymentRetry = val;
    },
    mutateColdCheckout(state, { coldCheckout }) {
      state.coldCheckout = coldCheckout;
    },
    mutateTrackedCheckoutOpportunity(state, { trackedCheckoutOpportunity }) {
      state.trackedCheckoutOpportunity = trackedCheckoutOpportunity;
    },
    resetCheckout(state) {
      state.orderId = null;
      state.checkoutCode = null;
      state.billingDetails = null;
      state.checkoutProduct = null;
      state.paymentRetry = false;
      state.coldCheckout = false;
      state.trackedCheckoutOpportunity = false;
    },
  },
  actions: {
    updateOrderId({ commit }, orderId) {
      localStorage.setItem("orderId", orderId);
      commit("mutateOrderId", { orderId });
    },
    updateCheckoutCode({ commit }, checkoutCode) {
      localStorage.setItem("checkoutCode", checkoutCode);
      commit("mutateCheckoutCode", { checkoutCode });
    },
    updateBillingDetails({ commit }, billingDetails) {
      localStorage.setItem("billingDetails", JSON.stringify(billingDetails));
      commit("mutateBillingDetails", { billingDetails });
    },
    updateCheckoutProduct({ commit }, checkoutProduct) {
      localStorage.setItem("checkoutProduct", JSON.stringify(checkoutProduct));
      commit("mutateCheckoutProduct", { checkoutProduct });
    },
    updateCheckoutVoucher({ commit }, checkoutVoucher) {
      localStorage.setItem("checkoutVoucher", JSON.stringify(checkoutVoucher));
      commit("mutateCheckoutVoucher", { checkoutVoucher });
    },
    updatePaymentRetry({ commit }, val) {
      localStorage.setItem("paymentRetry", val);
      commit("mutatePaymentRetry", val);
    },
    updateColdCheckout({ commit }, coldCheckout) {
      localStorage.setItem("coldCheckout", coldCheckout);
      commit("mutateColdCheckout", { coldCheckout });
    },
    updateTrackedCheckoutOpportunity({ commit }, trackedCheckoutOpportunity) {
      localStorage.setItem(
        "trackedCheckoutOpportunity",
        trackedCheckoutOpportunity
      );
      commit("mutateTrackedCheckoutOpportunity", {
        trackedCheckoutOpportunity,
      });
    },
    resetCheckout({ commit }) {
      localStorage.removeItem("orderId");
      localStorage.removeItem("checkoutCode");
      localStorage.removeItem("billingDetails");
      localStorage.removeItem("checkoutProduct");
      localStorage.removeItem("checkoutVoucher");
      localStorage.removeItem("paymentRetry");
      localStorage.removeItem("coldCheckout");
      localStorage.removeItem("trackedCheckoutOpportunity");
      sessionStorage.removeItem("paymentId");
      commit("resetCheckout");
    },
  },
  getters: {
    orderId: (state) => state.orderId,
    checkoutCode: (state) => state.checkoutCode,
    billingDetails: (state) =>
      state.billingDetails ? JSON.parse(state.billingDetails) : null,
    checkoutProduct: (state) =>
      state.checkoutProduct ? JSON.parse(state.checkoutProduct) : null,
    checkoutVoucher: (state) =>
      state.checkoutVoucher ? JSON.parse(state.checkoutVoucher) : null,
    paymentRetry: (state) => state.paymentRetry,
    coldCheckout: (state) => state.coldCheckout ? JSON.parse(state.coldCheckout) : false,
    trackedCheckoutOpportunity: (state) => state.trackedCheckoutOpportunity,
  },
};
