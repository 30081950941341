<template>
  <j-card rounded="lg">
    <v-toolbar class="mb-6">
      <v-toolbar-title>Confirm cancellation</v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="$emit('closed')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text v-if="hasPeachOrder" class="p-text">
      <p>We're sad to see you go <v-icon color="pink">mdi-emoticon-sad-outline</v-icon></p>
      <p>
        Do you need guidance about our different products?
        <router-link to="/help" class="text-decoration-underline">Contact us</router-link> and we'll help you make your
        next move.
      </p>

      <p>
        If you cancel, you'll no longer have access to nutrition programs, exercise programs, Challenges and Groups.
        When your subscription ends on {{ endDate }}, you'll only be able to access basic features of {{ $appName }}.
      </p>
      <p>You'll continue to have full access until {{ endDate }}.</p>
      <div class="d-flex justify-space-between">
        <j-btn @click="$emit('closed')" tertiary>Close</j-btn>
        <j-btn class="ml-2" :loading="loading" @click="$emit('confirm')">Confirm Cancellation</j-btn>
      </div>
    </v-card-text>
    <v-card-text v-else class="p-text">
      <p>We're sad to see you go <v-icon color="pink">mdi-emoticon-sad-outline</v-icon></p>
      <p>
        Do you need guidance about our different products?
        <router-link to="/help" class="text-decoration-underline">Contact us</router-link> and we'll help you make your
        next move.
      </p>
      <p>
        If you cancel, you'll no longer have access to nutrition programs, exercise programs, Challenges and Groups.
        When your subscription ends, you'll only be able to access basic features of {{ $appName }}.
      </p>
      <div class="d-flex justify-space-between">
        <j-btn @click="$emit('closed')" tertiary>Close</j-btn>
        <j-btn class="ml-2" :loading="loading" @click="$emit('confirm')">Confirm Cancellation</j-btn>
      </div>
    </v-card-text>
  </j-card>
</template>

<script>
export default {
  name: "CancelSubscriptionDialog",
  props: {
    loading: {
      type: Boolean,
      default: true,
      required: false,
    },
    hasPeachOrder: {
      type: Boolean,
      default: false,
      required: false,
    },
    endDate: {
      type: String,
      required: true,
    },
  },
};
</script>
