<template>
  <div class="d-flex text-center text-sm-left bg-grey pr-4 flex-column flex-sm-row rounded-lg">
    <div class="d-flex justify-center align-center justify-sm-left align-sm-left">
      <v-img :src="image" max-width="250"></v-img>
    </div>
    <div class="ml-4">
      <div class="text-h6 appblue--text font-weight-bold mt-4">{{ heading }}</div>
      <div class="p-text mt-1 appblue--text mb-2">{{ subHeading }}</div>
      <div class="p-text">{{ description }}</div>

      <div class="mt-4 mb-4 text-center text-sm-right"><j-btn @click="next()">Continue</j-btn></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeroDisplayCard",
  props: {
    heading: {
      type: String,
      required: true,
    },
    subHeading: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    buttonUrl: {
      type: String,
      required: true,
    },
  },
  methods: {
    next() {
      this.$router.push(this.buttonUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-grey {
  background-color: #f1f1f1;
}
</style>
