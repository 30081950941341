<template>
  <v-container v-if="workoutBlocks">
    <j-banner-container :gradient="['white', 'grey']" :textGradient="['green', 'pink']" title="Choose from the new Challenges">
      <j-card rounded="lg" v-for="workoutBlock in workoutBlocks.edges" :key="workoutBlock.node.id" class="mb-4">
          <condensed-block :workout-block="workoutBlock.node" navigate />
        </j-card>
    </j-banner-container>
    
  </v-container>
</template>

<script>
import { WORKOUT_BLOCKS_QUERY } from "@/graphql/queries/workoutblock";
import CondensedBlock from "@/components/workoutblocks/cards/CondensedBlock";
export default {
  name: "Recommendation",
  components: {
    CondensedBlock,
  },
  data() {
    return {
      workoutBlocks: null,
    };
  },
  apollo: {
    workoutBlocks: {
      query: WORKOUT_BLOCKS_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          type: "CHALLENGE",
          withUsers: false,
        };
      },
    },
  },
};
</script>
