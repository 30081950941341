import { NUTRITION_PREFERENCES_QUERY, USER_NUTRITION_PREFERENCES_QUERY } from "@/graphql/queries/nutrition";
import { USER_NUTRITION_PREFERENCE_MUTATION } from "@/graphql/mutations/nutrition";

export default {
  data() {
    return {
      nutritionPreferenceList: null,
      primaryNutritionPreferences: [],
      secondaryNutritionPreferences: [],
      selectedPrimaryNutritionPreferences: [],
      selectedSecondaryNutritionPreferences: [],
    };
  },
  methods: {
    disabledNutritionPrefs(prefId) {
      // this method is horrid. Don't blame me, blame Adrian. 
      const prefs = this.selectedSecondaryNutritionPreferences;

      // can't be a Vegan or Vegetarian and have Low Carb chosen
      if(this.selectedPrimaryNutritionPreferences && this.selectedPrimaryNutritionPreferences > 1 && prefId == '13') {
        return true;
      }
      
      // You can't have low carb (13) and fat free (15) at the same time. 
      if(prefs.length > 0 && prefs.includes('13') && prefId == '15') {
        return true;
      }
      if(prefs.length > 0 && prefs.includes('15') && prefId == '13') {
        return true;
      }
      return false;
    },
    async updateNutritionPreferences() {
      const preferences = [this.selectedPrimaryNutritionPreferences, ...this.selectedSecondaryNutritionPreferences];
      if (preferences.length > 0) {
        return this.$apollo.mutate({
          mutation: USER_NUTRITION_PREFERENCE_MUTATION,
          variables: {
            nutritionPreferences: preferences,
          },
          refetchQueries: ["accountQuery", "assistantQuery"],
        });
      }
    },
  },
  apollo: {
    nutritionPreferenceList: {
      query: NUTRITION_PREFERENCES_QUERY,
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data) {
          this.primaryNutritionPreferences = response.data.nutritionPreferenceList.filter((el) => el.isPrimary == true);
          this.secondaryNutritionPreferences = response.data.nutritionPreferenceList.filter(
            (el) => !el.isPrimary && el.showToUser
          );
        }
      },
    },
    userNutritionPreferences: {
      query: USER_NUTRITION_PREFERENCES_QUERY,
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data && response.data.userNutritionPreferences) {
          // should only be one primary. 
          let selectedPrimaryNutritionPreferences = response.data.userNutritionPreferences.find(
            (el) => el.nutritionPreference.isPrimary
          );
          let selectedSecondaryNutritionPreferences = response.data.userNutritionPreferences.filter(
            (el) => !el.nutritionPreference.isPrimary 
          );
          // should only be one. it's a radio. So pick the first one.
          if (selectedPrimaryNutritionPreferences) {
            this.selectedPrimaryNutritionPreferences = selectedPrimaryNutritionPreferences.nutritionPreference.id;
          }
          this.selectedSecondaryNutritionPreferences = selectedSecondaryNutritionPreferences.map((el) => {
            return el.nutritionPreference.id;
          });
        }
      },
    },
  },
};
