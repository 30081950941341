<template>
  <j-alert v-if="canBook && coachDetail && coachDetail.userProfile.consultBookingLink"
    >You can
    <a :href="coachDetail.userProfile.consultBookingLink" target="_blank" class="font-weight-bold">book a consult</a>
    with Coach
    {{ coachDetail.firstName }}
  </j-alert>
</template>

<script>
import { COACH_DETAIL_QUERY } from "@/graphql/queries/support";

export default {
  name: "BookConsult",
  data() {
    return {
      coachDetail: null,
      canBook: null,
    };
  },
  apollo: {
    coachDetail: {
      query: COACH_DETAIL_QUERY,
      fetchPolicy: "network-only",
      result(response) {
        this.coachDetail = response.data?.coachDetail;
        this.canBook = response.data?.me?.userProfile?.canBookCoachingCheckin;
      },
    },
  },
};
</script>
