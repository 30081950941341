<template>
  <checkin-stepper
    :step="1"
    :header="`Welcome, ${$store.getters.user.firstName}!`"
    :title="{ text: 'What can we', strongText: 'help you do?' }"
    subtitle="Choose one or multiple"
    @next="next"
  >
    <v-row v-if="tags && tags.edges.length > 0" class="pa-5 pb-0">
      <v-col cols="6" v-for="tag in tags.edges" :key="tag.node.code" class="px-5 py-1">
        <j-card flat :class="{ 'selected-tag': tagIsSelected(tag) }">
          <v-img
            :lazy-src="tag.node.thumbnail"
            :src="tag.node.thumbnail"
            :aspect-ratio="9 / 9"
            class="pointer"
            @click="toggleCheck(tag.node.code)"
          />
        </j-card>
        <j-checkbox v-model="selectedTags" :label="tag.node.title" :value="tag.node.code" class="mt-1" />
      </v-col>
    </v-row>
  </checkin-stepper>
</template>

<script>
import CheckinStepper from "@/components/checkin/CheckinStepper";
import TagMixin from "@/mixins/TagMixin";

export default {
  name: "Goals",
  mixins: [TagMixin],
  components: {
    CheckinStepper,
  },
  data() {
    return {
      category: "PRIMARY",
    };
  },
  methods: {
    toggleCheck(code) {
      const index = this.selectedTags.indexOf(code);
      index === -1 ? this.selectedTags.push(code) : this.selectedTags.splice(index, 1);

      this.$store.dispatch("updateGoals", this.selectedTags);
    },
  },
};
</script>

<style lang="scss" scoped>
.selected-tag {
  border: 4px solid var(--v-navy-base);
  background-color: var(--v-navy-base);
}
</style>
