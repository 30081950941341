<template>
  <div>
    <j-alert v-if="checkinBookingRequired" type="warning">
      <a
        class="d-flex flex-grow-1 text-decoration-none"
        :href="this.checkinBookingLink ? this.checkinBookingLink : '/today/to-do/checkin'"
        :target="this.checkinBookingLink && this.checkinBookingLink !== '/checkins/new/coaching' ? '_blank' : ''"
      >
        <strong class="mr-auto navy--text text-uppercase align-self-center p-text">{{ checkInTitleCopy }}</strong>
        <div class="flex-grow-0 navy--text">
          <v-icon size="24" class="navy--text">mdi-chevron-right</v-icon>
        </div>
      </a>
      <div class="p-text warning--text">{{ checkInCopy }}</div>
    </j-alert>
    <j-alert v-if="checkinRequired && checkinId" type="warning">
      <router-link class="d-flex flex-grow-1 text-decoration-none" :to="`/checkin/assessment/edit/${checkinId}`">
        <strong class="mr-auto navy--text text-uppercase align-self-center p-text"
          >Submit your check-in for your coach</strong
        >
        <div class="flex-grow-0 navy--text">
          <v-icon size="24" class="navy--text">mdi-chevron-right</v-icon>
        </div>
      </router-link>
    </j-alert>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CentralStorageMixin from "@/mixins/CentralStorageMixin";

export default {
  name: "CheckInAlert",
  mixins: [CentralStorageMixin],
  computed: {
    ...mapGetters(["checkinRequired", "checkinBookingRequired", "checkinBookingLink", "checkinId", "checkinIsInitial"]),
    withCoach() {
      if (this.$store.getters.user?.userProfile?.coach?.firstName) {
        return ` with Coach ${this.$store.getters.user.userProfile.coach.firstName}`;
      }
      return "";
    },
    checkInTitleCopy() {
      if (this.checkinBookingLink && this.checkinBookingLink === "/checkins/new/coaching")
        return "Complete your check-in"
      return "Book a check-in with your coach"
    },
    checkInCopy() {
      if (this.checkinIsInitial)
        return `Book your initial assessment ${this.withCoach}`
      if (this.checkinBookingLink && this.checkinBookingLink === "/checkins/new/coaching")
        return `Complete your measurements and progress assessment`

      return `It's time to book a progress check ${this.withCoach }`
    }
  },
};
</script>
