<template>
  <j-card
    rounded="lg" class="mr-3 my-1 j-elevated-2 search-result-card">
    <v-img v-if="image" :src="image" :lazy-src="image" :aspect-ratio="10 / 9" />
    <v-card-text class=" px-2 pt-1 pb-2 text-left">
      <div class="navy--text font-weight-bold">{{ title }}</div>
      <div v-if="subtitle" class="d-flex justify-space-between">
        <j-gradient-text
          :gradient="['pink', 'yellow']"
          class="text-uppercase sm-text"
        >
          {{ subtitle }}
        </j-gradient-text>
      </div>
    </v-card-text>
  </j-card>
</template>

<script>
export default {
  name: "SearchResultCard",
  props: {
    image: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    subtitle: {
      type: String,
      default: ""
    },
  }
}
</script>

<style scoped>
.search-result-card {
  min-height: 250px;
  cursor: pointer !important;
}
</style>