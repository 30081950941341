<template>
  <j-alert v-if="preferencesRequired" type="warning" icon="mdi-lightbulb-on-outline">
    You must
    <router-link to="/account/preferences" class="warning--text font-weight-bold">
      complete your preferences
    </router-link>
    to get the correct portion and calorie recommendations
  </j-alert>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "NutritionPreferenceAlert",
  computed: {
    ...mapGetters(["outstandingUserTodos"]),
    preferencesRequired() {
      return this.outstandingUserTodos?.some((todo) => todo.code == "preferences");
    },
  },
};
</script>
