<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center" class="fill-height">
      <v-col cols="12" md="6" class="background-grey-3 fill-height">
        <div class="text-center mt-3">
          <h1 class="text-h5 navy--text font-weight-bold">Choose your fitness level</h1>

          <j-radio-group
            v-model="selectedFitness"
            mandatory
            @change="$store.dispatch('updateFitnessLevel', selectedFitness)"
            class="mt-0"
          >
            <div v-for="option in fitnessOptions" :key="option.value">
              <j-card rounded="lg" :class="{ 'pink-border': option.value == selectedFitness }" class="mt-4">
                <j-radio color="pink" :value="option.value" hide-details class="pa-3 mt-0">
                  <div slot="label" class="std-text navy--text ml-1">
                    {{ option.text }}
                    <div class="grey-navy--text text-body-1 mt-n1">
                      {{ option.description }}
                    </div>
                  </div>
                </j-radio>
              </j-card>
            </div>
          </j-radio-group>
          <j-btn to="/start/plan" color="pink" wide class="d-flex mx-auto">Continue</j-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { fitnessOptions } from "@/lib/user";

export default {
  name: "Level",
  data() {
    return {
      fitnessOptions: fitnessOptions,
      selectedFitness: null,
    };
  },
  created() {
    this.selectedFitness = this.$store.getters.deal?.fitnessLevel;
  },
};
</script>

<style lang="scss" scoped>
.j-card.pink-border {
  border: 2px solid var(--v-pink-base);
}
</style>
