import gql from "graphql-tag";

export const UPSERT_HABIT_MUTATION = gql`
  mutation habitMutation($habitId: ID, $title: String, $startDate: Date, $endDate: Date, $color: String, $optionId: ID) {
    habitMutation(
      data: {habitId: $habitId, title: $title, startDate: $startDate, endDate: $endDate, colour: $color, habitOptionId: $optionId }
    ) {
      success
      error
    }
  }
`;

export const DELETE_HABIT_MUTATION = gql`
  mutation {
    deleteHabitMutation(habitId: 2) {
      success
      error
    }
  }
`;

export const UPSERT_HABIT_ENTRY_MUTATION = gql`
  mutation habitEntryMutation($habitId: ID, $entryId: ID, $date: Date, $status: Boolean, $comment: String) {
    habitEntryMutation(
      data: { habitId: $habitId, habitEntryId: $entryId, date: $date, status: $status, comment: $comment }
    ) {
      success
      error
      earnedBadges{
        title
        description
      }
    }
  }
`;

export const DELETE_HABIT_ENTRY_MUTATION = gql`
  mutation {
    deleteHabitEntryMutation(habitEntryId: 2) {
      success
      error
    }
  }
`;
