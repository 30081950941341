import moment from "moment";

export const capitalize = (string) => {
  string = string.replace("_", " ");
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const formatPhone = (phone) => {
  return phone ? phone.toString().replace(/(\d{2})(\d{2})(\d{3})(\d{4})/, "$1 $2 $3 $4") : "";
};

export const getYouTubeId = (embedUrl) => {
  if (!embedUrl) {
    return null;
  }
  let parts = embedUrl.split("v=", 2);
  if (parts[1]) {
    return parts[1];
  }
  // if not working, it may be in format of "https://youtu.be/I6o4X7GWEqQ"
  if (embedUrl.includes("youtu.be")) {
    const n = embedUrl.lastIndexOf("/");
    return embedUrl.substring(n + 1);
  }

  return null;
};

export const decodeId = (base64string) => {
  if (!base64string) {
    return null;
  }
  if (parseInt(base64string)) {
    return base64string;
  }
  let decoded = atob(base64string).split(":");
  return decoded[1].trim();
};

export const toGraphqlID = (nodeName, id) => {
  let fixedID = decodeId(id);
  let string = nodeName + ":" + fixedID;
  return btoa(string);
};

export const relativeImage = (imageUrl) => {
  if (!imageUrl) {
    return null;
  }
  return imageUrl.replace("https://storage.googleapis.com/master_site/", "");
};

export const nl2br = (str) => {
  if (!str) {
    return null;
  }
  const breakTag = "<br />";
  return (str + "").replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, "$1" + breakTag + "$2");
};

export const currency = (number, decimals = 0) => {
  if (!number) {
    return "";
  }
  number = parseFloat(number).toFixed(decimals);
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const truncate = (string, length, useWordBoundary = true) => {
  if (string.length <= length) {
    return string;
  }
  const subString = string.substr(0, length - 1);
  return (useWordBoundary ? subString.substr(0, subString.lastIndexOf(" ")) : subString) + "...";
};

// HH:MM:SS to seconds
export const maskedTimeToSeconds = (maskedValue = null) => {
  if (!maskedValue) {
    return null;
  }
  const lastChar = maskedValue.charAt(maskedValue.length - 1);

  let p = maskedValue.split(":"),
    s = 0,
    m = 1;

  if (p.length == 1 || (p.length == 2 && lastChar === ":")) {
    // user input only "12" or "12:"
    return p[0] * 60;
  }

  while (p.length > 0) {
    const int = parseInt(p.pop(), 10);
    s += m * int;
    m *= 60;
  }

  return s;
};

// seconds to HH:MM:SS or HH:MM:SS
export const secondsToTimeString = (secs) => {
  if (secs === null) {
    return null;
  }
  // if not a number, just return?
  if (isNaN(secs)) {
    return secs;
  }
  if (secs > 3600) {
    return moment.utc(secs * 1000).format("HH:mm:ss");
  }
  return moment.utc(secs * 1000).format("mm:ss");
};

// strip HTML tags from string
export const stripTags = (string) => {
  return string.replace(/<\/?[^>]+(>|$)/g, "");
};

// strip HTML <span> from string
export const stripSpans = (string) => {
  string = string.replace(/(<span([^>]+)>)/gi, "");
  string = string.replace(/<\/span>/gi, "");
  return string;
};
