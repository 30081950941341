<template>
  <div class="text-center ma-12" v-if="loading">
    <v-progress-circular :indeterminate="loading" color="blue"></v-progress-circular>
  </div>
  <div v-else>
    <j-banner-container
      header="1-on-1 Goal"
      md="5"
      :gradient="['green-background', 'yellow-background']"
      class="my-2"
    >
      <div v-if="bhagGoals || checkinGoals" class="pa-4">
        <j-card v-if="checkinGoals" class="my-5">
          <v-toolbar flat color="#FCD13C" class="pa-0" height="40">
            <v-toolbar-title class="font-weight-bold white--text">
              Check-in goal
            </v-toolbar-title>
          </v-toolbar>
          <v-card-title>
            {{ toProperCase(checkinGoals.type.replace("_", " ")) }}: {{ checkinGoals.category }}
          </v-card-title>
          <v-card-text class="text-left">
            <v-row v-if="hasTextUnit(checkinGoals)">
              <v-col cols="12">
                <span class="pb-0 subtitle-1"> Target </span>
                <div v-for="measurement in checkinGoals.goalMeasurement" :key="measurement.id">
                  <h3 v-if="measurement.targetText">
                    <strong>{{ measurement.targetText }}</strong>
                  </h3>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="hasValueUnits(checkinGoals)">
              <v-col cols="6" class="py-1">
                <span class="subtitle-1"> Initial </span>
                <div v-for="measurement in checkinGoals.goalMeasurement" :key="measurement.id" class="pb-0">
                  <span v-if="measurement.initialValue">{{ measurement.initialValue }} {{ measurement.unit }}</span>
                </div>
              </v-col>
              <v-col cols="6" class="py-1">
                <span class="subtitle-1"> Target </span>
                <div v-for="measurement in checkinGoals.goalMeasurement" :key="measurement.id" class="pb-0">
                  <strong>{{ measurement.targetValue }} {{ measurement.unit }}</strong>
                </div>
              </v-col>
            </v-row>
            <div class="mt-4">{{ checkinGoals.ultimateWhy }}</div>
          </v-card-text>
        </j-card>
        <j-card v-if="bhagGoals" class="my-5">
          <v-toolbar flat color="yellow" class="pa-0" height="40">
            <v-toolbar-title class="font-weight-bold white--text">
              Big Hairy Audacious Goal
            </v-toolbar-title>
          </v-toolbar>
          <v-card-title> {{ toProperCase(bhagGoals.type.replace("_", " ")) }}: {{ bhagGoals.category }} </v-card-title>
          <v-card-text class="text-left">
            <v-row v-if="hasTextUnit(bhagGoals)">
              <v-col cols="12">
                <span class="pb-0 subtitle-1"> Target </span>
                <div v-for="measurement in bhagGoals.goalMeasurement" :key="measurement.id">
                  <h3 v-if="measurement.targetText">
                    <strong>{{ measurement.targetText }}</strong>
                  </h3>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="hasValueUnits(bhagGoals)">
              <v-col cols="6" class="py-1">
                <span class="pb-0 subtitle-1"> Initial </span>
                <div v-for="measurement in bhagGoals.goalMeasurement" :key="measurement.id" class="pb-0">
                  <span v-if="measurement.initialValue">{{ measurement.initialValue }} {{ measurement.unit }}</span>
                </div>
              </v-col>
              <v-col cols="6" class="py-1">
                <span class="pb-0 subtitle-1"> Target </span>
                <div v-for="measurement in bhagGoals.goalMeasurement" :key="measurement.id" class="pb-0">
                  <strong>{{ measurement.targetValue }} {{ measurement.unit }}</strong>
                </div>
              </v-col>
            </v-row>
            <div class="mt-4">{{ bhagGoals.ultimateWhy }}</div>
          </v-card-text>
        </j-card>
      </div>
      <v-alert v-else type="info" color="primary">
        Once you set your goal in collaboration with your Coach, it will appear here.
      </v-alert>
    </j-banner-container>
  </div>
</template>

<script>
import { COACHING_CLIENT_GOALS_QUERY } from "@/graphql/queries/checkins";

export default {
  name: "CoachingGoals",
  data: () => ({
    loading: true,
    checkinGoals: null,
    bhagGoals: null,
  }),
  methods: {
    toProperCase(str) {
      // thanks stack overflow
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    hasTextUnit(goal) {
      return goal.goalMeasurement.some((measurement) => measurement.targetText != null);
    },
    hasValueUnits(goal) {
      return goal.goalMeasurement.some(
        (measurement) => measurement.initialValue != null || measurement.targetValue != null
      );
    },
  },
  apollo: {
    clientGoals: {
      query: COACHING_CLIENT_GOALS_QUERY,
      variables() {
        return {
          userId: this.$store.getters.user.id,
        };
      },
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data) {
          this.checkinGoals = response.data.clientGoals.filter((goal) => goal.timeframe != "BHAG")[0];
          this.bhagGoals = response.data.clientGoals.filter((goal) => goal.timeframe == "BHAG")[0];
          this.loading = false;
        }
      },
    },
  },
};
</script>

<style scoped>
/**
  Transition Animations
 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
