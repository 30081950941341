<template>
  <j-card>
    <v-card-title class="font-weight-bold pb-2">
      <div class="ml-auto">
        <j-btn tertiary small narrow to="/on-demand?eventType=Ed">
          <strong>Ed classes</strong>
          <v-icon size="20" class="ml-1">mdi-video-account</v-icon>
        </j-btn>
      </div>
    </v-card-title>
    <v-card-text class="mt-4">
      <v-row align="center" justify="center">
        <v-col>
          <course />
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col class="pb-0">
          <habit-tracker />
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col class="pb-0">
          <stress-tracker />
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col class="pb-0">
          <hydration-tracker />
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-col>
          <sleep-tracker />
        </v-col>
      </v-row>
    </v-card-text>
  </j-card>
</template>

<script>
import Course from "@/components/shared/sections/Course";
import StressTracker from "@/components/tracker/StressTracker";
import HydrationTracker from "@/components/tracker/HydrationTracker";
import SleepTracker from "@/components/tracker/SleepTracker";
import HabitTracker from "@/components/tracker/habit/HabitTracker";

export default {
  name: "TodayMindset",
  components: {
    Course,
    StressTracker,
    HydrationTracker,
    SleepTracker,
    HabitTracker,
  },
};
</script>
