import gql from "graphql-tag";

export const CORPORATE_CHALLENGE_QUERY = gql`
  query corporateChallengeQuery($registrationCode: String) {
    corporateChallenge(registrationCode: $registrationCode) {
      id
      title
      registrationCode
      startDate
      successMessage
      maxUsers
      numberOfUsers
      requiresEmployeeCode
    }
  }
`;
