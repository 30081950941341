import gql from "graphql-tag";

export const USER_CONNECTIONS_QUERY = gql`
  query userConnections($userId: ID, $forUserId: String, $status: String, $first: Int, $orderBy: String) {
    userConnections(userId: $userId, forUserId: $forUserId, status: $status, first: $first, orderBy: $orderBy) {
      edges {
        node {
          id
          status
          createdAt
          user {
            id
            firstName
            lastName
            userProfile {
              id
              profilePicture
              handle
            }
          }
          requester {
            id
            firstName
            lastName
            userProfile {
              id
              profilePicture
              handle
              friendship {
                id
                status
                mutualFriendsCount
              }
            }
          }
        }
      }
    }
  }
`;
