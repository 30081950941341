import gql from "graphql-tag";

export const UPSERT_ACTIVITY_MUTATION = gql`
  mutation upsertUserActivity($data: UserActivityInput!, $typeId: ID) {
    upsertUserActivity(data: $data, typeId: $typeId) {
      upsertedUserActivity {
        userActivity {
          id
        }
        earnedBadges {
          id
          title
          description
        }
      }
    }
  }
`;

export const DELETE_ACTIVITY_MUTATION = gql`
  mutation deleteUserActivity($id: ID!) {
    deleteUserActivity(input: { id: $id }) {
      ok
    }
  }
`;

export const TRACK_VIDEO_VIEW_MUTATION = gql`
  mutation trackVideoView($duration: Int!, $event: ID, $guidedRun: ID, $workout: ID, $unitActivity: Int) {
    upsertUserVideoViewLog(input: { duration: $duration, event: $event, guidedRun: $guidedRun, workout: $workout, unitActivity: $unitActivity }) {
      ok
    }
  }
`;

export const UPSERT_REFLECTION_MUTATION = gql`
  mutation upsertReflectionLogs($input: ReflectionLogInput!) {
    upsertReflectionLogs(input: $input) {
      success
      error
      earnedBadges {
        id
        title
        description
      }
    }
  }
`;

export const UNSET_REST_DAY_MUTATION = gql`
  mutation unsetRestDayMutation($date: Date!) {
    unsetUserRestDay(date: $date) {
      success
    }
  }
`;

export const SET_REST_DAY_MUTATION = gql`
  mutation mutateUserRestDay($input: UserRestDayInput!) {
    mutateUserRestDay(input: $input) {
      success
    }
  }
`;
