<template>
  <j-alert rounded color="warning" icon="mdi-earth-off" class="ma-3 mb-0">
    <span class="p-text warning--text">No shipping outside of South Africa</span>
  </j-alert>
</template>

<script>
export default {
  name: "NoOverseasShippingAlert",
};
</script>
