import gql from "graphql-tag";

export const SHOPPING_LIST_QUERY = gql`
  query shoppingList($startDate:Date!) {
    shoppingList(startDate:$startDate) {
      id
      startDate
      endDate
      requiresRefresh
      shoppinglistitemSet {
        id
        quantities
        ingredient {
          id
          singular
          plural
          group {
            id
            name
          }
        }
        purchased
        customItem
        isCustomItem
      }
    }
  }
`;