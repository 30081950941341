<template>
  <div>
    <div class="d-flex justify-space-between align-center py-3">
      <j-checkbox v-model="exerciseCompleted" dense />
      <div>
        <activity-exercise-image
          v-if="exercise.exercise.staticImage"
          class="mr-5"
          :exercise="exercise.exercise"
          :show-rest="isResting"
        />
        <div
          v-if="!isResting && exerciseHasHowTo && $vuetify.breakpoint.smAndDown"
          class="sm-text mt-2"
          @click="$emit('open-exercise-info-dialog', exercise.exercise)"
        >
          <v-icon size="20"> mdi-information-outline </v-icon>
          How To
        </div>
      </div>
      <div v-if="isResting" class="mr-auto">
        <h4 class="my-0 py-0 secondary--text">Rest</h4>
        <p>Take a break and recover</p>
      </div>
      <div v-else class="mr-auto">
        <p class="py-0 my-0 text-uppercase">Let's go</p>
        <h4 class="my-0 py-0 secondary--text">
          <span class="pointer" @click="$emit('open-exercise-info-dialog', exercise.exercise)">{{
            exercise.exercise.title
          }}</span>
          <v-icon
            v-if="exerciseHasHowTo && $vuetify.breakpoint.mdAndUp"
            size="20"
            class="ml-1 mt-n1"
            @click="$emit('open-exercise-info-dialog', exercise.exercise)"
          >
            mdi-information-outline
          </v-icon>
        </h4>
        <p class="mb-2">{{ exercise.description }}</p>
        <p v-if="$vuetify.breakpoint.mdAndUp && exercise.longDescription">
          {{ exercise.longDescription }}
        </p>
      </div>
      <div v-if="exercise.activitysectionexercisetimerSet.length > 0">
        <div
          v-for="(activitySectionTimer, index) in exercise.activitysectionexercisetimerSet"
          :key="`timer_${activitySectionTimer.id}`"
        >
          <countdown-timer
            class="ml-1"
            :class="{ 'mr-n2': $vuetify.breakpoint.smAndDown }"
            v-if="timerVisible(index)"
            @end="timerEnded(index)"
            :autostart="isAutoStart(index)"
            :title="activitySectionTimer.title"
            :seconds="activitySectionTimer.seconds"
            @rewind="
              timerEnded(index, false);
              preventAutoStart = true;
            "
            @forward="
              timerEnded(index);
              preventAutoStart = true;
            "
            :index="index"
            :timer-count="exercise.activitysectionexercisetimerSet.length"
          />
        </div>
      </div>
    </div>
    <div v-if="$vuetify.breakpoint.smAndDown && exercise.longDescription" class="ml-4 pl-3 pb-3 sm-text">
      {{ exercise.longDescription }}
    </div>
  </div>
</template>

<script>
import CountdownTimer from "@/components/shared/CountdownTimer";
import ActivityExerciseImage from "@/components/workoutblocks/cards/ActivityExerciseImage";
import ExercisePanelMixin from "@/mixins/ExercisePanelMixin";

export default {
  name: "ActiveExercisePanel",
  components: {
    CountdownTimer,
    ActivityExerciseImage,
  },
  mixins: [ExercisePanelMixin],
  data() {
    return {
      currentTimerIndex: 0,
      isResting: false,
      preventAutoStart: false,
    };
  },
  created() {
    this.$bus.$on("timerStarted", () => {
      // reset auto start to default whenver a timer starts.
      this.preventAutoStart = false;
    });
  },
  methods: {
    isAutoStart(index) {
      if (this.preventAutoStart) {
        return false;
      }
      return index !== 0 || this.exercise.isAutoStart;
    },
    timerVisible(index) {
      return this.currentTimerIndex === index;
    },
    timerEnded(index, increment = true) {
      let completed;
      if (increment) {
        completed = index + 1;
        this.currentTimerIndex = index + 1;
      } else {
        completed = index - 1;
        this.currentTimerIndex = index - 1;
      }

      if (completed === this.exercise.activitysectionexercisetimerSet.length) {
        this.$emit("exercise-completed", this.exercise.id, true, true);
      } else {
        let currentTimer = this.exercise.activitysectionexercisetimerSet[completed];
        this.isResting = currentTimer.isRest;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.long-description ::v-deep {
  p {
    font-size: 13px !important;
  }
}
</style>
