import { UPDATE_PROFILE_PICTURE_MUTATION } from "@/graphql/mutations/account";
import { dataURLtoBlob, blobToFile } from "@/lib/file";
export default {
  data() {
    return {
      imageLoading: false,
      selectedImgSrc: null,
      cropperDialog: false,
      savingProfilePicture: false,
    };
  },
  methods: {
    change({ canvas }) {
      this.data.image = canvas.toDataURL();
    },
    clickUploader(type) {
      this.imageType = type;
      document.getElementById("image-uploader").click();
    },
    setImage(output) {
      this.cropperDialog = true;
      this.selectedImgSrc = output.dataUrl;
    },
    async saveProfileImage() {
      this.cropperDialog = false;
      this.imageLoading = true;
      this.savingProfilePicture = true;
      let blob = dataURLtoBlob(this.data.image);
      let file = blobToFile(blob);
      const response = await this.$apollo.mutate({
        mutation: UPDATE_PROFILE_PICTURE_MUTATION,
        variables: {
          file: file,
        },
      });

      this.savingProfilePicture = false;
      this.data.image = null;
      this.selectedImgSrc = null;
      if (response.data.updateProfileImage.ok) {
        let url = response.data.updateProfileImage.profilePictureUrl;
        this.$store.dispatch("updateProfileImage", { url });

        await this.$apollo.queries.me.refetch();
        this.imageLoading = "";
      } else {
        this.$toasted.error("Unable to upload photo.", {
          icon: "alert",
          duration: 2000,
          position: "bottom-center",
        });
      }
      // little sugar to spin a little bit longer while the image loads. No harm. It then lines up nicely.
      setTimeout(() => (this.imageLoading = false), 250);
    },
  },
};
