<template>
  <div class="d-flex">
    <v-menu
      ref="datepicker"
      v-model="datepicker"
      :close-on-content-click="false"
      transition="scale-transition"
      min-width="290px"
    >
      <template v-slot:activator="{ on, attrs }">
        <j-text-field prepend-inner-icon="mdi-calendar" v-model="date" readonly v-bind="attrs" v-on="on" dense />
      </template>
      <v-date-picker
        ref="picker"
        color="navy"
        v-model="date"
        :min="$moment().format('YYYY-MM-DD')"
        @change="saveDate"
      ></v-date-picker>
    </v-menu>
    <j-select
      v-if="date"
      v-model="time"
      :items="times"
      dense
      hide-details
      @input="saveTime"
      class="ml-2"
      style="max-width:145px;"
    />
  </div>
</template>

<script>
export default {
  name: "DateTimePicker",
  data() {
    return {
      datepicker: false,
      date: null,
      time: null,
    };
  },
  computed: {
    times() {
      const desiredStartTime = "00:00";
      const interval = 30;
      const period = "m";
      const periodsInADay = this.$moment.duration(1, "day").as(period);

      let times = [];
      const startTime = this.$moment(desiredStartTime, "hh:mm");
      for (let i = 0; i <= periodsInADay; i += interval) {
        startTime.add(i === 0 ? 0 : interval, period);
        // if the selected date is today, the don't allow times already past
        if (this.date === this.$moment().format("YYYY-MM-DD")) {
          if (startTime.format("HH") <= this.$moment().format("HH")) {
            continue;
          }
        }
        times.push({ value: startTime.format("HH:mm"), text: startTime.format("hh:mm A") });
      }

      // remove elements to prevent end user confusion
      return times.slice(1, -1);
    },
  },
  methods: {
    saveDate(date) {
      this.$refs.datepicker.save(date);
      this.time = this.times[0]?.value
      this.onChanged();
    },
    saveTime() {
      this.onChanged();
    },
    onChanged() {
      if (this.date && this.time) {
        const dateTime = `${this.date} ${this.time}`;
        this.$emit("changed", dateTime);
      }
    },
  },
};
</script>
