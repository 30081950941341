<template>
  <div>
    <j-banner-container
      v-if="workoutBlockUnitActivity"
      :header="headerText"
      :gradient="['green-background', 'yellow-background']"
    >
      <div class="pa-4" align="left">
        <div class="d-flex align-center mb-2">
          <v-icon class="mr-2" large :color="accentColor">{{
            workoutBlockWorkoutLog ? "fiber_manual_record" : "radio_button_unchecked"
          }}</v-icon>
          <h2>{{ workoutBlockUnitActivity.activity.title }}</h2>
        </div>
        <user-avatar
          :profile-picture="workoutBlockUnitActivity.activity.leadInstructor.userProfile.profilePicture"
          :user="workoutBlockUnitActivity.activity.leadInstructor"
        />
        <span class="std-text ml-2">{{ subtitle }}</span>

        <div class="d-flex justify-space-between align-center mt-2">
          <div
            v-if="
              workoutBlockSelfPaced &&
              workoutBlockUnitActivity.currentActivity &&
              workoutBlockUnitActivity.unit.block.totalWorkouts
            "
            class="exercise--text"
          >
            Session {{ workoutBlockUnitActivity.currentActivity }} of
            {{ totalBlockActivities }}
          </div>
          <div
            v-else-if="workoutBlockUnitActivity.currentDay && workoutBlockUnitActivity.unit.block.duration"
            :class="`${accentColor}--text`"
          >
            Day {{ workoutBlockUnitActivity.currentDay }} of
            {{ workoutBlockUnitActivity.unit.block.duration }}
          </div>

          <div class="d-flex">
            <j-btn :color="accentColor" @click="logActivity">
              <span v-if="!loadingDot" class="white--text">
                <span v-if="!workoutBlockWorkoutLog">Log</span>
                <span v-else>Update</span> Dot
              </span>
              <v-progress-circular v-else indeterminate :size="20" color="white" />
            </j-btn>
            <v-icon class="ml-2 pointer" v-if="workoutBlockWorkoutLog" @click="deleteWorkoutLog()">
              mdi-trash-can-outline
            </v-icon>
            <manage-bookmark
              v-if="bookmark && bookmark.objectId"
              :objectId="bookmark.objectId"
              :objectType="bookmark.objectType"
              :objectNotes="bookmark.objectNotes"
              :bookmarkId="bookmark.bookmarkId"
              :refreshQuery="bookmark.refreshQuery"
            />
          </div>
        </div>

        <j-alert v-if="blockComplete" type="success" class="my-4">
          Well done on logging the <strong>last Workout</strong> of your block! If you're looking for the next step we
          would highly recommend taking a look at
          <router-link class="font-weight-bold green--text text-decoration-underline" to="/journey"
            >your journey</router-link
          >.
        </j-alert>

        <!-- -------------------------- -->
        <!-- main activity content: activity and alternate activity loaded.  -->
        <div v-if="hasActivityAndAlternateActivity" class="mt-2">
          <v-tabs v-model="tab" slider-size="7" height="40" class="pa-2 pl-0 pb-0 d-flex justify-center">
            <v-tabs-slider :style="activeStyle()"></v-tabs-slider>
            <v-tab key="current"> <span :style="activeStyle(0)">@ Gym</span></v-tab>
            <v-tab key="next"> <span :style="activeStyle(1)">@ Home</span></v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab" :touchless="true">
            <v-tab-item key="current">
              <div class="mt-6">
                <activity-content
                  :unit-activity="workoutBlockUnitActivity"
                  :activity="workoutBlockUnitActivity.alternateActivity"
                  :subscription-block-id="subscriptionBlockId"
                />
              </div>
            </v-tab-item>
            <v-tab-item key="next" class="text-left">
              <div class="mt-6">
                <activity-content
                  :unit-activity="workoutBlockUnitActivity"
                  :activity="workoutBlockUnitActivity.activity"
                  :subscription-block-id="subscriptionBlockId"
                />
              </div>
            </v-tab-item>
          </v-tabs-items>
        </div>

        <!-- main activity content: only one activity loaded.  -->
        <activity-content
          v-else
          :unit-activity="workoutBlockUnitActivity"
          :activity="workoutBlockUnitActivity.activity"
          :subscription-block-id="subscriptionBlockId"
        />
        <!-- -------------------------- -->

        <j-avatar-card
          v-if="workoutBlockUnitActivity.workoutLogCount > 0"
          :show-avatar="false"
          class="mt-4"
          :title="`Logged by ${workoutBlockUnitActivity.workoutLogCount} ${
            workoutBlockUnitActivity.workoutLogCount != 1 ? 'people' : 'person'
          }`"
        >
          <template v-slot:trailing>
            <div v-if="usersLoggedDots">
              <j-avatar-list :user-list="usersLoggedDots" />
            </div>
          </template>
        </j-avatar-card>

        <post-container
          ref="postContainer"
          class="mt-4"
          :activity-id="workoutBlockUnitActivity.activity.id"
          :featured-user-ids="[workoutBlockUnitActivity.activity.leadInstructor.id]"
          highlight-colour="#FD89A2"
          hide-post-group-name
          hide-post-link
        />
      </div>
    </j-banner-container>

    <!-- Enhance Dot/Workout Log dialog -->
    <v-dialog
      v-if="workoutBlockUnitActivity"
      v-model="logDotDialog"
      width="600"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <enhance-workout-log
        :activity-id="workoutLogId"
        :subscription-block-id="subscriptionBlockId"
        :unit-activity="workoutBlockUnitActivity"
        @closed="logDotDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import {
  WORKOUT_BLOCK_UNIT_ACTIVITY,
  WORKOUT_BLOCK_WORKOUT_LOG,
  SUBSCRIPTION_BLOCK_SELF_PACED,
} from "@/graphql/queries/workoutblock";

import { DELETE_ACTIVITY_MUTATION } from "@/graphql/mutations/tracker";
import { decodeId, toGraphqlID } from "@/lib/string";
import { gradientStyle } from "@/lib/gradientStyle";
import BadgeToaster from "@/mixins/BadgeToaster";
import UserAvatar from "@/components/shared/user/UserAvatar";
import EnhanceWorkoutLog from "@/components/workoutblocks/dialogs/EnhanceWorkoutLog";
import ActivityContent from "@/components/workoutblocks/ActivityContent";
import ManageBookmark from "@/components/bookmarks/block/ManageBookmark";
import { BOOKMARKS_QUERY } from "@/graphql/queries/bookmark";
import { extractNodes } from "@/lib/array";
import ScreenLockMixin from "@/mixins/ScreenLockMixin";
import PostContainer from "@/components/groups/posts/PostContainer";
import { UPSERT_ACTIVITY_MUTATION } from "@/graphql/mutations/tracker";

export default {
  name: "Activity",
  mixins: [BadgeToaster, ScreenLockMixin],
  components: {
    UserAvatar,
    EnhanceWorkoutLog,
    ActivityContent,
    ManageBookmark,
    PostContainer,
  },
  data() {
    return {
      tab: 0,
      workoutBlockUnitActivity: null,
      workoutBlockWorkoutLog: null,
      subscriptionBlockId: null,
      usersLoggedDots: [],
      logDotDialog: false,
      loadingDot: false,
      loading: false,
      anchorDate: null,
      workoutLogId: null,
      blockComplete: null,
      deletedBookmark: false,
    };
  },
  props: {
    unitActivityId: {
      type: String,
      required: false,
    },
  },
  async created() {
    this.$bus.$on("logDot", () => {
      this.logDot();
    });

    this.$bus.$on("timerStarted", () => {
      this.playFakeVideo();
    });

    if (this.$route.query.sbid) {
      this.subscriptionBlockId = this.$route.query.sbid;
    }
    if (this.$route.query.d) {
      this.anchorDate = this.$route.query.d;
    }
  },
  computed: {
    hasActivityAndAlternateActivity() {
      return !!this.workoutBlockUnitActivity?.activity && this.workoutBlockUnitActivity?.alternateActivity;
    },
    accentColor() {
      // if a nutrition block, brute force to nutrition color.
      if (this.workoutBlockUnitActivity.unit.block.type === "NUTRITION") {
        return "nutrition";
      }
      return this.workoutBlockUnitActivity.type == "WORKOUT" ? "pink" : "yellow";
    },
    totalBlockActivities() {
      return (
        (this.workoutBlockUnitActivity.unit?.block?.totalWorkouts ?? 0) +
        (this.workoutBlockUnitActivity.unit?.block?.totalModules ?? 0) +
        (this.workoutBlockUnitActivity.unit?.block?.totalEds ?? 0) +
        (this.workoutBlockUnitActivity.unit?.block?.totalLiveEventsRestDays ?? 0)
      );
    },
    headerText() {
      if (this.workoutBlockUnitActivity.type == "MODULE") {
        return "Module";
      }
      if (this.workoutBlockUnitActivity.type == "ED") {
        return "Ed";
      }
      return "Session";
    },
    subtitle() {
      let subtitle = `with ${this.workoutBlockUnitActivity.activity.leadInstructor.firstName}`;
      if (this.workoutBlockUnitActivity.activity.durationMinutes) {
        subtitle = `${subtitle} | ${this.workoutBlockUnitActivity.activity.durationMinutes} mins`;
      }
      return subtitle;
    },
    textGradientStyle() {
      return gradientStyle(["pink", "navy"], 90);
    },
    bookmark() {
      let obj = {
        objectId: this.unitActivityId,
        objectType: "unit_activity",
        objectNotes: "",
        refreshQuery: "bookmarks",
      };
      if (this.bookmarks?.length > 0) {
        obj.bookmarkId = decodeId(this.bookmarks[0].id);
        obj.objectNotes = this.bookmarks[0].notes;
      }
      return obj;
    },
  },
  methods: {
    activeStyle(btnIndex = this.tab) {
      return this.tab == btnIndex ? this.textGradientStyle : "";
    },
    logActivity() {
      if (this.workoutBlockWorkoutLog) this.updateDot();
      else this.logDot();
    },
    async updateDot() {
      this.workoutLogId = decodeId(this.workoutBlockWorkoutLog.id);
      await this.$nextTick();
      this.logDotDialog = true;
    },
    async logDot() {
      let input = {
        subscriptionBlockId: decodeId(this.subscriptionBlockId),
        unitActivityId: decodeId(this.unitActivityId),
        startDate: this.workoutBlockWorkoutLog?.startDate ? this.workoutBlockWorkoutLog.startDate : this.anchorDate,
        time: this.$moment().format("HH:mm:ss"),
      };

      let result = await this.$apollo.mutate({
        mutation: UPSERT_ACTIVITY_MUTATION,
        variables: {
          data: input,
        },
        refetchQueries: ["assistantQuery", "accountQuery"],
      });

      let badges = result.data.upsertUserActivity?.upsertedUserActivity.badges;
      if (badges) {
        this.toastBadges(badges);
      }

      const workoutLogId = result.data.upsertUserActivity.upsertedUserActivity.userActivity.id;
      if (workoutLogId) {
        this.workoutLogId = decodeId(workoutLogId);
        this.logDotDialog = true;
        await this.$apollo.queries.workoutBlockWorkoutLog.refetch();
      }
    },
    async deleteWorkoutLog() {
      //this.loadingDot = true;
      await this.$apollo.mutate({
        mutation: DELETE_ACTIVITY_MUTATION,
        variables: {
          id: toGraphqlID("UserActivity", this.workoutBlockWorkoutLog.id),
        },
      });
      // brute force to clear state. Not ideal, but otherwise the dialog form rememers old values. this is only a problem
      // if the user logs a dot, deletes dot and then logs again straight away. works, but form remmbers old values.
      //location.reload();
      await this.$apollo.queries.workoutBlockWorkoutLog.refetch();
      //this.loadingDot = false;
    },
  },
  apollo: {
    bookmarks: {
      query: BOOKMARKS_QUERY,
      fetchPolicy: "network-only",
      variables() {
        return {
          unitActivityId: this.unitActivityId,
        };
      },
      update(data) {
        return extractNodes(data.bookmarks.edges);
      },
    },
    workoutBlockUnitActivity: {
      query: WORKOUT_BLOCK_UNIT_ACTIVITY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          unitActivityId: this.unitActivityId,
        };
      },
      result(response) {
        if (response.data) {
          this.usersLoggedDots = [];
          response.data.workoutBlockUnitActivity.useractivitySet.edges.forEach((edge) => {
            this.usersLoggedDots.push(edge.node.subscriptionBlock.user);
          });
        }
      },
    },
    workoutBlockSelfPaced: {
      query: SUBSCRIPTION_BLOCK_SELF_PACED,
      variables() {
        return {
          id: this.subscriptionBlockId,
        };
      },
      update(data) {
        return data.subscriptionBlock.isSelfPaced ?? false;
      },
      skip() {
        return !this.subscriptionBlockId;
      },
    },
    workoutBlockWorkoutLog: {
      query: WORKOUT_BLOCK_WORKOUT_LOG,
      fetchPolicy: "network-only",
      variables() {
        return {
          subscriptionBlockId: this.subscriptionBlockId,
          unitActivityId: this.unitActivityId,
        };
      },
      skip() {
        return !this.subscriptionBlockId;
      },
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/styles/global.scss";

.v-tab--active span,
.v-tab--active .v-icon {
  @extend .gradient-text;
  font-weight: bold;
}
</style>
