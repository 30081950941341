<template>
  <div>
    <h3>{{ surveyQuestion.question.title }} <span v-if="surveyQuestion.isRequired"> * </span></h3>

    <v-select
      v-model="option"
      return-object
      @change="handleSave(null, option)"
      item-text="title"
      item-value="id"
      :items="surveyQuestion.question.options"
    >
    </v-select>

    <div v-for="child in surveyQuestion.children" :key="child.id" class="my-4">
      <question-block :surveyQuestion="child" />
    </div>
  </div>
</template>

<script>
import SurveyMixin from "@/mixins/SurveyMixin";

export default {
  name: "McqControl",
  mixins: [SurveyMixin],
  props: {
    surveyQuestion: {
      required: true,
    },
  },
  data() {
    return {
      option: null,
    };
  },
};
</script>
