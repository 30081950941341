<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6">
        <h1 class="text-center grey-navy--text mb-3" v-if="!saved">{{ labelsAndText.pageTitle }}</h1>
        <h1 class="text-center grey-navy--text mb-3" v-if="saved">Event Details</h1>
      </v-col>
    </v-row>
    <v-row v-if="!saved && currentStep !== 'start'" align="center" justify="center">
      <v-col cols="12" md="6">
        <j-card class="j-elevated-1">
          <div class="pa-2">
            <a class="subtitle-1 grey-navy--text font-weight-bold text-decoration-none" @click="currentStep = 'start'">
              <v-icon small class="pb-1">
                keyboard_backspace
              </v-icon>
              Edit Previous Page
            </a>
          </div>
          <v-simple-table>
            <tbody>
              <tr>
                <td>Title</td>
                <td>{{ formData.title }}</td>
              </tr>
              <tr>
                <td>Type</td>
                <td>{{ formData.type === "physical" ? "In Person" : "Virtual" }}</td>
              </tr>
              <tr>
                <td>Date</td>
                <td>{{ formData.date + " " + formData.hour + ":" + formData.minute }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </j-card>
      </v-col>
    </v-row>
    <v-row v-if="loading" align="center" justify="center">
      <v-col cols="12" md="6" align="center" justify="center">
        <v-progress-circular :indeterminate="true" color="blue" />
      </v-col>
    </v-row>
    <v-row v-else align="center" justify="center">
      <v-col cols="12" md="6">
        <div v-if="!saved">
          <j-card v-if="currentStep === 'start'" class="px-5 py-8 j-elevated-1">
            <validation-observer ref="observerPartOne">
              <v-row class="mb-2">
                <v-col cols="12" class="py-0">
                  <span class="secondary--text font-weight-bold">EVENT NAME</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-0">
                  <ValidationProvider rules="required" name="Name" v-slot="{ errors }">
                    <j-text-field v-model="formData.title" :error-messages="errors" placeholder="Meetup Name">
                    </j-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row class="mb-2">
                <v-col cols="12" class="py-0">
                  <span class="secondary--text font-weight-bold">EVENT TYPE</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-select
                    class="title-case"
                    selected="physical"
                    v-model="formData.type"
                    :items="options.types"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row class="mb-2">
                <v-col cols="12" class="py-0">
                  <span class="secondary--text font-weight-bold">EVENT DATE</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-0">
                  <v-menu
                    ref="menu"
                    v-model="datePickerModal"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <ValidationProvider mode="lazy" name="Event Date" rules="required" v-slot="{ errors, valid }">
                        <j-text-field
                          name="Date"
                          v-model="formData.date"
                          label="Select Date"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          :error-messages="errors"
                          :success="valid"
                          v-bind="attrs"
                          v-on="on"
                        ></j-text-field>
                      </ValidationProvider>
                    </template>
                    <v-date-picker
                      ref="picker"
                      v-model="formData.date"
                      :min="minStartDate"
                      @change="saveDate"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row class="mb-2">
                <v-col cols="12" class="py-0">
                  <span class="secondary--text font-weight-bold">EVENT TIME</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4" class="py-0">
                  <ValidationProvider name="Hour" rules="required" v-slot="{ errors, valid }">
                    <v-select
                      class="title-case"
                      selected="physical"
                      v-model="formData.hour"
                      :items="hours"
                      :error-messages="errors"
                      :success="valid"
                      label="Hour"
                    ></v-select>
                  </ValidationProvider>
                </v-col>
                <v-col cols="4" class="py-0">
                  <ValidationProvider name="Minute" rules="required" v-slot="{ errors, valid }">
                    <v-select
                      class="title-case"
                      selected="physical"
                      v-model="formData.minute"
                      :items="minutes"
                      label="Minute"
                      :error-messages="errors"
                      :success="valid"
                    ></v-select>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="11" align="center" justify="center" class="my-0 py-0">
                  <j-btn large wide :loading="saving" @click="nextStep()">
                    Next
                  </j-btn>
                </v-col>
              </v-row>
            </validation-observer>
          </j-card>
          <j-card v-if="currentStep !== 'start'" class="px-5 py-8 j-elevated-1">
            <validation-observer ref="observerPartTwo">
              <div v-if="currentStep === 'physical'">
                <v-row class="mb-2">
                  <v-col cols="12" class="py-0">
                    <span class="secondary--text font-weight-bold">LOCATION</span><br />
                    <span class="grey-navy--text">
                      Hosting events in public spaces is preferable for your safety and security
                    </span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <gmap-autocomplete @place_changed="setLocation" autocomplete="off">
                      <template v-slot:input="slotProps">
                        <ValidationProvider name="billing_location" v-slot="{ errors }">
                          <v-text-field
                            v-model="formData.tempLocation"
                            name="location"
                            dense
                            outlined
                            rounded
                            prepend-inner-icon="place"
                            placeholder="Location Of Event"
                            :error-messages="errors"
                            ref="input"
                            v-on:listeners="slotProps.listeners"
                            v-on:attrs="slotProps.attrs"
                            autocomplete="off"
                            list="autocompleteOff"
                          >
                          </v-text-field>
                        </ValidationProvider>
                      </template>
                    </gmap-autocomplete>
                  </v-col>
                </v-row>
                <v-row class="mb-2">
                  <v-col cols="12" class="py-0">
                    <span class="secondary--text font-weight-bold">MAXIMUM ATTENDEES</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <j-text-field
                      v-model="formData.capacity"
                      type="number"
                      @wheel="$event.target.blur()"
                      max="100"
                      min="1"
                      placeholder="Capacity"
                    >
                    </j-text-field>
                  </v-col>
                </v-row>
              </div>
              <div v-if="currentStep === 'virtual'">
                <v-row class="mb-2">
                  <v-col cols="12" class="py-0">
                    <span class="secondary--text font-weight-bold">MEETING LINK</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <ValidationProvider rules="required|url" name="Meeting Link" v-slot="{ errors }">
                      <j-text-field v-model="formData.meetingLink" :error-messages="errors" placeholder="Meeting Link">
                      </j-text-field>
                    </ValidationProvider>
                  </v-col>
                </v-row>
                <v-row class="mb-2">
                  <v-col cols="12" class="py-0">
                    <span class="secondary--text font-weight-bold">MAXIMUM ATTENDEES</span>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" class="py-0">
                    <j-text-field
                      v-model="formData.capacity"
                      type="number"
                      @wheel="$event.target.blur()"
                      max="100"
                      min="1"
                      placeholder="Capacity"
                    >
                    </j-text-field>
                  </v-col>
                </v-row>
              </div>
              <v-row class="mb-2">
                <v-col cols="12" class="py-0">
                  <span class="secondary--text font-weight-bold">EVENT INFORMATION</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-0">
                  <j-textarea
                    v-model="formData.notes"
                    counter
                    label="Information about your event"
                    :rules="[(v) => v.length <= 300 || 'Max 300 characters']"
                  ></j-textarea>
                </v-col>
              </v-row>
              <v-row class="mb-2">
                <v-col cols="12" class="py-0">
                  <span class="secondary--text font-weight-bold">WHATSAPP NUMBER</span>
                  <p>Attendees can see your number to contact you</p>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-0">
                  <ValidationProvider name="Contact Number" rules="required|phone_number" v-slot="{ errors }">
                    <j-text-field
                      v-model="formData.contactNumber"
                      :error-messages="errors"
                      placeholder="Contact Number"
                    >
                    </j-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
              <v-row align="center" justify="center">
                <v-col cols="11" align="center" justify="center" class="my-0 py-0">
                  <j-btn large :loading="saving" @click="submit()">
                    <strong>{{ labelsAndText.submitButtonText }}</strong>
                  </j-btn>
                </v-col>
              </v-row>
            </validation-observer>
          </j-card>
        </div>
        <div v-else>
          <MeetingResult :meeting-details="formData" :newMeetup="!this.id" :createdID="createdId"></MeetingResult>
        </div>
      </v-col>
    </v-row>
    <v-dialog v-model="confirmDialog" persistent width="500">
      <j-card class="j-elevated-1">
        <v-card-title class="headline grey lighten-2">
          Terms and Conditions
        </v-card-title>
        <v-card-text>
          <strong>I agree to:</strong>
          <ul>
            <li>Organise this event for the benefit of all attendees.</li>
            <li>Host the event at the host's own risk.</li>
            <li>Wear a mask and observe safe social distancing.</li>
            <li>Ensure that the event area is well ventilated.</li>
          </ul>
          <br />
          <p>By tapping 'I accept' you accept the Terms and Conditions.</p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer />
          <j-btn tertiary @click="confirmDialog = false">
            No Thanks
          </j-btn>
          <j-btn secondary @click="createMeetup()">
            I accept
          </j-btn>
        </v-card-actions>
      </j-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { MEETUP_QUERY } from "@/graphql/queries/communityFitness";
import { UPSERT_MEETUP_MUTATION } from "@/graphql/mutations/communityFitness";
import { dateAndTimeToUTC } from "@/lib/timezone.js";
import MeetingResult from "@/components/community-fitness/MeetingResult";

export default {
  name: "UpsertCommunityFitness",
  components: {
    MeetingResult,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      meetup: {},
      saving: false,
      saved: false,
      currentStep: "start",
      datePickerModal: false,
      confirmDialog: false,
      createdId: null,
      options: {
        types: [
          {
            text: "In Person",
            value: "physical",
          },
          {
            text: "Virtual",
            value: "virtual",
          },
        ],
      },
      formData: {
        title: "",
        category: "exercise",
        type: "physical",
        date: "",
        hour: null,
        minute: null,
        location: "",
        googlePlaceId: null,
        latitude: null,
        longitude: null,
        capacity: 1,
        notes: "",
        contactNumber: "",
        meetingLink: "",
        tempLocation: "",
      },
    };
  },
  computed: {
    labelsAndText() {
      return {
        pageTitle: this.id ? "Update Event" : "Create Event",
        submitButtonText: this.id ? "Update Event" : "Create Event",
      };
    },
    minStartDate() {
      return this.$moment()
        .toISOString()
        .substr(0, 10);
    },
    hours() {
      return [...Array(24).keys()].map((val) => {
        return val.toString().padStart(2, "0");
      });
    },
    minutes() {
      return [...Array(12).keys()].map((val) => {
        val = val * 5;
        return val.toString().padStart(2, "0");
      });
    },
  },
  mounted() {
    if (this.id) this.loading = true;
  },
  methods: {
    saveDate(date) {
      this.$refs.menu.save(date);
    },
    async nextStep() {
      const valid = await this.$refs.observerPartOne.validate();
      if (valid) {
        this.currentStep = this.formData.type;

        //hack: refs are not reactive and the reference
        //does not exist in the VUE object yet
        //so we need to wait for the next frame so the ref exists
        setTimeout(() => {
          this.$refs.observerPartTwo.reset();
        }, 1);
      }
    },
    setLocation(location) {
      this.formData.googlePlaceId = location.place_id;
      this.formData.location = location.formatted_address;
      this.formData.latitude = location.geometry.location.lat();
      this.formData.longitude = location.geometry.location.lng();
      this.formData.tempLocation = location.formatted_address;
    },
    transformMeetupData() {
      //transform the simple fields
      let directCopyFields = [
        "title",
        "capacity",
        "notes",
        "latitude",
        "longitude",
        "location",
        "googlePlaceId",
        "contactNumber",
      ];
      let lowerCaseCopyFields = ["type", "category"];
      directCopyFields.forEach((field) => (this.formData[field] = this.meetup[field]));
      lowerCaseCopyFields.forEach((field) => (this.formData[field] = this.meetup[field].toLowerCase()));

      //copy the location to templocation to show the location name
      this.formData.tempLocation = this.meetup.location;

      //transform the date
      let dateObj = this.$moment(this.meetup.eventDate).local(true);
      this.formData.date = dateObj.format("YYYY-MM-DD");
      this.formData.hour = dateObj.format("HH");
      this.formData.minute = dateObj.format("mm");
    },
    async submit() {
      let valid = await this.$refs.observerPartTwo.validate();

      if (!this.formData.googlePlaceId && this.formData.type === "physical") {
        this.$refs.observerPartTwo.setErrors({
          location: ["Please select your location"],
        });
        valid = false;
      }

      if (!valid) return;

      this.confirmDialog = true;
    },
    createMeetup() {
      this.confirmDialog = false;
      this.saving = true;

      let submitData = JSON.parse(JSON.stringify(this.formData));
      ["hour", "minute", "tempLocation"].forEach((val) => {
        delete submitData[val];
      });
      submitData.date = dateAndTimeToUTC(this.formData.date, this.formData.hour, this.formData.minute);

      if (this.id) submitData.id = atob(this.id).split(":")[1];

      if (
        submitData.type === "virtual" &&
        submitData.meetingLink !== "" &&
        submitData.meetingLink.toLowerCase().substr(0, 4) !== "http"
      ) {
        submitData.meetingLink = "http://" + submitData.meetingLink;
      }

      this.$apollo
        .mutate({
          mutation: UPSERT_MEETUP_MUTATION,
          variables: {
            input: submitData,
          },
        })
        .then((response) => {
          if (response.data) {
            if (response.data.upsertMeetup.success) {
              this.saved = true;
              this.createdId = response.data.upsertMeetup.meetup.id;
            } else {
              alert(response.data.upsertMeetup.error);
            }
            this.saving = false;
          }
        })
        .catch((error) => {
          this.saving = false;
          this.error = true;
          throw error;
        });
    },
  },
  apollo: {
    meetup: {
      query: MEETUP_QUERY,
      fetchPolicy: "network-only",
      result(response) {
        if (response.data) {
          //check permissions
          if (parseInt(response.data.meetup.host.id) !== parseInt(this.$store.getters.user.id)) {
            this.$router.push("/404");
          }
          this.loading = false;
          this.transformMeetupData();
        }
      },
      variables() {
        return { id: atob(this.id).split(":")[1] };
      },
      skip() {
        return !this.id;
      },
    },
  },
};
</script>

<style scoped></style>
