export default {
  methods: {
    replaceTemplateTags(content) {
      let user = this.$store.getters.user;
      let replacements = {
        "#NAME": user.firstName ? user.firstName : '',
        "#SURNAME": user.lastName ? user.lastName : ''
      }
      for (let tag in replacements) {
        let search_term = new RegExp(tag, "gi");
        content = content.replace(search_term, replacements[tag]);
      }
      return content;
    }
  }
}