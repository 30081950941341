<template>
  <div>
    <router-link v-if="title" elevation="0" :to="link" class="mt-2 text-decoration-none">
      <v-card-title class="p-text justify-center py-2">
        {{ title }}
      </v-card-title>
    </router-link>
    <v-row justify="center" align="center">
      <v-col
        v-for="option in optionList"
        :key="option.value"
        cols="4"
        class="option-hover"
        align="center"
        @click.prevent="selectValue(option.value)"
      >
        <v-icon :color="type || option.color">
          {{ currentValue == option.value ? "mdi-circle" : "mdi-circle-outline" }}
        </v-icon>
        <span class="block p-text">{{ option.text }}</span>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UPSERT_REFLECTION_MUTATION } from "@/graphql/mutations/tracker";
import BadgeToaster from "@/mixins/BadgeToaster";

export default {
  name: "LoggingSelectionButton",
  mixins: [BadgeToaster],
  props: {
    title: {
      type: String,
      required: false,
    },
    link: {
      type: String,
      required: false,
    },
    optionList: {
      type: Array,
      required: true,
    },
    type: {
      type: String,
      required: false,
    },
    value: {
      type: [Number, String, Boolean],
      default: 75,
    },
  },
  data() {
    return {
      currentValue: this.value,
    };
  },
  computed: {
    ...mapGetters(["selectedDate"]),
  },
  methods: {
    selectValue(value) {
      this.currentValue = value;
      if (this.type) {
        this.update();
      }
      this.$emit("selected", value);
    },
    formattedDate() {
      return this.selectedDate ? this.selectedDate.format("YYYY-MM-DD") : this.$moment().format("YYYY-MM-DD");
    },
    earned() {
      let selected = this.optionList.find((option) => option.value == this.currentValue);
      if (selected) return selected.filled;

      return false;
    },
    update() {
      let input = {
        date: this.formattedDate(),
      };
      if (this.type == "hydration") {
        input["hydration"] = this.currentValue;
        input["hydrationCompliant"] = this.earned();
      }

      if (this.type == "nutrition") {
        input["nutrition"] = this.currentValue;
        input["nutritionCompliant"] = this.earned();
      }

      if (this.type == "mindset") {
        input["mindset"] = this.currentValue;
        input["mindsetCompliant"] = this.earned();
      }

      this.$apollo
        .mutate({
          mutation: UPSERT_REFLECTION_MUTATION,
          variables: {
            input: input,
          },
          refetchQueries: ["activitiesQuery", "weeklyTrackerSummary"],
        })
        .then((response) => {
          if (response.data) {
            this.saving = false;
            let badges = response.data.upsertReflectionLogs?.earnedBadges;
            if (badges) {
              this.toastBadges(badges);
            }
          }
        });
    },
  },
};
</script>

<style scoped>
.option-hover:hover {
  background-color: var(--v-background-grey-base);
  cursor: pointer;
}
</style>
