<template>
  <j-card rounded="lg">
    <v-toolbar class="mb-3">
      <v-toolbar-title class="navy--text headline">Reviews </v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="$emit('closed')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text v-if="reviews.length > 0" class="h-500">
      <div class="pt-0 pb-0" v-for="(review, index) in reviews" :key="index">
        <v-rating
          class="star-rating-icons"
          background-color="yellow lighten-1"
          color="yellow"
          empty-icon="mdi-star-outline"
          full-icon="mdi-star"
          half-icon="mdi-star-half-full"
          readonly
          length="5"
          :size="25"
          :value="review.rating"
        ></v-rating>
        <p class="p-text info-text font-weight-bold ml-2">
          {{ review.user.firstName }} - {{ reviewDate(review.createdAt) }}
        </p>
        <p v-html="review.comment" class="p-text info-text ml-2"></p>
        <v-divider class="mb-5" />
      </div>
      <v-card-actions>
        <v-btn v-if="hasNextPage" color="success" elevation="4" rounded @click="showMore">
          <span class="pl-2 pr-2">SHOW MORE</span>
        </v-btn>
        <v-btn v-if="userCanRate" color="primary" elevation="4" rounded @click="$emit('addReview')">
          <span class="pl-2 pr-2">ADD REVIEW</span>
        </v-btn>
      </v-card-actions>
    </v-card-text>
    <v-card-text v-else>
      <div class="pb-4 my-5">
        <j-alert>No reviews yet</j-alert>
        <v-btn color="primary" elevation="4" rounded @click="$emit('addReview')" class="mt-4">
          ADD REVIEW
        </v-btn>
      </div>
    </v-card-text>
  </j-card>
</template>

<script>
export default {
  name: "RatingList",
  data() {
    return {};
  },
  props: {
    reviews: {
      type: Array,
      required: false,
      default: null,
    },
    hasNextPage: {
      type: Boolean,
      required: false,
    },
    userCanRate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getSize() {
      return this.$vuetify.breakpoint.smAndDown ? 30 : 53;
    },
  },
  methods: {
    reviewDate(date) {
      return this.$moment.utc(date).format("DD MMM YYYY");
    },
    showMore() {
      this.$emit("showMore", true);
    },
  },
};
</script>
<style scoped>
.h-500 {
  height: 500px;
}
</style>
