<template>
  <v-row align="center" justify="center">
    <v-col class="pb-0 relative mt-n2 mb-4 calendar-dots">
      <v-icon v-if="showArrows" color="secondary" @click="updateWeek(-1)" class="navigate-week left">
        keyboard_arrow_left
      </v-icon>
      <v-icon v-if="showArrows" @click="updateWeek(1)" color="secondary" class="navigate-week right">
        keyboard_arrow_right
      </v-icon>
      <v-tabs
        v-model="dayTab"
        center-active
        :centered="true"
        :icons-and-text="true"
        color="secondary"
        slider-size="0"
        :height="showWeekDay ? 100 : 72"
      >
        <v-tab
          v-for="(key, index) in days"
          :key="index"
          :icons-and-text="true"
          class="py-2 mb-0 pl-1 pr-1 pr-sm-2 day-tab"
          align="start"
          @click.native="changeDate(index)"
        >
          <div class="d-flex mw-30 pt-1 flex-wrap">
            <v-icon v-if="isRestDay(key.date)" color="rest" class="notification-icon" x-small>
              fiber_manual_record
            </v-icon>
            <v-icon v-if="isLoggedDay(key.date)" :color="loggedDayColour(key.date)" class="notification-icon" x-small>
              fiber_manual_record
            </v-icon>
            <v-icon
              v-for="activity in getActivities(key.date)"
              color="exercise"
              :key="activity.id"
              class="notification-icon"
              x-small
            >
              fiber_manual_record
            </v-icon>
          </div>
          <v-avatar
            class="elevation-2 caption navy--text"
            :class="[
              dayTab == index ? accentColor : '',
              { 'today-border': formatDate(key.date) == formatDate(new Date()) },
            ]"
            :style="borderColorStyle"
            size="30"
            outlined
          >
            <span :class="{ 'white--text': dayTab == index }">
              {{ key.date.getDate() }}
            </span>
          </v-avatar>
          <div v-if="showWeekDay" class="pb-3">
            {{ getDay(key.date) }}
          </div>
        </v-tab>
      </v-tabs>
      <v-row v-if="showSelectedDate">
        <v-col class="pb-2 text-center">
          <span class="grey-navy--text p-text">
            {{ getSelectedDate }}
          </span>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import CalendarDotMixin from "@/mixins/CalendarDotMixin";

export default {
  name: "BlockCalendarDots",
  mixins: [CalendarDotMixin],
  props: {
    accentColor: {
      type: String,
      required: false,
      default: "exercise",
    },
    activityLogs: {
      type: Array,
      required: false,
    },
    // custom dots
    activities: {
      type: Array,
      required: false,
    },
    restDays: {
      type: Array,
      required: false,
    },
    showArrows: {
      type: Boolean,
      default: true,
    },
    showSelectedDate: {
      type: Boolean,
      default: true,
    },
    nextWeek: {
      type: Boolean,
      default: false,
    },
    showWeekDay: {
      type: Boolean,
      default: false,
    },
    forceLoggedDayColour: {
      type: String,
      required: false,
    },
  },
  created() {
    if (this.nextWeek) {
      this.updateWeek(1);
    }
  },
  methods: {
    getActivities(date) {
      if (this.activities) {
        return this.activities.filter((activity) => {
          return (
            activity.node.startDate.substr(0, 10) === this.formatDate(date) &&
            activity.node.type?.category == "EXERCISE"
          );
        });
      } else {
        return [];
      }
    },
    changeDate(index) {
      this.dayTab = index;
      let newDate = this.$moment(this.week, "YYYY-MM-DD")
        .startOf("week")
        .add(index + 1, "days");
      this.$emit("dateUpdated", newDate);
    },
    setDay() {
      var today = new Date();
      var day = today.getDay();
      this.dayTab = day - (day == 0 ? -6 : 1);
    },
    isLoggedDay(date) {
      if (!this.activityLogs) {
        return false;
      }
      let formattedDate = this.formatDate(date);
      return this.activityLogs.some((log) => log.node.date == formattedDate);
    },
    loggedDayColour(date) {
      if (this.forceLoggedDayColour) {
        return this.forceLoggedDayColour;
      }
      let formattedDate = this.formatDate(date);
      const log = this.activityLogs.find((log) => log.node.date == formattedDate);
      if (log.node.unitActivity?.type === "WORKOUT") {
        return "exercise";
      }
      if (log.node.meal) {
        return "green";
      }
      return "yellow";
    },
    isRestDay(date) {
      let formattedDate = this.formatDate(date);
      if (!this.restDays) {
        return false;
      }
      return this.restDays.some((day) => day.restDayDate == formattedDate && day.didRest);
    },
  },
};
</script>
