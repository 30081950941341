import gql from 'graphql-tag'



export const UPDATE_CHECK_IN_MUTATION = gql`
mutation updateUserCheckIn($id: ID!, $userCheckInInput: UserCheckInInput!) {
  updateUserCheckIn(input: { id: $id, userCheckInInput: $userCheckInInput }) {
    userCheckIn {
      id
    }
  }
}
`


export const CREATE_CHECK_IN_MUTATION = gql`
mutation createUserCheckIn($userCheckInInput: UserCheckInInput!) {
  createUserCheckIn(userCheckInInput: $userCheckInInput) {
    originalServingSize
    newServingSize
    userCheckIn {
      id
      abdomen
		}
	}
}
`

export const DELETE_CHECK_IN_MUTATION = gql`
mutation deleteUserMutation($id: ID!) {
  deleteUserCheckIn(id: $id) {
    ok
  }
}
`