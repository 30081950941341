<template>
  <div>
    <v-btn
      icon
      large
      elevation="5"
      class="white"
      @click="!$store.getters.user ? $router.push('/login') : (showDialog = true)"
    >
      <v-icon size="20" color="navy">
        mdi-link-variant
      </v-icon>
    </v-btn>

    <v-dialog v-model="showDialog" max-width="500" transition="dialog-bottom-transition">
      <share-dialog
        :share-object="shareObject"
        :title="`Share page to...`"
        :quote="quote"
        share-with-chats
        @closed="showDialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import ShareDialog from "@/components/shared/dialogs/ShareDialog";

export default {
  name: "ShareLinkDialog",
  components: {
    ShareDialog,
  },
  props: {
    shareName: {
      type: String,
      required: false,
    },
    isPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showDialog: false,
      url: window.location.href,
      hashtags: "jeffclub",
    };
  },
  computed: {
    shareObject() {
      return {
        url: this.url,
        title: `${this.quote} on ${this.$appName}`,
        quote: `${this.quote} on ${this.$appName}`,
        hashtags: this.hashtags,
      };
    },
    quote() {
      return `Check out this ${this.shareName}${this.isPage ? " page" : ""}`;
    },
  },
};
</script>
