import gql from "graphql-tag";

export const SUBSCRIPTION_SURVEY_QUERY = gql`
query subscriptionSurvey($subscriptionSurveyId: ID!) {
  subscriptionSurvey(subscriptionSurveyId: $subscriptionSurveyId) {
    id
    title
    survey {
      id
      title
      description
      surveyQuestions {
        id
        ordering
        isActive
        isRequired
        question {
          id
          title
          isActive
          displayCriteria {
            id
            comparisonType
            value
          }
          parent {
            id
            title
            questionType
            isActive
            displayCriteria {
              id
              comparisonType
              value
            }
            options {
              id
              title
              ordering
            }
          }
          questionType
          isActive
          options {
            id
            title
            ordering
          }
        }
      }
    }
  }
}
`;
