<template>
  <div class="mid-grey--text pa-2">
    <div @click.stop="showNutritionLog = true">
      <v-btn icon elevation="2" class="mr-1">
        <v-avatar v-if="logged" color="green" size="45">
          <v-img v-if="image" :src="image" :lazy-src="image" height="37" max-width="37" />
        </v-avatar>
        <div v-else>
          <v-icon :size="46">
            mdi-plus-circle-outline
          </v-icon>
        </div>
      </v-btn>
      <div class="p-text mt-1">{{ title }}</div>
    </div>

    <v-dialog
      v-if="showNutritionLog"
      v-model="showNutritionLog"
      width="600"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <nutrition-log
        :recipe="recipe ? recipe.recipe : null"
        :blockId="nutritionBlockId"
        :date="date"
        :logged="logged"
        :meal="meal"
        :macros="macros"
        :guidePdf="guidePdf"
        @closed="showNutritionLog = false"
        @nutritionLogId="nutritionLogId = $event;"
      />
    </v-dialog>
  </div>
</template>

<script>
import NutritionLog from "@/components/nutritionblocks/dialogs/NutritionLog";
import ImageSaveMixin from "@/mixins/chats/ImageSaveMixin";

export default {
  name: "CompactNutritionLoggerItem",
  mixins: [ImageSaveMixin],
  components: {
    NutritionLog,
  },
  data() {
    return {
      showNutritionLog: false,
      loading: false,
      nutritionLogId: null,
    };
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    meal: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    recipe: {
      type: Object,
      required: false,
    },
    nutritionBlockId: {
      type: [String, Number],
      required: false,
    },
    logged: {
      type: Boolean,
      required: true,
    },
    image: {
      type: String,
      required: false,
    },
    macros: {
      type: Object,
      required: false
    },
    guidePdf: {
      type: String,
      required: false
    }
  },
};
</script>
