<template>
  <div v-if="hasUserMedia()">
    <v-avatar v-if="!isRecording" color="rgb(0, 0, 0, 0.1)" size="40">
      <v-icon @click="recordVideo()"> mdi-video </v-icon>
    </v-avatar>

    <v-dialog
      v-if="isRecording"
      v-model="showPreviewDialog"
      persistent
      max-width="320"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <j-card class="pa-2 card-radius">
        <v-layout>
          <v-spacer></v-spacer>
          <v-btn icon @click="close()" small>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-layout>

        <!-- nice and small so that it works on small mobile screens -->
        <video :srcObject.prop="videoSrc" width="300" height="220" autoplay playsinline muted></video>

        <div class="d-flex justify-space-between">
          <v-avatar color="rgb(0, 0, 0, 0.1)" size="40">
            <v-icon @click="stop()" color="green">{{ sendIcon }}</v-icon>
          </v-avatar>

          <p class="sm-text mt-3">Recording in progress...</p>

          <v-avatar color="rgb(0, 0, 0, 0.1)" size="40">
            <v-icon @click="close()">mdi-trash-can-outline </v-icon>
          </v-avatar>
        </div>
      </j-card>
    </v-dialog>
  </div>
</template>

<script>
import { blobToFile } from "@/lib/file";
import MediaMixin from "@/mixins/MediaMixin";

export default {
  name: "VideoRecorder",
  mixins: [MediaMixin],
  props: {
    sendIcon: {
      type: String,
      default: "mdi-send",
    },
  },
  data() {
    return {
      recorder: null,
      stream: null,
      chunks: [],
      device: null,
      isRecording: false,
      showPreviewDialog: false,
      videoSrc: null,
    };
  },
  methods: {
    getDevice() {
      this.device = navigator.mediaDevices.getUserMedia({ audio: true, video: true });
    },
    async recordVideo() {
      this.getDevice();
      this.chunks = [];
      this.device.then((stream) => {
        this.stream = stream;
        // play the video as you record.
        this.isRecording = true;
        this.showPreviewDialog = true;
        this.videoSrc = stream;

        // and record as we show the modal.
        this.recorder = new MediaRecorder(stream);
        this.recorder.ondataavailable = async (e) => {
          this.chunks.push(e.data);

          if (this.recorder?.state === "inactive") {
            let blob = new Blob(this.chunks, { type: "video/webm" });
            this.chunks = [];

            const videoFile = blobToFile(blob);
            // we can also upload here via CREATE_MESSAGE_ATTACHMENTS_MUTATION, but rather emit to parent.
            this.$emit("sendVideo", { videoFile: videoFile, time: this.time });
          }
        };
        this.recorder.start();
        this.startTimer();
      });
    },
    async stop() {
      this.recorder.stop();
      this.stopTimer();
      this.isRecording = false;
      this.showPreviewDialog = false;
      if (this.stream) this.stream.getTracks().forEach((track) => track.stop());
    },
    async close() {
      this.stopTimer();
      this.recorder = null;
      if (this.stream) this.stream.getTracks().forEach((track) => track.stop());
      this.stream = null;
      this.chunks = [];
      this.device = null;
      this.isRecording = false;
      this.showPreviewDialog = false;
      this.videoSrc = null;
    },
  },
};
</script>
