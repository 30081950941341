<template>
  <v-card
    class="pl-2 mt-2 mb-2 card-radius"
    style="border: 1px solid #eeeeee"
    @click="handleEventNavigation()"
  >
    <v-card-text class="pl-2 py-2">
      <div v-if="inActive" class="mb-1">
        <open-availability :locked="locked()"/>
      </div>
      <p class="mb-0 py-2 secondary--text font-16">
        <strong>{{ event.name }}</strong>
      </p>
    </v-card-text>
  </v-card>
</template>

<script>
import OpenAvailability from "@/components/shared/blocks/OpenAvailability";

export default {
  name: "AudioClassCard",
  props: {
    event: {
      type: Object,
      default: null,
    },
  },
  components: {
    OpenAvailability
  },
  computed: {
    inActive() {
      return (
        this.$store.getters.role == "INACTIVE" || this.$store.getters.inActive
      );
    },
  },
  methods: {
    handleEventNavigation() {
      if (this.locked()) {
        this.$emit("accessGated");
      } else {
        this.$router.push(`/on-demand/audio/${this.event.id}`);
      }
    },
    locked() {
      if (!this.inActive) {
        return null;
      }

      return !this.event.openAccess;
    },
  },
};
</script>

<style scoped>
.font-16 {
  font-size: 16px;
}
</style>
