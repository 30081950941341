<template>
  <j-main-banner-container header="Consistency">
    <v-row>
      <v-col cols="4">
        <v-select v-model="startDate" :items="startDateRange" label="Start Date" placeholder="Select a start date" />
      </v-col>
      <v-col cols="4">
        <v-select
          v-model="endDate"
          :items="endDateRange"
          :disabled="!startDate"
          label="End Date"
          placeholder="Select a end date"
        />
      </v-col>
      <v-col cols="4">
        <v-select v-model="mode" :items="modeOptions" />
      </v-col>
    </v-row>
    <v-row v-if="startDate && endDate">
      <v-col cols="12" align="left" class="py-0 my-0">
        <p v-if="mode === 'EXERCISE'" class="ml-2">
          <v-avatar class="mt-n1" color="exercise" size="25" /><span class="ml-1">Logged An Exercise</span>
          <v-avatar class="mt-n1 ml-2" color="grey" size="25" /><span class="ml-1">Rest Day</span>
        </p>
        <p v-if="mode === 'NUTRITION'" class="mb-0 ml-2">
          <v-avatar class="mt-n1" color="nutrition" size="25" />
          <span class="ml-2">Nutritious Day</span>
        </p>
        <p v-if="mode === 'MINDSET'" class="mb-0 ml-2">
          <v-avatar class="mt-n1" color="mindset" size="25" />
          <span class="ml-2">Logged a mindset activity</span>
        </p>
      </v-col>
    </v-row>
    <v-container v-if="startDate && endDate" class="mt-0 pt-0">
      <consistency-calendar
        v-for="range in getDateRange(startDate, endDate)"
        :key="range.text"
        :date="range.value"
        :mode="mode"
      >
      </consistency-calendar>
    </v-container>
  </j-main-banner-container>
</template>

<script>
import ConsistencyCalendar from "@/components/stats/ConsistencyCalendar";
import { mapGetters } from "vuex";

export default {
  name: "Consistency",
  components: {
    ConsistencyCalendar,
  },
  data() {
    return {
      mode: "EXERCISE",
      startDate: null,
      endDate: null,
      modeOptions: [
        { text: "Exercise", value: "EXERCISE" },
        { text: "Nutrition", value: "NUTRITION" },
        { text: "Mindset", value: "MINDSET" },
      ],
    };
  },
  mounted() {
    let dateJoined = this.$moment(this.user.dateJoined).set("date", 1);
    let threeMonthsAgo = this.$moment()
      .set("date", 1)
      .subtract(2, "months");
    let thisMonth = this.$moment().set("date", 1);

    this.startDate =
      dateJoined > threeMonthsAgo ? dateJoined.format("YYYY-MM-DD") : threeMonthsAgo.format("YYYY-MM-DD");
    this.endDate = thisMonth.format("YYYY-MM-DD");
  },
  computed: {
    ...mapGetters(["user"]),
    startDateRange() {
      let startDate = this.$moment(this.user.dateJoined).set("date", 1);
      let endDate = this.$moment();
      return this.getDateRange(startDate, endDate);
    },
    endDateRange() {
      let startDate = this.$moment(this.startDate, "YYYY-MM-DD");
      let endDate = this.$moment();
      return this.getDateRange(startDate, endDate);
    },
  },
  methods: {
    getDateRange(startDate, endDate) {
      if (typeof startDate === "string") startDate = this.$moment(startDate, "YYYY-MM-DD");
      if (typeof endDate === "string") endDate = this.$moment(endDate, "YYYY-MM-DD");

      const months = [];
      const flag = startDate;
      while (flag.diff(endDate) <= 0) {
        months.push({
          text: flag.format("MMM YYYY"),
          value: flag.format("YYYY-MM-DD"),
        });
        flag.add(1, "M");
      }
      return months.reverse();
    },
  },
};
</script>
