import { USER_PROFILE_CREATION, UPSERT_DEAL_MUTATION, CREATE_SUBSCRIPTION_MUTATION } from "@/graphql/mutations/account";
import { LINK_CORPORATE_CHALLENGE_MUTATION } from "@/graphql/mutations/account";
import { REGISTER_MUTATION } from "@/graphql/mutations/authentication";
import { USER_TAGS_MUTATION } from "@/graphql/mutations/tag";
import { countryListAlpha2 } from "@/lib/countries";
import VueJwtDecode from "vue-jwt-decode";
import Cookies from "js-cookie";

export default {
  name: "RegistrationForm",
  props: {
    includePassword: {
      type: Boolean,
      default: true,
    },
    requiresEmployeeCode: {
      type: Boolean,
      default: false,
    },
    loginLink: {
      type: String,
      default: "/login",
    },
    submitButton: {
      type: Object,
      default: () => {
        return {
          text: "Get access now",
          color: "secondary",
          textColor: "white",
        };
      },
    },
  },
  data() {
    return {
      userId: null,
      employeeCode: null,
      username: "",
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      suburb: "",
      phone: null,
      errors: null,
      showPassword: false,
      buttonLoading: false,
      terms: false,
      totallyRealFields: {
        name: "",
        surname: "",
      },
      countries: [...countryListAlpha2],
      country: "ZA",
    };
  },
  methods: {
    displayError(message) {
      // this is a django built in error message and unlikely to change.
      if (message == "A user with that username already exists.") {
        if (this.$route.query.code) {
          sessionStorage.setItem("redirectPath", `/checkout/${this.$route.query.code}`);
        }
        return `You already have an account with us. Please <a class="font-weight-bold" href="${this.loginLink}">login</a> to reactivate your account.`;
      }
      return message;
    },
    submit() {
      this.$refs.observer.validate().then((valid) => {
        if (valid) {
          if (this.includePassword && this.password !== this.confirmPassword) {
            this.$toasted.error("Passwords do not match", {
              icon: "alert",
              duration: 2000,
              position: "bottom-center",
            });
            return;
          }
          this.buttonLoading = true;
          this.handleUserCreation();
        }
      });
    },
    handleUserCreation() {
      const username = this.username.toLowerCase();
      let password = this.includePassword ? this.password : Math.random().toString(36).substr(2, 8);

      this.$apollo
        .mutate({
          mutation: REGISTER_MUTATION,
          variables: {
            email: username,
            username: username,
            password1: password,
            password2: password,
          },
        })
        .then((result) => {
          let register = result.data.register;
          this.errors = null;
          if (register.success === true) {
            const accessToken = register.token;
            const tokenPayload = VueJwtDecode.decode(accessToken);
            const refreshToken = tokenPayload.exp;

            this.$store.dispatch("setAuthToken", { accessToken, refreshToken }).then(() => {
              let storedUserData = {};

              if (this.$store.getters.deal) {
                let deal = this.$store.getters.deal;
                storedUserData = {
                  dateOfBirth: deal.birthday,
                  weight: deal.currentWeight,
                  desiredWeight: deal.goalWeight,
                  highestEducation: deal.education,
                  fitnessLevel: deal.fitnessLevel?.toUpperCase(),
                  height: deal.height,
                  biggestObstacle: deal.obstacle,
                  otherObstacleText: deal.obstacleText,
                  otherJoiningInfo: deal.otherText,
                  gender: deal.sex?.toUpperCase(),
                };

                if (deal.goals?.length > 0) {
                  this.handleUserGoals(deal.goals);
                }
              }

              this.handleProfileCreation(storedUserData);
            });
          } else {
            this.errors = register.errors;
            this.buttonLoading = false;
          }
        });
    },
    async handleProfileCreation(storedUserData) {
      const result = await this.$apollo.mutate({
        mutation: USER_PROFILE_CREATION,
        variables: {
          userProfileInput: {
            firstName: this.firstName,
            lastName: this.lastName,
            phone: this.phone,
            country: this.country,
            suburb: this.suburb,
            externalCompanyCode: this.$route.query.ec,
            externalCompanyGuid: this.$route.query.guid,
            externalCompanySite: this.$route.query.site,
            ...storedUserData,
          },
        },
      });
      this.userId = result.data.userProfileCreation.userId;

      /**
       * Careful! defaultProductId must be null in main.js for JEFF, otherwise users get access to the default product without paying!
       */
      if (this.$defaultProductId) {
        await this.$apollo.mutate({
          mutation: CREATE_SUBSCRIPTION_MUTATION,
          variables: {
            input: {
              productId: this.$defaultProductId,
              referralCode: sessionStorage.getItem("referral_code"),
            },
          },
        });
      }

      this.handleDealCreation();
      this.redirectToNext();
    },
    handleUserGoals(goals) {
      this.$apollo.mutate({
        mutation: USER_TAGS_MUTATION,
        variables: {
          tags: goals,
          category: "PRIMARY",
        },
      });
    },
    handleDealCreation() {
      const dealOwnerId = this.$route.query.doid ?? this.$store.getters.dealOwner?.id ?? null;
      const data = {
        dealOwnerId: dealOwnerId,
        userId: this.userId,
        dealTypeCode: "CHECKOUT",
        stageId: 1,
      };
      const utm_campaign = Cookies.get("utm_campaign");
      const utm_medium = Cookies.get("utm_medium");
      const utm_source = Cookies.get("utm_source");

      const attributionId = Cookies.get("attributionId");

      if (utm_campaign) {
        data["attributionCampaign"] = utm_campaign;
        Cookies.remove("utm_campaign");
      }

      if (utm_medium) {
        data["attributionMedium"] = utm_medium;
        Cookies.remove("utm_medium");
      }

      if (utm_source) {
        data["attributionSource"] = utm_source;
        Cookies.remove("utm_source");
      }

      if (attributionId) {
        data["attributionId"] = attributionId;
        Cookies.remove("attributionId");
      }

      this.$apollo
        .mutate({
          mutation: UPSERT_DEAL_MUTATION,
          variables: {
            data: data,
          },
        })
        .then(() => {
          this.$store.dispatch("clearDeal");
        });
    },
    async redirectToNext() {
      let userRole = "INACTIVE";

      window.analytics.identify(this.username, {
        name: this.firstName + " " + this.lastName,
        email: this.username,
        subscription: userRole,
      });

      let user = {
        id: this.userId,
        name: this.firstName + " " + this.lastName,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.username,
      };

      // if not cold checkout, then set the event for Profile Created now. Else, next page will do it.
      if (!this.$store.getters.coldCheckout && !this.$route.params.linkingCode) {
        window.analytics.track("Profile Created", {
          // set in Choose (/start)
          // used to be `1-on-1 coaching`, but we are optimising for Club now?!
          opportunity: this.$store.getters.trackedCheckoutOpportunity || "Club",
        });
      }

      this.$store.dispatch("updateRole", { role: userRole });
      this.$store.dispatch("updateUser", user);

      const path = sessionStorage.getItem("redirectPath");
      if (path) {
        sessionStorage.removeItem("redirectPath");
        return this.$router.push(`${path}`);
      }

      // tell the checkin wizard that the user has registered so that get kicked out of said wizard earlier.
      sessionStorage.setItem("registerCompleted", 1);

      const userId = this.$store.getters.user.id;
      if (userId) {
        this.$OneSignal.setExternalUserId(userId);
      }

      if (this.$route.params.linkingCode) {
        // we need to link user and then redirect the user to the corporate challenge success page
        const result = await this.$apollo.mutate({
          mutation: LINK_CORPORATE_CHALLENGE_MUTATION,
          variables: {
            registrationCode: this.$route.params.linkingCode,
            employeeCode: this.employeeCode,
          },
          refetchQueries: ["accountQuery", "assistantQuery", "notificationQuery"],
        });
        return this.$router.push(
          `/account/link-corporate-challenge/${this.$route.params.linkingCode}?lr=${result.data?.linkUserToCorporateChallenge?.success}&le=${result.data?.linkUserToCorporateChallenge?.error}`
        );
      }

      if (this.$store.getters.coldCheckout) {
        return this.$router.push(`/checkout/${this.$route.query.code}`);
      }

      if (this.$store.getters.goals?.length == 0) {
        return this.$router.push(`/checkin/goals`);
      }

      return this.$router.push(`/checkin/interests`);
    },
  },
  created() {
    if (this.$route.query.code) {
      this.$store.dispatch("updateColdCheckout", true);
    } else {
      this.$store.dispatch("updateColdCheckout", false);
    }

    // get username from querystring ?e if it exists
    if (this.$route.query.e) {
      this.username = this.$route.query.e;
    }

    if (process.env.NODE_ENV === "development") {
      const randomString = Math.random().toString(36).slice(2, 10);

      this.username = `${randomString}@test.com`;
      this.firstName = "Jordy";
      this.lastName = "Test";
      this.password = "Jeff123!";
      this.confirmPassword = "Jeff123!";
      this.phone = "1234567890";
      this.terms = true;
    }
  },
};
