<template>
  <v-container class="py-0 my-0 workout-form">
    <validation-observer v-if="!shared" ref="obs" v-slot="{ valid, errors, pristine, validate }">
      <div
        v-if="
          (activity.type && activity.type.category == 'EXERCISE') || workoutType == 'run' || eventType == 'EXERCISE'
        "
      >
        <div class="text-left pointer">
          <selfie-alert
            :image="activity.image"
            :loading="imageLoading"
            :prize-text="activePrizeText"
            @clickUploader="clickUploader"
            @removeSelfie="removeSelfie"
            @input="selectImg($event, valid)"
          />
        </div>
      </div>
      <div v-if="!$apollo.loading && !saving" class="py-b">
        <j-alert v-if="activePrizeText && submitted" type="warning" :icon="false" rounded="xl" class="text-center">
          <h3 class="mb-2">You're getting 1 entry ☝️</h3>
          <span>Get a <strong>bonus entry</strong> by sharing your photo to a Group or Chat</span>
        </j-alert>
        <share-options
          v-if="
            (activity.type && activity.type.category == 'EXERCISE') ||
              workoutType == 'run' ||
              (eventType == 'EXERCISE' && activity.image)
          "
          :image="activity.image"
          :date="date"
          :activity="eventName"
          :chatData="chatData"
          :with-groups="true"
          class="pt-4"
        />
        <j-checkbox
          v-if="fullForm"
          v-model="showAdditionalStats"
          label="Log Additional Stats"
          hide-details
          class="mt-0 ml-4"
        />
      </div>
      <v-row v-if="fullForm && showAdditionalStats">
        <v-col cols="12" v-if="workoutType.toLowerCase() == 'run' || secondaryType.toLowerCase() == 'run'">
          <j-text-field
            label="Distance"
            dense
            persistent-hint
            hint="KM"
            type="number"
            @wheel="$event.target.blur()"
            :rules="[rules.decimal]"
            v-model="activity.distance"
          />
        </v-col>
        <v-col cols="12" v-if="workoutType.toLowerCase() == 'run' || secondaryType.toLowerCase() == 'run'">
          <j-text-field
            label="Average Pace"
            dense
            persistent-hint
            hint="MIN/KM"
            type="text"
            :rules="[runningRule]"
            v-model="activity.averagePace"
          />
        </v-col>
        <v-col cols="12" class="mb-2">
          <j-input-mask
            v-model="activity.seconds"
            :value="activity.seconds"
            :mask="['##:##:##']"
            masked
            placeholder="Duration"
            suffix="HH:MM:SS or MM:SS"
          />
        </v-col>

        <v-col cols="12">
          <j-text-field
            v-model="activity.calories"
            label="Calories Burned"
            dense
            type="number"
            @wheel="$event.target.blur()"
            persistent-hint
            hint="CALORIES"
          />
        </v-col>
        <v-col cols="12">
          <j-text-field
            v-model="activity.heartRate"
            label="Average Heart Rate"
            dense
            type="number"
            @wheel="$event.target.blur()"
            persistent-hint
            hint="BPM"
          />
        </v-col>
        <v-col cols="12" class="my-0" v-if="mode == 'update' && activity.rating">
          <j-alert dense dismissible type="info" class="ma-20">
            How you felt during the exercise has been replaced by our new effort rating. Try it out below.<br />
            Previous Rating: {{ activity.rating }}
          </j-alert>
        </v-col>
        <v-col cols="12" class="my-0">
          <j-select
            v-model="activity.effort"
            label="Effort You Exerted"
            dense
            @change="updateEffortText"
            :items="ratingLabelsForm"
          />
        </v-col>
        <v-col cols="12" class="text-right pa-0 ma-0" v-if="isFieldVisible().effort()">
          <span class="pa-0 ma-0">{{ ratingLabel }}</span>
        </v-col>
        <v-col cols="12">
          <j-textarea label="Note To Self" v-model="activity.comment" auto-grow rows="2" />
        </v-col>
      </v-row>
      <v-row v-if="showAdditionalStats">
        <v-col cols="12" class="my-0">
          <div class="p-text mb-1">Completed on</div>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <j-text-field v-model="event_date" v-bind="attrs" v-on="on" class="pt-0 mt-0" readonly />
            </template>
            <v-date-picker
              v-if="date"
              v-model="date"
              prepend-inner-icon="mdi-calendar"
              no-title
              scrollable
              @input="menu = false"
            />
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" align="center" justify="center">
          <j-alert v-if="errors && errors.message && errors.message.length > 0" type="error" class="mb-3">
            {{ errors.message[0] }}
          </j-alert>
          <j-alert v-if="!activePrizeText && submitted" type="success">
            {{ submitMessage }}
          </j-alert>
          <div v-if="mode == 'update' && !submitted">
            Your session is saved. If needed, make changes and tap UPDATE.
          </div>
          <j-btn
            secondary
            class="dashboard-button mt-1"
            large
            :loading="saving"
            @click="
              pristine ? validate() : null;
              save(valid);
            "
          >
            {{ userActivityId === "0" ? "Save" : "Update" }}
            <span v-if="chatData.isSharing"> and send</span>
          </j-btn>
        </v-col>
      </v-row>
    </validation-observer>
    <div v-if="!$apollo.loading && shared">
      <successful-entry-alerts v-if="activePrizeText" :active-prize-text="activePrizeText" />
    </div>
  </v-container>
</template>

<script>
import ActivityFormBase from "@/mixins/ActivityFormBase";
import SelfieAlert from "@/components/tracker/activity-form/SelfieAlert";
import SuccessfulEntryAlerts from "@/components/tracker/activity-form/SuccessfulEntryAlerts.vue";

export default {
  name: "ActivityForm",
  mixins: [ActivityFormBase],
  components: {
    SelfieAlert,
    SuccessfulEntryAlerts,
  },
  data() {
    return {
      showAdditionalStats: true,
    };
  },
  computed: {
    eventName() {
      return this.activity.event?.name ?? "";
    },
  },
};
</script>

<style lang="scss">
.workout-form {
  .v-text-field__details {
    position: absolute;
    right: 0;
    margin: 22px;
  }

  .row .col {
    padding-bottom: 0;
  }
}
.feeling {
  width: 100%;
  margin-top: 8px;
}
.workout-status {
  padding: 0px;
  margin: 0px;
  color: #fff;
}
</style>
