export default {
  state: {
    subscriptionSurveyId: null,
    selectedAnswers: []
  },
  mutations: {
    mutateSubscriptionSurveyId(state, { subscriptionSurveyId }) {
      state.subscriptionSurveyId = subscriptionSurveyId;
    },
    mutateAnswer(state, { answer }) {
      // remove the incoming answer and then add it back. Just replacing in the array does not trigger Vuex reactivity. 
      let tempAnswers = state.selectedAnswers.filter(function( el ) {
        return el.questionId != answer.questionId
      });
      // if user is submitting a freetext answer with empty string, it's not valid so don't add it to array. 
      if(answer.answer) {
        tempAnswers.push(answer)
      }
      state.selectedAnswers = tempAnswers; 
    },
  },
  actions: {
    updateSubscriptionSurveyId({ commit }, subscriptionSurveyId) {
      commit("mutateSubscriptionSurveyId", { subscriptionSurveyId });
    },
    replaceAnswer({ commit }, answer) {
      commit("mutateAnswer", { answer });
    }
  },
  getters: {
    subscriptionSurveyId: (state) => state.subscriptionSurveyId,
    selectedAnswers: (state) => state.selectedAnswers,
  },
}