<template>
  <j-main-banner-container class="px-2" header="Chats" :gradient="['green-background', 'yellow-background']">
    <template v-slot:header>
      <span v-if="hasClub">
        <j-btn @click="createChat()" primary :small="!!$vuetify.breakpoint.mdAndDown" class="mr-2">
          Create
          <v-icon class="ml-2">mdi-plus-circle-outline</v-icon>
        </j-btn>
      </span>
    </template>
    <div v-if="$apollo.loading || loading">
      <v-skeleton-loader v-for="count in 4" :key="count" type="list-item-avatar" />
    </div>

    <j-alert v-if="chatAlert" :type="chatAlert.success ? 'success' : 'warning'" class="mx-6 mt-6">
      <span v-if="chatAlert.message" v-html="chatAlert.message" />
    </j-alert>

    <div v-if="!loading && (hasNextPage || (chats && chats.length > 10) || hasFilters)" class="mx-2 d-flex flex-wrap">
      <v-text-field
        rounded
        v-model="searchString"
        height="46"
        hide-details
        placeholder="Search for chats"
        @input="triggerSearch()"
      >
        <template v-slot:prepend-inner>
          <v-icon size="32" class="ml-n4 mr-4 mt-1">mdi-magnify</v-icon>
        </template>
        <template v-slot:append>
          <v-icon
            v-if="searchString"
            size="18"
            class="mt-3"
            @click="
              searchString = '';
              resetFilters();
            "
            >mdi-close</v-icon
          >
        </template>
      </v-text-field>

      <v-switch
        v-model="onlyUnread"
        class="mt-md-6 mt-1 ml-md-0 ml-2 p-text chat-switch"
        label="Unread only"
        color="nutrition"
        hide-details
        @click="
          resetFilters();
          storeOnlyUnread();
        "
      />
    </div>

    <div v-if="chats && chats.length > 0 && !loading" class="px-2 py-4">
      <div v-for="chat in chats" :key="chat.id">
        <chat-listing-card
          v-if="canAccessChat(chat, $store.getters.role)"
          :chat="chat"
          :showSettingsToggle="true"
          :showCard="false"
          @editChat="editChat"
          @leaveChat="leaveChatDialog"
        />
      </div>
    </div>

    <j-btn class="my-6" v-if="hasNextPage && !noChats" small tertiary narrow @click="loadMore"> Load More </j-btn>

    <!-- alerts -->
    <j-alert v-if="!loading && noChats && !hasFilters" class="ma-6"> You are not a part of any chats yet </j-alert>
    <j-alert v-if="!loading && noChats && hasFilters" class="ma-6">
      No Chats match your filters
      <j-btn
        class="d-block mt-4"
        primary
        @click="
          resetFilters();
          searchString = '';
        "
        >Reset Filters</j-btn
      >
    </j-alert>

    <j-alert v-if="$store.getters.role === 'INACTIVE'" class="ma-6">
      Join JEFF Club to get full access to Chats!
      <br />
      <j-btn secondary to="/checkout/jeff-club" class="mt-2">Join JEFF Club</j-btn>
    </j-alert>

    <!-- dialogs -->
    <v-dialog
      v-model="showCreateEditChatDialog"
      max-width="650"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <create-edit-chat @saved="saveChat" @closed="showCreateEditChatDialog = false" :chat="selectedChat" />
    </v-dialog>

    <v-dialog
      v-if="selectedChat"
      v-model="showLeaveChatDialog"
      max-width="650"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <confirm-leave-chat @confirm="leaveChat" @closed="showLeaveChatDialog = false" :chat="selectedChat" />
    </v-dialog>
  </j-main-banner-container>
</template>

<script>
import { decodeId } from "@/lib/string";
import { extractNodes } from "@/lib/array";
import { debounce } from "@/lib/helpers";
import { CHATS_FOR_USER } from "@/graphql/queries/chat";
import { UPSERT_CHAT_MUTATION, LEAVE_CHAT_MUTATION } from "@/graphql/mutations/chat";
import ChatListingCard from "@/components/chat/ChatListingCard";
import CreateEditChat from "@/components/chat/dialogs/CreateEditChat";
import ConfirmLeaveChat from "@/components/chat/dialogs/ConfirmLeaveChat";
import ChatMixin from "@/mixins/ChatMixin";
import Cookies from "js-cookie";

export default {
  name: "ChatList",
  mixins: [ChatMixin],
  components: {
    ChatListingCard,
    CreateEditChat,
    ConfirmLeaveChat,
  },
  data() {
    return {
      chats: [],
      loading: true,
      showCreateEditChatDialog: false,
      showLeaveChatDialog: false,
      selectedChat: null,
      showJoinChatDialog: false,
      chatAlert: null,
      hasNextPage: false,
      endCursor: null,
      after: null,
      onlyUnread: false,
      // we have both `search` and `searchString` so that we can debounce the query
      search: null,
      searchString: null,
    };
  },
  created() {
    // leaveChatId
    if (this.$route.query.lcid) {
      const leaveChatId = this.$route.query.lcid;
      // we must have a title as well.
      const title = this.$route.query.t;
      this.leaveChat({
        id: leaveChatId,
        title: title,
      });
      history.pushState({}, null, this.$route.path);
    }

    this.onlyUnread = !!Cookies.get("only_unread");
  },
  computed: {
    noChats() {
      return !this.$apollo.loading && this.chats && this.chats.length == 0 && this.$store.getters.role !== "INACTIVE";
    },
    hasFilters() {
      // add any filters here.
      return this.search || this.onlyUnread;
    },
  },
  methods: {
    storeOnlyUnread() {
      if (this.onlyUnread) {
        Cookies.set("only_unread", "true", { expires: 60 });
      } else {
        Cookies.remove("only_unread");
      }
    },
    triggerSearch() {
      debounce(this.doSearch, 1000);
    },
    doSearch() {
      this.loading = true;
      this.resetFilters();
      this.search = this.searchString;
    },
    resetFilters() {
      this.loading = true;
      this.after = null;
      this.search = null;
      this.chats = [];
    },
    loadMore() {
      if (!this.loading) {
        this.after = this.endCursor;
      }
    },
    createChat() {
      this.showCreateEditChatDialog = true;
      this.selectedChat = null;
      this.chatAlert = null;
    },
    editChat(chat) {
      this.selectedChat = chat;
      this.showCreateEditChatDialog = true;
      this.chatAlert = null;
    },
    leaveChatDialog(chat) {
      this.selectedChat = chat;
      this.showLeaveChatDialog = true;
      this.chatAlert = null;
    },
    async leaveChat(chat) {
      this.loading = true;
      this.showLeaveChatDialog = false;
      await this.$apollo.mutate({
        mutation: LEAVE_CHAT_MUTATION,
        variables: {
          chatId: decodeId(chat.id),
        },
      });
      const chatTitle = chat.title ? chat.title : "the chat";
      this.chatAlert = {
        success: true,
        message: `You have successfully left <strong>${chatTitle}</strong>`,
      };
      this.chats = [];
      await this.$apollo.queries.chatsForUser.refetch();
      this.loading = false;
    },
    async saveChat(data) {
      this.showCreateEditChatDialog = false;

      this.loading = true;
      await this.$apollo.mutate({
        mutation: UPSERT_CHAT_MUTATION,
        variables: {
          data: {
            id: data.id,
            title: data.chatName,
            image: data.image,
            userIds: data.selectedUsers,
            adminUserIds: data.adminUsers,
          },
        },
        refetchQueries: ["assistantQuery"],
      });

      if (data.id) {
        this.chats = [];
        await this.$apollo.queries.chatsForUser.refetch();
      } else {
        this.$apollo
          .query({
            query: CHATS_FOR_USER,
            variables: {
              first: 1,
              orderBy: "-created_at",
            },
          })
          .then((response) => {
            this.chats.unshift(response.data.chatsForUser.edges[0].node);
          });
      }

      this.loading = false;
    },
  },
  apollo: {
    chatsForUser: {
      query: CHATS_FOR_USER,
      fetchPolicy: "no-cache",
      variables() {
        return {
          first: 10,
          search: this.search,
          after: this.after,
          onlyUnread: this.onlyUnread,
        };
      },
      result(response) {
        if (response.data) {
          this.endCursor = response.data.chatsForUser.pageInfo.endCursor;
          this.hasNextPage = response.data.chatsForUser.pageInfo.hasNextPage;
          this.chats = [...this.chats, ...extractNodes(response.data.chatsForUser.edges)];
          this.chats.sort((a) => {
            if (a.type === 'ASSISTANT')
              return -1;
            return 1;
          });
          this.loading = false;
        }
      },
    },
  },
};
</script>

<style lang="scss">
.chat-switch {
  .v-label {
    font-size: 14px;
    font-weight: 500;
  }
}
</style>
