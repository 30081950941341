import gql from "graphql-tag";

export const UPSERT_WEEKLY_GOAL = gql`
  mutation upsertWeeklyGoal($input: WeeklyGoalInput!) {
    upsertWeeklyGoal(data: $input)  {
      success
      error
    }
  }
`;

export const ADD_FOCUS_DAY_MUTATION = gql`
  mutation addFocusDay($date: Date!) {
    addFocusDay(date: $date) {
      success
      error
    }
  }
`

export const DELETE_FOCUS_DAY_MUTATION = gql`
  mutation deleteFocusDay($date: Date!) {
    deleteFocusDay(date: $date) {
      success
      error
    }
  }
`