<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="5" align="center" justify="center">
        <div class="d-flex align-center mb-6">
          <back-link v-if="prevStepLink" icon :baseRoute="prevStepLink" />
          <v-progress-linear :value="progressAmount" background-color="grey" rounded height="6" class="mx-3" />
        </div>
        <h1 v-if="header" class="secondary--text text-uppercase heading-1">{{ header }}</h1>
        <v-row justify="center">
          <v-col cols="9" sm="6" md="9" align="center" justfy="center">
            <h2 class="secondary--text my-4 heading-2">
              <span class="font-weight-regular">{{ title.text }} </span>
              <span class="d-block font-weight-black">{{ title.strongText }}</span>
            </h2>
          </v-col>
        </v-row>
        <p v-if="subtitle" class="std-text secondary--text">{{ subtitle }}</p>

        <slot />
      </v-col>
    </v-row>
    <v-row justify="center" class="pt-0">
      <v-col cols="12" md="5" align="center" justfy="center">
        <j-btn :disabled="buttonDisabled" secondary wide @click="next">
          <strong class="white--text">{{ buttonText }}</strong>
        </j-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BackLink from "@/layouts/BackLink";

export default {
  name: "CheckinStepper",
  components: {
    BackLink,
  },
  props: {
    step: {
      type: Number,
      required: true,
    },
    header: {
      type: String,
      required: false,
    },
    title: {
      type: Object,
      required: true,
      default() {
        return {
          text: "",
          strongText: "",
        };
      },
    },
    subtitle: {
      type: String,
      required: false,
    },
    buttonText: {
      type: String,
      default: "Next"
    },
    buttonDisabled: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      // the prop `:step="5"` is tightly coupled to this. If you update the below, you need to change the step prop in each vue component
      steps: [
        "goals",
        "interests",
        //"instructors",
        "personal",
        "support",
        //"coaches",
        //"rewards",
        //"thank-you"
        "done"
      ],
    };
  },
  computed: {
    justPreferences() {
      return !!this.$route.query.preferences;
    },
    stepsLength() {
      if (this.justPreferences) {
        return 3;
      }
      return this.steps.length;
    },
    progressAmount() {
      return (this.step / this.stepsLength) * 100;
    },
    prevStepLink() {
      if (this.step == 1) {
        return;
      }
      return `/checkin/${this.steps[this.step - 2]}`;
    },
    nextStepLink() {
      if (this.step == this.stepsLength) {
        if (this.justPreferences) {
          return '/today/to-do';
        }
        return;
      }
      return `/checkin/${this.steps[this.step]}${this.justPreferences ? '?preferences=true' : ''}`;
    },
  },
  methods: {
    next() {
      this.$emit('next', this.nextStepLink);
    }
  },
};
</script>

<style lang="scss" scoped>
.container {
  height: 100%;

  & > .row:first-of-type {
    min-height: 90%;
  }
}
</style>
