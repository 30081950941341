<template>
  <div>
    <div v-if="$apollo.loading">
      <v-row>
        <v-col cols="6" md="4"><v-skeleton-loader type="card-avatar" /> </v-col>
        <v-col cols="6" md="4"><v-skeleton-loader type="card-avatar" /> </v-col>
        <v-col cols="6" md="4"><v-skeleton-loader type="card-avatar" /> </v-col>
        <v-col cols="6" md="4"><v-skeleton-loader type="card-avatar" /> </v-col>
        <v-col class="hidden-sm-and-down" cols="4"><v-skeleton-loader type="card-avatar" /> </v-col>
        <v-col class="hidden-sm-and-down" cols="4"><v-skeleton-loader type="card-avatar" /> </v-col>
      </v-row>
    </div>

    <div v-else>
      <j-alert v-if="!canCreate">
        Add 2 or more check-ins with images to create a Celebration!
        <br />
        <j-btn class="mt-2" to="/checkins/new">Add Check in</j-btn>
      </j-alert>

      <div v-else>
        <div v-if="!loading">
          <p class="px-4">
            Choose your before and after photos
            <v-icon v-if="canContinue" class="ml-2" color="green">mdi-check-circle</v-icon>
          </p>

          <j-btn v-if="canContinue" class="mb-6" @click="upsertCelebration()" :loading="loading" primary
            >Next step <v-icon color="white">mdi-arrow-right</v-icon></j-btn
          >
        </div>

        <j-alert v-if="loading" :icon="false"
          >We're getting your celebration ready for you to review...
          <v-progress-linear class="mt-2" color="navy" indeterminate rounded height="6"></v-progress-linear>
        </j-alert>

        <v-row v-if="beforeCheckin.image || afterCheckin.image">
          <v-col cols="6">
            <selected-image-card
              v-if="beforeCheckin.image"
              :image="beforeCheckin.image"
              type="before"
              @remove="removeImage('before')"
              @open="openCropperDialog(getCheckInImage('before'), beforeCheckin.checkIn, beforeCheckin.imageType)"
              class="mb-6"
            />
          </v-col>

          <v-col cols="6">
            <selected-image-card
              v-if="afterCheckin.image"
              :image="afterCheckin.image"
              type="after"
              @remove="removeImage('after')"
              @open="openCropperDialog(getCheckInImage('after'), afterCheckin.checkIn, afterCheckin.imageType)"
              class="mb-6"
          /></v-col>
        </v-row>

        <div v-if="userCheckIns && userCheckIns.length > 0" class="d-flex justify-start mb-4">
          <j-select v-model="orderBy" :items="orderByItems" label="Sort by" hide-details dense class="mw" />
        </div>

        <div v-for="checkIn in userCheckIns" :key="checkIn.id">
          <v-row v-if="hasImages(checkIn)">
            <v-col cols="12" class="mb-n4 p-text text-left grey-navy--text font-weight-bold">{{
              checkIn.assessmentDate | humanize_yyyy_mm_dd
            }}</v-col>
            <v-col v-if="checkIn.imgBack" cols="4">
              <v-img
                :lazy-src="checkIn.imgBack"
                :src="checkIn.imgBack"
                class="pointer rounded-lg"
                @click="openCropperDialog(checkIn.imgBack, checkIn, 'imgBack')"
              />
            </v-col>
            <v-col v-if="checkIn.imgFront" cols="4">
              <v-img
                :lazy-src="checkIn.imgFront"
                :src="checkIn.imgFront"
                class="pointer rounded-lg"
                @click="openCropperDialog(checkIn.imgFront, checkIn, 'imgFront')"
              />
            </v-col>
            <v-col v-if="checkIn.imgSide" cols="4">
              <v-img
                :lazy-src="checkIn.imgSide"
                :src="checkIn.imgSide"
                class="pointer rounded-lg"
                @click="openCropperDialog(checkIn.imgSide, checkIn, 'imgSide')"
              />
            </v-col>
          </v-row>
        </div>
      </div>

      <v-dialog v-model="cropperDialog" width="400" transition="dialog-bottom-transition">
        <crop-image-card
          :image="dialogImageDataUrl"
          @selectImage="selectImage($event)"
          @close="cropperDialog = false"
        />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { extractNodes } from "@/lib/array";
import { dataURLtoBlob, blobToFile, urlToDataURL } from "@/lib/file";
import { UPSERT_CELEBRATION_MUTATION } from "@/graphql/mutations/celebration";
import { USER_CHECK_INS_QUERY } from "@/graphql/queries/checkins";
import SelectedImageCard from "@/components/celebration/components/SelectedImageCard";
import CropImageCard from "@/components/celebration/components/CropImageCard";

export default {
  name: "CelebrationStepOne",
  components: {
    SelectedImageCard,
    CropImageCard,
  },
  data() {
    return {
      orderByItems: [
        {
          text: "Oldest first",
          value: "assessmentDate",
        },
        {
          text: "Newest first",
          value: "-assessmentDate",
        },
      ],
      cropperDialog: false,
      dialogImageDataUrl: null,
      beforeCheckin: { image: null, checkIn: null, imageType: null }, // set once we click one of the buttons in the dialog
      afterCheckin: { image: null, checkIn: null, imageType: null }, // set once we click one of the buttons in the dialog
      selectedCheckin: null, // when we click on an image, we set this.
      selectedImageType: null, // when we click this, we set this, e.g imgBack
      orderBy: "assessmentDate",
      loading: false,
    };
  },
  computed: {
    canContinue() {
      return this.beforeCheckin.image && this.afterCheckin.image;
    },
    canCreate() {
      if (!this.userCheckIns || this.userCheckIns.length < 2) {
        return false;
      }

      let checkinsWithImages = 0;
      this.userCheckIns.forEach((checkin) => {
        if (checkin.imgBack || checkin.imgFront || checkin.imgSide) {
          checkinsWithImages++;
        }
      });

      return checkinsWithImages > 1;
    },
  },
  methods: {
    getCheckInImage(imageType) {
      if (imageType === "before") {
        return this.beforeCheckin.checkIn[this.beforeCheckin.imageType];
      } else if (imageType === "after") {
        return this.afterCheckin.checkIn[this.afterCheckin.imageType];
      }
    },
    hasImages(checkIn) {
      return checkIn.imgBack || checkIn.imgFront || checkIn.imgSide;
    },
    removeImage(type) {
      if (type === "before") {
        this.beforeCheckin.image = null;
        this.beforeCheckin.checkIn = null;
        this.afterCheckin.imageType = null;
      } else if (type === "after") {
        this.afterCheckin.image = null;
        this.afterCheckin.checkIn = null;
        this.afterCheckin.imageType = null;
      }
    },
    selectImage(event) {
      this.cropperDialog = false;
      if (event.type === "before") {
        this.beforeCheckin.image = event.croppedImage;
        this.beforeCheckin.checkIn = this.selectedCheckin;
        this.beforeCheckin.imageType = this.selectedImageType;
      } else if (event.type === "after") {
        this.afterCheckin.image = event.croppedImage;
        this.afterCheckin.checkIn = this.selectedCheckin;
        this.afterCheckin.imageType = this.selectedImageType;
      }

      if (this.afterCheckin.image && this.beforeCheckin.image) {
        window.scrollTo({
          top: 0,
          behavior: "auto",
        });
      }
    },
    async openCropperDialog(remoteImage, checkIn, type) {
      const $vm = this;
      const ready = new Promise((resolve) => {
        urlToDataURL(remoteImage, async function(dataUrl) {
          $vm.dialogImageDataUrl = dataUrl;
          resolve();
        });
        return;
      });
      await ready;
      this.selectedCheckin = checkIn;
      this.selectedImageType = type; //imgBack etc.
      this.cropperDialog = true;
    },
    async upsertCelebration() {
      this.loading = true;

      const leftBlob = dataURLtoBlob(this.beforeCheckin.image);
      const leftFile = blobToFile(leftBlob);

      const rightBlob = dataURLtoBlob(this.afterCheckin.image);
      const rightFile = blobToFile(rightBlob);

      let weightLost = parseFloat(this.beforeCheckin.checkIn.weight) - parseFloat(this.afterCheckin.checkIn.weight);
      let abdomenLost = parseFloat(this.beforeCheckin.checkIn.abdomen) - parseFloat(this.afterCheckin.checkIn.abdomen);

      let s = this.$moment(this.beforeCheckin.checkIn.assessmentDate);
      let e = this.$moment(this.afterCheckin.checkIn.assessmentDate);
      const checkinDiffInMonths = e.diff(s, "months", false);
      const months = checkinDiffInMonths == 1 ? "month" : "months";

      if (isNaN(weightLost)) {
        weightLost = 0;
      }
      if (isNaN(abdomenLost)) {
        abdomenLost = 0;
      }
      let data = {
        leftImage: leftFile,
        rightImage: rightFile,
        weight: `${Math.ceil(weightLost)} kg lost`,
        cm: `${Math.ceil(abdomenLost)} cm lost`,
        months: `${checkinDiffInMonths} ${months}`,
      };

      const result = await this.$apollo.mutate({
        mutation: UPSERT_CELEBRATION_MUTATION,
        variables: {
          data: data,
        },
      });

      if (result.data.upsertCelebration?.success) {
        data["celebrationImage"] = result.data.upsertCelebration.image;
        data["id"] = result.data.upsertCelebration.id;
      }
      this.loading = false;
      this.$emit("saved", data);
    },
  },
  apollo: {
    userCheckIns: {
      query: USER_CHECK_INS_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          orderBy: this.orderBy,
        };
      },
      update: (data) => extractNodes(data.userCheckIns.edges),
    },
  },
};
</script>

<style lang="scss" scoped>
.mw {
  max-width: 175px;
}
</style>
