<template>
  <v-row v-if="$apollo.loading">
    <v-col :cols="mdCols" v-for="count in 4" :key="count"><v-skeleton-loader type="card-avatar" /> </v-col>
  </v-row>
  <div v-else>
    <template>
      <div class="d-flex justify-space-between">
        <h4>Nutrition</h4>
        <!-- you cannot turn off nutrition tracking -->
        <!-- <v-switch class="py-0 my-0" :input-value="nutritionEnabled" @change="updateNutritionSetting($event)">
        </v-switch> -->
      </div>
      <div v-if="hasClub && activeNutritionBlock">
        <div class="d-flex align-baseline">
          <span class="std-text navy--text pointer" @click="navigate(activeNutritionBlock.id)">{{
            activeNutritionBlock.title
          }}</span>
          <j-gradient-text :gradient="['yellow', 'green']">
            <span class="p-text font-weight-bold text-uppercase">
              &nbsp; with {{ activeNutritionBlock.leadInstructor.firstName }}
            </span>
          </j-gradient-text>
        </div>
        <single-day-recipe-day-listing
          :md-cols="mdCols"
        />
      </div>
      <nutrition-logger v-else class="mt-2" />
    </template>
  </div>
</template>

<script>
import { decodeId } from "@/lib/string";
import { mapGetters } from "vuex";
import UserMixin from "@/mixins/UserMixin";
import RecipeListMixin from "@/mixins/nutrition/RecipeListMixin";
import SingleDayRecipeDayListing from "@/components/nutritionblocks/SingleDayRecipeDayListing";
import NutritionLogger from "@/components/nutritionblocks/blocks/NutritionLogger";

export default {
  name: "NutritionTracker",
  mixins: [UserMixin, RecipeListMixin],
  components: {
    SingleDayRecipeDayListing,
    NutritionLogger
  },
  props: {
    mdCols: {
      type: [Number, String],
      default: 3,
    },
  },
  computed: {
    ...mapGetters(["selectedDate", "activeNutritionBlock"]),
    monday() {
      var startDate = new Date(this.selectedDate);
      startDate.setHours(0, 0, 0, 0);
      var day = startDate.getDay(),
        diff = startDate.getDate() - day + (day == 0 ? -6 : 1);
      return this.$moment(new Date(startDate.setDate(diff))).format("YYYY-MM-DD");
    },
  },
  methods: {
    formattedDate() {
      return this.selectedDate ? this.selectedDate.format("YYYY-MM-DD") : this.$moment().format("YYYY-MM-DD");
    },
    updateNutritionSetting(value) {
      this.upsertSetting("nutrition-tracking", value);
    },
    navigate(id) {
      this.$router.push(`/nutrition/${decodeId(id)}`);
    },
  },
};
</script>
