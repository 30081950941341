export default {
  data() {
    return {
      videoTracking: null,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.videoTracking) this.videoTracking.stopTracking();
    next();
  },
  methods: {
    playing() {
      if(this.videoTracking) {
        this.videoTracking.startTracking();
      }
      window.analytics.track("Video Played", this.eventProperties);
    },
    ended() {
      if(this.videoTracking) {
        this.videoTracking.stopTracking();
      }
      window.analytics.track("Video Ended", this.eventProperties);
    },
    paused() {
      if(this.videoTracking) {
        this.videoTracking.stopTracking();
      }
      window.analytics.track("Video Paused", this.eventProperties);
    },
    audioPlaying() {
      if(this.videoTracking) {
        this.videoTracking.startTracking();
      }
      window.analytics.track("Audio Played", this.eventProperties);
    },
    audioEnded() {
      if(this.videoTracking) {
        this.videoTracking.stopTracking();
      }
      window.analytics.track("Audio Ended", this.eventProperties);
    },
    audioPaused() {
      if(this.videoTracking) {
        this.videoTracking.stopTracking();
      }
      window.analytics.track("Audio Paused", this.eventProperties);
    },
  },
};
