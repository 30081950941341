import Vue from 'vue'
import Vuex from 'vuex'

import authenticationModule from "./modules/authentication";
import activityModule from "./modules/activity";
import inboxModule from "./modules/inbox";
import assistantModule from "./modules/assistant";
import checkoutModule from "./modules/checkout";
import surveyModule from "./modules/survey";
import settingsModule from "./modules/settings";
import dealModule from "./modules/deal";

Vue.use(Vuex)

export const store = new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth: authenticationModule,
    activity: activityModule,
    inbox: inboxModule,
    assistant: assistantModule,
    checkout: checkoutModule,
    survey: surveyModule,
    settings: settingsModule,
    deal: dealModule
  }
})
