<template>
  <div class="d-flex mt-n2 mb-4">
    <div class="d-flex align-center pointer" @click="clickUploader">
      <div class="text-left camera-icon">
        <v-avatar v-if="image.dataUrl" class="mr-2">
          <img :src="image.dataUrl" />
        </v-avatar>

        <image-uploader
          id="pic-uploader"
          ref="imageUploader"
          :maxWidth="400"
          hidden
          :preview="false"
          :quality="0.9"
          outputFormat="verbose"
          accept="image/*"
          @input="selectImg($event)"
        >
        </image-uploader>

        <v-avatar color="rgb(0, 0, 0, 0.1)" size="50">
          <v-icon size="30"> mdi-camera-plus</v-icon>
        </v-avatar>
      </div>

      <div class="std-text ml-2">{{ label }}</div>
    </div>
  </div>
</template>

<script>
import { dataURLtoBlob, blobToFile, blobToDataURL } from "@/lib/file";
import ImageUploader from "vue-image-upload-resize";

export default {
  name: "JImageUploader",
  components: {
    ImageUploader,
  },
  props: {
    label: {
      type: String,
      default: "Upload image",
    },
    image: {
      type: Object,
      required: true,
    },
  },
  methods: {
    clickUploader() {
      this.$refs.imageUploader.$el.children["pic-uploader"].click();
    },
    selectImg(data) {
      const blob = dataURLtoBlob(data.dataUrl);
      const file = blobToFile(blob);
      this.image.file = file;
      const $vm = this;
      blobToDataURL(file, function(e) {
        $vm.image.dataUrl = e;
      });
    },
  },
};
</script>
