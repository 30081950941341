import gql from "graphql-tag";

export const TAGS_QUERY = gql`
  query tags($category: String!, $showToUser: Boolean) {
    tags(category: $category, showToUser: $showToUser) {
      edges {
        node {
          id
          code
          title
          thumbnail
        }
      }
    }
  }
`;

export const USER_TAGS_QUERY = gql`
  query userTags($category: String!) {
    userTags(category: $category) {
      tag {
        id
        code
        title
      }
    }
  }
`;
