<template>
  <div class="pt-3 px-2">
    <h2 class="heading-2 text-left navy--text ml-2">
      Packages
    </h2>
    <v-row class="pa-2">
      <v-col v-for="packageType in packages"
             :key="packageType.id" cols="12" md="6" class="d-flex flex-column">
        <j-card
          rounded="lg"
          class="my-1 j-elevated-2 d-flex flex-column flex-grow-1"
          :to="`/checkout/${packageType.productVariant.product.code}?pvi=${packageType.productVariant.id}&package=${packageType.code}`"
        >
          <v-img
            v-if="packageType.image"
            :src="packageType.image"
            :lazy-src="packageType.image"
            :aspect-ratio="10 / 9"
            contain
            class="flex-grow-0"
          />
          <j-gradient-text v-if="hasPrice(packageType.productVariant)" :gradient="['pink', 'yellow']" class="text-uppercase">
            R {{ getPrice(packageType) }}
          </j-gradient-text>
          <v-card-text class="d-flex flex-column flex-grow-1">
            <div class="navy--text heading-5 font-weight-bold">{{ packageType.title }}</div>
            <div v-html="packageType.description"/>
          </v-card-text>
        </j-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "PackageSection",
  props: {
    packages: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  methods: {
    hasPrice(product) {
      if (product.productvariantpriceSet[0]?.price) {
        return true;
      }
      return false;
    },
    getPrice(product) {
      let price = product.productVariant.productvariantpriceSet[0].price;
      if (product.discount) {
        if (product.discount.flatDiscount)
          price = price - product.discount.flatDiscount;
        else
          price = price - (price * (product.discount.percentageDiscount / 100));
      }
      return price;
    },
    productDisabled(product) {
      if (!this.subscriptionProduct) {
        return false;
      }
      let mapping = {
        "JEFF Club + 1-on-1 Coaching": ["JEFF Club + 1-on-1 Coaching", "JEFF Club"],
        "JEFF Club": ["JEFF Club"],
      };
      if (!mapping[this.subscriptionProduct.product.title]) {
        return false;
      }
      return mapping[this.subscriptionProduct.product.title].includes(product.title);
    },
  },
}
</script>

<style scoped>

</style>