import { NUTRITION_PREFERENCES_QUERY } from "@/graphql/queries/nutrition";

export default {
  data() {
    return {
      orderBy: null,
      recipeTypes: ["BREAKFAST", "LUNCH", "DINNER", "PRIMARY_SNACK"],
      recipeTypeFilter: [],
      preferences: null,
      preferencesFilter: [],
      bookmarkedFilter: false,
      addedByMeFilter: null,
      thisMenuFilter: false
    };
  },
  apollo: {
    nutritionPreferenceList: {
      query: NUTRITION_PREFERENCES_QUERY,
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data) {
          this.preferences = response.data.nutritionPreferenceList.filter((el) => el.type == "PREFERENCE");
        }
      },
    },
  }
};
