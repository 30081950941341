<template>
  <v-autocomplete
    :color="color"
    outlined
    rounded
    append-icon="mdi-chevron-down"
    v-bind="$props"
    v-on="$listeners"
    class="j-select"
  >
    <template v-if="chipColor" v-slot:selection="{ item }">
      <v-chip :color="chipColor" class="white--text">
        <span>{{ item.text }}</span>
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
import { VAutocomplete } from "vuetify/lib";

export default {
  name: "JAutocomplete",
  extends: VAutocomplete,
  props: {
    color: {
      type: String,
      default: "secondary",
    },
    chipColor: {
      type: String,
      required: false
    }
  },
};
</script>
