<template>
  <j-card rounded="lg">
    <v-toolbar v-if="showToolbar" class="mb-3 navy--text">
      <v-toolbar-title>
        <v-icon color="navy"> mdi-link-variant </v-icon>
        {{ title }}
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="$emit('closed')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-tabs v-model="tab" class="d-flex justify-center mb-6">
        <v-tab v-if="shareWithChats" key="chats">Chats</v-tab>
        <v-tab key="groups">Groups</v-tab>
        <v-tab key="socials">Socials</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item v-if="shareWithChats" key="chats">
          <share-options :date="$moment().toString()" :chatData="chatData" messageAtBottom />
          <div class="text-center mb-6" v-if="chatData.isSharing">
            <j-btn
              :disabled="!content || sharedToChats"
              :tertiary="!content || sharedToChats"
              primary
              @click="shareToChats()"
              >Share to Chats
            </j-btn>

            <j-alert v-if="sharedToChats" class="mt-6">
              Successfully shared to chats
            </j-alert>
          </div>
        </v-tab-item>

        <v-tab-item key="groups">
          <p>Search for Groups to share this to</p>

          <j-text-field
            v-model="searchString"
            @keyup="searchGroups()"
            prepend-inner-icon="mdi-magnify"
            placeholder="Find Groups"
          />

          <div class="mt-n4" v-if="humanFriendlySelectedGroups.length > 0">
            <strong class="d-block">Groups to share to:</strong>
            {{ humanFriendlySelectedGroups.join(", ") }}
          </div>
          <div v-if="groups">
            <j-avatar-card
              v-for="(group, index) in groups"
              :key="group.node.id"
              :title="`${group.node.title}`"
              :subtitle="getSubTitle(group.node)"
              :colour="colours[index]"
            >
              <template v-slot:trailing>
                <j-checkbox v-if="group.node.isMember" v-model="selectedGroups" :value="group.node.id" />
              </template>
            </j-avatar-card>
          </div>

          <j-alert v-if="searchString && groups && groups.length == 0">
            No groups found. Please search again.
          </j-alert>

          <div class="text-center mb-6" v-if="humanFriendlySelectedGroups.length > 0">
            <j-textarea v-model="content" auto-grow persistent-hint rows="1" label="Comment" class="mt-3" />

            <j-btn
              :disabled="!content || sharedToGroup"
              :tertiary="!content || sharedToGroup"
              primary
              @click="shareToGroups()"
            >
              Share to Group<span v-if="humanFriendlySelectedGroups.length != 1">s</span>
            </j-btn>

            <j-alert v-if="sharedToGroup" class="mt-6">
              Successfully shared to group
              <span v-if="humanFriendlySelectedGroups.length != 1">s</span>!
            </j-alert>
          </div>
        </v-tab-item>

        <v-tab-item key="socials">
          <share-button-group :shareObject="shareObject" class="pb-3" />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </j-card>
</template>

<script>
import GroupMixin from "@/mixins/GroupMixin";
import SendToChatsGroupsMixin from "@/mixins/nutrition/SendToChatsGroupsMixin";
import ShareButtonGroup from "@/components/shared/blocks/ShareButtonGroup";
import ShareOptions from "@/components/tracker/activity-form/ShareOptions";

export default {
  name: "ShareDialog",
  mixins: [GroupMixin, SendToChatsGroupsMixin],
  components: {
    ShareButtonGroup,
    ShareOptions,
  },
  data() {
    return {
      searchMine: true,
      content: `${this.quote} ${this.shareObject.url ? this.shareObject.url : ""}`,
      sharedToGroup: false,
      sharedToChats: false,
      requiresImage: false,
      chatData: {
        sendToChats: [],
        sendToGroups: [],
        isSharing: false,
        chatText: `${this.quote} ${this.shareObject.url ? this.shareObject.url : ""}`,
      },
      tab: [0],
    };
  },
  props: {
    showToolbar: {
      type: Boolean,
      default: true,
    },
    shareObject: {
      type: Object,
      required: true,
    },
    shareWithChats: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Share",
    },
    quote: {
      type: String,
      required: false,
    },
  },
  methods: {
    async shareToGroups() {
      this.sharedToGroup = false;
      
      let image = null;
      if (this.shareObject?.media?.startsWith("http") && this.shareObject.type === "IMAGE") {
        image = this.shareObject.media;
      }
      this.chatData.url = this.shareObject?.url;
      this.chatData.type = this.shareObject?.type ?? null;

      let groupData = {...this.chatData};
      groupData.sendToChats = [];

      await this.sendMessages(groupData, image);
      this.sharedToGroup = true;

      this.$emit("saved");
    },
    async shareToChats() {
      let image = null;
      if (this.shareObject?.media?.startsWith("http") && this.shareObject.type === "IMAGE") {
        image = this.shareObject.media;
      }

      let copiedChatData = {...this.chatData};
      copiedChatData.sendToGroups = [];
      
      await this.sendMessages(this.copiedChatData, image);
      this.sharedToChats = true;
    },
  },
};
</script>

<style scoped lang="scss">
.or {
  position: relative;
  font-size: 14px;
  z-index: 1;
  overflow: hidden;
  text-align: center;
  &:before,
  &:after {
    position: absolute;
    top: 50%;
    overflow: hidden;
    width: 49%;
    height: 1px;
    content: "\a0";
    background-color: lightgrey;
  }
  &:before {
    margin-left: -49%;
    text-align: right;
  }
}
</style>
