<template>
  <v-container>
    <v-row>
      <v-col>
        <h1>Calendar</h1>
      </v-col>
    </v-row>
    <v-row class="fill-height">
      <v-col cols="12">
        <v-sheet height="64">
        <v-toolbar
          flat
        >
          <v-btn
            outlined
            class="mr-4"
            color="grey darken-2"
            @click="setToday"
          >
            Today
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="prev"
          >
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn
            fab
            text
            small
            color="grey darken-2"
            @click="next"
          >
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-spacer />
        </v-toolbar>
      </v-sheet>
        <v-sheet height="600">
          <v-calendar
            ref="calendar"
            v-model="focus"
            :type="type"
            :weekdays="[1, 2, 3, 4, 5, 6, 0]"
            :events="events"
            interval-height="250"
            color="primary"
            :event-color="getEventColor"
            @click:event="handleEventClick"
            @click:date="handleDayClick"
          ></v-calendar>
        </v-sheet>
      </v-col>
    </v-row>

    <v-dialog v-model="openManageBlockDialog" width="600" transition="dialog-bottom-transition" class="limited-height">
      <manage-block-dialog
        @leave="leaveBlockHandler"
        @saved="updateSubscriptionBlockHandler"
        :workout-subscription-block="workoutSubscriptionBlock"
        @closed="openManageBlockDialog = false"
      />
    </v-dialog>

    <!-- manage block dialog -->
    <v-dialog
      v-model="openManageNutritionBlockDialog"
      width="600"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <manage-nutrition-block-dialog
        @leave="leaveNutritionBlockHandler"
        @saved="updateSubscriptionNutritionBlockHandler"
        :nutrition-subscription-block="nutritionSubscriptionBlock"
        @closed="openManageNutritionBlockDialog = false"
      />
    </v-dialog>

  </v-container>
</template>

<script>
import {WORKOUT_SUBSCRIPTION_BLOCKS_QUERY} from "@/graphql/queries/workoutblock";
import ManageBlockDialog from "@/components/workoutblocks/dialogs/ManageBlockDialog";
import ManageNutritionBlockDialog from "@/components/nutritionblocks/dialogs/ManageBlockDialog";
import BlockMixin from "@/mixins/BlockMixin";
import NutritionBlockMixin from "@/mixins/nutrition/NutritionBlockMixin";
import { decodeId } from "@/lib/string";

export default {
  name: "Calendar",
  mixins: [BlockMixin, NutritionBlockMixin],
  components: {
    ManageBlockDialog,
    ManageNutritionBlockDialog
  },
  data() {
    return {
      type: 'month',
      focus: '',
      events: [],
      openManageBlockDialog: false,
      openManageNutritionBlockDialog: false,
      workoutSubscriptionBlock: null,
      nutritionSubscriptionBlock: null
    }
  },
  mounted () {
    this.$refs.calendar.checkChange()
  },
  methods: {
    getEventColor(event) {
      return {
        "PROGRAM": "red",
        "COURSE": "yellow",
        "NUTRITION": "green",
        "WORKOUT": "blue",
        "REST_DAY": "grey"
      }[event.type];
    },
    handleEventClick(eventParsed) {
      let eventBlock = eventParsed.event;
      switch (eventBlock.type) {
        case "COURSE":
        case "PROGRAM":
          this.workoutSubscriptionBlock = eventBlock.workoutBlock;
          this.openManageBlockDialog = true;
          break;
        case "NUTRITION":
          this.nutritionSubscriptionBlock = eventBlock.nutritionBlock;
          this.openManageNutritionBlockDialog = true;
          break;
      }
    },
    handleDayClick(event) {
      console.log(event);
    },
    setToday () {
      this.focus = ''
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    getEventDate(blockStartDate, currentDay ) {
      let startDate = this.$moment(blockStartDate, "YYYY-MM-DD");
      let eventDate = startDate.add(currentDay, 'days');
      return eventDate.format("YYYY-MM-DD");
    },
    //handling subscription block changes
    async updateSubscriptionBlockHandler(data) {
      await this.updateSubscriptionBlock(data);
      this.events = this.events.filter(element => element.type === 'NUTRITION');
      await this.$apollo.queries.workoutSubscriptionBlocks.refetch();
    },
    async leaveBlockHandler() {
      await this.leaveBlock();
      this.events = this.events.filter(element => element.type === 'NUTRITION');
      await this.$apollo.queries.workoutSubscriptionBlocks.refetch();
    },

    async leaveNutritionBlockHandler() {
      await this.leaveNutritionBlock();
      this.events = this.events.filter(element => element.type === 'PROGRAM' || element.type === 'COURSE');
      this.openManageNutritionBlockDialog = false;
    },
    async updateSubscriptionNutritionBlockHandler(data) {
      await this.updateNutritionSubscriptionBlock(data);
      this.events = this.events.filter(element => element.type === 'PROGRAM' || element.type === 'COURSE');
      this.openManageNutritionBlockDialog = false;
    },
  },
  apollo: {
    workoutSubscriptionBlocks: {
      query: WORKOUT_SUBSCRIPTION_BLOCKS_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          orderBy: "start_date",
        };
      },
      result(response) {
        if (response.data && !response.loading) {
          let edges = response.data.workoutSubscriptionBlocks.edges;
          edges.forEach((element) => {
            let subscriptionBlock = element.node;
            let endDate = this.$moment(subscriptionBlock.endDate, "YYYY-MM-DD").subtract(1, 'days').format("YYYY-MM-DD");
            let event = {
              blockId: decodeId(subscriptionBlock.block.id),
              start: subscriptionBlock.startDate,
              end: endDate,
              name: subscriptionBlock.block.title,
              type: subscriptionBlock.block.type,
              workoutBlock: subscriptionBlock
            }
            this.events.push(event);
            if (subscriptionBlock.block.unitSet) {
              subscriptionBlock.block.unitSet.forEach((unitSet) => {
                unitSet.unitactivitySet.forEach((unitActivity) => {
                  let eventDate = this.getEventDate(subscriptionBlock.startDate, unitActivity.currentDay);
                  let event = {
                    start: eventDate,
                    name: unitActivity.activity ? unitActivity.activity.title : "Live Event",
                    type: unitActivity.type
                  }
                  this.events.push(event);
                });
              });
            }
          });
        }
      },
    },
  }
}
</script>

<style scoped>

</style>