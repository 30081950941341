import gql from "graphql-tag";

export const GROUPS_QUERY = gql`
  query groups($search: String, $first: Int, $after: String, $mine: Boolean, $isFeatured:Boolean, $withUsers: Boolean!, $type: String, $typeIn: [String], $permissionIn: [String], $hasData: Boolean, $orderBy: String) {
    groups(search: $search, mine: $mine, first: $first, after: $after, isFeatured: $isFeatured, type: $type, type_In: $typeIn, permission_In: $permissionIn, hasData: $hasData, orderBy: $orderBy) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          title
          type
          banner
          permission
          createdAt
          isMember
          isFeatured
          postCount
          memberCount
          lastActive
          createdBy {
            id
            firstName
            lastName
            userProfile {
              id
              profilePicture
            }
          }
          users @include(if: $withUsers){
            id
            firstName
            lastName
            userProfile {
              id
              profilePicture
            }
          }
        }
      }
    }
  }
`;

export const GROUP_QUERY = gql`
  query group($groupId: ID!) {
    group(groupId: $groupId) {
      id
      title
      permission
      type
      description
      banner
      isMember
      memberCount
      pinnedPost {
        id
        likeCount
        commentCount
        createdAt
        content
        url
        allowSharing
        user {
          id
          firstName
          lastName
          userProfile {
            id
            profilePicture
            userType
          }
        }
        postattachmentSet {
          id
          file
          remoteFile
          type
          timeInSeconds
        }
        userPostLike {
          id
        }
        group {
          id
          title
          workoutBlock {
            id
          }
          nutritionBlock {
            id
          }
        }
      }
      createdBy {
        id
        firstName
        lastName
        userProfile {
          id
          profilePicture
        }
      }
      users {
        id
        firstName
        lastName
        userProfile {
          id
          profilePicture
        }
      }
    }
  }
`;

export const RECOMMENDED_GROUPS_QUERY = gql`
  query recommendedGroups($limit: Int) {
    recommendedGroups(limit: $limit) {
      id
      title
      banner
      memberCount
      createdBy {
        id
        firstName
        lastName
        userProfile {
          id
          profilePicture
        }
      }
    }
  }
`;

export const TRIBE_LEADERBOARD_QUERY = gql`
query tribeLeaderboard($startDate: Date, $endDate: Date){
  tribeLeaderboard(startDate: $startDate, endDate:$endDate){
    tribes {
      position
      team
      isMine
      teamImage
      dots
    }
  }
}
`;

export const GYM_LEADERBOARD_QUERY = gql`
query gymBranchesLeaderboard($startDate: Date!, $endDate: Date!, $leaderboardType: String){
  gymBranchesLeaderboard(startDate: $startDate, endDate: $endDate, leaderboardType: $leaderboardType) {
    gymBranches {
      position
      isMine
      dots
      gymBranch {
        id
        image
        title
      }
    }
  }
}
`

export const GLOBAL_LEADERBOARD_QUERY = gql`
query globalLeaderboard($startDate: Date, $endDate: Date) {
  globalLeaderboard(startDate: $startDate, endDate:$endDate) {
    totalDots
    myDots
  }
}
`;