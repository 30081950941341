<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center" class="fill-height">
      <v-col cols="12" md="6" xl="5" class="text-center mx-5 px-0 navy--text background-grey-3 fill-height mb-10">
        <j-card rounded="lg" class="d-flex flex-column text-center pa-5 mx-3">
          <div class="text-h3 mt-5 font-weight-bold navy--text">20,000+</div>
          <div class="text-h6 mb-3 mt-2 navy--text font-weight-regular">have chosen us to reach their goals</div>

          <v-rating :value="5" background-color="mindset" color="mindset" size="32"></v-rating>

          <div class="text-h6 mt-2 navy--text">Over 4,000</div>
          <div class="text-h6 navy--text mt-n1">5-Star Reviews</div>

          <div class="d-flex align-center text-left mt-6" :class="{ 'px-8': $vuetify.breakpoint.mdAndUp }">
            <v-img :src="require('@/assets/images/start/funnel-lauren.png')" contain max-width="75" />
            <div class="p-text ml-4">
              <span class="navy--text font-italic">"After just 3 weeks, I feel healthier and happier than ever!"</span> ~ Lauren
            </div>
          </div>

          <div class="d-flex align-center text-left mt-6" :class="{ 'px-8': $vuetify.breakpoint.mdAndUp }">
            <v-img :src="require('@/assets/images/start/funnel-chantelle.png')" contain max-width="75" />
            <div class="p-text ml-4">
              <span class="navy--text font-italic">"Best lifestyle and wellness platform on the market"</span> ~ Chantelle
            </div>
          </div>

          <div class="d-flex align-center text-left mt-6" :class="{ 'px-8': $vuetify.breakpoint.mdAndUp }">
            <v-img :src="require('@/assets/images/start/funnel-andrew.png')" contain max-width="75" />
            <div class="p-text ml-4">
              <span class="navy--text font-italic"
                >"I have gone to gym for many years BUT never have I got results like this!"</span
              >
              ~ Andrew
            </div>
          </div>

          <div class="std-text mt-8">TRUSTED BY</div>

          <v-img :src="require('@/assets/images/start/trustedby.png')" contain />
        </j-card>

        <div class="d-flex mx-5 mt-4">
          <j-btn tertiary :to="backlink">back</j-btn>
          <j-btn color="pink" to="/start/details" class="d-flex flex-fill ml-5">next</j-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Chosen",
  computed: {
    backlink() {
      let link = "/start/get-going";
      if (this.$store.getters.dealOwner) {
        link += `/${this.$store.getters.dealOwner.code}`;
      }
      return link;
    },
  },
};
</script>
