<template>
  <v-container>
    <v-row v-if="successMessage" justify="center" align="center">
      <v-col cols="12" md="7">
        <j-alert>
          {{ successMessage }}
        </j-alert>
      </v-col>
    </v-row>
    <v-row v-else justify="center" align="center">
      <v-col cols="12" md="7">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col>
                <h2>How can we help?</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ValidationObserver ref="observer" v-slot="{ invalid }">
                  <v-row>
                    <v-col cols="6" class="pb-0">
                      <ValidationProvider id="firstName" v-slot="{ errors }" name="First Name" rules="required">
                        <label class="p-text">First Name <span class="error--text">*</span></label>
                        <j-text-field v-model="contactSupportData.firstName" outlined :error-messages="errors" />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="6" class="pb-0">
                      <ValidationProvider id="lastName" v-slot="{ errors }" name="Last Name" rules="required">
                        <label class="p-text">Last Name <span class="error--text">*</span></label>
                        <j-text-field v-model="contactSupportData.lastName" outlined :error-messages="errors" />
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" class="pb-0">
                      <ValidationProvider id="email" v-slot="{ errors }" name="Email" rules="required|email">
                        <label class="p-text">Email <span class="error--text">*</span></label>
                        <j-text-field v-model="contactSupportData.email" outlined :error-messages="errors" />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="6" class="pb-0">
                      <ValidationProvider id="phone" v-slot="{ errors }" name="Phone Number" rules="required">
                        <label class="p-text">Phone <span class="error--text">*</span></label>
                        <j-text-field v-model="contactSupportData.phone" outlined :error-messages="errors" />
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <label class="p-text">Subject <span class="error--text">*</span></label>
                      <ValidationProvider id="subjectLine" v-slot="{ errors }" name="Subject Line" rules="required">
                        <j-text-field
                          v-model="contactSupportData.subjectLine"
                          outlined
                          label="How can we support you?"
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <ValidationProvider id="details" v-slot="{ errors }" name="Details" rules="required">
                        <label class="p-text">Details <span class="error--text">*</span></label>
                        <j-textarea
                          v-model="contactSupportData.details"
                          multi
                          outlined
                          label="Please provide details"
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="center">
                    <v-col cols="12">
                      <p class="p-text">Click or tap icon below to include any relavant images or files.</p>
                      <v-avatar color="rgb(0, 0, 0, 0.1)" size="40">
                        <v-icon @click="$refs.multipleImageUploader.clickUploader()">mdi-file-plus</v-icon>
                      </v-avatar>
                      <multiple-image-uploader ref="multipleImageUploader" @setImage="saveImageFiles($event)" />
                    </v-col>
                  </v-row>

                  <v-row align="center" justify="center" :disabled="invalid">
                    <j-alert v-if="errorMessage">
                      {{ errorMessage }}
                    </j-alert>
                    <j-btn
                      secondary
                      large
                      wide
                      :loading="loading"
                      @click.prevent="submitContactRequest()"
                      class="mb-6 mt-2"
                    >
                      Submit
                    </j-btn>
                  </v-row>
                </ValidationObserver>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MultipleImageUploader from "@/components/shared/MultipleImageUploader";
import { CREATE_TASK_EVENT_MUTATION } from "@/graphql/mutations/task";
import { UPSERT_CONTACT_REQUEST_MUTATION } from "@/graphql/mutations/contact";
import { dataURLtoBlob, blobToFile } from "@/lib/file";

export default {
  name: "Contact",
  components: {
    MultipleImageUploader,
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      successMessage: null,
      contactSupportData: {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        supportCategory: "",
        subjectLine: "",
        details: "",
      },
      taskEventData: {
        userId: null,
        title: "Support Request",
        description: "",
        taskTypeTitle: "Support",
        files: [],
      },
    };
  },
  methods: {
    saveImageFiles(files) {
      this.taskEventData.files = [];

      files.forEach((file) => {
        let blob = dataURLtoBlob(file.dataUrl);
        let tmpFile = blobToFile(blob);
        this.taskEventData.files.push(tmpFile);
      });
    },
    async submitContactRequest() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;

      this.loading = true;

      const { data } = await this.$apollo.mutate({
        mutation: UPSERT_CONTACT_REQUEST_MUTATION,
        variables: {
          data: this.contactSupportData,
        },
      });

      if (data.upsertContactRequest.success) {
        this.createTask();
      } else {
        this.errorMessage = data.upsertContactRequest.error;
      }
      this.loading = false;
    },
    async createTask() {
      this.taskEventData.userId = this.$store.getters.user ? this.$store.getters.user.id : null;
      this.taskEventData.description = `${this.contactSupportData.subjectLine}:  ${this.contactSupportData.details} - ${this.contactSupportData.firstName} ${this.contactSupportData.lastName} - ${this.contactSupportData.email} ${this.contactSupportData.phone}`;
      this.loading = true;

      const { data } = await this.$apollo.mutate({
        mutation: CREATE_TASK_EVENT_MUTATION,
        variables: {
          data: this.taskEventData,
        },
      });

      if (data.createTaskEvent.created) {
        this.successMessage = "Your request was submitted. We will be in touch as soon as possible.";
      } else {
        this.errorMessage = data.createTaskEvent.error;
      }
      this.loading = false;
    },
  },
};
</script>
