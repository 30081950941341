<template>
  <div>
    <j-btn tertiary :small="!large" :large="large" :narrow="!large" @click="modal.show = true">
      <strong class="pt-0 pr-1">
        <slot />
        <span v-if="!$slots.default">Rest day</span>
      </strong>
      <v-icon :small="!large" v-if="didRest" color="rest">fiber_manual_record</v-icon>
      <v-icon :small="!large" v-else color="rest">radio_button_unchecked</v-icon>
    </j-btn>

    <v-dialog v-if="modal.show" v-model="modal.show" width="350" transition="dialog-bottom-transition">
      <rest-day-logging :is-rest-day="isRestDay" :unit-activity="unitActivity" :subscription-block="subscriptionBlock" @close="modal.show = false" class="px-5" />
    </v-dialog>
  </div>
</template>

<script>
import RestDayMixin from "@/mixins/RestDayMixin";
import RestDayLogging from "@/components/tracker/RestDayLogging";

export default {
  name: "RestDayCard",
  mixins: [RestDayMixin],
  props: {
    isRestDay: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
    unitActivity: {
      type: Object,
      required: false,
    },
    subscriptionBlock: {
      type: Object,
      required: false,
    },
  },
  components: { RestDayLogging },
  data() {
    return {
      modal: {
        show: false,
      },
    };
  },
};
</script>
