<template>
  <v-container>
    <j-banner-container
      header="Community Stats"
      :gradient="['green-background', 'yellow-background']"
      show-share-link
      use-header-in-link
    >
      <v-row align="center" justify="center">
        <v-col cols="12" align="center" justify="center">
          <j-btn tertiary class="ma-2" to="/social/community-stats/reps">
            <strong>Log my reps</strong>
          </j-btn>
          <j-btn secondary class="ma-2" to="/accountability">
            <strong class="white--text">Log my activity</strong>
          </j-btn>
        </v-col>
      </v-row>
      <v-row v-if="loading" align="center" justify="center">
        <v-col cols="12" sm="6" align="center" justify="center">
          <v-progress-circular :indeterminate="true" color="blue" />
        </v-col>
      </v-row>
      <v-row v-else align="center" justify="center" class="mt-0">
        <v-col cols="12" align="center" justify="center">
          <j-card v-for="copy in communityRepLog" :key="copy.id" class="py-2 mb-5 j-elevated-1">
            <community-rep-block :repCopy="copy" />
          </j-card>
        </v-col>
      </v-row>
    </j-banner-container>
  </v-container>
</template>

<script>
import { COMMUNITY_REPS_QUERY } from "@/graphql/queries/communityStats";
import CommunityRepBlock from "@/components/community-stats/CommunityRepBlock";

export default {
  name: "CommunityStats",
  components: {
    CommunityRepBlock,
  },
  data() {
    return {
      loading: true,
    };
  },
  apollo: {
    communityRepLog: {
      query: COMMUNITY_REPS_QUERY,
      fetchPolicy: "network-only",
      result(response) {
        this.loading = response.data.loading;
      },
      update(data) {
        return data.communityRepLog;
      },
    },
  },
};
</script>
