import {debounce} from "@/lib/helpers";
import {CLIENTS_QUERY} from "@/graphql/queries/account";
import {decodeId} from "@/lib/string";
import {extractNodes} from "@/lib/array";

export default {
  methods: {
    mentionSearch(text, callback) {
      debounce(this.executeSearch, 500, [text, callback]);
    },
    executeSearch(text, callback) {
      this.memberQuery = this.$apollo.query({
        query: CLIENTS_QUERY,
        variables: {
          search: text,
          hasActiveSubscription: true,
          isMemberOfGroup: decodeId(this.groupId),
        },
        fetchPolicy: "no-cache",
      });

      this.memberQuery.then(result => {
        let data = extractNodes(result.data.clients.edges).map(element => {
          return {
            value: element.user.userProfile.handle,
            key: element.user.firstName + " " + element.user.lastName,
            image: element.profilePicture
          }
        });
        callback(data);
      });
    }
  }
}