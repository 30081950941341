<template>
  <v-container>
    <!-- <div class="text-center ma-12" v-if="!$apollo.loading">
      <v-progress-circular :indeterminate="loading" color="blue"></v-progress-circular>
    </div> -->
    <v-row justify="center">
      <v-col cols="12" md="5">
        <v-card class="mx-auto pb-2" v-if="article">
          <v-card-title>
            <h2 class="font-weight-bold mx-auto mt-3">{{ article.title }}</h2>
          </v-card-title>
          <div v-if="showArticle">
            <v-card-text id="article-content" v-html="article.content" class="mt-n2" />
            <div class="d-flex justify-center pb-4" v-if="article.buttonText">
              <v-btn
                color="secondary"
                align="center"
                large
                rounded
                :target="article.buttonOpenNewTab ? '_blank' : ''"
                :href="article.pdf ? article.pdf : article.buttonUrl"
              >
                <strong>{{ article.buttonText }}</strong>
              </v-btn>
            </div>
          </div>
          <v-card-text v-else class="text-center">
            This part of {{ $appName }} is only available once you sign up for a Program.
            <h2 class="mt-4 get-most">
              FIND OUT HOW TO GET THE MOST FROM {{ $appName }}
            </h2>
            <v-btn class="px-10 mt-6 mb-2 mx-auto cta one-on-one white--text" rounded href="/programs">
              <strong> Browse programs </strong>
            </v-btn>
          </v-card-text>

          <div v-if="youtubeId" class="pb-4">
            <video-block :youtubeId="youtubeId" :show-video-cast-message="false" />
          </div>

          <v-img class="mx-4 my-4" :src="article.bannerImage" />
        </v-card>
        <!-- <v-card v-else class="py-8 px-8">
          The article was not found.
          <router-link class="d-block mt-4 primary--text text-decoration-none" to="/">
            Back to home
          </router-link>
        </v-card> -->

        <!-- note, if FALSE. not doing this for now. May reinstate later.  -->
        <open-onboarding class="mt-8" v-if="false && showOnboarding && article" :firstName="name" :showGoal="hasNoGoals" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ARTICLE_QUERY } from "@/graphql/queries/article";
import OpenOnboarding from "@/components/shared/blocks/OpenOnboarding";
import UserMixin from "@/mixins/UserMixin";
import VideoBlock from "@/components/events/VideoBlock";
import {getYouTubeId} from "@/lib/string";

export default {
  name: "Article",
  mixins: [UserMixin],
  components: {
    OpenOnboarding,
    VideoBlock
  },
  mounted() {
    window.analytics.track("Viewed Article");
  },
  data() {
    return {
      article: null,
    };
  },
  computed: {
    showArticle() {
      // if (this.loading) {
      //   return false;
      // }
      if (this.$store.getters.role == "INACTIVE" || this.$store.getters.inActive) {
        return this.article.allowOpenAccess;
      }
      return true;
    },
    youtubeId() {
      return getYouTubeId(this.article?.youtubeVideo);
    },
  },
  apollo: {
    article: {
      query: ARTICLE_QUERY,
      variables() {
        let slug = this.$router.currentRoute.path.split("/").pop();
        return {
          slug: slug,
        };
      },
      // if cache-and-network, the loading experience is not great Flashes empty state first.
      //fetchPolicy: "network-only",
     
    },
  },
};
</script>

<style scoped>
.get-most {
  max-width: 300px;
  margin: 0 auto;
  line-height: 150%;
}
</style>

<style>
#article-content img {
  max-width: 100%;
  height: auto;
}
</style>
