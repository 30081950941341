<template>
  <v-container>
    <v-row v-if="shoppingList">
      <v-col cols="12" align="left">
        <div class="d-flex justify-space-between">
          <p class="p-text">{{ shoppingListDates }}</p>
          <v-btn class="mt-n2" medium icon @click="generateShoppingListPdf">
            <v-icon>mdi-download</v-icon>
          </v-btn>
        </div>
        <v-row>
          <v-col cols="8">
            <v-text-field v-model="newItem" placeholder="Add Your Own Item"></v-text-field>
          </v-col>
          <v-col cols="4">
            <j-btn class="mt-4" @click="addCustomItem()">Add</j-btn>
          </v-col>
        </v-row>
        <div v-for="category in Object.keys(shoppingList)" :key="category">
          <v-row>
            <v-col cols="12" class="py-2 ma-0">
              <span class="grey-navy--text text-uppercase title">{{ formatCategoryTitle(category) }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-row align="center" justify="center" class="mb-4" v-for="item in shoppingList[category]" :key="item.id">
                <v-col cols="1" class="pa-0 ma-0">
                  <j-checkbox
                    @change="updatePurchasedStatus(item)"
                    class="ml-4"
                    dense
                    v-model="item.purchased"
                  ></j-checkbox>
                </v-col>
                <v-col
                  :cols="category === 'X__Pantry' ? 11 : 7"
                  :class="item.purchased ? 'strikethrough' : ''"
                  class="pa-0 ma-0 mt-n2 pl-4"
                >
                  {{ itemTitle(item) }}
                </v-col>
                <v-col v-if="category !== 'X__Pantry' && category !== 'A__Your Items'" class="pa-0 ma-0 mt-n2 p-text">
                  {{ itemQuantity(item) }}
                </v-col>
                <v-col v-if="category === 'A__Your Items'" class="pa-0 ma-0 mt-n2">
                  <v-chip color="red" outlined pill @click="removeCustomItem(item.id)">Remove</v-chip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>

    <!-- PDF of shoppinglist-->
    <generate-pdf ref="refGeneratePdf" :title="`Shopping List - ${shoppingListDates}`">
      <template v-slot:content>
        <span v-for="category in Object.keys(shoppingList)" :key="category">
          <h2 class="pdf-heading-2" style="margin-bottom: 10px; margin-top: 10px;">
            {{ formatCategoryTitle(category) }}
          </h2>

          <span class="pdf-copy" v-for="(item, index) in shoppingList[category]" :key="item.id">
            <span :class="{ strikethrough: item.purchased }">{{ itemTitle(item) }}</span>
            <span v-if="itemQuantity(item)" style="font-size: 11px;" :class="{ strikethrough: item.purchased }">
              {{ itemQuantity(item) }}</span
            ><span v-if="index != shoppingList[category].length - 1">,</span>
            <br v-if="(index + 1) % 3 === 0 && category !== 'X__Pantry'" />
            <br v-if="(index + 1) % 5 === 0 && category === 'X__Pantry'" />
          </span>
        </span>
      </template>
    </generate-pdf>
  </v-container>
</template>

<script>
import { CUSTOM_SHOPPING_LIST_ITEM_MUTATION, UPDATE_PURCHASED_ITEM_MUTATION } from "@/graphql/mutations/shoppingList";
import { sortObject } from "@/lib/array";
import GeneratePdf from "@/components/pdf/GeneratePdf";
import ShoppingListPDFMixin from "@/mixins/ShoppingListPDFMixin";

export default {
  name: "ShoppingListView",
  mixins: [ShoppingListPDFMixin],
  components: {
    GeneratePdf,
  },
  props: {
    shoppingListId: {
      type: Number,
      required: true,
    },
    shoppingList: {
      type: Object,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newItem: "",
    };
  },
  computed: {
    shoppingListDates() {
      return `${this.formatDate(this.startDate)} - ${this.formatEndDate(this.startDate)}`;
    },
  },
  methods: {
    updatePurchasedStatus(item) {
      this.$apollo.mutate({
        mutation: UPDATE_PURCHASED_ITEM_MUTATION,
        variables: {
          data: {
            id: item.id,
            purchased: item.purchased,
          },
        },
      });
    },
    async addCustomItem() {
      let response = await this.$apollo.mutate({
        mutation: CUSTOM_SHOPPING_LIST_ITEM_MUTATION,
        variables: {
          data: {
            mode: "CREATE",
            shoppingListId: this.shoppingListId,
            customTitle: this.newItem,
          },
        },
      });
      if (response.data.customShoppingListItemMutation.success) {
        //we need to inject this item into the current list
        let newItem = {
          id: response.data.customShoppingListItemMutation.item.id,
          customItem: this.newItem,
          isCustomItem: true,
          portionMeasurementUnit: null,
          purchased: false,
          quantity: 0,
        };
        "A__Your Items" in this.shoppingList
          ? this.shoppingList["A__Your Items"].push(newItem)
          : (this.shoppingList["A__Your Items"] = [newItem]);
        this.shoppingList = sortObject(this.shoppingList);
      }
      this.newItem = "";
    },
    async removeCustomItem(id) {
      let response = await this.$apollo.mutate({
        mutation: CUSTOM_SHOPPING_LIST_ITEM_MUTATION,
        variables: {
          data: {
            mode: "DELETE",
            shoppingListId: this.shoppingListId,
            id: id,
          },
        },
      });
      if (response.data.customShoppingListItemMutation.success) {
        let element = this.shoppingList["A__Your Items"].findIndex((element) => element.id === id);
        this.shoppingList["A__Your Items"].splice(element, 1);
        if (this.shoppingList["A__Your Items"].length === 0) {
          delete this.shoppingList["A__Your Items"];
        }
      }
    },
  },
};
</script>
