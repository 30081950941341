export const ADD_PARAMS_TO_LOCATION = (route, params) => {
  history.pushState(
    {},
    null,
    route.path +
      "?" +
      Object.keys(params)
        .map((key) => {
          return (
            encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
          );
        })
        .join("&")
  );
}