import gql from "graphql-tag";

export const MEDICAL_CONDITIONS_QUERY = gql`
  query {
    medicalConditions {
      edges {
        node {
          id
          condition
        }
      }
    }
  }
`;