<template>
  <checkin-stepper :step="8" :title="{ text: 'You\'re', strongText: 'done!' }" @next="next"> </checkin-stepper>
</template>

<script>
import CheckinStepper from "@/components/checkin/CheckinStepper";

export default {
  name: "ThankYou",
  components: {
    CheckinStepper,
  },
};
</script>
