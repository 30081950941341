<template>
  <v-container> </v-container>
</template>

<script>
export default {
  name: "PartnerRegister",
  data() {
    return {};
  },
  props: {
    productCode: {
      type: String,
      required: true,
    },
  },
  async created() {
    sessionStorage.setItem("product-code", this.productCode);
    // if user is logged in and navigating here, we can redirect them straight to pricing.
    if (this.$store.getters.isLoggedIn) {
      await this.$router.push("/start/pricing");
    } else {
      await this.$router.push("/start/register");
    }
  },
};
</script>
