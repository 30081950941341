<template>
  <j-card rounded="lg">
    <v-toolbar class="mb-6">
      <v-toolbar-title>
        <span v-if="!isEditing"><v-icon color="green" :size="30">mdi-checkbox-marked-circle</v-icon> Dot logged!</span>
        <span v-else>Update your dot</span>
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="$emit('closed')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text v-if="!$apollo.loading && activePrizeText && shared">
      <successful-entry-alerts v-if="activePrizeText" :active-prize-text="activePrizeText" />
    </v-card-text>
    <validation-observer v-else ref="obs" v-slot="{ valid, errors, pristine, validate }">
      <v-card-text v-if="workoutBlockWorkoutLog">
        <p class="std-text" v-if="!isEditing">
          <span class="font-weight-bold">Success!</span> You have logged your dot. Now add some more details.
        </p>
        <!-- If a block dot is for a module, allow for comment and date, but nothing else. -->
        <div>
          <div v-if="additionalStatsAllowed" class="text-left pointer">
            <selfie-alert
              :image="data.image"
              :loading="imageLoading"
              :prize-text="activePrizeText"
              @clickUploader="clickUploader"
              @removeSelfie="removeSelfie"
              @input="selectImg($event)"
            />
            <j-alert
              v-if="!loading && activePrizeText && submitted"
              type="warning"
              :icon="false"
              rounded="xl"
              class="text-center"
            >
              <h3 class="mb-2">You're getting 1 entry ☝️</h3>
              <span>Get a <strong>bonus entry</strong> by sharing your photo to a Group or Chat</span>
            </j-alert>
          </div>
          <div v-if="unitActivity.type != 'WORKOUT' && unitActivity.type != 'LIVE_EVENT'">
            <div class="p-text mb-1">Completed on</div>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <j-text-field
                  prepend-inner-icon="mdi-calendar"
                  v-model="data.startDate"
                  hide-details
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="mt-1"
                />
              </template>
              <v-date-picker ref="picker" color="navy" v-model="data.date" @change="saveDate"></v-date-picker>
            </v-menu>
          </div>

          <div v-if="!$apollo.loading">
            <share-options
              v-if="data.image"
              :image="data.image"
              :date="data.startDate"
              :activity="activity"
              :chatData="chatData"
              :with-groups="true"
            />
            <j-checkbox v-model="showAdditionalStats" label="Log Additional Stats" hide-details class="mt-4 ml-4" />
          </div>
          <div v-if="showAdditionalStats" class="mt-5">
            <div v-if="data.isCustom">
              <j-select v-model="data.activityType" label="Activity Type" dense :items="categories['EXERCISE']" />
            </div>
            <j-textarea rows="2" auto-grow dense label="Comments / Description of Activity" v-model="data.comment" />
            <j-text-field
              v-if="additionalStatsAllowed"
              label="Average Heart Rate"
              v-model="data.heartRate"
              dense
              hide-details
              persistent-hint
              suffix="BPM"
              type="number"
              class="mb-6"
              @wheel="$event.target.blur()"
            />
            <j-text-field
              v-if="additionalStatsAllowed"
              label="Calories Burned"
              v-model="data.calories"
              dense
              hide-details
              persistent-hint
              suffix="Calories"
              type="number"
              class="mb-6"
              @wheel="$event.target.blur()"
            />
            <j-text-field
              v-if="additionalStatsAllowed"
              label="Effort"
              v-model="data.effort"
              dense
              persistent-hint
              suffix="1 to 10"
              type="number"
              class="mb-0"
              @wheel="$event.target.blur()"
              :rules="[oneToTenRule]"
            />
            <j-input-mask
              v-if="additionalStatsAllowed"
              v-model="data.seconds"
              :value="data.seconds"
              :mask="['##:##:##']"
              masked
              placeholder="Duration"
              suffix="HH:MM:SS or MM:SS"
              class="mb-5"
            />

            <template
              v-if="
                additionalStatsAllowed ||
                (unitActivity.type == 'WORKOUT' && unitActivity.activity.activityType.name.toLowerCase() == 'run')
              "
            >
              <j-text-field
                label="Distance"
                v-model="data.distance"
                dense
                hide-details
                persistent-hint
                suffix="KM"
                type="number"
                class="mb-6"
                @wheel="$event.target.blur()"
              />
              <j-text-field
                label="Average Pace"
                v-model="data.averagePace"
                dense
                hide-details
                persistent-hint
                suffix="MIN/KM"
                type="text"
                class="mb-6"
                :rules="[runningRule]"
              />
            </template>
          </div>
        </div>

        <div v-if="showAdditionalStats">
          <div v-if="additionalStatsAllowed">
            <div class="p-text mb-1">Completed on</div>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <j-text-field
                  prepend-inner-icon="mdi-calendar"
                  v-model="data.startDate"
                  hide-details
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  class="mt-1"
                />
              </template>
              <v-date-picker ref="picker" color="navy" v-model="data.startDate" @change="saveDate"></v-date-picker>
            </v-menu>
          </div>
        </div>
      </v-card-text>
      <v-card-actions class="justify-center flex-column">
        <j-alert v-if="errors && errors.message && errors.message.length > 0" type="error" class="mb-3">
          {{ errors.message[0] }}
        </j-alert>
        <j-alert v-if="submitted" type="success" class="mb-3">
          Great! Session logged<span v-if="chatData && chatData.isSharing"> and photo shared</span>.
        </j-alert>
        <j-btn
          primary
          :loading="imageLoading || loading"
          @click="
            pristine ? validate() : null;
            confirm(valid);
          "
        >
          <strong class="white--text">SAVE</strong>
          <strong v-if="chatData.isSharing" class="white--text"> AND SEND</strong>
        </j-btn>

        <div class="mt-4 p-text pointer font-weight-bold" @click="$emit('closed')">Close</div>
      </v-card-actions>
    </validation-observer>
  </j-card>
</template>

<script>
import { WORKOUT_BLOCK_WORKOUT_LOG } from "@/graphql/queries/workoutblock";
import { ACTIVE_PRIZE_QUERY } from "@/graphql/queries/prizeCalendar";
import { dataURLtoBlob, blobToFile } from "@/lib/file";
import { oneToTenRule, runningRule } from "@/lib/validationRules";
import { decodeId, secondsToTimeString } from "@/lib/string";
import { getMonday } from "@/lib/calendarWeek";
import DateMixin from "@/mixins/DateMixin";
import SelfieAlert from "@/components/tracker/activity-form/SelfieAlert";
import ShareOptions from "@/components/tracker/activity-form/ShareOptions";
import ImageSaveMixin from "@/mixins/chats/ImageSaveMixin";
import SuccessfulEntryAlerts from "@/components/tracker/activity-form/SuccessfulEntryAlerts.vue";
import { UPSERT_ACTIVITY_MUTATION } from "@/graphql/mutations/tracker";
import { ACTIVITY_TYPES_QUERY } from "@/graphql/queries/tracker";

export default {
  name: "EnhanceWorkoutLog",
  mixins: [DateMixin, ImageSaveMixin],
  components: {
    SelfieAlert,
    ShareOptions,
    SuccessfulEntryAlerts,
  },
  data() {
    return {
      imageLoading: false,
      workoutBlockWorkoutLog: null,
      menu: false,
      data: {},
      oneToTenRule: oneToTenRule,
      runningRule: runningRule,
      showAdditionalStats: true,
      imageChanged: false,
      submitted: false,
      shared: false,
      categories: [],
      chatData: {
        sendToChats: [],
        sendToGroups: [],
        isSharing: false,
        chatText: null,
      },
    };
  },
  props: {
    activityId: {
      type: [Number, String],
      required: true,
    },
    subscriptionBlockId: {
      type: [Number, String],
      required: true,
    },
    unitActivity: {
      type: Object,
      required: true,
    },
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    activity() {
      return this.data.unitActivity?.activity;
    },
    additionalStatsAllowed() {
      return (
        this.unitActivity.type == "LIVE_EVENT" ||
        this.unitActivity.type == "REST_DAY" ||
        this.unitActivity.type == "WORKOUT"
      );
    },
  },
  methods: {
    async removeSelfie() {
      this.data.image = null;
      this.imageChanged = true;
    },
    saveDate(date) {
      this.$refs.menu.save(date);
    },
    clickUploader() {
      document.getElementById("selfie-image-uploader").click();
    },
    async selectImg(data) {
      this.submitted = false;
      this.imageLoading = true;
      const blob = dataURLtoBlob(data.dataUrl);
      const file = blobToFile(blob);
      let input = {
        id: this.activityId,
        subscriptionBlockId: decodeId(this.subscriptionBlockId),
        unitActivityId: decodeId(this.unitActivity.id),
        image: file,
        time: this.$moment().format("HH:mm:ss"),
      };
      await this.$apollo.mutate({
        mutation: UPSERT_ACTIVITY_MUTATION,
        variables: {
          data: input,
        },
        refetchQueries: ["assistantQuery", "accountQuery"],
      });
      this.$apollo.queries.workoutBlockWorkoutLog?.refetch();
      setTimeout(() => (this.imageLoading = false), 250);
      this.data.image = data.dataUrl;
      this.submitted = true;
    },
    async confirm(valid) {
      this.submitted = false;
      // user has chosen to remove the image
      if (!this.data.image && this.imageChanged) {
        this.imageLoading = true;
        let input = {
          id: this.activityId,
          subscriptionBlockId: decodeId(this.subscriptionBlockId),
          unitActivityId: decodeId(this.unitActivity.id),
          imageChanged: true,
          image: null,
          time: this.$moment().format("HH:mm:ss"),
        };

        await this.$apollo.mutate({
          mutation: UPSERT_ACTIVITY_MUTATION,
          variables: {
            data: input,
          },
          refetchQueries: ["assistantQuery", "accountQuery"],
        });
        this.imageLoading = false;
      }

      if (valid) {
        await this.enrichDot(
          this.activityId,
          this.data,
          this.chatData,
          "activity",
          decodeId(this.subscriptionBlockId),
          decodeId(this.unitActivity.id)
        );
        this.submitted = true;
      }
    },
  },
  apollo: {
    workoutBlockWorkoutLog: {
      query: WORKOUT_BLOCK_WORKOUT_LOG,
      fetchPolicy: "network-only",
      variables() {
        return {
          subscriptionBlockId: decodeId(this.subscriptionBlockId),
          unitActivityId: decodeId(this.unitActivity.id),
        };
      },
      result(response) {
        if (response.data && response.data.workoutBlockWorkoutLog) {
          this.data = response.data.workoutBlockWorkoutLog;

          if (this.data.seconds && !isNaN(this.data.seconds)) {
            this.data.seconds = secondsToTimeString(this.data.seconds);
          }
          if (this.data.__typename) {
            delete delete this.data.__typename;
          }
        }
      },
    },
    activePrizeText: {
      query: ACTIVE_PRIZE_QUERY,
      variables() {
        return {
          date: this.$moment(getMonday(this.$moment().format("YYYY-MM-DD"))).format("YYYY-MM-DD"),
        };
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        let prize = data.activePrize;
        return prize?.entryPerSelfie ? prize?.entryPerSelfiePrizeText : null;
      },
    },
    activityTypes: {
      query: ACTIVITY_TYPES_QUERY,
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.loading) return;

        if (response.data) {
          let categories = {};
          response.data.activityTypes.forEach((item) => {
            if (item.category !== "OTHER") {
              if (!categories[item.category]) categories[item.category] = [];
              categories[item.category].push({ text: item.name, value: item.id });
            }
          });
          this.categories = categories;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.workout-log-image {
  border-radius: 10px;
}
</style>
