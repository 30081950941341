import { WORKOUT_BLOCK_EXERCISE_LOGS } from "@/graphql/queries/workoutblock";
import { PUT_EXERCISE_LOG_MUTATION } from "@/graphql/mutations/workoutblock";
import { decodeId } from "@/lib/string";

export default {
  name: "ExerciseMixin",
  data() {
    return {
      completedSectionExercises: [],
      panel: 0,
    };
  },
  computed: {
    workoutCompleted() {
      return this.completedSectionExercises.length == this.totalExercises;
    },
    totalExercises() {
      let total = 0;
      this.activitySections.forEach((activity) => {
        total += activity.activitysectionexerciseSet.length;
      });
      return total;
    },
  },
  methods: {
    async updateLog() {
      const input = {
        subscriptionBlockId: this.subscriptionBlockId,
        unitActivityId: this.unitActivity?.id,
        eventId: this.event?.id,
        activitySectionExerciseIds: this.completedSectionExercises,
        date: this.$moment().format("YYYY-MM-DD"),
      };

      await this.$apollo.mutate({
        mutation: PUT_EXERCISE_LOG_MUTATION,
        variables: {
          data: input,
        },
      });
    },
    async completeExercise(exerciseId, completed) {
      let inArray = this.completedSectionExercises.includes(exerciseId);
      if (inArray && !completed) {
        let index = this.completedSectionExercises.indexOf(exerciseId);
        this.completedSectionExercises.splice(index, 1);

        //lets go back to this exercise to do it again!
        let panel = this.activitySections.find((element) =>
          element.activitysectionexerciseSet.map((e) => e.id).includes(exerciseId)
        );
        if (panel) {
          let component = this.$refs["panel_" + panel.id][0];
          if (component) component.jumpToFirstIncompleteExercise();
        }
      } else if (!inArray && completed) {
        this.completedSectionExercises.push(exerciseId);
      }
      await this.updateLog();
    },
    async repeatWorkout() {
      this.completedSectionExercises = [];
      await this.updateLog();
    },
    nextSection() {
      this.panel++;
      if (this.workoutCompleted) {
        this.$bus.$emit("logDot");
      } else {
        //we might need to wrap back to a previous panel that has incomplete exercises
        if (this.panel === this.activitySections.length) {
          let incompleteIndex = this.activitySections.findIndex((element) => {
            const exerciseIds = element.activitysectionexerciseSet.map((e) => e.id);
            const filteredArray = exerciseIds.filter((value) => this.completedSectionExercises.includes(value));
            return filteredArray.length < exerciseIds.length;
          });
          if (incompleteIndex > -1) {
            this.panel = incompleteIndex;
          } else {
            this.panel = 0;
          }
        }
      }
    },
  },
  apollo: {
    workoutBlockExerciseLogs: {
      query: WORKOUT_BLOCK_EXERCISE_LOGS,
      variables() {
        return {
          subscriptionBlock: this.subscriptionBlockId
            ? window.btoa(`SubscriptionBlockNode: ${this.subscriptionBlockId}`)
            : null,
          unitActivity: this.unitActivity?.id ? window.btoa(`UnitActivityNode: ${this.unitActivity.id}`) : null,
          event: this.event?.id ? window.btoa(`EventNode: ${this.event.id}`) : null,
          user: this.event?.id ? window.btoa(`UserNode: ${this.$store.getters.user.id}`) : null,
        };
      },
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data) {
          this.completedSectionExercises = response.data?.workoutBlockExerciseLogs?.edges.map((log) => {
            return decodeId(log.node?.activitySectionExercise?.id);
          });
        }
      },
    },
  },
};
