<template>
  <j-card class="pb-4">
    <v-toolbar flat>
      <v-toolbar-title class="navy--text">
        <span class="heading">Log a rest day</span>
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon color="text-grey" class="float-right" @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>

    <!-- previously this used logging-selection-button, but i couldn't get the prop reactivity working without breaking the other logging -->
    <j-card width="100%" class="rounded-xl elevation-6 mt-4 mb-10">
      <v-row justify="center" align="center">
        <v-col
          v-for="option in options.planned"
          :key="option.value"
          cols="4"
          class="option-hover"
          align="center"
          @click="form.planned = option.value"
        >
          <v-icon size="20" color="rest-day">
            {{ form.planned == option.value ? "fiber_manual_record" : "radio_button_unchecked" }}</v-icon
          >
          <span class="caption block">{{ option.text }}</span>
        </v-col>
      </v-row>
    </j-card>
    <v-card-text class="px-4">
      <span>
        <strong class="header secondary--text mr-2">Details</strong>
        <v-chip v-if="form.planned" text-color="#747678" class="font-16">
          Optional
        </v-chip>
        <v-chip v-else color="#fff3ce" text-color="#c29400" class="font-16">
          Required
        </v-chip>
      </span>
      <v-radio-group v-model="form.details" class="mt-2 pb-0 max-width">
        <v-radio
          v-for="(option, index) in options.details"
          :key="index"
          :label="option.text"
          :value="option.value"
        ></v-radio>
      </v-radio-group>

      <v-text-field v-if="form.details == 'other'" v-model="form.description" label="Details" />
    </v-card-text>
    <v-card-actions v-if="isNew">
      <v-spacer />
      <v-btn color="secondary" rounded large class="px-10" :disabled="!valid" @click="submit()">
        <strong class="white-font">Save rest day</strong>
      </v-btn>
      <v-spacer />
    </v-card-actions>
    <v-card-actions v-else>
      <v-btn color="secondary" rounded large class="px-8" :disabled="!valid || !changed" @click="submit()">
        <strong class="white-font">Update</strong>
      </v-btn>
      <v-spacer />
      <v-btn rounded large color="background-grey" class="secondary--text px-8" @click="deleteRestDay()">
        <strong>Delete</strong>
      </v-btn>
    </v-card-actions>
  </j-card>
</template>

<script>
import { REST_DAYS_QUERY } from "@/graphql/queries/tracker";
import { REST_OPTIONS } from "@//constants/json/restOptions";
import { mapGetters } from "vuex";
import RestDayMixin from "@/mixins/RestDayMixin";
import { decodeId } from "@/lib/string";

export default {
  name: "RestDayLogging",
  mixins: [RestDayMixin],
  props: {
    isRestDay: {
      type: Boolean,
      default: false,
    },
    unitActivity: {
      type: Object,
      required: false,
    },
    subscriptionBlock: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      options: REST_OPTIONS,
      form: {
        didRest: true,
        planned: null,
        details: null,
        description: "",
      },
      restDay: {},
    };
  },
  computed: {
    ...mapGetters(["selectedDate"]),
    valid() {
      return this.form.planned != null && ((!this.form.planned && this.form.details) || this.form.planned);
    },
    changed() {
      return !(
        this.form.planned == this.userRestDay.planned &&
        this.form.details == this.userRestDay.details &&
        this.form.description == this.userRestDay.description
      );
    },
    isNew() {
      return this.userRestDay == null || this.userRestDay.id == null;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    submit() {
      const input = {
        ...this.form,
      };
      if(this.unitActivity && this.subscriptionBlock) {
        input['subscriptionBlockId'] = decodeId(this.subscriptionBlock.id);
        input['unitActivityId'] = decodeId(this.unitActivity.id);
      }
      this.setRestDay(input);
      this.close();
    },
    deleteRestDay() {
      this.unsetRestDay();
      this.close();
    },
  },
  apollo: {
    userRestDay: {
      query: REST_DAYS_QUERY,
      variables() {
        return {
          date: this.formattedDate(),
        };
      },
      fetchPolicy: "network-only",
      result(response) {
        let restDay = response.data.userRestDay;
        if (restDay) {
          restDay.planned = restDay.planned ? 1 : 0;
          restDay.details = restDay.details ? restDay.details.toLowerCase() : null;
          // required for cloning; to know if a change has taken place
          this.form = Object.assign({}, restDay);
        }
        return restDay;
      },
    },
  },
};
</script>

<style scoped>
.heading {
  font-size: 30px;
}
.header {
  text-transform: uppercase;
  font-size: 16px;
}
.font-16 {
  font-size: 16px;
}
.max-width {
  max-width: 150px;
}
.option-hover:hover {
  background-color: var(--v-background-grey-base);
  cursor: pointer;
}
</style>
