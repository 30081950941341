<template>
  <j-banner-container
    v-if="workoutBlock"
    :header="`${workoutBlock.title}`"
    :gradient="['green-background', 'yellow-background']"
    :textGradient="['mindset', 'nutrition']"
  >
    <j-card rounded="lg">
      <v-card-text>
        <p
          class="text-left p-text"
          v-if="workoutBlock.description"
          v-html="formatDescription(workoutBlock.description)"
        ></p>

        <j-btn
          v-if="workoutBlock.activityPlanner"
          tertiary
          narrow
          :href="workoutBlock.activityPlanner"
          target="_blank"
          class="mb-4"
        >
          <span>Activity Planner</span>
          <v-icon class="ml-2">mdi-file-link</v-icon>
        </j-btn>

        <div v-for="unit in workoutBlock.unitSet" :key="unit.id" class="mb-4">
          <h3 class="mb-2 text-left">{{ unit.title }}</h3>
          <j-avatar-card
            class="mb-4"
            v-for="unitActivity in unit.unitactivitySet"
            :key="unitActivity.id"
            :title="getTitle(unitActivity)"
            :subtitle="getSubTitle(unitActivity, unit)"
            :avatar-url="getDefaultIcon(unitActivity)"
            :navigate-to="navigateTo(unitActivity, unit)"
          >
            <template v-slot:trailing>
              <div v-if="subscriptionBlockId">
                <v-icon :color="getColour(unitActivity)">
                  {{
                    hasCompleted(
                      unitActivity,
                      restDays,
                      customActivities,
                      calculateActivityDate(unitActivity, unit, startDate),
                      subscriptionBlockId
                    )
                      ? "fiber_manual_record"
                      : ""
                  }}
                </v-icon>
              </div>
            </template>
          </j-avatar-card>
        </div>
      </v-card-text>
    </j-card>
  </j-banner-container>
</template>

<script>
import { WORKOUT_BLOCK_QUERY, WORKOUT_BLOCK_WORKOUT_LOGS } from "@/graphql/queries/workoutblock";
import { capitalize, decodeId, nl2br, toGraphqlID } from "@/lib/string";
import BlockMixin from "@/mixins/BlockMixin";
import { ACTIVITIES_AND_NUTRITION_QUERY } from "@/graphql/queries/tracker";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "WorkoutBlockOverview",
  mixins: [BlockMixin],
  data() {
    return {
      workoutBlock: null,
      workoutBlockWorkoutLogs: null,
      subscriptionBlockId: null,
      // subscription block start date
      startDate: null,
      workoutSubscriptionBlocks: null,
      customActivities: [],
    };
  },
  props: {
    blockId: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapGetters(["restDays"]),
  },
  methods: {
    ...mapActions(["setRestDays"]),
    formatDescription(string) {
      return nl2br(string);
    },
    getDefaultIcon(unitActivity) {
      if (unitActivity.activity) {
        if (unitActivity.activity.image) {
          return unitActivity.activity.image;
        }
        return unitActivity.activity.leadInstructor.userProfile.profilePicture;
      }
      if (unitActivity.type) {
        return require(`@/assets/images/activity_icons/${unitActivity.type}.png`);
      }
      return null;
    },
    getTitle(unitActivity) {
      if (unitActivity.activity) {
        return unitActivity.activity.title;
      }
      if (unitActivity.alternateActivity) {
        return unitActivity.alternateActivity.title;
      }
      return this.friendlyType(unitActivity.type);
    },
    getSubTitle(unitActivity, unit) {
      let subTitle = unitActivity.dayTitle;
      if (this.startDate || this.subscriptionBlockId) {
        const activityDate = this.calculateActivityDate(unitActivity, unit, this.startDate);
        if (this.$moment(activityDate).isAfter(this.$moment())) {
          subTitle = this.$moment(activityDate).format("ddd, D MMM YY");
        }
      }
      let append = "";
      if (unitActivity.activity?.durationMinutes) {
        append = `<div class="caption navy--text">${unitActivity.activity.durationMinutes} mins</div>`;
      }
      if (unitActivity.activity?.shortDescription) {
        append += `<div class="sm-text">${unitActivity.activity.shortDescription}</div>`;
      }
      return `${subTitle}${append}`;
    },
    friendlyType(type) {
      if (type === "LIVE_EVENT") {
        return "Freestyle";
      }
      return capitalize(type);
    },
    navigateTo(unitActivity, unit) {
      if (!this.subscriptionBlockId || !this.startDate || this.$moment().isBefore(this.startDate)) {
        return null;
      }

      if (unitActivity.type == "REST_DAY" || unitActivity.type == "LIVE_EVENT") {
        return null;
      }
      const activityDate = this.calculateActivityDate(unitActivity, unit, this.startDate);
      if (activityDate) {
        return `/unit-activity/${unitActivity.id}?sbid=${this.subscriptionBlockId}&d=${activityDate}`;
      }
      return null;
    },
    subscriptionBlockEndDate() {
      return this.$moment(this.startDate).add(this.workoutBlock.duration, "days").format("YYYY-MM-DD");
    },
  },
  apollo: {
    workoutBlock: {
      query: WORKOUT_BLOCK_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          workoutBlockId: this.blockId,
          withProductVariant: false,
        };
      },
    },
    workoutBlockWorkoutLogs: {
      query: WORKOUT_BLOCK_WORKOUT_LOGS,
      fetchPolicy: "network-only",
      skip() {
        return !this.subscriptionBlockId;
      },
      variables() {
        return {
          subscriptionBlockId: toGraphqlID("SubscriptionBlock", decodeId(this.subscriptionBlockId)),
        };
      },
      result(response) {
        if (response.data) {
          this.workoutBlockWorkoutLogs = response.data.workoutBlockWorkoutLogs.edges;
        }
      },
    },
    // custom activites dots and rest days
    activities: {
      query: ACTIVITIES_AND_NUTRITION_QUERY,
      variables() {
        return {
          startDate: this.startDate,
          endDate: this.subscriptionBlockEndDate,
          userId: btoa(`UserNode: ${this.$store.getters.user.id}`),
        };
      },
      skip() {
        return !this.subscriptionBlockId || !this.startDate || !this.subscriptionBlockEndDate;
      },
      fetchPolicy: "network-only",
      result(response) {
        if (response.data) {
          // set logs in Vuex
          this.setRestDays(response.data.restDays.edges);
          this.customActivities = response.data.activities.edges.map((item) => {
            return item.node;
          });
        }
      },
    },
  },
  created() {
    if (this.$route.query.sbid) {
      this.subscriptionBlockId = this.$route.query.sbid;
    }
    if (this.$route.query.sd) {
      this.startDate = this.$route.query.sd;
    }
  },
};
</script>
