<template>
  <checkin-stepper
    :step="3"
    :title="{ text: 'Get ready for', strongText: 'personalised recommendations' }"
    @next="next"
  >
    <ValidationObserver ref="obs">
      <j-card class="pa-2">
      <personal-preferences ref="preferences" />
    </j-card>
    </ValidationObserver>
  </checkin-stepper>
</template>

<script>
import PersonalPreferences from "@/components/support/PersonalPreferences";
import CheckinStepper from "@/components/checkin/CheckinStepper";

export default {
  name: "Personal",
  components: {
    CheckinStepper,
    PersonalPreferences,
  },
  methods: {
    previous() {
      return this.$router.push("/checkin/instructors");
    },
    async next(nextStepLink) {
      let valid = await this.$refs.obs.validate();
      if (valid) {
        let saveResult = await this.$refs.preferences.save();
        if (saveResult) {
          return this.$router.push(nextStepLink);
        }
      }
    },
  },
};
</script>
