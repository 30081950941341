<template>
  <div>
    <div class="recommended-wrapper" @click="navigateTo(workoutBlock.id)" :class="{ pointer: navigate }">
      <j-btn v-if="showRecommended" :gradient="['one-on-one', 'yellow']" class="cursor-default button">
        Recommended
      </j-btn>
      <v-img :src="workoutBlock.image" />
    </div>

    <div class="pa-4">
      <h3 @click="navigateTo(workoutBlock.id)" :class="{ pointer: navigate }">{{ workoutBlock.title }}</h3>
      <j-gradient-text :gradient="['pink', 'yellow']">
        <strong>With {{ workoutBlock.leadInstructor.firstName }}</strong>
        <strong v-if="workoutBlock.secondaryInstructor"> and {{workoutBlock.secondaryInstructor.firstName}}</strong>
      </j-gradient-text>
    </div>

    <block-header
      :show-cta="false"
      :workout-block="workoutBlock"
      :is-mine="false"
      :is-current="false"
      :has-ended="false"
    />
  </div>
</template>

<script>
import { decodeId } from "@/lib/string";
import BlockHeader from "@/components/workoutblocks/cards/BlockHeader";
export default {
  name: "CondensedBlock",
  props: {
    workoutBlock: {
      type: Object,
      required: true,
    },
    showRecommended: {
      type: Boolean,
      default: false,
    },
    navigate: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    BlockHeader,
  },
  methods: {
    navigateTo(blockId) {
      if (!this.navigate) {
        return;
      }
      this.$router.push(`/program/${decodeId(blockId)}`);
    },
  },
};
</script>

<style scoped lang="scss">
.recommended-wrapper {
  position: relative;

  .button {
    position: absolute;
    top: -10px;
    right: 0;
    z-index: 1;
  }
}
</style>
