<template>
  <v-row>
    <image-uploader
      :id="label + 'uploader'"
      :maxWidth="1024"
      hidden
      :preview="false"
      :quality="0.9"
      outputFormat="verbose"
      accept="image/*"
      @input="selectImg($event)"
    >
    </image-uploader>
    <v-icon @click="clickUploader">attach_file</v-icon>
    <v-text-field
      :value="imgName()"
      clearable
      readonly
      class="mx-2"
      :label="label"
      @click:clear="clearImage()"
      @input="clickUploader"
      @click="clickUploader"
    >
    </v-text-field>
  </v-row>
</template>

<script>
import ImageUploader from "vue-image-upload-resize";

export default {
  name: "CheckInUpload",
  components: {
    ImageUploader,
  },
  props: ["label"],
  data() {
    return {
      image: null,
    };
  },
  watch: {
    image: function(value) {
      this.$emit("imageChanged", value);
    },
  },
  methods: {
    clearImage() {
      this.image = null;
    },
    clickUploader() {
      document.getElementById(this.label + "uploader").click();
    },
    imgName() {
      return this.image && this.image.info.name ? this.image.info.name : "";
    },
    selectImg(file) {
      this.image = file;
    },
  },
};
</script>
