<template>
  <j-card rounded="lg" class="pb-4">
    <v-toolbar class="mb-6">
      <v-toolbar-title>
        <v-icon color="whatsapp-green" size="36">mdi-whatsapp</v-icon>
        Accountability Group</v-toolbar-title
      >
      <v-spacer />
      <v-btn icon @click="$emit('closed')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <div class="mt-4">
        <video-block youtubeId="MwiUvzBt1HI" :show-video-cast-message="false" />
      </div>

      <div class="mt-4">
        <h3 class="navy--text mb-n2">Do you want to be added to an Accountability Group on WhatsApp?</h3>
        <j-radio-group v-model="confirmCheck" row>
          <j-radio label="Yes" :value="true" />
          <j-radio label="No" :value="false" />
        </j-radio-group>
      </div>
      <div class="d-flex mb-4">
        <v-icon color="whatsapp-green" size="36">mdi-whatsapp</v-icon>
        <div class="ml-2">
          You'll be grouped with people on your program. You will receive an email invite to join the WhatsApp Group
          once it is created.
        </div>
      </div>

      <div class="text-center">
        <j-btn :secondary="confirmCheck" @click="confirm()">
          CONFIRM
        </j-btn>
      </div>
    </v-card-text>
  </j-card>
</template>

<script>
import { nextDateForDay } from "@/lib/fancy_date";
import VideoBlock from "@/components/events/VideoBlock";
export default {
  name: "JoinChat",
  components: {
    VideoBlock,
  },
  data() {
    return {
      confirmCheck: true,
    };
  },
  computed: {
    nextSunday() {
      return this.$moment(nextDateForDay(7, true)).format("D MMM");
    },
  },
  methods: {
    confirm() {
      if (!this.confirmCheck) {
        this.$emit("closed");
      } else {
        this.$emit("confirm");
      }
    },
  },
};
</script>
