<template>
  <j-card rounded="lg" class="pb-4">
    <v-toolbar class="mb-6">
      <v-toolbar-title>
        <simple-chat-header :chat="chat" classes="justify-center" />
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="$emit('closed')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <h4 class="navy--text">Notifications</h4>
      <div class="d-flex justify-space-between align-start">
        <p>Receive push notifications</p>
        <v-switch
          class="mt-0 pt-0"
          v-model="chat.userChat.receiveNotifications"
          hide-details
          @change="toggleNotifications($event)"
        />
      </div>

      <template v-if="chat.type === 'ACCOUNTABILITY'">
        <h4 class="navy--text">Privacy</h4>
        <div class="d-flex justify-space-between align-start">
          <p>Share my weekly overview of selfies and food pics, with the members of the chat</p>
          <v-switch class="mt-0 pt-0" v-model="chat.userChat.shareData" hide-details @change="togglePrivacy($event)" />
        </div>
      </template>
    </v-card-text>
  </j-card>
</template>

<script>
import { decodeId } from "@/lib/string";
import SimpleChatHeader from "@/components/chat/SimpleChatHeader";
import { UPSERT_USER_CHAT_MUTATION } from "@/graphql/mutations/chat";

export default {
  name: "ChatSettings",
  components: {
    SimpleChatHeader,
  },
  props: {
    chat: {
      type: Object,
      required: true,
    },
  },
  methods: {
    async upsertChat(chat) {
      await this.$apollo.mutate({
        mutation: UPSERT_USER_CHAT_MUTATION,
        variables: {
          data: {
            chatId: decodeId(chat.id),
            receiveNotifications: chat.receiveNotifications,
            shareData: chat.shareData,
          },
        },
      });
    },
    async toggleNotifications(event) {
      const result = await Notification.requestPermission();

      const $vm = this;
      if (result === "denied" || result === "default") {
        $vm.$OneSignal.showSlidedownPrompt();
        return;
      }
      const chat = {
        id: decodeId(this.chat.id),
        receiveNotifications: event,
        shareData: this.chat.userChat.shareData,
      };
      this.upsertChat(chat);
    },
    togglePrivacy(event) {
      const chat = {
        id: decodeId(this.chat.id),
        receiveNotifications: this.chat.userChat.receiveNotifications,
        shareData: event,
      };
      this.upsertChat(chat);
    },
  },
};
</script>
