import gql from 'graphql-tag'

export const VIRTUAL_EVENT_QUERY = gql`
query virtualEvent($id: ID!) {
  virtualEvent(id: $id){
    id
    title
    description
    dedicationText
    hasDedication
    thumbnail
    startDate
    endDate
    hasRaceNumber
  }
}
`

