<template>
  <v-container v-if="userId">
    <v-row v-if="$apollo.loading" align="center" justify="center" class="my-4">
      <v-col cols="12" md="6" class="col-auto px-0">
        <v-skeleton-loader type="card-avatar" />
      </v-col>
    </v-row>
    <div v-else>
      <v-row justify="center">
        <v-col cols="12" md="6" class="pa-0">
          <j-card elevation="0" rounded="sm" v-if="user">
            <div style="min-height: 130px" :style="backgroundGradientStyle">
              <v-row v-if="canViewData && user.userProfile.coverImage" no-gutters>
                <template>
                  <v-col>
                    <v-img :src="user.userProfile.coverImage" :lazy-src="user.userProfile.coverImage" />
                  </v-col>
                </template>
              </v-row>
              <v-row v-else no-gutters align="center" class="flex-nowrap overflow-hidden cover-image">
                <template v-if="canViewData">
                  <v-col v-for="img in imageList.slice(0, 6)" :key="img.node.id" cols="6" sm="3" lg="2">
                    <v-img
                      v-if="img.node.image"
                      :src="img.node.image"
                      class="cover-image"
                      :lazy-src="img.node.image"
                      min-height="130px"
                    />
                  </v-col>
                </template>
              </v-row>
              <div v-if="myProfile" class="text-center pointer edit-icon">
                <v-avatar color="blue-background" size="30">
                  <v-progress-circular
                    v-if="imageLoading == 'cover'"
                    indeterminate
                    color="primary"
                  ></v-progress-circular>
                  <v-icon v-else color="navy" small @click="clickUploader('cover')"> mdi-image-edit-outline</v-icon>
                </v-avatar>
              </div>

              <div v-if="staffViewData" class="green-background p-text font-weight-medium pa-2 pr-3">
                <div class="d-flex text-end justify-end left-space">
                  Profile viewable because you are staff
                  <v-icon class="ml-1">mdi-account</v-icon>
                </div>
              </div>
            </div>
            <v-card-text>
              <div class="d-flex justify-space-between">
                <div class="mt-n16">
                  <user-avatar
                    :profile-picture="user.userProfile ? user.userProfile.profilePicture : null"
                    :user="user ? user : null"
                    :size="profileSize"
                    highlight-avatar
                    highlight-colour="blue-background"
                  />
                  <div v-if="myProfile" class="text-center pointer camera-icon">
                    <v-avatar color="blue-background" size="35">
                      <v-progress-circular
                        v-if="imageLoading == 'profile'"
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                      <v-icon v-else color="#000" @click="clickUploader('profile')"> mdi-camera-plus</v-icon>
                    </v-avatar>
                  </div>
                </div>
                <div class="text-end">
                  <j-btn v-if="myProfile" small narrow secondary to="/account/general">
                    Edit profile
                    <v-icon>mdi-account-edit</v-icon>
                  </j-btn>
                  <template v-else>
                    <j-btn
                      v-if="!user.userProfile.friendship || user.userProfile.friendship.status == 'REJECTED'"
                      small
                      @click="createFriendRequest()"
                      :loading="loading"
                    >
                      Add friend <v-icon>mdi-account-plus-outline</v-icon>
                    </j-btn>
                    <j-btn
                      v-else-if="user.userProfile.friendship.status == 'PENDING'"
                      :disabled="true"
                      small
                      tertiary
                      narrow
                      to="/account/general"
                    >
                      Pending
                      <v-icon>mdi-email-send-outline</v-icon>
                    </j-btn>
                    <v-menu v-else rounded offset-y>
                      <template v-slot:activator="{ attrs, on }">
                        <v-btn small rounded dark class="j-btn navy" v-bind="attrs" v-on="on">
                          Friends <v-icon small class="mx-1">mdi-account-check-outline</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item @click="deleteDialog = true">
                          <v-list-item-title> Remove friend</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                  <span v-if="user.userProfile.friendship && user.userProfile.friendship.status === 'APPROVED'">
                    <v-progress-circular
                      v-if="chatLoading"
                      indeterminate
                      :width="4"
                      :size="22"
                      class="ml-2"
                      color="navy"
                    ></v-progress-circular>
                    <v-icon v-else class="ml-2 pointer navy--text" :size="34" @click="goToChat(user.id)"
                      >mdi-chat</v-icon
                    >
                  </span>
                  <span
                    v-if="user.userProfile.friendsCount > 0"
                    class="block mt-2 pointer"
                    @click="openfriendsDialog = true"
                  >
                    <strong>{{ user.userProfile.friendsCount }}</strong> Friend<span
                      v-if="user.userProfile.friendsCount && user.userProfile.friendsCount > 1"
                      >s</span
                    >
                    <span v-if="!myProfile && user.userProfile.friendship">
                      • <strong>{{ user.userProfile.friendship.mutualFriendsCount }}</strong> Mutual</span
                    >
                  </span>
                </div>
              </div>
              <div class="ml-2">
                <h4 class="navy--text">
                  {{ user.firstName }} {{ user.lastName }} <user-type-highlight :user="user" />
                </h4>
                <span v-if="user.userProfile.biography" class="caption block">{{ user.userProfile.biography }}</span>
                <span class="caption block"
                  ><v-icon small>mdi-calendar-month</v-icon> Joined <b>{{ $appName }}</b>
                  {{ user.dateJoined | moment("MMM YYYY") }}</span
                >
                <p v-if="subscription.name" :class="`${subscription.color}--text`">
                  <v-icon small :color="subscription.color">
                    {{ subscription.icon }}
                  </v-icon>
                  {{ subscription.name }}
                  <j-btn
                    v-if="myProfile && subscription.upgradeLink"
                    color="pink"
                    small
                    narrow
                    class="ml-2"
                    elevation="0"
                    :to="subscription.upgradeLink"
                  >
                    Upgrade
                  </j-btn>
                </p>
                <j-alert v-if="!canViewData" type="warning" :icon="false" class="text-center mt-2">
                  <span v-if="user.userProfile.friendship && user.userProfile.friendship.status == 'PENDING'">
                    You have a pending friendship request with {{ user.firstName }}
                  </span>
                  <span v-else> You must be friends with {{ user.firstName }} to see their profile </span>
                </j-alert>
              </div>
            </v-card-text>

            <streak-calendar-dots v-if="canViewData" read-only :user-id="getId(userId)" class="pb-6 pt-0 pl-6 pr-3" />
          </j-card>
        </v-col>
      </v-row>
      <v-row v-if="user && canViewData" justify="center">
        <v-col cols="12" md="6">
          <j-card class="j-elevated-1 mx-auto" align="left">
            <v-list-item-group class="px-6">
              <v-list-item class="px-0" :to="`/programs/${userId}`">
                <v-list-item-icon class="mr-2">
                  <v-icon class="navy--text">mdi-book-account-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="navy--text text-uppercase font-weight-bold">
                    Programs, menu, courses
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon class="align-center">
                  <v-icon class="navy--text" size="27">mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item class="px-0" :to="myProfile ? '/accountability/dots' : `/activity-stats/${userId}`">
                <v-list-item-icon class="mr-2">
                  <v-icon class="navy--text">mdi-grain</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="navy--text text-uppercase font-weight-bold d-flex">
                    <span class="mr-1">Stats</span>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon class="align-center">
                  <v-icon class="navy--text" size="27">mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item class="px-0" @click="openfriendsDialog = true">
                <v-list-item-icon class="mr-2">
                  <v-icon class="navy--text">mdi-account-multiple</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="navy--text text-uppercase font-weight-bold d-flex align-center justify-space-between"
                  >
                    <span class="mr-1">Friends</span>
                    <friend-avatar-listing :user-id="getId(userId)" class="ml-2" />
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon class="align-center">
                  <span class="p-text mt-1 font-weight-bold">{{ user.userProfile.friendsCount }}</span>
                  <v-icon class="navy--text" size="27">mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-list-item>

              <v-list-item class="px-0" :to="`/badges/${userId}`">
                <v-list-item-icon class="mr-2">
                  <v-icon class="navy--text">mdi-police-badge-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title
                    class="navy--text text-uppercase font-weight-bold d-flex align-center justify-space-between"
                  >
                    <span class="mr-1">Badges</span>

                    <div class="d-flex">
                      <v-img
                        v-for="(item, index) in awardedBadges"
                        :key="index"
                        :alt="item.node.badge.title"
                        :src="item.node.badge.iconLink"
                        max-width="30px"
                      />
                    </div>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon class="align-center">
                  <span class="p-text mt-1 font-weight-bold">{{ user.userProfile.earnBadgeCount }}</span>
                  <v-icon class="navy--text" size="27">mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item class="px-0" :to="`/bookmarks/${userId}`">
                <v-list-item-icon class="mr-2">
                  <v-icon class="navy--text">mdi-bookmark-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="navy--text text-uppercase font-weight-bold">Bookmarks </v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon class="align-center">
                  <span v-if="bookmarks" class="p-text mt-1 font-weight-bold">
                    {{ bookmarks.edges.length }}
                  </span>
                  <v-icon class="navy--text"> mdi-chevron-right</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
          </j-card>
        </v-col>
      </v-row>

      <v-dialog v-model="openfriendsDialog" max-width="600" transition="dialog-bottom-transition">
        <j-card class="pa-2 card-radius">
          <v-toolbar flat>
            <v-toolbar-title class="secondary--text pl-1">
              <span class="heading-2 ml-n1">Friends</span>
            </v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="openfriendsDialog = false" small>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="overflow-auto" style="max-height: 500px">
            <friends-listing :userId="getId(userId)" status="APPROVED" class="pt-5" />
          </v-card-text>
        </j-card>
      </v-dialog>
      <v-dialog v-model="deleteDialog" width="500">
        <j-card rounded="lg">
          <v-card-title class="std-text"> Are you sure you want to remove this friend? </v-card-title>
          <v-card-text></v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="secondary" @click="deleteDialog = false"> Cancel </v-btn>
            <v-spacer />
            <v-btn color="background-grey" class="secondary--text pa-2" @click="removeFriend"> Confirm </v-btn>
          </v-card-actions>
        </j-card>
      </v-dialog>
      <v-row v-if="canViewData" justify="center">
        <v-col cols="12" md="6" class="white pt-0 mt-3">
          <post-container :userId="getId(userId)" :userPostsOnly="true" dedupe-user hide-post-link only-show-public />
        </v-col>
      </v-row>
    </div>
    <div>
      <image-uploader
        id="image-uploader"
        :maxWidth="1000"
        hidden
        :preview="false"
        :quality="0.9"
        outputFormat="verbose"
        accept="image/*"
        @input="setImage"
      />
    </div>
    <v-dialog v-model="cropperDialog" width="600" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar color="white">
          <j-btn
            v-if="data.image != null"
            color="secondary"
            class="dashboard-button mt-1"
            rounded
            :loading="savingProfilePicture"
            @click="saveProfileImage()"
          >
            Update {{ imageType }} image
          </j-btn>
          <v-btn icon @click="cropperDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row>
          <v-col cols="12">
            <cropper
              v-if="selectedImgSrc != null"
              class="cropper mt-2 mb-2"
              :src="selectedImgSrc"
              :stencil-props="{
                aspectRatio: imageType == 'profile' ? 1 : 1 / 0.5,
                movable: true,
                resizable: true,
                resizeImage: false,
              }"
              @change="change"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import "vue-advanced-cropper/dist/style.css";
import { USER_QUERY } from "@/graphql/queries/profile";
import { CREATE_FRIEND_REQUEST, DELETE_FRIEND_REQUEST } from "@/graphql/mutations/friends";
import { UPDATE_PROFILE_PICTURE_MUTATION } from "@/graphql/mutations/account";
import { AWARDED_BADGES_QUERY } from "@/graphql/queries/badges";
import { userData } from "@/lib/user";
import { BOOKMARKS_QUERY } from "@/graphql/queries/bookmark";
import { decodeId } from "@/lib/string";
import { gradientStyle } from "@/lib/gradientStyle";
import { dataURLtoBlob, blobToFile } from "@/lib/file";
import UserAvatar from "@/components/shared/user/UserAvatar";
import StreakCalendarDots from "@/components/shared/blocks/StreakCalendarDots";
import FriendsListing from "@/components/friends/FriendsListing";
import PostContainer from "@/components/groups/posts/PostContainer";
import FriendAvatarListing from "@/components/friends/FriendAvatarListing";
import ImageUploader from "vue-image-upload-resize";
import { Cropper } from "vue-advanced-cropper";
import { HANDLE_TO_USER_ID } from "@/graphql/queries/account";
import FriendMixin from "@/mixins/FriendMixin";
import UserTypeHighlight from "@/components/groups/UserTypeHighlight";

export default {
  name: "Profile",
  mixins: [FriendMixin],
  components: {
    UserAvatar,
    StreakCalendarDots,
    FriendsListing,
    PostContainer,
    FriendAvatarListing,
    ImageUploader,
    Cropper,
    UserTypeHighlight,
  },
  props: {
    handle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      userId: null,
      loading: true,
      user: null,
      awardedBadges: [],
      bookmarks: null,
      data: { ...userData, image: null },
      hasSubscription: false,
      product: null,
      openfriendsDialog: false,
      imageList: [],
      deleteDialog: false,
      imageLoading: false,
      savingProfilePicture: false,
      selectedImgSrc: null,
      cropperDialog: false,
      imageType: "",
    };
  },
  async mounted() {
    await this.setupHandle();
  },
  watch: {
    userId: function () {
      this.openfriendsDialog = false;
    },
    $route() {
      this.setupHandle();
    },
  },
  computed: {
    profileSize() {
      let size = "120";

      if (this.$vuetify.breakpoint.lgAndUp) {
        size = "170";
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        size = "140";
      } else if (this.$vuetify.breakpoint.smAndUp) {
        size = "170";
      }

      return size;
    },

    backgroundGradientStyle() {
      return gradientStyle(["yellow lighten-2", "hydration lighten-2"], 180);
    },
    myProfile() {
      if (!this.userId) {
        return false;
      }
      return this.$store.getters.user?.id == decodeId(this.userId);
    },
    inActive() {
      return this.$store.getters.role == "INACTIVE" || this.$store.getters.inActive;
    },
    canViewData() {
      return (
        this.myProfile || this.$store.getters.user?.isStaff || this.user?.userProfile?.friendship?.status == "APPROVED"
      );
    },
    staffViewData() {
      return (
        !this.myProfile && this.$store.getters.user?.isStaff && this.user?.userProfile?.friendship?.status != "APPROVED"
      );
    },
    subscription() {
      let value = {
        color: "",
        icon: "",
        name: "",
      };

      switch (this.product) {
        case "None":
          value.icon = "mdi-account-cancel";
          value.color = "grey darken-2";
          value.name = null;
          value.upgradeLink = "/checkout/jeff-club";
          break;
        case "JEFF_MEMBER":
          value.color = "jeff-life";
          value.icon = "mdi-account-lock";
          value.name = "JEFF Life";
          value.upgradeLink = "/checkout/jeff-club";
          break;
        case "CLUB":
          value.color = "challenges";
          value.icon = "mdi-cards-club";
          value.name = "JEFF Club ";
          value.upgradeLink = "/checkout/1-on-1";
          break;
        case "ONE_ON_ONE":
          value.color = "one-on-one";
          value.icon = "mdi-numeric-1-box-multiple-outline";
          value.name = "1-1 Coaching";
          break;
        case "GRAD":
          value.color = "one-on-one";
          value.icon = "mdi-account-school";
          value.name = "1-1 Coaching";
          break;
      }

      return value;
    },
  },
  methods: {
    async setupHandle() {
      if (!this.handle.startsWith("@")) {
        this.userId = this.handle;
        return;
      }
      let result = await this.$apollo.query({
        query: HANDLE_TO_USER_ID,
        variables: {
          handle: this.handle.replace("@", ""),
        },
      });
      if (result.data.handleToUserId) {
        this.userId = btoa("UserNode: " + result.data.handleToUserId);
      }
    },
    getId(idString) {
      return decodeId(idString);
    },
    change({ canvas }) {
      this.data.image = canvas.toDataURL();
    },
    clickUploader(type) {
      this.imageType = type;
      document.getElementById("image-uploader").click();
    },
    setImage(output) {
      this.cropperDialog = true;
      this.selectedImgSrc = output.dataUrl;
    },
    async saveProfileImage() {
      this.cropperDialog = false;
      this.imageLoading = this.imageType;
      this.savingProfilePicture = true;
      let blob = dataURLtoBlob(this.data.image);
      let file = blobToFile(blob);
      this.$apollo
        .mutate({
          mutation: UPDATE_PROFILE_PICTURE_MUTATION,
          variables: {
            file: file,
            type: this.imageType,
          },
        })
        .then((response) => {
          this.savingProfilePicture = false;
          this.data.image = null;
          this.selectedImgSrc = null;
          if (response.data.updateProfileImage.ok) {
            let url = response.data.updateProfileImage.profilePictureUrl;
            if (this.imageType == "profile") {
              this.$store.dispatch("updateProfileImage", { url });
            }

            this.$apollo.queries.user.refetch();
            this.imageLoading = "";
          } else {
            this.$toasted.error("Unable to upload photo.", {
              icon: "alert",
              duration: 2000,
              position: "bottom-center",
            });
          }
        });
    },
    async removeFriend() {
      if (this.user.userProfile.friendship) {
        this.loading = true;
        await this.$apollo.mutate({
          mutation: DELETE_FRIEND_REQUEST,
          variables: {
            id: this.user.userProfile.friendship.id,
          },
          refetchQueries: ["profileQuery"],
        });
        this.loading = false;
      }

      this.deleteDialog = false;
    },
    async createFriendRequest() {
      this.loading = true;
      await this.$apollo.mutate({
        mutation: CREATE_FRIEND_REQUEST,
        variables: {
          userId: decodeId(this.userId),
        },
        refetchQueries: ["profileQuery"],
      });
      this.loading = false;
    },
  },
  apollo: {
    user: {
      query: USER_QUERY,
      fetchPolicy: "network-only",
      variables() {
        return {
          userId: this.userId,
        };
      },
      result(response) {
        if (response.data) {
          this.loading = false;

          // add some keys that are not the same accross workoutBlocksLogs and activity Logs
          let workoutBlockLogs = response.data.workoutBlockWorkoutLogs.edges;
          let combinedActivities = [...response.data.user.useractivitySet.edges, ...workoutBlockLogs];
          combinedActivities.sort((a, b) => (a.node.startDate < b.node.startDate ? 1 : -1));
          this.imageList = combinedActivities;

          this.product = response.data.user.userProfile.activeSubscription?.product.type;
        }
      },
      skip() {
        return !this.userId;
      },
    },
    bookmarks: {
      query: BOOKMARKS_QUERY,
      variables() {
        return {
          userId: this.userId,
          orderBy: "-created_at",
        };
      },
      fetchPolicy: "cache-and-network",
      skip() {
        return !this.userId;
      },
    },
    awardedBadges: {
      query: AWARDED_BADGES_QUERY,
      variables() {
        return {
          userId: this.userId,
          first: 5,
        };
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        if (data.awardedBadges.edges) {
          return data.awardedBadges.edges;
        }
      },
      skip() {
        return !this.userId;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.cover-image {
  max-height: 130px;
}

.camera-icon {
  margin-top: -30px;
  margin-left: 80px;
}

.v-list-item:not(:last-child) {
  border-bottom: var(--v-grey-base) 1px solid;
}

.left-space {
  margin-left: 170px;
}
.edit-icon {
  margin-top: -40px;
  right: 0px;
  margin-right: 10px;
  position: absolute;
}
</style>
