export const REFLECTION_OPTIONS = {
  hydration: [
    { filled: false, text: "No water", value: 25 },
    { filled: false, text: "Some water", value: 75 },
    { filled: true, text: "1.5 litres +", value: 100 },
  ],
  nutrition: [
    { filled: false, text: "Off track", value: 25 },
    { filled: false, text: "Mostly good", value: 75 },
    { filled: true, text: "On plan", value: 100 },
  ],
  stress: [
    { filled: true, text: "Struggling", value: 25 },
    { filled: true, text: "Stretched", value: 75 },
    { filled: true, text: "Feeling great", value: 100 },
  ],
  habit: [
    { filled: false, text: "Missed", value: 0, color: "grey" },
    { filled: false, text: "Completed", value: 1, color: "yellow" },
  ],
};
