import gql from "graphql-tag";

export const REWARD_CATEGORIES_QUERY = gql`
  query rewardEventCategories($categoryType: String!) {
    rewardEventCategories(categoryType: $categoryType) {
      id
      code
      title
      type
      pointValue
    }
  }
`;

export const REWARDS_QUERY = gql`
  query rewardEvents {
    rewardEvents {
      id
      eventDate
      approvalStatus
      description
      points
      declinedReason
      seen
      category {
        id
        title
        type
      }
      discount {
        id
        maxUses
        numberOfUses
        code
        flatDiscount
      }
    }
  }
`;
