<template>
  <div>
    <div v-if="loading" class="text-center ma-4">
      <v-row align="center" justify="center">
        <v-col cols="12" md="6">
          <v-skeleton-loader
            type="list-item-avatar-three-line, divider, list-item-avatar-three-line, divider, list-item-avatar-three-line, divider, list-item-avatar-three-line"
          ></v-skeleton-loader> 
          </v-col
      ></v-row>
    </div>
    <div v-else class="mt-3 mb-8 mx-3">
      <v-row v-if="important.length > 0" align="center" justify="center">
        <v-col class="pa-7 pb-0" cols="12" md="6">
          <v-chip class="mb-5" color="yellow-background" text-color="alert-yellow">
            IMPORTANT ⚡
          </v-chip>
          <template v-if="important.length > 0">
            <inbox-message-block v-for="(message, index) in important" :key="'important-' + index" :message="message" />
          </template>
          <template v-if="important.length === 0 && archive.length > 0">
            <v-alert type="success" class="alert-success">Looks like you’re all caught up!</v-alert>
          </template>
          <template v-if="important.length === 0 && archive.length === 0">
            <v-alert type="success" class="alert-success"
              >You’ll see a number on the Inbox button when you have new messages to read in your Inbox.</v-alert
            >
          </template>
        </v-col>
      </v-row>
      <v-row
        v-if="fresh.length > 0 || important.length > 0 || pagination.currentPage == 1"
        align="center"
        justify="center"
      >
        <v-col class="pa-7 pb-0" cols="12" md="6">
          <v-row class="mx-1">
            <v-chip class="mb-5" color="green-background" text-color="alert-green">
              FRESH 🥬
            </v-chip>
            <v-spacer />
            <v-btn v-if="fresh.length > 0" rounded height="32" class="px-3 alert-grey--text" @click="archiveAll()">
              <strong>ARCHIVE ALL</strong>
            </v-btn>
          </v-row>
          <template v-if="fresh.length > 0">
            <inbox-message-block v-for="(message, index) in fresh" :key="'fresh-' + index" :message="message" />
          </template>
          <template v-if="fresh.length === 0 && archive.length > 0">
            <v-alert type="success" class="alert-success">Looks like you’re all caught up!</v-alert>
          </template>
          <template v-if="fresh.length === 0 && archive.length === 0">
            <v-alert type="success" class="alert-success"
              >You’ll see a number on the Inbox button when you have new messages to read in your Inbox.</v-alert
            >
          </template>
        </v-col>
      </v-row>
      <v-row v-if="archive.length > 0" align="center" justify="center">
        <v-col class="pa-7 pb-0" cols="12" md="6">
          <v-row class="mx-1">
            <v-chip class="mb-5" color="grey-background" text-color="alert-grey">
              ARCHIVE 🗄️
            </v-chip>
            <p class="alert-grey--text px-3 pt-1">Messages you read or older than 7 days</p>
          </v-row>
          <inbox-message-block v-for="(message, index) in archive" :key="'archive-' + index" :message="message" />
        </v-col>
      </v-row>
      <v-row v-if="pagination.pages > 1" align="center" justify="center">
        <v-col cols="12" md="6" class="pb-0">
          <v-pagination
            v-if="pagination.pages > 1"
            v-model="pagination.currentPage"
            :length="pagination.pages"
            @input="getMessages()"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { INBOX_QUERY } from "@/graphql/queries/inbox";
import { ARCHIVE_INBOX_MUTATION } from "@/graphql/mutations/inbox";
import { ADD_PARAMS_TO_LOCATION } from "@/lib/history";
import InboxMessageBlock from "@/components/inbox/InboxMessageBlock";

export default {
  name: "Inbox",
  components: {
    InboxMessageBlock,
  },
  data() {
    return {
      loading: true,
      important: [],
      fresh: [],
      archive: [],
      importantCount: 0,
      freshCount: 0,
      archiveCount: 0,
      pagination: {
        currentPage: 1,
        pages: 0,
        pageSize: 20,
        after: null,
        endCursor: null,
      },
    };
  },
  mounted() {
    if (this.$route.query.page) this.pagination.currentPage = parseInt(this.$route.query.page);

    this.getMessages();
  },
  methods: {
    getMessages() {
      this.loading = true;
      this.$apollo.queries.allMessages.refetch();
    },
    addParamsToLocation(params) {
      ADD_PARAMS_TO_LOCATION(this.$route, params);
    },
    archiveAll() {
      if (this.freshCount == 0) {
        this.$toasted.info("Nothing to archive", {
          icon: "info",
          duration: 2000,
          position: "bottom-center",
        });
      } else {
        this.$apollo
          .mutate({
            mutation: ARCHIVE_INBOX_MUTATION,
            refetchQueries: ["inboxMessages"],
          })
          .then(() => {
            this.loading = false;
            this.$toasted.success("Archived", {
              icon: "arm-flex",
              duration: 2000,
              position: "bottom-center",
            });
          })
          .catch({});
      }
    },
  },
  apollo: {
    allMessages: {
      query: INBOX_QUERY,
      fetchPolicy: "network-only",
      variables() {
        let variables = {
          pageSize: this.pagination.pageSize,
        };

        if (this.pagination.currentPage > 1) {
          let afterString = "arrayconnection:" + ((this.pagination.currentPage - 1) * this.pagination.pageSize - 1);
          this.pagination.after = btoa(afterString);
        } else {
          this.pagination.after = null;
        }

        variables.after = this.pagination.after;

        this.addParamsToLocation({
          page: this.pagination.currentPage,
        });

        return variables;
      },
      update(data) {
        let messages = data.inboxMessages.edges;
        this.importantCount = data.inboxMessages.importantCount;
        this.freshCount = data.inboxMessages.freshCount;
        this.archiveCount = data.inboxMessages.archiveCount;
        this.important = [];
        this.fresh = [];
        this.archive = [];

        this.important = messages.slice(0, this.importantCount);
        this.fresh = messages.slice(this.importantCount, this.importantCount + this.freshCount);
        this.archive = messages.slice(
          this.importantCount + this.freshCount,
          this.importantCount + this.freshCount + this.archiveCount
        );

        if (data.inboxMessages.totalCount > 0) {
          this.pagination.pages = Math.ceil(data.inboxMessages.totalCount / this.pagination.pageSize);
        }
        this.pagination.endCursor = data.endCursor;
        this.loading = false;
      },
    },
  },
};
</script>
