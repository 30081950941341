<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <j-card class="text-center pa-6">
          <img :src="require('@/assets/images/completed.png')" width="100" />

          <h1 class="mt-6 appblue--text">Thank you</h1>
          <div class="std-text mt-6" style="max-width: 350px; margin-left: auto; margin-right: auto">
            Your request has been submitted and we will be in touch within one business day.
          </div>

          <div class="mt-6 mb-2">
            <a href="https://www.jeff.fitness" class="text-decoration-none font-weight-bold">Back to Website</a>
          </div>
        </j-card></v-col
      ></v-row
    >
  </v-container>
</template>

<script>
export default {
  name: "TeamSuccess",
};
</script>
