import gql from "graphql-tag";

export const UPDATE_PROFILE_MUTATION = gql`
  mutation updateProfile($userProfileInput: UserProfileInput!) {
    updateProfile(userProfileInput: $userProfileInput) {
      ok
      originalServingSize
      newServingSize
    }
  }
`;

export const UPDATE_PROFILE_PICTURE_MUTATION = gql`
  mutation updateProfileImage($file: Upload!, $type: String) {
    updateProfileImage(file: $file, type: $type) {
      ok
      profilePictureUrl
    }
  }
`;

export const UPDATE_TIMEZONE_MUTATION = gql`
  mutation updateUserTimezone($timezone: String!) {
    updateUserTimezone(timezone: $timezone) {
      ok
    }
  }
`;

export const USER_PROFILE_CREATION = gql`
  mutation userProfileCreation($userProfileInput: UserProfileInput!) {
    userProfileCreation(userProfileInput: $userProfileInput) {
      userId
    }
  }
`;

export const CREATE_BUDDY_PROGRAM_APPLICATION_MUTATION = gql`
  mutation createBuddyProgramApplication($friendPhone: String, $friendName: String, $wantSimilar: Boolean) {
    createBuddyProgramApplication(friendPhone: $friendPhone, friendName: $friendName, wantSimilar: $wantSimilar) {
      ok
    }
  }
`;

export const CHANGE_PASSWORD_MUTATION = gql`
  mutation passwordChange($oldPassword: String!, $newPassword1: String!, $newPassword2: String!) {
    passwordChange(oldPassword: $oldPassword, newPassword1: $newPassword1, newPassword2: $newPassword2) {
      success
      errors
    }
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
  mutation resetPassword($hash: String, $newPassword: String!, $userId: Int) {
    resetPassword(hash: $hash, newPassword: $newPassword, userId: $userId) {
      success
      validationErrors
      error
      username
    }
  }
`;

export const SEND_PASSWORD_RESET_MUTATION = gql`
  mutation initiatePasswordReset($email: String!) {
    initiatePasswordReset(email: $email) {
      success
    }
  }
`;

export const FAVOURITE_USERS_MUTATION = gql`
  mutation setFavouriteUsers($staff: [Int]!) {
    setFavouriteUsers(staff: $staff) {
      success
      error
    }
  }
`;

export const UPSERT_MY_SETTINGS_MUTATION = gql`
  mutation upsertMySetting($settingName: String!, $value: Boolean!) {
    upsertMySetting(settingName: $settingName, value: $value) {
      error
      success
    }
  }
`;

export const TRANSITION_SUBSCRIPTION_MUTATION = gql`
  mutation transitionSubscriptionMutation(
    $productVariantCode: String
    $endDate: Date
    $recurringDiscountCode: String
    $transitionCoachId: ID
  ) {
    transitionSubscriptionMutation(
      productVariantCode: $productVariantCode
      endDate: $endDate
      recurringDiscountCode: $recurringDiscountCode
      transitionCoachId: $transitionCoachId
    ) {
      error
      success
    }
  }
`;

export const REOPEN_SUBSCRIPTION_MUTATION = gql`
  mutation reopenSubscriptionMutation {
    reopenSubscriptionMutation {
      error
      success
    }
  }
`;

export const LINK_CORPORATE_CHALLENGE_MUTATION = gql`
  mutation linkUserToCorporateChallenge($registrationCode: String!, $employeeCode: String) {
    linkUserToCorporateChallenge(registrationCode: $registrationCode, employeeCode: $employeeCode) {
      error
      success
      corporateChallenge {
        id
        title
        registrationCode
        startDate
        successMessage
      }
    }
  }
`;

export const UPSERT_ACCOUNTABILITY_GROUP_MUTATION = gql`
  mutation upsertAccountabilityGroupRegistration($type: String!, $friends: String, $whatsappGroupName: String) {
    upsertAccountabilityGroupRegistration(type: $type, friends: $friends, whatsappGroupName: $whatsappGroupName) {
      error
      success
    }
  }
`;

export const UPSERT_LAST_ACTIVE = gql`
  mutation upsertLastActive {
    upsertLastActive {
      success
    }
  }
`;

export const LINK_DISCOUNT_TO_CURRENT_SUBSCRIPTION = gql`
  mutation linkDiscountToCurrentSubscription($discountCode: String!, $productVariantCode: String) {
    linkDiscountToCurrentSubscription(discountCode: $discountCode, productVariantCode: $productVariantCode) {
      success
      error
    }
  }
`;

export const UPSERT_DEAL_MUTATION = gql`
  mutation upsertDealMutation($data: UpdateDealInput!) {
    upsertDealMutation(data: $data) {
      success
    }
  }
`;

export const CREATE_SUBSCRIPTION_MUTATION = gql`
  mutation createSubscriptionMutation($input: CreateSubscriptionInput!) {
    createSubscriptionMutation(input: $input) {
      success
      error
    }
  }
`;

export const UPDATE_SUBSCRIPTION_MUTATION = gql`
  mutation updateSubscriptionMutation($input: UpdateSubscriptionInput!) {
    updateSubscriptionMutation(input: $input) {
      success
      error
    }
  }
`;
