export const commonChartOptions = {
  
    title: {
      text: "",
    },
    plotOptions: {
      series: {
        pointPlacement: "on",
      },
    },
    credits: {
      enabled: false,
    },
};


