<template>
  <v-row>
    <v-col>
      <j-text-field
        v-model="searchString"
        @keyup="searchUsers()"
        prepend-inner-icon="mdi-magnify"
        append-icon="mdi-close"
        placeholder="Type name..."
        @click:append="searchString = null"
      >
      </j-text-field>

      <div v-if="users !== null && searchString">
        <j-alert v-if="users.length == 0" type="warning" icon="mdi-star-circle-outline">
          No users match {{ searchString }}
        </j-alert>
        <div v-for="(user, index) in users" :key="user.id" class="d-flex justify-space-between">
          <j-avatar-card
            :title="`${user.firstName} ${user.lastName}`"
            :avatar-url="user.userProfile.profilePicture"
            :colour="colours[index]"
            class="mb-2"
            :subtitle="user.description"
            :navigateTo="`/p/${getUserNode(user.id)}`"
          >
          </j-avatar-card>
          <j-btn
            v-if="!user.friendship"
            small
            tertiary
            @click="createFriendRequest(user)"
            :loading="loadingBtn == user.id"
            class="mt-4"
          >
            Add friend
          </j-btn>
        </div>
      </div>
    </v-col>
    <v-col v-if="searchString && users !== null && users.length == 0" cols="12" class="pt-0">
      <j-card class="j-elevated-1 pb-1">
        <v-card-text class="text-center pb-0">
          <h3>Invite friends to {{ $appName }}</h3>
          <p class="mb-0">Your friends get a discount and you get rewards points!</p>
        </v-card-text>
        <v-card-actions class="mb-3">
          <v-spacer />
          <j-btn wide color="whatsapp-green" target="_blank" :href="whatsappShareUrl">
            <strong>Invite via whatsapp</strong>
            <v-icon class="ml-2">mdi-whatsapp</v-icon>
          </j-btn>
          <v-spacer />
        </v-card-actions>
      </j-card>
    </v-col>
  </v-row>
</template>

<script>
import { CLIENTS_QUERY } from "@/graphql/queries/account";
import { get_friend_request_share_link } from "@/lib/whatsapp";
import { CREATE_FRIEND_REQUEST } from "@/graphql/mutations/friends";
import { decodeId } from "@/lib/string";

export default {
  name: "UserSearch",
  data() {
    return {
      keyUpSearchInterval: null,
      users: null,
      adminUsers: [],
      searchString: null,
      colours: ["pink", "green", "yellow", "navy", "blue", "red"],
      limit: 16,
      loadingBtn: null,
    };
  },
  computed: {
    whatsappShareUrl() {
      return get_friend_request_share_link(this.inviteFriendDiscountCode, this.$appName, this.$store.getters.settings.get("REWARDS")?.get('invite-friend-url'));
    },
    inviteFriendDiscountCode() {
      return this.$store.getters.user?.userProfile?.inviteFriendDiscountCode;
    },
  },
  methods: {
    getUserNode(userId) {
      return btoa(`UserNode: ${userId}`);
    },
    async createFriendRequest(user) {
      this.loadingBtn = user.id;
      await this.$apollo.mutate({
        mutation: CREATE_FRIEND_REQUEST,
        variables: {
          userId: decodeId(user.id),
        },
      });
      user.friendship = true;
      this.loadingBtn = null;
    },
    searchUsers() {
      if (this.keyUpSearchInterval) {
        clearTimeout(this.keyUpSearchInterval);
        this.keyUpSearchInterval = null;
      }
      this.keyUpSearchInterval = setTimeout(() => {
        this.doSearch();
      }, 1000);
    },
    doSearch() {
      if (!this.searchString) {
        if (!this.chat) {
          this.users = null;
        } else {
          this.users = this.chat.users;
        }
        return;
      }
      let data = {
        first: this.limit,
        search: this.searchString,
      };

      // no need to paginate. let's think about it...the user is trying to find and add people one at a time.
      // showing `$limit` is enough. Will JEFF have more than `$limit` people of the same name and surname? if so, we can deal with i then.
      this.$apollo
        .query({
          query: CLIENTS_QUERY,
          variables: data,
        })
        .then((response) => {
          let users = [];
          for (const userNode of response.data.clients.edges) {
            if (userNode.node.user.id != this.$store.getters.user.id) {
              let user = {
                userProfile: {},
              };
              user.id = userNode.node.user.id;
              user.firstName = userNode.node.user.firstName;
              user.lastName = userNode.node.user.lastName;
              user.description = userNode.node.description;
              user.isStaff = userNode.node.user.isStaff;
              user.userProfile.profilePicture = userNode.node.profilePicture;
              user.activeSubscription = userNode.node.activeSubscription;
              user.friendship = userNode.node.friendship;
              users.push(user);
            }
          }
          this.users = users;
        });
    },
  },
};
</script>
