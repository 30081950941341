<template>
  <j-card-banner-container
    header="Recipe"
    :image="recipe.image"
    :title="recipe.title"
    :subtitle="mealSubtitle"
    :gradient="['green-background', 'yellow-background']"
    :text-gradient="['yellow', 'green']"
    :loading="$apollo.loading.recipe"
    :show-edit="canEdit"
    @edit="editRecipe(recipe.id)"
    :bookmark="$store.getters.isLoggedIn ? bookmark : null"
    :show-download="!recipeLocked"
    @download="generatePdf"
    show-share-link
    use-header-title-in-link
  >
    <template v-slot:card-content>
      <v-skeleton-loader v-if="$apollo.loading.recipe" type="image, article" />
      <div v-else-if="!recipeLocked">
        <view-recipe
          :recipe="recipe"
          :ingredients="getIngredients"
          :portion="portionSize"
          :client-made="recipe.isClientMade"
          class="pb-4"
        >
          <template v-slot:media-content>
            <div v-if="youtubeId" class="mb-4 px-4">
              <video-block
                :eventProperties="eventProperties"
                :youtubeId="youtubeId"
                @ended="ended()"
                @playing="playing()"
                @paused="paused()"
                :show-video-cast-message="false"
              />
            </div>
          </template>
        </view-recipe>

        <!-- absolute positioned ingredient warning button -->
        <v-btn
          v-if="recipe && getMissingPreferences(recipe).length > 0"
          medium
          icon
          elevation="2"
          class="ml-2 mt-n1 missing-preferences-button white"
        >
          <v-icon class="warning--text" @click.prevent="openMissingPrefs = true">mdi-alert-circle-outline</v-icon>
        </v-btn>

        <div v-if="blockId && !logged">
          <v-card-subtitle>
            <h3>Want to swap out?</h3>
          </v-card-subtitle>

          <j-text-field
            class="px-2 mb-2"
            v-model="search"
            prepend-inner-icon="mdi-magnify"
            placeholder="Search recipe database"
            dense
            outlined
            hide-details
          />
          <recipe-filter-sort
            :incoming-params="incomingParams"
            @triggerFilters="triggerFilters"
            :block-id="blockId"
            class="mx-2"
          />

          <v-row v-if="$apollo.loading" class="mx-2 pb-2">
            <v-col v-for="num in 9" :key="num" cols="6" md="4"><v-skeleton-loader type="card-avatar" /> </v-col>
          </v-row>
          <v-row
            no-gutters
            v-if="isSearching && recipes && recipes.edges && recipes.edges.length > 0"
            class="mx-2 pb-2"
          >
            <v-col cols="6" md="4" v-for="recommendedRecipe in recipes.edges" :key="recommendedRecipe.id">
              <recommended-recipe-block
                :blockId="blockId"
                :date="date"
                :recipe="recommendedRecipe.node"
                :swappable="true"
                :original-recipe="recipe"
                :loggable="false"
                :meal="meal"
                showMealType
              />
            </v-col>
          </v-row>

          <v-row no-gutters v-if="recipeAlternates.length > 0 && !isSearching" class="mx-2 pb-2">
            <v-col cols="6" md="4" v-for="recipeAlternate in recipeAlternates" :key="recipeAlternate.id">
              <recommended-recipe-block
                :blockId="blockId"
                :date="date"
                :recipe="recipeAlternate"
                :swappable="true"
                :original-recipe="recipe"
                :loggable="false"
                :meal="meal"
                showMealType
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-if="recipeLocked && $store.getters.isLoggedIn" class="pb-3">
        <j-alert type="warning" class="mx-4">
          Sorry, you need to
          <router-link to="/shop" class="warning--text font-weight-bold">upgrade your membership</router-link> to view
          this recipe.
        </j-alert>
      </div>
      <div v-if="!$store.getters.isLoggedIn" class="pb-3">
        <j-alert type="warning" class="mx-4">
          <router-link to="/shop" class="warning--text font-weight-bold">Sign up now</router-link> for full access to
          {{ $appName }}.
        </j-alert>
      </div>

      <post-container
        v-if="recipe && $store.getters.isLoggedIn"
        ref="postContainer"
        class="mt-6 px-4"
        :recipe-id="recipe.id"
        :featured-user-ids="recipe.owner ? [recipe.owner.id] : null"
        highlight-colour="#3ADEB4"
        hide-post-group-name
        hide-post-link
      />
    </template>

    <!-- Dialog for ingredient warning  -->
    <v-dialog
      v-if="getMissingPreferences(recipe).length > 0"
      v-model="openMissingPrefs"
      width="600"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <missing-preferences-dialog
        :missing-preferences="getMissingPreferences(recipe)"
        @closed="openMissingPrefs = false"
      />
    </v-dialog>

    <!-- PDF of recipe-->
    <generate-pdf v-if="recipe.title" ref="refGeneratePdf" :title="recipe.title">
      <template v-slot:content>
        <table width="100%">
          <tr>
            <td align="left" colspan="2" class="pdf-copy">
              <p v-if="recipe.isClientMade" class="pdf-copy" style="white-space: pre-wrap">{{ recipe.description }}</p>
              <h2 v-else class="pdf-copy">{{ portionSize }} portion size</h2>
              <h2 class="pdf-heading-2" style="margin-bottom: 10px; margin-top: 10px">Ingredients</h2>
              <span v-for="ing in getIngredients" :key="ing.id">
                <p v-if="ing.quantity > 0" style="margin-bottom: 2px">
                  {{ ing.formattedQuantity }} {{ ing.measurement }} {{ ing.preparation }} {{ ing.name }}
                </p>
              </span>
            </td>
            <td align="right" class="pdf-copy">
              <h3 v-if="recipe.isClientMade" class="pdf-copy">
                Added by {{ recipe.owner.firstName }} {{ recipe.owner.lastName }}
              </h3>
            </td>
          </tr>
          <tr>
            <td align="left" colspan="2" class="pdf-copy">
              <h2 class="pdf-heading-2" style="margin-bottom: 10px; margin-top: 20px">Directions</h2>
              <p v-html="recipe.directions" style="padding-left: 15px" />
            </td>
          </tr>
        </table>
      </template>
    </generate-pdf>
  </j-card-banner-container>
</template>

<script>
import { RECIPE_QUERY, RECIPE_ALTERNATES_QUERY } from "@/graphql/queries/nutritionblock";
import { RECIPES_QUERY } from "@/graphql/queries/nutrition";
import { BOOKMARKS_QUERY } from "@/graphql/queries/bookmark";
import { mapGetters } from "vuex";
import { decodeId } from "@/lib/string";
import { getYouTubeId } from "@/lib/string";
import GeneratePdf from "@/components/pdf/GeneratePdf";
import RecommendedRecipeBlock from "@/components/nutritionblocks/RecommendedRecipeBlock.vue";
import IngredientsMixin from "@/mixins/nutrition/IngredientsMixin";
import ActivityTrackingMixin from "@/mixins/ActivityTrackingMixin";
import VideoBlock from "@/components/events/VideoBlock";
import UserNutritionPreferencesMixin from "@/mixins/nutrition/UserNutritionPreferencesMixin";
import RecipeFilterSort from "@/components/nutritionblocks/blocks/RecipeFilterSort";
import ViewRecipe from "@/components/nutritionblocks/blocks/ViewRecipe";
import PostContainer from "@/components/groups/posts/PostContainer";

export default {
  name: "Recipe",
  mixins: [IngredientsMixin, ActivityTrackingMixin, UserNutritionPreferencesMixin],
  components: {
    RecommendedRecipeBlock,
    VideoBlock,
    GeneratePdf,
    RecipeFilterSort,
    ViewRecipe,
    PostContainer,
  },
  data() {
    return {
      portionSize: "Medium",
      recipeAlternates: [],
      loadedRecipe: false,
      recipe: {
        image: null,
        title: null,
        meal: null,
      },
      search: null,
      searchFilterVariables: {
        isAddedByMe: false,
      },
      incomingParams: {
        thisMenuFilter: null,
      },
    };
  },
  created() {
    this.ingredientMultiplier = parseInt(this.$store.getters.ingredientMultiplier);
    this.incomingParams.thisMenuFilter = this.blockId;
  },
  computed: {
    ...mapGetters(["selectedDate"]),
    recipeLocked() {
      if (["ONE_ON_ONE", "CLUB"].includes(this.$store.getters.role)) return false;
      if (this.$store.getters.role == "JEFF_MEMBER") {
        return !this.recipe.availableToLife;
      }

      return !this.recipe.openAccess;
    },
    isSearching() {
      return (
        this.search ||
        this.recipeTypeFilter.length > 0 ||
        this.preferencesFilter.length > 0 ||
        this.searchFilterVariables.isInBlock
      );
    },
    recipeTypeFilter() {
      return this.searchFilterVariables?.mealIn ?? [];
    },
    preferencesFilter() {
      return this.searchFilterVariables?.preferencesIn ?? [];
    },
    date() {
      return this.$route.query?.d;
    },
    blockId() {
      return this.$route.query?.bid;
    },
    meal() {
      return this.$route.query?.t;
    },
    logged() {
      const logged = this.$route.query?.l;
      if (!logged) {
        return false;
      }
      if (logged == "false") {
        return false;
      }
      return true;
    },
    bookmark() {
      let obj = {
        objectId: this.recipe.id,
        objectNotes: "",
        objectType: "recipe",
        refreshQuery: "bookmarks",
      };
      if (this.bookmarks?.edges?.length > 0) {
        obj.bookmarkId = decodeId(this.bookmarks.edges[0].node.id);
        obj.objectNotes = this.bookmarks.edges[0].node.notes;
      }
      return obj;
    },
    youtubeId() {
      if (this.recipe.videoUrl) {
        return getYouTubeId(this.recipe.videoUrl);
      }
      return null;
    },
    eventProperties() {
      return {
        name: this.recipe.title,
        instructor_id: "",
        event_id: this.recipe.id,
        video_type: "recipe",
        workout_type: "",
      };
    },
    canEdit() {
      return this.recipe.isClientMade && this.recipe?.owner?.id == this.$store.getters.user?.id;
    },
  },
  methods: {
    generatePdf() {
      this.$refs.refGeneratePdf.generatePdf();
    },
    triggerFilters(filterVariables) {
      this.doSearch();
      this.searchFilterVariables = filterVariables;
    },
    doSearch() {
      this.recipes = [];
      this.after = "";
    },
    editRecipe(recipeId) {
      this.$router.push({ name: "EditRecipe", params: { id: recipeId } });
    },
  },
  apollo: {
    recipe: {
      query: RECIPE_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result(response) {
        if (response.data && !response.loading) {
          this.loadedRecipe = true;

          window.analytics.track("Recipe Viewed", {
            recipeId: this.recipe.id,
            recipe: this.recipe.title,
          });
        }
      },
      update(data) {
        this.loading = false;
        return data.recipe;
      },
      skip() {
        return !this.$route.params.id;
      },
    },
    recipeAlternates: {
      query: RECIPE_ALTERNATES_QUERY,
      fetchPolicy: "no-cache",
      variables() {
        return {
          input: {
            nutritionBlockId: this.blockId,
            numberOfRecommendations: 9,
            type: this.meal,
            excludeIds: [this.recipe.id],
          },
        };
      },
      skip() {
        return !this.loadedRecipe || !this.blockId || !this.date || this.logged == "true";
      },
    },
    bookmarks: {
      query: BOOKMARKS_QUERY,
      variables() {
        return {
          recipeId: this.recipe.id,
        };
      },
      fetchPolicy: "cache-and-network",
    },
    // for searching and swapping out.
    recipes: {
      query: RECIPES_QUERY,
      fetchPolicy: "cache-and-network",
      debounce: 200,
      variables() {
        return {
          search: this.search,
          ...this.searchFilterVariables,
          // if we are filtering by primary type, we should show a lot.
          first: this.recipeTypeFilter.length > 0 ? 30 : 12,
        };
      },
      skip() {
        return !this.isSearching;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.missing-preferences-button {
  /** overwrite the global style in components.scss to move the button down a little bit more */
  top: 8px;
}
.directions ::v-deep ol li {
  margin-bottom: 10px;
}
.portion-select {
  max-width: 340px;
}
</style>
