<template>
  <div class="d-flex justify-center align-center flex-column countdown-timer-wrapper">
    <div class="d-flex justify-center align-center">
      <v-icon
        v-if="!showPause && (navigationEnabled('rewind') || navigationEnabled('forward'))"
        @click="navigateTimer('rewind')"
        :color="navigationEnabled('rewind') ? 'navy' : 'grey'"
        >mdi-chevron-left</v-icon
      >
      <countdown
        ref="countdown"
        :time="startMilliseconds"
        :auto-start="autostart"
        @start="onStart"
        @abort="onAbort"
        @end="onEnd"
        @progress="handleCountdownProgress"
      >
        <template slot-scope="props">
          <v-progress-circular :rotate="360" :size="60" :width="5" :value="progressPercent" color="navy">
            <span class="timer" :class="props.totalSeconds > 60 ? 'minutes' : 'seconds'">{{
              formatNumber(props.totalSeconds)
            }}</span>
          </v-progress-circular>
        </template>
      </countdown>

      <v-icon
        v-if="!showPause && (navigationEnabled('rewind') || navigationEnabled('forward'))"
        @click="navigateTimer('forward')"
        :color="navigationEnabled('forward') ? 'navy' : 'grey'"
        >mdi-chevron-right</v-icon
      >
    </div>
    <div class="mt-1">
      <span v-if="!showPause"> <v-icon @click="toggle" color="navy" large>mdi-play-circle-outline</v-icon> </span
      ><span v-else
        ><v-icon @click="stop" color="navy">mdi-stop-circle-outline</v-icon
        ><v-icon @click="toggle" color="navy">mdi-pause-circle-outline</v-icon></span
      >
    </div>
    <div>
      <v-icon v-if="playTimerSound" @click="toggleTimerSound(false)" color="navy" small>mdi-volume-high</v-icon>
      <v-icon v-else @click="toggleTimerSound(true)" small>mdi-volume-off</v-icon>
    </div>
  </div>
</template>

<script>
import { UPSERT_MY_SETTINGS_MUTATION } from "@/graphql/mutations/account";

// https://github.com/fengyuanchen/vue-countdown/
export default {
  name: "CountdownTimer",
  data() {
    return {
      startMilliseconds: null,
      currentSeconds: 0,
      showPause: false,
      showPlay: true,
      progressPercent: 0,
      playTimerSound: true,
    };
  },
  props: {
    title: {
      required: true,
    },
    seconds: {
      required: true,
    },
    autostart: {
      type: Boolean,
      required: true,
    },
    index: {
      required: true,
    },
    timerCount: {
      required: true,
    },
  },
  created() {
    this.setStartMilliseconds();
    const playTimerSound = this.$store.getters.user?.userSettings?.find((obj) => obj.key === "timer-play-sound");
    if (playTimerSound) {
      this.playTimerSound = playTimerSound.value;
    }
  },
  methods: {
    toggleTimerSound(value) {
      this.playTimerSound = value;
      this.$apollo.mutate({
        mutation: UPSERT_MY_SETTINGS_MUTATION,
        variables: {
          settingName: "timer-play-sound",
          value: value,
        },
        refetchQueries: ["accountQuery"],
      });
    },
    setStartMilliseconds() {
      this.startMilliseconds = this.seconds * 1000;
    },
    toggle() {
      if (this.showPlay) {
        this.start();
      } else {
        this.abort();
      }
    },
    navigationEnabled(type) {
      if ((type === "forward" && this.index + 1 >= this.timerCount) || (type === "rewind" && this.index == 0)) {
        return false;
      }
      return true;
    },
    navigateTimer(type) {
      if (!this.navigationEnabled(type)) {
        return false;
      }
      this.$emit(type);
    },
    stop() {
      this.abort();
      this.progressPercent = 0;
      this.$refs.countdown.totalMilliseconds = this.startMilliseconds;
    },
    start() {
      this.$bus.$emit("timerStarted");
      if (this.currentSeconds <= 1) {
        this.progressPercent = 0;
        this.setStartMilliseconds();
        this.$refs.countdown.totalMilliseconds = this.startMilliseconds;
      }
      this.$refs.countdown.start();
    },
    abort() {
      this.$refs.countdown.abort();
    },
    onStart() {
      this.showPlay = false;
      this.showPause = true;
    },
    onAbort() {
      this.showPlay = true;
      this.showPause = false;
    },
    onEnd() {
      this.showPlay = true;
      this.showPause = false;
      this.progressPercent = 100;
      this.playTing();
      this.$emit("end");
    },
    handleCountdownProgress(data) {
      this.currentSeconds = data.totalSeconds;
      const startSeconds = this.startMilliseconds / 1000;
      // totalSeconds is currentSeconds
      const difference = this.startMilliseconds / 1000 - data.totalSeconds;
      this.progressPercent = Math.round((difference / startSeconds) * 100);
      if (data.totalSeconds <= 3) {
        this.playDong();
      } else if (data.totalSeconds % 60 == 0) {
        this.playTing();
      }
    },
    playDong() {
      // https://pixabay.com/sound-effects/search/dong/?duration=0-30
      if (this.playTimerSound) {
        new Audio(require("@/assets/audio/dong.mp3")).play();
      }
    },
    playTing() {
      // https://pixabay.com/sound-effects/search/ting/?duration=0-30
      // spotify breaks if we play timer sound. Make it optional.
      if (this.playTimerSound) {
        new Audio(require("@/assets/audio/ting.mp3")).play();
      }
    },
    formatNumber(totalSeconds) {
      if (totalSeconds > 60) {
        return this.$moment.utc(totalSeconds * 1000).format("m:ss");
      }
      return totalSeconds;
    },
  },
};
</script>

<style lang="scss" scoped>
.countdown-timer-wrapper {
  .timer {
    font-weight: bold;
    color: var(--v-navy-base);
  }
  .seconds {
    font-size: 26px;
  }
  .minutes {
    font-size: 18px;
  }
}
</style>
