<template>
  <sign-up-block
    :upperTitle="true"
    :image="image"
    :topSubTitle="topSubTitle"
    :title="title"
    :description="''"
    :buttonText="buttonText"
    :button="button"
    :checks="showChecks ? checks : []"
  />
</template>

<script>
import SignUpBlock from "./SignUpBlock";

export default {
  name: "LearnMoreBlock",
  components: {
    SignUpBlock,
  },
  props: {
    showChecks: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      image: require("@/assets/images/upsell/socks.png"),
      topSubTitle:
        `This part of ${this.$appName} is only available once you sign up for a Program`,
      title: `Find out how to get the most from ${this.$appName}`,
      buttonText: "View Programs",
      buttonUrl: "/programs",
      checks: [
        { text: `Full access to the ${this.$appName} Web App` },
        { text: "50+ live workouts per week, including HITT, LITT, Yoga, Zumba, Run, Pilates and Cycle." },
        { text: "Library of healthy recipes" },
        { text: "Join our education sessions and cook-alongs for expert fitness and nutritional guidance." },
        { text: "Participate in online and in-person events." },
      ],
      button: {
        buttonUrl: "/programs",
        raiseEvent: false,
        eventName: "",
        buttonText: "Browse programs",
        target: "",
      },
    };
  },
};
</script>
