<template>
  <div>
    <v-expansion-panels v-if="!workoutCompleted" v-model="panel">
      <activity-exercise-panel
        v-for="section in activitySections"
        :ref="`panel_${section.id}`"
        :key="section.section"
        :section="section"
        :completed-exercises="completedSectionExercises"
        @complete="completeExercise"
        @next-section="nextSection"
      />
    </v-expansion-panels>
    <j-alert v-else type="success">
      <span class="font-weight-bold"
        >Workout completed!
        <span class="text-decoration-underline pointer" @click="repeatWorkout">Repeat this workout</span></span
      >.
    </j-alert>
  </div>
</template>

<script>
import ActivityExercisePanel from "@/components/workoutblocks/cards/ActivityExercisePanel";
import ExerciseMixin from "@/mixins/ExerciseMixin";

export default {
  name: "ActivityExercises",
  mixins: [ExerciseMixin],
  components: {
    ActivityExercisePanel,
  },
  data() {
    return {
      exerciseInfoDialog: false,
      selectedExercise: null,
      completedSectionExercises: [],
      panel: 0,
    };
  },
  props: {
    unitActivity: {
      required: true,
    },
    activitySections: {
      type: Array,
      required: true,
    },
    subscriptionBlockId: {
      required: true,
    },
  },
};
</script>
