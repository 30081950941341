import gql from "graphql-tag";

export const TODO_QUERY = gql`
  query todoQuery($todoId: ID!) {
    todo(todoId: $todoId) {
      id
      title
      shortDescription
      description
      url
      youtube
      loom
      computed
    }
  }
`;

export const TODOS_QUERY = gql`
  query todosQuery($type: String, $forSubscription: Boolean) {
    todos(type: $type, forSubscription: $forSubscription) {
      edges {
        node {
          id
          title
          shortDescription
          description
          url
          youtube
          loom
          computed
          type
          subscriptionTypes {
            id
            title
          }
        }
      }
    }
  }
`;
