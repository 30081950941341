<template>
  <j-card
    flat
    rounded="lg"
    class="d-flex flex-row background-grey h-event"
    :class="{ 'fake-link': view == 'Events' }"
    @click="handleEventNavigation()"
  >
    <v-img
      v-if="event.thumbnail || (event.profile && event.profile.profilePic)"
      :src="event.thumbnail ? event.thumbnail : event.profile && event.profile.profilePic"
      :lazy-src="event.thumbnail ? event.thumbnail : event.profile && event.profile.profilePic"
      class="mw-event rounded-lg"
    />
    <div v-else class="mw-event h-event rounded-l-lg rounded-r-0" :style="gradientStyle" />
    <div class="d-flex px-3 flex-column flex-grow-1">
      <div class="pt-2 d-flex flex-row">
        <div class="d-flex flex-column flex-fill text-left">
          <div class="p-text font-weight-bold">
            {{ event.categoryName }}
          </div>
          <h3 class="p-text navy--text">
            {{ event.name }}
          </h3>
          <j-gradient-text class="p-text" :gradient="['pink', 'yellow']"
            >with {{ event.instructor.firstName }}</j-gradient-text
          >
          <v-list-item-subtitle v-if="inActive" class="pt-2">
            <open-availability :locked="locked()" />
          </v-list-item-subtitle>
        </div>

        <div v-if="showTime" class="d-flex flex-column text-right">
          <span class="sm-text navy--text">
            {{ showDaysAgo ? daysAgo(event.scheduledDate) : event.scheduledDate | moment("HH:mm") }}
          </span>
          <span v-if="eventPassed(event.scheduledDate)" class="mid-grey--text sm-text">
            Past<v-icon small class="pl-1">history </v-icon>
          </span>
          <v-chip v-if="isLive() && event.url" class="mr-2 caption" color="error" text-color="white" label small>
            <h1 class="pr-1">&#8226;</h1>
            LIVE NOW
          </v-chip>
        </div>
      </div>
      <div class="d-flex flex-row mt-auto navy--text justify-space-between mr-n1">
        <span v-if="event.durationMinutes" class="caption pa-0">{{ event.durationMinutes }} mins</span>
        <span v-if="event.targetAreaTags" class="caption pa-0">{{
          event.targetAreaTags.map((tag) => tag.title).join(" | ")
        }}</span>

        <div class="d-flex">
          <manage-bookmark
            :objectId="event.id"
            objectType="event"
            :objectNotes="event.notes"
            :bookmarkId="event.userBookmark && event.userBookmark.id"
            refreshQuery="eventQuery"
          />
          <v-icon v-if="activityLogged" :color="event.eventType.category.toString().toLowerCase()">
            radio_button_unchecked
          </v-icon>
          <v-icon v-else :color="event.eventType.category.toString().toLowerCase()" style="font-size: 28px">
            fiber_manual_record
          </v-icon>
        </div>
      </div>
    </div>
    <up-sell-pop-up :showModal="showUpSellModal" @upSellModalClosed="showUpSellModal = false" />
  </j-card>
</template>

<script>
import { decodeId } from "@/lib/string";
import ManageBookmark from "@/components/bookmarks/block/ManageBookmark";
import { utcDateStringToLocalFormat } from "@/lib/timezone";
import { gradientStyle } from "@/lib/gradientStyle";
import OpenAvailability from "@/components/shared/blocks/OpenAvailability";
import UpSellPopUp from "@/components/shared/blocks/UpSellPopUp";
import { daysAgoDescriptor } from "@/lib/fancy_date";

export default {
  name: "EventCard",
  components: {
    ManageBookmark,
    OpenAvailability,
    UpSellPopUp,
  },
  data() {
    return {
      showUpSellModal: false,
    };
  },
  props: {
    event: {
      type: Object,
      default: null,
    },
    activityLogged: {
      type: Boolean,
      default: false,
    },
    view: {
      type: String,
      default: "Events",
    },
    showDaysAgo: {
      type: Boolean,
      default: false,
    },
    showTime: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    gradientStyle() {
      return gradientStyle(["pink-background", "yellow-background"], 45);
    },
    inActive() {
      return this.$store.getters.role == "INACTIVE" || this.$store.getters.inActive;
    },
  },
  methods: {
    handleEventNavigation() {
      if (this.view != "Events") {
        return;
      }
      if (this.locked(this.event)) {
        this.showUpSellModal = true;
      } else {
        this.$router.push({
          name: "EventView",
          params: { id: decodeId(this.event.id) },
        });
      }
    },
    locked() {
      if (!this.inActive) {
        return null;
      }

      return !this.event.openAccess;
    },
    available() {
      if (!this.inActive) {
        return null;
      }

      return event.openAccess;
    },
    eventPassed(date) {
      let now = this.$moment().utc();
      let eventDate = this.$moment(date).utc();
      return now > eventDate.add(1, "h");
    },
    daysAgo(date) {
      return daysAgoDescriptor(date);
    },
    isLive() {
      let now = this.$moment().utc();
      let eventDate = this.$moment(this.event.scheduledDate).utc();

      //calculate duration & end date of event
      let startTime = this.$moment(this.event.startTime, "HH:mm:ss");
      let endTime = this.$moment(this.event.endTime, "HH:mm:ss");
      let duration = this.$moment.duration(endTime.diff(startTime));
      let passedDate = eventDate.clone().add(duration);

      /**
       * If the embed url is set to: https://www.facebook.com/groups/jefftogether
       * Then the video is NOT live and the OPS team needs to first add the video
       */
      if (!this.fbLiveVideoUpdated(this.event)) {
        return false;
      } else if (this.fbLiveVideoUpdated(this.event) && this.event.vimeoVideoId) {
        return false;
      } else if (this.fbLiveVideoUpdated(this.event) && !this.event.vimeoVideoId) {
        /**
         * If the vimeo video has not been added but the FB live video has
         * then this video is live
         */
        if (now > passedDate) return false;

        return true;
      }

      return now > eventDate && now < passedDate;
    },
    timeToLocal() {
      return utcDateStringToLocalFormat(this.event.scheduledDate);
    },
    fbLiveVideoUpdated() {
      if (!this.event.url) return false;

      let placeholderUrls = [
        "https://www.facebook.com/groups/jeffmembers",
        "https://www.facebook.com/groups/jefftogether",
      ];
      let event_url = this.event.url.trim().toLowerCase().replace(/\/$/, "");
      return !placeholderUrls.includes(event_url);
    },
  },
};
</script>

<style lang="scss" scoped>
.location-pin-icon {
  color: #9e9ca3 !important;
  font-size: 20px !important;
  padding-bottom: 5px;
  float: left;
  padding-right: 2px;
}
.mw-event {
  min-width: 130px;
  max-width: 130px;
}
.h-event {
  height: 130px;
}

.card-list-title {
  color: var(--v-secondary-base);
  white-space: normal;
  max-width: 100px;
}

.stream-container {
  line-height: 20px;
  margin-left: -2px;
  font-weight: normal;
}

.fake-link {
  cursor: pointer;

  &:hover {
    background-color: var(--v-background-grey-base);
  }
}
</style>
