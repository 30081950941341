<template>
  <div v-if="workoutBlock" class="pointer">
    <div @click="navigate()">
      <j-block-header-card
        v-if="showHeader"
        :title="workoutBlock.title"
        :sub-title="withCopy"
        :user-profile-list="workoutBlock.userList"
        :avatar-picture="instructorProfilePic"
        :secondary-avatar-picture="secondaryInstructorProfilePic"
        :accent-color="accentColor"
        :start-date="startDate"
        :end-date="endDate"
        :show-actions="showActions"
        @manage="manageBlock"
        class="transparent py-1"
      />
    </div>

    <div v-if="type === 'NUTRITION' && showHeader" class="text-left ml-2 mb-6">
      <j-btn narrow :to="'/nutrition/' + decodeId(workoutBlock.id) + '/overview'" x-small class="mb-2 mr-2">
        <span>Week's Menu</span>
        <v-icon x-small class="ml-2">mdi-calendar-month</v-icon>
      </j-btn>
      <j-btn tertiary narrow :to="'/nutrition/shopping-list/' + decodeId(workoutBlock.id)" x-small class="mb-2">
        <span>Shopping List</span>
        <v-icon x-small class="ml-2">mdi-cart-outline</v-icon>
      </j-btn>
    </div>

    <div v-if="dailyWorkoutActivities && dailyWorkoutActivities.length && hasAccess">
      <div v-if="isSelfPaced && $moment().isAfter(startDate)">
        <today-activity-block
          v-for="dailyWorkoutActivity in dailyWorkoutActivities[0].activities"
          :key="dailyWorkoutActivity.id"
          :unit-activity="dailyWorkoutActivity"
          :subscription-block="subscriptionBlock"
          :anchor-date="anchorDate"
          show-arrows
          :completed="hasCompleted(dailyWorkoutActivity, restDays, customActivities, anchorDate, subscriptionBlock.id)"
          @change-activity="activityCount += $event"
          class="mb-3"
        />
      </div>

      <div v-else-if="showUnit && $moment().isAfter(startDate)">
        <j-card
          v-for="dailyWorkoutActivity in dailyWorkoutActivities[0].activities"
          :key="dailyWorkoutActivity.id"
          class="mt-2 mb-3 pt-1 v-btn"
          rounded="xl"
        >
          <activity-day-card
            :colour="getColour(dailyWorkoutActivity)"
            :title="getActivityTitle(dailyWorkoutActivity)"
            :unit-activity="dailyWorkoutActivity"
            :current-day="dailyWorkoutActivity.currentDay"
            :progress-percent="subscriptionBlock.progressPercent"
            :total-days="workoutBlock.duration"
            :subscription-block-id="decodeId(subscriptionBlock.id)"
            :anchor-date="anchorDate"
            :has-completed="hasCompleted(dailyWorkoutActivity, restDays, customActivities, anchorDate, subscriptionBlock.id)"
            @openRestDay="$emit('openRestDay')"
          />
        </j-card>
      </div>
    </div>
  </div>
</template>

<script>
import { DAILY_WORKOUT_ACTIVITIES_QUERY, WORKOUT_BLOCK_WORKOUT_LOGS } from "@/graphql/queries/workoutblock";
import {decodeId, toGraphqlID} from "@/lib/string";
import { mapGetters, mapActions } from "vuex";
import { ACTIVITIES_AND_NUTRITION_QUERY } from "@/graphql/queries/tracker";
import { getDayOfWeekString } from "@/lib/timezone";
import { TRACKER_SUMMARY_QUERY } from "@/graphql/queries/tracker";
import BlockMixin from "@/mixins/BlockMixin";
import ActivityDayCard from "@/components/workoutblocks/cards/ActivityDayCard";
import TodayActivityBlock from "@/components/workoutblocks/cards/TodayActivityBlock";

export default {
  name: "WorkoutBlockOverview",
  mixins: [BlockMixin],
  components: {
    ActivityDayCard,
    TodayActivityBlock,
  },
  data() {
    return {
      customActivities: [],
      activityCount: null,
    };
  },
  props: {
    workoutBlock: {
      type: Object,
      required: true,
    },
    startDate: {
      type: String,
      required: false,
    },
    endDate: {
      type: String,
      required: false,
    },
    subscriptionBlock: {
      type: Object,
      required: false,
    },
    type: {
      type: String,
      default: "PROGRAM",
    },
    showUnit: {
      type: Boolean,
      default: true,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
    anchorDate: {
      type: String,
    },
  },
  computed: {
    ...mapGetters(["restDays"]),
    withCopy() {
      let copy = `${this.workoutBlock.leadInstructor.firstName}`;
      if (this.workoutBlock.secondaryInstructor?.firstName) {
        copy = copy + ` & ${this.workoutBlock.secondaryInstructor?.firstName}`;
      }
      return copy;
    },
    hasAccess() {
      const role = this.$store.getters.role;
      return this.subscriptionBlock != null && (role === "ONE_ON_ONE" || role === "CLUB");
    },
    accentColor() {
      return {
        PROGRAM: "pink",
        CHALLENGE: "yellow",
        COURSE: "yellow",
        NUTRITION: "nutrition",
      }[this.type];
    },
    weeklyProgress() {
      return Math.round(((this.subscriptionBlock.currentDay + 1) / this.subscriptionBlock.block.duration) * 100);
    },
    instructorProfilePic() {
      if (this.workoutBlock.leadInstructor && this.workoutBlock.leadInstructor.userProfile) {
        return this.workoutBlock.leadInstructor.userProfile.profilePicture;
      }
      return null;
    },
    secondaryInstructorProfilePic() {
      if (this.workoutBlock.secondaryInstructor && this.workoutBlock.secondaryInstructor.userProfile) {
        return this.workoutBlock.secondaryInstructor.userProfile.profilePicture;
      }
      return null;
    },
    isSelfPaced() {
      return this.subscriptionBlock?.isSelfPaced ?? false;
    },
  },
  methods: {
    ...mapActions(["setRestDays"]),
    decodeId(id) {
      return decodeId(id);
    },
    manageBlock() {
      this.$emit("manage");
    },
    navigate() {
      this.$router.push(`/program/${decodeId(this.workoutBlock.id)}`);
    },
  },
  apollo: {
    dailyWorkoutActivities: {
      query: DAILY_WORKOUT_ACTIVITIES_QUERY,
      fetchPolicy: "cache-and-network",
      skip() {
        return !this.subscriptionBlock || !this.anchorDate;
      },
      variables() {
        return {
          anchorDate: this.anchorDate,
          subscriptionBlockId: decodeId(this.subscriptionBlock.id),
          activityCount: this.activityCount,
        };
      },
      update(data) {
        if (!this.activityCount && data.dailyWorkoutActivities.length > 0) {
          this.activityCount = data.dailyWorkoutActivities[0]?.activities[0]?.currentActivity;
        }

        return data.dailyWorkoutActivities;
      },
    },
    workoutBlockWorkoutLogs: {
      query: WORKOUT_BLOCK_WORKOUT_LOGS,
      fetchPolicy: "network-only",
      skip() {
        return !this.subscriptionBlock;
      },
      variables() {
        return {
          subscriptionBlockId: toGraphqlID("SubscriptionBlock", decodeId(this.subscriptionBlock.id)),
        };
      },
      result(response) {
        if (response.data) {
          this.workoutBlockWorkoutLogs = response.data.workoutBlockWorkoutLogs.edges;
        }
      },
    },
    activities: {
      query: ACTIVITIES_AND_NUTRITION_QUERY,
      variables() {
        return {
          startDate: getDayOfWeekString(1, false, this.week, false),
          endDate: getDayOfWeekString(7, true, this.week, false),
          userId: btoa(`UserNode: ${this.$store.getters.user.id}`),
        };
      },
      fetchPolicy: "network-only",
      result(response) {
        if (response.data) {
          // set logs in Vuex
          this.setRestDays(response.data.restDays.edges);
          this.customActivities = response.data.activities.edges.map((item) => {
            return item.node;
          });
        }
      },
    },
    dailyTrackerSummary: {
      query: TRACKER_SUMMARY_QUERY,
      skip() {
        return !this.anchorDate;
      },
      variables() {
        return {
          summaryDate: this.anchorDate,
        };
      },
      fetchPolicy: "cache-and-network",
    },
  },
};
</script>

<style lang="scss" scoped>
.card-border-radius {
  border-radius: 40px;
}
.day-text {
  font-size: 12px;
  line-height: 24px;
}
.j-card.v-btn {
  text-transform: none;
  letter-spacing: unset;
}
</style>
