import { ACCOUNT_QUERY } from "@/graphql/queries/account";
import { UPSERT_MY_SETTINGS_MUTATION } from "@/graphql/mutations/account";
import { mapGetters } from "vuex";
import { decodeId } from "@/lib/string";

export default {
  data() {
    return {
      me: {},
      goals: [],
      goalsLoaded: false,
      coach: null,
      nutritionPlan: null,
      tribe: null,
      corporateChallenge: null,
    };
  },
  computed: {
    ...mapGetters(["selectedDate", "isLoggedIn"]),
    hasNoGoals() {
      // redo goals
      return true;
    },
    isOnboarding() {
      return this.$moment(this.me.dateJoined).add(10, "days") >= this.$moment() && !this.hasCoaching;
    },
    showOnboarding() {
      return (this.inActive && this.isOnboarding) || (this.isLifer && this.isOnboarding);
    },
    inActive() {
      return this.$store.getters.role == "INACTIVE" || this.$store.getters.inActive;
    },
    hasActiveSub() {
      return this.$store.getters.subscription != null;
    },
    onTrial() {
      // no, a user no longer gets a trial.
      return false;
      //return this.$moment(this.$store.getters.user.dateJoined) > this.$moment().subtract(1, "weeks")
    },
    isLifer() {
      return this.$store.getters.role == "JEFF_MEMBER";
    },
    hasClub() {
      return ["CLUB", "ONE_ON_ONE"].includes(this.$store.getters.role);
    },
    hasCoaching() {
      return this.$store.getters.role == "ONE_ON_ONE";
    },
    isGrad() {
      //temporary until reflections apply to all programs
      return this.$store.getters.programType.includes("WEEKLY");
    },
    name() {
      return this.$store.getters.user && this.$store.getters.user.firstName;
    },
    myProfile() {
      let myId = btoa(`UserNode: ${this.me.id}`);
      return `/user/${myId}`;
    },
    isMyPage() {
      // this is typically a prop passed to the component. Not in this mixin.
      if (!this.userId) {
        return false;
      }
      return decodeId(this.userId) == decodeId(this.$store.getters.user.id);
    },
  },
  methods: {
    nutritionPlanPdf() {
      if (this.nutritionPlan === null) return null;
      return this.nutritionPlan.pdf;
    },
    settingEnabled(setting) {
      if (this.me.userSettings.length == 0) {
        return false;
      }

      let settings = this.me.userSettings.filter((obj) => obj.key == setting);
      // show if no setting is added
      if (settings.length == 0) {
        return false;
      }

      return settings[0].value;
    },
    upsertSetting(setting, value) {
      this.$apollo
        .mutate({
          mutation: UPSERT_MY_SETTINGS_MUTATION,
          variables: {
            settingName: setting,
            value: value,
          },
          refetchQueries: ["accountQuery", "weeklyTrackerSummary"],
        })
        .then((result) => {
          if (result.data.upsertMySetting.success) {
            // this.$toasted.success("Setting Updated", {
            //   icon: "arm-flex",
            //   duration: 2000,
            //   position: "bottom-center",
            // });
          } else {
            this.$toasted.error("Unable to update preference", {
              icon: "alert",
              duration: 2000,
              position: "bottom-center",
            });
          }
        });
    },
  },
  apollo: {
    me: {
      query: ACCOUNT_QUERY,
      // we should poll this. If a user never refreshes the page, then account info may go stale.
      pollInterval: 8 * 60 * 60 * 1000,
      fetchPolicy: "cache-and-network",
      skip() {
        return !this.isLoggedIn;
      },
      result(response) {
        if (response.data && !response.loading) {
          this.$store.dispatch("updateUser", response.data.me);

          const role = response.data.subscription?.product?.type;
          if (role && this.$store.getters.role != role) {
            this.$store.dispatch("updateRole", { role: role });
            this.$store.dispatch("setSubscription", response.data.subscription);
          }

          if (response.data.activeNutritionBlock) {
            this.$store.dispatch("updateActiveNutritionBlock", response.data.activeNutritionBlock);
          }
          if (response.data.activeSubscriptionWorkoutBlocks) {
            this.$store.dispatch(
              "updateActiveSubscriptionWorkoutBlocks",
              response.data.activeSubscriptionWorkoutBlocks
            );
          }
          if (response.data.upcomingNutritionBlock) {
            this.$store.dispatch("updateUpcomingNutritionBlock", response.data.upcomingNutritionBlock);
          }
          if (response.data.userNutritionPreferences) {
            let prefs = [];
            response.data.userNutritionPreferences.forEach((pref) => {
              prefs.push(pref.nutritionPreference);
            });
            this.$store.dispatch("updateNutritionPreferences", prefs);
          }

          if (response.data.tribes.edges.length > 0) this.tribe = response.data.tribes.edges[0].node;

          this.coach = response.data.subscription?.coach;
          this.$store.dispatch("updateCoach", this.coach);

          this.nutritionPlan = response.data.newNutritionPlan;
          this.corporateChallenge = response.data?.me?.userProfile?.corporateChallenge;

          this.meLoaded = true;
        }
      },
    },
  },
};
