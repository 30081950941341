<template>
  <j-card class="j-elevated-1 px-3">
    <v-row>
      <v-col cols="6" class="d-flex justify-start pl-7">
        <div class="grey-navy--text subtitle-1 font-weight-bold">
          <template v-if="measurement.type == 'COACHING' && measurement.assessmentDate == null">
            Due {{ measurement.requiredDate | moment("ddd, D MMMM 'YY") }}
          </template>
          {{ measurement.assessmentDate | moment("ddd, D MMMM 'YY") }}
        </div>
      </v-col>
      <v-col cols="6" class="pa-0 rounded">
        <div class="grey pa-2 secondary--text">
          <strong>{{ measurement.type == "COACHING" ? "1-on-1 check-in" : "Self check-in" }}</strong>
        </div>
      </v-col>
    </v-row>

    <v-card-text class="text-left">
      <div v-if="hasMeasurement">
        <h4 class="grey-navy--text mb-2">Anthropometric</h4>
        <div>
          <span class="secondary--text subtitle-1"><strong>Weight: </strong></span>
          <span class="subtitle-1">
            {{ measurement.weight ? `${measurement.weight} kg` : "" }}
          </span>
        </div>
        <div>
          <span class="secondary--text subtitle-1"><strong>Chest: </strong></span>
          <span class="subtitle-1">{{ measurement.chest ? `${measurement.chest} cm` : "" }} </span>
        </div>
        <div>
          <span class="secondary--text subtitle-1"><strong>Abdomen: </strong></span>
          <span class="subtitle-1">
            {{ measurement.abdomen ? `${measurement.abdomen} cm` : "" }}
          </span>
        </div>
        <div>
          <span class="secondary--text subtitle-1"><strong>Hip: </strong></span>
          <span class="subtitle-1"> {{ measurement.hip ? `${measurement.hip} cm` : "" }}</span>
        </div>
        <div>
          <span class="secondary--text subtitle-1"><strong>Quad: </strong></span>
          <span class="subtitle-1">
            {{ measurement.quad ? `${measurement.quad} cm` : "" }}
          </span>
        </div>
      </div>
      <div v-if="measurement.rating">
        <span class="secondary--text subtitle-1 font-weight-bold">Self rating: </span>
        <span class="subtitle-1">
          {{ measurement.rating }}
        </span>
      </div>
      <div v-if="measurement.nutritionRating">
        <span class="secondary--text subtitle-1 font-weight-bold">Self nutrition rating: </span>
        <span class="subtitle-1">
          {{ measurement.nutritionRating }}
        </span>
      </div>
      <div v-if="measurement.nutritionComments">
        <span class="secondary--text subtitle-1 font-weight-bold">Nutrition reflection: </span>
        <span class="subtitle-1 font-weight-light">
          "{{ measurement.nutritionComments }}"
        </span>
      </div>
      <p v-if="measurement.comments" class="my-2 text-center font-weight-light std-text">
        "{{ measurement.comments }}"
      </p>
      <div v-if="measurement.imgFront || measurement.imgBack || measurement.imgSide">
        <v-row>
          <v-col cols="4" align="center" justify="center">
            <span class="secondary--text">Front</span>
          </v-col>
          <v-col cols="4" align="center" justify="center">
            <span class="secondary--text">Back</span>
          </v-col>
          <v-col cols="4" align="center" justify="center">
            <span class="secondary--text">Side</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" align="center" justify="center">
            <progress-image-block :image="measurement.imgFront" />
          </v-col>
          <v-col cols="4" align="center" justify="center">
            <progress-image-block :image="measurement.imgBack" />
          </v-col>
          <v-col cols="4" align="center" justify="center">
            <progress-image-block :image="measurement.imgSide" />
          </v-col>
        </v-row>
      </div>
      <div v-if="hasFitness" class="mt-4">
        <h4 class="grey-navy--text mb-2">Fitness</h4>
        <div>
          <span class="secondary--text subtitle-1 font-weight-bold">1 mile/1.6km: </span>
          <span v-if="measurement.oneMile" class="subtitle-1">
            {{ measurement.oneMile | humanizeSeconds }}
          </span>
        </div>
        <div>
          <span class="secondary--text subtitle-1 font-weight-bold">2min push-ups: </span>
          <span class="subtitle-1">
            {{ measurement.pushUps ? `${measurement.pushUps}` : "" }}
          </span>
        </div>
        <div>
          <span class="secondary--text subtitle-1 font-weight-bold">1min sit-ups: </span>
          <span class="subtitle-1">
            {{ measurement.sitUps ? `${measurement.sitUps}` : "" }}
          </span>
        </div>
        <div>
          <span class="secondary--text subtitle-1 font-weight-bold">1min burpee/chirpee: </span>
          <span class="subtitle-1">
            {{ measurement.burpeeChirpee ? `${measurement.burpeeChirpee}` : "" }}
          </span>
        </div>
        <div>
          <span class="secondary--text subtitle-1 font-weight-bold">Elbow plank: </span>
          <span class="subtitle-1">
            {{ measurement.elbowPlank ? `${measurement.elbowPlank} seconds` : "" }}
          </span>
        </div>
        <div>
          <span class="secondary--text subtitle-1 font-weight-bold">Squad hold/wall squat: </span>
          <span class="subtitle-1">
            {{ measurement.squat ? `${measurement.squat} seconds` : "" }}
          </span>
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <j-btn
        v-if="measurement.requiredDate == null || $moment() >= $moment(measurement.requiredDate).subtract(3, 'days')"
        rounded
        class="secondary"
        :to="`/checkins/edit/${measurement.id}`"
      >
        {{ measurement.assessmentDate == null ? "Submit" : "Edit" }}
      </j-btn>
    </v-card-actions>
  </j-card>
</template>

<script>
import ProgressImageBlock from "@/components/checkin/ProgressImageBlock";

export default {
  name: "Block",
  components: {
    ProgressImageBlock,
  },
  props: {
    measurement: {
      type: Object,
      required: true,
    },
  },
  computed: {
    hasMeasurement() {
      return (
        this.measurement.chest ||
        this.measurement.quad ||
        this.measurement.hip ||
        this.measurement.abdomen ||
        this.measurement.weight
      );
    },
    hasFitness() {
      return (
        this.measurement.oneMile ||
        this.measurement.pushUps ||
        this.measurement.sitUps ||
        this.measurement.burpeeChirpee ||
        this.measurement.elbowPlank ||
        this.measurement.squat
      );
    },
  },
};
</script>

<style scoped>
.v-card > .row:first-child {
  overflow: auto;
}
</style>
