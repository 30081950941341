<template>
  <!-- If you need access to v-slot, add an observer layer around card-details; it will work with both -->
  <validation-observer ref="observer">
    <v-form>
      <v-card-text class="pb-0">
        <v-row align="center" justify="center">
          <v-col cols="12">
            <ValidationProvider
              rules="required"
              name="Card Holder"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="cardDetails.holder"
                label="Card Holder Name"
                outlined
                dense
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="12">
            <div class="flex">
              <ValidationProvider
                rules="required"
                name="Card Number"
                v-slot="{ errors }"
              >
                <v-text-field
                  id="creditCardInput"
                  v-model="cardDetails.number"
                  :append-icon="cardBrandIcon"
                  label="Card Number"
                  type="tel"
                  outlined
                  dense
                  :error-messages="errors"
                  v-cardformat:formatCardNumber
                ></v-text-field>
              </ValidationProvider>
            </div>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col cols="7">
            <v-row align="center" justify="center">
              <v-col cols="6" class="pr-0">
                <ValidationProvider
                  rules="required"
                  name="ExpiryMonth"
                  v-slot="{ errors }"
                >
                  <v-select
                    v-model="cardDetails.expiryMonth"
                    :items="
                      Array.from(Array(12), (_, i) =>
                        (i + 1).toString().padStart(2, '0')
                      )
                    "
                    :error-messages="errors"
                    outlined
                    dense
                    label="MM"
                  >
                  </v-select>
                </ValidationProvider>
              </v-col>
              <v-col cols="6" class="pr-0">
                <ValidationProvider
                  rules="required"
                  name="ExpiryYear"
                  v-slot="{ errors }"
                >
                  <v-select
                    v-model="cardDetails.expiryYear"
                    :items="
                      Array.from(
                        Array(9),
                        (_, i) =>
                          i +
                          parseInt(
                            new Date()
                              .getFullYear()
                              .toString()
                              .substring(2, 4)
                          )
                      )
                    "
                    :error-messages="errors"
                    outlined
                    dense
                    label="YY"
                  >
                  </v-select>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="5">
            <ValidationProvider
              :rules="'required'"
              name="CVV"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="cardDetails.cvv"
                label="CVV"
                outlined
                type="number"
                @wheel="$event.target.blur()"
                dense
                :error-messages="errors"
                v-cardformat:formatCardCVC
              />
            </ValidationProvider>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </validation-observer>
</template>

<script>
export default {
  name: "CardDetails",
  props: {
    cardDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cardBrand: null,
    };
  },
  computed: {
    cardBrandIcon() {
      return this.getBrandClass(this.cardBrand);
    },
  },
  methods: {
    getBrandClass(brand) {
      let icon = "";
      brand = brand || "unknown";
      this.cardDetails.brand = brand;

      let cardBrandToClass = {
        visa: "fa fa-cc-visa",
        mastercard: "fa fa-cc-mastercard",
        amex: "fa fa-cc-amex",
        discover: "fa fa-cc-discover",
        diners: "fa fa-cc-diners-club",
        jcb: "fa fa-cc-jcb",
        unknown: "fa fa-credit-card",
      };
      if (cardBrandToClass[brand]) {
        icon = cardBrandToClass[brand];
      }

      return icon;
    },
  },
};
</script>

<style scoped>
.v-form .row + .row {
  margin-top: 0;
}
.v-form .row .col {
  padding-top: 2px;
  padding-bottom: 2px;
}
</style>

<style>
/* apply globally to override the field, but only apply to id:creditCardInput */
.v-text-field.v-input input#creditCardInput {
  margin-left: 4px;
}
</style>
