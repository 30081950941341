<template>
  <j-card elevation="0" class="grey-background mt-3" :gradient="['yellow-background', 'green-background']">
    <div class="d-flex justify-space-between pointer pa-5" :class="{'pb-0': expanded}" @click="expanded = !expanded">
      <h4 class="navy--text font-weight-medium">{{ title }}</h4>
      <v-icon v-if="expanded" size="20" class="turned">mdi-arrow-collapse</v-icon>
      <v-icon v-else size="20" class="turned">mdi-arrow-expand</v-icon>
    </div>
    <v-card-text v-show="expanded" class="pa-5 pt-0 mt-3">
      <slot />
    </v-card-text>
  </j-card>
</template>

<script>
export default {
  name: "FormBlock",
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.turned {
  transform: rotate(135deg);
}
</style>
