import gql from 'graphql-tag'

export const EARN_REWARD_MUTATION = gql`
mutation earnPointsMutation($input: EarnPointsInput!) {
  earnPointsMutation(input: $input) {
    success
    error
    event {
      id
    }
  }
}`

export const REDEEM_REWARD_MUTATION = gql`
mutation redeemPointsMutation($input: RedeemPointsInput!) {
  redeemPointsMutation(input: $input) {
    success
    error
    event {
      id
      points
      discount {
        id
        code
      }
    }
  }
}`