<template>
  <v-container>
    <v-row v-if="successText" justify="center" align="center">
      <v-col cols="12" md="7">
        <j-alert>
          {{ successText }}
        </j-alert>
      </v-col>
    </v-row>
    <v-row v-else justify="center" align="center">
      <v-col cols="12" md="7">
        <v-card>
          <v-card-text>
            <v-row>
              <v-col>
                <h2>How can we help?</h2>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <ValidationObserver ref="observer" v-slot="{ invalid }">
                  <v-form @submit.prevent="createTask">
                    <v-row>
                      <v-col cols="12" class="pb-0">
                        <ValidationProvider id="description" v-slot="{ errors }" name="Description" rules="required">
                          <j-textarea
                            v-model="data.description"
                            multi
                            outlined
                            label="What is the detail of your query"
                            :error-messages="errors"
                          />
                        </ValidationProvider>
                      </v-col>
                    </v-row>
                    <v-row align="center" justify="center">
                      <v-col cols="12">
                        <p class="caption">Click or tap icon below to include any relavant images or files.</p>
                        <v-avatar color="rgb(0, 0, 0, 0.1)" size="40">
                          <v-icon @click="$refs.multipleImageUploader.clickUploader()">mdi-file-plus</v-icon>
                        </v-avatar>
                        <multiple-image-uploader ref="multipleImageUploader" @setImage="saveImageFiles($event)" />
                      </v-col>
                    </v-row>

                    <v-row align="center" justify="center">
                      <j-alert v-if="alertText">
                        {{ alertText }}
                      </j-alert>
                      <j-btn
                        secondary
                        large
                        wide
                        type="submit"
                        :loading="buttonLoading"
                        :disabled="invalid"
                        class="mb-6 mt-2"
                      >
                        Submit
                      </j-btn>
                    </v-row>
                  </v-form>
                </ValidationObserver>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MultipleImageUploader from "@/components/shared/MultipleImageUploader";
import { CREATE_TASK_EVENT_MUTATION } from "@/graphql/mutations/task";
import { dataURLtoBlob, blobToFile } from "@/lib/file";

export default {
  name: "Contact",
  components: {
    MultipleImageUploader,
  },
  data() {
    return {
      loading: true,
      alertText: null,
      successText: null,
      buttonLoading: false,
      data: {
        title: "Support Request",
        description: "",
        taskTypeTitle: "Support",
        files: [],
      },
    };
  },
  methods: {
    saveImageFiles(files) {
      this.data.files = [];

      files.forEach((file) => {
        let blob = dataURLtoBlob(file.dataUrl);
        let tmpFile = blobToFile(blob);
        this.data.files.push(tmpFile);
      });
    },
    createTask() {
      this.data.userId = this.$store.getters.user.id;
      this.buttonLoading = true;

      this.$apollo
        .mutate({
          mutation: CREATE_TASK_EVENT_MUTATION,
          variables: {
            data: this.data,
          },
        })
        .then((result) => {
          this.buttonLoading = false;
          if (!result.data.createTaskEvent.created) {
            this.alertText = result.data.createTaskEvent.error;
          } else {
            this.successText = "Request submitted";
          }
        });
    },
  },
};
</script>
