import gql from "graphql-tag";

export const UPSERT_USER_REP_LOG = gql`
  mutation upsertUserRepLog($input: UserRepLogInput!) {
    upsertRepLog(input: $input) {
      success
      created
      error
    }
  }
`;

export const DELETE_USER_REP_LOG = gql`
  mutation deleteUserRepLog($id: ID!) {
    deleteRepLog(id: $id) {
      success
    }
  }
`;
