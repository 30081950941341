<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <v-skeleton-loader v-if="$apollo.loading" type="image, table-heading, list-item-two-line, table-tfoot" />

        <div v-else>
          <j-alert v-if="corporateMaxReached"
            >Your organisation has reached the limit of registrations. Please contact the relevant person in your
            organisation or <a href="mailto:teams@jeff.fitness">teams@jeff.fitness</a> for more information.
          </j-alert>

          <div v-else>
            <j-card class="mx-auto mb-6" v-if="article">
              <v-card-title>
                <h2 class="font-weight-bold mx-auto mt-3">{{ article.title }}</h2>
              </v-card-title>
              <v-card-text>
                <v-img class="mt-2 mb-4 mx-4" :src="article.bannerImage" />
                <v-alert dense type="info">
                  To get access, create your free profile below or
                  <router-link class="primary--text white--text font-weight-bold" to="/login"> log in </router-link>
                </v-alert>
              </v-card-text>
            </j-card>

            <j-card class="mx-auto">
              <v-card-title>
                <h2 class="font-weight-bold mx-auto my-3">Create your {{ $appName }} Account</h2>
              </v-card-title>
              <v-card-subtitle class="pt-2 text-center"
                >Already have an account?
                <a :href="loginLink" class="secondary--text font-weight-bold body-2 text-decoration-none"
                  >Log in
                </a></v-card-subtitle
              >
              <v-card-text>
                <!-- <v-row class="mb-0 pb-0">
                   <v-col cols="12">
                    <div class="video-container">
                      <youtube
                        video-id="fbwTRTgu1-c"
                        host="https://www.youtube-nocookie.com"
                        :player-vars="playerVars"
                      ></youtube>
                    </div>
                  </v-col> 
                  <v-col cols="12">
                    <q class="font-weight-bold font-italic"
                      >I'm a lot more confident, I'm in a really happy place. It's a whole body, mind and soul
                      transformation.</q
                    >
                    - Cindene, Ballito
                    <div class="mt-1" align="center">
                      <v-avatar size="100">
                        <v-img alt="cindene" :src="require('@/assets/testimonials/cindene.png')"></v-img>
                      </v-avatar>
                    </div>
                  </v-col>
                  <v-col cols="12" align="center">
                    <p class="secondary--text font-weight-bold">
                      You're almost in!
                      <br />
                      Fill out your details to create your free profile
                    </p>
                  </v-col>
                </v-row> -->
                <v-row class="mt-0 pt-0">
                  <v-col>
                    <registration-form :requires-employee-code="requiresEmployeeCode" :login-link="loginLink" />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-row>
                  <v-col
                    cols="12"
                    style="border-top: 1px solid; border-top-color: #e5e5e5"
                    align="center"
                    justify="center"
                  >
                    <v-row>
                      <v-col cols="12" align="center" justify="center" class="mt-2 mb-4 pb-0 p-text">
                        Already have an account?
                        <a :href="loginLink" class="secondary--text font-weight-bold body-2 text-decoration-none"
                          >Log in
                        </a>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-actions>
            </j-card>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ARTICLE_QUERY } from "@/graphql/queries/article";
import RegistrationForm from "@/components/authentication/blocks/RegistrationForm";
import { CORPORATE_CHALLENGE_QUERY } from "@/graphql/queries/challenge";

export default {
  name: "Register",
  components: {
    RegistrationForm,
  },
  data() {
    return {
      article: null,
      articleSlug: null,
      playerVars: {
        start: 0,
        modestbranding: 1,
        showinfo: 0,
        rel: 0,
        autoplay: 0,
        enablecastapi: 1,
      },
    };
  },
  created() {
    let path = sessionStorage.getItem("contentRedirectPath") || "";
    if (path !== "" && path.includes("/article/")) {
      sessionStorage.removeItem("contentRedirectPath");
      this.articleSlug = path.split("/").pop();
    }
  },
  computed: {
    loginLink() {
      return this.$route.params.linkingCode ? "/login?next=/join/" + this.$route.params.linkingCode : "/login";
    },
    corporateMaxReached() {
      return (
        this.corporateChallenge?.maxUsers > 0 &&
        this.corporateChallenge?.numberOfUsers >= this.corporateChallenge?.maxUsers
      );
    },
    requiresEmployeeCode() {
      return this.corporateChallenge?.requiresEmployeeCode;
    },
  },
  apollo: {
    article: {
      query: ARTICLE_QUERY,
      variables() {
        return {
          slug: this.articleSlug,
        };
      },
      fetchPolicy: "cache-and-network",
      skip() {
        return !this.articleSlug;
      },
    },
    corporateChallenge: {
      query: CORPORATE_CHALLENGE_QUERY,
      variables() {
        return {
          registrationCode: this.$route.params.linkingCode,
        };
      },
      fetchPolicy: "cache-and-network",
      skip() {
        return !this.$route.params.linkingCode;
      },
    },
  },
};
</script>
