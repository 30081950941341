<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" class="blue-background">
        <back-link icon large />
        <j-card class="j-elevated-1 ma-5">
          <v-card-title class="navy--text">
            Please speak to your coach.
          </v-card-title>
          <v-card-text class="text-left">
            <p>
              Once your Coach has activated your booking calendar, you can come back here to choose a date and time.
            </p>
          </v-card-text>
        </j-card>
        <div class="d-flex justify-center">
          <j-btn secondary wide @click="$router.go(-1)">
            Okay
            <v-icon>mdi-check-circle-outline</v-icon>
          </j-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BackLink from "@/layouts/BackLink";

export default {
  name: "Checkin",
  components: {
    BackLink
  },
};
</script>
