import gql from "graphql-tag";

export const UPDATE_PURCHASED_ITEM_MUTATION = gql`
  mutation updatePurchasedItemsMutation($data: ShoppingListItemPurchasedInput!) {
    updatePurchasedItemsMutation(data: $data) {
      success
    }
  }
`;

export const CUSTOM_SHOPPING_LIST_ITEM_MUTATION = gql`
  mutation customShoppingListItemMutation($data: CustomShoppingItemInput!){
    customShoppingListItemMutation(data: $data) {
      success
      item {
        id
      }
    }
  }
`;

export const GENERATE_SHOPPING_LIST_MUTATION = gql`
  mutation generateShoppingListMutation($data: GenerateShoppingListInput!){
    generateShoppingListMutation(data: $data) {
      success
      error
      shoppingList {
        id
        startDate
        endDate
        requiresRefresh
        shoppinglistitemSet {
          id
          quantities
          ingredient {
            id
            singular
            plural
            group {
              id
              name
            }
          }
          purchased
        }
      } 
    }
  }
`;