export const oneToTenRule = (v) => {
  if (!v) return true;
  if (!isNaN(parseFloat(v)) && v >= 1 && v <= 10) return true;
  return "Number has to be between 1 and 10";
};

export const runningRule = (v) => {
  if (!v) return true;
  let regexp = /^[0-9]+(:[0-9]{1,2})?$/;
  return regexp.test(v);
};
