<template>
  <div class="d-flex">
    <div class="d-flex">
      <user-avatar
        :profile-picture="comment.user.userProfile.profilePicture"
        :user="comment.user"
        size="30"
        :highlight-avatar="highlightPost"
        :highlight-colour="highlightColour"
      />
      <v-divider v-if="hasReplies" vertical inset class="ml-n4 mr-3 border-adjustment" />
    </div>
    <div class="d-flex flex-fill flex-column">
      <v-hover v-slot="{ hover }">
        <j-card
          class="ml-3 flex-grow-1"
          rounded="lg"
          elevation="0"
          :class="{ 'highlight-post': highlightPost }"
          :style="[highlightPost ? { 'border-color': highlightColour } : '']"
          @dblclick="!isReply ? (showReply = true) : null"
        >
          <div class="d-flex justify-space-between my-n1">
            <div class="text-left mb-2">
              <router-link :to="profileLink(comment.user)" class="navy--text font-weight-medium text-decoration-none">
                {{ comment.user.firstName }} {{ comment.user.lastName }}
                <user-type-highlight :user="comment.user" />
              </router-link>
              <span v-if="$vuetify.breakpoint.mdAndUp" class="grey--text text--darken-4"> • </span>
              <span class="sm-text grey--text text--darken-4" :class="{ 'd-block': $vuetify.breakpoint.smAndDown }">{{
                fancyShortDate(comment.updatedAt)
              }}</span>
            </div>

            <div>
              <v-btn
                small
                icon
                @click="showReply = !showReply"
                :class="{ 'd-none': isReply || ($vuetify.breakpoint.mdAndUp && !hover) }"
              >
                <v-icon small>mdi-reply</v-icon>
              </v-btn>
              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small
                    icon
                    v-bind="attrs"
                    v-on="on"
                    :class="{ 'd-none': $vuetify.breakpoint.mdAndUp && !hover }"
                  >
                    <v-icon small>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item v-if="comment.user.id != $store.getters.user.id" link @click="reportComment()">
                    <v-list-item-title>Report</v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="comment.user.id == $store.getters.user.id" link @click="deleteComment()">
                    <v-list-item-title class="p-text">Delete Comment</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div class="d-flex justify-start">
            <span class="p-text pb-2 text-left" v-html="formattedComment(comment.text)" />
          </div>
          <div v-if="showReply" class="mb-4 mr-4">
            <j-emoji-textarea
              v-model="replyText"
              :disabled="saving"
              :placeholder="'Reply to ' + comment.user.firstName + ' ' + comment.user.lastName"
              auto-grow
              autofocus
              outlined
              dense
              rows="1"
              row-height="15"
              @keydown.enter.shift.exact="postCommentReply()"
            />
            <div class="d-flex justify-end mt-n6">
              <j-btn
                tertiary
                small
                narrow
                secondary
                @click="postCommentReply()"
                :disabled="saving"
                class="mt-2 mx-2 px-0"
              >
                Reply
              </j-btn>
            </div>
          </div>
        </j-card>
      </v-hover>
      <template v-if="hasReplies">
        <comment
          v-for="commentReply in comment.commentSet.edges"
          :key="commentReply.node.id"
          class="mt-2 ml-2 d-flex"
          :postId="postId"
          :comment="commentReply.node"
          :highlightColour="highlightColour"
          :isReply="true"
          @comment-update="$emit('comment-update', $event)"
        />
      </template>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import {
  CREATE_COMMENT_MUTATION,
  DELETE_COMMENT_MUTATION,
  CREATE_MODERATION_MUTATION,
} from "@/graphql/mutations/group";
import { fancyShortDate } from "@/lib/fancy_date";
import { decodeId, nl2br } from "@/lib/string";
import UserAvatar from "@/components/shared/user/UserAvatar";
import ProfileLinkMixin from "@/mixins/ProfileLinkMixin";
import UserTypeHighlight from "@/components/groups/UserTypeHighlight";

export default {
  name: "Comment",
  mixins: [ProfileLinkMixin],
  components: {
    UserAvatar,
    UserTypeHighlight,
  },
  data() {
    return {
      showReply: false,
      replyText: null,
      saving: false,
    };
  },
  props: {
    postId: {
      type: String,
      required: true,
    },
    highlightColour: {
      type: String,
      default: "#211256",
    },
    comment: {
      type: Object,
      required: true,
    },
    isReply: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    highlightPost() {
      return this.featuredUserIds?.includes(this.comment.user.id);
    },
    hasReplies() {
      return this.comment.commentSet?.edges?.length > 0;
    },
  },
  methods: {
    fancyShortDate(date) {
      return fancyShortDate(date);
    },
    formattedComment(text) {
      const tagged = this.$options.filters.replaceMentionsWithHref(text);
      return nl2br(this.$options.filters.replaceLinksWithHref(tagged));
    },
    getId(idString) {
      return decodeId(idString);
    },
    async deleteComment() {
      this.saving = true;
      await this.$apollo.mutate({
        mutation: DELETE_COMMENT_MUTATION,
        variables: {
          id: this.getId(this.comment.id),
        },
        refetchQueries: ["comments"],
      });
      this.$emit("comment-update", "delete");
      this.saving = false;
    },
    async reportComment() {
      await this.$apollo.mutate({
        mutation: CREATE_MODERATION_MUTATION,
        variables: {
          id: this.getId(this.comment.id),
          type: "COMMENT",
        },
      });
      Vue.toasted.success(`The comment has been reported`);
    },
    async postCommentReply() {
      this.saving = true;
      await this.$apollo.mutate({
        mutation: CREATE_COMMENT_MUTATION,
        variables: {
          text: this.replyText,
          postId: this.getId(this.postId),
          parentCommentId: this.getId(this.comment.id),
        },
        refetchQueries: ["comments"],
      });
      this.replyText = null;
      this.showReply = false;
      this.$emit("comment-update", "add");
      this.saving = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.border-adjustment {
  border-width: thin;
}
</style>
