import { render, staticRenderFns } from "./FriendAvatarListing.vue?vue&type=template&id=40092e1d&"
import script from "./FriendAvatarListing.vue?vue&type=script&lang=js&"
export * from "./FriendAvatarListing.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports