<template>
  <div>
    <h3 class="mb-2">{{ surveyQuestion.question.title }} <span v-if="surveyQuestion.isRequired"> * </span></h3>

    <v-chip
      v-for="(n, i) in 11"
      :key="`${surveyQuestion.question.id}-${i}`"
      class="mr-2 mt-2"
      @click="
        selectedIndex = i;
        handleSave(i);
      "
      :color="getColour(i)"
    >
      {{ i }}
    </v-chip>

    <div v-for="child in surveyQuestion.children" :key="child.id" class="my-4">
      <question-block :surveyQuestion="child" />
    </div>
  </div>
</template>

<script>
import SurveyMixin from "@/mixins/SurveyMixin";

export default {
  name: "NpsControl",
  mixins: [SurveyMixin],
  props: {
    surveyQuestion: {
      required: true,
    },
  },
  data() {
    return {
      selectedIndex: null,
    };
  },
  methods: {
    getColour(index) {
      return index == this.selectedIndex ? "secondary" : "grey";
    },
  },
};
</script>
