<template>
  <div>
    <v-date-picker
      v-model="date"
      no-title
      color="blue"
      @update:picker-date="updateMonth($event)"
      readonly
    />
  </div>
</template>

<script>
import { MONTH_HABIT_ENTRIES_QUERY } from "@/graphql/queries/habits";
import { mapActions } from "vuex";

export default {
  name: "HabitCalendar",
  data() {
    return {
      completed: [],
      missed: [],
      date: null,
      monthStart: null,
      monthEnd: null,
    };
  },
  created() {
    this.updateCalendar();
  },
  methods: {
    ...mapActions(["setSelectedDate"]),
    updateCalendar() {
      // put @change="updateCalendar()" on v-date-picker if you want the selectedDatee to update
      this.styleDates();
      let date = this.date ? this.$moment(this.date) : this.$moment();
      this.setSelectedDate(date);
    },
    updateMonth(value) {
      this.monthStart = this.$moment(value)
        .startOf("month")
        .format("YYYY-MM-DD");
      this.monthEnd = this.$moment(value)
        .endOf("month")
        .format("YYYY-MM-DD");
    },
    styleDates() {
      var dateButtons = document.querySelectorAll(".v-date-picker-table .v-btn");

      var completedDates = this.completed.map((entry) => parseInt(entry.node.date.split("-")[2]));
      var missedDates = this.missed.map((entry) => parseInt(entry.node.date.split("-")[2]));
      dateButtons.forEach((btn) => {
        if (completedDates.includes(parseInt(btn.innerText))) {
          btn.classList.add("yellow-background");
          btn.firstElementChild.classList.add("yellow-icon--text", "text--darken-1", "font-weight-bold");
        }

        if (missedDates.includes(parseInt(btn.innerText))) {
          btn.classList.add("grey-background");
          btn.firstElementChild.classList.add("grey-icon--text", "text--darken-1", "font-weight-bold");
        }
      });
    },
  },
  apollo: {
    entries: {
      query: MONTH_HABIT_ENTRIES_QUERY,
      variables() {
        return {
          startDate: this.monthStart,
          endDate: this.monthEnd,
        };
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        this.completed = data.habitEntriesQuery?.edges.filter((edge) => edge.node.status);
        this.missed = data.habitEntriesQuery?.edges.filter((edge) => !edge.node.status);

        this.styleDates();
        return data;
      },
    },
  },
};
</script>
