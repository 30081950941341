import { mapGetters } from "vuex";
import {
  NUTRITION_BLOCK_QUERY,
  WEEK_RECOMMENDED_RECIPES_QUERY,
  NUTRITION_BLOCK_NUTRITION_LOGS,
  USER_SWAPPED_RECIPES_QUERY,
  NUTRITION_SUBSCRIPTION_BLOCKS,
} from "@/graphql/queries/nutritionblock";
import { decodeId } from "@/lib/string";
import RecipeDayListing from "@/components/nutritionblocks/RecipeDayListing";
import BlockCalendarDots from "@/components/shared/blocks/BlockCalendarDots";

export default {
  name: "RecipeListMixin",
  components: {
    RecipeDayListing,
    BlockCalendarDots,
  },
  data() {
    return {
      anchorDate: this.$moment(),
      nutritionBlock: null,
      recipeListLoading: true,
      recipesForDay: new Map(),
      useActiveNutritionBlock: true,
    };
  },
  mounted() {
    if (this.$route.query.upcoming) {
      this.useActiveNutritionBlock = false;
    }
  },
  computed: {
    ...mapGetters(["activeNutritionBlock", "upcomingNutritionBlock"]),
    isCurrent() {
      if (this.activeNutritionBlock && decodeId(this.activeNutritionBlock.id) == this.blockId) {
        return true;
      }
      if (this.upcomingNutritionBlock && decodeId(this.upcomingNutritionBlock.id) == this.blockId) {
        return true;
      }
      return false;
    },
    hasAccess() {
      const role = this.$store.getters.role;
      return role === "ONE_ON_ONE" || role === "CLUB";
    },
    monday() {
      // if we have a sd param, let's trust that and use it as Monday.
      if (this.$route.query.sd) {
        return this.$route.query.sd;
      }
      var startDate = new Date(this.anchorDate);
      startDate.setHours(0, 0, 0, 0);
      var day = startDate.getDay(),
        diff = startDate.getDate() - day + (day == 0 ? -6 : 1);
      return this.$moment(new Date(startDate.setDate(diff))).format("YYYY-MM-DD");
    },
    formattedAnchorDate() {
      return this.anchorDate.format("YYYY-MM-DD");
    },
    nutritionSubscriptionBlock() {
      return this.$store.getters.activeSubscriptionNutritionBlock;
    },
    activeSubscriptionBlockId() {
      if (this.$store.getters.activeSubscriptionNutritionBlock) {
        return decodeId(this.$store.getters.activeSubscriptionNutritionBlock.id);
      }
      return null;
    },
    currentNutritionSubscriptionBlock() {
      const blockId = this.blockId;
      if (this.nutritionSubscriptionBlocks?.edges?.length > 0) {
        const nutritionSubscriptionBlock = this.nutritionSubscriptionBlocks.edges.find(function (el) {
          return decodeId(el.node.nutritionBlock.id) == decodeId(blockId);
        });

        return nutritionSubscriptionBlock?.node ?? null;
      }
      return null;
    },
    isMine() {
      return !!this.currentNutritionSubscriptionBlock || this.isCurrent;
    },
  },
  methods: {
    calendarUpdated(date) {
      this.anchorDate = date;
    },
    getRecipesForDay(date) {
      if (!this.recipesForDay[date]) {
        if (!this.recipesForWeek) {
          this.recipesForDay[date] = null;
          return;
        }

        const anchorDate = date ? date : this.anchorDate;
        const startDate = new Date(anchorDate);
        let selectedIndex = startDate.getDay();
        // goes from 0 on a sunday to 6 on a Saturday
        // I'm trying to get the index of the array, so Monday must be 0 and Sunday must be 6
        selectedIndex = selectedIndex > 0 ? selectedIndex - 1 : 6;
        const recipesForDay = this.recipesForWeek[selectedIndex];
        this.recipesForDay[date] = recipesForDay?.nutritionblockdayrecipeSet?.edges;
      }
      return this.recipesForDay[date];
    },
    getRecipeForDay(type, date) {
      const recipesForDay = this.getRecipesForDay(date);
      if (recipesForDay) {
        const suggestedRecipe = recipesForDay.find((element) => element.node.meal === type)?.node?.recipe;
        const swappedRecipe = this.getSwappedRecipe(date, type);
        if (swappedRecipe) {
          swappedRecipe.swapped = true;
          return swappedRecipe;
        }
        return suggestedRecipe;
      }
      return null;
    },
    breakfast(date) {
      const recipe = this.getRecipeForDay("BREAKFAST", date);
      if (recipe) {
        return {
          recipe: recipe,
          log: this.getLogForMeal("BREAKFAST", date),
        };
      }
      return null;
    },
    lunch(date) {
      const recipe = this.getRecipeForDay("LUNCH", date);
      if (recipe) {
        return {
          recipe: recipe,
          log: this.getLogForMeal("LUNCH", date),
        };
      }
      return null;
    },
    dinner(date) {
      const recipe = this.getRecipeForDay("DINNER", date);
      if (recipe) {
        return {
          recipe: recipe,
          log: this.getLogForMeal("DINNER", date),
        };
      }
      return null;
    },
    primarySnack(date) {
      const recipe = this.getRecipeForDay("PRIMARY_SNACK", date);
      if (recipe) {
        return {
          recipe: recipe,
          log: this.getLogForMeal("PRIMARY_SNACK", date),
        };
      }
      return null;
    },
    getLogForMeal(meal, date) {
      if (this.nutritionBlockLogs) {
        return this.nutritionBlockLogs.find((element) => element.node.meal === meal && element.node.date == date)?.node;
      }
      return null;
    },
    getSwappedRecipe(date, type) {
      const swapped = this.userSwappedRecipes?.edges.find(
        (element) => element.node.date == date && element.node.meal == type
      );
      if (swapped) {
        let deletedRecipe = { id: 0, title: "Removed", meal: type };
        return swapped.node.recipe ? swapped.node.recipe : deletedRecipe;
      }
      return null;
    },
    getSubscriptionBlockIdForBlock(blockId) {
      // the user has manually signed up to blocks (in subscriptionnutrtionblock). Let's see if they signed up to this block they are viewing.
      if (this.nutritionSubscriptionBlocks?.edges?.length > 0) {
        const nutritionSubscriptionBlock = this.nutritionSubscriptionBlocks.edges.find(function (el) {
          return decodeId(el.node.nutritionBlock.id) == blockId;
        });
        if (nutritionSubscriptionBlock?.node?.id) {
          return decodeId(nutritionSubscriptionBlock.node.id);
        }
      }
      return null;
    },
  },
  apollo: {
    nutritionBlock: {
      query: NUTRITION_BLOCK_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          nutritionBlockId: this.blockId,
        };
      },
      skip() {
        return !this.blockId;
      },
    },
    recipesForWeek: {
      query: WEEK_RECOMMENDED_RECIPES_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          anchorDate: this.monday,
          nutritionBlockId: this.blockId,
          type: "STANDARD",
          subscriptionBlockId: this.getSubscriptionBlockIdForBlock(this.blockId),
        };
      },
      result(response) {
        this.recipeListLoading = response.recipeListLoading;
        this.recipesForDay = new Map();
      },
      skip() {
        return !this.blockId || !this.isMine;
      },
    },
    nutritionBlockLogs: {
      query: NUTRITION_BLOCK_NUTRITION_LOGS,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          nutritionBlockId: decodeId(this.blockId),
        };
      },
      update(data) {
        return data.nutritionBlockNutritionLogs.edges;
      },
      skip() {
        return !this.blockId || !this.isCurrent;
      },
    },
    userSwappedRecipes: {
      query: USER_SWAPPED_RECIPES_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          startDate: this.monday,
          endDate: this.$moment(this.monday).add(6, "days").format("YYYY-MM-DD"),
        };
      },
      skip() {
        return !this.blockId || !this.isCurrent;
      },
    },
    nutritionSubscriptionBlocks: {
      query: NUTRITION_SUBSCRIPTION_BLOCKS,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          nutritionBlock_IsActive: this.useActiveNutritionBlock,
        };
      },
    },
  },
};
