<template>
  <v-container>
    <v-card class="pb-5">
      <v-img
        v-if="image"
        :lazy-src="image"
        :src="image"
        :aspect-ratio="16 / 6"
      />
      <v-card-subtitle v-if="topSubTitle" class="pb-0 d-flex">
        <span class="center-element top-subtitle">
          <strong>{{ topSubTitle }}</strong>
        </span>
      </v-card-subtitle>
      <v-card-title
        class="mx-2 d-flex flex-column"
        :class="planClass + '--text'"
      >
        <v-icon :color="planClass" large class="mx-auto">{{ icon }}</v-icon>
        <h1
          align="center"
          justify="center"
          class="center-element sign-up-title my-2"
          :class="titleClass"
        >
          {{ title }}
        </h1>
      </v-card-title>
      <v-card-text v-if="description != ''" class="text-center pb-2">
        <span class="description"
          ><strong>{{ description }}</strong></span
        >
      </v-card-text>

      <v-card-actions>
        <v-btn
          class="px-10 mx-auto cta one-on-one white--text"
          rounded
          @click="clickButton(button)"
        >
          <strong>
            {{ button.buttonText }}
          </strong>
        </v-btn>
      </v-card-actions>

      <div v-if="secondaryButton.buttonUrl" class="mt-1 d-flex justify-center">
        <v-btn
          class="secondary--text px-11 mt-2 background-grey"
          rounded
          @click="clickSecondaryButton(secondaryButton)"
        >
          <strong>
            {{ secondaryButton.buttonText }}
          </strong>
        </v-btn>
      </div>

      <div v-if="testimonial" class="mt-8">
        <v-row align="center" justify="center">
          <v-col cols="12" sm="10" class="py-0">
            <v-card-text class="text-center py-0">
              <v-card-subtitle class="font-weight-bold pb-0 font-18">
                {{ testimonial.title }}
              </v-card-subtitle>
              <div class="pb-2" v-if="testimonial.stars">
                <v-icon
                  v-for="value in testimonial.stars"
                  :key="value"
                  small
                  color="yellow"
                >
                  star
                </v-icon>
              </div>
              <div class="pt-2">
                <q class="font-weight-bold font-italic">{{ testimonial.quote }}</q>
              </div>
              <div>- {{ testimonial.clientName }}</div>
              <div v-if="testimonial.image" class="mt-1" align="center">
                <v-avatar size="100">
                  <v-img
                    :alt="testimonial.clientName"
                    :lazy-src="testimonial.image"
                    :src="testimonial.image"
                  ></v-img>
                </v-avatar>
              </div>
            </v-card-text>
          </v-col>
        </v-row>
      </div>

      <div v-if="showExpertButton">
        <v-card-text class="text-center pb-1 mt-6">
          <span class="description"
            ><strong>
              Do you want to talk to an expert to learn more?
            </strong></span
          >
        </v-card-text>
        <v-card-actions v-if="!talkToExpertButtonClicked">
          <v-btn
            rounded
            class="px-10 mx-auto cta one-on-one white--text"
            @click="clickTalkToExpert()"
          >
            <strong>Talk to an expert</strong>
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-alert color="jeff-life" dark class="mx-auto py-3 px-10"
            >Your request has been received - we'll contact you soon!</v-alert
          >
        </v-card-actions>
      </div>

      <v-card-text v-if="checks && checks.length > 0">
        What you get:
        <v-list flat disabled class="mt-n2">
          <v-list-item-group>
            <v-list-item v-if="notLife">
              <v-list-item-icon>
                <v-icon color="jeff-life">mdi-all-inclusive</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  Everything in JEFF Life
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-for="(check, index) in checks" :key="index">
              <v-list-item-icon>
                <v-icon v-if="check.icon" :color="planClass">{{
                  check.icon
                }}</v-icon>
                <v-icon v-else :color="planClass">mdi-check</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ check.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: "SignUpBlock",
  props: {
    target: {
      type: String,
      required: false,
      default: "secondary",
    },
    topSubTitle: {
      type: String,
      required: false,
      default: null,
    },
    upperTitle: {
      type: Boolean,
      required: false,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: "Sign up to get a plan",
    },
    icon: {
      type: String,
      required: false,
    },
    image: {
      type: String,
      required: false,
    },
    description: {
      type: String,
      required: false,
      default:
        "If you're on Life, Challenges or 1-on-1, you get plans for activity, nutrition and education.",
    },
    checks: {
      type: Array,
      required: false,
    },
    testimonial: {
      type: Object,
      required: false,
    },
    showExpertButton: {
      type: Boolean,
      required: false,
      default: false,
    },
    button: {
      type: Object,
      required: true,
      default: () => {
        return {
          buttonUrl:
            "https://www.jeff.fitness/pages/jeff-starter-pack-new-website-1-on-1-v2",
          raiseEvent: false,
          eventName: "Learn more",
          buttonText: "Learn more",
          target: "_blank",
        };
      },
    },
    secondaryButton: {
      type: Object,
      required: false,
      default: () => {
        return {
          buttonUrl: "",
          raiseEvent: false,
          eventName: "",
          buttonText: "",
          target: "",
        };
      },
    },
  },
  data() {
    return {
      talkToExpertButtonClicked: false,
    };
  },
  computed: {
    planClass() {
      return this.target;
    },
    titleClass() {
      return this.upperTitle ? "upper" : "";
    },
    notLife() {
      return this.target != "jeff-life";
    },
  },
  methods: {
    clickButton(btn) {
      if (btn.raiseEvent) {
        window.analytics.track(btn.eventName, {
          opportunity: this.title,
          url: btn.buttonUrl,
        });
      }
      this.$router.push(btn.buttonUrl);
    },
    clickSecondaryButton(btn) {
      if (btn.raiseEvent) {
        window.analytics.track(btn.eventName, {
          opportunity: this.title,
          url: btn.buttonUrl,
        });
      }
      window.open(btn.buttonUrl, btn.target);
    },
    clickTalkToExpert() {
      window.analytics.track("Request A Call Clicked", {
        name: this.title,
        url: "",
      });

      this.talkToExpertButtonClicked = true;
    },
  },
};
</script>

<style scoped>
.upper {
  text-transform: uppercase;
}
.top-subtitle {
  font-size: 18px;
  text-align: center;
}
.font-18 {
  font-size: 18px;
}
.sign-up-title {
  font-weight: 900;
  font-size: 32px;
}
.description {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
}
.cta {
  font-size: 15px;
}
.v-card__actions .row .col:not(:first-child) {
  padding-top: 0;
}
</style>
