import Vue from 'vue';

export default {
  methods: {
    toastBadges(badges) {
      badges.forEach((badge) => {
        Vue.toasted.success(
          `You did it! You've earned the ${badge.title} badge. Well done!`,
          {
            icon: {
              name: 'party-popper'
            },
            action: {
              text: 'View badges',
              href: '/badges'
            }
          });
      });
    },
  }
}