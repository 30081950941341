<template>
  <v-skeleton-loader v-if="$apollo.loading" type="article" />
  <div v-else>
    <v-form>
      <validation-provider name="Meal Type" rules="required" v-slot="{ errors }">
        <j-select v-model="recipe.meal" :items="mealTypes" label="Meal Type" :error-messages="errors" />
      </validation-provider>
      <j-image-uploader :label="recipe.image ? 'Update Photo' : 'Add Photo'" :image="recipe.image" class="mb-6" />
      <validation-provider name="Title" rules="required" v-slot="{ errors }">
        <j-text-field
          v-model="recipe.title"
          label="Name"
          :hide-details="errors.length == 0"
          class="mb-4"
          :error-messages="errors"
        />
      </validation-provider>
      <j-alert v-if="showTemplateHelp" type="warning" dismissible>
        <div v-if="uneditedTemplateItems.length > 0 || missingServeText">
          Don't forget to update the template description.
          <span v-if="missingServeText">Include number of portions served eg. "Serves 4".</span>
          <span v-if="uneditedTemplateItems.length > 0">We're still seeing the following placeholders:</span>
          <ul>
            <li v-for="(item, index) in uneditedTemplateItems" :key="index">
              {{ item }}
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon @click="selectTemplateItem(item)">
                    <v-icon v-bind="attrs" v-on="on" color="navy">mdi-map-marker-question-outline</v-icon>
                  </v-btn>
                </template>
                <span>Select template item</span>
              </v-tooltip>
            </li>
          </ul>
        </div>
        <div v-else>Template has been updated.</div>
      </j-alert>
      <validation-provider name="Description" v-slot="{ errors }">
        <j-textarea
          v-model="recipe.description"
          ref="descriptionTextarea"
          label="Description"
          class="mb-4"
          rows="4"
          auto-grow
          :hide-details="errors.length == 0"
          :error-messages="errors"
          @blur="showTemplateHelp = true"
        />
      </validation-provider>

      <div class="d-flex std-text mb-2 align-center">
        Ingredients
        <span v-if="!showIngredients" class="p-text ml-2">({{ recipe.recipeingredientSet.length }})</span>

        <v-btn icon @click="toggleShowIngredients" class="ml-auto">
          <v-icon v-if="showIngredients">mdi-eye-off</v-icon>
          <v-icon v-else>mdi-eye</v-icon>
        </v-btn>
      </div>

      <div v-if="showIngredients">
        <div class="drag-block px-4">
          <draggable v-model="recipe.recipeingredientSet" @start="drag = true" @end="drag = false" handle=".handle">
            <div
              v-for="(ingredient, index) in recipe.recipeingredientSet"
              :key="ingredient.ingredient.id"
              class="drag-ingredient d-flex align-center py-1 text-left"
            >
              <ingredient-form
                v-if="newIngredient && newIngredient.index == index"
                :ordering="index + 1"
                :ingredient="newIngredient"
                :ingredient-name="$options.filters.displayName(newIngredient)"
                :ingredient-items="[...unselectedIngredients, newIngredient.ingredient]"
                :preparation-items="preparations"
                :measurement-unit-items="measurementUnits"
                :is-empty-ingredient="isEmptyIngredient(newIngredient)"
                @cancel="cancelEditing"
                @save="saveEditing"
              />
              <div v-else @dblclick="editIngredient(ingredient, index)" class="d-flex flex-fill align-center">
                <v-hover v-slot="{ hover }">
                  <div class="d-flex flex-fill align-center">
                    <v-icon v-if="hover && !drag" size="18" class="mr-3 ml-1 handle">mdi-drag-variant</v-icon>
                    <div v-else class="mr-4 ml-2">{{ index + 1 }}.</div>
                    {{ ingredient | displayName }}
                    <div v-if="hover" class="d-flex flex-fill justify-end">
                      <v-btn small icon @click="editIngredient(ingredient, index)" class="ml-1">
                        <v-icon size="18">mdi-pencil</v-icon>
                      </v-btn>
                      <v-btn small icon @click="deleteIngredient(index)" class="mr-1">
                        <v-icon size="18">mdi-trash-can</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-hover>
              </div>
            </div>
          </draggable>
          <ingredient-form
            v-if="newIngredient && newIngredient.index == null"
            :ingredient="newIngredient"
            :ingredient-name="$options.filters.displayName(newIngredient)"
            :ingredient-items="unselectedIngredients"
            :preparation-items="preparations"
            :measurement-unit-items="measurementUnits"
            :empty-ingredient="isEmptyIngredient(newIngredient)"
            class="new-ingredient"
            @cancel="cancelEditing"
            @save="saveEditing"
          />
        </div>
        <div class="d-flex align-center mt-2 ml-4" @click="addIngredient">
          <v-btn icon large class="grey"><v-icon>mdi-plus</v-icon></v-btn>
          <div class="ml-2 text-body-1 grey--text text--darken-3">Add ingredient</div>
        </div>
      </div>
      <j-alert
        v-if="!missingIngredientsDismissed && recipe.recipeingredientSet.length > 0"
        v-model="showMissingIngredients"
        type="warning"
        dismissible
        @input="missingIngredientsDismissed = true"
        class="my-4"
      >
        <div v-if="missingIngredients.length == 0">
          All ingredients accounted for in directions
        </div>
        <div v-else>
          You may be missing these ingredients from your directions:
          <ul>
            <li v-for="(item, index) in missingIngredients" :key="index">
              <div>
                {{ item.ingredient.singular }}
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="addToDirections(item.ingredient.singular)">
                      <v-icon v-bind="attrs" v-on="on" color="navy">mdi-playlist-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Add to directions</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon @click="copyIngredient(item.ingredient.singular)">
                      <v-icon v-bind="attrs" v-on="on" color="navy">mdi-content-copy</v-icon>
                    </v-btn>
                  </template>
                  <span>Copy to clipboard</span>
                </v-tooltip>
              </div>
            </li>
          </ul>
        </div>
      </j-alert>
      <validation-provider name="Directions" v-slot="{ errors }">
        <j-textarea
          v-model="recipe.directions"
          label="Directions"
          rows="3"
          placeholder="1. Step one"
          persistent-placeholder
          @keydown="showMissingIngredients = true"
          auto-grow
          :hide-details="errors.length == 0"
          :error-messages="errors"
          class="mt-8"
        />
      </validation-provider>
    </v-form>

    <j-alert v-if="showErrors" type="error" class="mt-6 mb-0">
      Some fields are missing or need more attention in order to be saved.

      <ul>
        <li v-for="(item, index) in errors" :key="index">
          {{ item }}
        </li>
      </ul>
    </j-alert>
    <div
      v-if="missingIngredients.length > 0 && missingIngredientsDismissed"
      @click="
        missingIngredientsDismissed = false;
        showMissingIngredients = true;
      "
      class="d-flex justify-end mr-5 mt-3"
    >
      <j-btn icon color="nutrition" narrow><v-icon>mdi-help</v-icon></j-btn>
    </div>
    <j-btn wide @click="$emit('submit')" :loading="loadingButton" class="mt-3">Save</j-btn>
  </div>
</template>

<script>
import { INGREDIENTS_QUERY, PREPARATION_QUERY, MEASUREMENT_UNITS_QUERY } from "@/graphql/queries/nutrition";
import IngredientForm from "@/components/nutritionblocks/blocks/IngredientForm";
import RecipeMixin from "@/mixins/nutrition/RecipeMixin";
import Draggable from "vuedraggable";

export default {
  name: "RecipeForm",
  mixins: [RecipeMixin],
  components: {
    IngredientForm,
    Draggable,
  },
  props: {
    recipe: {
      type: Object,
      required: true,
    },
    loadingButton: {
      type: Boolean,
      default: false,
    },
    showErrors: {
      type: Boolean,
      default: false,
    },
    templateItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    errors: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      drag: false,
      showIngredients: true,
      newIngredient: null,
      showMissingIngredients: false,
      missingIngredientsDismissed: false,
      showTemplateHelp: false,
    };
  },
  filters: {
    displayName(ing) {
      let plural = ing.quantity > 1;

      let display = `${ing.quantity} `;

      if (ing.measurementUnit) {
        display += plural ? `${ing.measurementUnit.plural} ` : `${ing.measurementUnit.singular} `;
      }

      display += ing.preparation?.title ? `${ing.preparation.title} ` : "";
      
      if (ing.ingredient) {
        display += plural ? `${ing.ingredient.plural} ` : `${ing.ingredient.singular} `;
      }

      return display;
    },
  },
  computed: {
    mealTypes() {
      return this.recipeTypes?.map((type) => {
        let name = type.replace("PRIMARY_", "");

        return {
          value: type,
          text: `${name.charAt(0)}${name.slice(1).toLowerCase()}`,
        };
      });
    },
    unselectedIngredients() {
      return this.ingredients?.filter((ing) => {
        return !this.recipe.recipeingredientSet.some((recipeIng) => recipeIng?.ingredient?.id == ing.id);
      });
    },
    uneditedTemplateItems() {
      return this.templateItems?.filter((temp) => {
        return this.recipe.description?.match(new RegExp(`{{ ${temp} }}`));
      });
    },
    missingServeText() {
      return !this.recipe.description?.toLowerCase().match(/serves /);
    },
    missingIngredients() {
      return this.recipe.recipeingredientSet.filter((ing) => {
        if (!ing?.ingredient) return false;
        let lettersOnly = /[^a-zA-Z ]+/g;

        let comparisons = [
          ...ing?.ingredient?.singular
            .replace(lettersOnly, "")
            .toLowerCase()
            .split(" "),
          ...ing?.ingredient?.plural
            .replace(lettersOnly, "")
            .toLowerCase()
            .split(" "),
        ];
        let expr = "";

        comparisons.forEach((comp, index) => {
          expr += `${comp}`;
          if (index != comparisons.length - 1) {
            expr += "|";
          }
        });
        return !this.recipe.directions?.toLowerCase().match(new RegExp(`${expr}`));
      });
    },
  },
  methods: {
    toggleShowIngredients() {
      this.showIngredients = !this.showIngredients;

      if (!this.showIngredients) {
        this.showMissingIngredients = true;
      }
    },
    startDrag(evt, index) {
      this.drag = true;
      evt.dataTransfer.dropEffect = "move";
      evt.dataTransfer.effectAllowed = "move";
      evt.dataTransfer.setData("index", index);
    },
    endDrag(evt, index) {
      const oldIndex = evt.dataTransfer.getData("index");
      if (index != oldIndex) {
        this.recipe.recipeingredientSet.splice(index, 0, this.recipe.recipeingredientSet.splice(oldIndex, 1)[0]);
      }
    },
    addIngredient() {
      this.saveEditing();
      this.resetNewIngredient();
    },
    resetNewIngredient() {
      this.newIngredient = {
        id: null,
        ingredient: null,
        preparation: null,
        quantity: 1,
        measurementUnit: null,
        index: null,
      };
    },
    cancelIngredient(index) {
      this.recipe.recipeingredientSet.splice(index, 1);
    },
    cancelEditing() {
      this.newIngredient = null;
    },
    saveEditing() {
      if (this.newIngredient) {
        if (this.newIngredient.index == null) {
          this.recipe.recipeingredientSet.push(this.newIngredient);
          this.newIngredient = null;
        } else {
          this.recipe.recipeingredientSet.splice(this.newIngredient.index, 1, this.newIngredient);
          this.newIngredient = null;
        }
      }

      this.closeIngredientEditing();
    },
    closeIngredientEditing() {
      this.recipe.recipeingredientSet.forEach((ing, index) => {
        if (this.isEmptyIngredient(ing)) {
          this.cancelIngredient(index);
        }
      });
    },
    isEmptyIngredient(ingredient) {
      return !ingredient || !ingredient.ingredient || !ingredient.quantity || ingredient.quantity == 0;
    },
    editIngredient(ingredient, index) {
      this.newIngredient = { ...ingredient, index: index };
      this.closeIngredientEditing();
    },
    deleteIngredient(index) {
      this.recipe.recipeingredientSet.splice(index, 1);
    },
    addToDirections(val) {
      if (!this.recipe.directions == "") {
        this.recipe.directions += "\n";
      }
      this.recipe.directions += val;
    },
    copyIngredient(val) {
      navigator.clipboard.writeText(val);
    },
    selectTemplateItem(item) {
      var match = new RegExp(`{{ ${item} }}`).exec(this.recipe.description);
      if (match) {
        this.$refs.descriptionTextarea.select(match.index, match.index + item.length + 6);
      }
    },
    validate() {
      let errors = [];
      if (this.uneditedTemplateItems?.length > 0) {
        errors.push("Description has unedited template values");
      }

      this.showTemplateHelp = true;
      return errors;
    },
  },
  apollo: {
    ingredients: {
      query: INGREDIENTS_QUERY,
      variables() {
        return {
          orderBy: "singular",
        };
      },
      update(data) {
        return data.ingredients.edges.map((edge) => edge.node);
      },
    },
    preparations: {
      query: PREPARATION_QUERY,
      update(data) {
        return data.preparations.edges.map((edge) => edge.node);
      },
    },
    measurementUnits: {
      query: MEASUREMENT_UNITS_QUERY,
      update(data) {
        return data.measurementUnits.edges.map((edge) => edge.node);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.drag-block {
  &:hover {
    .drag-ingredient:not(:last-of-type) {
      border-bottom: 2px solid var((--v-background-grey-darken1));
    }
    .new-ingredient {
      border-top: 2px solid var((--v-background-grey-darken1));
    }
  }
  .new-ingredient {
    border-top: 2px solid white;
  }
  .drag-ingredient {
    min-height: 48px;

    &:hover {
      background-color: var((--v-background-grey-base));
    }
    &:not(:last-of-type) {
      border-bottom: 2px solid white;
    }
  }
}
</style>
