<template>
  <!-- <router-link class="subtitle-1 grey-navy--text font-weight-bold text-decoration-none" :to="nextRoute"> -->
  <a class="subtitle-1 grey-navy--text font-weight-bold text-decoration-none" @click="navigate">
    <v-icon :small="!large" class="pa-3 pl-1"> keyboard_backspace </v-icon>
    <span v-if="!icon">Back</span>
  </a>
</template>

<script>
export default {
  name: "BackLink",
  props: {
    baseRoute: {
      type: String,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    navigate() {
      if (this.baseRoute) {
        return this.$router.push(this.baseRoute);
      }
      // on some pages, this requires a double click? `-2` works, but does that break other pages? TODO
      this.$router.go(-1);
    },
  },
  // data() {
  //   return {
  //     prevRoute: "",
  //   };
  // },
  // beforeRouteEnter(to, from, next) {
  //   next((vm) => {
  //     vm.prevRoute = from;
  //   });
  // },
  // computed: {
  //   nextRoute() {
  //     // doesn't work for me. Hacking it, hopefully only temp
  //     return window.history.back();
  //     //return this.prevRoute ? this.prevRoute : this.baseRoute;
  //   },
  // },
};
</script>
