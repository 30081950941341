import gql from "graphql-tag";

export const BOOKMARKS_QUERY = gql`
  query bookmarks(
    $userId: ID
    $event_ScheduledDate_Gte: DateTime
    $event_ScheduledDate_Lte: DateTime
    $search: String
    $recipeId: String
    $unitActivityId: String
    $orderBy: String
    $first: Int
    $recipeId_Isnull: Boolean
    $eventId_Isnull: Boolean
    $unitActivityId_Isnull: Boolean
    $contentResourceId_Isnull: Boolean
    $after: String
  ) {
    bookmarks(
      userId: $userId
      event_ScheduledDate_Gte: $event_ScheduledDate_Gte
      event_ScheduledDate_Lte: $event_ScheduledDate_Lte
      recipeId: $recipeId
      unitActivityId: $unitActivityId
      orderBy: $orderBy
      search: $search
      first: $first
      recipeId_Isnull: $recipeId_Isnull
      eventId_Isnull: $eventId_Isnull
      unitActivityId_Isnull: $unitActivityId_Isnull
      contentResourceId_Isnull: $contentResourceId_Isnull
      after: $after
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          notes
          createdAt
          unitActivity {
            id
            title
            type
            activity {
              id
              title
              leadInstructor {
                id
                userProfile {
                  id
                  profilePicture
                }
              }
            }
          }
          recipe {
            id
            title
            image
            userBookmarks(userId: $userId) {
              edges {
                node {
                  id
                  notes
                }
              }
            }
          }
          event {
            id
            name
            eventType {
              id
              name
              category
            }
            thumbnail
            url
            embedUrl
            scheduledDate
            startTime
            endTime
            duration
            durationMinutes
            description
            createdAt
            vimeoVideoId
            location
            openAccess
            session {
              name
              vimeoLiveId
            }
            instructor {
              id
              firstName
              lastName
              userProfile {
                id
                profilePicture
              }
            }
            useractivitySet {
              edges {
                node {
                  id
                  seconds
                  status
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const BOOKMARKS_EVENT_ONLY_QUERY = gql`
  query bookmarks(
    $userId: ID
    $event_ScheduledDate_Gte: DateTime
    $event_ScheduledDate_Lte: DateTime
    $search: String
    $orderBy: String
  ) {
    bookmarks(
      userId: $userId
      event_ScheduledDate_Gte: $event_ScheduledDate_Gte
      event_ScheduledDate_Lte: $event_ScheduledDate_Lte
      orderBy: $orderBy
      search: $search
    ) {
      edges {
        node {
          id
          event {
            id
            name
            eventType {
              id
              name
              category
            }
            thumbnail
            url
            embedUrl
            scheduledDate
            startTime
            endTime
            duration
            durationMinutes
            description
            createdAt
            vimeoVideoId
            location
            openAccess
            session {
              name
              vimeoLiveId
            }
            useractivitySet {
              edges {
                node {
                  id
                  seconds
                  status
                }
              }
            }
          }
        }
      }
    }
  }
`;
