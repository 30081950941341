<template>
  <j-banner-container
    v-if="chat"
    :header="`Members of ${chat.title}`"
    :gradient="['green-background', 'yellow-background']"
  >
    <block-calendar-dots
      showWeekDay
      accentColor="navy"
      @dateUpdated="calendarUpdated($event)"
      class="mx-1 mt-4"
    />
    <div v-if="$apollo.loading">
      <v-skeleton-loader v-for="count in 4" :key="count" type="list-item-avatar" />
    </div>

    <div v-if="!$apollo.loading" class="mt-2">
      <div v-if="chat.userchatSet.length > 0">
        <div v-for="userChat in chat.userchatSet" :key="userChat.id" class="d-flex align-center text-left">
          <chat-member-card
            v-if="canAccessChat(chat, getUserProductType(userChat.user))"
            :user="userChat.user"
            :show-details="showDetails(userChat.shareData)"
            :monday="monday"
            :activePrize="activePrize"
          />
        </div>
      </div>
    </div>
  </j-banner-container>
</template>

<script>
import { CHAT } from "@/graphql/queries/chat";
import { ACTIVE_PRIZE_QUERY } from "@/graphql/queries/prizeCalendar";
import { mapGetters, mapActions } from "vuex";
import { getMonday } from "@/lib/calendarWeek";
import BlockCalendarDots from "@/components/shared/blocks/BlockCalendarDots";
import ChatMemberCard from "@/components/chat/ChatMemberCard";
import ChatMixin from "@/mixins/ChatMixin";

export default {
  name: "ChatMemberView",
  mixins: [ChatMixin],
  components: {
    BlockCalendarDots,
    ChatMemberCard,
  },
  props: {
    chatId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["setSelectedDate"]),
    showDetails(shareData) {
      if (this.$store.getters.user.isStaff) {
        return true;
      }
      return shareData;
    },
    calendarUpdated(date) {
      this.setSelectedDate(this.$moment(date));
    },
  },
  computed: {
    ...mapGetters(["selectedDate", "role"]),
    monday() {
      if (this.selectedDate) {
        return this.$moment(getMonday(this.selectedDate.format("YYYY-MM-DD"))).format("YYYY-MM-DD");
      }
      return this.$moment(getMonday(this.$moment().format("YYYY-MM-DD"))).format("YYYY-MM-DD");
    },
  },
  apollo: {
    chat: {
      query: CHAT,
      variables() {
        return {
          chatId: this.chatId,
          withUserChats: true,
        };
      },
    },
    activePrize: {
      query: ACTIVE_PRIZE_QUERY,
      variables() {
        return {
          date: this.monday,
        };
      },
      fetchPolicy: "cache-and-network",
    },
  },
};
</script>
