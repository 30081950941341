<template>
  <div v-if="showRest" style="position: relative;">
    <v-img :max-width="maxWidth" :src="require('@/assets/images/rest-icon.png')" class="pointer rounded-lg" />
  </div>
  <div v-else style="position: relative;" @click="toggleImage">
    <v-img :max-width="maxWidth" :src="currentImage" class="pointer rounded-lg" />
    <img v-if="showOverlay" width="25" :src="require('@/assets/images/playnavy.png')" class="overlay-image pointer" />
  </div>
</template>

<script>
export default {
  name: "ActivityExerciseImage",
  data() {
    return {
      currentImage: this.exercise.staticImage,
      showOverlay: true,
    };
  },
  created() {
    // preload the image.
    const image = new Image();
    image.src = this.exercise.animatedImage;
  },
  props: {
    exercise: {
      required: true,
    },
    maxWidth: {
      required: false,
      default: 90
    },
    showRest: {
      required: false,
      default: false
    }
  },
  methods: {
    toggleImage() {
      if (this.currentImage == this.exercise.staticImage) {
        this.showOverlay = false;
        return (this.currentImage = this.exercise.animatedImage);
      }
      this.showOverlay = true;
      return (this.currentImage = this.exercise.staticImage);
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay-image {
  position: absolute;
  top: 23px;
  right: 32px;
}
</style>
