import gql from "graphql-tag";

export const CREATE_SUBSCRIPTION_BLOCK_MUTATION = gql`
  mutation createSubscriptionBlockMutation($data: SubscriptionBlockInput!) {
    createSubscriptionBlockMutation(data: $data) {
      error
      success
    }
  }
`;

export const UPDATE_SUBSCRIPTION_BLOCK_MUTATION = gql`
  mutation updateSubscriptionBlockMutation($data: SubscriptionBlockInput!) {
    updateSubscriptionBlockMutation(data: $data) {
      error
      success
    }
  }
`;

export const DELETE_SUBSCRIPTION_BLOCK_MUTATION = gql`
  mutation deleteSubscriptionBlock($subscriptionBlockId: ID!) {
    deleteSubscriptionBlock(subscriptionBlockId: $subscriptionBlockId) {
      error
      success
    }
  }
`;

export const PUT_EXERCISE_LOG_MUTATION = gql`
  mutation putExerciseLogMutation($data: ExerciseLogInput!) {
    putExerciseLogMutation(data: $data) {
      error
      success
    }
  }
`;
