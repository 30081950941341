<template>
  <v-container v-if="nutritionBlocks">
    <j-banner-container
      header="Nutrition programs"
      md="6"
      :gradient="['green-background', 'yellow-background']"
      slot-text-align="left"
    >
      <v-row>
        <v-col cols="12">
          <div v-if="loading">
            <v-progress-circular indeterminate color="secondary" size="40" class="my-4"></v-progress-circular>
          </div>
          <div v-else>
            <v-row>
              <v-col cols="12">
                <h3 class="navy--text">Choose your nutrition program</h3>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <div v-if="nutritionBlocks.length > 0">
                  <j-block-header-card
                    class="pointer"
                    v-for="nutritionBlock in nutritionBlocks"
                    :key="nutritionBlock.id"
                    :title="nutritionBlock.title"
                    :avatar-picture="
                      nutritionBlock.leadInstructor ? nutritionBlock.leadInstructor.userProfile.profilePicture : null
                    "
                    :sub-title="nutritionBlock.leadInstructor ? nutritionBlock.leadInstructor.firstName : null"
                    :user-profile-list="nutritionBlock.userList"
                    accent-color="nutrition"
                    @click.native="viewBlock(nutritionBlock.id)"
                  ></j-block-header-card>
                </div>
                <div v-else>
                  <j-alert>No results found</j-alert>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </j-banner-container>
  </v-container>
</template>

<script>
import moment from "moment";
import ChooseProgramMixin from "@/mixins/ChooseProgramMixin";
import JBlockHeaderCard from "@/layouts/cards/JBlockHeaderCard";
import { NUTRITION_BLOCKS_QUERY } from "@/graphql/queries/nutritionblock";
import { decodeId } from "@/lib/string";

export default {
  name: "ChooseNutritionProgram",
  mixins: [ChooseProgramMixin],
  components: {
    JBlockHeaderCard,
  },
  methods: {
    updateSearch() {
      this.$apollo.queries.nutritionBlocks.refetch({
        leadInstructorsIn: this.form.instructors.map((element) => {
          return element;
        }),
        tags: this.form.tags.join(","),
        startDateLte: moment().format("YYYY-MM-DD"),
      });
    },
    viewBlock(blockId) {
      this.$router.push("/nutrition/" + decodeId(blockId));
    },
  },
  apollo: {
    nutritionBlocks: {
      query: NUTRITION_BLOCKS_QUERY,
      variables: {
        isActive: true,
        showInSearches: true,
        startDateLteOrNull: moment().format("YYYY-MM-DD"),
      },
      update(data) {
        return data.nutritionBlocks.edges.map((item) => {
          return item.node;
        });
      },
    },
  },
};
</script>

<style scoped></style>
