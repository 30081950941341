
export const COLORGRADIENT = {
          componentToHex: (c) => {
            var hex = c.toString(16);
            return hex.length == 1 ? "0" + hex : hex;
          },
          clipto255: (num) => {
            return num > 255 ? 255 : num;
          },
          generateGradientColor: (index, total) => {
            let r = Math.round(COLORGRADIENT.clipto255(255 * (index / total) * 2));
            let g = Math.round(COLORGRADIENT.clipto255((255 - ((index / total) * 255)) * 2));
            let b = 0;
            return "#" + COLORGRADIENT.componentToHex(r) + COLORGRADIENT.componentToHex(g) + COLORGRADIENT.componentToHex(b);
          },
      };
