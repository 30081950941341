<template>
  <v-container>
      <v-alert v-if="error" type="error">Unable to locate coaching chat.</v-alert>
  </v-container>
</template>

<script>
import {CREATE_COACHING_CHAT_MUTATION} from "@/graphql/mutations/chat";
import {mapGetters} from "vuex";

export default {
  name: "CreateCoachingChat",
  data() {
    return {
      error: false,
    }
  },
  props: {
    handle: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(["user"]),
  },
  async created() {
    const chatId = await this.createCoachChat();
    if (!chatId)
       return this.error = true;
    this.$router.push(`/chats/${chatId}`);
  },
  methods: {
    async createCoachChat() {
      let result = await this.$apollo.mutate({
        mutation: CREATE_COACHING_CHAT_MUTATION,
        variables: {
          userId: this.user.id,
          coachHandle: this.handle
        },
      });

      if (!result.data?.createCoachingChatMutation?.id)
        return false;

      return parseInt(result.data?.createCoachingChatMutation?.id);
    }
  }
}
</script>