<template>
  <div class="d-flex justify-space-between mx-1 mt-n4 mb-n2">
    <j-btn v-if="leftArrow" icon narrow :color="color" @click.stop="$emit('arrow-clicked', -1)">
      <v-icon large>mdi-chevron-left</v-icon>
    </j-btn>
    <v-spacer />
    <j-btn v-if="rightArrow" icon narrow :color="color" @click.stop="$emit('arrow-clicked', 1)">
      <v-icon large>mdi-chevron-right</v-icon>
    </j-btn>
  </div>
</template>

<script>
export default {
  name: "ArrowRow",
  props: {
    color: {
      type: String,
      default: "pink"
    },
    leftArrow: {
      type: Boolean,
      default: true,
    },
    rightArrow: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
