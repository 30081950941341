<template>
  <div v-if="recipe">
    <j-card rounded="lg" class="my-1 j-elevated-2" @click="clickCard">
      <v-img
        v-if="recipe.image && !hideImage"
        class="pointer"
        :src="recipe.image"
        :lazy-src="recipe.image"
        :aspect-ratio="10 / 9"
      >
        <span v-if="recipeLocked" class="ribbon p-text pa-1"><v-icon>mdi-lock</v-icon>Jeff Club</span>
      </v-img>
      <v-chip v-if="isNew && !hideIsNew" small color="yellow" class="new-chip">NEW</v-chip>
      <v-chip v-if="isMine" small color="green" class="new-chip">MY RECIPE</v-chip>
      <v-card-text class=" px-2 pt-1 pb-2 text-left">
        <div class="d-flex justify-space-between">
          <j-gradient-text :gradient="['yellow', 'green']" class="text-uppercase mt-2">
            <strong>{{ recipe.meal | humanizeMeal }}</strong>
          </j-gradient-text>
          <div v-if="bookmark && bookmark.objectId && !hideBookmark">
            <manage-bookmark
              :isObjectNote="isObjectNote"
              :objectNotes="bookmark.objectNotes"
              :objectId="bookmark.objectId"
              :objectType="bookmark.objectType"
              :bookmarkId="bookmark.bookmarkId"
              @saved="onSaved"
            />
          </div>
        </div>
        <div class="navy--text font-weight-bold pointer" :class="{ 'three-lines': isListing }">
          {{ recipe.title }}
        </div>
      </v-card-text>
      <v-btn
        v-if="recipe && getMissingPreferences(recipe).length > 0"
        medium
        icon
        elevation="2"
        class="missing-preferences-button white"
      >
        <v-icon class="warning--text" @click.prevent="openMissingPrefs = true">mdi-alert-circle-outline</v-icon>
      </v-btn>
    </j-card>

    <!-- Dialog for ingredient warning -->
    <v-dialog
      v-if="getMissingPreferences(recipe).length > 0"
      v-model="openMissingPrefs"
      width="600"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <missing-preferences-dialog
        :missing-preferences="getMissingPreferences(recipe)"
        @closed="openMissingPrefs = false"
      />
    </v-dialog>

    <!-- Dialog for swapping out recipe -->
    <v-dialog
      v-if="originalRecipe"
      v-model="showSwapDialog"
      width="600"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <recipe-swap-out-confirm
        :recipe="recipe"
        :originalRecipe="originalRecipe"
        :meal="meal"
        :date="date"
        @close="showSwapDialog = false"
        @swapped="
          showSwapDialog = false;
          $emit('close');
        "
      />
    </v-dialog>
  </div>
</template>

<script>
import { decodeId } from "@/lib/string";
import ManageBookmark from "@/components/bookmarks/block/ManageBookmark";
import UserNutritionPreferencesMixin from "@/mixins/nutrition/UserNutritionPreferencesMixin";
import RecipeSwapOutConfirm from "@/components/nutritionblocks/dialogs/RecipeSwapOutConfirm";

export default {
  name: "RecipeListingCard",
  mixins: [UserNutritionPreferencesMixin],
  components: {
    ManageBookmark,
    RecipeSwapOutConfirm,
  },
  props: {
    recipe: {
      type: Object,
      required: true,
    },
    originalRecipe: {
      type: Object,
      required: false,
    },
    meal: {
      type: String,
      required: false,
    },
    date: {
      type: String,
      required: false,
    },
    hideImage: {
      type: Boolean,
      default: false,
    },
    // if we are showing this card by itself and not in a listing, then this `three-lines ` makes it look bad and should be removed
    isListing: {
      type: Boolean,
      default: true,
    },
    hideBookmark: {
      type: Boolean,
      default: false,
    },
    hideIsNew: {
      type: Boolean,
      default: false,
    },
    isObjectNote: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bookmarkId: null,
      showSwapDialog: false,
    };
  },
  computed: {
    isNew() {
      const dayDelta = this.$moment().diff(this.recipe?.createdAt, "days", true);
      return dayDelta < 30;
    },
    isMine() {
      return this.recipe.owner?.id == this.$store.getters.user.id;
    },
    bookmark() {
      let obj = {
        objectId: decodeId(this.recipe.id),
        objectNotes: this.recipe.userBookmarks?.edges[0]?.node?.notes,
        objectType: "recipe",
        refreshQuery: "recipes",
      };
      if (this.bookmarkId) {
        obj.bookmarkId = this.bookmarkId > 0 ? this.bookmarkId : null;
      }
      if (!this.bookmarkId && this.recipe?.userBookmarks?.edges?.length > 0) {
        obj.bookmarkId = decodeId(this.recipe?.userBookmarks?.edges[0].node.id);
      }
      return obj;
    },
    recipeLink() {
      return `/recipes/${decodeId(this.recipe.id)}`;
    },
    recipeLocked() {
      if (["ONE_ON_ONE", "CLUB"].includes(this.$store.getters.role)) return false;
      if (this.$store.getters.role == "JEFF_MEMBER") {
        return !this.recipe.availableToLife;
      }

      return !this.recipe.openAccess;
    },
  },
  methods: {
    onSaved(bookmarkId) {
      this.bookmarkId = bookmarkId;
    },
    viewRecipe() {
      if (this.recipeLocked) {
        this.$emit("showLockedBanner");
        return;
      }
      this.$router.push(this.recipeLink);
    },
    clickCard() {
      if (this.originalRecipe) {
        this.showSwapDialog = true;
      } else {
        this.viewRecipe();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.three-lines {
  overflow: hidden;
  height: 65px;

  @supports (-webkit-line-clamp: 3) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}
.ribbon {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateX(30%) translateY(0%) rotate(45deg);
  transform-origin: top left;
  background-image: linear-gradient(180deg, #d0f6f2, #fff3ce);
}
.ribbon:before,
.ribbon:after {
  content: "";
  position: absolute;
  top: 0;
  margin: 0 -1px; /* tweak */
  width: 100%;
  height: 100%;
  background-image: linear-gradient(180deg, #d0f6f2, #fff3ce);
}
.ribbon:before {
  right: 100%;
}

.ribbon:after {
  left: 100%;
}

.new-chip {
  position: absolute;
  top: 5px;
  left: 5px;
  color: white;
  font-weight: bold;
}
</style>
