import { LOGIN_MUTATION } from "@/graphql/mutations/authentication";
import { updateTimezone } from "@/lib/timezone";
import VueJwtDecode from "vue-jwt-decode";
import CentralStorageMixin from "@/mixins/CentralStorageMixin";

export default {
  mixins: [CentralStorageMixin],
  methods: {
    login() {
      const username = this.username.toLowerCase();
      const password = this.password;

      this.$apollo
        .mutate({
          mutation: LOGIN_MUTATION,
          variables: {
            username,
            password,
          },
        })
        .then(async (result) => {
          const login = result.data.tokenAuth;
          const accessToken = login.token;
          const tokenPayload = VueJwtDecode.decode(accessToken);
          const refreshToken = tokenPayload.exp;
          let user = login.user;
          user.phone = login.user.userProfile ? login.user.userProfile.phone : "";

          const profileImage = login.user.userProfile ? login.user.userProfile.profilePicture : "";
          let timezone = "Africa/Johannesburg";
          let userRole = "INACTIVE";
          let programType = "";

          if (login.subscription && login.subscription.product) {
            userRole = login.subscription.product.type;
            programType = login.subscription.product.programType;
          }

          if (login.subscription) {
            await this.$store.dispatch("setSubscription", login.subscription);
          }
          await this.$store.dispatch("updateColdCheckout", false);

          this.$store
            .dispatch("login", {
              accessToken,
              refreshToken,
              user,
              userRole,
              programType,
              timezone,
              profileImage,
            })
            .then(() => {
              setTimeout(function () {
                // wait before redirect to prevent 400 errors due to token not being set
                if (login.userProfile) updateTimezone(this.$store, this.$apollo);
              }, 3000);
              this.redirectToNext(user, userRole);
            });
        })
        .catch(() => {
          this.buttonLoading = false;
          this.errorMessage = "Please enter a valid email & password combination";
        });
    },

    redirectToNext(user, userRole) {
      window.analytics.identify(user.email, {
        name: user.firstName + " " + user.lastName,
        email: user.email,
        isStaff: user.is_staff,
        subscription: userRole,
      });

      window.analytics.track("Logged In", {
        subscription: userRole,
      });
      this.$apollo.queries.assistantTasks.refetch();

      if (this.$route.query.next) {
        this.$router.push(this.$route.query.next);
        return;
      }

      let path = sessionStorage.getItem("redirectPath") || "";
      this.buttonLoading = false;

      if (path) {
        sessionStorage.removeItem("redirectPath");
        return this.$router.push(path);
      }

      const userId = this.$store.getters.user.id;
      if (userId) {
        this.$OneSignal.setExternalUserId(userId);
      }

      this.$router.push("/today");
    },
  },
};
