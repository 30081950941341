<template>
  <v-dialog
    transition="dialog-top-transition"
    max-width="600"
    v-model="showModal"
  >
    <v-card>
      <v-btn
        icon
        elevation="4"
        color="white"
        class="floating-button"
        @click="closeModal()"
      >
        <v-icon large>mdi-close</v-icon>
      </v-btn>
      <learn-more-block :showChecks="false" class="pa-0" />
    </v-card>
  </v-dialog>
</template>

<script>
import LearnMoreBlock from "@/components/shared/blocks/LearnMoreBlock";

export default {
  name: "UpSellPopUp",
  components: {
    LearnMoreBlock,
  },
  props: {
    showModal: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    closeModal() {
      this.$emit("upSellModalClosed");
    },
  },
};
</script>

<style scoped>
.font-18 {
  font-size: 18px;
}
.floating-button {
  background-color: rgba(0,0,0,0.3);
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 1;
}
</style>
