<template>
  <div class="flex-fill j-elevated-3 mb-4">
    <j-card class="j-elevated-1 pa-6 bg-blue">
      <div style="position: relative; width: 100%; height: 10px">
        <div style="position: absolute; top: 0; right: 0">
          <v-icon large color="white" class="pointer" @click="showModal = true"> mdi-information-outline </v-icon>
        </div>
      </div>
      <div class="white--text font-weight-medium">YOU'RE USING THE</div>
      <div class="white--text font-weight-bold" style="font-size: 38px">JEFF Web Portal</div>

      <div
        class="sm-text white--text font-weight-medium text-decoration-underline pointer mt-2"
        @click="showModal = true"
      >
        Find out more
      </div>
      <!-- <div class="d-flex">
        <div class="px-5"><v-img :src="require('@/assets/images/app_collage.png')" :max-width="100"></v-img></div>
        <div class="d-flex flex-column justify-center pl-md-6 pl-0">
          <div class="text-left grey-navy--text">#JEFFEVOLUTION</div>
          <h1 class="text-left navy--text">Our Native App is live!</h1>
        </div>
      </div>

      <div class="text-left grey-navy--text pt-4">
        We recommend you download and start using it to get the best out of your experience with JEFF.
        <span v-if="this.$store.getters.user.email">
          Be sure to login with the email <strong>{{ this.$store.getters.user.email }}</strong
          >. <b>Do not create a new account.</b></span
        >
      </div>

      <native-app-icons class="mt-4" /> -->
    </j-card>

    <v-dialog v-if="showModal" v-model="showModal" max-width="600px">
      <j-card class="pa-2 card-radius">
        <div class="text-center">
          <div class="text-center">
            <div class="pt-2 std-text font-weight-medium">YOU'RE USING THE</div>
            <div class="secondary--text font-weight-bold" style="font-size: 32px">JEFF Web Portal</div>
          </div>
          <v-card-text>
            <p class="p-text grey-navy--text">Our Web Portal is here for you to easily:</p>

            <div class="p-text grey-navy--text">
              <v-icon color="teal" small class="mr-2">mdi-check</v-icon>Manage your subscription
            </div>
            <div class="p-text grey-navy--text mt-2">
              <v-icon color="teal" small class="mr-2">mdi-check</v-icon>Access workouts from your laptop
            </div>
            <div class="p-text grey-navy--text mt-2">
              <v-icon color="teal" small class="mr-2">mdi-check</v-icon>Cast workouts to your TV
            </div>

            <div class="mt-6 std-text font-weight-medium">FOR EVERYTHING ELSE, GO TO THE</div>
            <div class="mt-4 secondary--text font-weight-bold" style="font-size: 32px">JEFF Fitness APP</div>

            <div class="grey-navy--text pt-6">
              Don't have it yet? Download the JEFF Fitness app from your App Store
              <span v-if="this.$store.getters.user.email">
                and login with your email <strong>{{ this.$store.getters.user.email }}</strong
                >. <br /><br />
                <b>Do not create a new account.</b></span
              >
            </div>

            <div>
              <p class="p-text grey-navy--text mt-6">
                While both platforms share the same data, we'd recommend using the App to:
              </p>

              <div class="p-text grey-navy--text">
                <v-icon color="teal" small class="mr-2">mdi-check</v-icon>Access your daily workouts & meals
              </div>
              <div class="p-text grey-navy--text mt-2">
                <v-icon color="teal" small class="mr-2">mdi-check</v-icon>Track your movement, meals and mindset
              </div>
              <div class="p-text grey-navy--text mt-2">
                <v-icon color="teal" small class="mr-2">mdi-check</v-icon>Be inspired by our community
              </div>
              <div class="p-text grey-navy--tex mt-2">
                <v-icon color="teal" small class="mr-2">mdi-check</v-icon>Stay up to date wiht all the latest news &
                offers
              </div>
              <div v-if="$store.getters.role == 'ONE_ON_ONE'" class="p-text grey-navy--text mt-2">
                <v-icon color="teal" small class="mr-2">mdi-check</v-icon>Chat to your coach
              </div>
            </div>

            <div class="d-flex justify-center align-center mt-6">
              <a href="https://apps.apple.com/vn/app/jeff-fitness/id6470368710" target="_blank"
                ><v-img :src="require('@/assets/images/appstore_small.jpg')" width="70" class="mr-4"></v-img
              ></a>

              <a
                href="https://play.google.com/store/apps/details?id=fitness.jeff.jeffnative&hl=en_ZA&gl=US"
                target="_blank"
                ><v-img :src="require('@/assets/images/playstore_small.jpg')" width="70" class="mr-4"></v-img
              ></a>

              <a href="https://appgallery.huawei.com/#/app/C109805715" target="_blank"
                ><v-img :src="require('@/assets/images/huaweistore_small.jpg')" width="70"></v-img
              ></a>
            </div>
          </v-card-text>
        </div>
      </j-card>
    </v-dialog>
  </div>
</template>

<script>
//import NativeAppIcons from "@/components/shared/NativeAppIcons";

export default {
  name: "NativeAppBanner",
  // components: {
  //   NativeAppIcons,
  // },
  data() {
    return {
      showModal: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.bg-blue {
  background-color: #1f008e;
}
</style>
