<template>
  <j-main-banner-container header="Leaderboards" :gradient="['green-background', 'yellow-background']">
    <j-card class="j-elevated-1 mx-auto" align="left">
      <v-list-item-group class="px-6">
        <v-list-item class="px-0" :to="`/leaderboard/global`">
          <v-list-item-icon class="mr-2">
            <v-icon class="navy--text">mdi-earth</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="navy--text text-uppercase font-weight-bold">
              Global Leaderboard
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon class="align-center">
            <v-icon class="navy--text" size="27">mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item v-if="friendCount > 0" class="px-0" :to="`/leaderboard/friends`">
          <v-list-item-icon class="mr-2">
            <v-icon class="navy--text">mdi-account-multiple</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="navy--text text-uppercase font-weight-bold">
              Friends Leaderboard
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon class="align-center">
            <v-icon class="navy--text" size="27">mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item v-if="gymBranch && gymBranch.group" class="px-0" :to="`/leaderboard/gym`">
          <v-list-item-icon class="mr-2">
            <v-icon class="navy--text">mdi-arm-flex</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="navy--text text-uppercase font-weight-bold"> Gym Leaderboard </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon class="align-center">
            <v-icon class="navy--text" size="27">mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-list-item
          v-for="leaderboard in myActiveLeaderboards"
          :key="leaderboard.id"
          class="px-0"
          :to="`/leaderboard/${leaderboard.id}`"
        >
          <v-list-item-icon class="mr-2">
            <v-icon class="navy--text">mdi-chart-bar</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="navy--text text-uppercase font-weight-bold">
              {{ leaderboard.title }}
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon class="align-center">
            <v-icon class="navy--text" size="27">mdi-chevron-right</v-icon>
          </v-list-item-icon>
        </v-list-item>
      </v-list-item-group>
    </j-card>
  </j-main-banner-container>
</template>

<script>
import { USER_LEADER_BOARDS_QUERY } from "@/graphql/queries/leaderboards";

export default {
  name: "LeaderboardList",
  data() {
    return {
      myActiveLeaderboards: [],
    };
  },
  computed: {
    gymBranch() {
      return this.$store.getters.user?.userProfile?.gymBranch;
    },
    friendCount() {
      return this.$store.getters.user?.userProfile?.friendsCount ?? 0;
    },
  },
  apollo: {
    myActiveLeaderboards: {
      query: USER_LEADER_BOARDS_QUERY,
      fetchPolicy: "cache-and-network",
    },
  },
};
</script>

<style scoped>
.v-list-item:not(:last-child) {
  border-bottom: var(--v-grey-base) 1px solid;
}
</style>
