<template>
  <v-skeleton-loader v-if="$apollo.loading" type="card" />
  <div v-else>
    <highcharts v-if="weightChart()" :options="weightChart()" />
    <j-dotted-btn v-else wide color="primary" to="/checkins/new">{{ buttonText }}</j-dotted-btn>
  </div>
</template>

<script>
import { USER_CHECK_INS_QUERY } from "@/graphql/queries/checkins";
import { commonChartOptions } from "@/lib/chart";

export default {
  name: "CheckinSummary",
  props: {
    goalWeight: {
      type: [Number, String],
      required: false,
    },
    buttonText: {
      type: String,
      default: "Capture your weight",
    },
  },
  methods: {
    weightChart() {
      if (this.userCheckIns) {
        if (this.userCheckIns?.length == 0) {
          return null;
        }

        return {
          ...commonChartOptions,
          chart: {
            type: "container",
            height: 200,
            zoomType: "x",
          },
          yAxis: {
            min: 40,
            softMin: 40,
            softMax: 100,
            title: {
              text: "Weight (kg)",
            },
            plotBands: [
              {
                color: this.$vuetify.theme.themes.light.sleep,
                from: parseFloat(this.goalWeight || 0),
                to: parseFloat(this.goalWeight || 0) - 1,
              },
            ],
          },
          xAxis: {
            type: "datetime",
            zoomEnabled: true,
          },
          tooltip: {
            valueDecimals: 1,
            valueSuffix: "kg",
          },
          plotOptions: {
            series: {
              relativeXValue: true,
            },
            areaspline: {
              marker: {
                enabled: true,
              },
            },
          },
          series: [
            {
              type: "areaspline",
              name: "Weight",
              showInLegend: false,
              color: this.$vuetify.theme.themes.light.hydration,
              data: this.userCheckIns,
            },
          ],
        };
      }
    },
  },
  apollo: {
    userCheckIns: {
      query: USER_CHECK_INS_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          // UI seems ok with a lot of checkins. But let's still limit it to an arbitray value.
          first: 50,
          assessmentDate_Isnull: false,
          orderBy: "assessmentDate",
        };
      },
      update(data) {
        return data.userCheckIns.edges
          .filter((checkin) => checkin.node.weight != null)
          .map((checkin) => {
            let date = this.$moment(checkin.node.assessmentDate);
            return [Date.UTC(date.year(), date.month(), date.date()), parseFloat(checkin.node.weight)];
          });
      },
    },
  },
};
</script>
