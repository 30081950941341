export default {
  state: {
    tasks: 0,
    checkinBookingRequired: false,
    checkinRequired: false,
    checkinBookingLink: "",
    checkinIsInitial: false,
    checkinId: null,
    outstandingUserTodos: [],
    skipTasks: JSON.parse(localStorage.getItem("skipTasks")) || {
      lastSkip: null,
      skipped: false,
      taskCount: 0,
    },
  },
  mutations: {
    mutateTasks(state) {
      let count = 0;

      count += state.checkinBookingRequired || state.checkinRequired || false;
      count += state.outstandingUserTodos.length;

      state.tasks = count;
    },
    mutateCheckinStatus(state, { status }) {
      if (status == "CHECK_IN_BOOKING_REQUIRED") {
        state.checkinBookingRequired = true;
        state.checkinRequired = false;
      } else if (status == "CHECK_IN_REQUIRED") {
        state.checkinBookingRequired = false;
        state.checkinRequired = true;
      } else {
        state.checkinBookingRequired = false;
        state.checkinRequired = false;
      }
    },
    mutateCheckinId(state, { id }) {
      state.checkinId = id;
    },
    mutateBookingLink(state, { bookingLink }) {
      state.checkinBookingLink = bookingLink;
    },
    mutateOutstandingUserTodos(state, { todos }) {
      state.outstandingUserTodos = todos;
    },
    mutateCheckinIsInitial(state, { initial }) {
      state.checkinIsInitial = initial;
    },
    mutateSkipTasks(state, lastSkip) {
      state.skipTasks = lastSkip;
      localStorage.setItem("skipTasks", JSON.stringify(state.skipTasks));
    },
  },
  actions: {
    updateCheckinStatus({ commit }, status) {
      commit("mutateCheckinStatus", { status });
      commit("mutateTasks");
    },
    updateCheckinBookinglink({ commit }, bookingLink) {
      commit("mutateBookingLink", { bookingLink });
    },
    updateCheckinId({ commit }, id) {
      commit("mutateCheckinId", { id });
    },
    updateCheckinIsInitial({ commit }, initial) {
      commit("mutateCheckinIsInitial", { initial })
    },
    updateOutstandingUserTodos({ commit }, todos) {
      commit("mutateOutstandingUserTodos", { todos });
      commit("mutateTasks");
    },
    updateSkipTasks({ commit }, lastSkip) {
      commit("mutateSkipTasks", lastSkip);
    },
  },
  getters: {
    tasks: (state) => state.tasks,
    checkinRequired: (state) => state.checkinRequired,
    checkinBookingRequired: (state) => state.checkinBookingRequired,
    checkinBookingLink: (state) => state.checkinBookingLink,
    checkinIsInitial: (state) => state.checkinIsInitial,
    checkinId: (state) => state.checkinId,
    outstandingUserTodos: (state) => state.outstandingUserTodos,
    skipTasks: (state) => state.skipTasks,
  },
};
