<template>
  <v-row justify="center" class="mb-4 mx-0">
    <v-col
      cols="12"
      :md="md"
      :lg="lg"
      :xl="xl"
      align="center"
      justify="center"
      class="j-main-banner-container white pa-0 mt-1 j-elevated-1 rounded-lg"
    >
      <div v-if="header" :class="color" :style="gradientStyle" class="py-4">
        <div class="d-flex" :class="!!$slots.header ? 'justify-space-between' : 'justify-center'">
          <h1 class="secondary--text header heading-2 ml-4">{{ header }}</h1>
          <div v-if="!!$slots.header">
            <slot name="header"></slot>
          </div>
        </div>
        <v-row v-if="search" justify="center">
          <v-col cols="11" md="10" class="py-1">
            <v-text-field
              rounded
              v-model="searchString"
              background-color="white"
              height="46"
              hide-details
              placeholder="What are you looking for?"
              @input="emitSearch()"
              class="search-field"
            >
              <template v-slot:prepend-inner>
                <v-icon size="32" class="ml-n4 mr-4 mt-1">mdi-magnify</v-icon>
              </template>
              <template v-slot:append>
                <v-icon v-if="searchString" size="28" class="mr-n4 mt-1" @click="clear()">mdi-close</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </div>
      <div :class="slotTextAlignClass">
        <slot />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { gradientStyle } from "@/lib/gradientStyle";

export default {
  name: "JMainBannerContainer",
  data() {
    return {
      keyUpSearchInterval: null,
      searchString: null,
    };
  },
  props: {
    header: {
      type: String,
      required: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    searchVal: {
      type: String,
      required: false,
      default: "",
    },
    color: {
      type: String,
      required: false,
    },
    gradient: {
      type: Array,
      required: false,
    },
    md: {
      type: [String, Number],
      required: false,
      default: 6,
    },
    lg: {
      type: [String, Number],
      required: false,
      default: 6,
    },
    xl: {
      type: [String, Number],
      required: false,
      default: 5,
    },
    slotTextAlign: {
      type: String,
      required: false,
      default: "center",
    },
  },
  computed: {
    gradientStyle() {
      return gradientStyle(this.gradient, 180);
    },
    slotTextAlignClass() {
      return {
        center: "",
        left: "text-left",
      }[this.slotTextAlign];
    },
  },
  mounted() {
    if (this.searchVal) this.searchString = this.searchVal;
  },
  methods: {
    emitSearch() {
      if (this.keyUpSearchInterval) {
        clearTimeout(this.keyUpSearchInterval);
        this.keyUpSearchInterval = null;
      }
      this.keyUpSearchInterval = setTimeout(() => {
        this.$emit("searchValueChanged", this.searchString);
      }, 1000);
    },
    clear() {
      this.searchString = null;
      this.$emit("searchValueChanged", "");
    },
  },
};
</script>

<style lang="scss" scoped>
.j-main-banner-container {
  .row {
    margin: 0;
  }
}
.search-field {
  max-width: 550px;
  .v-input {
    text-align: center;
  }
}
</style>
