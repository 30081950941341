import gql from "graphql-tag";

export const TASKS_QUERY = gql`
  query taskEvents($userId: ID!, $taskTypeTitle: String) {
    taskEvents(userId: $userId, taskType_Title: $taskTypeTitle) {
      edges {
        node {
          id
          title
          description
          taskType {
            id
            title
          }
        }
      }
    }
  }
`;
