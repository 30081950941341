export default {
  state: {
    settings: new Map(),
    ingredientMultiplier: localStorage.getItem("ingredientMultiplier") || 0,
  },
  mutations: {
    mutateSetting(state, { setting }) {
      let category = state.settings.get(setting.type);

      if (category) {
        category.set(setting.key, setting.value);
      } else {
        state.settings.set(setting.type, new Map().set(setting.key, setting.value));
      }
    },
    mutateIngredientMultiplier(state, { multiplier }) {
      state.ingredientMultiplier = multiplier;
    },
  },
  actions: {
    updateSetting({ commit }, setting) {
      commit("mutateSetting", { setting });
    },
    updateSettings({ commit }, settings) {
      settings.forEach((setting) => {
        commit("mutateSetting", { setting });
      });
    },
    updateIngredientMultiplier({ commit }, multiplier) {
      localStorage.setItem("ingredientMultiplier", multiplier);
      commit("mutateIngredientMultiplier", { multiplier });
    },
  },
  getters: {
    settings: (state) => state.settings,
    ingredientMultiplier: (state) => state.ingredientMultiplier,
  },
};
