<template>
  <v-dialog v-model="dialog" width="500">
    <j-card>
      <v-card-title class="headline grey lighten-2">
        <span v-if="originalPlan">Change Nutrition Plan</span>
        <span v-else>Your Nutrition Plan</span>
      </v-card-title>
      <v-divider />
      <v-card-text class="text-center pb-0">
        <div class="video-container mb-4">
          <youtube
            class="my-5"
            key="1"
            video-id="FscJiX-zBRk"
            host="https://www.youtube-nocookie.com"
            :player-vars="playerVars"
          ></youtube>
        </div>
        <p v-if="originalPlan">
          Based on your information, we recommend you move from <strong>Balanced {{ originalPlan }}</strong> to
          <strong>Balanced {{ newPlan }}</strong> to reach your goals.
        </p>
        <p v-else>
          Based on your information, we recommend you use our <strong>Balanced {{ newPlan }}</strong> nutrition plan to
          reach your goals.
        </p>
      </v-card-text>
      <v-card-actions class="pb-5">
        <j-btn tertiary @click="cancel"> No Thanks </j-btn>
        <v-spacer />
        <j-btn primary class="secondary--text pa-2" @click="confirm"> Confirm </j-btn>
      </v-card-actions>
    </j-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmNutritionChangeDialog",
  props: {
    originalPlan: {
      type: String,
      required: false,
    },
    newPlan: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      playerVars: {
        start: this.videoStartTime,
        modestbranding: 1,
        showinfo: 0,
        rel: 0,
        autoplay: 0,
        enablecastapi: 1,
      },
    };
  },
  methods: {
    open() {
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    confirm() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
