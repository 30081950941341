<template>
  <v-progress-linear color="navy" height="20" rounded :value="uploadPercentCompleted">
    <template v-slot:default="{ value }">
      <span v-if="value < 100" class="white--text font-weight-bold p-text">{{ Math.ceil(value) }}%</span>
      <span v-else class="white--text font-weight-bold sm-text"
        >Compressing
        <v-progress-circular size="13" class="ml-2" width="2" indeterminate color="white"></v-progress-circular
      ></span>
    </template>
  </v-progress-linear>
</template>

<script>
export default {
  name: "UploadProgress",
  props: {
    uploadPercentCompleted: {
      type: Number,
      default: 0,
    },
  },
};
</script>
