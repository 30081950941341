<template>
  <div>
    <v-row align="center" justify="center">
      <v-col cols="12" class="pa-0">
        <v-card-text class="p-text mt-2">
          Please note, this product requires a recurring JEFF Club membership, so we have
          added this for you. <span class="font-weight-bold">You can go to 'Profile' → 'Manage Subscription' anytime to change your membership.</span>
        </v-card-text>
      </v-col>
    </v-row>
    <product-block
      v-if="!loading"
      ref="productBlock"
      :product="jeffClubProduct"
      :show-amount="true"
      :is-required-product="true"
      :allow-billing-period-selection="true"
    />
  </div>
</template>

<script>
import { BILLING_PRODUCTS_QUERY } from "@/graphql/queries/store";
import ProductBlock from "@/components/store/blocks/ProductBlock";

export default {
  name: "ClubProductBlock",
  components: { ProductBlock },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      currency: "zar",
      jeffClubProduct: null,
    };
  },
  watch: {
    "jeffClubProduct.variant": function(value) {
      this.product.clubVariant = value;
      this.$store.dispatch("updateCheckoutProduct", this.product);
    },
  },
  apollo: {
    jeffClubProduct: {
      query: BILLING_PRODUCTS_QUERY,
      variables() {
        return {
          code: "jeff-club",
          withPackages: false
        };
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        if (data.billingProducts.edges.length > 0) {
          this.loading = false;
          let product = data.billingProducts.edges[0].node;

          if (product.productvariantSet.edges)
            product.productvariantSet = product.productvariantSet.edges.map(
              (edge) => edge.node
            );

          product.variant = this.product.clubVariant;
          return product;
        }
        return null;
      },
    },
  },
};
</script>


