<template>
  <j-card class="pr-6 pointer d-inline-block" elevation="0" @click="click">
    <v-row v-if="image" align="center">
      <v-col cols="12" class="pt-5 d-flex">
        <v-img class="rounded-lg j-elevated-1" :aspect-ratio="1" :src="image" :lazy-src="image" width="150">
          <div class="top-left d-flex flex-column">
            <v-chip v-if="showNewChip" small color="yellow">New</v-chip>
            <v-icon v-if="filledExerciseDot" size="35" :color="dotColor" class="mr-auto">mdi-circle</v-icon>
            <v-icon v-else-if="unfilledExerciseDot" size="35" :color="dotColor" class="mr-auto"
              >mdi-circle-outline</v-icon
            >
          </div>
          <v-chip v-if="badgeCount" color="#ffcad6" class="post-chip font-weight-bold pink--text" @click="click">
            {{ badgeCount }}
          </v-chip>
          <div v-if="showIcons" class="icons rounded pa-1 ma-1" :class="{ 'd-block': $vuetify.breakpoint.mdAndDown }">
            <v-icon v-if="editable" class="pa-1" @click.stop="edit()">mdi-cog-outline</v-icon>
            <v-icon v-else class="pa-1" @click.stop="leave()">mdi-logout</v-icon>
          </div>
        </v-img>
      </v-col>
    </v-row>
    <v-row class="mt-0">
      <v-col cols="12" class="text-left navy--text p-text">
        <div v-if="title">
          <span v-if="shortenedTitle" class="font-weight-bold">{{ title | shortened }} </span>
          <span v-else class="font-weight-bold">{{ title }} </span>
        </div>

        <span v-if="subTitle && !(memberCount > 0)">{{ subTitle }}</span>
        <p v-if="memberCount > 0" class="font-italic mid-grey--text">
          {{ memberCount }} member{{ memberCount > 1 ? "s" : "" }}
        </p>
      </v-col>
    </v-row>
  </j-card>
</template>

<script>
export default {
  name: "JBlockCard",
  props: {
    id: {
      required: true,
    },
    image: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
    shortenedTitle: {
      type: Boolean,
      default: false,
    },
    subTitle: {
      type: String,
      required: false,
    },
    memberCount: {
      type: Number,
      required: false,
    },
    badgeCount: {
      type: Number,
      required: false,
    },
    showNewChip: {
      type: Boolean,
      required: false,
    },
    showIcons: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    unfilledExerciseDot: {
      type: Boolean,
      default: false,
    },
    filledExerciseDot: {
      type: Boolean,
      default: false,
    },
    dotColor: {
      type: String,
      default: "exercise",
    },
  },
  filters: {
    shortened(value) {
      if (value?.length > 25) return `${value.substring(0, 25)}...`;
      return value;
    },
  },
  methods: {
    click() {
      this.$emit("clicked", this.id);
    },
    edit() {
      this.$emit("clickEdit");
    },
    leave() {
      this.$emit("clickLeave");
    },
  },
};
</script>

<style lang="scss" scoped>
.card__title {
  font-size: 14px;
  line-height: 16px;
}
.card__subtitle {
  padding-top: 2px;
  font-size: 12px;
  line-height: 16px;
}
.top-left {
  position: absolute;
  top: 5px;
  left: 5px;
  color: white;
}
.post-chip {
  position: absolute;
  top: 5px;
  right: 5px;
  color: white;
}
.badge {
  z-index: 2;
}

.j-card {
  .v-image .v-responsive__content .icons {
    position: absolute;
    bottom: 0;
    background-color: black;
    opacity: 0.6;
    z-index: 1;
    display: none;

    button.v-icon {
      color: white;
    }
  }

  &:hover .v-image .v-responsive__content .icons {
    display: block;

    &:hover {
      opacity: 1;
    }

    button.v-icon:hover {
      color: var(--v-green-base);
    }
  }
}
</style>
