<template>
  <j-card>
    <v-toolbar class="mb-2">
      <v-toolbar-title>
        <v-card-title class="secondary--text">
          <h2>Swap out {{ date | moment("dddd, DD MMM") }} {{ mealSubtitle }}</h2>
        </v-card-title>
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text class="std-text">
      Swap out
      <strong>{{ originalRecipe.title }}</strong> with <strong>{{ recipe.title }}</strong> for
      <j-gradient-text :gradient="['yellow', 'green']" class="text-uppercase">
        <strong>{{ meal | humanizeMeal }}</strong> </j-gradient-text
      >?
      <div class="d-flex mt-4">
        <v-img
          contain
          max-height="300"
          max-width="45%"
          :src="originalRecipe.image"
          :lazy-src="originalRecipe.image"
          class="rounded-lg"
        />
        <v-icon color="blue" class="mx-auto" large>mdi-swap-horizontal</v-icon>
        <v-img
          contain
          max-height="300"
          max-width="45%"
          :src="recipe.image"
          :lazy-src="recipe.image"
          class="rounded-lg"
        />
      </div>
    </v-card-text>
    <v-card-actions class="py-4">
      <v-spacer />
      <j-btn tertiary @click="$emit('close')">Cancel</j-btn>
      <j-btn @click="swapRecipes">Swap</j-btn>
    </v-card-actions>
  </j-card>
</template>

<script>
import { SWAP_RECIPE_MUTATION } from "@/graphql/mutations/nutritionblock";
import { decodeId } from "@/lib/string";
import IngredientsMixin from "@/mixins/nutrition/IngredientsMixin";

export default {
  name: "RecipeSwapOutConfirm",
  mixins: [IngredientsMixin],
  props: {
    recipe: {
      type: Object,
      required: true,
    },
    originalRecipe: {
      type: Object,
      required: true,
    },
    meal: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
  },
  methods: {
    swapRecipes() {
      this.$apollo
        .mutate({
          mutation: SWAP_RECIPE_MUTATION,
          variables: {
            data: {
              recipeId: decodeId(this.recipe.id),
              date: this.date,
              meal: this.meal,
            },
          },
          refetchQueries: ["recipesForWeek", "recipesForDay", "userSwappedRecipes"],
        })
        .then((result) => {
          if (result.data.upsertUserSwappedRecipe.success) {
            window.analytics.track("Recipe Swapped To", {
              recipeId: decodeId(this.recipe.id),
              recipe: this.recipe.title,
            });

            this.$emit("swapped");
          }
        });
    },
  }
};
</script>
