import gql from "graphql-tag";

export const NOTIFICATION_QUERY = gql`
  query notificationQuery {
    unreadNotificationCount
    unreadChatCount
    unreadRewardNotifications
  }
`;

export const ASSISTANT_QUERY = gql`
  query assistantQuery {
    outstandingUserTodos {
      id
      title
      code
      shortDescription
      description
      url
      youtube
      loom
      type
    }
    me {
      id
      firstName
      medicalAssessments {
        id
        assessmentDate
        valid
      }
    }

    subscription {
      id
      startDate
      active
      product {
        id
        type
        programType
        requiresIdentification
      }
      billingOrder{
        id
      }
    }

    userCheckInStatus {
      status
      bookingLink
      initial
      checkIn {
        id
        calendlyUuid
        assessmentDate
        requiredDate
      }
    }
    preferencesRequired

    nutritionSubscriptionBlocks(active: true) {
      edges {
        node {
          id
        }
      }
    }

    home {
      subscriptionBlocks {
        id
        ownWhatsappGroup
        whatsappGroupParticipants
        joinedFacebook
        joinedWhatsapp
        block {
          id
          type
          whatsappUrl
        }
      }

      upcomingSubscriptionBlocks {
        id
        ownWhatsappGroup
        whatsappGroupParticipants
        joinedFacebook
        joinedWhatsapp
        block {
          id
          type
          whatsappUrl
        }
      }
    }
  }
`;

export const ACCOUNTABILITY_GROUPS = gql`
  query accountabilityGroups {
    home {
      subscriptionBlocks {
        id
        block {
          id
          title
          type
          leadInstructor {
            id
            firstName
            userProfile {
              id
              profilePicture
            }
          }
        }
      }
      upcomingSubscriptionBlocks {
        id
        block {
          id
          title
          type
          leadInstructor {
            id
            firstName
            userProfile {
              id
              profilePicture
            }
          }
        }
      }
    }
  }
`;

export const FACEBOOK_GROUPS = gql`
  query facebookGroups {
    home {
      subscriptionBlocks {
        id
        block {
          id
          title
          type
          facebookUrl
          leadInstructor {
            id
            firstName
          }
        }
      }
      upcomingSubscriptionBlocks {
        id
        block {
          id
          title
          type
          facebookUrl
          leadInstructor {
            id
            firstName
          }
        }
      }
    }
  }
`;

export const WHATSAPP_GROUPS = gql`
  query whatsappGroups {
    home {
      subscriptionBlocks {
        id
        block {
          id
          title
          type
          whatsappUrl
          leadInstructor {
            id
            firstName
          }
        }
      }
      upcomingSubscriptionBlocks {
        id
        block {
          id
          title
          type
          whatsappUrl
          leadInstructor {
            id
            firstName
          }
        }
      }
    }
  }
`;
