<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <router-link :to="profileLink" class="text-decoration-none" :class="{ disabled: !profileLink }">
        <v-avatar
          v-bind="attrs"
          v-on="on"
          :color="avatarClass"
          :size="size"
          :class="[{ 'highlight-avatar': highlightAvatar }]"
        >
          <img v-if="profilePicture" :src="profilePicture" />
          <span v-if="!profilePicture && user" class="white--text ">
            {{ user.firstName ? user.firstName.charAt(0).toUpperCase() : "" }}
            {{ user.lastName ? user.lastName.charAt(0).toUpperCase() : "" }}
          </span>
        </v-avatar>
      </router-link>
    </template>
    <span v-if="tooltipCopy">{{ tooltipCopy }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: "UserAvatar",
  props: {
    user: {
      type: Object,
      required: false,
    },
    profilePicture: {
      type: String,
      required: false,
    },
    size: {
      type: String,
      default: "40",
    },
    highlightAvatar: {
      type: Boolean,
      default: false,
    },
    highlightColour: {
      type: String,
      default: "#211256",
    },
    tooltip: {
      type: String,
      required: false,
    },
  },
  computed: {
    profileLink() {
      if (!this.user?.id) {
        return "";
      }
      if (parseInt(this.user.id)) {
        return `/p/${btoa(`UserNode: ${this.user.id}`)}`;
      }
      return `/p/${this.user.id}`;
    },
    avatarClass() {
      if (this.highlightAvatar) {
        return this.highlightColour;
      }

      if (this.profilePicture) {
        return "white";
      }
      return "navy";
    },
    pluralSuffix() {
      return this.user?.firstName.slice(-1) == "s" ? "" : "s";
    },
    tooltipCopy() {
      if (this.tooltip) {
        return this.tooltip;
      }
      if (this.user?.firstName) {
        return `View ${this.user.firstName}'${this.pluralSuffix} Profile`;
      }
      return null;
    },
  },
};
</script>

<style scoped lang="scss">
.highlight-avatar {
  border: 3px solid;
}
.disabled {
  pointer-events: none;
}
</style>
