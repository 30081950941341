<template>
  <j-card elevation="2" class="pointer">
    <div class="d-flex justify-space-between text-left align-center">
      <div class="d-flex align-center">
        <div class="ma-2" @click="handleClick()">
          <v-avatar size="80"><v-img :src="img" /> </v-avatar>
        </div>
        <div class="ml-2 pt-1">
          <div @click="handleClick()" class="navy--text body-2 block font-weight-bold">
            {{ title.toUpperCase() }}
          </div>
          <div @click="handleClick()" class="navy--text p-text block font-weight-bold truncate-text">
            {{ subtitle }}
          </div>
          <div class="block grey-navy--text sm-text">{{ caption }}</div>

          <div v-if="blockId" class="my-2">
            <j-btn narrow :to="'/nutrition/' + decodeId(blockId) + '/overview'" x-small class="mb-2 mr-2">
              <span>Week's Menu</span>
              <v-icon x-small class="ml-2">mdi-calendar-month</v-icon>
            </j-btn>
            <j-btn tertiary narrow :to="'/nutrition/shopping-list/' + decodeId(blockId)" x-small class="mb-2">
              <span>Shopping List</span>
              <v-icon x-small class="ml-2">mdi-cart-outline</v-icon>
            </j-btn>
          </div>
        </div>
      </div>
      <div @click="handleClick()">
        <a :target="target" :href="link" class="font-weight-bold heading-6 navy--text text-decoration-none">
          <v-icon size="32" color="navy">mdi-chevron-right</v-icon>
        </a>
      </div>
    </div>
  </j-card>
</template>

<script>
import { decodeId } from "@/lib/string";
export default {
  name: "NutritionSummaryCard",
  props: {
    img: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
      default: "My Plan",
    },
    subtitle: {
      type: String,
      required: false,
    },
    caption: {
      type: String,
      required: false,
    },
    link: {
      type: String,
      required: false,
    },
    raiseEvent: {
      type: Boolean,
      default: false,
    },
    target: {
      type: String,
      required: false,
    },
    blockId: {
      type: [String, Number],
      required: false,
    },
  },
  methods: {
    decodeId(id) {
      return decodeId(id);
    },
    handleClick() {
      if (this.raiseEvent) {
        window.analytics.track(`Clicked ${this.title}`);
      }
      if (this.link && this.target == "_blank") {
        window.open(this.link, "_blank");
      } else {
        this.$router.push(this.link);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.truncate-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
