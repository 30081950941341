<template>
  <v-row justify="center" class="mb-4 mx-0">
    <v-col
      cols="12"
      :md="md"
      :lg="lg"
      :xl="xl"
      align="center"
      justify="center"
      class="j-banner-container white pa-0 pb-5 j-elevated-1 rounded-lg d-flex flex-column"
    >
      <div :class="color" :style="backgroundGradientStyle" class="py-3">
        <div class="d-flex align-center mx-3 justify-space-between">
          <div class="d-flex align-center">
            <back-link icon large :baseRoute="backLink" />
            <v-avatar v-if="headerImage" size="50" class="mr-2">
              <v-img :src="headerImage"></v-img>
            </v-avatar>
            <h1 class="secondary--text header heading-2" :class="{ 'smaller-heading': $vuetify.breakpoint.mdAndDown }">
              {{ header }}
            </h1>
          </div>

          <div class="d-flex">
            <j-btn v-if="secondaryBtn" tertiary narrow :to="secondaryBtn.to" :href="secondaryBtn.href" :target="secondaryBtn.target"  small class="mx-2 my-2">
              <span v-if="secondaryBtn.text">{{ secondaryBtn.text }}</span>
              <v-icon v-if="secondaryBtn.icon" small class="ml-1 mb-1">{{ secondaryBtn.icon }}</v-icon>
            </j-btn>
            <share-link v-if="showShareLink" isPage :share-name="useHeaderInLink ? header : ''" />
          </div>
        </div>

        <div v-if="contents" class="mx-10 mt-4 text-left">
          <v-list-item>
            <v-list-item-avatar v-if="icon" :color="accentColor" size="55">
              <v-img :src="icon" :lazy-src="icon" />
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-if="title" class="text-uppercase secondary--text heading-3">
                <strong>{{ title }}</strong>
              </v-list-item-title>

              <v-list-item-subtitle v-if="subtitle">
                <span class="gradient-text grey-navy--text d-inline heading-4" :style="textGradientStyle">{{
                  subtitle
                }}</span>
              </v-list-item-subtitle>
              <slot name="header-action-bar"></slot>
            </v-list-item-content>
          </v-list-item>
        </div>
        <slot name="header-section" />
        <v-row v-if="search" justify="center">
          <v-col cols="11" md="10" class="py-1">
            <v-text-field
              rounded
              v-model="searchString"
              background-color="white"
              height="46"
              hide-details
              placeholder="What are you looking for?"
              @input="emitSearch()"
              class="search-field"
            >
              <template v-slot:prepend-inner>
                <v-icon size="32" class="ml-n4 mr-4 mt-1">mdi-magnify</v-icon>
              </template>
              <template v-slot:append>
                <v-icon v-if="searchString" size="28" class="mr-n4 mt-1" @click="clear()">mdi-close</v-icon>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </div>
      <slot :class="slotTextAlignClass" />
    </v-col>
  </v-row>
</template>

<script>
import BannerContainerMixin from "@/mixins/BannerContainerMixin";
import ShareLink from "@/components/shared/dialogs/ShareLinkDialog";

export default {
  name: "JBannerContainer",
  mixins: [BannerContainerMixin],
  components: {
    ShareLink,
  },
  props: {
    search: {
      type: Boolean,
      default: false,
    },
    searchVal: {
      type: String,
      required: false,
      default: "",
    },
    showShareLink: {
      type: Boolean,
      default: false,
    },
    useHeaderInLink: {
      type: Boolean,
      default: false,
    },
    secondaryBtn: {
      type: Object,
      required: false,
    },
    backLink: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      keyUpSearchInterval: null,
      searchString: null,
    };
  },
  computed: {
    contents() {
      return this.icon || this.title || this.subtitle;
    },
  },
  mounted() {
    if (this.searchVal) this.searchString = this.searchVal;
  },
  methods: {
    emitSearch() {
      if (this.keyUpSearchInterval) {
        clearTimeout(this.keyUpSearchInterval);
        this.keyUpSearchInterval = null;
      }
      this.keyUpSearchInterval = setTimeout(() => {
        this.$emit("searchValueChanged", this.searchString);
      }, 1000);
    },
    clear() {
      this.searchString = null;
      this.$emit("searchValueChanged", "");
    },
  },
};
</script>

<style lang="scss" scoped>
.j-banner-container {
  .row {
    margin: 0;
  }
  .smaller-heading {
    font-size: 21px;
  }
}
</style>
