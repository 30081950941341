<template>
  <j-card rounded="lg">
    <v-toolbar class="mb-6">
      <v-toolbar-title>Manage Program</v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="$emit('closed')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <!-- <v-col cols="6"
          ><j-btn block secondary @click="navigateOverview"
            >Plan
            <v-icon small class="mt-n1 mr-n6">mdi-format-list-bulleted</v-icon>
          </j-btn></v-col 
        >
        -->
        <v-col cols="6">
          <j-btn block tertiary @click="leaveBlock" :loading="loading">
            Leave
            <v-icon small class="mt-n1 mr-n6">mdi-logout</v-icon>
          </j-btn>
        </v-col>
      </v-row>

      <!-- <div class="mb-2 mt-6">
        <h4>
          Change start date
        </h4>
        <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" min-width="290px">
          <template v-slot:activator="{ on, attrs }">
            <j-text-field
              prepend-inner-icon="mdi-calendar"
              v-model="startDate"
              readonly
              v-bind="attrs"
              v-on="on"
            />
          </template>
          <v-date-picker
            ref="picker"
            color="navy"
            v-model="startDate"
            :min="
              $moment()
                .startOf('isoweek')
                .format('YYYY-MM-DD')
            "
            :allowed-dates="allowedDates"
            @change="saveStartDate"
          ></v-date-picker>
        </v-menu>

        <j-btn block primary @click="updateBlock">Update Dates</j-btn>
      </div> -->
    </v-card-text>
  </j-card>
</template>

<script>
import { decodeId } from "@/lib/string";

export default {
  name: "ManageBlockDialog",

  data() {
    return {
      startDate: this.nutritionSubscriptionBlock.startDate,
      allowedDates: (val) => new Date(val).getUTCDay() === 1, // only Monday!
      menu: false,
    };
  },
  props: {
    nutritionSubscriptionBlock: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    saveStartDate(date) {
      this.$refs.menu.save(date);
    },
    navigateOverview() {
      this.$router.push(`/nutrition/${decodeId(this.nutritionSubscriptionBlock.nutritionBlock.id)}/overview`);
    },
    leaveBlock() {
      this.$emit("leave");
    },
    updateBlock() {
      this.$emit("saved", {
        nutritionSubscriptionBlock: this.nutritionSubscriptionBlock,
        startDate: this.startDate,
      });
    },
  },
};
</script>
