import axios from "axios";

export default {
  data: () => ({
    downloading: false,
    percentCompleted: 0,
  }),
  methods: {
    download(file, label) {
      this.downloading = true;
      let fileExtension = file.split(".").pop();
      // so that we can safely remove the Authorization header
      const customAxios = axios.create();
      delete customAxios.defaults.headers.common["Authorization"];
      this.percentCompleted = 0;

      customAxios({
        url: file,
        method: "GET",
        responseType: "blob",
        onDownloadProgress: (progressEvent) => {
          this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${label}.${fileExtension}`);
          document.body.appendChild(link);
          link.click();
          this.downloading = false;
        })
        .catch((error) => {
          console.log(error);
          this.downloading = false;
        });
    },
  },
};
