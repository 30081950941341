import moment from "moment";
import { fancy_date } from "@/lib/fancy_date";
import { capitalize } from "@/lib/string";

const filters = {
  humanizeSeconds: (value) => {
    if (!value) return "";
    let duration = moment.duration(parseInt(value), "seconds");
    let h = duration.hours();
    let m = duration.minutes();
    let s = duration.seconds();

    let timeString = "";
    if (h > 0) {
      timeString = `${h.toString()}h `;
    }

    if (m > 0) {
      timeString = `${timeString} ${m.toString()}m `;
    }

    if (s > 0) {
      timeString = `${timeString} ${s.toString()}s `;
    }
    return timeString;
  },
  humanize_yyyy_mm_dd: (value, day = true) => {
    return fancy_date(value, `${day ? "ddd, " : ""}D MMMM YYYY`, false);
  },
  titleize(str) {
    if (!str) {
      return "";
    }
    // no strings of ours should have underscores right? This sorts out "REST_DAY"
    str = str.replace("_", " ");
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  },
  currency(number) {
    if (!number) {
      return "";
    }
    number = parseFloat(number).toFixed(2);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  intl_number_format(number) {
    if (!number) {
      return 0;
    }
    number = parseFloat(number).toFixed(0);
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  },
  humanizeMeal: (meal) => {
    if (!meal) return "";
    meal = meal.toLowerCase();
    // primary_snack
    meal = meal.replace("primary_", "");
    return capitalize(meal);
  },

  replaceLinksWithHref: (text) => {
    //URLs starting with http://, https://, or ftp://
    let replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim;
    let replacedText = text.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

    //URLs starting with www. (without // before it, or it'd re-link the ones done above)
    let replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

    //Change email addresses to mailto:: links
    let replacePattern3 = /(([a-zA-Z0-9_\-.]+)@[a-zA-Z_]+?(?:\.[a-zA-Z]{2,6}))+/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    return replacedText;
  },

  replaceMentionsWithHref: (text) => {
    return text.replace(/@([a-z\d_]+)/gi, '<a href="/p/@$1">@$1</a>');
  },

  southAfricanNumberFormat: (text) => {
    if (!text) {
      return null;
    }
    text = text.replaceAll(" ", "");
    if (text.substr(0, 1) === "0") {
      text = text.replace("0", "+27");
    } else if (text.substr(0, 1) === "2") {
      text = text.replace("2", "+2");
    }
    if (text) {
      return text.replace(/^(.{3})(.{4})(.*)$/, "$1 $2 $3");
    }
    return null;
  },
};
export default filters;
