<template>
  <j-card>
    <v-card-title class="font-weight-bold justify-center">
      <j-btn x-small narrow tertiary class="mr-4" to="/recipes">
        Recipes
        <v-icon small class="ml-1">mdi-silverware-fork-knife</v-icon>
      </j-btn>
      <j-btn v-if="!hasTargets" x-small narrow tertiary to="/today/goal">
        Set my Goal
        <v-icon small class="ml-1">mdi-pencil</v-icon>
      </j-btn>
    </v-card-title>
    <v-card-text>
      <v-skeleton-loader v-if="$apollo.loading" type="list-item-avatar" />
      <div v-else>
        <j-subsection-row
          v-if="hasTargets"
          title="Goal"
          color="grey-navy"
          :see-all="{ text: 'Edit', link: '/today/goal' }"
        >
          <div>
            <div class="text-left mt-n2">
              <span v-if="weeklyGoal.targets">
                <span class="green--text font-weight-bold">{{ nutritionFocus }}</span> for
                {{ weeklyGoal.targets.weekly_nutrition }} day<span v-if="weeklyGoal.targets.weekly_nutrition != 1"
                  >s</span
                >
                this week
              </span>
            </div>
            <v-btn
              v-if="nutritiousDay"
              color="green"
              rounded
              large
              @click="DeleteNutritiousDay"
              class="thick-border white--text font-weight-bold pl-15 pr-3 mt-3 j-elevated-1"
            >
              Achieved for Today <v-icon color="white" size="23" class="px-3">mdi-check</v-icon>
            </v-btn>
            <v-btn
              v-else
              color="green"
              rounded
              large
              outlined
              @click="AddNutritiousDay"
              class="thick-border font-weight-bold pl-3 pr-1 mt-3 j-elevated-1"
            >
              Achieved for Today <v-icon color="green" large class="ml-1">mdi-circle-outline</v-icon>
            </v-btn>
          </div>
        </j-subsection-row>

        <j-subsection-row title="Log Meals" color="grey-navy" :show-see-all="false">
          <nutrition-logger :plan="nutritionPlanMacros" />
        </j-subsection-row>
        <nutrition-preference-alert class="mt-6" />

        <!----------------------------------------- -->
        <!-- The user is on an active nutrition PROGRAM (not menu i.e workout_blocks.block) -->
        <!----------------------------------------- -->
        <j-subsection-row
          v-if="activeNutritionSubscriptionBlock"
          title="Your Nutrition Program"
          color="grey-navy"
          :see-all="{ text: 'View Program', link: `/program/${decodeId(activeNutritionSubscriptionBlock.block.id)}` }"
          class="mt-5"
        >
          <workout-block-overview
            class="mt-n2"
            :show-header="false"
            :workout-block="activeNutritionSubscriptionBlock.block"
            :subscription-block="activeNutritionSubscriptionBlock"
            :startDate="activeNutritionSubscriptionBlock.startDate"
            :end-date="activeNutritionSubscriptionBlock.endDate"
            :anchor-date="selectedDate.format('YYYY-MM-DD')"
            type="NUTRITION"
          />
          <nutrition-summary-card
            :title="activeNutritionSubscriptionBlock.block.title"
            :img="activeNutritionSubscriptionBlock.block.image"
            :caption="`with ${activeNutritionSubscriptionBlock.block.leadInstructor.firstName}`"
            :link="`/program/${decodeId(activeNutritionSubscriptionBlock.block.id)}`"
            :block-id="
              activeNutritionSubscriptionBlock.block.relatedNutritionBlock
                ? activeNutritionSubscriptionBlock.block.relatedNutritionBlock.id
                : null
            "
            class="my-5"
          />

          <nutrition-summary-card
            v-if="mealPlan && mealPlan.attachment && nutritionPlanMacros"
            title="Eating Guidelines"
            :img="nutritionPlanMacros.coverImage"
            :subtitle="nutritionPlanMacros.title"
            :caption="weightLossGoalText"
            :link="mealPlan.attachment"
            target="_blank"
            raise-event
          />
        </j-subsection-row>

        <!----------------------------------------- -->
        <!-- The user is NOT on an active nutrition program, loading the menu. default one or the one chosen. -->
        <!----------------------------------------- -->
        <j-subsection-row v-else title="Menu" color="grey-navy" :show-see-all="false" class="mt-5">
          <nutrition-summary-card
            v-if="$store.getters.role === 'CLUB' && nutritionPlanMacros"
            title="Eating Guidelines"
            :img="nutritionPlanMacros.coverImage"
            :subtitle="nutritionPlanMacros.title"
            :caption="weightLossGoalText"
            :link="nutritionPlanMacros.guidePdf"
            target="_blank"
            raise-event
          />
          <j-card
            v-if="hasCoaching && nutritionPlanPdf()"
            elevation="0"
            color="background-grey-2"
            :href="nutritionPlanPdf()"
            rounded="pill"
            class="pointer py-3 mb-4 d-flex button-card v-btn"
            target="_blank"
          >
            <v-avatar size="50" class="ml-3 my-auto" color="green">
              <v-img
                :src="coachPicture || require('@/assets/images/Adrian.png')"
                :lazy-src="coachPicture || require('@/assets/images/Adrian.png')"
                height="42"
                max-width="42"
                :aspect-ratio="1 / 1"
              />
            </v-avatar>
            <div class="d-flex ml-2 text-left flex-grow-1 align-center">
              <h6 class="navy--text card-title">Your 1-on-1 Nutrition Guide</h6>
            </div>
          </j-card>
        </j-subsection-row>

        <!-- not on an active nutrition program -->
        <nutrition-summary-card
          v-if="hasClub && activeNutritionBlock && !activeNutritionSubscriptionBlock"
          title="Menu"
          :img="activeNutritionBlock.image"
          :subtitle="activeNutritionBlock.title"
          :caption="`with ${activeNutritionBlock.leadInstructor.firstName}`"
          :link="getBlockLink(activeNutritionBlock.id)"
          :block-id="activeNutritionBlock.id"
          class="my-5"
        />
        <j-card v-if="featuredNutritionEvent" class="my-2 j-elevated-5">
          <event-card
            :event="featuredNutritionEvent"
            :activityLogged="
              featuredNutritionEvent.useractivitySet.edges && featuredNutritionEvent.useractivitySet.edges.length < 1
            "
          />
        </j-card>
        <div v-if="hasClub && !activeNutritionBlock && !upcomingNutritionBlock" align="center" class="my-2">
          <j-dotted-btn wide color="green" to="/programs?type=NUTRITION">Choose Nutrition Menu</j-dotted-btn>
        </div>

        <div v-if="!hasClub" align="center" class="my-2">
          <j-dotted-btn wide color="green" to="/programs?type=NUTRITION">Choose Nutrition Menu</j-dotted-btn>
        </div>

        <nutrition-summary-card
          v-if="hasClub && upcomingNutritionBlock"
          title="Upcoming Menu"
          :img="upcomingNutritionBlock.image"
          :subtitle="upcomingNutritionBlock.title"
          :caption="`with ${upcomingNutritionBlock.leadInstructor.firstName}`"
          :link="getBlockLink(upcomingNutritionBlock.id)"
          class="my-3"
        />
      </div>

      <j-subsection-row
        v-if="activeNutritionBlock"
        title="Today's Recipes"
        color="grey-navy"
        class="mt-4"
        :see-all="{ text: 'View Full Menu', link: `/nutrition/${decodeId(activeNutritionBlock.id)}/overview` }"
      >
        <single-day-recipe-day-listing :md-cols="6" />
      </j-subsection-row>
    </v-card-text>

    <v-card-actions class="grey pa-0">
      <v-btn
        elevation="0"
        color="grey"
        class="font-weight-bold rounded-b-xl py-5 px-4 grey-navy--text flex-grow-1"
        to="/accountability/dots"
      >
        SEE ALL MY ACTIVITY
        <v-spacer />
        <v-icon color="grey-navy" class="ml-auto">mdi-chevron-right</v-icon>
      </v-btn>
    </v-card-actions>
  </j-card>
</template>

<script>
import { mapGetters } from "vuex";
import { decodeId } from "@/lib/string";
import { getMonday } from "@/lib/calendarWeek";
import { convertSATimeToLocalTime } from "@/lib/timezone";
import { SCHEDULED_EVENTS_QUERY } from "@/graphql/queries/events";
import { NUTRITION_PLAN_MACRO_QUERY } from "@/graphql/queries/nutrition";
import { WEEKLY_GOAL_QUERY } from "@/graphql/queries/goal";
import { ADD_FOCUS_DAY_MUTATION, DELETE_FOCUS_DAY_MUTATION } from "@/graphql/mutations/goal";
import { HOME_QUERY } from "@/graphql/queries/home";
import NutritionLogger from "@/components/nutritionblocks/blocks/NutritionLogger";
import EventCard from "@/components/events/EventCard";
import NutritionSummaryCard from "@/components/nutritionblocks/cards/NutritionSummaryCard";
import NutritionPreferenceAlert from "@/components/nutritionblocks/NutritionPreferenceAlert";
import UserMixin from "@/mixins/UserMixin";
import WorkoutBlockOverview from "@/components/workoutblocks/cards/WorkoutBlockOverview";
import SingleDayRecipeDayListing from "@/components/nutritionblocks/SingleDayRecipeDayListing";

export default {
  name: "TodayNutrition",
  mixins: [UserMixin],
  components: {
    NutritionLogger,
    EventCard,
    NutritionSummaryCard,
    NutritionPreferenceAlert,
    WorkoutBlockOverview,
    SingleDayRecipeDayListing,
  },
  data() {
    return {
      upcomingPrograms: [],
      weightLossGoal: null,
      activeNutritionSubscriptionBlock: null,
    };
  },
  filters: {
    smartPossessionSuffix(name) {
      if (name[name.length - 1] == "s") {
        return `${name}'`;
      }
      return `${name}'s`;
    },
  },
  computed: {
    ...mapGetters(["selectedDate", "activeNutritionBlock", "upcomingNutritionBlock"]),
    mealPlan() {
      return this.activeNutritionSubscriptionBlock?.block?.blockattachmentSet?.find((obj) => {
        return obj.code?.toLowerCase() === this.$store.getters.user?.userProfile?.calculatedServingSize?.toLowerCase();
      });
    },
    hasTargets() {
      return !(this.weeklyGoal?.targets?.weekly_nutrition === 0 || this.weeklyGoal?.targets?.weekly_nutrition === null);
    },
    monday() {
      let date = this.selectedDate
        ? this.$moment(this.selectedDate).format("YYYY-MM-DD")
        : this.$moment().format("YYYY-MM-DD");
      return this.$moment(getMonday(date)).format("YYYY-MM-DD");
    },
    pastWeek() {
      return this.monday < this.$moment(getMonday(this.$moment())).format("YYYY-MM-DD");
    },
    nutritiousDay() {
      let today = this.selectedDate
        ? this.$moment(this.selectedDate).format("YYYY-MM-DD")
        : this.$moment().format("YYYY-MM-DD");
      return this.weeklyGoal?.nutrition_breakdown[today];
    },
    nutritionFocus() {
      return this.weeklyGoal?.targets?.focus?.title ?? "My own focus set";
    },
    nutritionProgramLink() {
      if (this.activeNutritionBlock) {
        return "/your-programs";
      }
      return "/programs?type=NUTRITION";
    },
    coachPicture() {
      return this.coach?.userProfile?.profilePicture;
    },
    featuredNutritionEvent() {
      // given that this query is not a proper relay filter, we have to hack and get the required event here using JS
      return this.events?.find((element) => element.eventType?.name === "Nutrition" && element.featuredEvent);
    },
    // careful, duplicated in WorkoutBlock.vue
    weightLossGoalText() {
      let values = {
        fat_loss: "for Weight Loss",
        maintenance: "to Maintain Weight",
        toning: "for Toning",
        weight_gain: "for Gaining Weight",
      };
      return this.weightLossGoal in values ? values[this.weightLossGoal] : "";
    },
  },
  methods: {
    decodeId(id) {
      return decodeId(id);
    },
    getBlockLink(id) {
      return `/nutrition/${decodeId(id)}`;
    },
    navigateNutritionBlock(id) {
      this.$router.push(`/nutrition/${decodeId(id)}`);
    },
    AddNutritiousDay() {
      this.$apollo.mutate({
        mutation: ADD_FOCUS_DAY_MUTATION,
        variables: {
          date: this.selectedDate.format("YYYY-MM-DD"),
        },
        refetchQueries: ["weeklyGoal", "accountQuery"],
      });
    },
    DeleteNutritiousDay() {
      this.$apollo.mutate({
        mutation: DELETE_FOCUS_DAY_MUTATION,
        variables: {
          date: this.selectedDate.format("YYYY-MM-DD"),
        },
        refetchQueries: ["weeklyGoal", "accountQuery"],
      });
    },
  },
  apollo: {
    events: {
      query: SCHEDULED_EVENTS_QUERY,
      variables() {
        return {
          scheduledDate: this.selectedDate
            ? this.selectedDate.format("YYYY-MM-DD")
            : this.$moment().format("YYYY-MM-DD"),
          eventTypeName: ["Nutrition"],
          featuredEvent: true,
        };
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        let dateFormatted = data.events.map((event) => {
          event.startTime = convertSATimeToLocalTime(event.startTime);
          event.endTime = convertSATimeToLocalTime(event.endTime);
          return event;
        });
        return dateFormatted;
      },
    },
    nutritionPlanMacros: {
      query: NUTRITION_PLAN_MACRO_QUERY,
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data && !response.loading) {
          this.weightLossGoal = response.data.me.userProfile.weightLossGoal;
        }
      },
    },
    weeklyGoal: {
      query: WEEKLY_GOAL_QUERY,
      variables() {
        return {
          weekStart: this.monday,
        };
      },
      update(data) {
        return data.weeklyGoals;
      },
    },
    home: {
      query: HOME_QUERY,
      variables() {
        return {
          withGroups: true,
          withGroupUsers: false,
          withUsers: true,
        };
      },
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data && !response.loading) {
          let data = response.data.home;
          if (data.subscriptionBlocks) {
            this.activeNutritionSubscriptionBlock =
              data.subscriptionBlocks.find(
                (element) =>
                  element.block.type === "NUTRITION" &&
                  (element.endDate == null || this.$moment(element.endDate).isAfter(this.selectedDate))
              ) ?? null;
          }
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.button-card.v-btn {
  text-transform: none;
  letter-spacing: unset;
}

.v-btn.thick-border {
  border-width: 2px;
}
</style>
