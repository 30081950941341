<template>
  <j-card v-if="message" :class="getMessageCardClass(message)" class="message-width j-elevated-4 mb-8">
    <v-card-text class="pb-0">
      <p
        v-if="!sentBySelf(message)"
        @dblclick.stop="replyToMessage(message)"
        class="font-weight-bold mb-1 text-left"
      >
        <span v-if="message.user">
          <user-avatar :user="message.user" :profile-picture="message.user.userProfile.profilePicture" size="35" />
          <span class="ml-2">{{ message.user.firstName }} {{ message.user.lastName }}</span>
        </span>
        <span v-else>{{ $appName }} Support Team</span>
      </p>

      <v-chip v-if="isScheduled(message)" color="mindset" small class="mb-2">
        <span class="font-weight-bold sm-text"
          >Scheduled to send {{ message.scheduledDate | moment("ddd, D MMM hh:mm A") }}</span
        >
      </v-chip>

      <message-reply-card
        v-if="message.parent"
        :message="message"
        :parent="message.parent"
        :thread="true"
        class="mb-4 ml-n2"
      />

      <p
        v-if="message.text"
        @dblclick.stop="replyToMessage(message)"
        class="text-left mb-0"
        v-html="formatMessage(message.text)"
      ></p>
      <div
        v-if="messageReactions && messageReactions.length > 0"
        :class="getReactionBarClass(message)"
        class="mx-2 reaction-bar"
      >
        <v-menu
          v-for="(messageReaction, index) in messageReactions"
          :key="`message_${message.id}_reaction_${index}`"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-avatar class="ml-1" color="#eee" size="40" v-bind="attrs" v-on="on">
              {{ messageReaction.reaction }}
              <span class="reaction-count">{{ messageReaction.users.length }}</span>
            </v-avatar>
          </template>
          <div class="pa-2 sm-text" style="background-color:white;">
            {{ getReactionUserList(messageReaction.users) }}
          </div>
        </v-menu>
      </div>
    </v-card-text>
    <div v-if="message.messageattachmentSet.length > 0" class="pa">
      <div v-if="getImages(message.messageattachmentSet).length > 0" class="images-wrapper pa-2">
        <v-row>
          <v-col
            v-for="(image, imageIndex) in getImageGallery(message.messageattachmentSet)"
            :key="imageIndex"
            class="d-flex child-flex pb-0 justify-center"
            :cols="imageGalleryCols"
          >
            <v-img
              :src="image"
              :lazy-src="image"
              contain
              class="rounded-lg pointer image-size"
              @click="openImage(image)"
            />
          </v-col>
        </v-row>
      </div>
      <div v-if="getVideos(message.messageattachmentSet).length > 0" class="pa-2">
        <div v-for="(video, videoIndex) in getVideos(message.messageattachmentSet)" :key="'video-' + videoIndex">
          <vue-plyr>
            <video v-if="video.file" controls crossorigin playsinline>
              <source size="500" :src="video.file" type="video/mp4" />
            </video>
            <cld-video
              v-else-if="video.remoteFile"
              cloud-name="jeff-fitness"
              :public-id="video.remoteFile"
              controls
              crossorigin
              playsinline
              start_offset="0"
            >
              <cld-transformation video_codec="auto" width="500" crop="pad" />
            </cld-video>
          </vue-plyr>
        </div>
      </div>
      <div v-if="getAudioFiles(message.messageattachmentSet).length > 0" class="audio-ply">
        <div v-for="(audio, audioIndex) in getAudioFiles(message.messageattachmentSet)" :key="'audio-' + audioIndex">
          <div class="d-flex justify-end mt-2 pl-1">
            <vue-plyr
              :options="{
                volume: 1,
                controls: [
                  'play-large',
                  'play',
                  'progress',
                  'current-time',
                  'captions',
                  'settings',
                  'pip',
                  'airplay',
                  'fullscreen',
                ],
              }"
            >
              <audio controls crossorigin playsinline>
                <source v-if="audio.file" :src="audio.file" type="audio/mp3" />
                <source v-else-if="audio.remoteFile" :src="cloudinaryAudioFile(audio.remoteFile)" type="audio/mp3" />
              </audio>
            </vue-plyr>

            <v-avatar v-if="message.user && message.user.userProfile.profilePicture" size="50" class="ml-2 mr-2">
              <v-img
                :src="message.user.userProfile.profilePicture"
                :lazy-src="message.user.userProfile.profilePicture"
              />
            </v-avatar>
          </div>
        </div>
      </div>
      <div v-if="getPdfs(message.messageattachmentSet).length > 0" class="text-left pa-2">
        <div v-for="(pdf, pdfIndex) in getPdfs(message.messageattachmentSet)" :key="'pdf-' + pdfIndex">
          <a :href="pdf.file" target="_blank" class="text-decoration-none"
            ><v-icon color="red" x-large>mdi-file-pdf-box</v-icon></a
          >
        </div>
      </div>
      <div v-if="getGifs(message.messageattachmentSet).length > 0" class="text-left pa-2">
        <div v-for="(gif, gifIndex) in getGifs(message.messageattachmentSet)" :key="'gif-' + gifIndex">
          <v-img :src="gif.remoteFile" :lazy-src="gif.remoteFile" contain class="rounded-lg gif-size" />
        </div>
      </div>
    </div>
    <div class="pl-4 pr-2 pt-1 text-right sm-text grey--text text--darken-3">
      <span v-if="message.sentDate" :class="{'mr-2': hideOptions}">{{ formatChatDate(message.sentDate) }}</span>
      <span v-if="!hideOptions">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" class="mb-1 grey--text text--darken-3">
              mdi-dots-vertical
            </v-icon>
          </template>

          <v-list>
            <v-list-item v-if="!isScheduled(message)" class="p-text pointer" @click="replyToMessage(message)"
              ><v-icon class="pr-2 mt-n1">mdi-reply</v-icon> Reply</v-list-item
            >
            <v-list-item
              v-if="sentBySelf(message) || $store.getters.user.isStaff"
              class="p-text pointer"
              @click="confirmDeleteDialog = true"
              ><v-icon class="pr-2 mt-n1">mdi-trash-can-outline</v-icon> Delete</v-list-item
            >
          </v-list>
        </v-menu>
        <div class="relative d-inline">
          <v-icon @click="$emit('choose-reaction', message)" class="mb-1 grey--text text--darken-3 v-ripple"
            >mdi-emoticon-outline</v-icon
          >
        </div>
      </span>
    </div>

    <!-- view image dialog -->
    <v-dialog
      v-if="selectedImage"
      v-model="openImageDialog"
      max-width="800"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <j-card rounded="lg">
        <v-card-text>
          <div class="d-flex justify-end">
            <v-btn icon @click="openImageDialog = false" class="mr-n6">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-img class="rounded-lg" :src="selectedImage" />
        </v-card-text>
      </j-card>
    </v-dialog>

    <!-- confirmDelete dialog -->
    <v-dialog
      v-model="confirmDeleteDialog"
      max-width="400"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <j-card rounded="lg">
        <v-card-text>
          <div class="d-flex justify-end">
            <v-btn icon @click="confirmDeleteDialog = false" class="mr-n6">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <p>
            Are you sure you want to delete
            <span v-if="message.text" class="font-weight-bold" v-html="truncate(formatMessage(message.text), 50)" />
            <span v-else>this message</span>?
          </p>
        </v-card-text>
        <v-card-actions class="justify-center mt-n6 pb-4">
          <div class="d-flex justify-space-between flex-wrap">
            <j-btn class="mr-2 mb-4" @click="confirmDeleteDialog = false" tertiary>Close</j-btn>
            <j-btn class="ml-2 mb-4" @click="deleteMessage(message)">Delete Message</j-btn>
          </div>
        </v-card-actions>
      </j-card>
    </v-dialog>
  </j-card>
</template>

<script>
import { CloudinaryCore } from "@/lib/cloudinary";
import MessageReplyCard from "@/components/chat/MessageReplyCard";
import ChatMixin from "@/mixins/ChatMixin";
import UserAvatar from "@/components/shared/user/UserAvatar";

export default {
  name: "ChatMessageView",
  mixins: [ChatMixin],
  components: {
    MessageReplyCard,
    UserAvatar,
  },
  data() {
    return {
      confirmDeleteDialog: false,
      search: "",
    };
  },
  props: {
    message: {
      type: Object,
    },
    hideOptions: {
      type: Boolean,
      default:false
    }
  },
  computed: {
    imageGalleryCols() {
      if (this.message?.messageattachmentSet?.length == 0) {
        return 12;
      }
      const numImages = this.getImages(this.message.messageattachmentSet).length;
      if (numImages == 1) {
        return 12;
      }
      if (numImages % 3 == 0) {
        return 4;
      }
      return 6;
    },
    messageReactions() {
      if (!this.message || !this.message.messagereactionSet || this.message.messagereactionSet.length === 0) return [];
      let reactions = [];
      let msgReactions = this.message.messagereactionSet;
      msgReactions.forEach((element) => {
        let existingId = reactions.findIndex((r) => r.reaction == element.reaction);
        if (existingId > -1) {
          reactions[existingId]["users"].push(element.user);
        } else {
          reactions.push({
            reaction: element.reaction,
            users: [element.user],
          });
        }
      });

      return reactions;
    },
  },
  methods: {
    cloudinaryAudioFile(publicId) {
      const url = CloudinaryCore.video_url(publicId, { bit_rate: "50k" });
      return `${url}.mp3`;
    },
    replyToMessage(message) {
      this.$emit("reply", message);
    },
    deleteMessage(message) {
      this.$emit("delete", message);
    },
    secondsToTime(seconds) {
      return this.$moment.utc(seconds * 1000).format("mm:ss");
    },

    getMessageCardClass(message) {
      if (this.isScheduled(message)) {
        return "message-scheduled";
      }
      let sentBySelf = this.sentBySelf(message);
      return sentBySelf ? "message-sent-by-self" : "message-sent-by-other";
    },
    getReactionBarClass(message) {
      let sentBySelf = this.sentBySelf(message);
      return sentBySelf ? "right text-right" : "left text-left";
    },
    getReactionUserList(users) {
      return users.map((element) => element.firstName + " " + element.lastName).join(", ");
    },
  },
};
</script>

<style lang="scss" scoped>
.audio-ply >>> .plyr__controls {
  border-radius: 10px;
  background-color: rgb(241, 241, 241);
}
.message-sent-by-self {
  background-color: #d9fdd3;
}
.message-sent-by-other {
  background-color: white;
}
.message-scheduled {
  background-color: var(--v-background-grey-base);
}
.image-size {
  max-height: 350px;
  max-width: 300px;
}
.gif-size {
  max-height: 250px;
  max-width: 200px;
}
.message-width {
  max-width: 90%;
  min-width: 30%;
}
.reaction-bar {
  position: absolute;
  bottom: -30px;
  width: 100%;
}
.reaction-bar.right {
  right: 10px;
}
.reaction-bar.left {
  left: 10px;
}
.reaction-count {
  font-weight: bold;
  font-size: 11px;
  margin-top: 3px;
  margin-left: 2px;
}
</style>
