<template>
  <div>
    <file-upload :value="files" ref="upload" @input-filter="inputFilter" @input="updatedValue">
      <v-avatar class="pointer" color="rgb(0, 0, 0, 0.1)" size="40">
        <v-icon> attach_file</v-icon>
      </v-avatar>
    </file-upload>
  </div>
</template>

<script>
export default {
  name: "FileUploader",
  data() {
    return {
      files: [],
    };
  },
  methods: {
    updatedValue(files) {
      this.$emit("sendFile", files);
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(jpeg|jpe|jpg|gif|png|webp|mp4|mov|wmv|webm|wav|mp3|pdf)$/i.test(newFile.name)) {
          this.$emit("error", "Invalid file format. Please upload only images, audio, video or pdf.");
          return prevent();
        }
      }
    },
  },
};
</script>
