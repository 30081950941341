<template>
  <j-card elevation="3" style="max-width: 550px;">
    <div class="d-flex justify-space-between align-center pr-4">
      <div class="d-flex align-center">
        <v-img class="rounded-l-xl mr-4" max-width="60" max-height="60" :src="corporateChallenge.logo" />
        <div class="text-left">
          <span class="grey-navy--text sm-text">Welcome to</span><br />
          <h3 class="navy--text std-text font-weight-bold" style="line-height: 15px;">{{ corporateChallenge.title }}</h3>
        </div>
      </div>
      <div class="d-flex">
        <j-btn v-if="corporateChallenge.primaryGroup" @click="goToGroup(corporateChallenge.primaryGroup.id)" secondary superNarrow class="mt-n1 text-caption mr-2">
          Group
          <v-icon class="mt-n1" size="20">mdi-account-group</v-icon>
        </j-btn>
        <j-btn v-if="corporateChallenge.article" @click="goToArticle(corporateChallenge.article.slug)" tertiary superNarrow class="mt-n1 text-caption">
          Info
          <v-icon size="20">mdi-flare</v-icon>
        </j-btn>
      </div>
    </div>
  </j-card>
</template>

<script>
import { decodeId } from "@/lib/string";
export default {
  name: "CorporateSummaryCard",
  props: {
    corporateChallenge: {
      type: Object,
      required: true,
    },
  },
  methods: {
    goToGroup(id) {
      this.$router.push(`/group/${decodeId(id)}`);
    },
    goToArticle(slug) {
      this.$router.push(`/article/${slug}`);
    },
  },
};
</script>
