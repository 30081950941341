import gql from "graphql-tag";

export const ACTIVE_PRIZE_QUERY = gql`
  query activePrizeQuery($date: Date!) {
    activePrize(startDate: $date) {
      id
      startDate
      endDate
      entryCopy
      entryIcon
      drawCopy
      drawIcon
      rewardsCopy
      rewardsIcon
      url
      entryPerSelfie
      entryPerSelfiePrizeText
      runAction
    }
  }
`;

export const PRIZE_ELIGIBILITY_QUERY = gql`
  query prizeEligibilityQuery($date: Date!, $userId: ID) {
    userPrizeWeeklyEligibility(anchorDate: $date, userId: $userId) {
      qualifiedForPrize
      newInbox
      dayBreakdown {
        date
        isRestDay
        workoutImage
        loggedWorkout
        loggedNutrition
        loggedNutritionNoPic
        greenDay
      }
    }
  }
`;

export const PRIZE_DAY_ELIGIBILITY_QUERY = gql`
  query prizeEligibilityDayQuery($date: Date, $userId: ID!) {
    userPrizeDayEligibility(date: $date, userId: $userId) {
      date
      isRestDay
      workoutImage
      loggedWorkout
      loggedNutrition
      greenDay
    }
  }
`;
