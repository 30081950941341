<template>
  <div>
    <div class="mx-4">
      <p class="std-text">
        Hey,
        <strong class="font-weight-bold blue--text">{{ user.firstName }}</strong
        >! You want to <strong class="font-weight-bold blue--text">{{ primaryGoal }}</strong
        >, at your <strong class="font-weight-bold blue--text">{{ fitnessLevel | titleize }}</strong> fitness level,
        with a weight goal to
        <strong class="font-weight-bold blue--text">{{ goal }} {{ amount | formatAmount }}kg</strong>
      </p>
      <p class="std-text">
        Start now by choosing one of the below
        <strong class="font-weight-bold pink--text">recommended programs</strong>
      </p>
    </div>

    <div class="smallest-height">
      <v-skeleton-loader v-if="$apollo.loading" type="article" />
      <recommendation-timeline v-else @block-selected="$emit('block-selected', $event)" />
    </div>
  </div>
</template>

<script>
import JourneyDetailsMixin from "@/mixins/JourneyDetailsMixin";
import RecommendationTimeline from "@/components/shared/sections/RecommendationTimeline";

export default {
  name: "RecommendationJourney",
  mixins: [JourneyDetailsMixin],
  components: {
    RecommendationTimeline,
  },
  props: {
    joinClub: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.smallest-height {
  min-height: 150px;
}
</style>
