var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"pb-4 mx-5"},[(_vm.checkinBookingRequired)?[_c('todo-listing-card',{attrs:{"todo":{
        title: 'Book check-in with your Coach',
        url: _vm.checkinBookingLink ? _vm.checkinBookingLink : '/today/to-do/checkin',
      }}})]:_vm._e(),(_vm.checkinRequired)?[_c('todo-listing-card',{attrs:{"todo":{
        title: 'Add check-in to track progress',
        url: _vm.checkinLink,
      }}})]:_vm._e(),(_vm.outstandingUserTodos.length > 0)?_vm._l((_vm.outstandingUserTodos),function(todo){return _c('todo-listing-card',{key:todo.id,attrs:{"todo":todo}})}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }