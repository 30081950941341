<template>
  <div class="pb-6">
    <j-card-content
      :summaryInfo="summaryInfo"
      :description="isCurrent ? '' : nutritionBlock.description"
      accentColor="green"
      :leftButton="isMine && !nutritionBlock.isDefault ? leftButtonText : null"
      :leftButtonIcon="isMine && !nutritionBlock.isDefault ? 'mdi-format-list-bulleted' : null"
      @leftButtonClicked="clickLeftButton()"
      :primaryButton="primaryButtonText"
      :primaryButtonLoading="loading"
      @primaryButtonClicked="$emit('doProgram')"
    />

    <div v-if="nutritionBlock.isDefault && isCurrent" class="px-4 pt-4 p-text">
      This is our standard default Nutrition Menu. You can also
      <router-link to="/programs?type=NUTRITION" class="text-decoration-underline">
        choose any other specialist Nutrition Menu </router-link
      >.
    </div>
    <j-alert
      v-if="
        nutritionSubscriptionBlock &&
          nutritionSubscriptionBlock.endDate &&
          $moment($moment()).isSameOrAfter($moment(nutritionSubscriptionBlock.endDate))
      "
      class="mt-6 mx-4"
    >
      Your program ended <span v-if="nutritionSubscriptionBlock.endDate">on {{ nutritionSubscriptionBlock.endDate | moment("ddd, D MMM 'YY") }} </span><br />
      <j-btn secondary narrow @click="restartProgram()" class="d-block mt-2">
        Restart Program
      </j-btn>
    </j-alert>
    <div
      v-if="
        nutritionSubscriptionBlock &&
          $moment(nutritionSubscriptionBlock.startDate).isSameOrAfter($moment()) &&
          !isCurrent
      "
      class="ma-4 mt-6"
    >
      <j-alert>
        Your program starts on
        {{ nutritionSubscriptionBlock.startDate | moment("ddd, D MMM 'YY") }}
      </j-alert>
    </div>
  </div>
</template>

<script>
import JCardContent from "@/layouts/sections/JCardContent";

export default {
  name: "BlockHeader",
  props: {
    nutritionBlock: {
      type: Object,
      required: true,
    },
    nutritionSubscriptionBlock: {
      type: Object,
      required: false,
    },
    isCurrent: {
      type: Boolean,
      default: false,
    },
    isMine: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    JCardContent,
  },
  data() {
    return {
      summaryInfo: [],
      leftButton: "",
      rightButton: "",
    };
  },
  created() {
    if (this.nutritionBlock.activeUsers > 0) {
      this.summaryInfo.push({ title: "Active", value: this.nutritionBlock.activeUsers });
    }

    if (this.nutritionBlock.completedUsers > 0) {
      this.summaryInfo.push({ title: "Completed", value: this.nutritionBlock.completedUsers });
    }
  },
  computed: {
    info() {
      return [
        {
          icon: "mdi-calendar-month",
          text: `Starting ${
            this.$moment(this.nutritionBlock.startDate).isSameOrAfter(this.$moment())
              ? this.$moment(this.nutritionBlock.startDate).format("ddd, D MMM 'YY")
              : "every Monday"
          }`,
        },
        {
          icon: "mdi-tag-outline",
          text: `<span class="text-decoration-line-through">R 1,250</span> ${this.getPrice(
            this.nutritionBlock.price
          )} with <strong>Club</strong>`,
        },
        {
          icon: "mdi-clock-outline",
          text: `${this.nutritionBlock.duration} days`,
        },
      ];
    },
    instructor() {
      let instructor = this.nutritionBlock.leadInstructor;
      return `${instructor.firstName} ${instructor.lastName}`;
    },
    leftButtonText() {
      return this.isMine ? "Manage" : "Plan";
    },
    primaryButtonText() {
      if (this.isCurrent || this.isMine) return null;

      const role = this.$store.getters.role;
      if (role === "ONE_ON_ONE" || role === "CLUB") {
        // if on club, then we can go ahead and do this.
        return "Make this my menu";
      }
      return "Join JEFF CLUB";
    },
  },
  methods: {
    getPrice() {
      return this.nutritionBlock.price > 0 ? "R" + this.nutritionBlock.price : "FREE";
    },
    clickLeftButton() {
      this.$emit("manage");
    },
    restartProgram() {
      this.$emit("restartProgram");
    },
  },
};
</script>
