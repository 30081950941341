import gql from "graphql-tag";

export const UPSERT_GROUP_MUTATION = gql`
mutation upsertGroup($data: GroupInput!){
  upsertGroup(data: $data){
    error
    success
  }
}`

export const JOIN_GROUP_MUTATION = gql`
mutation joinGroup($groupId: ID!){
  joinGroup(groupId: $groupId){
    error
    created
  }
}`

export const LEAVE_GROUP_MUTATION = gql`
mutation leaveGroup($groupId: ID!){
  leaveGroup(groupId: $groupId){
    error
    updated
  }
}`


export const UPSERT_POST_MUTATION = gql`
mutation upsertPost($data: PostInput!){
  upsertPost(data: $data){
    error
    success
  }
}`

export const LIKE_POST_MUTATION = gql`
mutation likePost($postId: ID!) {
  likePost(postId: $postId){
    error
    success
    id
  }
}`

export const DELETE_LIKE_MUTATION = gql`
mutation deleteLike($id: ID!) {
  deleteLike(id: $id){
    error
    success
  }
}`

export const DELETE_POST_MUTATION = gql`
mutation deletePost($id: ID!) {
  deletePost(id: $id){
    error
    success
  }
}`


export const CREATE_COMMENT_MUTATION = gql`
mutation createComment($postId: ID!, $text: String!, $parentCommentId: ID) {
  createComment(postId: $postId, text: $text, parentCommentId: $parentCommentId){
    error
    success
  }
}`

export const DELETE_COMMENT_MUTATION = gql`
mutation deleteComment($id: ID!) {
  deleteComment(id: $id){
    error
    success
  }
}`

export const CREATE_MODERATION_MUTATION = gql`
mutation createModeration($id: ID!, $type: String!) {
  createModeration(id: $id, type: $type){
    error
    success
  }
}`

export const PIN_POST_TO_GROUP_MUTATION = gql`
mutation pinPostToGroup($groupId: ID!, $postId: ID!) {
  pinPostToGroup(groupId: $groupId, postId: $postId){
    error
    success
  }
}`

export const REMOVE_PINNED_POST_MUTATION = gql`
mutation removePinnedPost($groupId: ID!, $postId: ID!) {
  removePinnedPost(groupId: $groupId, postId: $postId){
    error
    success
  }
}`