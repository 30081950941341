<template>
  <v-container>
    <div v-if="!leadCampaign"></div>
    <v-row v-else justify="center">
      <v-col cols="12" md="6">
        <j-card>
          <div v-if="leadCampaign.bannerImage">
            <img :src="leadCampaign.bannerImage" style="height: 150px; width: 100%; object-fit: cover" />
          </div>
          <v-card-title class="pb-0 mb-1 justify-center">
            <h2 class="font-weight-bold mb-4 mt-4 appblue--text">{{ leadCampaign.title }}</h2>
          </v-card-title>
          <v-card-text>
            <div class="px-3">
              <p class="text-center mt-n2 mb-6">{{ leadCampaign.description }}</p>
              <ValidationObserver ref="observer">
                <v-form @submit.prevent="submit">
                  <j-text-field
                    v-model="totallyRealFields.name"
                    aria-hidden="true"
                    class="totally_real_input"
                  ></j-text-field>

                  <j-text-field
                    v-model="totallyRealFields.surname"
                    aria-hidden="true"
                    class="totally_real_input"
                  ></j-text-field>

                  <v-row no-gutters>
                    <v-col cols="6">
                      <ValidationProvider id="firstName" v-slot="{ errors }" name="first name" rules="required">
                        <j-text-field
                          v-model.trim="data.firstName"
                          label="First name"
                          outlined
                          :error-messages="errors"
                          @keydown.space.prevent
                          class="mr-4"
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="6">
                      <ValidationProvider id="lastName" v-slot="{ errors }" name="last name" rules="required">
                        <j-text-field
                          v-model.trim="data.lastName"
                          label="Last name"
                          outlined
                          :error-messages="errors"
                          @keydown.space.prevent
                        />
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12">
                      <ValidationProvider id="email" v-slot="{ errors }" name="email" rules="required|email">
                        <j-text-field
                          v-model.trim="data.email"
                          label="Email"
                          outlined
                          :error-messages="errors"
                          @keydown.space.prevent
                        />
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12">
                      <ValidationProvider id="phone" v-slot="{ errors }" name="phone" rules="required">
                        <j-text-field
                          v-model.trim="data.phone"
                          label="Phone Number"
                          outlined
                          :error-messages="errors"
                          @keydown.space.prevent
                        />
                      </ValidationProvider>
                    </v-col>
                  </v-row>

                  <v-row justify="center">
                    <j-alert v-if="errorMessage">
                      {{ errorMessage }}
                    </j-alert>
                    <j-btn type="submit" :loading="buttonLoading" width="100%" class="mt-n2 mb-8">Submit</j-btn>
                  </v-row>
                </v-form>
              </ValidationObserver>
            </div>
          </v-card-text>
        </j-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { LEAD_CAMPAIGN } from "@/graphql/queries/settings";
import UserMixin from "@/mixins/UserMixin";
import { UPSERT_DEAL_MUTATION } from "@/graphql/mutations/account";
import { SEND_LEAD_CAMPAIGN_EMAIL_MUTATION } from "@/graphql/mutations/settings";

export default {
  name: "LeadCapture",
  mixins: [UserMixin],
  data() {
    return {
      data: {
        firstName: this.$store.getters.user?.firstName,
        lastName: this.$store.getters.user?.lastName,
        email: this.$store.getters.user?.email,
        phone: this.$store.getters.user?.userProfile?.phone,
      },
      errors: null,
      buttonLoading: false,
      totallyRealFields: {
        name: null,
        surname: null,
      },
      errorMessage: null,
    };
  },
  props: {
    leadCampaignId: {
      type: String,
      required: true,
    },
  },
  methods: {
    async toThankYou() {
      await this.$apollo.mutate({
        mutation: SEND_LEAD_CAMPAIGN_EMAIL_MUTATION,
        variables: {
          leadCampaignId: this.leadCampaignId,
          email: this.data.email,
          firstName: this.data.firstName,
        },
      });
      this.$router.push(`/campaign-success/${this.data.email}`);
    },
    async submit() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;

      if (valid) {
        this.buttonLoading = true;

        if (this.totallyRealFields.name || this.totallyRealFields.surname) {
          this.toThankYou();
          return;
        }

        if (this.hasActiveSub) {
          this.toThankYou();
          return;
        } else {
          this.data["note"] = `Created from lead capture form for campaign ${this.leadCampaignId}`;
          this.data["dealTypeCode"] = "LEADCAPTURE";

          const { data } = await this.$apollo.mutate({
            mutation: UPSERT_DEAL_MUTATION,
            variables: {
              data: this.data,
            },
          });

          if (data.upsertDealMutation.success) {
            // redirect to thank you page
            this.toThankYou();
            this.loading = false;
            return;
          }
        }

        this.errorMessage = "There was a problem. Please try again.";
        this.loading = false;
      }
    },
  },
  apollo: {
    leadCampaign: {
      query: LEAD_CAMPAIGN,
      variables() {
        return {
          id: this.leadCampaignId,
        };
      },
    },
  },
};
</script>
