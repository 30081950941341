<template>
  <j-card rounded="pill" class="ma-1" :elevation="highlight ? 2 : 0" :outlined="highlight">
    <div class="d-flex justify-space-between align-center py-2 px-2 px-md-4">
      <div class="d-flex align-center">
        <div v-if="position" class="d-flex align-center">
          <v-icon :color="positionColor" size="40">mdi-decagram</v-icon>
          <span
            class="badge-position font-weight-bold heading-2 mr-n2 mr-md-0"
            :class="[{ 'small-text': position > 9 }]"
            >{{ position }}</span
          >
        </div>
        <v-avatar v-if="image" class="mr-1 mr-md-3"><v-img :src="image" :lazy-src="image"/></v-avatar>
        <v-avatar v-if="icon"><v-icon size="30" class="mb-1 mr 2">{{icon}}</v-icon></v-avatar>
        <span
          class="std-text text-left navy--text"
          :class="[{ 'font-weight-bold': highlight }, { 'text-body-1': $vuetify.breakpoint.smAndDown }]"
        >
          {{ title }}
        </span>
      </div>

      <div class="d-flex align-center">
        <v-icon :color="dotColor" size="30">mdi-circle</v-icon>
        <div
          class="medium-text navy--text ml-1 ml-md-2 mr-2"
          :class="[{ 'font-weight-bold': highlight }, { 'large-text': $vuetify.breakpoint.mdAndUp }]"
        >
          {{ dots | intl_number_format }}
        </div>
      </div>
    </div>
  </j-card>
</template>

<script>
export default {
  name: "LeaderboardEntry",
  props: {
    title: {
      type: String,
      required: true,
    },
    position: {
      type: [Number, Boolean],
      required: false,
    },
    dots: {
      type: Number,
      default: 0,
    },
    dotColor: {
      type: String,
      default: "exercise", // exercise, nutrition or mindset
    },
    image: {
      type: String,
      required: true
    },
    highlight: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    positionColor() {
      switch (this.position) {
        case 1:
          return "yellow";
        case 2:
          return "grey darken-2";
        case 3:
          return "brown lighten-2";
        default:
          return "navy";
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.badge-position {
  position: relative;
  right: 27px;
  color: white;
}
.small-text {
  font-size: 18px !important;
  right: 30px;
}
.medium-text {
  font-size: 24px;
}
.large-text {
  font-size: 28px;
}
</style>