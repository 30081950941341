<template>
  <div>
    <div v-if="!loading" >
      <div class="d-flex justify-space-between flex-wrap mb-4">
      <j-btn @click="$emit('back')" tertiary narrow class="mt-n1"><v-icon color="navy">mdi-arrow-left</v-icon> Back</j-btn>

      <j-btn @click="$emit('saved', data)" primary narrow 
        >Next Step <v-icon color="white" class="mt-n1">mdi-arrow-right</v-icon></j-btn
      >
      </div>
      <p>Review your celebration, then proceed</p>
    </div>

    <v-img
      v-if="data.celebrationImage"
      :lazy-src="data.celebrationImage"
      :src="data.celebrationImage"
      class="rounded-lg mb-4"
    />

    <j-btn v-if="!loading" class="mb-4" @click="save()">Re-generate Image <v-icon>mdi-sync</v-icon></j-btn>

    <ValidationObserver ref="obs">
      <ValidationProvider rules="required|max:12" v-slot="{ errors }" name="'Kg lost'">
        <j-text-field v-model="data.weight" label="Kg lost" :error-messages="errors" counter="12" />
      </ValidationProvider>
      <ValidationProvider rules="max:12" v-slot="{ errors }" name="'Size'">
        <j-text-field v-model="data.size" label="Sizes e.g '2 sizes'" :error-messages="errors" counter="12" />
      </ValidationProvider>
      <ValidationProvider rules="required|max:12" v-slot="{ errors }" name="'Cm'">
        <j-text-field v-model="data.cm" label="Cm" :error-messages="errors" counter="12" />
      </ValidationProvider>
      <ValidationProvider rules="required|max:10" v-slot="{ errors }" name="'Months'">
        <j-text-field v-model="data.months" label="Months" :error-messages="errors" counter="10" />
      </ValidationProvider>
    </ValidationObserver>

    <j-alert v-if="loading" class="mt-2 mx-4"
      >Please be patient while we generate your image...
      <v-progress-linear color="navy" indeterminate rounded height="6"></v-progress-linear>
    </j-alert>
  </div>
</template>

<script>
import { UPSERT_CELEBRATION_MUTATION } from "@/graphql/mutations/celebration";

export default {
  name: "CelebrationStepTwo",
  props: {
    celebrationData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      data: this.createEmptyDataObject(),
    };
  },
  watch: {
    celebrationData() {
      this.setData();
    },
  },
  created() {
    this.setData();
  },
  methods: {
    createEmptyDataObject() {
      return {
        id: null,
        celebrationImage: null,
        weight: null,
        size: null,
        cm: null,
        months: null,
      };
    },
    setData() {
      this.data = this.createEmptyDataObject();
      this.data.id = this.celebrationData.id;
      this.data.celebrationImage = this.celebrationData.celebrationImage;
      this.data.weight = this.celebrationData.weight;
      this.data.size = null;
      this.data.cm = this.celebrationData.cm;
      this.data.months = this.celebrationData.months;
    },
    save() {
      this.$refs.obs.validate().then(async (valid) => {
        if (valid) {
          this.loading = true;
          this.data = {
            id: this.celebrationData.id,
            weight: this.data.weight,
            size: this.data.size,
            cm: this.data.cm,
            months: this.data.months,
          };
          const result = await this.$apollo.mutate({
            mutation: UPSERT_CELEBRATION_MUTATION,
            variables: {
              data: this.data,
            },
          });

          if (result.data.upsertCelebration?.success) {
            this.data.celebrationImage = result.data.upsertCelebration.image;
          }
          this.loading = false;
        }
      });
    },
  },
};
</script>
