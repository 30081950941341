import gql from "graphql-tag";

export const INBOX_QUERY = gql`
query inboxMessages($pageSize: Int, $after: String, $id: ID, $unreadOnly: Boolean) {
  inboxMessages(first: $pageSize, after: $after, id: $id, readAt_Isnull: $unreadOnly) {
    totalCount
    edgeCount
    importantCount
    freshCount
    archiveCount
    edges {
      node {
        id
        readAt
        archivedAt
        dismissed
        inboxMessage {
          id
          image
          youtubeId
          showAsBanner
          sender {
            id
            firstName
            lastName
            userProfile {
              id
              profilePicture
            }
          }
          subject
          content
          isImportant
          ctaUrl
          ctaCopy
          deliveredAt
        }
      }
    }
  }
}
`;