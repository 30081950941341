export default {
  state: {
    unread: 0,
  },
  mutations: {
    mutateUnread(state, { unread }) {
      state.unread = unread;
    },
  },
  actions: {
    updateUnread({ commit }, unread) {
      commit("mutateUnread", { unread });
    }
  },
  getters: {
    unread: (state) => state.unread,
  },
}