<template>
  <div>
    <v-card class="reply-card text-left px-4 py-1" :class="message && sentBySelf(message) ? 'border-self' : 'border-other'">
      <v-btn v-if="dismissible" icon color="text-grey" class="float-right" @click="dismiss()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <div>
        <div>
          <div class="font-weight-bold" :class="message && sentBySelf(message) ? 'nutrition--text' : 'navy--text'">
            <user-avatar v-if="parent.user.userProfile" :user="parent.user" :profile-picture="parent.user.userProfile.profilePicture" size="35" />
            <span class="ml-2">{{ parent.user.firstName }} {{ parent.user.lastName }}</span>
          </div>
          <p
            v-if="parent.text"
            @click="openMessageDialog = true"
            class="pointer my-2 pb-0"
            v-html="truncate(formatMessage(parent.text), 250)"
          ></p>
        </div>
        <div>
          <div v-if="getImages(parent.messageattachmentSet).length > 0">
            <v-img
              max-width="80"
              max-height="100"
              contain
              :src="getImageGallery(parent.messageattachmentSet, true)"
              class="rounded-lg pointer px-1"
              @click="openImage(getImageGallery(parent.messageattachmentSet, true))"
            />
          </div>

          <div v-if="getVideos(parent.messageattachmentSet).length > 0" class="pa-2">
            <v-icon
              @click="
                openVideoDialog = true;
                selectedVideo = getVideos(parent.messageattachmentSet)[0];
              "
              large
              class="pointer"
              >mdi-video</v-icon
            >
          </div>

          <div v-if="getAudioFiles(parent.messageattachmentSet).length > 0" class="pa-2">
            <v-icon
              @click="
                openAudioDialog = true;
                selectedAudio = getAudioFiles(parent.messageattachmentSet)[0];
              "
              large
              class="pointer"
            >
              mdi-microphone
            </v-icon>
          </div>

          <div v-if="getPdfs(parent.messageattachmentSet).length > 0" class="pa-2">
            <div v-for="(pdf, pdfIndex) in getPdfs(parent.messageattachmentSet)" :key="'pdf-' + pdfIndex">
              <a :href="pdf.file" target="_blank" class="text-decoration-none">
                <v-icon color="red" x-large>mdi-file-pdf-box</v-icon>
              </a>
            </div>
          </div>
          <div v-if="getGifs(parent.messageattachmentSet).length > 0" class="text-left pa-2">
            <div v-for="(gif, gifIndex) in getGifs(parent.messageattachmentSet)" :key="'gif-' + gifIndex">
              <v-img
                :src="gif.remoteFile"
                :lazy-src="gif.remoteFile"
                contain
                :class="thread ? 'tiny-gif-size rounded-lg' : 'gif-size rounded-lg'"
              />
            </div>
          </div>
        </div>
      </div>
    </v-card>

    <!-- view audio dialog -->
    <v-dialog
      v-if="selectedAudio"
      v-model="openAudioDialog"
      max-width="600"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <j-card class="pa-2 card-radius">
        <v-layout>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              openAudioDialog = false;
              selectedAudio = null;
            "
            small
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-layout>

        <vue-plyr
          :options="{
            duration: selectedAudio.timeInSeconds,
            volume: 1,
          }"
          autoplay
        >
          <audio controls crossorigin playsinline>
            <source :src="selectedAudio.file" type="audio/mp3" />
          </audio>
        </vue-plyr>
      </j-card>
    </v-dialog>

    <!-- view video dialog -->
    <v-dialog
      v-if="selectedVideo"
      v-model="openVideoDialog"
      max-width="600"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <j-card class="pa-2 card-radius">
        <v-layout>
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              openVideoDialog = false;
              selectedVideo = null;
            "
            small
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-layout>

        <vue-plyr
          :options="{
            duration: selectedVideo.timeInSeconds,
          }"
          autoplay
        >
          <video controls crossorigin playsinline>
            <source size="720" :src="selectedVideo.file" type="video/mp4" />
          </video>
        </vue-plyr>
      </j-card>
    </v-dialog>

    <!-- view image dialog -->
    <v-dialog
      v-if="selectedImage"
      v-model="openImageDialog"
      max-width="800"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <j-card rounded="lg">
        <v-card-text>
          <div class="d-flex justify-end">
            <v-btn icon @click="openImageDialog = false" class="mr-n6">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-img class="rounded-lg" :src="selectedImage" />
        </v-card-text>
      </j-card>
    </v-dialog>

    <!-- view message dialog -->
    <v-dialog v-model="openMessageDialog" max-width="600" transition="dialog-bottom-transition" class="limited-height">
      <j-card rounded="lg">
        <v-card-text>
          <div class="d-flex justify-end">
            <v-btn icon @click="openMessageDialog = false" class="mr-n6">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <p class="my-2 pb-0" v-html="formatMessage(parent.text)"></p>
        </v-card-text>
      </j-card>
    </v-dialog>
  </div>
</template>

<script>
import ChatMixin from "@/mixins/ChatMixin";
import UserAvatar from "@/components/shared/user/UserAvatar";

export default {
  name: "MessageReplyCard",
  mixins: [ChatMixin],
  components: {
    UserAvatar
  },
  data() {
    return {
      openVideoDialog: false,
      selectedVideo: null,
      openAudioDialog: false,
      selectedAudio: null,
      openMessageDialog: false,
    };
  },
  props: {
    message: {
      type: Object,
      required: false,
    },
    parent: {
      type: Object,
      required: true,
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
    thread: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    dismiss() {
      this.$emit("dismiss");
    },
  },
};
</script>

<style lang="scss" scoped>
.reply-card {
  background-color: var(--v-background-grey-base);
  border-radius: 10px;
}
.gif-size {
  max-height: 250px;
  max-width: 200px;
}
.tiny-gif-size {
  max-height: 100px;
  max-width: 50px;
}
.border-self {
  border-left: 5px solid var(--v-nutrition-base);
}
.border-other {
  border-left: 5px solid var(--v-primary-base);
}
</style>
