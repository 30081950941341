import gql from 'graphql-tag'

export const REGISTER_MUTATION = gql`
mutation eventRegistration($registrationInput: RegistrationInput!){
  eventRegistration(registrationInput: $registrationInput){
    registration{
      id
      link
    }
  }
}
`
