import {sortObject} from "@/lib/array";

export default {
  methods: {
    mutateShoppingList(shoppingList) {
      let data = {};
      this.shoppingListId = shoppingList.id;
      shoppingList.shoppinglistitemSet = shoppingList.shoppinglistitemSet.sort((a, b) => {
        let aName = a.isCustomItem ? a.customItem : a.ingredient.plural;
        let bName = b.isCustomItem ? b.customItem : b.ingredient.plural;
        return aName > bName ? 1 : bName > aName ? -1 : 0;
      });
      shoppingList.shoppinglistitemSet.forEach((item) => {
        let groupName = item.isCustomItem ? "A__Your Items" : item.ingredient.group.name;
        if (groupName === "Condiments/Other") {
          groupName = "X__Pantry"; // hack to push condiments to bottom of list...
        }
        if (groupName in data) {
          data[groupName].push(item);
        } else {
          data[groupName] = [item];
        }
      });
      return sortObject(data);
    },
  }
}