<template>
  <div>
    <v-row v-if="$apollo.loading || $apollo.loading.userSwappedRecipes">
      <v-col :cols="mdCols" v-for="count in 4" :key="count"><v-skeleton-loader type="card-avatar" /> </v-col>
    </v-row>
    <recipe-day-listing
      v-else-if="recipesForDay && nutritionBlock"
      :date="date"
      :md-cols="mdCols"
      :blockId="blockId"
      :breakfast="breakfast(date)"
      :lunch="lunch(date)"
      :dinner="dinner(date)"
      :primarySnack="primarySnack(date)"
    />
    <div v-else-if="!recipesForDay && nutritionBlock">
      <j-alert>No recipes for this day</j-alert>
    </div>
  </div>
</template>

<script>
import { DAY_RECOMMENDED_RECIPES_QUERY, USER_SWAPPED_RECIPES_QUERY } from "@/graphql/queries/nutritionblock";
import { decodeId } from "@/lib/string";
import RecipeListMixin from "@/mixins/nutrition/RecipeListMixin";
import RecipeDayListing from "@/components/nutritionblocks/RecipeDayListing";

export default {
  name: "SingleDayRecipeListing",
  mixins: [RecipeListMixin],
  props: {
    mdCols: {
      type: [Number, String],
      default: 3,
    },
  },
  components: {
    RecipeDayListing,
  },
  created() {
    this.anchorDate = this.date;
    this.recipeForDayOnly = true;
  },
  computed: {
    date() {
      return this.$store.getters.selectedDate
        ? this.$store.getters.selectedDate.format("YYYY-MM-DD")
        : this.$moment().format("YYYY-MM-DD");
    },
    blockId() {
      if (this.$store.getters.activeNutritionBlock) {
        return decodeId(this.$store.getters.activeNutritionBlock.id);
      }
      return null;
    },
  },
  methods: {
    // Overrides the mixin method if data is set.
    getRecipesForDay() {
      if (this.recipesForDay?.nutritionblockdayrecipeSet?.edges) {
        return this.recipesForDay?.nutritionblockdayrecipeSet?.edges;
      }
      return RecipeListMixin.methods.getRecipesForDay.call(this);
    },
  },
  apollo: {
    recipesForDay: {
      query: DAY_RECOMMENDED_RECIPES_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          anchorDate: this.date,
          nutritionBlockId: decodeId(this.blockId),
          type: "STANDARD",
          subscriptionBlockId: this.activeSubscriptionBlockId,
        };
      },
      skip() {
        return !this.blockId && !this.activeSubscriptionBlockId;
      },
    },
    userSwappedRecipes: {
      query: USER_SWAPPED_RECIPES_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          startDate: this.date,
          endDate: this.$moment(this.date).add(1, "days").format("YYYY-MM-DD"),
        };
      },
      skip() {
        return !this.blockId && !this.activeSubscriptionBlockId;
      },
    },
  },
};
</script>
