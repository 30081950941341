<template>
  <j-banner-container
    header="Post"
    :gradient="['green-background', 'yellow-background']"
    show-share-link
    use-header-in-link
  >
    <v-col cols="12">
      <v-skeleton-loader v-if="$apollo.loading" type="card,list-item-two-line,list-item-two-line" />
      <post-block v-else :post="post" showCommentOnLoad hidePostLink @reload="reloadQuery" />
    </v-col>
  </j-banner-container>
</template>

<script>
import { UPDATE_NOTIFICATION_MUTATION } from "@/graphql/mutations/notifications";
import { POST_QUERY } from "@/graphql/queries/post";
import { decodeId } from "@/lib/string";
import PostBlock from "@/components/groups/posts/PostBlock";

export default {
  name: "Post",
  components: {
    PostBlock,
  },
  props: {
    postId: {
      type: [String, Number],
      required: false,
    },
  },
  data() {
    return {
      selectedPost: null,
    };
  },
  created() {
    this.$apollo.mutate({
      mutation: UPDATE_NOTIFICATION_MUTATION,
      variables: {
        // update all notifications related to this post. not just an individual notification
        postId: this.postId,
        // a hack so that the date_read is in the future vs updated_at, which will be set when this mutation runs
        dateRead: this.$moment().add(10, "seconds").toISOString(),
      },
      refetchQueries: ["assistantQuery", "notificationQuery"],
    });
  },
  methods: {
    reloadQuery() {
      this.$apollo.queries.post?.refetch();
    },
  },
  apollo: {
    post: {
      query: POST_QUERY,
      variables() {
        return {
          id: decodeId(this.postId),
        };
      },
      fetchPolicy: "network-only",
    },
  },
};
</script>
