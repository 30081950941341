<template>
  <div class="text-center mb-5">
    <div class="heading-3 font-weight-medium appblue--text pt-3 pb-1 mt-4">Need some guidance?</div>
    <div class="mb-2 text-center p-text grey-navy--text font-weight-medium">
      Chat to us first

      <span :class="{ 'd-none': $vuetify.breakpoint.mdAndUp }">
        or explore our
        <a
          href="https://www.jeff.fitness"
          target="_blank"
          class="p-text appblue--text font-weight-medium text-decoration-none"
          >membership options</a
        >.</span
      >
    </div>
    <div class="d-flex align-center justify-center">
      <a href="https://wa.me/+27607435740" target="_blank"
        ><img :src="require('@/assets/images/whatsapp_green.png')" width="88px"
      /></a>

      <!-- <div>
        <div class="mr-2 mr-md-5">
          <a href="https://wa.me/+27627089003" target="_blank"
            ><img :src="require('@/assets/images/Janelle.png')" width="100"
          /></a>
        </div>
        <div class="p-text font-weight-medium mb-1">Janelle</div>
        <div>
          <a href="https://wa.me/+27627089003" target="_blank"
            ><img :src="require('@/assets/images/whatsappicon.png')" width="35"
          /></a>
        </div>
      </div>
      <div class="mr-2 mr-md-5">
        <div>
          <a href="https://wa.me/+270609135323" target="_blank"
            ><img :src="require('@/assets/images/Shaun.png')" width="100"
          /></a>
        </div>
        <div class="p-text font-weight-medium mb-1">Shaun</div>
        <div>
          <a href="https://wa.me/+270609135323" target="_blank"
            ><img :src="require('@/assets/images/whatsappicon.png')" width="35"
          /></a>
        </div>
      </div>
      <div class="mr-2 mr-md-5">
        <div>
          <a href="https://wa.me/+27824445827" target="_blank"
            ><img :src="require('@/assets/images/Zamo.png')" width="100"
          /></a>
        </div>
        <div class="p-text font-weight-medium mb-1">Zamo</div>
        <div>
          <a href="https://wa.me/+27824445827" target="_blank"
            ><img :src="require('@/assets/images/whatsappicon.png')" width="35"
          /></a>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "NeedMoreTime",
};
</script>
