import { LEAD_INSTRUCTOR_QUERY } from "@/graphql/queries/workoutblock";

export default {
  data() {
    return {
      loading: true,
      instructors: [],
      tags: [],
      form: {
        instructors: [],
        types: [
          {
            text: 'Exercise Programs',
            value: 'PROGRAM'
          },
          {
            text: 'New Exercise Programs',
            value: 'CHALLENGE'
          },
          {
            text: 'Courses',
            value: 'COURSE'
          }
        ]
      }
    }
  },
  apollo: {
    leadInstructors: {
      query: LEAD_INSTRUCTOR_QUERY,
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data && !response.loading) {
          this.loading = false;
          this.instructors = response.data.leadInstructors.map((item) => {
            return {
              text: item.firstName + " " + item.lastName,
              value: item.id,
            };
          });
        }
      }
    },
  }
}