import { UPDATE_SUBSCRIPTION_BLOCK_MUTATION } from "@/graphql/mutations/workoutblock";
import { decodeId, capitalize } from "@/lib/string";

export default {
  data() {
    return {
      openManageBlockDialog: false,
    };
  },
  computed: {
    accentColor() {
      if (this.workoutBlock?.type === "COURSE") {
        return "yellow";
      }
      if (this.workoutBlock?.type === "NUTRITION") {
        return "nutrition";
      }
      return "pink";
    },
    withCopy() {
      // refactor problems.
      // Sometimes referred to as `workoutblock` but now also just `block`, so that we can reuse for nutrition blocks
      let block = this.workoutBlock;
      if (!block) {
        block = this.block;
      }

      if (!block) {
        return "";
      }
      let copy = `with ${block.leadInstructor?.firstName}`;
      if (block.secondaryInstructor?.firstName) {
        copy = copy + ` & ${block.secondaryInstructor?.firstName}`;
      }
      return copy;
    },
  },
  methods: {
    navigateOverview() {
      if(this.workoutSubscriptionBlock?.block?.id) {
        return `/program/${decodeId(this.workoutSubscriptionBlock.block.id)}/overview?sbid=${decodeId(
          this.workoutSubscriptionBlock.id
        )}&sd=${this.workoutSubscriptionBlock.startDate}`;
      }
    },
    decodeId(str) {
      return decodeId(str);
    },
    hasCompleted(dailyWorkoutActivity, restDays, customActivities, date, subscriptionBlockId) {
      // first check workout logs before falling through
      date; // unused
      if (
        this.workoutBlockWorkoutLogs?.some(
          (log) => decodeId(log.node.unitActivity.id) == decodeId(dailyWorkoutActivity.id)
        )
      ) {
        return true;
      }

      // rest days and live event logs now are logged against subscription block and unit activity
      if (dailyWorkoutActivity.type == "LIVE_EVENT" || dailyWorkoutActivity.type == "REST_DAY") {
        let completed = customActivities.some((activityLog) => activityLog.subscriptionBlock?.id == decodeId(subscriptionBlockId) && activityLog.unitActivity?.id == dailyWorkoutActivity?.id);
        if(completed){
          return true;
        }
        return restDays.some((restDay) => restDay.subscriptionBlock?.id == decodeId(subscriptionBlockId) && restDay.unitActivity?.id == dailyWorkoutActivity?.id);
      }

      return false;
    },
    getColour(dailyWorkoutActivity) {
      if (dailyWorkoutActivity.type == "REST_DAY") {
        return "darker-grey";
      }
      // if the workout block is nutrition, then just brute force to the nutrition colour.
      if (this.workoutBlock.type === "NUTRITION") {
        return "nutrition";
      }
      if (dailyWorkoutActivity.type == "WORKOUT" || dailyWorkoutActivity.type == "LIVE_EVENT") {
        return "pink";
      }
      // Module or Ed
      return "yellow";
    },
    getActivityTitle(dailyWorkoutActivity) {
      if (!dailyWorkoutActivity.activity) {
        if (dailyWorkoutActivity.type == "LIVE_EVENT") {
          return "Freestyle";
        }
        return capitalize(dailyWorkoutActivity.type);
      }
      return dailyWorkoutActivity.activity.title;
    },

    /**
     * For a given activity, in a unit, for a specific subscription block start date, calculate the date activity is for
     * @param {object} unitActivity
     * @param {object} unit
     * @param {String} startDate
     * @returns String
     */
    calculateActivityDate(unitActivity, unit, startDate) {
      const daysToAdd = unit.order * 7 + unitActivity.day;
      const activityDate = this.$moment(startDate, "YYYY-MM-DD").add(daysToAdd, "days");
      return activityDate.format("YYYY-MM-DD");
    },

    async updateSubscriptionBlock(data) {
      this.startDate = data.startDate;
      const input = {
        subscriptionBlockId: decodeId(data.workoutSubscriptionBlock.id),
        startDate: this.startDate,
        blockId: decodeId(data.workoutSubscriptionBlock.block.id),
        isSelfPaced: data.isSelfPaced,
      };
      await this.$apollo.mutate({
        mutation: UPDATE_SUBSCRIPTION_BLOCK_MUTATION,
        variables: {
          data: input,
        },
      });
    },

    async leaveBlock() {
      const input = {
        subscriptionBlockId: decodeId(this.workoutSubscriptionBlock.id),
        endDate: this.$moment().format("YYYY-MM-DD"),
      };
      await this.$apollo.mutate({
        mutation: UPDATE_SUBSCRIPTION_BLOCK_MUTATION,
        variables: {
          data: input,
        },
        refetchQueries: ["assistantQuery"],
      });
    },
  },
};
