<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="5">
        <j-card>
          <v-card-title class="pb-0 mb-1 justify-center">
            <h2 class="font-weight-bold mb-4 mt-4 appblue--text">
              <span v-if="productCode">Start your Journey with {{ captialise(productCode) }}</span>
              <span v-else>Start your JEFF Journey</span>
            </h2>
          </v-card-title>
          <v-card-text>
            <div class="pt-2 px-3">
              <registration-form :submit-button="{ text: 'Continue', color: 'pink' }" :include-password="true" />
            </div>
          </v-card-text>
        </j-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RegistrationForm from "@/components/authentication/blocks/RegistrationForm";

export default {
  name: "Register",
  components: {
    RegistrationForm,
  },
  data() {
    return {
      productCode: null,
    };
  },
  methods: {
    captialise(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },
  created() {
    // only setRedirectPath if not already set.
    if (!sessionStorage.getItem("redirectPath")) {
      sessionStorage.setItem("redirectPath", "/start/pricing");
    }

    this.productCode = sessionStorage.getItem("product-code");
    //   if (!sessionStorage.getItem("redirectPath")) {
    //     sessionStorage.setItem("redirectPath", "/gate");
    //   }
  },
};
</script>
