import { RECOMMENDED_PORTION_QUERY } from "@/graphql/queries/nutritionblock";
import { capitalize } from "@/lib/string";

export default {
  name: "IngredientsMixin",
  data() {
    return {
      portionSizes: [
        { text: "Lite", value: "Lite" },
        { text: "Small", value: "Small" },
        { text: "Medium", value: "Medium" },
        { text: "Standard", value: "Standard" },
      ],
      ingredientMultiplier: null,
    };
  },
  methods: {
    updateIngredientMultiplier(val) {
      this.$store.dispatch("updateIngredientMultiplier", val);
    },
    formatNumber(number) {
      let parsed = parseFloat(number);
      let decimalValue = Math.round((parsed % 1) * 1000) / 1000;
      if (decimalValue === 0) return parsed; // this is a whole number
      let wholeNumber = parseInt(parsed);
      let fraction = this.tryConvertToFraction(decimalValue);

      //try to find the closest appropriate fraction, otherwise display the decimal point
      if (!this.isFraction(fraction)) {
        let result = this.tryGetFraction(fraction, 1000 / 5);
        if (result.success) {
          return this.displayNumber(wholeNumber, true, result.value);
        } else {
          result = this.tryGetFraction(fraction + 5 / 1000, 1000 / 5);
          if (result.success) {
            return this.displayNumber(wholeNumber, true, result.value);
          } else {
            result = this.tryGetFraction(fraction - 5 / 1000, 1000 / 5);
            if (result.success) {
              return this.displayNumber(wholeNumber, true, result.value);
            } else {
              return this.displayNumber(wholeNumber, false, fraction);
            }
          }
        }
      }

      return this.displayNumber(wholeNumber, true, fraction);
    },
    displayNumber(wholeNumber, isFraction, value) {
      if (!isFraction) {
        value = value.toString().split(".")[1];

        return wholeNumber != 0 ? `${wholeNumber}.${value}` : `0.${value}`;
      }
      return wholeNumber != 0 ? `${wholeNumber} ${value}` : `${value}`;
    },
    isFraction(value) {
      return value.toString().includes("/");
    },
    tryGetFraction(value, roundingValue) {
      let roundedDecimal = Math.round(value * roundingValue) / roundingValue;
      value = this.tryConvertToFraction(roundedDecimal);
      let success = this.isFraction(value);

      return { success: success, value: value };
    },
    tryConvertToFraction(decimal) {
      const commonFractions = {
        0.5: "1/2",
        0.33: "1/3",
        0.66: "2/3",
        0.67: "2/3",
        0.25: "1/4",
        0.75: "3/4",
        0.165: "1/6",
        0.125: "1/8",
        0.375: "3/8",
        0.625: "5/8",
        0.875: "7/8",
        0.2: "1/5",
        0.1: "1/10",
      };

      return commonFractions[decimal] ?? decimal;
    },
    setIngredients(ingredientSet, ingredientMultiplier) {
      return ingredientSet?.map((recIng) => {
        let quantity = recIng.quantityLarge ?? recIng.quantity; //portionSize[2] is medium aka quantityLarge (default)
        if (this.clientMade) {
          this.portionSize = this.portionSizes[1];
        } else {
          // Lite = quantitySmall
          // Small = quantityMedium
          // Medium = quantityLarge
          // Standard = quantityExtraLarge
          switch (this.portionSize) {
            case this.portionSizes[0].value:
              quantity = recIng.quantitySmall;
              break;
            case this.portionSizes[1].value:
              quantity = recIng.quantityMedium;
              break;
            case this.portionSizes[3].value:
              quantity = recIng.quantityExtraLarge;
              break;
          }
        }

        // ingredientMultiplier starts at 0, to make it work with vueitfy button group, hence we need to add 1
        /**
         * CAREFUL, we are discontinuining the use of ingredientMultiplier. There are many references to it, 
         * but this is where we are hard code setting it to default, which is 0
         */
        ingredientMultiplier = 0; 

        quantity = parseFloat(quantity) * (ingredientMultiplier + 1);
        return {
          id: recIng.ingredient.id,
          formattedQuantity: this.formatNumber(quantity),
          quantity: quantity,
          measurement: quantity == 1 ? recIng.measurementUnit?.singular : recIng.measurementUnit?.plural,
          preparation: recIng.preparation?.title,
          name: recIng.ingredient?.plural,
        };
      });
    },
  },
  computed: {
    getIngredients() {
      return this.setIngredients(this.recipe?.recipeingredientSet, this.ingredientMultiplier);
    },
    mealSubtitle() {
      let recipeMeal = this.$options.filters.humanizeMeal(this.recipe.meal);

      if (this.meal && this.recipe.meal != this.meal) {
        return `${recipeMeal} as ${this.$options.filters.humanizeMeal(this.meal)}`;
      }
      return recipeMeal;
    },
  },
  apollo: {
    recommendedPortion: {
      query: RECOMMENDED_PORTION_QUERY,
      fetchPolicy: "cache-and-network",
      update(data) {
        let portion = data.me?.userProfile?.calculatedServingSize;

        if (portion) {
          let mySize = this.portionSizes.find((size) => size.text == capitalize(portion));
          if (mySize) {
            mySize.text += " (recommended for you)";
            this.portionSize = mySize.value;
          }

          this.$apollo.queries.recipe?.refetch();
          return capitalize(portion);
        } else {
          return this.portionSize;
        }
      },
      skip() {
        return !!this.recipe?.isClientMade;
      },
    },
  },
};
