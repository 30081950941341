<template>
  <v-card-text class="secondary--text pb-2">
    <v-card-title class="pa-0 font-18">
      <strong>
        {{ repCopy.title }}
      </strong>
    </v-card-title>
    <div class="d-flex">
      <p>Me</p>
      <v-spacer />
      <p v-if="timeMetric">{{ repCopy.totals.me | formatTime }}</p>
      <p v-else>{{ repCopy.totals.me | intl_number_format }}</p>
    </div>
    <div class="d-flex">
      <p>Community</p>
      <v-spacer />
      <p v-if="timeMetric">{{ repCopy.totals.community | formatTime }}</p>
      <p v-else>{{ repCopy.totals.community | intl_number_format }}</p>
    </div>
    <div class="d-flex pt-3">
      <div class="pr-2">
        <v-img
          :alt="repCopy.imageAltText"
          :lazy-src="repCopy.image"
          :src="repCopy.image"
          contain
          width="67"
          class="pt-2 mr-2"
        />
      </div>
      <div class="text-left grey-navy--text">
        <p class="mb-0">
          {{ repCopy.description }}
        </p>
        <a v-if="repCopy.linkText && repCopy.linkUrl" :href="repCopy.linkUrl" class="grey-navy--text">
          <strong>{{ repCopy.linkText }}</strong>
        </a>
      </div>
    </div>
  </v-card-text>
</template>

<script>
export default {
  name: "CommunityRepBlock",
  props: {
    repCopy: {
      type: Object,
      required: true,
    },
  },
  filters: {
    formatTime(number) {
      let hours = parseInt(number / 3600 % 24);
      let minutes = parseInt(number % 3600 / 60);
      let seconds = parseInt(number % 3600 % 60);

      if (number > (3600 * 24)) {
        let days = parseInt(number / 3600 / 24);
        return `${days}d ${hours}h ${minutes}m ${seconds}s`
      }
      else {
        return `${hours}h ${minutes}m ${seconds}s`
      }
    }
  },
  computed: {
    timeMetric() {
      return this.repCopy.metric == 'SECONDS';
    }
  }
};
</script>

<style scoped>
.font-18 {
  font-size: 18px;
}
div.d-flex > p {
  margin-bottom: 5px;
}
</style>