
import { CLIENTS_QUERY, FAVOURITE_USERS_QUERY } from "@/graphql/queries/account";
import { FAVOURITE_USERS_MUTATION } from "@/graphql/mutations/account";
import { extractNodes } from "@/lib/array";
import { decodeId } from "@/lib/string";

export default {
  data() {
    return {
      userType: null,
      selectedStaff: [],
      featuredStaff: [],
      staff: [],
      showMore: false,
      isActive: true,
    };
  },
  computed: {
    coaches() {
      return this.clients?.filter((client) => client.oneOnOneClientAllocation > client.oneOnOneClients);
    },
    coachName() {
      const coach = this.clients?.find((client) => decodeId(client.user.id) == decodeId(this.selectedCoachId));
      if (coach) {
        return coach.user.firstName;
      }
      return null;
    },
  },
  methods: {
    toggleCheck(id) {
      const index = this.selectedStaff.indexOf(id);
      index === -1 ? this.selectedStaff.push(id) : this.selectedStaff.splice(index, 1);
    },
    next(nextStepLink) {
      this.$apollo
        .mutate({
          mutation: FAVOURITE_USERS_MUTATION,
          variables: {
            staff: this.selectedStaff,
          },
        })
        .then((response) => {
          if (response.data.setFavouriteUsers.success) {
            return this.$router.push(nextStepLink);
          }
        });
    },
  },
  apollo: {
    clients: {
      query: CLIENTS_QUERY,
      variables() {
        return {
          userType: this.userType,
          isActive: this.isActive,
        };
      },
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data) {
          this.featuredStaff = [];
          this.staff = [];
          response.data.clients.edges.forEach((el) => {
            el.node.featured ? this.featuredStaff.push(el.node) : this.staff.push(el.node);
          });
        }
      },
      update: (data) => extractNodes(data.clients.edges),
      skip() {
        return this.userType == null;
      }
    },
    favouriteUsers: {
      query: FAVOURITE_USERS_QUERY,
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data) {
          this.selectedStaff = [];
          response.data.favouriteUsers.forEach((el) => {
            this.selectedStaff.push(el.staff.id.toString());
          });
        }
      },
      skip() {
        return this.userType == null;
      }
    },
  },
};
