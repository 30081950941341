<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center" class="fill-height">
      <v-col cols="12" md="6" xl="5" class="text-center px-5 background-grey-3 fill-height">
        <div class="text-h5 font-weight-medium navy--text mt-2">What more can you tell us?</div>
        <div class="grey-navy--text mt-2">We use this to calculate your health plan and achieve your goals</div>

        <v-btn-toggle
          v-model="sexToggle"
          dense
          mandatory
          borderless
          background-color="white"
          color="pink"
          rounded
          class="gender-toggle my-5 elevation-2"
          @change="$store.dispatch('updateSex', sexToggle ? 'Male' : 'Female')"
        >
          <v-btn class="px-4 py-2 rounded-pill" :class="{ 'pr-6': !sexToggle }">
            <v-icon :color="sexToggle ? 'mid-grey' : 'white'">mdi-gender-female</v-icon>
            <span class="text-capitalize text-body-2 font-weight-bold">Female</span>
          </v-btn>
          <v-btn class="px-5 pr-6 py-2 rounded-pill" :class="{ 'pl-6': sexToggle }">
            <v-icon :color="!sexToggle ? 'mid-grey' : 'white'">mdi-gender-male</v-icon>
            <span class="text-capitalize text-body-2 font-weight-bold">Male</span>
          </v-btn>
        </v-btn-toggle>

        <validation-observer ref="obs">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <validation-provider rules="required" v-slot="{ errors }" name="date of birth">
                <j-text-field
                  prepend-inner-icon="mdi-calendar"
                  v-model="dateOfBirth"
                  label="Birthday"
                  dense
                  background-color="white"
                  color="navy"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  :error-messages="errors"
                  :hide-details="errors.length == 0"
                  class="mb-4 mx-auto"
                />
              </validation-provider>
            </template>
            <v-date-picker
              :max="
                $moment()
                  .subtract(15, 'years')
                  .format('YYYY-MM-DD')
              "
              min="1920-01-01"
              ref="picker"
              :active-picker.sync="activePicker"
              color="navy"
              v-model="dateOfBirth"
              @change="saveDOB"
            />
          </v-menu>

          <validation-provider rules="required|noDecimals|max:3" v-slot="{ errors }" name="height">
            <j-text-field
              v-model="height"
              dense
              type="number"
              min="1"
              suffix="cm"
              pattern="\d*"
              background-color="white"
              color="navy"
              label="Height"
              :error-messages="errors"
              :hide-details="errors.length == 0"
              placeholder="in cm"
              class="mb-4 mx-auto"
              @change="$store.dispatch('updateHeight', height)"
            />
          </validation-provider>
          <validation-provider rules="required" v-slot="{ errors }" name="current weight">
            <j-text-field
              v-model="currentWeight"
              dense
              type="number"
              min="1"
              suffix="kg"
              background-color="white"
              color="navy"
              label="Current Weight"
              :error-messages="errors"
              :hide-details="errors.length == 0"
              class="mb-4 mx-auto"
              @change="$store.dispatch('updateCurrentWeight', currentWeight)"
            />
          </validation-provider>
          <validation-provider rules="required|minWeight" v-slot="{ errors }" name="goal weight">
            <j-text-field
              v-model="goalWeight"
              dense
              type="number"
              min="1"
              suffix="kg"
              background-color="white"
              color="navy"
              label="Goal Weight"
              :error-messages="errors"
              :hide-details="errors.length == 0"
              class="mb-4 mx-auto"
              @change="$store.dispatch('updateGoalWeight', goalWeight)"
            />
          </validation-provider>
        </validation-observer>

        <div class="buttons mx-auto d-flex mt-6">
          <j-btn tertiary to="/start/chosen">back</j-btn>
          <j-btn color="pink" @click="submit" class="d-flex flex-fill ml-5">next</j-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "Details",
  data() {
    return {
      menu: false,
      activePicker: null,
      sexToggle: 0,
      dateOfBirth: null,
      height: null,
      currentWeight: null,
      goalWeight: null,
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  created() {
    let deal = this.$store.getters.deal;

    this.sexToggle = deal.sex == "Male";
    this.dateOfBirth = deal.birthday;
    this.height = deal.height;
    this.currentWeight = deal.currentWeight;
    this.goalWeight = deal.goalWeight;
  },
  methods: {
    saveDOB(date) {
      this.$refs.menu.save(date);
      this.$store.dispatch("updateBirthday", date);
    },
    submit() {
      this.$refs.obs.validate().then((valid) => {
        if (valid) {
          this.$router.push("/start/level");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.gender-toggle {
  .v-btn {
    background-color: white;

    span {
      color: var(--v-mid-grey-base);
    }
  }
  .v-btn--active {
    background-color: var(--v-pink-base);

    span {
      color: white;
    }
  }
}

.j-text-field,
.buttons {
  max-width: 420px;
}
</style>
