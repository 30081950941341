<template>
  <j-card rounded="lg" class="pb-4">
    <v-toolbar class="mb-6">
      <v-toolbar-title>
        <v-icon size="36">mdi-chat</v-icon>
        Join Accountability Chats</v-toolbar-title
      >
      <v-spacer />
      <v-btn icon @click="$emit('closed')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <div class="mt-2">
        <p>For daily accountability, select one of the chats below that best matches your interests.</p>

        <chat-listing-card
          v-for="chat in chats"
          :key="chat.id"
          info-only
          description-inline
          :chat="chat"
          :showSettingsToggle="false"
          :showCard="false"
          @clicked="selectChat($event)"
        />

        <j-btn v-if="selectedChat" class="mt-6" secondary @click="confirm()"> CONFIRM {{ selectedChat.title }} </j-btn>
        <j-btn v-else tertiary disabled class="mt-6">
          SELECT A CHAT
        </j-btn>
      </div>
    </v-card-text>
  </j-card>
</template>

<script>
import { decodeId } from "@/lib/string";
import ChatListingCard from "@/components/chat/ChatListingCard";
export default {
  name: "JoinAccountabilityChats",
  components: {
    ChatListingCard,
  },
  props: {
    chats: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedChatId: null,
    };
  },
  computed: {
    selectedChat() {
      return this.chats?.find((element) => decodeId(element.id) === decodeId(this.selectedChatId));
    },
  },
  methods: {
    decodeId(id) {
      return decodeId(id);
    },
    selectChat(chatId) {
      this.selectedChatId = decodeId(chatId);
    },
    confirm() {
      this.$emit("confirm", this.selectedChatId);
    },
  },
};
</script>
