<template>
  <j-card
    rounded="lg"
    class="mr-3 my-1 j-elevated-5"
    :class="{ minheight: $vuetify.breakpoint.mdAndUp }"
    :to="`/group/${decodeId(group.id)}`"
  >
    <v-img
      :src="group.banner || require('@/assets/images/group_banner_default.png')"
      :lazy-src="group.banner || require('@/assets/images/group_banner_default.png')"
      :aspect-ratio="10 / 9"
    >
    </v-img>
    <v-card-text class=" px-2 pt-1 pb-2 text-left">
      <div class="d-flex justify-space-between">
        <div class="navy--text font-weight-bold three-lines">{{ group.title }}</div>
        <user-avatar v-if="profilePicture" :profile-picture="profilePicture" :user="group.createdBy" size="30" />
      </div>
    </v-card-text>
  </j-card>
</template>

<script>
import { decodeId } from "@/lib/string";
import UserAvatar from "@/components/shared/user/UserAvatar";

export default {
  name: "GroupListingCard",
  components: {
    UserAvatar,
  },
  props: {
    group: {
      type: Object,
      required: true,
    },
  },
  computed: {
    profilePicture() {
      return this.group.createdBy?.userProfile?.profilePicture;
    },
  },
  methods: {
    decodeId(id) {
      return decodeId(id);
    },
  },
};
</script>

<style lang="scss" scoped>
.minheight {
  min-height: 240px;
}
</style>
