<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" class="blue-background">
        <back-link icon large />
        <join-whatsapp-block canComplete @complete="complete"/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { UPDATE_WHATSAPP_JOINED } from "@/graphql/mutations/assistant";
import BackLink from "@/layouts/BackLink";
import JoinWhatsappBlock from "@/components/shared/blocks/JoinWhatsapp";

export default {
  name: "JoinWhatsapp",
  components: {
    BackLink,
    JoinWhatsappBlock,
  },
  methods: {
    complete() {
      this.$apollo
        .mutate({
          mutation: UPDATE_WHATSAPP_JOINED,
          refetchQueries: ["assistantQuery"],
        })
        .then(() => {
          this.$router.go(-1);
        });
    },
  },
};
</script>
