<template>
  <v-navigation-drawer
    tile
    elevation="12"
    :width="this.$vuetify.breakpoint.md ? 200 : 256"
    class="ml-3 background-grey"
    style="position:absolute;"
    floating
    permanent
  >
    <v-list dense rounded>
      <v-list-item link to="/today" active-class="text-gradient-style elevation-4">
        <v-list-item-icon>
          <v-icon class="link-text">mdi-white-balance-sunny</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="link-text">Home</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link to="/search" active-class="text-gradient-style elevation-4">
        <v-list-item-icon>
          <v-icon class="link-text">mdi-magnify</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="link-text">Programs</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link :to="classesLink" active-class="text-gradient-style elevation-4">
        <v-list-item-icon>
          <v-icon class="link-text">mdi-play-speed</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="link-text">Classes</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link to="/social" active-class="text-gradient-style elevation-4">
        <v-list-item-icon>
          <v-icon class="link-text">mdi-account-group</v-icon>
          <div v-if="unreadNotificationCount > 0 && value != 3" class="unread-notification">
            <v-avatar color="pink-background" size="20"
              ><span class="pink--text caption font-weight-bold">{{ unreadNotificationCount }}</span></v-avatar
            >
          </div>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="link-text">Community </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item link to="/chats" active-class="text-gradient-style elevation-4">
        <v-list-item-icon>
          <v-icon class="link-text">mdi-chat</v-icon>
          <div v-if="unreadChatCount && unreadChatCount > 0 && value != 4" class="unread-notification">
            <v-avatar color="pink-background" size="20"
              ><span class="pink--text caption font-weight-bold">{{ unreadChatCount }}</span></v-avatar
            >
          </div>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="link-text">Chat </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FloatingNav",
  data() {
    return { value: null };
  },
  computed: {
    ...mapGetters(["unreadNotificationCount", "unreadChatCount", "user"]),
    classesLink() {
      return this.user?.userProfile?.gymBranch ? "/classes" : "/live";
    }
  },
};
</script>

<style lang="scss" scoped>
.text-gradient-style {
  .link-text {
    background-image: linear-gradient(180deg, rgb(255, 197, 11), rgb(46, 184, 167));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.v-list-item--link::before {
  background-color: #fff !important;
}
.unread-notification {
  position: absolute;
  top: 0px;
  left: 38px;
}
</style>
