<template>
  <v-btn ref="button" medium icon @click="$vuetify.goTo(0)">
    <v-icon color="light-purple">
      mdi-navigation
    </v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "ScrollToTopBtn",
};
</script>
