import { MEDICAL_CONDITIONS_QUERY } from "@/graphql/queries/medicalConditions";
import { USER_MEDICAL_CONDITION_MUTATION } from "@/graphql/mutations/medicalConditions";
import { extractNodes } from "@/lib/array";

export default {
  data() {
    return {
      medicalConditions: [],
      selectedMedicalConditions: [],
      usermedicalconditionSet: [],
      otherMedicalCondition: "",
      hasMedicalConditions: false
    };
  },
  methods: {
    async updateUserMedicalConditions() {
      return this.$apollo.mutate({
        mutation: USER_MEDICAL_CONDITION_MUTATION,
        variables: {
          medicalConditions: this.selectedMedicalConditions,
          otherCondition: this.isOtherMedicalConditionSelected ? this.otherMedicalCondition : ""
        }
      });
    },
  },
  apollo: {
    medicalConditions: {
      query: MEDICAL_CONDITIONS_QUERY,
      fetchPolicy: "cache-and-network",
      update(data) {
        return extractNodes(data.medicalConditions.edges)
      }
    }
  },
};
