import { UPDATE_TIMEZONE_MUTATION } from '@/graphql/mutations/account'
import moment from 'moment'

/**
 * Updates the users timezone and syncronizes with the backend.
 * @param $store
 * @param $apollo
 */
export const updateTimezone = ($store, $apollo) => {

  let savedTimezone = $store.getters.timezone;
  let browserTimezone = moment.tz.guess(true);

  if (savedTimezone !== browserTimezone) {
    $apollo.mutate({
      mutation: UPDATE_TIMEZONE_MUTATION,
      variables: {
        timezone: browserTimezone,
      },
    }).then(() => {
      let timezone = browserTimezone;
      $store.dispatch('updateTimezone', { timezone } );
    })
  }

};

/**
 * Take a date and convert it to a LOCAL iso string.
 * EG: date in utc -> return LOCAL iso string
 * @param date
 * @returns {string}
 */
export const toLocalString = (date, toIso = true) => {
    function pad(number) { return ('' + number).padStart(2, '0') }
    return toIso ? `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}T${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`
     : `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())}`
}

/**
 * Take a date object and convert it to UTC timestamp.
 * This function DOES not convert a time to local time. It converts to UTC.
 * @param date
 * @param hours
 * @param minutes
 * @returns {string}
 */
export const dateAndTimeToUTC = (date, hours, minutes) => {
  function pad(number) { return ('' + number).padStart(2, '0') }
  let output = moment(`${date} ${pad(hours)}:${pad(minutes)}`, "YYYY-MM-DD HH:mm")
  return output.toISOString();
}

/**
 * Takes a date from the DB and returns the date in the users local time.
 * The date in the DB must be timestamp aware and stored as UTC time.
 * @param timestamp
 * @param format
 * @returns {string}
 */
export const utcDateStringToLocalFormat = (timestamp, format = 'MMMM Do YYYY, h:mm a') => {
  return moment(timestamp).tz(moment.tz.guess()).format(format);
}

/**
 * Take a time in the DB and convert this time from SA time to the users local time
 * eg: 15:00 SA time = 05:00 pacific standard time
 * @param time
 * @returns {string}
 */
export const convertSATimeToLocalTime = (time) => {
  //sadly some dates are stored in the DB in south african time
  //and we need to convert those dates to user local time
  let today = moment().format("YYYY-MM-DD")
  let start = moment.tz(`${today} ${time}`, "Africa/Johannesburg");
  return start.clone().tz(moment.tz.guess()).format("HH:mm:ss");
}

/**
 * Gets the isostring for specified day of the week
 * Hours / Minutes / Seconds are set to zero
 * Indexing starts at 1, with 1 = Monday, 7 = Sunday
 * @param index The day to get
 * @param endOfDay boolean End of the day or start of the day
 * @param currentWeekDate The reference date. Format: any moment supported format
 * @returns {string}
 */
export const getDayOfWeekString = (index, endOfDay = false, currentWeekDate = null, toIso=true) => {
  if (currentWeekDate == null)
    currentWeekDate = moment().format("YYYY-MM-DD");

  let date = moment(currentWeekDate).utc().weekday(index);

  if (endOfDay)
    date = date.set({"hour": 23, "minute": 59, "second": 59});
  else
    date = date.set({"hour": 0, "minute": 0, "second": 0});

  return toIso ? date.toISOString() : date.format("YYYY-MM-DD");
}