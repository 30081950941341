<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <j-card>
          <v-row>
            <v-col cols="12">
              <v-container v-if="$apollo.loading">
                <v-progress-circular indeterminate></v-progress-circular>
              </v-container>
              <v-container v-else>
                <div v-if="linkingResult || linkingError === 'USER_ALREADY_LINKED'" class="text-center">
                  <img :src="require('@/assets/images/completed.png')" width="100" />

                  <h2 class="green--text mt-4">WELCOME TO JEFF 🎉</h2>

                  <j-alert type="success" class="mt-6" style="max-width: 350px; margin-left: auto; margin-right: auto">
                    You're on the {{ this.corporateChallenge.title }}! Your program will start on
                    {{ startDate | moment("dddd, DD MMM") }}.
                  </j-alert>

                  <div class="std-text mt-6" style="max-width: 350px; margin-left: auto; margin-right: auto">
                    <span v-if="isNewUser">
                      Download the <b>JEFF Fitness APP</b> and login with your details to get started.</span
                    >
                    <span v-else>
                      If you haven't done so yet, download the <b>JEFF Fitness APP</b> and login with your details to
                      get started. <br /><br />If you already have the app, you should be able to login or pull down to
                      refresh to unlock all features.
                    </span>
                  </div>

                  <native-app-icons class="mt-4" />
                </div>

                <j-alert type="error" v-else> Invalid linking code. </j-alert>
              </v-container>
            </v-col>
          </v-row>
        </j-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { CORPORATE_CHALLENGE_QUERY } from "@/graphql/queries/challenge";
import { fancy_date } from "@/lib/fancy_date";
import { LINK_CORPORATE_CHALLENGE_MUTATION } from "@/graphql/mutations/account";
import NativeAppIcons from "@/components/shared/NativeAppIcons";

export default {
  name: "LinkCorporateChallenge",
  components: {
    NativeAppIcons,
  },
  data() {
    return {
      linkingResult: this.$route?.query?.lr,
      linkingError: this.$route?.query?.le,
    };
  },
  computed: {
    startDate() {
      return fancy_date(this.corporateChallenge.startDate, "dddd, D MMMM YYYY", false);
    },
  },
  async mounted() {
    // if a user is logged in, do the linking here. If they are a new sign up, the linking is handled by RegistrationMixin
    if (this.$store.getters.isLoggedIn && this.$route.query.loggedin) {
      const result = await this.$apollo.mutate({
        mutation: LINK_CORPORATE_CHALLENGE_MUTATION,
        variables: {
          registrationCode: this.$route.params.linkingCode,
        },
        refetchQueries: ["accountQuery", "assistantQuery", "notificationQuery"],
      });
      this.linkingResult = result.data?.linkUserToCorporateChallenge?.success;
    }
  },
  apollo: {
    corporateChallenge: {
      query: CORPORATE_CHALLENGE_QUERY,
      variables() {
        return {
          registrationCode: this.$route.params.linkingCode,
        };
      },
      fetchPolicy: "cache-and-network",
      skip() {
        return !this.$route.params.linkingCode;
      },
    },
  },
};
</script>
