<template>
  <div class="text-left mx-6">
    <ValidationObserver ref="obs" v-slot="{ valid }">
      <j-alert v-if="!valid && !$apollo.loading" type="warning" icon="mdi-arrow-down" class="mt-4"
        >Please enter more information</j-alert
      >
      <div class="text-center mt-6">
        <user-avatar
          :profile-picture="data.profilePicture"
          :user="data"
          :size="profileSize"
          highlight-avatar
          highlight-colour="blue-background"
        />
        <div class="text-center pointer camera-icon">
          <v-avatar color="blue-background" size="35">
            <v-progress-circular v-if="imageLoading" indeterminate color="primary"></v-progress-circular>
            <v-icon v-else color="#000" @click="clickUploader()"> mdi-camera-plus</v-icon>
          </v-avatar>
        </div>
      </div>

      <h3>When is your birthday?</h3>
      <div class="mb-n6">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <ValidationProvider rules="required" v-slot="{ errors }" name="date of birth">
              <j-text-field
                prepend-inner-icon="mdi-calendar"
                v-model="data.dateOfBirth"
                readonly
                v-bind="attrs"
                v-on="on"
                :error-messages="errors"
                class="mt-3"
              />
            </ValidationProvider>
          </template>
          <v-date-picker
            :max="$moment().subtract(15, 'years').format('YYYY-MM-DD')"
            min="1920-01-01"
            ref="picker"
            :active-picker.sync="activePicker"
            color="navy"
            v-model="data.dateOfBirth"
            @change="saveDOB"
          />
        </v-menu>
      </div>

      <h3>What is your current fitness level?</h3>
      <ValidationProvider rules="required" v-slot="{ errors }" name="fitness">
        <j-radio-group v-model="data.fitnessLevel" row :error-messages="errors">
          <j-radio
            v-for="fitnessOption in fitnessOptions"
            :key="fitnessOption.value"
            :label="fitnessOption.text"
            :value="fitnessOption.value"
          />
        </j-radio-group>
      </ValidationProvider>

      <h3>Do you have any injuries or medical conditions?</h3>
      <ValidationProvider rules="required" v-slot="{ errors }" name="medical conditions">
        <j-radio-group v-model="hasMedicalConditions" row :error-messages="errors">
          <j-radio label="No" v-bind:value="false" />
          <j-radio label="Yes" v-bind:value="true" />
        </j-radio-group>
      </ValidationProvider>
      <p class="subtitle">Please consult with your doctor about your unique physical and medical conditions</p>

      <div v-if="hasMedicalConditions">
        <h3>Please specify if you have been diagnosed with any of the following medical conditions</h3>
        <p class="subtitle mt-2">Choose none, one or multiple</p>
        <v-row no-gutters>
          <v-col cols="4" v-for="medicalCondition in medicalConditions" :key="medicalCondition.id">
            <j-checkbox
              v-model="selectedMedicalConditions"
              :key="medicalCondition.id"
              :label="medicalCondition.condition"
              :value="medicalCondition.id"
            />
          </v-col>
          <v-col cols="12" v-if="isOtherMedicalConditionSelected">
            <ValidationProvider rules="required" v-slot="{ errors }" name="details">
              <j-text-field
                v-model="otherMedicalCondition"
                type="text"
                label="Please specify"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
      </div>

      <h3>What is your gender?</h3>
      <ValidationProvider rules="required" v-slot="{ errors }" name="sex">
        <j-radio-group v-model="data.gender" row :error-messages="errors">
          <j-radio
            v-for="genderOption in genderOptions"
            :key="genderOption.value"
            :label="genderOption.text"
            :value="genderOption.value"
          />
        </j-radio-group>
      </ValidationProvider>

      <div v-if="data.gender && data.gender === 'FEMALE'">
        <h3>Are you currently pregnant?</h3>
        <ValidationProvider rules="required" v-slot="{ errors }" name="pregnant">
          <j-radio-group v-model="data.pregnant" row :error-messages="errors">
            <j-radio label="Yes" :value="true" />
            <j-radio label="No" :value="false" />
          </j-radio-group>
        </ValidationProvider>

        <div v-if="data.pregnant">
          <h3>What trimester are you in?</h3>
          <ValidationProvider rules="required" v-slot="{ errors }" name="trimester">
            <j-radio-group v-model="data.trimester" row :error-messages="errors">
              <j-radio
                v-for="pregnantOption in pregnantOptions"
                :key="pregnantOption.value"
                :label="pregnantOption.text"
                :value="pregnantOption.value"
              />
            </j-radio-group>
          </ValidationProvider>
        </div>
        <div v-if="data.pregnant === false">
          <h3>Are you currently breastfeeding?</h3>
          <ValidationProvider rules="required" v-slot="{ errors }" name="breastfeeding">
            <j-radio-group v-model="data.breastfeeding" row :error-messages="errors">
              <j-radio label="Yes" :value="true" />
              <j-radio label="No" :value="false" />
            </j-radio-group>
          </ValidationProvider>
        </div>
      </div>

      <div v-if="showWeightLossGoal">
        <h3>What is your primary weight related goal?</h3>
        <ValidationProvider rules="required" v-slot="{ errors }" name="weight-related goal">
          <j-radio-group v-model="data.weightLossGoal" :error-messages="errors">
            <j-radio
              v-for="weightLossGoal in weightLossGoalOptions"
              :key="weightLossGoal.value"
              :label="weightLossGoal.text"
              :value="weightLossGoal.value"
            />
          </j-radio-group>
        </ValidationProvider>
      </div>

      <div v-if="showWeightLossGoal">
        <h3>What is your goal weight?</h3>
        <ValidationProvider rules="required|min_value:1" v-slot="{ errors }" name="goal weight">
          <j-text-field
            v-model="data.goalWeight"
            type="number"
            label="Kg"
            min="1"
            :error-messages="errors"
            class="mt-4"
          />
        </ValidationProvider>
      </div>

      <h3>Any diet preference?</h3>
      <ValidationProvider rules="required" v-slot="{ errors }" name="diet preference">
        <j-radio-group v-model="selectedPrimaryNutritionPreferences" row :error-messages="errors">
          <j-radio
            v-for="primaryNutritionPreference in primaryNutritionPreferences"
            :key="primaryNutritionPreference.id"
            :label="primaryNutritionPreference.title"
            :value="primaryNutritionPreference.id"
            class="mb-4"
          />
        </j-radio-group>
      </ValidationProvider>

      <h3>Any other food preferences?</h3>
      <p class="subtitle">Choose none, one or multiple</p>
      <v-row no-gutters>
        <v-col
          cols="4"
          v-for="secondaryNutritionPreference in secondaryNutritionPreferences"
          :key="secondaryNutritionPreference.id"
        >
          <j-checkbox
            v-model="selectedSecondaryNutritionPreferences"
            :key="secondaryNutritionPreference.id"
            :label="secondaryNutritionPreference.title"
            :value="secondaryNutritionPreference.id"
            :disabled="disabledNutritionPrefs(secondaryNutritionPreference.id)"
          />
        </v-col>
      </v-row>

      <h3>What is your current weight and height?</h3>
      <v-row class="mt-2">
        <v-col cols="6">
          <ValidationProvider rules="required" v-slot="{ errors }" name="weight">
            <j-text-field v-model="data.weight" type="number" label="Weight (kg)" :error-messages="errors" />
          </ValidationProvider>
        </v-col>
        <v-col cols="6">
          <ValidationProvider v-slot="{ errors }" name="height" rules="required|noDecimals|threeDigits">
            <j-text-field
              v-model="data.height"
              type="number"
              label="Height (cm)"
              :error-messages="errors"
            />
          </ValidationProvider>
        </v-col>
      </v-row>

      <h3>What is your role and where do you work?</h3>
      <v-row class="mt-2">
        <v-col cols="6">
          <j-text-field v-model="data.companyRole" label="Role" />
        </v-col>
        <v-col cols="6">
          <j-text-field v-model="data.company" label="Company" />
        </v-col>
      </v-row>
      <p class="subtitle">Employees in company</p>
      <v-row>
        <v-col cols="12" class="pt-0">
          <j-radio-group v-model="data.companySize" row>
            <j-radio
              v-for="companySizeOption in companySizeOptions"
              :key="companySizeOption.value"
              :label="companySizeOption.text"
              :value="companySizeOption.value"
            />
          </j-radio-group>
        </v-col>
      </v-row>
      <j-alert v-if="!valid" type="warning" icon="mdi-arrow-up">Enter outstanding information</j-alert>
      <j-alert v-if="showSuccessAlert" type="success">Form successfully saved.</j-alert>
    </ValidationObserver>
    <confirm-nutrition-change-dialog
      :original-plan="changeNutritionPlan.original"
      :new-plan="changeNutritionPlan.new"
      ref="nutritionChangeModal"
    />

    <div>
      <image-uploader
        id="image-uploader"
        :maxWidth="1000"
        hidden
        :preview="false"
        :quality="0.9"
        outputFormat="verbose"
        accept="image/*"
        @input="setImage"
      />
    </div>
    <v-dialog v-model="cropperDialog" width="600" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar color="white">
          <j-btn
            v-if="data.image != null"
            color="secondary"
            class="dashboard-button mt-1"
            rounded
            :loading="savingProfilePicture"
            @click="saveProfileImage()"
          >
            Update Profile image
          </j-btn>
          <v-btn icon @click="cropperDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row>
          <v-col cols="12">
            <cropper
              v-if="selectedImgSrc != null"
              class="cropper mt-2 mb-2"
              :src="selectedImgSrc"
              :stencil-props="{
                aspectRatio: 1,
                movable: true,
                resizable: true,
                resizeImage: false,
              }"
              @change="change"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import "vue-advanced-cropper/dist/style.css";
import ImageUploader from "vue-image-upload-resize";
import { ACCOUNT_QUERY } from "@/graphql/queries/account";
import { UPDATE_PROFILE_MUTATION } from "@/graphql/mutations/account";
import {
  userData,
  genderOptions,
  fitnessOptions,
  companySizeOptions,
  pregnantOptions,
  weightLossGoalOptions,
} from "@/lib/user";
import NutritionPreferencesMixin from "@/mixins/nutrition/NutritionPreferencesMixin";
import ProfilePictureMixin from "@/mixins/ProfilePictureMixin";
import UserAvatar from "@/components/shared/user/UserAvatar";
import ConfirmNutritionChangeDialog from "@/components/shared/dialogs/ConfirmNutritionChangeDialog";
import { UPDATE_SERVING_SIZE_MUTATION } from "@/graphql/mutations/nutrition";
import MedicalConditionsMixin from "@/mixins/medicalCondition/MedicalConditionsMixin";
import { extractNodes } from "@/lib/array";
import { Cropper } from "vue-advanced-cropper";

export default {
  name: "PersonalPreferences",
  mixins: [NutritionPreferencesMixin, ProfilePictureMixin, MedicalConditionsMixin],
  components: {
    ConfirmNutritionChangeDialog,
    ImageUploader,
    UserAvatar,
    Cropper,
  },
  data() {
    return {
      menu: false,
      activePicker: null,
      me: {},
      genderOptions: genderOptions.filter((item) => item.value !== null),
      fitnessOptions: fitnessOptions,
      pregnantOptions: pregnantOptions,
      weightLossGoalOptions: weightLossGoalOptions,
      companySizeOptions: companySizeOptions,
      data: { ...userData, image: null },
      showSuccessAlert: false,
      changeNutritionPlan: {
        original: "",
        new: "",
      },
    };
  },
  watch: {
    hasMedicalConditions(val) {
      this.data.hasMedicalConditions = val;

      if (val) return;
      this.otherMedicalCondition = "";
      this.selectedMedicalConditions = [];
    },
    selectedMedicalConditions() {
      this.otherMedicalCondition = !this.isOtherMedicalConditionSelected ? "" : this.otherMedicalCondition;
    },
    usermedicalconditionSet() {
      this.setMedicalConditionNotes();
    },
    medicalConditions() {
      this.setMedicalConditionNotes();
    },
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
  computed: {
    profileSize() {
      return this.$vuetify.breakpoint.smAndDown ? "100" : "140";
    },
    showWeightLossGoal() {
      if (this.data.gender === "MALE") return true;

      if (this.data.pregnant || this.data.breastfeeding) return false;

      return true;
    },
    isOtherMedicalConditionSelected() {
      const other = this.medicalConditions.find((x) => x.condition === "Other");

      if (!other) return false;
      return !!this.selectedMedicalConditions.find((x) => x === other.id);
    },
  },
  methods: {
    setMedicalConditionNotes() {
      if (!this.medicalConditions || !this.usermedicalconditionSet) return;

      const other = this.medicalConditions.find((x) => x.condition === "Other");
      if (other) {
        this.otherMedicalCondition = this.usermedicalconditionSet.find((x) => x.medicalCondition.id === other.id)?.note;
      }
    },
    saveDOB(date) {
      this.$refs.menu.save(date);
    },
    getUserData() {
      let userData = {
        dateOfBirth: this.data.dateOfBirth,
        fitnessLevel: this.data.fitnessLevel,
        hasMedicalConditions: this.data.hasMedicalConditions,
        gender: this.data.gender,
        height: this.data.height,
        weight: this.data.weight,
        desiredWeight: this.data.goalWeight,
        companyRole: this.data.companyRole,
        company: this.data.company,
        companySize: this.data.companySize,
        pregnant: this.data.pregnant,
        breastfeeding: this.data.breastfeeding,
        trimester: this.data.trimester,
        otherMedicalCondition: this.data.otherMedicalCondition,
      };
      let weightLossGoal = this.data.weightLossGoal;
      if (this.data.pregnant) {
        weightLossGoal = "healthy_pregnancy_trimester_" + this.data.trimester;
      } else if (this.data.breastfeeding) {
        weightLossGoal = "breastfeeding";
      }

      userData["weightLossGoal"] = weightLossGoal;
      return userData;
    },
    async save() {
      let formValid = await this.$refs.obs.validate();
      if (!formValid) {
        return false;
      }
      let userData = this.getUserData();
      let result = await this.$apollo.mutate({
        mutation: UPDATE_PROFILE_MUTATION,
        variables: {
          userProfileInput: userData,
        },
      });

      if (
        result.data.updateProfile.newServingSize &&
        result.data.updateProfile.newServingSize !== result.data.updateProfile.originalServingSize
      ) {
        this.changeNutritionPlan.new = result.data.updateProfile.newServingSize;
        this.changeNutritionPlan.original = result.data.updateProfile.originalServingSize;
        //let confirmed = await this.$refs.nutritionChangeModal.open();

        //if (confirmed) {
        await this.$apollo.mutate({
          mutation: UPDATE_SERVING_SIZE_MUTATION,
        });
        //}
      }

      await this.updateUserMedicalConditions();
      await this.updateNutritionPreferences();
      this.showSuccessAlert = true;
      return true;
    },
  },
  apollo: {
    me: {
      query: ACCOUNT_QUERY,
      fetchPolicy: "no-cache",
      result(response) {
        if (response.data) {
          let userProfile = response.data.me.userProfile;
          let user = response.data.me;
          // overwrite the ID with the non hashed version
          user.id = userProfile.user.id;
          this.data.fitnessLevel = userProfile.fitnessLevel;
          this.data.gender = userProfile.gender;
          this.data.hasMedicalConditions = userProfile.hasMedicalConditions;
          this.data.height = userProfile.height ? parseInt(userProfile.height) : null;
          this.data.weight = userProfile.weight;
          this.data.companyRole = userProfile.companyRole;
          this.data.company = userProfile.company;
          this.data.companySize = userProfile.companySize;
          this.data.profilePicture = userProfile.profilePicture;
          this.data.firstName = user.firstName;
          this.data.lastName = user.lastName;
          this.data.dateOfBirth = userProfile.dateOfBirth;
          this.data.weightLossGoal = userProfile.weightLossGoal;
          this.data.goalWeight = userProfile.desiredWeight;
          this.data.pregnant = userProfile.pregnant;
          this.data.breastfeeding = userProfile.breastfeeding;
          this.data.trimester = userProfile.trimester;
          this.usermedicalconditionSet = extractNodes(user.usermedicalconditionSet.edges);
          this.selectedMedicalConditions = extractNodes(user.usermedicalconditionSet.edges).map(
            ({ medicalCondition }) => medicalCondition.id
          );
          this.hasMedicalConditions = userProfile.hasMedicalConditions;

          this.$nextTick(() => {
            this.$refs.obs.validate();
          });
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
h3 {
  color: var(--v-grey-navy-base);
  margin-top: 36px;
}

p.subtitle {
  color: var(--v-grey-navy-base);
  font-size: 14px;
  margin-bottom: 8px;
}
.camera-icon {
  margin-top: -30px;
  margin-left: 70px;
}
</style>
