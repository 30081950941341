<template>
  <j-card>
    <v-card-title>{{ pageTitle }}</v-card-title>
    <v-card-text>
      <div v-if="newMeetup">
        <p>
          Thank you for submitting your event request. You can print the following details to send to a friend or to
          save for yourself.
        </p>
        <j-btn color="whatsapp-green" large target="_blank" :href="whatsappShareUrl">
          <strong>Share via Whatsapp<v-icon class="pb-1 pl-1">mdi-whatsapp</v-icon></strong>
        </j-btn>
      </div>
      <p v-else>The details for your event have been updated.</p>
      <v-card-title>Event Details:</v-card-title>
      <v-simple-table class="std-text">
        <tbody>
          <tr>
            <td>Event Name</td>
            <td>{{ meetingDetails.title }}</td>
          </tr>
          <tr>
            <td>Contact Number</td>
            <td>{{ meetingDetails.contactNumber }}</td>
          </tr>
          <tr>
            <td>Event Type</td>
            <td>{{ meetingType }}</td>
          </tr>
          <tr v-if="meetingDetails.type === 'virtual'">
            <td>Meeting Link</td>
            <td>{{ meetingDetails.meetingLink }}</td>
          </tr>
          <tr>
            <td>Date & Time</td>
            <td>{{ meetingDateTime }}</td>
          </tr>
          <tr>
            <td>Maximum Attendees</td>
            <td>{{ meetingDetails.capacity }}</td>
          </tr>
          <tr v-if="meetingDetails.location !== '' && meetingDetails.type === 'physical'">
            <td colspan="2">
              <p class="mt-2">{{ meetingDetails.location }}</p>
              <google-map
                :center="mapLocation"
                :zoom="14"
                :options="{
                  zoomControl: false,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: true,
                  disableDefaultUi: false,
                  draggable: false,
                }"
                style="width: 100%; height: 300px; position: relative"
              >
                <google-marker :position="mapLocation" :clickable="true" :draggable="false"></google-marker>
              </google-map>
            </td>
          </tr>
          <tr v-if="meetingDetails.longitude && meetingDetails.latitude && meetingDetails.type === 'physical'">
            <td>Coordinates</td>
            <td>{{ meetingDetails.latitude }},{{ meetingDetails.longitude }}</td>
          </tr>
          <tr>
            <td>Information About Event</td>
            <td>{{ meetingDetails.notes }}</td>
          </tr>
        </tbody>
      </v-simple-table>
      <h3 class="mt-5">What Now?</h3>
      <p class="mt-2">Your event is ready to be shared with others.</p>
      <v-row>
        <v-col cols="12" align="center" justify="center">
          <j-btn secondary large to="/social/community-fitness">Go to events</j-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </j-card>
</template>

<script>
import { fancy_date } from "@/lib/fancy_date";
import { get_community_event_share_link } from "@/lib/whatsapp";

export default {
  name: "MeetingResult",
  props: {
    meetingDetails: {
      type: Object,
      default: null,
    },
    newMeetup: {
      type: Boolean,
      default: true,
    },
    createdID: {
      type: String,
      default: "",
    },
  },
  computed: {
    pageTitle() {
      return this.newMeetup ? "New Event Created" : "Event Updated";
    },
    meetingType() {
      return this.meetingDetails.type === "physical" ? "In Person" : "Virtual";
    },
    meetingDateTime() {
      return `${fancy_date(this.meetingDetails.date, "ddd, D MMMM YYYY", false)} ${this.meetingDetails.hour.padStart(
        2,
        "0"
      )}:${this.meetingDetails.minute.padStart(2, "0")}`;
    },
    mapLocation() {
      return {
        lat: parseFloat(this.meetingDetails.latitude),
        lng: parseFloat(this.meetingDetails.longitude),
      };
    },
    createdIDString() {
      return btoa("MeetupNode:" + this.createdID.toString());
    },
    whatsappShareUrl() {
      return get_community_event_share_link(this.createdIDString, this.meetingDetails.title, this.$appName);
    },
  },
};
</script>
