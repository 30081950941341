import gql from 'graphql-tag'

export const GUIDED_RUNS_QUERY = gql`
query guidedRuns($orderBy: String, $category: String, $categories: [String], $search: String) {
  guidedRuns(orderBy: $orderBy, category: $category, categories: $categories, search: $search)  {
    id
    name
    published
    file
    createdAt
    hidden
    openAccess
    useractivitySet {
      edges {
        node {
          id
          status
        }
      }
    }
  }
}
`


export const GUIDED_RUN_QUERY = gql`
query guidedRun($id: ID!) {
  guidedRun(id: $id) {
    id
    name
    published
    file
    openAccess
    createdAt
    category
    instructor {
      id
      userProfile {
        id
        profilePicture
      }
    }
    useractivitySet {
      edges {
        node {
          id
          status
        }
      }
    }
 }
}
`