<template>
  <div :class="qualifiedForPrize ? 'yellow-background' : 'white'" class="pa-1 prize-summary-pill">
    <span v-if="activePrize.entryCopy">
      <v-img
        v-if="activePrize.entryIcon"
        :src="activePrize.entryIcon"
        :lazy-src="activePrize.entryIcon"
        width="23"
        height="23"
        class="my-1"
      />
      <v-icon v-else :color="iconColor" :size="25">mdi-seal</v-icon>
    </span>
    <span v-if="activePrize.drawCopy">
      <v-img
        v-if="activePrize.drawIcon"
        :src="activePrize.drawIcon"
        :lazy-src="activePrize.drawIcon"
        width="23"
        height="23"
        class="my-1"
      />
      <v-icon v-else :color="iconColor" :size="24">mdi-gift</v-icon>
    </span>

    <span v-if="activePrize.rewardsCopy">
      <v-img
        v-if="activePrize.rewardsIcon"
        :src="activePrize.rewardsIcon"
        :lazy-src="activePrize.rewardsIcon"
        width="23"
        height="23"
        class="my-1"
      />
      <v-avatar v-else tile size="18">
        <img
          v-if="qualifiedForPrize"
          :src="require('@/assets/images/rewards-yellow.svg')"
          max-height="18"
          max-width="18"
        />
        <img v-else :src="require('@/assets/images/rewards.svg')" max-height="18" max-width="18" />
      </v-avatar>
    </span>
  </div>
</template>

<script>
export default {
  name: "PrizeSummaryPill",
  props: {
    qualifiedForPrize: {
      type: Boolean,
      default: false,
    },
    activePrize: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    iconColor() {
      return this.qualifiedForPrize ? "yellow darken-2" : "grey darken-1";
    },
  },
};
</script>

<style lang="scss" scoped>
.prize-summary-pill {
  min-width: 31px;
}
</style>
