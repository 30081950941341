var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"reply-card text-left px-4 py-1",class:_vm.message && _vm.sentBySelf(_vm.message) ? 'border-self' : 'border-other'},[(_vm.dismissible)?_c('v-btn',{staticClass:"float-right",attrs:{"icon":"","color":"text-grey"},on:{"click":function($event){return _vm.dismiss()}}},[_c('v-icon',[_vm._v("mdi-close")])],1):_vm._e(),_c('div',[_c('div',[_c('div',{staticClass:"font-weight-bold",class:_vm.message && _vm.sentBySelf(_vm.message) ? 'nutrition--text' : 'navy--text'},[(_vm.parent.user.userProfile)?_c('user-avatar',{attrs:{"user":_vm.parent.user,"profile-picture":_vm.parent.user.userProfile.profilePicture,"size":"35"}}):_vm._e(),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.parent.user.firstName)+" "+_vm._s(_vm.parent.user.lastName))])],1),(_vm.parent.text)?_c('p',{staticClass:"pointer my-2 pb-0",domProps:{"innerHTML":_vm._s(_vm.truncate(_vm.formatMessage(_vm.parent.text), 250))},on:{"click":function($event){_vm.openMessageDialog = true}}}):_vm._e()]),_c('div',[(_vm.getImages(_vm.parent.messageattachmentSet).length > 0)?_c('div',[_c('v-img',{staticClass:"rounded-lg pointer px-1",attrs:{"max-width":"80","max-height":"100","contain":"","src":_vm.getImageGallery(_vm.parent.messageattachmentSet, true)},on:{"click":function($event){_vm.openImage(_vm.getImageGallery(_vm.parent.messageattachmentSet, true))}}})],1):_vm._e(),(_vm.getVideos(_vm.parent.messageattachmentSet).length > 0)?_c('div',{staticClass:"pa-2"},[_c('v-icon',{staticClass:"pointer",attrs:{"large":""},on:{"click":function($event){_vm.openVideoDialog = true;
              _vm.selectedVideo = _vm.getVideos(_vm.parent.messageattachmentSet)[0];}}},[_vm._v("mdi-video")])],1):_vm._e(),(_vm.getAudioFiles(_vm.parent.messageattachmentSet).length > 0)?_c('div',{staticClass:"pa-2"},[_c('v-icon',{staticClass:"pointer",attrs:{"large":""},on:{"click":function($event){_vm.openAudioDialog = true;
              _vm.selectedAudio = _vm.getAudioFiles(_vm.parent.messageattachmentSet)[0];}}},[_vm._v(" mdi-microphone ")])],1):_vm._e(),(_vm.getPdfs(_vm.parent.messageattachmentSet).length > 0)?_c('div',{staticClass:"pa-2"},_vm._l((_vm.getPdfs(_vm.parent.messageattachmentSet)),function(pdf,pdfIndex){return _c('div',{key:'pdf-' + pdfIndex},[_c('a',{staticClass:"text-decoration-none",attrs:{"href":pdf.file,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"red","x-large":""}},[_vm._v("mdi-file-pdf-box")])],1)])}),0):_vm._e(),(_vm.getGifs(_vm.parent.messageattachmentSet).length > 0)?_c('div',{staticClass:"text-left pa-2"},_vm._l((_vm.getGifs(_vm.parent.messageattachmentSet)),function(gif,gifIndex){return _c('div',{key:'gif-' + gifIndex},[_c('v-img',{class:_vm.thread ? 'tiny-gif-size rounded-lg' : 'gif-size rounded-lg',attrs:{"src":gif.remoteFile,"lazy-src":gif.remoteFile,"contain":""}})],1)}),0):_vm._e()])])],1),(_vm.selectedAudio)?_c('v-dialog',{staticClass:"limited-height",attrs:{"max-width":"600","transition":"dialog-bottom-transition"},model:{value:(_vm.openAudioDialog),callback:function ($$v) {_vm.openAudioDialog=$$v},expression:"openAudioDialog"}},[_c('j-card',{staticClass:"pa-2 card-radius"},[_c('v-layout',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.openAudioDialog = false;
            _vm.selectedAudio = null;}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('vue-plyr',{attrs:{"options":{
          duration: _vm.selectedAudio.timeInSeconds,
          volume: 1,
        },"autoplay":""}},[_c('audio',{attrs:{"controls":"","crossorigin":"","playsinline":""}},[_c('source',{attrs:{"src":_vm.selectedAudio.file,"type":"audio/mp3"}})])])],1)],1):_vm._e(),(_vm.selectedVideo)?_c('v-dialog',{staticClass:"limited-height",attrs:{"max-width":"600","transition":"dialog-bottom-transition"},model:{value:(_vm.openVideoDialog),callback:function ($$v) {_vm.openVideoDialog=$$v},expression:"openVideoDialog"}},[_c('j-card',{staticClass:"pa-2 card-radius"},[_c('v-layout',[_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.openVideoDialog = false;
            _vm.selectedVideo = null;}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('vue-plyr',{attrs:{"options":{
          duration: _vm.selectedVideo.timeInSeconds,
        },"autoplay":""}},[_c('video',{attrs:{"controls":"","crossorigin":"","playsinline":""}},[_c('source',{attrs:{"size":"720","src":_vm.selectedVideo.file,"type":"video/mp4"}})])])],1)],1):_vm._e(),(_vm.selectedImage)?_c('v-dialog',{staticClass:"limited-height",attrs:{"max-width":"800","transition":"dialog-bottom-transition"},model:{value:(_vm.openImageDialog),callback:function ($$v) {_vm.openImageDialog=$$v},expression:"openImageDialog"}},[_c('j-card',{attrs:{"rounded":"lg"}},[_c('v-card-text',[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mr-n6",attrs:{"icon":""},on:{"click":function($event){_vm.openImageDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-img',{staticClass:"rounded-lg",attrs:{"src":_vm.selectedImage}})],1)],1)],1):_vm._e(),_c('v-dialog',{staticClass:"limited-height",attrs:{"max-width":"600","transition":"dialog-bottom-transition"},model:{value:(_vm.openMessageDialog),callback:function ($$v) {_vm.openMessageDialog=$$v},expression:"openMessageDialog"}},[_c('j-card',{attrs:{"rounded":"lg"}},[_c('v-card-text',[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"mr-n6",attrs:{"icon":""},on:{"click":function($event){_vm.openMessageDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('p',{staticClass:"my-2 pb-0",domProps:{"innerHTML":_vm._s(_vm.formatMessage(_vm.parent.text))}})])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }