export default {
  state: {
    deal: JSON.parse(localStorage.getItem("deal")) ?? {
      owner: {
        id: null,
        code: null
      },
      goals: [],
      sex: "Female",
      birthday: null,
      height: null,
      currentWeight: null,
      goalWeight: null,
      fitnessLevel: "BEGINNER",
      obstacle: null,
      otherObstacleText: null,
      education: null,
      otherText: null
    },
    preferredVariant: localStorage.getItem("preferredVariant") ?? null
  },
  mutations: {
    mutateOwner(state, { owner }) {
      state.deal.owner = owner;
      localStorage.setItem("deal", JSON.stringify(state.deal));
    },
    mutateGoals(state, { goals }) {
      state.deal.goals = goals;
      localStorage.setItem("deal", JSON.stringify(state.deal));
    },
    mutateSex(state, { sex }) {
      state.deal.sex = sex;
      localStorage.setItem("deal", JSON.stringify(state.deal));
    },
    mutateBirthday(state, { birthday }) {
      state.deal.birthday = birthday;
      localStorage.setItem("deal", JSON.stringify(state.deal));
    },
    mutateHeight(state, { height }) {
      state.deal.height = height;
      localStorage.setItem("deal", JSON.stringify(state.deal));
    },
    mutateCurrentWeight(state, { currentWeight }) {
      state.deal.currentWeight = currentWeight;
      localStorage.setItem("deal", JSON.stringify(state.deal));
    },
    mutateGoalWeight(state, { goalWeight }) {
      state.deal.goalWeight = goalWeight;
      localStorage.setItem("deal", JSON.stringify(state.deal));
    },
    mutateFitnessLevel(state, { fitnessLevel }) {
      state.deal.fitnessLevel = fitnessLevel;
      localStorage.setItem("deal", JSON.stringify(state.deal));
    },
    mutateObstacle(state, { obstacle }) {
      state.deal.obstacle = obstacle;
      localStorage.setItem("deal", JSON.stringify(state.deal));
    },
    mutateOtherObstacleText(state, { otherObstacleText }) {
      state.deal.otherObstacleText = otherObstacleText;
      localStorage.setItem("deal", JSON.stringify(state.deal));
    },
    mutateEducation(state, { education }) {
      state.deal.education = education;
      localStorage.setItem("deal", JSON.stringify(state.deal));
    },
    mutateOtherText(state, { otherText }) {
      state.deal.otherText = otherText;
      localStorage.setItem("deal", JSON.stringify(state.deal));
    },
    deleteDeal(state) {
      state.deal = {};
      localStorage.removeItem("deal");
    },
    mutatePreferredVariant(state, { preferredVariant }) {
      state.preferredVariant = preferredVariant;
      localStorage.setItem("preferredVariant", preferredVariant);
    },
    deletePreferredVariant(state) {
      state.preferredVariant = null;
      localStorage.removeItem("preferredVariant");
    }
  },
  actions: {
    updateOwner({ commit }, owner) {
      commit("mutateOwner", { owner });
    },
    updateGoals({ commit }, goals) {
      commit("mutateGoals", { goals });
    },
    updateSex({ commit }, sex) {
      commit("mutateSex", { sex });
    },
    updateBirthday({ commit }, birthday) {
      commit("mutateBirthday", { birthday });
    },
    updateHeight({ commit }, height) {
      commit("mutateHeight", { height });
    },
    updateCurrentWeight({ commit }, currentWeight) {
      commit("mutateCurrentWeight", { currentWeight });
    },
    updateGoalWeight({ commit }, goalWeight) {
      commit("mutateGoalWeight", { goalWeight });
    },
    updateFitnessLevel({ commit }, fitnessLevel) {
      commit("mutateFitnessLevel", { fitnessLevel });
    },
    updateObstacle({ commit }, obstacle) {
      commit("mutateObstacle", { obstacle });
    },
    updateOtherObstacleText({ commit }, otherObstacleText) {
      commit("mutateOtherObstacleText", { otherObstacleText });
    },
    updateEducation({ commit }, education) {
      commit("mutateEducation", { education });
    },
    updateOtherText({ commit }, otherText) {
      commit("mutateOtherText", { otherText });
    },
    clearDeal({ commit }) {
      commit("deleteDeal");
    },
    updatePreferredVariant({ commit }, preferredVariant) {
      commit("mutatePreferredVariant", { preferredVariant });
    },
    clearPreferredVariant({ commit }) {
      commit("deletePreferredVariant");
    },
  },
  getters: {
    deal: (state) => state.deal,
    dealOwner: (state) => state.deal?.owner,
    dealGoals: (state) => state.deal?.goals,
    sex: (state) => state.deal?.sex,
    birthday: (state) => state.deal?.birthday,
    height: (state) => state.deal?.height,
    currentWeight: (state) => state.deal?.currentWeight,
    goalWeight: (state) => state.deal?.goalWeight,
    fitnessLevel: (state) => state.deal?.fitnessLevel,
    obstacle: (state) => state.deal?.obstacle,
    otherObstacleText: (state) => state.deal?.otherObstacleText,
    education: (state) => state.deal?.education,
    otherText: (state) => state.deal?.otherText,
    preferredVariant: (state) => state.preferredVariant
  },
};
