<template>
  <div>
    <div class="mb-4">
      <j-emoji-textarea
        v-model="commentText"
        :disabled="saving"
        placeholder="Add a comment"
        auto-grow
        autofocus
        outlined
        dense
        rows="1"
        row-height="15"
        :include-mentions="true"
        :mention-values-lookup="mentionSearch"
        @keydown.enter.shift.exact="postComment()"
      />
      <div class="d-flex justify-end mt-n6">
        <j-btn v-if="commentText" tertiary small narrow secondary @click="postComment()" class="mt-2 mx-2 px-0">
          Comment
        </j-btn>
      </div>
    </div>

    <template v-if="$apollo.loading">
      <v-skeleton-loader v-for="count in commentCount" :key="count" type="list-item-two-line" class="text-left" />
    </template>
    <template v-else>
      <comment
        v-for="comment in comments"
        :key="comment.node.id"
        class="d-flex mb-4"
        :postId="postId"
        :comment="comment.node"
        :highlight-colour="highlightColour"
        @comment-update="emitCommentSaved($event)"
      />
    </template>
  </div>
</template>

<script>
import { CREATE_COMMENT_MUTATION } from "@/graphql/mutations/group";
import { COMMENTS_QUERY } from "@/graphql/queries/post";
import { decodeId } from "@/lib/string";
import Comment from "@/components/groups/interactions/Comment";
import Mentions from "@/mixins/Mentions";

export default {
  name: "PostComment",
  mixins: [Mentions],
  props: {
    postId: {
      type: String,
      required: true,
    },
    // we can pass an array of Ids. For each of these IDs, the post container will show
    // a bit differently. Border colour etc. i.e we want to highlight any posts the instructor makes different to normal users
    featuredUserIds: {
      type: Array,
      required: false,
    },
    highlightColour: {
      type: String,
      default: "#211256",
    },
    commentCount: {
      type: Number,
      default: 1,
    },
  },
  components: {
    // UserAvatar,
    Comment: Comment,
  },
  data() {
    return {
      replies: {},
      commentText: null,
      saving: false,
      comments: [],
    };
  },
  computed: {
    encodedPostId() {
      if (!isNaN(parseInt(this.postId))) {
        return btoa(`CommentNode: ${this.postId}`);
      }
      return this.postId;
    },
  },
  methods: {
    getId(idString) {
      return decodeId(idString);
    },
    emitCommentSaved(type) {
      this.$emit("commentUpdate", {
        postId: this.postId,
        type: type,
      });
    },
    async postComment() {
      this.saving = true;
      await this.$apollo.mutate({
        mutation: CREATE_COMMENT_MUTATION,
        variables: {
          text: this.commentText,
          postId: this.getId(this.postId),
        },
      });

      await this.$apollo.queries.comments.refetch({
        after: this.after,
        postId: this.encodedPostId,
      });
      this.emitCommentSaved("add");
      this.saving = false;
      this.commentText = null;
    },
  },
  apollo: {
    comments: {
      query: COMMENTS_QUERY,
      variables() {
        return {
          hideChildComments: true,
          after: this.after,
          postId: this.encodedPostId,
        };
      },
      // network-only, otherwise doesn't update the listing card nicely on update
      fetchPolicy: "network-only",
      update: (data) => data.comments.edges,
    },
  },
};
</script>

<style scoped lang="scss">
.highlight-post {
  border-left: 5px solid;
}
</style>
