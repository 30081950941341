import axios from "axios";
import { CLOUDINARY_URL, CLOUDINARY_UPLOAD_PRESET_CHAT, CLOUDINARY_UPLOAD_PRESET_POST } from "@/lib/cloudinary";

export default {
  data() {
    return {
      uploadPercentCompleted: 0,
    };
  },
  methods: {
    async uploadFileToCloudinary(file, directory = "chat_attachments") {
      const preset = directory == "chat_attachments" ? CLOUDINARY_UPLOAD_PRESET_CHAT : CLOUDINARY_UPLOAD_PRESET_POST;
      const customAxios = axios.create();
      delete customAxios.defaults.headers.common["Authorization"];

      let formData = new FormData();
      formData.append("upload_preset", preset);
      formData.append("folder", directory);
      formData.append("file", file);

      const result = await customAxios({
        method: "post",
        url: CLOUDINARY_URL,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
        onUploadProgress: (progressEvent) => {
          this.uploadPercentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        },
      });
      return result.data;
    },
  },
};
