<template>
  <div>
    <v-card-text v-if="recipe.description" class="pt-0">
      <j-alert v-if="clientMade && !preview" type="success">
        Congrats on uploading your recipe. You can now find your recipe in the
        <router-link to="/recipes" class="success--text font-weight-bold">recipe library</router-link>. Your recipe is
        completely hidden from everyone else unless you want to share it using the
        <v-icon color="navy">mdi-link-variant</v-icon> button above. You can also edit your recipe any time using the
        <v-icon color="grey darken-3">mdi-pencil</v-icon> button above.
      </j-alert>
      <span class="with-line-breaks">{{ recipe.description }} </span>
    </v-card-text>

    <v-card-text v-if="clientMade" class="text-right grey-navy--text pt-0">
      <div v-if="recipe.owner">
        Added by <strong>{{ recipe.owner.firstName }} {{ recipe.owner.lastName }}</strong>
      </div>
      <div>Added: {{ recipe.createdAt | moment("ddd, DD MMM YYYY") }}</div>
      <div v-if="updated">Last Updated: {{ recipe.updatedAt | moment("ddd, DD MMM YYYY") }}</div>
    </v-card-text>

    <div v-else>
      <v-card-subtitle class="py-0">
        <h4>Portion size:</h4>
      </v-card-subtitle>
      <v-card-actions class="portion-select">
        <j-select v-model="portionSize" dense :items="portionSizes" />
      </v-card-actions>

      <macro-card class="ml-4 mt-n6 mb-6" :macros="selectedMacros" />
    </div>

    <slot name="media-content" />
    <nutrition-preference-alert class="mx-6" />

    <v-card-subtitle class="pt-2">
      <div class="d-flex flex-wrap">
        <h4>Ingredients</h4>
        <!-- removing this. doesn't really work/make sense. -->
        <!-- <v-btn-toggle
          v-model="ingredientMultiplier"
          mandatory
          rounded
          class="ml-4"
          @change="updateIngredientMultiplier($event)"
        >
          <v-btn small>
            1X
          </v-btn>
          <v-btn small>
            2X
          </v-btn>
          <v-btn small>
            3X
          </v-btn>
          <v-btn small>
            4X
          </v-btn>
        </v-btn-toggle> -->
      </div>
    </v-card-subtitle>
    <v-card-text class="pb-2">
      <div v-for="ing in getIngredients" :key="ing.id">
        <p v-if="ing.quantity > 0">
          {{ ing.formattedQuantity }} {{ ing.measurement }} {{ ing.preparation }} {{ ing.name }}
        </p>
      </div>
    </v-card-text>

    <div v-if="recipe.directions">
      <v-card-subtitle class="pt-0">
        <h4>Directions</h4>
      </v-card-subtitle>
      <v-card-text>
        <p v-html="recipe.directions" class="directions" />
      </v-card-text>
    </div>
  </div>
</template>

<script>
import IngredientsMixin from "@/mixins/nutrition/IngredientsMixin";
import MacroCard from "@/components/nutritionblocks/cards/MacroCard";
import NutritionPreferenceAlert from "@/components/nutritionblocks/NutritionPreferenceAlert";

export default {
  name: "ViewRecipe",
  mixins: [IngredientsMixin],
  components: {
    MacroCard,
    NutritionPreferenceAlert,
  },
  props: {
    recipe: {
      type: Object,
      required: true,
    },
    clientMade: {
      type: Boolean,
      default: false,
    },
    preview: {
      type: Boolean,
      default: false,
    },
    portion: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      portionSize: null,
    };
  },
  created() {
    this.portionSize = this.portion;
    this.ingredientMultiplier = parseInt(this.$store.getters.ingredientMultiplier);
  },
  computed: {
    selectedMacros() {
      const macros = {};
      if (!this.portionSize || !this.recipe.recipemacroSet) {
        return null;
      }
      const selectedMacros = this.recipe.recipemacroSet.filter(
        (el) => el.servingSize == this.portionSize.toUpperCase()
      );
      selectedMacros.forEach((macro) => {
        let quantity = macro.quantity;
        if (quantity >= 1 || quantity == 0.0) {
          quantity = parseFloat(quantity).toFixed(1);
        }
        macros[macro.type] = quantity;
      });
      return macros;
    },
    updated() {
      return (
        this.$moment(this.recipe.updatedAt).format("YYYY-MM-DD") !=
        this.$moment(this.recipe.createdAt).format("YYYY-MM-DD")
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.missing-preferences-button {
  /** overwrite the global style in components.scss to move the button down a little bit more */
  top: 8px;
}

.with-line-breaks {
  white-space: pre-wrap;
}
.directions {
  white-space: pre-wrap;
}
.directions ::v-deep ol li {
  margin-bottom: 10px;
}
.portion-select {
  max-width: 340px;
}
</style>
