import gql from "graphql-tag";

export const USER_TAGS_MUTATION = gql`
mutation setUserTags($category: String!, $tags: [String]!){
  setUserTags(category: $category, tags: $tags){
    success
    error
  }
}`

