<template>
  <j-main-banner-container>
    <div class="pa-6 text-left">
      <div class="d-flex justify-center">
        <v-img max-width="250" :src="require('@/assets/images/tada.png')" />
      </div>
      <h2 class="navy--text mt-6">{{ $store.getters.user.firstName }}, welcome to the 1-on-1 Coaching Program!</h2>
      <p class="mt-4">
        Thank you for submitting your information and congratulations for taking the first steps to commmitting to
        improving your wellbeing.
      </p>

      <p class="font-weight-bold">We can't wait to be a part of your journey!</p>

      <div v-if="coach">
        <h3 class="navy--text mt-6">Meet your Coach</h3>

        <div class="d-flex mt-4">
          <div>
            <v-avatar color="blue-background" size="80">
              <img :src="coach.userProfile.profilePicture" />
            </v-avatar>
          </div>
          <div class="mt-1 ml-5">
            <h3 class="pink--text">{{ coach.firstName }} {{ coach.lastName }}</h3>
            <p>{{ coach.userProfile.biography }}</p>
          </div>
        </div>

        <h3 class="navy--text mt-2">What's next?</h3>

        <div class="d-flex mt-4">
          <div class="mr-2"><v-icon color="pink">mdi-arrow-right-circle-outline</v-icon></div>
          <div class="p-text">
            Book a time with your coach for your kickoff meeting, so you can get setup and start moving towards your
            goals.
          </div>
        </div>

        <div class="d-flex mt-4">
          <div class="mr-2"><v-icon color="pink">mdi-arrow-right-circle-outline</v-icon></div>
          <div class="p-text">
            Start getting comfortable and browse the {{ $appName }} App for workouts, recipes and so much more.
          </div>
        </div>

        <div v-if="checkinBookingLink" class="d-flex justify-center mt-4">
          <j-btn secondary :href="checkinBookingLink">{{ checkInTitleCopy }}</j-btn>
        </div>
      </div>

      <!-- no coach -->
      <div v-else>
        <h3 class="navy--text mt-2">What's next?</h3>

        <div class="d-flex mt-4">
          <div class="mr-2"><v-icon color="pink">mdi-arrow-right-circle-outline</v-icon></div>
          <div class="p-text">
            We are finding the perfect coach for you. They will be in touch in the next few days.
          </div>
        </div>

        <div class="d-flex mt-4">
          <div class="mr-2"><v-icon color="pink">mdi-arrow-right-circle-outline</v-icon></div>
          <div class="p-text">
            Start getting comfortable and browse the {{ $appName }} App for workouts, recipes and so much more.
          </div>
        </div>

        <div class="d-flex justify-center mt-4">
          <j-btn secondary to="/today">Start Exploring</j-btn>
        </div>
      </div>
    </div>
  </j-main-banner-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MedicalAssessmentThankYou",
  computed: {
    ...mapGetters(["checkinRequired", "checkinBookingRequired", "checkinBookingLink", "checkinId", "checkinIsInitial"]),
    coach() {
      return this.$store.getters.user?.userProfile?.coach;
    },
    checkInTitleCopy() {
      if (this.checkinBookingLink && this.checkinBookingLink === "/checkins/new/coaching")
        return "Complete your check-in";
      return "Book your Meeting";
    },
    checkInCopy() {
      if (this.checkinIsInitial)
        return `Book a time with your coach for your kickoff meeting, so you can get setup and start moving towards your
            goals.`;
      if (this.checkinBookingLink && this.checkinBookingLink === "/checkins/new/coaching")
        return `Complete your measurements and progress assessment.`;

      return `Book a progress check with your coach`;
    },
  },
};
</script>
