<template>
  <j-card rounded="lg">
    <v-toolbar class="mb-6">
      <v-toolbar-title> Choose your coach</v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="close()">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <div>
        <v-row>
          <v-col cols="12" v-for="coach in coaches" :key="coach.user.id" class="mt-4">
            <choose-coach-listing-card
              @choose="chooseCoach"
              :coach-profile="coach"
              :is-selected="isSelected(coach.user.id)"
              class="mb-4"
            />
          </v-col>
        </v-row>
      </div>

      <div class="d-flex justify-space-between">
        <j-btn @click="close()" tertiary>Cancel</j-btn>
        <j-btn class="ml-2" primary :disabled="confirmDisabled" @click="confirm">Confirm</j-btn>
      </div>
    </v-card-text>
  </j-card>
</template>

<script>
import { decodeId } from "@/lib/string";
import StaffMixin from "@/mixins/StaffMixin";
import ChooseCoachListingCard from "@/components/coach/ChooseCoachListingCard";
import { UPDATE_SUBSCRIPTION_MUTATION } from "@/graphql/mutations/account";

export default {
  name: "ChooseCoachDialog",
  mixins: [StaffMixin],
  components: { ChooseCoachListingCard },
  data() {
    return {
      userType: "Coach",
      selectedCoachId: null,
    };
  },

  computed: {
    confirmDisabled() {
      return !this.selectedCoachId;
    },
  },
  methods: {
    isSelected(userId) {
      return decodeId(this.selectedCoachId) == decodeId(userId);
    },
    chooseCoach(userId) {
      this.selectedCoachId = decodeId(userId);
    },
    async confirm() {
      await this.$apollo.mutate({
        mutation: UPDATE_SUBSCRIPTION_MUTATION,
        variables: {
          input: {
            coachId: this.selectedCoachId,
          },
        },
        refetchQueries: ["accountQuery"],
      });
      this.$emit("closed");
    },
    close() {
      this.$emit("closed");
    },
  },
};
</script>
