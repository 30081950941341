<template>
  <form
    :action="redirectForm.url"
    method="post"
    ref="redirect_to_3d_secure"
  >
    <div
      v-for="(param, index) in redirectForm.parameters"
      :key="`param-${index}`"
    >
      <input
        type="text"
        hidden="hidden"
        :name="param.name"
        v-model="redirectForm.parameters[index].value"
      />
    </div>
  </form>
</template>

<script>
export default {
  name: "SecureRedirectForm",
  props: {
    redirectForm: {
      type: Object,
      default: null
    }
  },
  methods: {
    submit() {
      this.$nextTick(() => {
        this.$refs.redirect_to_3d_secure.submit();
      });
    }
  }
}
</script>