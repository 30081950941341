<template>
<div>
  <v-img v-if="image" :lazy-src="image" :src="image" @click="openImage(image)" class="pointer align-self-center" width="100%" />
  <v-tooltip v-else top>
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-bind="attrs" v-on="on"> texture </v-icon>
    </template>
    <span>You did not upload an image with {{ firstLastAssessment }} completed check-in</span>
  </v-tooltip>
  
  <!-- view image dialog -->
    <v-dialog
      v-if="selectedImage"
      v-model="openImageDialog"
      width="800"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <j-card rounded="lg">
        <v-card-text>
          <div class="d-flex justify-end">
            <v-btn icon @click="openImageDialog = false" class="mr-n6">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-img class="rounded-lg" :src="selectedImage" />
        </v-card-text>
      </j-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "ProgressImageBlock",
  data() {
    return {
      openImageDialog: false,
      selectedImage: null,
    };
  },
  props: ["image", "firstAssessment"],
  computed: {
    firstLastAssessment() {
      return this.firstAssessment ? "the first" : this.firstAssessment === false ? "the last" : "this";
    },
  },
  methods: {
    openImage(image) {
      this.selectedImage = image;
      this.openImageDialog = true;
    },
  }
};
</script>
