<template>
  <div>
    <j-card rounded="lg" class="mr-3 my-1 j-elevated-2" :to="recipeUrl">
      <v-img :src="image" :lazy-src="image" :alt="mealTitle" :height="150" class="grey">
        <div v-if="showActionButtons && !isCustom" class="d-flex flex-wrap icons rounded pa-1 fill-height">
          <div v-if="!blank" class="d-flex px-1 flex flex-wrap mt-auto">
            <j-btn small super-narrow flatten @click.prevent="showIngredientsModal" class="ma-1">
              <v-icon small>mdi-format-list-bulleted</v-icon>
              Preview
            </j-btn>
            <v-spacer />
            <j-btn v-if="swappable" small super-narrow flatten @click.prevent="showSwapRecipes = true" class="ma-1">
              <v-icon>mdi-rotate-left</v-icon>
              Swap
            </j-btn>
            <j-btn
              v-else-if="searchSwappable"
              small
              super-narrow
              flatten
              @click.prevent="showSwapModal = true"
              class="ma-1"
            >
              <v-icon>mdi-rotate-left</v-icon>
              Swap
            </j-btn>
            <j-btn small super-narrow flatten @click.prevent="updateRecipe(remove=true)" class="ma-1">
              <v-icon small>mdi-trash-can</v-icon>
              Remove
            </j-btn>
            <v-spacer />
            <j-btn v-if="swapped" small super-narrow flatten @click.prevent="updateRecipe()" class="ma-1">
              <v-icon small>mdi-shape-square-plus</v-icon>
              Reset
            </j-btn>
          </div>
          <div v-else-if="swapped" class="d-flex pa-1 flex flex-wrap mt-auto">
            <j-btn small super-narrow flatten @click.prevent="updateRecipe()" class="ma-1">
              <v-icon small>mdi-shape-square-plus</v-icon>
              Reset
            </j-btn>
          </div>
        </div>
      </v-img>
      <v-card-text class="pt-1 pb-2 text-left">
        <div class="d-flex">
          <j-gradient-text :gradient="['yellow', 'green']" class="text-uppercase">
            <strong v-if="showMealType">{{ recipe.meal | humanizeMeal }}</strong>
            <strong v-else>{{ meal | humanizeMeal }}</strong>
          </j-gradient-text>
          <v-btn
            v-if="showActionButtons && loggable"
            medium
            icon
            elevation="2"
            class="pointer d-flex white ml-auto mt-n4 mr-n2"
            @click.prevent="showNutritionLog = true"
          >
            <v-icon :color="logged ? 'green' : 'mid-grey'">
              mdi-plus-circle-outline
            </v-icon>
          </v-btn>
        </div>
        <v-card-subtitle class="pa-0 navy--text three-lines">
          {{ mealTitle }}
        </v-card-subtitle>
      </v-card-text>
      <v-btn
        v-if="recipe && getMissingPreferences(recipe).length > 0"
        medium
        icon
        elevation="2"
        class="missing-preferences-button white"
      >
        <v-icon class="warning--text" @click.prevent="openMissingPrefs = true">mdi-alert-circle-outline</v-icon>
      </v-btn>
    </j-card>

    <!-- Dialog for ingredient warning -->
    <v-dialog
      v-if="getMissingPreferences(recipe).length > 0"
      v-model="openMissingPrefs"
      width="600"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <missing-preferences-dialog
        :missing-preferences="getMissingPreferences(recipe)"
        @closed="openMissingPrefs = false"
      />
    </v-dialog>

    <!-- Dialog to log nutrition -->
    <v-dialog
      v-if="showNutritionLog"
      v-model="showNutritionLog"
      width="600"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <nutrition-log
        :blockId="blockId"
        :recipe="recipe"
        :meal="meal"
        :date="date"
        :logged="logged"
        @closed="showNutritionLog = false"
      />
    </v-dialog>

    <!-- Dialog to show ingredients -->
    <v-dialog v-model="showIngredients" width="600" transition="dialog-bottom-transition" class="limited-height">
      <j-card rounded="lg">
        <v-toolbar class="mb-2">
          <v-toolbar-title>
            <v-card-title class="secondary--text">
              <h2>{{ recipe.title }}</h2>
            </v-card-title>
            <v-card-subtitle>
              <j-gradient-text :gradient="['yellow', 'green']" class="text-uppercase">
                <strong>{{ mealSubtitle }}</strong>
              </j-gradient-text>
            </v-card-subtitle>
          </v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="showIngredients = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-skeleton-loader v-if="$apollo.loading" type="list-item-three-line" width="50%" />
        <div v-else>
          <v-card-text>
            <div class="ml-4">
              <j-btn large :to="recipeUrl" class="mb-6">See full recipe → </j-btn>
              <h3 class="grey-navy--text">Ingredients</h3>
              <span v-if="!recommendedPortion" class="mid-grey--text">for Medium portion</span>
              <span v-else class="mid-grey--text">for {{ recommendedPortion }} (recommended for you)</span>
            </div>
            <v-card-text>
              <div v-for="ing in ingredients" :key="ing.id">
                <p v-if="ing.quantity > 0">
                  {{ ing.formattedQuantity }} {{ ing.measurement }} {{ ing.preparation }} {{ ing.name }}
                </p>
              </div>
            </v-card-text>
          </v-card-text>
        </div>
      </j-card>
    </v-dialog>

    <!-- Dialog for swapping recipes -->
    <v-dialog
      v-if="swappable"
      v-model="showSwapRecipes"
      width="600"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <recipe-swap-out-confirm
        :recipe="recipe"
        :originalRecipe="originalRecipe"
        :meal="meal"
        :date="date"
        @close="showSwapRecipes = false"
        @swapped="
          showSwapRecipes = false;
          $router.go(-1);
        "
      />
    </v-dialog>

    <!-- Dialog for searching for recipe to swap with  -->
    <v-dialog
      v-if="showSwapModal"
      v-model="showSwapModal"
      width="500"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <recipe-swap-search :recipe="recipe" :meal="meal" :date="date" :block-id="blockId" @close="showSwapModal = false" />
    </v-dialog>
  </div>
</template>

<script>
import { RECIPE_QUERY } from "@/graphql/queries/nutritionblock";
import { REMOVE_RECIPE_MUTATION, RESET_RECIPE_MUTATION } from "@/graphql/mutations/nutritionblock";
import { decodeId } from "@/lib/string";
import NutritionLog from "@/components/nutritionblocks/dialogs/NutritionLog";
import IngredientsMixin from "@/mixins/nutrition/IngredientsMixin";
import UserNutritionPreferencesMixin from "@/mixins/nutrition/UserNutritionPreferencesMixin";
import RecipeSwapSearch from "@/components/nutritionblocks/dialogs/RecipeSwapSearch";
import RecipeSwapOutConfirm from "@/components/nutritionblocks/dialogs/RecipeSwapOutConfirm";

export default {
  name: "RecommendedRecipeBlock",
  mixins: [IngredientsMixin, UserNutritionPreferencesMixin],
  props: {
    recipe: {
      type: Object,
      required: false,
    },
    // if we are swapping, this is the original recipe we are swapping out
    originalRecipe: {
      type: Object,
      required: false,
    },
    meal: {
      type: String,
      required: false,
    },
    showMealType: {
      type: Boolean,
      default: false,
    },
    blockId: {
      type: String,
      required: false,
    },
    date: {
      type: String,
      required: false,
    },
    log: {
      type: [Object],
      required: false,
    },
    swappable: {
      type: Boolean,
      default: false,
    },
    searchSwappable: {
      type: Boolean,
      default: false,
    },
    loggable: {
      type: Boolean,
      default: true,
    },
    swapped: {
      type: Boolean,
      default: false,
    },
    showActionButtons: {
      type: Boolean,
      default: true,
    },
    
  },
  components: {
    NutritionLog,
    RecipeSwapSearch,
    RecipeSwapOutConfirm,
  },
  data() {
    return {
      showNutritionLog: false,
      showIngredients: false,
      showSwapRecipes: false,
      showSwapModal: false,
      ingredients: null,
      loading: false,
    };
  },
  computed: {
    image() {
      if (this.isCustom && !this.log.image) {
        return require("@/assets/images/grey.png");
      }
      return this.recipe.image;
    },
    isCustom() {
      return this.log && this.log.customTitle;
    },
    mealTitle() {
      if (this.isCustom) {
        return this.log.customTitle;
      }
      return this.recipe.title;
    },
    recipeUrl() {
      if (this.isCustom || !this.recipe || this.recipe.id == 0) {
        return null;
      }
      let url = `/recipes/${decodeId(this.recipe.id)}`;
      if (this.blockId && this.date && this.showActionButtons) {
        url += `?bid=${decodeId(this.blockId)}&t=${this.meal}&d=${this.date}&l=${this.logged}`;
      }
      return url;
    },
    logged() {
      return !!this.log?.id;
    },
    loggedImage() {
      return this.log?.image;
    },
    blank() {
      return this.recipe?.id == 0;
    }
  },
  methods: {
    showIngredientsModal() {
      this.showIngredients = true;
    },
    updateRecipe(remove = false) {
      this.$apollo.mutate({
        mutation: remove ? REMOVE_RECIPE_MUTATION : RESET_RECIPE_MUTATION,
        variables: {
          date: this.date,
          meal: this.meal,
        },
        refetchQueries: ["recipes", "recipesForWeek", "userSwappedRecipes", "recipesForDay"],
      });
    },
  },
  apollo: {
    ingredients: {
      query: RECIPE_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          id: decodeId(this.recipe.id),
        };
      },
      update(data) {
        this.ingredientMultiplier = this.$store.getters.ingredientMultiplier;
        return this.setIngredients(data.recipe?.recipeingredientSet, this.ingredientMultiplier);
      },
      skip() {
        return !this.showIngredients;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.j-card {
  .v-image .v-responsive__content .icons .j-btn {
    opacity: 0.8;
  }

  &:hover .v-image .v-responsive__content .icons .j-btn:hover {
    opacity: 1;
  }

  .three-lines {
    overflow: hidden;
    height: 65px;

    @supports (-webkit-line-clamp: 3) {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
}

.swap-recipes-modal.j-card .v-card__title {
  white-space: break-spaces;
}
</style>
