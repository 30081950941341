<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title class="headline grey lighten-2">
        Are you sure you want to delete?
      </v-card-title>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="secondary" @click="cancel">
          Cancel
        </v-btn>
        <v-spacer />
        <v-btn
          color="background-grey"
          class="secondary--text pa-2"
          @click="confirm"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DeleteConfirm",
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
    };
  },
  methods: {
    open() {
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    confirm() {
      this.resolve(true);
      this.dialog = false;
    },
    cancel() {
      this.resolve(false);
      this.dialog = false;
    },
  },
};
</script>
