<template>
  <j-card class="j-elevated-2" :class="small ? 'py-1' : 'py-3'" :width="cardWidth" @click="$emit('navigate')">
    <v-img :src="imageSrc" class="rounded-t-xl blue-background" :class="small ? 'mx-2' : 'mx-3'" :aspect-ratio="16 / 9">
      <v-overlay
        v-if="overlayType === 'PLAY' || overlayType === 'LISTEN'"
        absolute
        opacity="0"
        class="j-workout-overlay"
      >
        <v-img :src="require('@/assets/images/playnavy.png')" class="overlay-image mx-auto" :aspect-ratio="1 / 1" />
      </v-overlay>
      <v-overlay v-if="overlayType === 'DONE'" absolute opacity="0" class="j-workout-overlay">
        <v-img :src="require('@/assets/images/tickred.png')" class="overlay-image mx-auto" :aspect-ratio="1 / 1" />
      </v-overlay>
      <v-overlay v-if="overlayType === 'READ'" absolute opacity="0" class="j-workout-overlay">
        <v-icon size="100">mdi-file-link</v-icon>
      </v-overlay>
      <v-chip v-if="chipText" color="yellow" small class="top-left white--text font-weight-bold px-3">
        <span>{{ chipText.toUpperCase() }}</span>
      </v-chip>
    </v-img>
    <arrow-row
      v-if="showArrows"
      :color="color"
      :left-arrow="leftArrow"
      :right-arrow="rightArrow"
      @arrow-clicked="$emit('arrow-clicked', $event)"
    />
    <div
      v-if="showArrows && progress"
      class="mb-2 mt-n5 mx-3"
      :class="{ 'ml-9': this.leftArrow, 'mr-9': this.rightArrow }"
    >
      <v-progress-linear
        :color="color"
        height="8"
        :value="progress"
        :class="{ 'rounded-bl-lg': !this.leftArrow, 'rounded-br-lg': !this.rightArrow }"
      />
    </div>

    <arrow-row-content
      class="pt-2"
      :instructor-profile-pic="instructorProfilePic"
      :small="small"
      :title="title"
      :instructor-name="instructorName"
      :duration="duration"
      :button-text="buttonText"
      :topHeading="topHeading"
      :hasDot="hasDot"
      @navigate="$emit('navigate')"
    />
  </j-card>
</template>

<script>
import ArrowRow from "@/components/workoutblocks/ArrowRow";
import ArrowRowContent from "@/components/workoutblocks/ArrowRowContent";

export default {
  name: "JWorkoutCard",
  components: {
    ArrowRow,
    ArrowRowContent,
  },
  props: {
    color: {
      type: String,
      default: "pink",
    },
    chipText: {
      type: String,
      default: null,
    },
    imageSrc: {
      type: String,
      required: true,
    },
    instructorProfilePic: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    instructorName: {
      type: String,
      required: false,
    },
    duration: {
      type: String,
      required: false,
    },
    showArrows: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Number,
      required: false,
    },
    leftArrow: {
      type: Boolean,
      default: true,
    },
    rightArrow: {
      type: Boolean,
      default: true,
    },
    overlayTick: {
      type: Boolean,
      default: false,
    },
    overlayPlay: {
      type: Boolean,
      default: false,
    },
    overlayType: {
      type: String,
      required: false,
      default: "PLAY",
    },
    cardWidth: {
      type: String,
      required: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    buttonText: {
      type: String,
      required: false,
    },
    topHeading: {
      type: String,
      required: false,
    },
    hasDot: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.top-left {
  position: absolute;
  top: 10px;
  left: 10px;
}
</style>

<style lang="scss">
.j-workout-overlay .v-overlay__content {
  width: 100%;
  .overlay-image {
    width: 20%;
  }
}
</style>
