<template>
  <j-banner-container back-link="/today" :header="isComplete ? 'Well done!' : 'Start here'" :gradient="['green-background', 'yellow-background']">
    
    <div v-if="isComplete" class="mt-4">
      <j-btn narrow tertiary @click="upsertSetting()"
        >Close
        <v-icon class="ml-2">mdi-check-circle-outline</v-icon>
      </j-btn>
    </div>
    
    <div class="d-flex align-center mt-4 ml-6">
      <div class="p-text mr-4">{{ onboardingCompletedCount }} of {{ onboardingTotalCount }} completed</div>

      <v-progress-linear
        color="nutrition darken-1"
        background-color="nutrition lighten-4"
        class="onboarding-progress"
        rounded
        :value="progressValue"
        height="12"
      ></v-progress-linear>
    </div>

    <j-card class="j-elevated-2 pa-6 ma-4">
      <v-row>
        <todo-listing-card
          done
          :todo="{
            title: signUpTitle,
          }"
        />

        <template v-if="userTodos && userTodos.length > 0">
          <todo-listing-card v-for="todo in userTodos" :key="todo.id" :todo="todo" :done="todo.done" />
        </template>
      </v-row>
    </j-card>
  </j-banner-container>
</template>

<script>
import { extractNodes } from "@/lib/array";
import { decodeId } from "@/lib/string";
import { UPSERT_MY_SETTINGS_MUTATION } from "@/graphql/mutations/account";
import TodoMixin from "@/mixins/TodoMixin";
import TodoListingCard from "@/components/todo/TodoListingCard";

export default {
  name: "TodoOnboardingView",
  mixins: [TodoMixin],
  components: {
    TodoListingCard,
  },
  computed: {
    userTodos() {
      if (!this.todos?.edges || this.todos?.edges?.length == 0) {
        return [];
      }
      const todos = extractNodes(this.todos.edges);
      return todos.map((todo) => {
        const todoIsOutstanding = this.$store.getters.outstandingUserTodos?.find(
          (obj) => decodeId(obj.id) == decodeId(todo.id)
        );
        todo.done = !todoIsOutstanding;
        return todo;
      });
    },
    signUpTitle() {
      if (this.$store.getters.role == "INACTIVE") {
        return "Create a free profile";
      }

      if (this.$store.getters.role == "JEFF_MEMBER") {
        return `Sign up to ${this.$appName} Life`;
      }
      if (this.$store.getters.role == "CLUB") {
        return `Sign up to ${this.$appName} Club`;
      }
      if (this.$store.getters.role == "ONE_ON_ONE") {
        return `Sign up to 1-1 Coaching`;
      }
      return "Create a profile";
    },
  },
  methods: {
    async upsertSetting() {
      await this.$apollo.mutate({
        mutation: UPSERT_MY_SETTINGS_MUTATION,
        variables: {
          settingName: "hide-onboarding",
          value: true,
        },
        refetchQueries: ["accountQuery"],
      });
      this.$router.push("/today");
    },
  },
};
</script>

<style lang="scss" scoped>
.onboarding-progress {
  max-width: 125px;
}
</style>
