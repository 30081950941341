<template>
  <v-row align="center" justify="center" class="pt-0 mt-0">
    <v-col>
      <v-row v-if="loading">
        <v-col cols="12" align="center" justify="center">
          <v-progress-circular indeterminate color="secondary" size="70" class="my-4"></v-progress-circular>
        </v-col>
      </v-row>
      <div v-else>
        <div v-if="bookmarks.edges.length > 0">
          <bookmark-event-card
            v-for="item in bookmarks.edges"
            :key="item.node.id"
            :showBookmark="true"
            :event="item.node.event"
            :includeLive="true"
            :includeTime="true"
            :bookmarkId="item.node.id"
          />
        </div>
        <div v-else>
          <v-row align="center" justify="center" class="pt-0 mt-0">
            <v-col cols="12" class="px-10 pt-0 mt-0">
              <j-alert>
                  Bookmark future sessions to plan your week. Bookmark everything else to find later.
              </j-alert>
            </v-col>
          </v-row>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { BOOKMARKS_EVENT_ONLY_QUERY } from "@/graphql/queries/bookmark";
import BookmarkEventCard from "@/components/bookmarks/BookmarkEventCard";

export default {
  name: "UpcomingEvents",
  components: {
    BookmarkEventCard,
  },
  data() {
    return {
      loading: true,
      bookmarks: null,
      showUpSellModal: false,
    };
  },
  apollo: {
    bookmarks: {
      query: BOOKMARKS_EVENT_ONLY_QUERY,
      variables() {
        // get all events from this morning
        return {
          userId: btoa(`UserNode: ${this.$store.getters.user.id}`),
          event_ScheduledDate_Gte: this.$moment().startOf("day"),
          event_ScheduledDate_Lte: this.$moment().add(7, "days"),
          orderBy: "event__scheduled_date",
        };
      },
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data) {
          this.loading = false;
        }
      },
    },
  },
};
</script>

<style scoped>
.body-text {
  font-size: 1rem;
}
</style>
