<template>
  <v-container>
    <v-row justify="center" align="center">
      <v-col cols="12" md="6">
        <native-app-banner />

        <j-card rounded="sm">
          <v-card-text class="text-center px-10">
            <v-img :alt="$appName + ' Logo'" class="ma-auto" src="@/assets/images/JeffLogo.png" width="100" />
            <h1 class="navy--text mt-4">Choose the membership that's right for you</h1>
            <p class="subtitle-1 mb-0 mt-4">
              You need to be a paying member to access workouts, recipes, programs and more.
            </p>
          </v-card-text>
          <v-card-actions class="d-flex flex-wrap justify-center pb-4">
            <j-btn secondary class="mt-2" to="/shop">EXPLORE MEMBERSHIPS <v-icon>mdi-cart</v-icon></j-btn>
            <j-btn primary class="mt-2" to="/programs">
              EXPLORE PROGRAMS <v-icon> mdi-card-search-outline</v-icon>
            </j-btn>
            <j-btn color="facebook-blue" class="mt-3" href="https://www.facebook.com/groups/jefftogether">
              FREE FACEBOOK GROUP <v-icon class="pb-1">mdi-facebook</v-icon>
            </j-btn>
          </v-card-actions>
        </j-card>
      </v-col>
    </v-row>
    <v-row justify="center" align="center">
      <v-col cols="12" md="6">
        <j-card rounded="sm">
          <v-card-text>
            <p class="std-text font-weight-bold text-center navy--text">Helpful links</p>
            <div class="d-flex flex-wrap justify-center">
              <j-btn class="mr-4 mb-2" tertiary to="/rewards/points">
                Rewards
                <v-avatar tile size="16">
                  <img :src="require('@/assets/images/rewards.svg')" max-height="16" max-width="16" />
                </v-avatar>
              </j-btn>
              <j-btn class="mr-4 mb-2" to="/account/billing" tertiary>
                BILLING <v-icon>mdi-credit-card-outline</v-icon>
              </j-btn>
              <j-btn class="mb-2" tertiary :to="supportChat ? `/chats/${this.supportChat.id}` : '/contact'">
                SUPPORT <v-icon>mdi-chat</v-icon>
              </j-btn>
            </div>
          </v-card-text>
        </j-card>
      </v-col>
    </v-row>
    <v-row v-if="events.length > 0" justify="center" align="center">
      <v-col cols="12" md="6" align="center">
        <h3 class="pink--text text-left overflow-visible">Free classes</h3>

        <v-carousel v-model="eventSlide" hide-delimiters :height="null">
          <v-carousel-item v-for="currentSlide in totalEventSlides" :key="'class' + currentSlide">
            <div v-if="$apollo.loading" class="d-flex flex-row">
              <v-skeleton-loader
                v-for="loadingCard in cardsPerSlide"
                :key="loadingCard"
                type="card"
                class="flex-fill mx-1 rounded-xl"
              />
            </div>
            <div v-else class="d-flex flex-row pr-2">
              <j-workout-card
                v-for="event in events.slice(slideIndex(currentSlide), slideIndex(currentSlide) + cardsPerSlide)"
                :key="event.id"
                small
                :imageSrc="imageSrc(event)"
                :instructor-profile-pic="profilePicture(event)"
                :title="event.name"
                :instructor-name="'with ' + event.instructor.firstName"
                :card-width="cardSize"
                overlay-play
                @navigate="$router.push(`/event/${decodeId(event.id)}`)"
                class="ma-2 py-2"
              />
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
    <v-row v-if="recipes.length > 0" justify="center" align="center">
      <v-col cols="12" md="6" align="center">
        <h3 class="green--text text-left">Free recipes</h3>

        <v-carousel v-model="recipeSlide" hide-delimiters :height="null">
          <v-carousel-item v-for="currentSlide in totalRecipeSlides" :key="'recipe' + currentSlide">
            <div v-if="$apollo.loading" class="d-flex flex-row">
              <v-skeleton-loader
                v-for="loadingCard in cardsPerSlide"
                :key="loadingCard"
                type="card"
                class="flex-fill mx-1 rounded-xl"
              />
            </div>
            <div v-else class="d-flex flex-row">
              <recipe-listing-card
                v-for="recipe in recipes.slice(
                  recipeSlideIndex(currentSlide),
                  recipeSlideIndex(currentSlide) + recipeCardsPerSlide
                )"
                :key="recipe.id"
                :recipe="recipe"
                :class="'col-' + 12 / recipeCardsPerSlide"
              />
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-col>
    </v-row>
    <v-row justify="center" align="center" class="text-center mb-3">
      <div @click="logout" class="pointer">
        <v-avatar color="blue-background" size="80">
          <img v-if="profileImage" :src="profileImage" />
          <span v-if="!profileImage && user" class="white--text">
            {{ user.firstName ? user.firstName.charAt(0).toUpperCase() : ""
            }}{{ user.lastName ? user.lastName.charAt(0).toUpperCase() : "" }}
          </span>
        </v-avatar>
        <span class="block subtitle-1 p-text navy--text">Logout</span>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { SIMPLE_ME_QUERY } from "@/graphql/queries/account";
import { OPEN_EVENTS_QUERY } from "@/graphql/queries/events";
import { OPEN_RECIPES_QUERY } from "@/graphql/queries/nutrition";
import { decodeId } from "@/lib/string";
import JWorkoutCard from "@/layouts/cards/JWorkoutCard";
import RecipeListingCard from "@/components/nutritionblocks/cards/RecipeListingCard";
import NativeAppBanner from "@/components/shared/NativeAppBanner";
export default {
  name: "Gate",
  data() {
    return {
      cardsPerSlide: 2,
      maxRecipeCardsPerSlide: 3,
      totalCards: 4,
      eventSlide: 0,
      recipeSlide: 0,
      events: [],
      recipes: [],
    };
  },
  components: {
    JWorkoutCard,
    RecipeListingCard,
    NativeAppBanner,
  },
  computed: {
    profileImage() {
      return this.$store.getters.profileImage;
    },
    user() {
      return this.$store.getters.user;
    },
    totalEventSlides() {
      if (this.events) {
        return Math.ceil(this.events.length / this.cardsPerSlide);
      }
      return 1;
    },
    totalRecipeSlides() {
      if (this.recipes) {
        return Math.ceil(this.recipes.length / this.cardsPerSlide);
      }
      return 1;
    },
    cardSize() {
      return `${100 / this.cardsPerSlide - this.cardsPerSlide}%`;
    },
    mobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    recipeCardsPerSlide() {
      return this.mobile ? 2 : this.maxRecipeCardsPerSlide;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push("/login");
      });
    },
    slideIndex(slide) {
      return slide * this.cardsPerSlide - this.cardsPerSlide;
    },
    recipeSlideIndex(slide) {
      return slide * this.recipeCardsPerSlide - this.recipeCardsPerSlide;
    },
    imageSrc(node) {
      const overlayImage = "l_playnavy_mtlav7";

      if (node.embedUrl && node.embedUrl.includes("youtube")) {
        const youtubeId = node.embedUrl.split("watch?v=")[1];
        return `https://res.cloudinary.com/jeff-fitness/image/youtube/${overlayImage}/w_250/fl_layer_apply/v1/${youtubeId}.jpg`;
      } else if (node.youtubeId) {
        return `https://res.cloudinary.com/jeff-fitness/image/youtube/${overlayImage}/w_250/fl_layer_apply/v1/${node.youtubeId}.jpg`;
      } else if (node.thumbnail) {
        return node.thumbnail;
      }

      return "";
    },
    profilePicture(node) {
      return node.instructor?.userProfile?.profilePicture;
    },
    decodeId(val) {
      return decodeId(val);
    },
  },
  apollo: {
    supportChat: {
      query: SIMPLE_ME_QUERY,
      fetchPolicy: "no-cache",
      variables() {
        return {
          first: 1,
        };
      },
      update(data) {
        return data.me.userProfile.supportChat;
      },
    },
    events: {
      query: OPEN_EVENTS_QUERY,
      variables() {
        return {
          first: this.totalCards,
        };
      },
      update(data) {
        return data.paginatedEvents?.edges.map((edge) => edge.node) ?? [];
      },
    },
    recipes: {
      query: OPEN_RECIPES_QUERY,
      variables() {
        return {
          first: this.totalCards,
        };
      },
      update(data) {
        return data.recipes?.edges.map((edge) => edge.node) ?? [];
      },
    },
  },
};
</script>
