<template>
  <checkin-stepper
    :step="3"
    :title="{ text: 'Any instructors', strongText: 'you prefer?' }"
    subtitle="Choose none, one or multiple"
    @next="next"    
  >
    <v-row v-if="featuredStaff && featuredStaff.length > 0" class="pa-5">
      <v-col cols="6" v-for="featuredStaff in featuredStaff" :key="featuredStaff.user.id" class="px-5 py-0">
        <j-card flat>
          <v-img
            :lazy-src="featuredStaff.profilePicture"
            :src="featuredStaff.profilePicture"
            :aspect-ratio="9 / 9"
            class="pointer"
            @click="toggleCheck(featuredStaff.user.id)"
          />
        </j-card>
        <j-checkbox
          v-model="selectedStaff"
          :label="featuredStaff.user.firstName"
          :value="featuredStaff.user.id"
        ></j-checkbox>
      </v-col>
    </v-row>

    <div v-if="staff && staff.length > 0" class="d-flex justify-center mb-5">
      <v-btn color="grey-navy" text @click="showMore = !showMore">
        <strong>Show More</strong>
        <v-icon v-if="showMore" right dark>
          mdi-chevron-up
        </v-icon>
        <v-icon v-else right dark>
          mdi-chevron-down
        </v-icon>
      </v-btn>
    </div>

    <v-row v-if="showMore" class="pa-5">
      <v-col cols="6" v-for="instructor in staff" :key="instructor.user.id" class="px-5 py-0">
        <j-card flat>
          <v-img
            :lazy-src="instructor.profilePicture"
            :src="instructor.profilePicture"
            :aspect-ratio="9 / 9"
            class="pointer"
            @click="toggleCheck(instructor.user.id)"
          />
        </j-card>
        <j-checkbox v-model="selectedStaff" :label="instructor.user.firstName" :value="instructor.user.id"></j-checkbox>
      </v-col>
    </v-row>
  </checkin-stepper>
</template>

<script>
import StaffMixin from "@/mixins/StaffMixin";
import CheckinStepper from "@/components/checkin/CheckinStepper";

export default {
  name: "Instructors",
  mixins: [StaffMixin],
  components: {
    CheckinStepper,
  },
  data() {
    return {
      userType: "Instructor",
    };
  },
};
</script>
