<template>
  <v-container class="pa-0">
    <v-row align="center" justify="center">
      <v-col>
        <ActivityTotalBlock
          :loaded="loadingSection.week"
          :loadedStats="loadingSection.stats"
          :title="weekTitle"
          link="/goal/tracker/dots/list"
          :exercise="activitiesForWeek.exercise"
          :mindset="activitiesForWeek.mindset"
          :nutrition="activitiesForWeek.nutrition"
          :stats="userStats"
          statsPeriod="thisWeek"
        ></ActivityTotalBlock>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col>
        <ActivityTotalBlock
          :loaded="loadingSection.month"
          :loadedStats="loadingSection.stats"
          :title="monthTitle"
          link="/goal/tracker/dots/list"
          :exercise="activitiesForMonth.exercise"
          :mindset="activitiesForMonth.mindset"
          :nutrition="activitiesForMonth.nutrition"
          :stats="userStats"
          statsPeriod="thisMonth"
        ></ActivityTotalBlock>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col>
        <ActivityTotalBlock
          :loaded="loadingSection.year"
          :loadedStats="loadingSection.stats"
          :title="yearTitle"
          link="/goal/tracker/dots/list"
          :exercise="activitiesForYear.exercise"
          :mindset="activitiesForYear.mindset"
          :nutrition="activitiesForYear.nutrition"
          :stats="userStats"
          statsPeriod="thisYear"
        ></ActivityTotalBlock>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col>
        <ActivityTotalBlock
          :loaded="loadingSection.all"
          :loadedStats="loadingSection.stats"
          title="All Time"
          link="/goal/tracker/dots/list"
          :exercise="activitiesForAllTime.exercise"
          :mindset="activitiesForAllTime.mindset"
          :nutrition="activitiesForAllTime.nutrition"
          :stats="userStats"
          statsPeriod="allTime"
        ></ActivityTotalBlock>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { USER_DOTS_QUERY, USER_STATS_QUERY } from "@/graphql/queries/tracker";
import ActivityTotalBlock from "@/components/tracker/ActivityTotalBlock";

export default {
  name: "ActivityOverview",
  props: {
    userId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      userStats: {},
      activitiesForMonth: { mindset: 0, exercise: 0, nutrition: 0 },
      activitiesForYear: { mindset: 0, exercise: 0, nutrition: 0 },
      activitiesForWeek: { mindset: 0, exercise: 0, nutrition: 0 },
      activitiesForAllTime: { mindset: 0, exercise: 0, nutrition: 0 },
      loadingSection: {
        stats: true,
        year: true,
        month: true,
        week: true,
        all: true,
      },
    };
  },
  components: {
    ActivityTotalBlock,
  },
  computed: {
    weekTitle() {
      let start = this.$moment(this.getMonday()).format("DD");
      let end = this.$moment(this.sunday).format("DD MMMM");
      return "This week • " + start + "-" + end;
    },
    monthTitle() {
      return "This month • " + this.$moment().format("MMMM YYYY");
    },
    yearTitle() {
      return "This year • " + this.$moment().format("YYYY");
    },
    sunday() {
      var date = new Date(this.getMonday());
      return date.setDate(date.getDate() + 6);
    },
  },
  methods: {
    getMonday() {
      var startDate = new Date();
      startDate.setHours(0, 0, 0, 0);
      var day = startDate.getDay(),
        diff = startDate.getDate() - day + (day == 0 ? -6 : 1);
      return new Date(startDate.setDate(diff));
    },
  },
  mounted() {
    window.analytics.page("DotsTracker");
  },
  apollo: {
    userStats: {
      query: USER_STATS_QUERY,
      variables() {
        return {
          userId: this.userId,
        };
      },
      fetchPolicy: "network-only",
      result(response) {
        if (response.data) {
          this.loadingSection.stats = false;
        }
      },
    },
    activitiesForMonth: {
      query: USER_DOTS_QUERY,
      variables() {
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        return {
          startDate: firstDay.toISOString(),
          endDate: lastDay.toISOString(),
          userId: this.userId,
        };
      },
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data) {
          this.loadingSection.month = false;
        }
      },
      update: (data) => data.userDots,
    },
    activitiesForYear: {
      query: USER_DOTS_QUERY,
      variables() {
        var firstDay = new Date(new Date().getFullYear(), 0, 1);

        return {
          startDate: firstDay.toISOString(),
          endDate: new Date(new Date().getFullYear(), 11, 31),
          userId: this.userId,
        };
      },
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data) {
          this.loadingSection.year = false;
        }
      },
      update: (data) => data.userDots,
    },
    activitiesForWeek: {
      query: USER_DOTS_QUERY,
      variables() {
        var date = new Date(this.getMonday());
        date.setDate(date.getDate() + 8);

        return {
          startDate: new Date(this.getMonday()).toISOString(),
          endDate: date.toISOString(),
          userId: this.userId,
        };
      },
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data) {
          this.loadingSection.week = false;
        }
      },
      update: (data) => data.userDots,
    },
    activitiesForAllTime: {
      query: USER_DOTS_QUERY,
      variables() {
        return {
          startDate: new Date(1970, 12, 0, 0, 0, 0, 0).toISOString(),
          endDate: new Date(new Date().getFullYear(), 11, 31),
          userId: this.userId,
        };
      },
      result(response) {
        if (response.data) {
          this.loadingSection.all = false;
        }
      },
      update: (data) => data.userDots,
    },
  },
};
</script>
