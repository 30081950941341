import { GROUPS_QUERY } from "@/graphql/queries/group";

export default {
  data() {
    return {
      keyUpSearchInterval: null,
      groups: null,
      selectedGroups: [],
      // selectedGroups contains id for the radio button. But we may want to display a human friendly title of the selectedGroups
      humanFriendlyGroups: [],
      searchString: null,
      // NB, limit must correspond or be less than the number of items in colour array, otherwise this will bomb
      // I can't set the random colour in Javatarcard, since on click of checkbox, it then changes the colour, which is weird.
      limit: 6,
      colours: ["pink", "green", "yellow", "navy", "blue", "red"],
      // if true, searches groups that the user is a member of.
      searchMine: false,
    };
  },
  computed: {
    humanFriendlySelectedGroups() {
      let groups = this.humanFriendlyGroups.filter((obj) => this.selectedGroups.includes(obj.id));

      let groupTitles = [];
      groups.forEach((group) => {
        groupTitles.push(group.title.trim());
      });

      return [...new Set(groupTitles)];
    },
  },
  methods: {
    searchGroups() {
      if (this.keyUpSearchInterval) {
        clearTimeout(this.keyUpSearchInterval);
        this.keyUpSearchInterval = null;
      }
      this.keyUpSearchInterval = setTimeout(() => {
        this.doSearch();
      }, 1000);
    },
    doSearch() {
      if (!this.searchString) {
        return (this.groups = null);
      }

      // no need to paginate. let's think about it...the user is trying to find and add people one at a time.
      // showing `$limit` is enough. Will JEFF have more than `$limit` people of the same name and surname? if so, we can deal with i then.
      this.$apollo
        .query({
          query: GROUPS_QUERY,
          variables: {
            first: this.limit,
            mine: this.searchMine,
            search: this.searchString,
            withUsers: false,
          },
        })
        .then((response) => {
          this.groups = response.data.groups.edges;
          //this.humanFriendlyGroups.push(this.groups)
          this.groups.forEach((group) => {
            this.humanFriendlyGroups.push({
              id: group.node.id,
              title: group.node.title,
            });
          });
        });
    },
    getSubTitle(group) {
      const userCount = group.memberCount;
      const suffix = userCount != 1 ? "s" : "";
      const createdAt = this.$moment(group.createdAt).format("D MMM YY");
      let isMember = "";
      let createdBy = "";
      if (group.createdBy) {
        createdBy = ` - created by ${group.createdBy.firstName} ${group.createdBy.lastName} on ${createdAt}`;
        if (group.createdBy.id == this.$store.getters.user.id) {
          isMember = "";
          createdBy = ` - created by you on ${createdAt}`;
        }
      }
      if (group.isMember) {
        isMember = "(including you)";
      }

      return `${userCount} member${suffix} ${isMember} ${createdBy}`;
    },
  },
};
