<template>
  <j-banner-container header="Leaderboard" :gradient="['green-background', 'yellow-background']">
    <v-container v-if="initializing" class="pa-0 ma-0">
      <v-skeleton-loader v-for="item in 2" :key="item" type="list-item-avatar" class="mt-2" />
    </v-container>
    <v-container v-else class="pa-0 ma-0">
      <div class="my-2">
        <v-avatar v-if="leaderboard.logo" size="100"><img :src="leaderboard.logo"/></v-avatar>
        <h3 v-else class="grey-text">{{leaderboard.title}}</h3>
      </div>
      <v-tabs
        v-model="tab"
        centered
        background-color="background-grey"
        class="tribe-tabs pt-3"
        hide-slider
        active-class="font-weight-bold"
      >
        <v-tab key="exercise" class="text-capitalize text-body-1 px-sm-10 px-md-7 px-lg-13 navy--text">
          Exercise
        </v-tab>
        <v-tab key="nutrition" class="text-capitalize text-body-1 px-sm-10 px-md-7 px-lg-13 navy--text">
          Nutrition
        </v-tab>
        <v-tab key="mindset" class="text-capitalize text-body-1 px-sm-10 px-md-7 px-lg-13 navy--text">
          Mindset
        </v-tab>
      </v-tabs>
      <v-tabs v-model="activeTab" centered>
        <v-tab key="this_week"> This Week </v-tab>
        <v-tab key="last_week"> Last Week </v-tab>
        <v-tab key="all_time"> All Time </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item key="exercise" class="tribes-content">
          <leaderboard-board
            :leaderboard-data="formatLeaderboardData('exercise')"
            leaderboard-type="exercise"
          />
        </v-tab-item>
        <v-tab-item key="nutrition" class="tribes-content">
          <leaderboard-board
            :leaderboard-data="formatLeaderboardData('nutrition')"
            leaderboard-type="nutrition"
          />
        </v-tab-item>
        <v-tab-item key="mindset" class="tribes-content">
          <leaderboard-board
            :leaderboard-data="formatLeaderboardData('mindset')"
            leaderboard-type="mindset"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </j-banner-container>
</template>

<script>

import LeaderboardBoard from "@/components/leaderboard/LeaderboardBoard";
import {LEADER_BOARD_QUERY} from "@/graphql/queries/leaderboards";
import LeaderboardMixin from "@/mixins/LeaderboardMixin";

export default {
  name: "LeaderboardView",
  components : {
    LeaderboardBoard
  },
  mixins: [LeaderboardMixin],
  methods: {
    formatLeaderboardData(section) {
      let data = this.leaderboard.stats[section];
      return data.map(element => {
        return {
          title: element.first_name + " " + element.last_name,
          image: element.profile_pic ?? require("@/assets/images/grey.png"),
          dots: element.dots,
          isMine: element.handle === this.$store.getters.user?.userProfile?.handle,
        }
      })
    }
  },
  apollo: {
    leaderboard: {
      query: LEADER_BOARD_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          leaderboardId: this.$route.params.id,
          startDate: this.queryDateRange().startDate,
          endDate: this.queryDateRange().endDate,
        }
      },
      result(response) {
        if (!response.loading) {
          this.loadingNewData = false;
          this.initializing = false;
        }
      }
    }
  }
}
</script>