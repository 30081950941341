<template>
  <div>
    <j-main-banner-container header="Community" :gradient="['green-background', 'yellow-background']">
      <div v-if="!$apollo.loading" class="text-right mr-4 mt-4 mb-n4">
        <v-btn icon color="black" to="/notifications">
          <v-icon large>mdi-bell-outline</v-icon
          ><span class="pink--text unread-notification unread-absolute">{{ unreadNotificationCount }}</span>
        </v-btn>
      </div>

      <j-alert v-if="!hasClub" class="mx-7 my-5">
        <div class="mb-2">Join JEFF Club to get full access to Groups!</div>
        <j-btn secondary to="/checkout/jeff-club" class="mt-2">Join JEFF Club</j-btn>
      </j-alert>
      <div v-else>
        <v-skeleton-loader v-if="$apollo.loading" type="list-item-avatar,list-item-avatar,list-item-avatar" />

        <div v-else>
          <j-subsection-row title="Your Groups" :see-all="{ text: '', link: '' }">
            <div v-if="groups && groups.length > 0">
              <v-card class="mx-auto" align="left" style="box-shadow: none">
                <v-list three-line>
                  <template v-for="(item, index) in groups">
                    <v-list-item :key="item.node.title">
                      <v-list-item-avatar size="70" class="rounded-lg pointer" @click="navigateGroup(item.node.id)">
                        <v-img :src="item.node.banner || require('@/assets/images/group_banner_default.png')"></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title class="d-inline-grid" @click="navigateGroup(item.node.id)">
                          <div class="d-flex">
                            <div class="heading-4 font-weight-bold pointer">
                              {{ item.node.title }}
                            </div>
                            <div v-if="item.node.postCount > 0" class="ml-2">
                              <span class="pink--text unread-notification">{{ item.node.postCount }}</span>
                            </div>
                          </div>

                          <span class="sm-text">
                            {{ `${item.node.memberCount} member${item.node.memberCount != 1 ? "s" : ""}` }}
                            <span v-if="formattedLastActive(item.node.lastActive)"
                              >| {{ formattedLastActive(item.node.lastActive) }}</span
                            ></span
                          >
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-icon class="align-center">
                        <v-icon class="navy--text pointer mt-1 mr-1" size="24" @click="navigateGroup(item.node.id)"
                          >mdi-message</v-icon
                        >
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon class="navy--text pointer" size="24" v-bind="attrs" @click.prevent="on.click"
                              >mdi-dots-horizontal-circle</v-icon
                            >
                          </template>

                          <v-list>
                            <v-list-item class="p-text pointer" @click="confirmLeaveGroupDialog(item.node)">
                              <v-icon class="pr-2 mt-n1">mdi-logout</v-icon>Leave Group
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-icon>
                    </v-list-item>
                    <v-divider :key="index" inset />
                  </template>
                </v-list>
              </v-card>

              <j-btn :rounded="false" small narrow :to="`/manage-groups`" class="mx-2 my-1">
                <span>View All Your Groups</span>
              </j-btn>
            </div>

            <div v-else class="pa-4">
              <j-alert>Groups you join will appear here</j-alert>
            </div>
          </j-subsection-row>

          <j-subsection-row :title="`${$appName} Groups`" :see-all="{ text: '', link: '' }">
            <div v-if="featuredGroups && featuredGroups.length > 0">
              <v-card class="mx-auto" align="left" style="box-shadow: none">
                <v-list three-line>
                  <template v-for="(item, index) in featuredGroups">
                    <v-list-item :key="item.node.title">
                      <v-list-item-avatar size="70" class="pointer" @click="navigateGroup(item.node.id)">
                        <v-img :src="item.node.banner || require('@/assets/images/group_banner_default.png')"></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title class="d-inline-grid pointer" @click="navigateGroup(item.node.id)">
                          <span class="heading-4 font-weight-bold">
                            {{ item.node.title }}
                          </span>
                          <span class="sm-text">
                            {{ `${item.node.memberCount} member${item.node.memberCount != 1 ? "s" : ""}` }}
                            <span v-if="formattedLastActive(item.node.lastActive)"
                              >| {{ formattedLastActive(item.node.lastActive) }}</span
                            >
                          </span>
                        </v-list-item-title>
                      </v-list-item-content>

                      <v-list-item-icon class="align-center">
                        <v-icon class="navy--text pointer mt-1 mr-1" size="24" @click="navigateGroup(item.node.id)"
                          >mdi-message</v-icon
                        >
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon class="navy--text pointer" size="24" v-bind="attrs" @click.prevent="on.click"
                              >mdi-dots-horizontal-circle</v-icon
                            >
                          </template>

                          <v-list>
                            <v-list-item
                              v-if="item.node.isMember"
                              class="p-text pointer"
                              @click="confirmLeaveGroupDialog(item.node)"
                            >
                              <v-icon class="pr-2 mt-n1">mdi-logout</v-icon> Leave Group
                            </v-list-item>
                            <v-list-item v-else class="p-text pointer" @click="joinGroup(item.node.id)">
                              <v-icon class="pr-2 mt-n1">mdi-login</v-icon> Join Group
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-icon>
                    </v-list-item>

                    <v-divider :key="index" inset />
                  </template>
                </v-list>
              </v-card>

              <j-btn :rounded="false" small narrow :to="`/groups`" class="mx-2 my-1">
                <span>Browse all Groups</span>
              </j-btn>
            </div>

            <div v-else class="pa-4">
              <j-alert>No available Groups to Join</j-alert>
            </div>
          </j-subsection-row>

          <friends-block :userId="$store.getters.user.id" />

          <j-subsection-row
            title="Newsfeed"
            class="mt-6"
            :see-all="{ text: 'Go to your posts', link: '/social/your-posts' }"
          >
            <post-container
              v-if="groups && groups.length > 0"
              heading=""
              hide-post-link
              show-all-posts-toggle
              :userId="$store.getters.user.id"
              dedupe-user
            />
            <div v-else class="pa-4">
              <j-alert>Posts from your groups will appear here</j-alert>
            </div>
          </j-subsection-row>
        </div>
      </div>
    </j-main-banner-container>

    <v-dialog v-model="leaveGroupDialog" width="400" transition="dialog-bottom-transition" class="limited-height">
      <leave-group :group="selectedGroup" @saved="leaveGroup(selectedGroup.id)" @closed="leaveGroupDialog = false" />
    </v-dialog>
  </div>
</template>

<script>
import { GROUPS_QUERY } from "@/graphql/queries/group";
import { LEAVE_GROUP_MUTATION, JOIN_GROUP_MUTATION } from "@/graphql/mutations/group";
import PostContainer from "@/components/groups/posts/PostContainer";
import FriendsBlock from "@/components/friends/FriendsBlock";
import UserMixin from "@/mixins/UserMixin";
import { mapGetters } from "vuex";
import { decodeId } from "@/lib/string";
import LeaveGroup from "@/components/groups/dialogs/LeaveGroup";

export default {
  name: "Feed",
  mixins: [UserMixin],
  data() {
    return {
      showSettingsToggle: false,
      leaveGroupDialog: false,
      selectedGroup: null,
    };
  },
  computed: {
    ...mapGetters(["unreadNotificationCount"]),
  },
  components: {
    PostContainer,
    FriendsBlock,
    LeaveGroup,
  },
  methods: {
    formattedLastActive(lastActive) {
      const dateBefore = this.$moment().subtract(4, "weeks");
      if (this.$moment(lastActive).isAfter(dateBefore)) {
        return this.$moment(lastActive).format("ddd, D MMM");
      }
      return null;
    },
    async joinGroup(id) {
      await this.$apollo.mutate({
        mutation: JOIN_GROUP_MUTATION,
        variables: {
          groupId: decodeId(id),
        },
      });

      this.$apollo.queries.groups.refetch();
      this.$apollo.queries.featuredGroups.refetch();
    },
    async leaveGroup(id) {
      this.leaveGroupDialog = false;
      await this.$apollo.mutate({
        mutation: LEAVE_GROUP_MUTATION,
        variables: {
          groupId: decodeId(id),
        },
      });

      this.groupList = [];
      this.$apollo.queries.groups.refetch();
      this.$apollo.queries.featuredGroups.refetch();
    },
    confirmLeaveGroupDialog(group) {
      this.selectedGroup = group;
      this.leaveGroupDialog = true;
    },
    navigateGroup(groupId) {
      this.$router.push(`/group/${decodeId(groupId)}`);
    },
    loadMoreGroups() {
      this.after = this.endCursor;
    },
  },
  apollo: {
    groups: {
      query: GROUPS_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          mine: true,
          first: 4,
          withUsers: false,
          typeIn: ["GENERAL", "ACCOUNTABILITY", "SUBURB", "GYM"],
          orderBy: "-lastActive",
        };
      },
      update(data) {
        return data.groups.edges;
      },
    },
    featuredGroups: {
      query: GROUPS_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          isFeatured: true,
          withUsers: false,
          first: 4,
          orderBy: "order",
          mine: false,
        };
      },
      update(data) {
        return data.groups.edges;
      },
    },
  },
};
</script>

<style scoped lang="scss">
.group-wrapper {
  position: relative;
  .group-title {
    min-height: 40px;
  }
  .bubble {
    position: absolute;
    right: -20px;
    top: -20px;
  }
}

.d-none {
  display: none !important;
}

.unread-notification {
  background: #ffcad6;
  padding: 2px 5px;
  border-radius: 50%;
  font-size: 12px;
}
.unread-absolute {
  position: absolute;
  left: 17px;
  top: -6px;
}

::v-deep .toasted.toasted-primary.success {
  margin: auto !important;
}

.d-inline-grid {
  display: inline-grid;
}
</style>
