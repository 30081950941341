<template>
  <v-col cols="12" @click="navigate()" class="todo-listing-card px-0 d-flex text-left" :class="{ pointer: !done }">
    <div class="flex-grow-0 mt-n1 mr-1 mr-md-2">
      <v-icon size="32" :color="done ? 'success' : 'grey-navy'">{{
        done ? "mdi-check-circle" : "mdi-checkbox-blank-circle-outline"
      }}</v-icon>
    </div>
    <span
      class="mr-auto navy--text align-self-center std-text"
      :class="{ strikethrough: done, 'font-weight-bold': !done }"
    >
      {{ todo.title }}
    </span>

    <div v-if="!done" class="flex-grow-0">
      <v-icon size="24" color="grey-navy">mdi-chevron-right</v-icon>
    </div>
  </v-col>
</template>

<script>
import { decodeId } from "@/lib/string";

export default {
  name: "TodoListingCard",
  props: {
    todo: {
      type: Object,
      required: true,
    },
    done: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    navigate() {
      if (this.done) {
        return false;
      }
      if (!this.todo.url) {
        return this.$router.push(`/to-do/${decodeId(this.todo.id)}`);
      }
      if (this.todo.url.startsWith("https://")) {
        return window.open(this.todo.url, "_blank");
      }
      return this.$router.push(this.todo.url);
    },
  },
};
</script>

<style lang="scss" scoped>
.todo-listing-card:not(:last-of-type) {
  border-bottom: var(--v-grey-base) 1px solid;
}
</style>
