<template>
  <j-main-banner-container
    header="Groups"
    :gradient="['green-background', 'yellow-background']"
    search
    :search-val="search"
    @searchValueChanged="updateSearch"
  >
    <div v-if="$apollo.loading && groups.length == 0">
      <v-row>
        <v-col cols="6"><v-skeleton-loader type="card-avatar" /> </v-col>
        <v-col cols="6"><v-skeleton-loader type="card-avatar" /> </v-col>
      </v-row>
    </div>
    <div v-else class="pa-2">
      <group-listing-repeater :groups="groups" title="Featured" field="isFeatured" :value="true" class="mb-8" />
      <group-listing-repeater :groups="groups" title="Suburb" field="type" value="SUBURB" class="mb-8" />
      <group-listing-repeater
        :groups="groups"
        title="Accountability"
        field="type"
        value="ACCOUNTABILITY"
        class="mb-8"
      />
      <group-listing-repeater :groups="groups" title="Gyms" field="type" value="GYM" class="mb-8" />

      <v-row v-if="!$apollo.loading && groups.length == 0">
        <v-col cols="12">
          <j-alert
            >No results found <span v-if="search"> for: "{{ search }}"</span></j-alert
          >
        </v-col>
      </v-row>
    </div>
  </j-main-banner-container>
</template>

<script>
import { GROUPS_QUERY } from "@/graphql/queries/group";
import { decodeId } from "@/lib/string";
import GroupListingRepeater from "@/components/groups/GroupListingRepeater";

export default {
  name: "FeaturedGroups",
  components: {
    GroupListingRepeater,
  },
  data() {
    return {
      groups: [],
      search: "",
    };
  },
  created() {
    if (this.$route.query.s) {
      this.search = this.$route.query.s;
    }
  },
  methods: {
    decodeId(id) {
      return decodeId(id);
    },
    updateSearch(value) {
      this.search = value;
      history.pushState({}, null, this.$route.path + "?s=" + encodeURIComponent(this.search));
    },
  },
  apollo: {
    groups: {
      query: GROUPS_QUERY,
      fetchPolicy: "cache-and-network",
      variables() {
        return {
          search: this.search,
          //isFeatured: true,
          typeIn: ["GENERAL", "SUBURB", "ACCOUNTABILITY", "GYM"],
          permissionIn: ["PUBLIC"], // REQUIRES_APPROVAL needs workflow
          withUsers: false,
          orderBy: "title",
          hasData: true, // has custom image and a title
        };
      },
      update(data) {
        return data.groups.edges.map((item) => {
          return item.node;
        });
      },
    },
  },
};
</script>
