<template>
  <v-container>
    <v-row justify="center">
      <v-col cols="12" md="6">
        <j-card>
          <v-card-title class="pb-0 mb-1 justify-center">
            <h2 class="font-weight-bold mb-4 mt-4 appblue--text">JEFF for Teams</h2>
          </v-card-title>
          <v-card-text>
            <div class="px-3">
              <p class="text-center mt-n2 mb-6">Unlock the potential of your team with JEFF</p>
              <ValidationObserver ref="observer">
                <v-form @submit.prevent="submit">
                  <j-text-field
                    v-model="totallyRealFields.name"
                    aria-hidden="true"
                    class="totally_real_input"
                  ></j-text-field>

                  <j-text-field
                    v-model="totallyRealFields.surname"
                    aria-hidden="true"
                    class="totally_real_input"
                  ></j-text-field>

                  <v-row no-gutters>
                    <v-col cols="12" md="12">
                      <ValidationProvider id="companyName" v-slot="{ errors }" name="company name" rules="required">
                        <j-text-field
                          v-model="data.companyName"
                          outlined
                          label="Company Name"
                          :error-messages="errors"
                          class="mr-0 mr-md-1"
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" md="12">
                      <ValidationProvider id="teamSize" v-slot="{ errors }" name="team size" rules="required">
                        <j-text-field
                          v-model="data.teamSize"
                          outlined
                          label="Size of your team"
                          :error-messages="errors"
                          class="mr-0 ml-md-1"
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12">
                      <ValidationProvider id="contactName" v-slot="{ errors }" name="name" rules="required">
                        <j-text-field
                          v-model.trim="data.contactName"
                          label="Your Name"
                          outlined
                          :error-messages="errors"
                          @keydown.space.prevent
                        />
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12">
                      <ValidationProvider id="contactEmail" v-slot="{ errors }" name="email" rules="required|email">
                        <j-text-field
                          v-model.trim="data.contactEmail"
                          label="Your Email"
                          outlined
                          :error-messages="errors"
                          @keydown.space.prevent
                        />
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12">
                      <ValidationProvider id="contactPhone" v-slot="{ errors }" name="phone" rules="required">
                        <j-text-field
                          v-model.trim="data.contactPhone"
                          label="Your Phone Number"
                          outlined
                          :error-messages="errors"
                          @keydown.space.prevent
                        />
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12">
                      <j-textarea
                        v-model="data.description"
                        multi
                        outlined
                        label="Please provide details about how we can help you"
                        :error-messages="errors"
                      />
                    </v-col>
                  </v-row>

                  <v-row justify="center">
                    <j-alert v-if="errorMessage">
                      {{ errorMessage }}
                    </j-alert>
                    <j-btn type="submit" :loading="buttonLoading" width="100%" class="mt-n2 mb-8">Submit</j-btn>
                  </v-row>
                </v-form>
              </ValidationObserver>
            </div>
          </v-card-text>
        </j-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { CREATE_TEAM_REQUEST_MUTATION } from "@/graphql/mutations/contact";

export default {
  name: "Team",
  data() {
    return {
      data: {
        companyName: null,
        teamSize: null,
        contactName: null,
        contactEmail: null,
        contactPhone: null,
        description: null,
      },
      errors: null,
      buttonLoading: false,
      totallyRealFields: {
        name: null,
        surname: null,
      },
      errorMessage: null,
    };
  },
  methods: {
    toThankYou() {
      this.$router.push("/team/thank-you");
    },
    async submit() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;

      if (valid) {
        this.buttonLoading = true;

        if (this.totallyRealFields.name || this.totallyRealFields.surname) {
          this.toThankYou();
          return;
        }
        const { data } = await this.$apollo.mutate({
          mutation: CREATE_TEAM_REQUEST_MUTATION,
          variables: {
            data: this.data,
          },
        });
        this.loading = false;
        if (data.createTeamRequest.success) {
          // redirect to thank you page
          this.toThankYou();
          return;
        }

        this.errorMessage = "There was a problem. Please try again.";
      }
    },
  },
};
</script>
