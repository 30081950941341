import { TAGS_QUERY, USER_TAGS_QUERY } from "@/graphql/queries/tag";
import { USER_TAGS_MUTATION } from "@/graphql/mutations/tag";


export default {
  data() {
    return {
      category: null,
      tags: null,
      selectedTags: [],
    };
  },
  methods: {
    previous() {
      return this.$router.push(this.previousRedirect);
    },
    next(nextStepLink) {
      this.$apollo
        .mutate({
          mutation: USER_TAGS_MUTATION,
          variables: {
            tags: this.selectedTags,
            category: this.category,
          },
        })
        .then((response) => {
          if (response.data.setUserTags.success) {
            return this.$router.push(nextStepLink);
          }
        });
    },
    tagIsSelected(tag) {
      return this.selectedTags.find((obj) => obj == tag.node.code);
    },
  },
  apollo: {
    tags: {
      query: TAGS_QUERY,
      variables() {
        return {
          category: this.category,
          showToUser: true,
        };
      },
      fetchPolicy: "cache-and-network",
    },
    userTags: {
      query: USER_TAGS_QUERY,
      variables() {
        return {
          category: this.category,
        };
      },
      fetchPolicy: "cache-and-network",
      result(response) {
        if (response.data) {
          this.selectedTags = [];
          response.data.userTags.forEach((el) => {
            this.selectedTags.push(el.tag.code);
          });
        }
      },
    },
  },
};
