<template>
  <div>
    <v-row v-if="$apollo.loading" justify="center">
      <v-col cols="12" md="5"><v-skeleton-loader type="card" /> </v-col
    ></v-row>

    <j-main-banner-container
      v-if="!$apollo.loading"
      header="Chat Joined"
      :gradient="['green-background', 'yellow-background']"
      :back-link="null"
    >
      <div class="pa-4" align="left">
        <j-alert type="success">
          You have successfully joined <strong>{{ chat.title }}</strong
          >.
          <router-link :to="`/chats/${chat.id}`" class="green--text">
            Go ahead and say hi
          </router-link>
          👋
        </j-alert>
      </div>
    </j-main-banner-container>
  </div>
</template>

<script>
import { CHAT } from "@/graphql/queries/chat";
import { UPSERT_USER_CHAT_MUTATION } from "@/graphql/mutations/chat";
export default {
  name: "ChatJoin",
  data() {
    return {
      loading: false,
    };
  },
  props: {
    chatId: {
      type: String,
      required: true,
    },
  },
  methods: {},
  apollo: {
    chat: {
      query: CHAT,
      fetchPolicy: "network-only",
      variables() {
        return {
          chatId: this.chatId,
          withUserChats: false,
        };
      },
      result(response) {
        if (response.data) {
          this.$apollo.mutate({
            mutation: UPSERT_USER_CHAT_MUTATION,
            variables: {
              data: {
                chatId: response.data.chat.id,
                receiveNotifications: true,
                shareData: true,
              },
            },
            refetchQueries: ["chatsForUser"],
          });
        }
      },
    },
  },
};
</script>
