<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" md="6" class="blue-background">
        <back-link icon large />
        <j-card class="j-elevated-1 ma-5">
          <div class="d-flex flex-wrap justify-space-between pt-3 px-5">
            <j-btn secondary @click="complete" class="mt-2">
              Tick
              <v-icon>mdi-check-circle-outline</v-icon>
            </j-btn>
            <j-btn tertiary @click="complete" class="mt-2">
              Remove
              <v-icon>mdi-trash-can-outline</v-icon>
            </j-btn>
          </div>
          <v-card-title class="navy--text">
            Facebook groups for you
          </v-card-title>
          <v-card-text class="text-left">
            <join-facebook-content />
          </v-card-text>
        </j-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { UPDATE_FACEBOOK_JOINED } from "@/graphql/mutations/assistant";
import BackLink from "@/layouts/BackLink";
import JoinFacebookContent from "@/components/shared/blocks/JoinFacebookContent";

export default {
  name: "JoinFacebook",
  components: {
    BackLink,
    JoinFacebookContent,
  },
  methods: {
    complete() {
      this.$apollo
        .mutate({
          mutation: UPDATE_FACEBOOK_JOINED,
          refetchQueries: ["assistantQuery"],
        })
        .then(() => {
          this.$router.go(-1);
        });
    },
  },
};
</script>
