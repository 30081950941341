<template>
  <j-banner-container header="Mindset">
    <calendar-dots accent-color="navy"/>
    <today-mindset-section class="mx-4 mt-n6" />
  </j-banner-container>
</template>

<script>
import TodayMindsetSection from "@/components/shared/sections/TodayMindset";
import CalendarDots from "@/components/shared/blocks/CalendarDots";

export default {
  name: "TodayMindset",
  components: {
    TodayMindsetSection,
    CalendarDots,
  },
};
</script>
