<template>
  <v-skeleton-loader v-if="$apollo.loading" type="card-heading" />
  <div v-else>
    <div class="d-flex justify-space-between mb-4">
      <h4>Exercise</h4>
      <v-spacer />
      <v-btn small text to="/accountability/dots" class="grey-navy--text">
        <strong>MORE</strong>
      </v-btn>
      <!-- You cannot turn off exercise tracking -->
      <!-- <v-switch class="py-0 my-0" :input-value="exerciseEnabled" @change="updateExceriseSetting($event)" /> -->
    </div>
    <div class="d-flex justify-space-between align-center mt-n4">
      <v-card class="pa-2 my-2 rounded-xl" width="100%" elevation="6">
        <v-icon color="exercise">
          {{ mergedActivities.length > 0 ? "mdi-circle" : "mdi-circle-outline" }}
        </v-icon>
        <span class="pl-2 p-text">{{
          mergedActivities.length > 0 ? activityTitle(mergedActivities[0].node) : "No exercise logged"
        }}</span>
      </v-card>
      <span v-if="mergedActivities.length > 1" @click="showMore = !showMore" class="pointer caption ml-2 pt-2">
        <v-icon>
          {{ showMore ? "remove" : "add" }}
        </v-icon>
      </span>
    </div>
    <div v-if="showMore">
      <v-card
        v-for="act in mergedActivities.slice(1)"
        :key="act.node.id"
        class="pa-2 ml-n7 my-4 rounded-xl"
        width="93%"
        elevation="6"
      >
        <v-icon color="exercise">
          mdi-circle
        </v-icon>
        <span class="pl-2 p-text">{{ activityTitle(act.node) }}</span>
      </v-card>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserMixin from "@/mixins/UserMixin";
import { USER_ACTIVITIES_QUERY } from "@/graphql/queries/tracker";
import { WORKOUT_BLOCK_WORKOUT_LOGS } from "@/graphql/queries/workoutblock";

export default {
  name: "ExerciseTracker",
  mixins: [UserMixin],
  data() {
    return {
      logCustomWorkoutDialog: false,
      showMore: false,
      workoutBlockWorkoutLogs: null,
    };
  },
  computed: {
    ...mapGetters(["selectedDate"]),
    exerciseEnabled() {
      return this.settingEnabled("exercise-tracking");
    },
    mergedActivities() {
      let exerciseLogs = [];
      // filter out anything not a workout, e.g ED
      if (this.workoutBlockWorkoutLogs && this.workoutBlockWorkoutLogs.length > 0) {
        exerciseLogs = this.workoutBlockWorkoutLogs.filter(function(log) {
          return log.node.unitActivity.type === "WORKOUT";
        });
      }
      return [...this.activities, ...exerciseLogs];
    },
  },
  methods: {
    formattedDate() {
      return this.selectedDate ? this.selectedDate.format("YYYY-MM-DD") : this.$moment().format("YYYY-MM-DD");
    },
    updateExceriseSetting(value) {
      this.upsertSetting("exercise-tracking", value);
    },
    activityTitle(activity) {
      // this must be a workoutBlockWorkoutLogs activity
      if (activity.unitActivity && activity.unitActivity.type == "WORKOUT") {
        return activity.unitActivity.activity.title;
      }
      if (activity.isCustom) {
        if (activity.type) {
          if (activity.type.name.toLowerCase() == "other") {
            return activity.activityType + " | Custom Activity";
          }
          return activity.type.name + " | Custom Activity";
        }
        return "Other | " + activity.activityType.replace("Other: ", "") + " | Custom Activity";
      }

      if (activity.event) {
        return activity.event.name;
      }
      if (activity.guidedRun) {
        return activity.guidedRun.name;
      }
      if (activity.action?.workout?.label) {
        return activity.action.workout.label;
      }
    },
    isExcerise(activity) {
      if (activity.node.event) {
        return activity.node.event?.eventType?.category?.toLowerCase() == "exercise";
      } else if (activity.node.workout) {
        return true;
      } else if (activity.node.guidedRun) {
        return activity.node.guidedRun?.category?.toLowerCase() != "meditation";
      } else if (activity.node.isCustom && activity.node.type) {
        return activity.node.type?.category?.toLowerCase() == "exercise";
      } else if (activity.node.action) {
        return true;
      } else {
        return false;
      }
    },
  },
  apollo: {
    activities: {
      query: USER_ACTIVITIES_QUERY,
      variables() {
        return {
          selectedDate: this.formattedDate(),
        };
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        const activityList = data.activities.edges.sort((a, b) => (a.node.createdAt < b.node.createdAt ? 1 : -1));
        return activityList.filter((act) => this.isExcerise(act));
      },
    },
    workoutBlockWorkoutLogs: {
      query: WORKOUT_BLOCK_WORKOUT_LOGS,
      fetchPolicy: "network-only",
      variables() {
        return {
          date: this.formattedDate(),
        };
      },
      result(response) {
        if (response.data) {
          this.workoutBlockWorkoutLogs = response.data.workoutBlockWorkoutLogs.edges;
        }
      },
    },
  },
};
</script>
