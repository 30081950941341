import gql from "graphql-tag";

export const GOAL_BlOCKS_QUERY = gql`
  query home {
    home {
      subscriptionBlocks {
        id
        endDate
        block {
          id
          title
          type
        }
      }
    }
  }
`;

export const FOCUSES_QUERY = gql`
  query focuses {
    nutritionFocuses {
      id
      title
    }
  }
`;

export const WEEKLY_GOAL_QUERY = gql`
  query weeklyGoal($weekStart: Date!) {
    weeklyGoals(weekStart: $weekStart)
  }
`;
