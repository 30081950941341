<template>
  <v-container>
    <div class="d-flex flex-column text-center justify-center">
      <h1>{{ title }}</h1>
      <h4 class="my-6 std-text">{{ description }}</h4>
    </div>
    <v-row justify="center" align="center">
      <v-col cols="12" md="7">
        <j-card class="pt-4">
          <v-card-text>
            <v-row>
              <v-col>
                <ValidationObserver ref="observer" v-slot="{ invalid }">
                  <v-row>
                    <v-col cols="12" md="6" class="pb-0 mb-n6">
                      <ValidationProvider id="firstName" v-slot="{ errors }" name="First Name" rules="required">
                        <j-text-field
                          v-model="dealData.firstName"
                          label="First Name"
                          outlined
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" md="6" class="pb-0 mb-n6">
                      <ValidationProvider id="lastName" v-slot="{ errors }" name="Last Name" rules="required">
                        <j-text-field v-model="dealData.lastName" label="Last Name" outlined :error-messages="errors" />
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12" md="6" class="pb-0 mb-n6">
                      <ValidationProvider id="email" v-slot="{ errors }" name="Email" rules="required|email">
                        <j-text-field v-model="dealData.email" label="Email" outlined :error-messages="errors" />
                      </ValidationProvider>
                    </v-col>
                    <v-col cols="12" md="6" class="pb-0 mb-n6">
                      <ValidationProvider id="phone" v-slot="{ errors }" name="Contact Number" rules="required">
                        <j-text-field
                          v-model="dealData.phone"
                          label="Contact Number"
                          outlined
                          :error-messages="errors"
                        />
                      </ValidationProvider>
                    </v-col>

                    <v-col cols="12" class="pb-0 mb-n6">
                      <j-textarea
                        v-model="dealData.note"
                        multi
                        outlined
                        label="Tell us more about yourself"
                      />
                    </v-col>
                  </v-row>

                  <v-row align="center" justify="center" :disabled="invalid">
                    <j-alert v-if="message" class="mt-6">
                      {{ message }}
                    </j-alert>
                    <j-btn secondary large wide :loading="loading" @click.prevent="submit()" class="mb-6 mt-2">
                      Submit
                    </j-btn>
                  </v-row>
                </ValidationObserver>
              </v-col>
            </v-row>
          </v-card-text>
        </j-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { UPSERT_DEAL_MUTATION } from "@/graphql/mutations/account";
import { mapGetters } from "vuex";

export default {
  name: "CreateDeal",
  data() {
    return {
      loading: false,
      message: "",
      isOneOnOneInfoPack: false,
      dealData: {
        firstName: "",
        lastName: "",
        phone: "",
        email: "",
        note: "",
        dealTypeCode: "CHECKOUT",
        attributionSource: "DEALFORM",
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
    title() {
      if (this.isOneOnOneInfoPack) {
        return "Want to know more about 1-on-1 Coaching?";
      }
      return "Have some questions?";
    },
    description() {
      if (this.isOneOnOneInfoPack) {
        return "Fill in the form below to download your 1-on-1 Info Pack with additional nutrition tips and recipes.";
      }
      return "Fill in the form below and one of our team members will be in touch.";
    },
  },
  created() {
    this.dealData.firstName = this.user?.firstName;
    this.dealData.lastName = this.user?.lastName;
    this.dealData.email = this.user?.email;
    this.dealData.phone = this.user?.userProfile?.phone;

    this.isOneOnOneInfoPack = !!this.$route.query.infopack;
  },
  methods: {
    async submit() {
      const valid = await this.$refs.observer.validate();
      if (!valid) return;

      this.loading = true;

      const { data } = await this.$apollo.mutate({
        mutation: UPSERT_DEAL_MUTATION,
        variables: {
          data: this.dealData,
        },
      });

      if (data.upsertDealMutation.success) {
        if (this.isOneOnOneInfoPack) {
          return this.$router.push("/info-pack-download");
        } else {
          this.message = "Thank you! We will be in touch shortly. You can close this window now.";
        }
        
      } else {
        this.message = "Error submitting form. Please check your details and try again.";
      }
      this.loading = false;
    },
  },
};
</script>
