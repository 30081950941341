<template>
  <!-- wip -->
  <j-card rounded="sm" elevation="0">
    <div class="d-flex align-center pa-2">
      <v-avatar
        v-if="showAvatar"
        size="40"
        class="mr-2"
        :class="{ pointer: navigateTo }"
        :color="getRandomColour()"
        @click="navigate()"
      >
        <img v-if="avatarUrl" :src="avatarUrl" />
        <span v-else class="white--text font-weight-bold">{{ title.charAt(0).toUpperCase() }}</span>
      </v-avatar>

      <div>
        <div class="font-weight-bold text-left" :class="getTitleClass()" @click="navigate()">
          {{ title }}
        </div>
        <div class="darker-grey--text text-left" v-html="subtitle" />
      </div>

      <div class="ml-auto">
        <slot name="trailing"></slot>
      </div>
    </div>
  </j-card>
</template>

<script>
export default {
  name: "JAvatarCard",
  props: {
    // maybe a bit weird to have this on an avatar card, but there are cases where we want to use this, without the icon on the left.
    showAvatar: {
      type: Boolean,
      default: true,
    },
    avatarUrl: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
    },
    colour: {
      type: String,
    },
    navigateTo: {
      type: String,
    },
    titleClass: {
      type: String,
      default: "",
    },
  },
  methods: {
    getRandomColour() {
      if (this.avatarUrl) {
        return "white";
      }
      const colours = ["pink", "green", "yellow", "navy", "blue", "red"];
      return this.colour ? this.colour : colours[Math.floor(Math.random() * colours.length)];
    },
    navigate() {
      if (this.navigateTo) {
        return this.$router.push(this.navigateTo);
      }
    },
    getTitleClass() {
      let cssClass = "";
      if (this.navigateTo) {
        cssClass += "pointer ";
      }
      if (this.titleClass) {
        cssClass += `${this.titleClass} `;
      } else {
        cssClass += `navy--text `;
      }
      return cssClass;
    },
  },
};
</script>
