export default {
  name: "ProfileLinkMixin",
  methods: {
    profileLink(user) {
      if (parseInt(user.id)) {
        return `/p/${btoa(`UserNode: ${user.id}`)}`;
      }
      return `/p/${user.id}`;
    },
  },
};
