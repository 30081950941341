<template>
  <v-row v-if="$apollo.loading" align="center" justify="center" class="my-4">
    <v-col class="col-auto px-0">
      <v-skeleton-loader type="list-item-avatar" />
      <v-skeleton-loader type="avatar@7" class="d-flex ml-4" />
    </v-col>
  </v-row>
  <v-row align="center" justify="center" class="chat-member-card" v-else>
    <v-col v-if="!showDetails"
      ><div class="d-flex align-center justify-center">
        <user-avatar :profile-picture="user.userProfile.profilePicture" :user="user" size="45" />
        <div class="navy--text font-weight-medium ml-3">{{ user.firstName }} {{ user.lastName }}</div>
      </div>
      <p class="mt-2 font-italic text-center">Chose to hide week overview</p>
    </v-col>

    <v-col v-else cols="12" class="relative pt-0">
      <v-row align="center" justify="center">
        <v-col class="relative">
          <v-tabs v-if="!$apollo.loading" :centered="true" color="secondary" slider-size="0">
            <div class="d-flex flex-column activity-icons">
              <div class="related-avatar d-flex align-center">
                <user-avatar :profile-picture="user.userProfile.profilePicture" :user="user" size="45" />
                <div class="navy--text font-weight-medium ml-3">{{ user.firstName }} {{ user.lastName }}</div>
              </div>
              <v-icon color="pink" size="18" class="py-special">mdi-run</v-icon>
              <v-icon color="green" size="18" class="py-special">mdi-silverware-fork-knife</v-icon>
            </div>
            <v-tab v-for="(key, index) in days" :key="index" class="px-0 day-tab mw-40 fill-height" align="start">
              <calendar-dot-tab
                v-if="eligible"
                :showSimpleTab="true"
                :active="false"
                :prizesEnabled="prizesEnabled"
                :workoutImage="eligible.dayBreakdown[index].workoutImage"
                :loggedWorkout="eligible.dayBreakdown[index].loggedWorkout"
                :greenDay="eligible.dayBreakdown[index].greenDay"
                :loggedNutrition="eligible.dayBreakdown[index].loggedNutrition"
                :qualified="eligible.qualifiedForPrize"
                :restLogged="eligible.dayBreakdown[index].isRestDay"
              />
            </v-tab>
            <div class="prize-summary-wrapper d-flex flex-column">
              <div
                v-if="prizesEnabled"
                class="prize-summary elevation-3 flex-shrink-1"
                @click.stop="showPrizeModal = true"
                :style="prizeSummaryMarginTop"
              >
                <prize-summary-pill :qualifiedForPrize="eligible.qualifiedForPrize" :activePrize="activePrize" />
              </div>
            </div>
          </v-tabs>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog
      v-if="showPrizeModal"
      v-model="showPrizeModal"
      max-width="600"
      transition="dialog-bottom-transition"
      class="limited-height"
    >
      <prize-details :qualified="eligible.qualifiedForPrize" @closed="showPrizeModal = false" />
    </v-dialog>
  </v-row>
</template>

<script>
import { PRIZE_ELIGIBILITY_QUERY } from "@/graphql/queries/prizeCalendar";
import { mapGetters } from "vuex";
import { formatDate } from "@/lib/fancy_date";
import { mapActions } from "vuex";
import UserAvatar from "@/components/shared/user/UserAvatar";
import CalendarDotMixin from "@/mixins/CalendarDotMixin";
import PrizeDetails from "@/components/shared/dialogs/PrizeDetails";
import CalendarDotTab from "@/components/shared/sections/CalendarDotTab";
import PrizeSummaryPill from "@/components/shared/blocks/PrizeSummaryPill";

export default {
  name: "ChatMemberCard",
  mixins: [CalendarDotMixin],
  components: {
    UserAvatar,
    CalendarDotTab,
    PrizeDetails,
    PrizeSummaryPill,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    monday: {
      type: String,
      required: true,
    },
    activePrize: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
    showDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showPrizeModal: false,
    };
  },
  filters: {
    fancyDate(val) {
      return formatDate(val, "dddd, D MMM YYYY");
    },
  },
  methods: {
    ...mapActions(["setSelectedDate"]),
    setDay() {
      if (!this.selectedDate) {
        this.setSelectedDate(this.$moment());
      }
    },
  },
  computed: {
    ...mapGetters(["selectedDate", "role"]),
    prizeSummaryMarginTop() {
      let prizeCount =
        (this.activePrize.entryCopy != null) +
        (this.activePrize.drawCopy != null) +
        (this.activePrize.rewardsCopy != null);
      switch (prizeCount) {
        case 1:
        case 2:
          return "margin-top: 10px";
      }
      return "";
    },
    prizesEnabled() {
      return this.activePrize && ["ONE_ON_ONE", "CLUB"].includes(this.role);
    },
  },
  apollo: {
    eligible: {
      query: PRIZE_ELIGIBILITY_QUERY,
      variables() {
        return {
          date: this.monday,
          userId: this.user.id,
        };
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        return data.userPrizeWeeklyEligibility;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-member-card {
  .mw-40 {
    min-width: 40px;
  }

  .related-avatar {
    position: absolute;
    top: -50px;
  }
}

.chat-member-card .v-tabs {
  .activity-icons {
    .py-special {
      padding: 15px 0;
    }
  }

  .v-tab--active.active::before {
    opacity: 0.12;
    border-radius: 25px;
  }

  .v-tab:hover::before {
    border-radius: 25px;
  }

  .prize-summary {
    border: 3px solid var(--v-yellow-base);
    background-color: white;
    min-height: 70px;
    max-height: 100px;
    z-index: 2;

    &,
    div {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      border-radius: 100px;
    }

    .icon-wrapper .v-icon {
      padding: 3px 0;
      width: 30px;
    }
  }
}
</style>

<style lang="scss">
.chat-member-card {
  .v-item-group {
    height: 100%;
  }

  .v-slide-group__content {
    margin-top: 50px;
  }

  .half-tracking-icon {
    position: absolute;
    left: unset;
  }

  .nutrition-no {
    left: unset;
    width: 25px;
  }

  .v-tab {
    .btn-wrapper .v-btn {
      display: contents;
    }

    .day-tracking .prize-line {
      margin-left: 17px;
      left: 11px;
    }
    .day-tracking.mx-1 .prize-line {
      margin-left: 7px;
      left: 20px;
    }
  }
}
</style>
