export default {
  data() {
    return {
      time: 0,
      interval: null,
    };
  },
  methods: {
    hasUserMedia() {
      // TODO enable for now for testing?
      return true;       
      // let userMedia =
      //   navigator.getUserMedia || navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia;
      // return !!userMedia;
    },
    startTimer() {
      this.time = 0;
      this.interval = setInterval(this.incrementTime, 1000);
    },
    incrementTime() {
      this.time = parseInt(this.time) + 1;
    },
    stopTimer() {
      clearInterval(this.interval);
    },
  },
};
