<template>
  <div v-if="showTimer" class="wrapper" :style="`background-image: url('${blockCountdownTimer.image}');`">
    <div>
      <div class="countdown-title">
        <div class="countdown-title-bg">{{ blockCountdownTimer.description }}</div>
      </div>
      <generic-countdown-timer @timerEnded="timerEnded" :milisecondsToEnd="blockCountdownTimer.milisecondsToEnd" />
    </div>
  </div>
</template>

<script>
import GenericCountdownTimer from "@/components/shared/GenericCountdownTimer";

export default {
  name: "BlockCountdownTimer",
  components: {
    GenericCountdownTimer,
  },
  props: {
    blockCountdownTimer: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showTimer: true,
    };
  },
  methods: {
    timerEnded() {
      this.showTimer = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  border-radius: 40px;
  background-repeat: no-repeat;
  background-size: cover;
}

.countdown-title {
  padding-top: 10px;
  color: white;
  font-size: 18px;
  font-weight: bold;

  .countdown-title-bg {
    display: inline-block;
    padding: 5px 30px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 40px;
  }
}

h1 {
  font-weight: normal;
  letter-spacing: 0.125rem;
  text-transform: uppercase;
}

li {
  display: inline-block;
  font-size: 1em;
  list-style-type: none;
  padding: 1em;
  text-transform: uppercase;
  color: white;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.37);
}
.large-padding {
  padding-right: 3.5em;
}

li span {
  display: block;
  font-size: 3.5rem;
}

@media all and (max-width: 768px) {
  h1 {
    font-size: calc(1.5rem * var(--smaller));
  }

  li {
    font-size: calc(1.125rem * var(--smaller));
  }

  li span {
    font-size: calc(3.375rem * var(--smaller));
  }
}
</style>
```
