<template>
  <div class="j-avatar-list" :class="['d-flex', 'align-center', { 'justify-center': justifyCenter }]">
    <v-avatar :class="size" :color="getRandomColour()" v-for="user in userList.slice(0, this.maxItems)" :key="user.id" :size="avatarSize">
      <img v-if="getProfilePicture(user)" :src="getProfilePicture(user)" />
      <span v-else class="white--text font-weight-bold">{{ user.firstName.charAt(0).toUpperCase() }}</span>
    </v-avatar>
  </div>
</template>

<script>
export default {
  name: "JAvatarList",
  props: {
    userList: {
      type: Array,
      required: true,
    },
    maxItems: {
      type: Number,
      default: 5,
    },
    showMoreIcon: {
      type: Boolean,
      default: true,
    },
    forceShowMoreIcon: {
      type: Boolean,
      default: false
    },
    justifyCenter: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'medium',
      validator(value){ return ['small', 'medium'].includes(value) }
    },
    accentColor: {
      type: String,
      default: "pink",
    },
  },
  computed: {
    avatarSize() {
      return {
        'small': 24,
        'medium': 36
      }[this.size];
    }
  },
  methods: {
    getRandomColour() {
      let colours = ["pink", "green", "yellow", "navy", "blue", "red"];
      if (colours.includes(this.accentColor))
        colours.splice(colours.indexOf(this.accentColor), 1);
      return colours[Math.floor(Math.random() * colours.length)];
    },
    getProfilePicture(user) {
      // bloddy js doesn't work like you would expect it to
      //return user?.profilePicture ?? user.userProfile.profilePicture ?? null;
      if(user.profilePicture) {
        return user.profilePicture
      }
      if(user.userProfile && user.userProfile.profilePicture) {
        return user.userProfile.profilePicture;
      }
      return null;
      
    },
  },
};
</script>

<style lang="scss" scoped>
.j-avatar-list {
  @for $index from 0 to 9 {
    .v-avatar:nth-last-child(#{$index + 1}) {
      z-index: $index;
    }
  }
}
.small {
  margin-left: -4px;
}
.medium {
  margin-left: -14px;
}
</style>
