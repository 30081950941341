import Vue from "vue";
import { extend, ValidationProvider, ValidationObserver, setInteractionMode } from "vee-validate";
import {
  required,
  email,
  min,
  confirmed,
  is,
  numeric,
  regex,
  max,
  max_value,
  integer,
  min_value,
  digits,
  alpha_num,
} from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en";

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

extend("required", { ...required, message: en.messages["required"] });
extend("email", { ...email, message: en.messages["email"] });
extend("min", { ...min, message: en.messages["min"] });
extend("confirmed", { ...confirmed, message: en.messages["confirmed"] });
extend("is", { ...is, message: en.messages["is"] });
extend("numeric", { ...numeric, message: en.messages["numeric"] });
extend("max", { ...max, message: en.messages["max"] });
extend("regex", { ...regex, message: en.messages["regex"] });
extend("max_value", { ...max_value, message: en.messages["max_value"] });
extend("integer", { ...integer, message: en.messages["integer"] });
extend("min_value", { ...min_value, message: en.messages["min_value"] });
extend("digits", { ...digits, message: en.messages["digits"] });
extend("required_if", { ...required, message: en.messages["required_if"] });
extend("alpha_num", { ...alpha_num, message: en.messages["alpha_num"] });
// const checkLuhn = function(cardNo) {
//   var s = 0;
//   var doubleDigit = false;
//   for (var i = cardNo.length - 1; i >= 0; i--) {
//     var digit = +cardNo[i];
//     if (doubleDigit) {
//       digit *= 2;
//       if (digit > 9) digit -= 9;
//     }
//     s += digit;
//     doubleDigit = !doubleDigit;
//   }
//   return s % 10 === 0;
// };

// extend("verify_password", (value) => {
//   var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{6,})");
//   if (strongRegex.test(value)) {
//     return true;
//   }
//   return `The password must contain at least: 1 uppercase letter, 1 lowercase letter and 1 number`;
// });
extend("verify_password", (value) => {
  if (value && value.length >= 6) {
    return true;
  }
  return `The password should be longer than 6 characters`;
});

extend("url", {
  getMessage: (field) => "The " + field + " value is not a valid link.",
  validate: (value) => {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "igm"
    ); // fragment locator
    return !!pattern.test(value);
  },
});

extend("phone_number", (value) => {
  let pattern = new RegExp("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\./0-9]*$", "g");
  if (pattern.test(value)) {
    return true;
  }
  return "Please enter a valid phone number. Allowed characters are: Numbers, spaces, dashes and brackets.";
});

extend("validDecimal", (value) => {
  //example 1234.546
  //Checks that value does not start with dot/comma
  let dotRegex = /^(\d){1,3}(\.|,)?/;
  if (!dotRegex.test(value)) {
    return "The {_field_} field cannot only be a decimal value";
  }

  //Checks that value starts with 1-3 digits before either ending or having a dot or comma.
  let digitRegex = /^(\d){1,3}(($)|(\.|,))/;
  if (!digitRegex.test(value)) {
    return "The {_field_} field value cannot be larger than 999";
  }

  //Checks that value either ends with dot/comma and 0-2 digits OR starts with digits and then ends (no comma/dot)
  let decimalRegex = /((\.|,)(\d){0,2})$|^(\d){1,3}$/;
  if (!decimalRegex.test(value)) {
    return "The {_field_} field value cannot have more than 2 decimal places";
  }

  return true;
});

extend("creditCard", (value, [type]) => {
  let cardFormatter = Vue.prototype.$cardFormat;

  if (!["visa", "mastercard"].includes(type)) {
    return "Invalid Credit Card type. Please use VISA or MasterCard.";
  }

  if (!cardFormatter.validateCardNumber(value)) {
    return "Invalid Credit Card Number.";
  }
  return true;
});

extend(
  "cardExpiryMonth",
  (month, [year]) => {
    let cardFormatter = Vue.prototype.$cardFormat;
    if (year != null && !cardFormatter.validateCardExpiry(month, year)) {
      return "Invalid Expiry Date";
    }
    return true;
  },
  {
    hasTarget: true,
  }
);

extend(
  "cardExpiryYear",
  (year, [month]) => {
    let cardFormatter = Vue.prototype.$cardFormat;
    if (month != null && !cardFormatter.validateCardExpiry(month, year)) {
      return "Invalid Expiry Date";
    }
    return true;
  },
  {
    hasTarget: true,
  }
);

extend("cardCVC", (cvc, [type]) => {
  let cardFormatter = Vue.prototype.$cardFormat;
  if (cardFormatter.validateCardCVC(cvc, type)) {
    return "Invalid CVV Number";
  }
  return true;
});

extend("noDecimals", (v) => {
  if (!v) return "Required";
  // why does vee-validate say `includes` is not a function?? Supress in a try catch.
  try {
    if (v.includes(".")) return "Decimals are not allowed in {_field_}";
    if (v.includes(",")) return "Decimals are not allowed in {_field_}";
  } catch (e) {
    // do nothing
    e;
  }

  if (!isNaN(parseInt(v))) return true;
  return "Decimals are not allowed in {_field_}";
});

extend("threeDigits", (v) => {
  if ((v && v.toString().length != 3) || isNaN(v)) {
    return "{_field_} should be three digits exactly";
  }
  return true;
});

extend("minWeight", (v) => {
  if (v < 40) {
    return "That is an unrealistic goal";
  }
  return true;
});

extend("idNumber", (idNumber) => {
  //Ref: http://www.sadev.co.za/content/what-south-african-id-number-made
  // SA ID Number have to be 13 digits, so check the length
  if (idNumber.length != 13 || isNaN(parseFloat(idNumber))) {
    return "Invalid ID Number";
  }

  // get first 6 digits as a valid date
  let tempDate = new Date(idNumber.substring(0, 2), idNumber.substring(2, 4) - 1, idNumber.substring(4, 6));

  let id_date = tempDate.getDate();
  let id_month = tempDate.getMonth();
  //let id_year = tempDate.getFullYear();

  if (
    !(
      tempDate.getYear() == idNumber.substring(0, 2) &&
      id_month == idNumber.substring(2, 4) - 1 &&
      id_date == idNumber.substring(4, 6)
    )
  ) {
    return "Invalid ID Number";
  }

  // get the gender
  //let genderCode = idNumber.substring(6, 10);
  //let gender = parseInt(genderCode) < 5000 ? "Female" : "Male";

  // get country ID for citzenship
  //let citzenship = parseInt(idNumber.substring(10, 11)) == 0 ? "Yes" : "No";

  // apply Luhn formula for check-digits
  let tempTotal = 0;
  let checkSum = 0;
  let multiplier = 1;
  for (let i = 0; i < 13; ++i) {
    tempTotal = parseInt(idNumber.charAt(i)) * multiplier;
    if (tempTotal > 9) {
      tempTotal = parseInt(tempTotal.toString().charAt(0)) + parseInt(tempTotal.toString().charAt(1));
    }
    checkSum = checkSum + tempTotal;
    multiplier = multiplier % 2 == 0 ? 1 : 2;
  }
  if (checkSum % 10 != 0) {
    return "Invalid ID Number";
  }

  return true;
});

setInteractionMode("eager");
