<template>
  <j-card class="mt-5 j-elevated-5">
    <div v-if="showNewChip" class="text-left pt-3 pl-3">
      <v-chip small color="yellow" class="new-chip mb-n3">NEW</v-chip>
    </div>

    <v-card-title class="grey-navy--text flex-nowrap">
      <h4 v-if="event.category" class="text-left">{{ event.category.title }}</h4>
      <h4 v-else class="text-left">{{ event.description }}</h4>
      <v-spacer />
      <div :class="infoLabelClass" class="justify-end align-self-baseline d-flex flex-shrink-0">{{ infoLabel }}</div>
    </v-card-title>
    <v-card-subtitle class="text-left"
      >{{ event.eventDate | humanize_yyyy_mm_dd((day = false)) }}
      <p v-if="event.description" class="mt-2">{{ event.description }}</p>
      <p v-if="event.discount && event.discount.code" class="font-weight-bold mt-n2">Code used: {{ event.discount.code }}</p>
    </v-card-subtitle>
    <v-card-subtitle v-if="event.declinedReason">Declined Reason: {{ event.declinedReason }}</v-card-subtitle>
    <div v-if="event.discount && !event.discount.code" class="pb-5">
      <j-card class="mx-3 background-grey">
        <v-card-subtitle class="pa-4 ma-0 font-weight-bold"
          >These points were automatically redeemed against your subscription payment.</v-card-subtitle
        >
      </j-card>
    </div>
  </j-card>
</template>

<script>
export default {
  name: "RewardEventCard",
  props: {
    event: {
      type: Object,
      default: null,
    },
  },
  computed: {
    showNewChip() {
      return this.event.category && this.event.category.type === 'EARN' && !this.event.seen;
    },
    infoLabel() {
      if (this.event.approvalStatus === "PENDING") return "Pending";

      if (this.event.approvalStatus === "DECLINED") return "Declined";

      if (this.event.points > 0) return "+ " + this.event.points;

      return "- " + Math.abs(this.event.points);
    },
    infoLabelClass() {
      if (this.event.approvalStatus === "APPROVED") return "green--text";
      else if (this.event.approvalStatus === "DECLINED") return "red--text";
      else if (this.event.approvalStatus === "PENDING") return "yellow--text";
      else if (this.event.points > 0) return "green--text";
      else return "grey--text";
    },
  },
};
</script>

<style scoped>

.new-chip {
  color: white;
  font-weight: bold;
}

</style>
