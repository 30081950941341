<template>
  <div>
    <h3>{{ surveyQuestion.question.title }} <span v-if="surveyQuestion.isRequired"> * </span></h3>

    <v-select
      v-model="selectedBool"
      @change="handleSave(selectedBool)"
      item-text="title"
      item-value="id"
      :items="boolOptions"
    >
    </v-select>

    <div v-for="child in surveyQuestion.children" :key="child.id" class="my-4">
      <question-block :surveyQuestion="child" />
    </div>
  </div>
</template>

<script>
import SurveyMixin from "@/mixins/SurveyMixin";

export default {
  name: "BoolControl",
  mixins: [SurveyMixin],
  props: {
    surveyQuestion: {
      required: true,
    },
  },
  data() {
    return {
      // note that we will just use the answer for boolean type questions. Inserting Yes or No
      selectedBool: null,
      boolOptions: [
        { title: "Yes", id: "Yes" },
        { title: "No", id: "No" },
      ],
    };
  },
};
</script>
