<template>
  <v-alert class="text-center" border="right" colored-border :color="borderColor" elevation="2" dense>
    <a :href="attachment" target="_blank" class="text-decoration-none">
      <v-avatar :size="30">
        <img :src="require(`@/assets/images/${icon}.png`)" />
      </v-avatar>
      <span class="p-text font-weight-bold mt-1 ml-2">{{ title }}</span></a
    >
  </v-alert>
</template>

<script>
export default {
  name: "PlanDownloadCard",
  props: {
    attachment: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: "Eating Guidelines",
    },
    borderColor: {
      type: String,
      default: "nutrition",
    },
    icon: {
      type: String,
      default: "nutrition_icon",
    },
  },
};
</script>
