import gql from "graphql-tag";

export const USER_CHECK_IN_QUERY = gql`
  query userCheckIn($id: ID!) {
    userCheckIn(id: $id) {
      id
      weight
      abdomen
      chest
      hip
      quad
      oneMile
      pushUps
      sitUps
      burpeeChirpee
      elbowPlank
      squat
      imgFront
      imgBack
      imgSide
      assessmentDate
      createdAt
      rating
      nutritionRating
      comments
      nutritionComments
      user {
        id
      }
    }
  }
`;

export const USER_CHECK_INS_QUERY = gql`
  query checkInsQuery($first: Int, $assessmentDate_Isnull: Boolean, $orderBy: String) {
    userCheckIns(first: $first, assessmentDate_Isnull: $assessmentDate_Isnull, orderBy: $orderBy) {
      edges {
        node {
          id
          weight
          abdomen
          chest
          hip
          quad
          oneMile
          pushUps
          sitUps
          burpeeChirpee
          elbowPlank
          squat
          imgFront
          imgBack
          imgSide
          createdAt
          assessmentDate
          requiredDate
          type
          rating
          nutritionRating
          comments
          nutritionComments
          user {
            id
          }
        }
      }
    }
  }
`;

export const COACHING_CLIENT_GOALS_QUERY = gql`
  query clientGoals($userId: ID!) {
    clientGoals(userId: $userId, active: true) {
      id
      ultimateWhy
      timeframe
      type
      category
      dueDate
      status
      achievedAt
      goalMeasurement {
        id
        initialValue
        targetValue
        unit
        targetText
      }
    }
  }
`;
