<template>
  <j-card rounded="lg">
    <v-toolbar class="mb-6">
      <v-toolbar-title v-if="group">Update {{ group.title }}</v-toolbar-title>
      <v-toolbar-title v-else>Create a Group</v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="$emit('closed')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <span v-if="!group">
        <h3>Create your own group for extra accountability</h3>
        <p>Search for people, or you can invite your friends to join {{ $appName }} after you create your group.</p>
      </span>

      <j-text-field placeholder="Give the group a name" v-model="groupName" />

      <h3>Group permissions</h3>
      <v-radio-group v-model="permissionType">
        <j-radio
          v-for="groupTypeOption in permissionTypeOptions"
          :key="groupTypeOption.value"
          :label="groupTypeOption.text"
          :value="groupTypeOption.value"
        />
      </v-radio-group>

      <j-text-field
        v-model="searchString"
        @keyup="searchUsers()"
        prepend-inner-icon="mdi-magnify"
        placeholder="Add people"
      ></j-text-field>

      <div v-if="users">
        <j-avatar-card
          v-for="(user, index) in users"
          :key="user.id"
          :title="`${user.firstName} ${user.lastName}`"
          :avatar-url="user.userProfile.profilePicture"
          :colour="colours[index]"
          class="mb-2"
        >
          <template v-slot:trailing>
            <j-checkbox v-model="selectedUsers" :value="user.id" />
          </template>
        </j-avatar-card>
      </div>
    </v-card-text>
    <v-card-actions class="justify-center">
      <j-btn primary @click="confirm()">
        <strong class="white--text" v-if="group">UPDATE</strong>
        <strong class="white--text" v-else>CREATE</strong>
      </j-btn>
    </v-card-actions>
  </j-card>
</template>

<script>
import { CLIENTS_QUERY } from "@/graphql/queries/account";

export default {
  name: "CreateEditGroup",
  data() {
    return {
      groupName: null,
      permissionTypeOptions: [
        { text: "Anyone can join", value: "PUBLIC" },
        { text: "Private. I need to add people myself", value: "PRIVATE" },
        // Requires all sorts of workflow. i.e approve/deny by admins
        // {text: "I need to approve", value: 'REQUIRES_APPROVAL'},
      ],
      permissionType: null,
      keyUpSearchInterval: null,
      users: null,
      selectedUsers: [],
      searchString: null,
      // NB, limit must correspond or be less than the number of items in colour array, otherwise this will bomb
      // I can't set the random colour in Javatarcard, since on click of checkbox, it then changes the colour, which is weird.
      limit: 6,
      colours: ["pink", "green", "yellow", "navy", "blue", "red"],
    };
  },
  props: {
    workoutBlock: {
      type: Object,
      required: false,
    },
    // if editing
    group: {
      type: Object,
      required: false,
    },
  },
  watch: {
    group() {
      this.setData();
    },
  },
  methods: {
    searchUsers() {
      if (this.keyUpSearchInterval) {
        clearTimeout(this.keyUpSearchInterval);
        this.keyUpSearchInterval = null;
      }
      this.keyUpSearchInterval = setTimeout(() => {
        this.doSearch();
      }, 1000);
    },
    doSearch() {
      if (!this.searchString) {
        if (!this.group) {
          this.users = null;
        } else {
          this.users = this.group.users;
        }
        return;
      }

      let data = {
        first: this.limit,
        search: this.searchString,
      };
      if (!this.$store.getters.user.isStaff) {
        data["userType"] = "User";
        data["userSubscriptionsActive"] = true;
        data["userIsStaff"] = false;
      }

      // no need to paginate. let's think about it...the user is trying to find and add people one at a time.
      // showing `$limit` is enough. Will JEFF have more than `$limit` people of the same name and surname? if so, we can deal with i then.
      this.$apollo
        .query({
          query: CLIENTS_QUERY,
          variables: data,
        })
        .then((response) => {
          let users = [];
          for (const userNode of response.data.clients.edges) {
            let user = {
              userProfile: {},
            };
            user.id = userNode.node.user.id;
            user.firstName = userNode.node.user.firstName;
            user.lastName = userNode.node.user.lastName;
            user.userProfile.profilePicture = userNode.node.profilePicture;
            users.push(user);
          }
          this.users = users;
        });
    },
    confirm() {
      let data = {
        groupName: this.groupName,
        permission: this.permissionType,
        selectedUsers: this.selectedUsers,
      };
      if (this.group) {
        data.id = this.group.id;
      }
      this.$emit("saved", data);
    },
    setData() {
      if (this.group) {
        this.permissionType = this.group.permission;
        this.groupName = this.group.title;
        this.users = this.group.users;
        this.selectedUsers = this.users.map((user) => {
          return user.id;
        });
      } else {
        this.permissionType = null;
        this.groupName = null;
        this.users = null;
        this.selectedUsers = [];
      }
    },
  },
  created() {
    this.setData();
  },
};
</script>
