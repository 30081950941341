<template>
  <v-radio-group v-bind="$props" v-on="$listeners">
    <slot />
  </v-radio-group>
</template>

<script>
import { VRadioGroup } from "vuetify/lib";

export default {
  name: "JRadioGroup",
  extends: VRadioGroup
}
</script>