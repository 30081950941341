<template>
  <v-skeleton-loader v-if="$apollo.loading" type="card-heading" />
  <div v-else>
    <div class="d-flex justify-space-between">
      <h4>Sleep</h4>
      <v-switch class="py-0 my-0" hide-details :input-value="sleepEnabled" @change="updateSleepSetting($event)" />
    </div>
    <div v-if="sleepEnabled" class="pb-4">
      <div class="d-inline-flex ml-2">
        <v-icon :color="isCompliant ? 'sleep' : 'grey'">{{ sleepLog ? "mdi-circle" : "mdi-circle-outline" }}</v-icon>
        <v-select
          v-model="sleep.hours"
          :items="sleepHours"
          @change="updateSleep()"
          single-line
          hide-details
          label="hours"
          class="mx-3 pt-0"
          style="max-width:180px"
        />
        <v-select
          v-model="sleep.minutes"
          :items="sleepMinutes"
          @change="updateSleep()"
          single-line
          hide-details
          label="minutes"
          class="pt-0"
          style="max-width:120px"
        />
      </div>
      <div v-if="sleepLog && !isCompliant" class="p-text">Sleep 7 hours or more to get your sleep dot</div>
    </div>
  </div>
</template>

<script>
import { SLEEP_QUERY } from "@/graphql/queries/tracker";
import { mapGetters } from "vuex";
import { UPSERT_REFLECTION_MUTATION } from "@/graphql/mutations/tracker";
import UserMixin from "@/mixins/UserMixin";

export default {
  name: "SleepTracker",
  mixins: [UserMixin],
  data() {
    return {
      sleep: {
        minutes: null,
        hours: null,
      },
      sleepLog: null,
      loadingSleep: true,
      isCompliant: false
    };
  },
  computed: {
    ...mapGetters(["selectedDate"]),
    sleepHours() {
      let hours = Array.from(Array(15).keys());
      hours = hours.map((hour) => {
        return { value: hour, text: `${hour} hours` };
      });

      return hours;
    },
    sleepMinutes() {
      let minutes = [0, 15, 30, 45];
      minutes = minutes.map((minutes) => {
        return { value: minutes, text: `${minutes} minutes` };
      });

      return minutes;
    },
    sleepEnabled() {
      return this.settingEnabled("sleep-tracking");
    },
  },
  methods: {
    formattedDate() {
      return this.selectedDate ? this.selectedDate.format("YYYY-MM-DD") : this.$moment().format("YYYY-MM-DD");
    },
    updateSleepSetting(value) {
      this.upsertSetting("sleep-tracking", value);
    },
    sleepSeconds() {
      return this.sleep.hours * 60 * 60 + (this.sleep.minutes != null ? this.sleep.minutes * 60 : 0);
    },
    updateSleep() {
      if (this.sleep.hours != null) {
        this.isCompliant = this.sleep.hours >= 7;

        let input = {
          date: this.formattedDate(),
          sleep: this.sleepSeconds(),
          sleepCompliant: this.isCompliant,
        };

        this.$apollo
          .mutate({
            mutation: UPSERT_REFLECTION_MUTATION,
            variables: {
              input: input,
            },
            refetchQueries: ["activitiesQuery", "sleepQuery", "weeklyTrackerSummary"],
          })
          .then((response) => {
            if (response.data) {
              this.saving = false;
              if (!response.data.upsertReflectionLogs.success) {
                this.$toasted.error("Something went wrong. Please try again or contact Support", {
                  duration: 2000,
                  position: "bottom-center",
                });
              }
            }
          });
      }
    },
  },
  apollo: {
    sleepLog: {
      query: SLEEP_QUERY,
      variables() {
        return {
          date: this.formattedDate(),
        };
      },
      fetchPolicy: "cache-and-network",
      update(data) {
        this.loadingStress = false;
        if (data.sleepLog.edges && data.sleepLog.edges.length > 0) {
          let seconds = data.sleepLog.edges[0].node.seconds;
          let duration = this.$moment.duration(seconds, "seconds");

          this.sleep.hours = duration.hours();
          this.sleep.minutes = duration.minutes();

          return data.sleepLog.edges[0].node;
        } else {
          this.sleep.hours = null;
          this.sleep.minutes = null;
          return null;
        }
      },
    },
  },
};
</script>
