<template>
  <j-card rounded="lg" class="px-2">
    <v-toolbar class="mb-2 px-2" flat>
      <v-toolbar-title v-if="$vuetify.breakpoint.smAndUp" class="blue--text heading-2 font-weight-bold"
        >This Week's Prize</v-toolbar-title
      >
      <v-toolbar-title v-else class="blue--text heading-2 font-weight-bold">This week</v-toolbar-title>
      <v-spacer />
      <v-btn icon @click="$emit('closed')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-skeleton-loader v-if="$apollo.loading" type="card" />
    <v-card-text v-else>
      <p class="std-text font-weight-bold">
        {{ activePrize.startDate | moment("ddd, D MMM") }} - {{ activePrize.endDate | moment("ddd, D MMM") }}
      </p>

      <j-alert v-if="qualified" type="success"
        >Congratulations! You unlocked this week's gifts by logging a workout picture and 3 meal pictures for 5 out of 7
        days!</j-alert
      >
      <div v-else>
        <div v-if="activePrize.runAction == 'FIVE_OUT_OF_SEVEN_WITH_PHOTOS'">
          <p class="std-text">
            Complete your <strong>Exercise</strong> and <strong>Nutrition</strong> to stand a chance to win awesome
            prizes!
          </p>
          <p class="std-text"><strong>Exercise</strong> — Log a workout picture for 5 out of 7 days in a week.</p>
          <p class="std-text"><strong>Nutrition</strong> — Log 3 meal pictures for 5 out of 7 days in a week.</p>
        </div>

        <div v-if="activePrize.runAction == 'THREE_OUT_OF_SEVEN_WITHOUT_PHOTOS'">
          <p class="std-text">
            <strong>Exercise</strong> — Log a workout (with or without a picture) for 3 out of 7 days in a week.
          </p>
        </div>
      </div>

      <div v-if="activePrize.entryCopy">
        <v-img v-if="activePrize.entryIcon" :src="activePrize.entryIcon" width="30" height="30"></v-img>
        <v-icon v-else :color="iconColor" size="30">mdi-seal</v-icon>
        <p class="std-text">{{ activePrize.entryCopy }}</p>
      </div>
      <div v-if="activePrize.drawCopy">
        <v-img v-if="activePrize.drawIcon" :src="activePrize.drawIcon" width="30" height="30"></v-img>
        <v-icon v-else :color="iconColor" size="30">mdi-gift</v-icon>
        <p class="std-text">{{ activePrize.drawCopy }}</p>
      </div>
      <div v-if="activePrize.rewardsCopy">
        <v-img v-if="activePrize.rewardsIcon" :src="activePrize.rewardsIcon" width="30" height="30"></v-img>
        <div v-else>
          <v-avatar v-if="qualified" tile size="30">
            <img :src="require('@/assets/images/rewards-yellow.svg')" max-height="25" max-width="25" />
          </v-avatar>
          <v-avatar v-else tile size="30">
            <img :src="require('@/assets/images/rewards.svg')" max-height="25" max-width="25" />
          </v-avatar>
        </div>
        <p class="std-text">{{ activePrize.rewardsCopy }}</p>
      </div>

      <v-card-actions class="d-flex flex-column">
        <j-btn :href="activePrize.url" target="_blank">Find out more</j-btn>
      </v-card-actions>
    </v-card-text>
  </j-card>
</template>

<script>
import { ACTIVE_PRIZE_QUERY } from "@/graphql/queries/prizeCalendar";
import { getMonday } from "@/lib/calendarWeek";

export default {
  name: "PrizeDetails",
  props: {
    qualified: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconColor() {
      return this.qualified ? "yellow darken-1" : "grey darken-2";
    },
  },
  apollo: {
    activePrize: {
      query: ACTIVE_PRIZE_QUERY,
      variables() {
        return {
          date: this.$moment(getMonday(this.$store.getters.selectedDate.format("YYYY-MM-DD"))).format("YYYY-MM-DD"),
        };
      },
      fetchPolicy: "cache-and-network",
    },
  },
};
</script>
